import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Modal,
  Icon,
  TextInfo,
  Checkbox,
  Button
} from './ui/index';
import {
  INITIAL_DORMITORIOS,
  INITIAL_BANOS,
  selectUniqueComunas,
  selectFiltroUbicaciones,
  setFiltroUbicaciones
} from '../redux/proyectosSlice';
import './styles/ModalGenerico.scss';
import InputFiltro from './ui/InputFiltro/InputFiltro';
import { MENU_ENABLED } from '../config/constant';


const ARRIENDO_TYPE = {
  DEPTOS: 0,
  ESTACIONAMIENTOS: 1,
  BODEGAS: 2,
};

// Toma un ARRIENDO_TYPE y devuelve la URL a la que debería navegarse cuando se le hace click
const arriendoTypeToUrl = (arriendoType) => {
  switch (arriendoType) {
    case ARRIENDO_TYPE.DEPTOS:
      return '/result';

    case ARRIENDO_TYPE.ESTACIONAMIENTOS:
      return '/estacionamientos';

    case ARRIENDO_TYPE.BODEGAS:
      return '/bodegas';

    default:
      console.log(`arriendoTypeToUrl -> tipo de arriendo "${arriendoType}" desconocido!`);
      return '';
  }
}


const ModalArrendar = ({ show, close }) => {

  const dispatch = useDispatch();
  const [arriendoType, setArriendoType] = useState(ARRIENDO_TYPE.DEPTOS);

  const filtroUbicaciones = useSelector(selectFiltroUbicaciones);
  const uniqueComunas = useSelector(selectUniqueComunas);

  const onUbicacionCheckboxChange = (event, comuna) => {
    if (event.target.checked) {
      // Ubicación ticked, agregarla al filtro de ubicaciones
      const newFiltroUbicaciones = filtroUbicaciones.slice();
      newFiltroUbicaciones.push(comuna);
      dispatch(setFiltroUbicaciones(newFiltroUbicaciones));
    } else {
      // Ubicación unticked, removerla del filtro de ubicaciones
      const newFiltroUbicaciones = filtroUbicaciones.filter(ubi => ubi !== comuna);
      dispatch(setFiltroUbicaciones(newFiltroUbicaciones));
    }
  }

  const ubicacionesCheckboxes = uniqueComunas.map((comuna) => {
    return (
      <Checkbox key={comuna} name={comuna} onChange={(event) => onUbicacionCheckboxChange(event, comuna)} position="right">
        <TextInfo color="black"><Icon name="icon-location-pin" color="#848390" border="round" size="12px" />{comuna}</TextInfo>
      </Checkbox>
    );
  });

  const onDepartamentosClick = () => {
    setArriendoType(ARRIENDO_TYPE.DEPTOS);
  }

  const onEstacionamientosClick = () => {
    if (MENU_ENABLED.ESTACIONAMIENTOS) {
      setArriendoType(ARRIENDO_TYPE.ESTACIONAMIENTOS);
    }
  }

  const onBodegasClick = () => {
    if (MENU_ENABLED.BODEGAS) {
      setArriendoType(ARRIENDO_TYPE.BODEGAS);
    }
  }

  const estacionamientosColor = MENU_ENABLED.ESTACIONAMIENTOS ? '' : 'darkgray';
  const bodegasColor = MENU_ENABLED.BODEGAS ? '' : 'darkgray';

  return (
    <Modal show={show} close={close}>
      <div className="modal-content modal-generico large fade">
        <div className="header-modal">
          <Icon onClick={close} name="icon-close" size="14" />
          Arrendar online
        </div>
        <div className="modal-body searchArrendaronline">
          <h4 className="card-title">¿Qué quieres arrendar?</h4>
          <div className="items-select">

            <div onClick={onDepartamentosClick} className={`item-card ${arriendoType === ARRIENDO_TYPE.DEPTOS ? 'selected' : ''}`}>
              <Icon name="icon-hotel" size="30" />
              <div className="card-info">
                <p>Departamentos</p>
              </div>
            </div>

            <div onClick={onEstacionamientosClick} className={`item-card ${arriendoType === ARRIENDO_TYPE.ESTACIONAMIENTOS ? 'selected' : ''}`}>
              <Icon name="icon-garage" size="30" color={estacionamientosColor} />
              <div className="card-info">
                <p style={{ color: estacionamientosColor }}>Estacionamientos</p>
              </div>
            </div>

            <div onClick={onBodegasClick} className={`item-card ${arriendoType === ARRIENDO_TYPE.BODEGAS ? 'selected' : ''}`}>
              <Icon name="icon-warehouse" size="30" color={bodegasColor} />
              <div className="card-info">
                <p style={{ color: bodegasColor }}>Bodegas</p>
              </div>
            </div>

          </div>

          <h4 className="card-title">Filtros más comunes</h4>

          <div className="box-cards">
            <div className="box-card">
              <TextInfo className="title-card" textWeight="bold" color="black">Selecciona una ubicación</TextInfo>
              {ubicacionesCheckboxes}
            </div>
            <div className="box-card">
              <TextInfo className="title-card" textWeight="bold" color="black">Cantidad de dormitorios y baños</TextInfo>
              <div className="boder-box">
                <InputFiltro type="dormitorio" initialValue={INITIAL_DORMITORIOS} label="Cantidad de dormitorios"
                  name="dormitorios" flex={true} between={true} marginBottom="20" />
                <InputFiltro type="bano" initialValue={INITIAL_BANOS} label="Cantidad de baños"
                  name="banos" flex={true} between={true} />
              </div>
            </div>
          </div>

          {
            MENU_ENABLED.QUIENES_SOMOS &&

            <div className="help">
              <TextInfo size="12">Luego podrás agregar más opciones. ¿Necesitas ayuda? <a href="# ">Aprende a arrendar online.</a></TextInfo>
            </div>
          }

          <Link to={arriendoTypeToUrl(arriendoType)}><Button onClick={close} className="btn btn-submit mt-2" startIcon="icon-search-fill" color='btn-orange' fullWidth={true} size="small">BUSCAR</Button></Link>
        </div>
      </div>
    </Modal>
  );
};

export default ModalArrendar;
