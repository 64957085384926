import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BACKEND_BASE_URL } from '../config/constant';
import {
  deleteToken,
  getAuthenticatedRequestData,
  getStrapiResponseErrorMessage,
  getTipoDocumentoStr,
  getTipoPersonaStr,
  getToken,
  setToken
} from '../utils';
const axios = require('axios');


export const INITIAL_USERNAME = '';
export const INITIAL_EMAIL = '';
export const INITIAL_NOMBRE = '';
export const INITIAL_APELLIDO = '';
export const INITIAL_TIPO_DOCUMENTO = '';
export const INITIAL_NUMERO_DOCUMENTO = '';
export const INITIAL_TELEFONO = '';
export const INITIAL_TIPO_PERSONA = '';
export const INITIAL_LOGGED_IN = false;
export const INITIAL_LEVEL_CLIENTEID = '';
export const INITIAL_JWT = '';
export const INITIAL_LOGIN_SUCCESS_COUNT = 0;
export const INITIAL_LEVEL_CREAR_NATURAL_ERROR_MESSAGE = '';
export const INITIAL_LEVEL_CREAR_NATURAL_ERROR_COUNT = 0;
export const INITIAL_LOGIN_USER_ERROR_MESSAGE = '';
export const INITIAL_LOGIN_USER_ERROR_COUNT = 0;
export const INITIAL_RESET_PASSWORD_ERROR_MESSAGE = '';
export const INITIAL_RESET_PASSWORD_ERROR_COUNT = 0;
export const INITIAL_RESET_PASSWORD_SUCCESS_COUNT = 0;
export const INITIAL_CHANGE_PASSWORD_SUCCESS_MESSAGE = '';
export const INITIAL_CHANGE_PASSWORD_SUCCESS_COUNT = 0;
export const INITIAL_CHANGE_PASSWORD_ERROR_MESSAGE = '';
export const INITIAL_CHANGE_PASSWORD_ERROR_COUNT = 0;
export const INITIAL_CHANGE_EMAIL_SUCCESS_MESSAGE = '';
export const INITIAL_CHANGE_EMAIL_SUCCESS_COUNT = 0;
export const INITIAL_CHANGE_EMAIL_ERROR_MESSAGE = '';
export const INITIAL_CHANGE_EMAIL_ERROR_COUNT = 0;


const initialState = {
  username: INITIAL_USERNAME,
  email: INITIAL_EMAIL,
  nombre: INITIAL_NOMBRE,
  apellido: INITIAL_APELLIDO,
  apellidoPaterno: INITIAL_APELLIDO,
  apellidoMaterno: INITIAL_APELLIDO,
  tipoDocumento: INITIAL_TIPO_DOCUMENTO,
  numeroDocumento: INITIAL_NUMERO_DOCUMENTO,
  telefono: INITIAL_TELEFONO,
  tipoPersona: INITIAL_TIPO_PERSONA,
  loggedIn: INITIAL_LOGGED_IN,
  levelClienteId: INITIAL_LEVEL_CLIENTEID,
  jwt: INITIAL_JWT,

  // Thunk levelCrearNatural
  levelCrearNaturalStatus: 'idle',
  levelCrearNaturalErrorMessage: INITIAL_LEVEL_CREAR_NATURAL_ERROR_MESSAGE,
  levelCrearNaturalErrorCount: INITIAL_LEVEL_CREAR_NATURAL_ERROR_COUNT,

  // Thunk loginUser
  loginUserStatus: 'idle',
  loginSuccessCount: INITIAL_LOGIN_SUCCESS_COUNT,
  loginUserErrorMessage: INITIAL_LOGIN_USER_ERROR_MESSAGE,
  loginUserErrorCount: INITIAL_LOGIN_USER_ERROR_COUNT,

  // Thunk reset password
  resetPasswordSuccessCount: INITIAL_RESET_PASSWORD_SUCCESS_COUNT,
  resetPasswordErrorMessage: INITIAL_RESET_PASSWORD_ERROR_MESSAGE,
  resetPasswordErrorCount: INITIAL_RESET_PASSWORD_ERROR_COUNT,

  // Thunk changePassword
  changePasswordStatus: 'idle',
  changePasswordSuccessMessage: INITIAL_CHANGE_PASSWORD_SUCCESS_MESSAGE,
  changePasswordSuccessCount: INITIAL_CHANGE_PASSWORD_SUCCESS_COUNT,
  changePasswordErrorMessage: INITIAL_CHANGE_PASSWORD_ERROR_MESSAGE,
  changePasswordErrorCount: INITIAL_CHANGE_PASSWORD_ERROR_COUNT,

  // Thunk changeEmail
  changeEmailStatus: 'idle',
  changeEmailSuccessMessage: INITIAL_CHANGE_EMAIL_SUCCESS_MESSAGE,
  changeEmailSuccessCount: INITIAL_CHANGE_EMAIL_SUCCESS_COUNT,
  changeEmailErrorMessage: INITIAL_CHANGE_EMAIL_ERROR_MESSAGE,
  changeEmailErrorCount: INITIAL_CHANGE_EMAIL_ERROR_COUNT,

  // Misc
  logoutUserStatus: 'idle',
  resetPasswordStatus: 'idle',
};


// Thunk para crear un "cliente natural" en la API de Level. Se asume que userData tiene la forma
// { tipoDocumentoId, numeroDocumento, apellidoPaterno, apellidoMaterno, nombre, telefono, email, password }.
export const levelCrearNatural = createAsyncThunk('user/levelCrearNatural', async (userData) => {
  const formattedData = {
    clientenaturaltipodocumentoid: userData.tipoDocumentoId,
    clientenaturaldocumento: userData.numeroDocumento,
    clientenaturalapellidopaterno: userData.apellidoPaterno,
    clientenaturalapellidomaterno: userData.apellidoMaterno,
    clientenaturalnombre: userData.nombre,
    clientenaturalfono1: userData.telefono,
    clientenaturalmail1: userData.email,
    clientepassword: userData.password,
  };

  try {
    const response = await axios.post(`${BACKEND_BASE_URL}/level-api/crearnatural`, formattedData);
    return { response };
  } catch (error) {
    const errorMsg = getStrapiResponseErrorMessage(error);
    throw new Error(errorMsg);
  }
});

export const levelModificarNatural = createAsyncThunk('user/levelModificarNatural', async ({
  personaData: {
    tipoPersona,
    nacionalidad,
    genero,
    estadocivil,
    profesion,
    isPep
  }
}, { getState }) => {
  const clienteid = getState().user.levelClienteId
  try {
    const jwt = getToken();
    const response = await axios.post(`${BACKEND_BASE_URL}/level-api/modificarnatural`, {
      clienteid,
      clientenaturaltipotrabajadorid: parseInt(tipoPersona, 10),
      clientenaturalpaisid: parseInt(nacionalidad),
      clientenaturalgeneroid: parseInt(genero, 10),
      clientenaturalestadocivilid: parseInt(estadocivil),
      clientenaturalprofesionid: parseInt(profesion),
      clientepep: isPep,
    }, { headers: { 'x-api-key': `Bearer ${jwt}` } });
    return { response };
  } catch (error) {
    const errorMsg = getStrapiResponseErrorMessage(error);
    throw new Error(errorMsg);
  }
});

// Thunk para loggear un usuario con la API de Level. Se asume que userData tiene
// la forma { numeroDocumento, password }.
export const loginUser = createAsyncThunk('user/login', async (userData) => {
  // Renombramos campos tal cual los necesita la API
  const userDataApi = {
    clientedocumento: userData.numeroDocumento,
    clientecontrasena: userData.password,
  };

  try {
    const response = await axios.post(`${BACKEND_BASE_URL}/level-api/login`, userDataApi);
    const { data: { user: { clienteid, jwt } } } = response
    setToken(jwt);
    const { data: clienteNatural } = await axios.post(
      `${BACKEND_BASE_URL}/level-api/obtenernatural`, { clienteid }, { headers: { 'x-api-key': `Bearer ${jwt}` } });
    return { response, clienteNatural: clienteNatural[0] };
  } catch (error) {
    const errorMsg = getStrapiResponseErrorMessage(error);
    throw new Error(errorMsg);
  }
});


// Thunk para resetar la contraseña del usuario. La API de Level se encarga de mandarle un email
// con la nueva contraseña. Se asume que userData tiene la forma { numeroDocumento }.
export const resetPassword = createAsyncThunk('user/resetPassword', async (userData) => {
  const clientedocumento = userData.numeroDocumento;

  try {
    const response = await axios.post(`${BACKEND_BASE_URL}/level-api/resetpassword`, { clientedocumento });
    return response;
  } catch (error) {
    const errorMsg = getStrapiResponseErrorMessage(error);
    throw new Error(errorMsg);
  }
});

// Thunk para cambiar la contraseña del usuario.
export const changePassword = createAsyncThunk('user/changePassword', async (userData, thunkAPI) => {

  const { password } = userData;
  const reduxState = thunkAPI.getState();

  let requestData = {
    clientedocumento: reduxState.user.numeroDocumento,
    clientecontrasena: password,
  };

  requestData = getAuthenticatedRequestData(requestData, reduxState);

  try {
    const jwt = getToken();
    const response = await axios.post(`${BACKEND_BASE_URL}/level-api/changepassword`, requestData, { headers: { 'x-api-key': `Bearer ${jwt}` } });
    return { response };
  } catch (error) {
    const errorMsg = getStrapiResponseErrorMessage(error);
    throw new Error(errorMsg);
  }
});

// Thunk para cambiar el email del usuario.
export const changeEmail = createAsyncThunk('user/changeEmail', async (userData, thunkAPI) => {

  const { email } = userData;
  const reduxState = thunkAPI.getState();

  let requestData = {
    clienteid: reduxState.user.levelClienteId,
    clientenaturalmail1: email,
  };

  requestData = getAuthenticatedRequestData(requestData, reduxState);

  try {
    const jwt = getToken();
    const response = await axios.post(`${BACKEND_BASE_URL}/level-api/changeemail`, requestData, { headers: { 'x-api-key': `Bearer ${jwt}` } });
    return { response };
  } catch (error) {
    const errorMsg = getStrapiResponseErrorMessage(error);
    throw new Error(errorMsg);
  }
});


const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserPreEvaluacion(state, action) {
      if (action.payload) {
        state.tipoPersonaId = action.payload.tipoPersona;
        state.paisId = action.payload.nacionalidad;
        state.generoId = action.payload.genero;
        state.estadoCivilId = action.payload.estadocivil;
        state.profesionId = action.payload.profesion;
      }
    },
    setUsername(state, action) {
      state.username = action.payload ? action.payload : INITIAL_USERNAME;
    },
    setEmail(state, action) {
      state.email = action.payload ? action.payload : INITIAL_EMAIL;
    },
    setNombre(state, action) {
      state.nombre = action.payload ? action.payload : INITIAL_NOMBRE;
    },
    setApellido(state, action) {
      state.apellido = action.payload ? action.payload : INITIAL_APELLIDO;
    },
    setApellidoPaterno(state, action) {
      state.apellidoPaterno = action.payload ? action.payload : INITIAL_APELLIDO;
    },
    setApellidoMaterno(state, action) {
      state.apellidoMaterno = action.payload ? action.payload : INITIAL_APELLIDO;
    },
    setTipoDocumento(state, action) {
      state.tipoDocumento = action.payload ? action.payload : INITIAL_TIPO_DOCUMENTO;
    },
    setNumeroDocumento(state, action) {
      state.numeroDocumento = action.payload ? action.payload : INITIAL_NUMERO_DOCUMENTO;
    },
    setTelefono(state, action) {
      state.telefono = action.payload ? action.payload : INITIAL_TELEFONO;
    },
    setTipoPersona(state, action) {
      state.tipoPersona = action.payload ? action.payload : INITIAL_TIPO_PERSONA;
    },
    setLoggedIn(state, action) {
      state.loggedIn = action.payload ? action.payload : INITIAL_LOGGED_IN;
    },
    logoutUser(state, action) {
      // Reiniciar todo el estado

      // No reseteamos 'nombre' porque lo necesitamos para mostrar un mensaje de despedida al usuario.
      //state.nombre = INITIAL_NOMBRE;

      state.email = INITIAL_EMAIL;
      state.apellido = INITIAL_APELLIDO;
      state.apellidoPaterno = INITIAL_APELLIDO;
      state.apellidoMaterno = INITIAL_APELLIDO;
      state.tipoDocumento = INITIAL_TIPO_DOCUMENTO;
      state.numeroDocumento = INITIAL_NUMERO_DOCUMENTO;
      state.telefono = INITIAL_TELEFONO;
      state.tipoPersona = INITIAL_TIPO_PERSONA;
      state.loggedIn = INITIAL_LOGGED_IN;
      state.levelClienteId = INITIAL_LEVEL_CLIENTEID;
      state.levelCrearNaturalErrorMessage = INITIAL_LEVEL_CREAR_NATURAL_ERROR_MESSAGE;
      state.levelCrearNaturalErrorCount = INITIAL_LEVEL_CREAR_NATURAL_ERROR_COUNT;
      state.levelCrearNaturalStatus = 'idle';
      state.loginUserStatus = 'idle';
      state.loginUserErrorMessage = INITIAL_LOGIN_USER_ERROR_MESSAGE;
      state.loginUserErrorCount = INITIAL_LOGIN_USER_ERROR_COUNT;
      state.logoutUserStatus = 'succeeded';
      state.jwt = INITIAL_JWT;

      deleteToken();

    },
    setUpdateUserStatus(state, action) {
      state.updateUserStatus = action.payload ? action.payload : 'idle';
    },
    setLogoutUserStatus(state, action) {
      state.logoutUserStatus = action.payload ? action.payload : 'idle';
    },
    setLevelCrearNaturalErrorCount(state, action) {
      state.levelCrearNaturalErrorCount = action.payload ? action.payload : INITIAL_LEVEL_CREAR_NATURAL_ERROR_COUNT;
    },
    setLoginUserErrorCount(state, action) {
      state.loginUserErrorCount = action.payload ? action.payload : INITIAL_LOGIN_USER_ERROR_COUNT;
    },
    setResetPasswordErrorCount(state, action) {
      state.resetPasswordErrorCount = action.payload ? action.payload : INITIAL_RESET_PASSWORD_ERROR_COUNT;
    },
    setResetPasswordSuccessCount(state, action) {
      state.resetPasswordSuccessCount = action.payload ? action.payload : INITIAL_RESET_PASSWORD_SUCCESS_COUNT;
    },
    setChangePasswordErrorCount(state, action) {
      state.changePasswordErrorCount = action.payload ? action.payload : INITIAL_CHANGE_PASSWORD_ERROR_COUNT;
    },
    setChangePasswordSuccessCount(state, action) {
      state.changePasswordSuccessCount = action.payload ? action.payload : INITIAL_CHANGE_PASSWORD_SUCCESS_COUNT;
    },
    setChangeEmailErrorCount(state, action) {
      state.changeEmailErrorCount = action.payload ? action.payload : INITIAL_CHANGE_EMAIL_ERROR_COUNT;
    },
    setChangeEmailSuccessCount(state, action) {
      state.changeEmailSuccessCount = action.payload ? action.payload : INITIAL_CHANGE_EMAIL_SUCCESS_COUNT;
    },
  },
  extraReducers: {
    // levelCrearNatural thunk
    [levelCrearNatural.pending]: (state, action) => {
      state.levelCrearNaturalStatus = 'loading';
    },
    [levelCrearNatural.fulfilled]: (state, action) => {
      state.levelCrearNaturalStatus = 'succeeded';

      const levelUser = action.payload.response.data;
      // Resetear variables de error. Recordar que ahora todo esto es persistido!
      state.levelCrearNaturalErrorMessage = INITIAL_LEVEL_CREAR_NATURAL_ERROR_MESSAGE;
      state.levelCrearNaturalErrorCount = INITIAL_LEVEL_CREAR_NATURAL_ERROR_COUNT;
      state.loginUserErrorMessage = INITIAL_LOGIN_USER_ERROR_MESSAGE;
      state.loginUserErrorCount = INITIAL_LOGIN_USER_ERROR_COUNT;

      // Guardar datos de usuario
      state.username = levelUser.clientenaturalmail1 || INITIAL_USERNAME;
      state.email = levelUser.clientenaturalmail1 || INITIAL_EMAIL;
      state.nombre = levelUser.clientenaturalnombre || INITIAL_NOMBRE;
      state.tipoDocumento = getTipoDocumentoStr(levelUser.clientenaturaltipodocumentoid) || INITIAL_TIPO_DOCUMENTO;
      state.numeroDocumento = levelUser.clientenaturaldocumento || INITIAL_NUMERO_DOCUMENTO;
      state.telefono = levelUser.clientenaturalfono1 || INITIAL_TELEFONO;
      state.tipoPersona = getTipoPersonaStr(levelUser.clientetipopersonaid) || INITIAL_TIPO_PERSONA;
      state.loggedIn = true;
      state.levelClienteId = levelUser.clienteid || INITIAL_LEVEL_CLIENTEID;
    },
    [levelCrearNatural.rejected]: (state, action) => {
      state.levelCrearNaturalStatus = 'failed';
      state.levelCrearNaturalErrorMessage = action.error.message;
      state.levelCrearNaturalErrorCount += 1;
    },

    // loginUser thunk
    [loginUser.pending]: (state, action) => {
      state.loginUserStatus = 'loading';
    },
    [loginUser.fulfilled]: (state, { payload: { response, clienteNatural } }) => {
      state.loginUserStatus = 'succeeded';

      const userObj = response.data.user;
      console.log(userObj);
      // Resetear variables de error. Recordar que ahora todo esto es persistido!
      state.levelCrearNaturalErrorMessage = INITIAL_LEVEL_CREAR_NATURAL_ERROR_MESSAGE;
      state.levelCrearNaturalErrorCount = INITIAL_LEVEL_CREAR_NATURAL_ERROR_COUNT;
      state.loginUserErrorMessage = INITIAL_LOGIN_USER_ERROR_MESSAGE;
      state.loginUserErrorCount = INITIAL_LOGIN_USER_ERROR_COUNT;
      state.resetPasswordErrorMessage = INITIAL_RESET_PASSWORD_ERROR_MESSAGE;
      state.resetPasswordErrorCount = INITIAL_RESET_PASSWORD_ERROR_COUNT;
      state.changePasswordErrorMessage = INITIAL_CHANGE_PASSWORD_ERROR_MESSAGE;
      state.changePasswordErrorCount = INITIAL_CHANGE_PASSWORD_ERROR_COUNT;
      state.changePasswordSuccessMessage = INITIAL_CHANGE_PASSWORD_SUCCESS_MESSAGE;
      state.changePasswordSuccessCount = INITIAL_CHANGE_PASSWORD_SUCCESS_COUNT;
      state.changeEmailErrorMessage = INITIAL_CHANGE_EMAIL_ERROR_MESSAGE;
      state.changeEmailErrorCount = INITIAL_CHANGE_EMAIL_ERROR_COUNT;
      state.changeEmailSuccessMessage = INITIAL_CHANGE_EMAIL_SUCCESS_MESSAGE;
      state.changeEmailSuccessCount = INITIAL_CHANGE_EMAIL_SUCCESS_COUNT;

      // Guardar datos de usuario
      state.username = userObj.username || INITIAL_USERNAME;
      state.jwt = userObj.jwt || INITIAL_JWT;
      state.email = clienteNatural.clientenaturalmaiL1 || INITIAL_EMAIL;
      state.nombre = clienteNatural.clientenaturalnombre || INITIAL_NOMBRE;
      state.tipoDocumento = getTipoDocumentoStr(clienteNatural.clientenaturaltipodocumentoid) || INITIAL_TIPO_DOCUMENTO;
      state.numeroDocumento = clienteNatural.clientenaturaldocumento || INITIAL_NUMERO_DOCUMENTO;
      state.telefono = clienteNatural.clientenaturalfonO1 || INITIAL_TELEFONO;
      state.tipoPersona = clienteNatural.clientenaturaltipotrabajador || INITIAL_TIPO_PERSONA;
      state.tipoPersonaId = clienteNatural.clientenaturaltipotrabajadorid;
      state.levelClienteId = clienteNatural.clienteid || INITIAL_LEVEL_CLIENTEID;
      state.generoId = clienteNatural.clientenaturalgeneroid
      state.nacionalidad = clienteNatural.clientenaturalpais
      state.paisId = clienteNatural.clientenaturalpaisid
      state.profesion = clienteNatural.clientenaturalprofesion
      state.profesionId = clienteNatural.clientenaturalprofesionid
      state.estadoCivil = clienteNatural.clientenaturalestadocivil
      state.estadoCivilId = clienteNatural.clientenaturalestadocivilid
      state.loggedIn = true;
    },
    [loginUser.rejected]: (state, action) => {
      state.loginUserStatus = 'failed';

      // Actualizar mensaje de error e incrementar contador
      state.loginUserErrorMessage = action.error.message;
      state.loginUserErrorCount += 1;

      // Resetear el resto del estado
      state.username = INITIAL_USERNAME;
      state.email = INITIAL_EMAIL;
      state.nombre = INITIAL_NOMBRE;
      state.apellido = INITIAL_APELLIDO;
      state.apellidoPaterno = INITIAL_APELLIDO;
      state.apellidoMaterno = INITIAL_APELLIDO;
      state.tipoDocumento = INITIAL_TIPO_DOCUMENTO;
      state.numeroDocumento = INITIAL_NUMERO_DOCUMENTO;
      state.telefono = INITIAL_TELEFONO;
      state.tipoPersona = INITIAL_TIPO_PERSONA;
      state.loggedIn = INITIAL_LOGGED_IN;
      state.levelClienteId = INITIAL_LEVEL_CLIENTEID;
    },

    // resetPassword thunk
    [resetPassword.pending]: (state, action) => {
      state.resetPasswordStatus = 'loading';
    },
    [resetPassword.fulfilled]: (state, action) => {
      state.resetPasswordStatus = 'succeeded';

      // Incrementar contador de éxito
      state.resetPasswordSuccessCount += 1;

      // Reiniciar contador y mensaje de error
      state.resetPasswordErrorMessage = INITIAL_RESET_PASSWORD_ERROR_MESSAGE;
      state.resetPasswordErrorCount = INITIAL_RESET_PASSWORD_SUCCESS_COUNT;
    },
    [resetPassword.rejected]: (state, action) => {
      state.resetPasswordStatus = 'failed';

      // Actualizar mensaje de error e incrementar contador
      state.resetPasswordErrorMessage = action.error.message;
      state.resetPasswordErrorCount += 1;

      // Reiniciar contador de éxito
      state.resetPasswordSuccessCount = INITIAL_RESET_PASSWORD_SUCCESS_COUNT;
    },

    // changePassword thunk
    [changePassword.pending]: (state, action) => {
      state.changePasswordStatus = 'loading';
    },
    [changePassword.fulfilled]: (state, action) => {
      state.changePasswordStatus = 'succeeded';

      state.changePasswordSuccessMessage = 'Contraseña actualizada exitosamente';
      state.changePasswordSuccessCount += 1;
      state.changePasswordErrorMessage = INITIAL_CHANGE_PASSWORD_ERROR_MESSAGE;
      state.changePasswordErrorCount = INITIAL_CHANGE_PASSWORD_ERROR_COUNT;
    },
    [changePassword.rejected]: (state, action) => {
      state.changePasswordStatus = 'failed';

      // Actualizar mensaje de error e incrementar contador
      state.changePasswordErrorMessage = action.error.message;
      state.changePasswordErrorCount += 1;
    },

    // changeEmail thunk
    [changeEmail.pending]: (state, action) => {
      state.changeEmailStatus = 'loading';
    },
    [changeEmail.fulfilled]: (state, action) => {
      state.changeEmailStatus = 'succeeded';

      // Actualizar email de usuario en Redux
      state.email = action.payload.response.data.email;

      state.changeEmailSuccessMessage = 'Email actualizado exitosamente';
      state.changeEmailSuccessCount += 1;
      state.changeEmailErrorMessage = INITIAL_CHANGE_EMAIL_ERROR_MESSAGE;
      state.changeEmailErrorCount = INITIAL_CHANGE_EMAIL_ERROR_COUNT;
    },
    [changeEmail.rejected]: (state, action) => {
      state.changeEmailStatus = 'failed';

      // Actualizar mensaje de error e incrementar contador
      state.changeEmailErrorMessage = action.error.message;
      state.changeEmailErrorCount += 1;
    },
  },
});

export default userSlice.reducer;

export const selectNombre = state => state.user.nombre;
export const selectFirstName = state => {
  const splitArray = state.user.nombre.split(' ');
  return splitArray[0];
}

export const selectResumenContrato = state => state.proyectos.resumenContrato;
export const selectEstadoCivil = state => state.user.estadoCivil;
export const selectProfesion = state => state.user.profesion;
export const selectNacionalidad = state => state.user.nacionalidad;
export const selectApellido = state => state.user.apellido;
export const selectApellidoPaterno = state => state.user.apellidoPaterno;
export const selectApellidoMaterno = state => state.user.apellidoMaterno;
export const selectTipoDocumento = state => state.user.tipoDocumento;
export const selectNumeroDocumento = state => state.user.numeroDocumento;
export const selectTipoPersona = state => state.user.tipoPersona;
export const selectTelefono = state => state.user.telefono;
export const selectUsername = state => state.user.username;
export const selectTipoPersonaId = state => state.user.tipoPersonaId;
export const selectGeneroId = state => state.user.generoId;
export const selectPaisId = state => state.user.paisId;
export const selectProfesionId = state => state.user.profesionId;
export const selectEstadoCivilId = state => state.user.estadoCivilId;

export const selectEmail = state => state.user.email;
export const selectLevelCrearNaturalStatus = state => state.user.levelCrearNaturalStatus;
export const selectLoginUserStatus = state => state.user.loginUserStatus;
export const selectLoggedIn = state => state.user.loggedIn;
export const selectLevelCrearNaturalErrorMessage = state => state.user.levelCrearNaturalErrorMessage;
export const selectLevelCrearNaturalErrorCount = state => state.user.levelCrearNaturalErrorCount;
export const selectLoginUserErrorMessage = state => state.user.loginUserErrorMessage;
export const selectLoginUserErrorCount = state => state.user.loginUserErrorCount;
export const selectStrapiId = state => state.user.strapiId;
export const selectLevelClienteId = state => state.user.levelClienteId;
export const selectLogoutUserStatus = state => state.user.logoutUserStatus;
export const selectLoginSuccessCount = state => state.user.loginSuccessCount;
export const selectResetPasswordStatus = state => state.user.resetPasswordStatus;
export const selectResetPasswordErrorCount = state => state.user.resetPasswordErrorCount;
export const selectResetPasswordSuccessCount = state => state.user.resetPasswordSuccessCount;
export const selectResetPasswordData = (state) => {
  return {
    status: state.user.resetPasswordStatus,
    errorCount: state.user.resetPasswordErrorCount,
    errorMessage: state.user.resetPasswordErrorMessage,
    successCount: state.user.resetPasswordSuccessCount,
  };
}
export const selectChangePasswordData = (state) => {
  return {
    status: state.user.changePasswordStatus,
    errorCount: state.user.changePasswordErrorCount,
    errorMessage: state.user.changePasswordErrorMessage,
    successCount: state.user.changePasswordSuccessCount,
    successMessage: state.user.changePasswordSuccessMessage,
  };
}
export const selectChangeEmailData = (state) => {
  return {
    status: state.user.changeEmailStatus,
    errorCount: state.user.changeEmailErrorCount,
    errorMessage: state.user.changeEmailErrorMessage,
    successCount: state.user.changeEmailSuccessCount,
    successMessage: state.user.changeEmailSuccessMessage,
  };
}



// Thunk para enviar formulario de contcato
export const sendContactame = createAsyncThunk('user/contactame', async (formData) => {

  let requestData = {
    email: formData.email,
    nombre: formData.nombre,
    telefono: formData.telefono
  };

  try {
    const response = await axios.post(`${BACKEND_BASE_URL}/level-api/contactame`, requestData);
    const { status } = response;
    return status;
  } catch (error) {
    const errorMsg = getStrapiResponseErrorMessage(error);
    throw new Error(errorMsg);
  }
});

// Thunk para enviar formulario de contcato
export const mailAgendaVisita = createAsyncThunk('user/mailAgendaVisita', async (formData) => {

  try {
    const response = await axios.post(`${BACKEND_BASE_URL}/level-api/mailAgendaVisita`, formData);
    const { status } = response;
    return status;
  } catch (error) {
    const errorMsg = getStrapiResponseErrorMessage(error);
    throw new Error(errorMsg);
  }
});

export const {
  setUsername,
  setUserPreEvaluacion,
  setEmail,
  setNombre,
  setApellido,
  setApellidoPaterno,
  setApellidoMaterno,
  setTipoDocumento,
  setNumeroDocumento,
  setTelefono,
  setTipoPersona,
  setStrapiId,
  setLoggedIn,
  logoutUser,
  setLogoutUserStatus,
  setLevelCrearNaturalErrorCount,
  setLoginUserErrorCount,
  setResetPasswordErrorCount,
  setResetPasswordSuccessCount,
  setChangePasswordErrorCount,
  setChangePasswordSuccessCount,
  setChangeEmailErrorCount,
  setChangeEmailSuccessCount,
} = userSlice.actions;
