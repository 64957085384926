import React from 'react';
import './styles/MenuBar.scss';
import { TextInfo } from '../ui/index';
import { useHistory } from 'react-router-dom';
import { MENU_ENABLED } from '../../config/constant';


const MenuBar = ({ openSearchMobile, openVisitaMobile }) => {

  const history = useHistory();

  const onContactoClick = () => {
    history.push('/contacto');
  }

  const onVisitarClick = () => {
   if (MENU_ENABLED.PLACE_VISITAR) {
    openVisitaMobile();
   }
  }

  const visitarColor = MENU_ENABLED.PLACE_VISITAR ? 'white' : 'disabled';

  return (
    <div className="menu-bar">
      <div className="container">
        <div className="row">
          <div className="icons-bar">
            <TextInfo iconName="icon-key" onClick={openSearchMobile} color="white">ARRENDAR</TextInfo>
            <TextInfo iconName="icon-like-heart" color="disabled">ACTIVIDAD</TextInfo>
            <TextInfo iconName="icon-chat-conversation" onClick={onContactoClick} color="white">CONTACTO</TextInfo>
            <TextInfo iconName="icon-clock" onClick={onVisitarClick} color={visitarColor}>VISITAR</TextInfo>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuBar;
