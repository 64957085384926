import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import { scrollToTop } from '../utils';
import bannerDesk from '../static/images/conoce_tu_barrio/peatones.png';
import bannerMobile from '../static/images/quienes_somos/mural.jpg';
import styles from './styles/ConoceTuBarrio.module.scss';
import useResize from '../hooks/useResize';
import { BREAKPOINT_MOBILE } from '../config/constant';
import BarrioDropdown from '../components/BarrioDropdown';
import { Button, Icon } from '../components/ui/index';
import { useHistory } from 'react-router-dom';
import SliderMobile from '../components/containers/SliderMobile';
import {
  selectFiltroBarrios,
  selectFiltroUbicaciones,
  setFiltroUbicaciones
} from '../redux/proyectosSlice';
import { useDispatch, useSelector } from 'react-redux';
import MapPuntosInteres from '../components/MapPuntosInteres/MapPuntosInteres';
import SliderGallery from '../components/SliderGallery';
import CardLugarInteres from '../components/CardLugarInteres';
import SliderHeader from '../components/SliderHeader';
import useBarrioData from '../hooks/useBarrioData';


const ConoceTuBarrio = ({ match }) => {

  const [rizeWidth] = useResize();
  const dispatch = useDispatch();
  const history = useHistory();
  const pageCurrent = match.path;
  const [placesData, setPlacesData] = useState([]);
  const filtroUbicaciones = useSelector(selectFiltroUbicaciones);
  const filtroBarrios = useSelector(selectFiltroBarrios);
  const currentBarrioData = useBarrioData(filtroBarrios);

  useEffect(() => {
    scrollToTop();
  }, []);

  const banner = rizeWidth > BREAKPOINT_MOBILE ? bannerDesk : bannerMobile;

  const onVerEdificiosClick = () => {
    // Agregar barrio a filtro de ubicaciones
    const newUbicaciones = filtroUbicaciones.slice();
    newUbicaciones.push(filtroBarrios);
    dispatch(setFiltroUbicaciones(newUbicaciones));

    // Redirigir a página de resultados
    history.push('/result');
  }

  let sanitizedDescripcion = null;
  let direccionFull = null;

  if (currentBarrioData) {
    if (currentBarrioData.descripcion) {
      // Reemplazar globalmente el patrón [clickme](www.example.com)
      // por <a href="www.example.com">clickme</a>
      sanitizedDescripcion = currentBarrioData.descripcion.replace(/\[(.*?)\]\((.*?)\)/g, `<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>`);
    }

    direccionFull = currentBarrioData.direccionFull;
  }


  return (
    <Layout pageCurrent={pageCurrent}>
      <div className={`container ${styles.containerCustom}`}>
        <div className={styles.bannerContainer}>
          <img src={banner} alt="banner" />
          <div className={styles.veil} />
          <h1>¡Recorre tu barrio con Level!</h1>
          <h2>Conoce tu barrio</h2>
          {
            rizeWidth > BREAKPOINT_MOBILE &&

            <BarrioDropdown
              width="20%"
              left="120px"
              top="200px"
            />
          }
          {
            rizeWidth <= BREAKPOINT_MOBILE &&

            <BarrioDropdown
              width="80%"
              center={true}
              top="170px"
            />
          }
        </div>

        <div className={styles.mainContent}>
          {
            rizeWidth <= BREAKPOINT_MOBILE &&

            <>
              <h1>
                <Icon
                  name="icon-location-pin"
                  border="round"
                  background="light-orange"
                  color="#EB6B08"
                  width={40}
                  size={20}
                />
                <br />
                {direccionFull}
              </h1>
            </>
          }
          {
            rizeWidth > BREAKPOINT_MOBILE &&

            <div className={styles.locationIconContainer}>
              <Icon
                name="icon-location-pin"
                color="#EB6B08"
                width={40}
                size={25}
              />
              <h1 style={{ display: 'inline-block' }}>{direccionFull}</h1>
              <span onClick={onVerEdificiosClick} className={styles.link}><Icon name="icon-edificio" /> Ver edificios en {filtroBarrios}</span>
            </div>
          }

          <p style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: sanitizedDescripcion }}></p>

          {
            rizeWidth > BREAKPOINT_MOBILE &&

            <>
              <SliderGallery
                barrioData={currentBarrioData}
                updatePlacesData={(newPlacesData) => setPlacesData(newPlacesData)}
              />

              {
                placesData.length > 0 &&

                <SliderHeader itemsToScroll={3} itemsToShow={3} header="Lugares de interés" >
                  {
                    placesData.map((placeData, index) => {
                      return (
                        <CardLugarInteres key={placeData.id} placeData={placeData} marginTop="40px" />
                      );
                    })
                  }
                </SliderHeader>
              }
            </>
          }

          {
            rizeWidth <= BREAKPOINT_MOBILE &&

            <>
              <Button
                color='btn-outline-orange'
                size="medium"
                startIcon="icon-edificio"
                fullWidth={true}
                style={{
                  marginBottom: '20px',
                }}
                onClick={onVerEdificiosClick}
              >
                Ver edificios en {filtroBarrios}
              </Button>

              <SliderMobile
                home={false}
                imgArray={currentBarrioData.slider ? currentBarrioData.slider.map((slide) => {
                  return {
                    src: slide.url,
                    alt: slide.alt,
                  };
                }) : []}
              />
            </>
          }

          {
            rizeWidth <= BREAKPOINT_MOBILE &&

            <>
              <MapPuntosInteres
                coordenadas={currentBarrioData.coordenadas}
                direccion={currentBarrioData.direccion}
                height="300px"
                updatePlacesData={(newPlacesData) => setPlacesData(newPlacesData)}
              />

              {
                placesData.map((placeData, index) => {
                  const marginTop = index === 0 ? '40px' : '20px';

                  return (
                    <CardLugarInteres key={placeData.id} placeData={placeData} marginTop={marginTop} />
                  );
                })
              }
            </>
          }
        </div>
      </div>
    </Layout>
  );
}

export default ConoceTuBarrio;
