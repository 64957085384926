import React from 'react';
import {
  Icon,
  Modal
} from '../ui/index';
import styles from './SalaArriendoModal.module.scss';
import backgroundSala from '../../static/images/sala_arriendo/background-sala.jpg';


const SalaArriendoModal = ({ show, close, children, contentStyle }) => {

  return (
    <Modal show={show} bgClose={false} className={styles.container}>
      <div className={`modal-content modal-login fade ${styles.modalContentCustom}`} style={contentStyle}>
        <div className={`header-modal ${styles.headerModalCustom}`}>
          <Icon onClick={close} name="icon-close" size="14" color="white" />
        </div>
        <div className={`body-modal ${styles.bodyModalCustom}`}>
          <img src={backgroundSala} alt="background-sala" />
          <div className={styles.bodyModalContent}>
            {children}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default SalaArriendoModal;
