import React, { useEffect, useState } from 'react';
import { Button, TextInfo, Icon } from '../../../ui/index';
import logoColors from '../../../../static/images/logo.png'
import { useDispatch, useSelector } from 'react-redux';
import {
  sendFirma,
  getContratoByte,
  setSpinner,
  crearContratos,
  obtenerContratoReserva
} from '../../../../redux/proyectosSlice';
import Spinner from '../../../Spinner';

const FirmarContrato = ({cotizacion, close}) => {
  const dispatch = useDispatch();
  const spinnerEnabled = useSelector((state) => state.proyectos.spinnerEnabled)

  const { resumenContrato , tipologia } = cotizacion
  const [contratoId, setContratoId] = useState(0)
  console.log(cotizacion);
  const obtenerEstado = () => {
    dispatch(obtenerContratoReserva(resumenContrato.idReserva)).then((response) => {
      if(response.payload != null ){
        setContratoId(response.payload.ContratoId);
      }
      dispatch(setSpinner(false));
    }).catch(()=> {
      dispatch(setSpinner(false))
    })
  }

  const generarContrato = () => {
    dispatch(setSpinner(true))
    dispatch(crearContratos(resumenContrato.idReserva)).then((response) => {
      if(response.payload != null ){
        setContratoId(response.payload.ContratoId);
      }
      dispatch(setSpinner(false));
    }).catch(()=> {
      dispatch(setSpinner(false))
    })
  }

  const generarFirma = () => {
    dispatch(setSpinner(true))
    dispatch(sendFirma({ reservaid: resumenContrato.idReserva,  contratoid: contratoId})).then((response) => {
      console.log(response);
      dispatch(setSpinner(false))
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    obtenerEstado();  
  }, [])

  const STEPS = {
    CONTRATOS_A_PAGAR: 0,
    COMPROBANTE_DE_PAGO: 1,
    FELICIDADES: 2,
  }

  const [progressbar] = useState([
    { active: true, checked: false },   // contratos a pagar
    { active: false, checked: false },  // comprobante de pago
    { active: false, checked: false },  // felicidades
  ]);

  return (
    <>
      <div className="_preContainer pagar-contrato">
        <div className="_headerPre headerPreMovile">
          <div className="container">
            <div className="row">
              <div className="_header-container">
                <div className="_logo">
                  <a href="/"><img className={`_logo-white`} src={logoColors} alt="Logo Level" /></a>
                </div>
                <div className="_step-pre-evaluacion">
                  <div className={`_itemStep-pre ${progressbar[STEPS.CONTRATOS_A_PAGAR].active ? 'active' : ''}`}>
                    <span className={`_circle ${progressbar[STEPS.CONTRATOS_A_PAGAR].checked ? 'checkedReady' : ''}`}></span>
                  </div>
                  <div className={`_itemStep-pre ${progressbar[STEPS.COMPROBANTE_DE_PAGO].active ? 'active' : ''}`}>
                    <span className={`_circle ${progressbar[STEPS.COMPROBANTE_DE_PAGO].checked ? 'checkedReady' : ''}`}></span>
                  </div>
                  <div className={`_itemStep-pre ${progressbar[STEPS.FELICIDADES].active ? 'active' : ''}`}>
                    <span className={`_circle ${progressbar[STEPS.FELICIDADES].checked ? 'checkedReady' : ''}`}></span>
                  </div>
                </div>
                <div className="_option-pre">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a onClick={()=>close(false)}>Guardar y salir</a>
                  <Button color="btn-blue-dark" size="extra-small" endIcon="icon-question">AYUDA</Button>
                </div>
              </div>
              <div className="_main-container-reserva">
                <Icon name="icon-tick" size={54} />
                <h2>FIRMA ONLINE</h2>
                <div className="_form-departamento">
                  <div className="_header-form">
                    <div className="_opciones">
                      <Icon color="orange" size="33" name="icon-text-document" />
                      <div>
                        <TextInfo textWeight="bold" size={12} color="white">
                          {tipologia.titulo} | {tipologia.proyecto_nombre}
                        </TextInfo><br />
                        <TextInfo size={12} color="white">
                          Reserva #{resumenContrato.idReserva}
                        </TextInfo>
                      </div>
                    </div>
                  </div>
                </div>
                { contratoId == 0 && <Button color='btn-orange' onClick={generarContrato} className="btn mb-2" size="small" fullWidth={true}> GENERAR CONTRATO</Button>
                }
                {
                  spinnerEnabled ? (
                    <Spinner enabled={true} />
                  ) : contratoId > 0 && 
                    <>
                    <div className="infoFirmaPost">
                      <span>Hola</span>
                      <span>Estás a un paso de formar parte de nuestra gran comunidad LEVEL. Para facilitarte la vida, puedes generar el proceso completamente online, a continuación comenzarás la firma de tu contrato de arriendo”</span>
                    </div>
                      <Button
                        color="btn-gray"
                        size="small"
                        fullWidth={true}
                        className="btn mb-2"
                        onClick={() => {
                            dispatch(setSpinner(true))
                            dispatch(getContratoByte({ reservaid: resumenContrato.idReserva, contratoid: contratoId  }))
                          }
                        }
                      >
                        VER CONTRATO
                      </Button>
                      <Button color='btn-orange' onClick={generarFirma} className="btn mb-2" size="small" fullWidth={true}>FIRMAR CONTRATO</Button>
                    </> 
                }

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FirmarContrato;
