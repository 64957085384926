import React from 'react';
import { Slider } from '../ui/index';
import './styles/Slider.scss';
import LazyLoad from 'react-lazyload';
import useHomeData from '../../hooks/useHomeData';
import {
  HOME_DESK_IMAGE_HEIGHT,
  HOME_DESK_IMAGE_WIDTH
} from '../../config/constant';


const PROMO_INFO_ENABLED = false;


const Hero = React.memo(() => {

  const homeData = useHomeData();

  const handleClickLink = (link) => {
    if(link!=""){
      window.location.href = link
    }
  }

  return (
    <div className="container">
      <section id="corousel-principal">
        <Slider
          className="slider-header"
          itemsToShow={1}
          enableAutoPlay={true}
        >
          {
            homeData.bannersDesk.map((bannerDesk) => {
              return (
                <div className="item-slider" key={bannerDesk.id}>
                  <div className="overlay-black" style={{cursor: 'pointer'}} onClick={()=>handleClickLink(bannerDesk.alternativeText)}></div>
                  <LazyLoad>
                    <picture>
                      <img
                        src={bannerDesk.url}
                        alt={bannerDesk.alt}
                        style={{
                          width: HOME_DESK_IMAGE_WIDTH,
                          height: HOME_DESK_IMAGE_HEIGHT,
                          objectFit: 'cover',
                        }}
                      />
                    </picture>
                  </LazyLoad>
                  {
                    PROMO_INFO_ENABLED &&

                    <div className="row body-center-right">
                      <div className="card-slider">
                        <h2 className="card-title-slider">Solo por marzo</h2>
                        <div className="card-ticket">
                          <div className="itemCard-descuento">
                            <span>50% <small>dcto.</small></span>
                          </div>
                          <div className="itemCard-ticke-arriendo">
                            <p>En tu primer</p>
                            <span>mes de arriendo<sup>*</sup></span>
                          </div>
                        </div>
                        <div className="card-slider-footer">
                          <span>Estudios y 1 dormitorio desde</span>
                          <span>$265.000</span>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              );
            })
          }
        </Slider>
      </section>
    </div>
  );
});

export default Hero;
