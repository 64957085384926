import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { TIPO_ORDENAR } from '../config/constant';
import { setFiltroOrdenar } from '../redux/proyectosSlice';
import { Checkbox, TextInfo } from './ui/index';
import { dataLayerPush } from '../utils';


// Componente que muestra una lista de checkboxes con las opciones de ordenamiento, tal como están
// configuradas en la constante `TIPO_ORDENAR` en src/config/constant.js. Las checkboxes settean/resetean el
// filtro correspondiente de Redux (filtroOrdenar del slice proyectosSlice).
const OrdenarCheckboxes = ({ updatePlaceholder, defaultPlaceholder, hideDropdown }) => {

  const dispatch = useDispatch();
  const [ordenamientoCheckboxState, setOrdenamientoCheckboxState] = useState(Array(TIPO_ORDENAR.length).fill(false));

  const onOrdenamientoCheckboxChange = (event, index, tipoOrdenamiento) => {
    const checked = event.target.checked;

    // Puede haber como máximo 1 checkbox activo; puede no haber ningún checkbox activo
    const newOrdenamientoCheckboxState = Array(TIPO_ORDENAR.length).fill(false);
    newOrdenamientoCheckboxState[index] = checked;
    setOrdenamientoCheckboxState(newOrdenamientoCheckboxState);

    if (checked) {
      dispatch(setFiltroOrdenar(tipoOrdenamiento));

      if (updatePlaceholder) {
        updatePlaceholder(tipoOrdenamiento);
      }

      // Analytics
      dataLayerPush({
        event: 'click',
        ordenProyectos: tipoOrdenamiento,
        eventCategory: 'ordenar',
        eventAction: 'click',
        eventLabel: tipoOrdenamiento,
      });
    } else {
      dispatch(setFiltroOrdenar(null));

      if (updatePlaceholder) {
        updatePlaceholder(defaultPlaceholder);
      }
    }

    if (hideDropdown) {
      hideDropdown();
    }
  }

  const ordenarCheckboxes = TIPO_ORDENAR.map((tipoOrdenamiento, index) => {
    const isLastElement = index === TIPO_ORDENAR.length - 1;

    return (
      <Checkbox
        checked={ordenamientoCheckboxState[index]}
        onChange={(event) => onOrdenamientoCheckboxChange(event, index, tipoOrdenamiento)}
        key={tipoOrdenamiento}
        position="right"
        marginZero={isLastElement} >
        <TextInfo color="black">{tipoOrdenamiento}</TextInfo>
      </Checkbox>
    );
  });

  return (
    <>
      {ordenarCheckboxes}
    </>
  );
}

export default OrdenarCheckboxes;
