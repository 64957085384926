import React from 'react';
import './TitleFiltro.scss';


const TitleFiltro = (props) => {

  const { children, title, className = '' } = props;

  return (
    <div className={`title-filtro ${className}`}>
      <span className="title">{title}</span>
      {children}
    </div>
  );
}

export default TitleFiltro;
