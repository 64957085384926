import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import proyectos from './proyectosSlice';
import user from './userSlice';
import preevaluacion from './preevaluacionSlice';
import home from './homeSlice';
import nosotros from './nosotrosSlice';
import beneficios from './beneficiosSlice';
import comunidad from './comunidadSlice';
import preguntasFrecuentes from './preguntasFrecuentesSlice';


const reducers = combineReducers({
  proyectos,
  user,
  preevaluacion,
  home,
  nosotros,
  beneficios,
  comunidad,
  preguntasFrecuentes,
});

const PERSIST_VERSION = 0.1;

const persistConfig = {
  key: `arriendo-${PERSIST_VERSION}`,
  storage,
  // Por simpleza y para evitar posibles edge-cases peligrosos, persistimos solamente el
  // slice de user. El slice de preevaluacion no tiene sentido persistirlo, y si se
  // persiste el slice de proyectos hay que tener mucho cuidado y testear extensivamente
  // para asegurarte de que no exista algún use-case raro en donde no se fetcheen nunca
  // los proyectos o cosas así. Así que por el momento, keep it simple.
  blacklist: ['proyectos', 'preevaluacion', 'home', 'nosotros', 'beneficios', 'comunidad', 'preguntasFrecuentes'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  reducer: persistedReducer,
  // Desactivar redux-immutable-state-invariant. Es un performance killer (hace un deep check de
  // todo el estado cada vez que algo es modificado!) y de todas formas no lo necesitamos porque
  // @reduxjs/toolkit incluye Immer que se encarga de que no hagamos manipulaciones manuales de estado.
  // Más info en:
  // https://github.com/leoasis/redux-immutable-state-invariant
  // https://github.com/reduxjs/redux-toolkit/issues/415
  middleware: [...getDefaultMiddleware({ immutableCheck: false, serializableCheck: false })],
});
