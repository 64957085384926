import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactGA from "react-ga4";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import Routes from './Routes';
import store from './redux/store';
import './static/styles/app.scss';
// eslint-disable-next-line no-unused-vars
import { setServiceWorkerFoundNewContent, BACKEND_BASE_URL, GOOGLE_ANALYTICS } from './config/constant';

const persistor = persistStore(store);

// GC initialize
ReactGA.initialize(GOOGLE_ANALYTICS);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Routes />
        </Router>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
