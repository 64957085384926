import React from 'react';
import Carousel from 'react-elastic-carousel';
import styles from './styles/SliderHeader.module.scss';
import './styles/SliderHeaderCustomCarousel.scss';
import { Button } from './ui/index';


// Componente que muestra un slider con un header y botones de atrás/adelante a la derecha
// del header
const SliderHeader = ({ children, itemsToScroll = 1, itemsToShow = 1, header }) => {

  let carousel;

  const nextSlide = () => {
    carousel.slideNext();
  }

  const previousSlide = () => {
    carousel.slidePrev();
  }


  return (
    <div className={styles.topContainer}>
      <div className={styles.carouselContainer}>
        <h1 className={styles.header}>{header}</h1>
       
        <Button
          style={{
            float: 'right',
            position: 'relative',
            left: '-18px',
          }}
          color='btn-white-gray'
          size="small-icon"
          startIcon="icon-button-arrow-ight"
          onClick={nextSlide}
        />

        <Button
          style={{
            float: 'right',
            position: 'relative',
            left: '-28px',
          }}
          color='btn-white-gray'
          size="small-icon"
          startIcon="icon-left-arrow"
          onClick={previousSlide}
        />

        <Carousel
          ref={el => carousel = el}
          itemsToScroll={itemsToScroll}
          itemsToShow={itemsToShow}
          showArrows={false}
          enableSwipe={false}
          className="sliderHeaderCustomCarousel"
        >
          {children}
        </Carousel>

      </div>
    </div>
  );
}

export default SliderHeader;
