import React from 'react';
import PropTypes from 'prop-types';
import './ButtonDropdown.scss';
import useResize from '../../../hooks/useResize';


const ButtonDropdown = props => {
  const {
    enableArrowAnimation = false,
    fullWidth = false,
    size = '',
    color = '',
    children,
    placeholder = '',
    preventMainWindowScrolling = true,
    innerRef,
    ...other
  } = props;

  const width = fullWidth ? 'full-width' : '';
  const [rizeWidth] = useResize();

  const onButtonClick = (event) => {
    event.preventDefault();

    const item = event.target;

    const dropdownCurrent = item.nextElementSibling;
    const allDropdownToggle = document.querySelector('.dropdown.show');
    const overlayDropdow = document.querySelector('.overlayDropdown');
    const header = document.querySelector('.header');

    if (allDropdownToggle) {
      if (dropdownCurrent.tabIndex !== allDropdownToggle.tabIndex) {
        allDropdownToggle.removeClass('show');
      }
    }

    if (enableArrowAnimation) {
      item.toggleClass('active');
    }

    dropdownCurrent.toggleClass('show');

    if (rizeWidth < 768) {
      if (overlayDropdow) {
        overlayDropdow.toggleClass('show');
      }
      header.toggleClass('overlay');

      // Previene scrolling de la ventana principal
      if (preventMainWindowScrolling) {
        const body = document.getElementsByTagName("body")[0];
        body.style.position = 'fixed';
      }
    } else {
      //window.scrollBy({top:dropdownCurrent.clientHeight-100, behavior: 'smooth'});
      if (overlayDropdow) {
        overlayDropdow.toggleClass('show');
      }
    }

    if (overlayDropdow) {
      overlayDropdow.addEventListener('click', (e) => {
        if (enableArrowAnimation) {
          item.removeClass('active');
        }

        overlayDropdow.removeClass('show');
        header.removeClass('overlay');
        dropdownCurrent.removeClass('show');

        // Restaura scrolling de la ventana principal
        if (preventMainWindowScrolling) {
          const body = document.getElementsByTagName("body")[0];
          body.style.position = 'initial';
        }
      });
    }
  }

  return (
    <>
      <div className={`form-buttonDropdown ${width}`}>
        <button
          ref={innerRef}
          onClick={onButtonClick}
          type="button"
          className={`buttonDropdown ${width} ${color} ${size}`}
          {...other}
        >
          {placeholder}
        </button>
        {children}
      </div>
    </>
  );
}

ButtonDropdown.propTypes = {
  color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary', 'btn-white-gray']),
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.string,
  size: PropTypes.string
}

export default ButtonDropdown;
