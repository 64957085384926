import React from 'react';
import { useSelector } from 'react-redux';
import { selectProyectosFuturos } from '../../redux/proyectosSlice';
import { Button, TextInfo } from '../ui/index';
import Carousel from 'react-elastic-carousel';
import './styles/ProximosEdificios.scss';
import {
  getDormitoriosAndBanosString,
  getPlaceUrl,
  getPortadaData
} from '../../utils';
import { useHistory } from 'react-router-dom';


const ProximosEdificios = () => {

  const history = useHistory();

  // Usado para referenciar el carousel
  let carouselProximo;

  const proyectosFuturos = useSelector(selectProyectosFuturos);

  // Construir slider items loopeando sobre los proyectos futuros
  const sliderItems = proyectosFuturos.map((proy) => {
    const portadaData = getPortadaData(proy);

    // Recorrer tipologías del proyecto para encontrar número mínimo y máximo de dormitorios
    let dormitorios = [];

    proy.tipologias.forEach((tipo) => {
      dormitorios.push(tipo.dormitorios);
    });

    const bothStrs = getDormitoriosAndBanosString(proy, false);
    const dormitoriosStr = bothStrs.dormitoriosStr;

    return (
      <div onClick={() => history.push(getPlaceUrl(proy))} key={proy._id} className="item-slider placeNext">
        <div className="header-title">
          <span>INAGURACIÓN 2021</span>
        </div>
        <div className="image" style={{ backgroundImage: `url(${portadaData.url})` }}>

        </div>
        <div className="body">
          <span className="title">{proy.proyecto_nombre}</span>
          <div className="footer">
            <TextInfo iconColor="#FF6D00" iconName="icon-location-pin" color="white">{proy.direccion}</TextInfo>
            <TextInfo iconColor="#FFFFFF" iconName="icon-bed" color="white">{dormitoriosStr}</TextInfo>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="container">
      <section className="proximoEdificios">
        <div className="row">
          <div className="proximos-edificios">
            <h2 className="title">Próximos edificios</h2>
            <div className="option-slider">
              <Button color='btn-white-gray' size="small-icon" startIcon="icon-left-arrow" onClick={() => carouselProximo.slidePrev()}></Button>
              <Button color='btn-white-gray' size="small-icon" startIcon="icon-button-arrow-ight" onClick={() => carouselProximo.slideNext()}></Button>
            </div>
          </div>

          <Carousel
            ref={ref => (carouselProximo = ref)}
            itemsToScroll={3}
            itemsToShow={3}
            enableAutoPlay={false}
            showArrows={false}
            autoPlaySpeed={5000}
            className="slider-edificio-proximo">
            {sliderItems}
            
          </Carousel>

        </div>
      </section>
    </div>
  );
}


export default ProximosEdificios;
