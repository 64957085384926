import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getComunidadData,
  selectAllComunidadData,
  INITIAL_GET_COMUNIDAD_DATA_STATUS
} from '../redux/comunidadSlice';


// Hook de datos de la página "Comunidad". Obtiene los datos de la página "Comunidad" si no fueron
// obtenidos previamente.
// Devuelve un objeto con toda la data del slice de Redux correspondiente.
const useComunidadData = () => {

  const dispatch = useDispatch();
  const comunidadData = useSelector(selectAllComunidadData);

  useEffect(() => {
    if (comunidadData.getComunidadDataStatus === INITIAL_GET_COMUNIDAD_DATA_STATUS) {
      dispatch(getComunidadData());
    }
  }, [comunidadData.getComunidadDataStatus, dispatch]);

  return comunidadData;
}

export default useComunidadData;
