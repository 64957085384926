import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { isValidPassword } from '../validation';
import { Button, Icon, Input, Modal } from './ui/index';
import { getInvalidPasswordString } from '../validation';
import { useDispatch } from 'react-redux';
import { changePassword } from '../redux/userSlice';


const ModalChangePassword = ({ show, close }) => {

  const dispatch = useDispatch();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const onCambiarContrasenaClick = () => {
    if (password !== confirmPassword) {
      toast.error('Las contraseñas no coinciden!');
      return;
    }

    if (!isValidPassword(password) || !isValidPassword(confirmPassword)) {
      toast.error(getInvalidPasswordString());
      return;
    }

    const userData = {
      password,
    };

    dispatch(changePassword(userData));

    if (close) {
      close();
    }
  }


  return (
    <Modal show={show} close={close}>
      <div className="modal-content modal-login fade">
        <div className="header-modal">
          <Icon onClick={close} name="icon-close" size="14" />
        </div>
        <div className="body-modal">
          <div className="form-content">
            <div className="formLogin">
              <form>
                <Input
                  type="password"
                  name="password-1"
                  typeStyle="filtro-white"
                  fullWidth={true}
                  placeholder="Nueva constraseña"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  formGroupStyle={{ margin: "0" }}
                />

                <Input
                  type="password"
                  name="password-2"
                  typeStyle="filtro-white"
                  fullWidth={true}
                  placeholder="Confirmar nueva constraseña"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  formGroupStyle={{
                    margin: '0',
                  }}
                />
              </form>
            </div>
            <Button
              onClick={onCambiarContrasenaClick}
              className="btn btn-submit"
              color="btn-orange"
              size="small"
              style={{
                marginTop: '20px',
                marginBottom: '20px',
              }}
            >
              CAMBIAR CONTRASEÑA
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalChangePassword;
