import React from 'react';
import { TextInfo } from '../ui/index';
import styles from './styles/PlaceCard.module.css';


const CARD_WIDTH = 200;
const CARD_HEIGHT = 120;
const ICON_WIDTH = 32;

// Componente con información acerca de un lugar de Google Maps. Muestra el nombre
// del lugar y su dirección.
// `show` determina si el componente es mostrado o no, y `close` debe ser una función
// para cerrar el componente (i.e. setear `show` en false).
const PlaceCard = ({ show, close, name, address }) => {
  const containerStyle = {
    width: `${CARD_WIDTH}px`,
    height: `${CARD_HEIGHT}px`,
    top: `-${CARD_HEIGHT + 5}px`,
    left: `-${(CARD_WIDTH / 2) - (ICON_WIDTH / 2)}px`,
  };

  return (
    <>
      {
        show &&

        <div className={styles.container} style={containerStyle}>
          <TextInfo onClick={close} textWeight="bold" color="black" size="20" style={{ width: '100%', cursor: 'pointer' }}>X</TextInfo>
          <br />
          <br />
          <TextInfo textWeight="bold" color="black" size="18">{name}</TextInfo>
          <br />
          <br />
          <TextInfo iconColor="#FF6D00" className="lugar" iconName="icon-location-pin" size="14">{address}</TextInfo>
        </div>
      }
    </>
  );
}

export default PlaceCard;
