import React from 'react';
import styles from './styles/IngresoMantencionFrame.module.scss';


const LEVEL_MANTENCION_HEIGHT = '791px';


const IngresoMantencionFrame = ({ match }) => {

  const page = match.params.page;
  const pageUrl = page ? `https://apps1.buildingclerk.cl/postventa/formularios/level/mantenimiento.html` : 'https://apps1.buildingclerk.cl/postventa/formularios/level';

  return (
    <div className={styles.container}>
      <table border="0" cellspacing="0" cellpadding="0" id="iframe_datview" style={{ width: '100%' }}>
        <iframe
          title="level-ingreso-mantencion-form"
          src={pageUrl}
          marginwidth="0" marginheight="0" frameborder="0"
          style={{ height: LEVEL_MANTENCION_HEIGHT, width: '100%' }}></iframe>
      </table>
    </div>
  );
}

export default IngresoMantencionFrame;
