import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from '../components/Layout';
import { getInvalidPasswordString, isValidPassword } from '../validation';
import { BACKEND_BASE_URL } from '../config/constant';
import styles from '../components/styles/ResetPassword.module.scss';
import { Button, Input } from '../components/ui/index';
import { dataLayerPush } from '../utils';


const ResetPassword = ({ match }) => {

  const [firstPassword, setFirstPassword] = useState('');
  const [secondPassword, setSecondPassword] = useState('');
  const pageCurrent = match.path;
  const location = useLocation();
  const { search } = location;
  let code = '';

  if (search.startsWith('?code=')) {
    code = search.slice('?code='.length);
    console.log(`code: ${code}`);
  }

  const onResetearContrasena = (event) => {
    event.preventDefault();

    if (firstPassword !== secondPassword) {
      toast.error('Las contraseñas no coinciden!');
      return;
    }

    if (!isValidPassword(firstPassword)) {
      toast.error(getInvalidPasswordString());
      return;
    }

    axios
      .post(`${BACKEND_BASE_URL}/auth/reset-password`, {
        code,
        password: firstPassword,
        passwordConfirmation: secondPassword,
      })
      .then(() => {
        toast.success('Contraseña reseteada!');
      })
      .catch((error) => {
        toast.error('No se pudo resetear la contraseña!');
        console.log(`No se pudo resetear la contraseña: ${error}`);
      });
  }

  const onFirstPasswordChange = (event) => {
    setFirstPassword(event.target.value);
  }

  const onSecondPasswordChange = (event) => {
    setSecondPassword(event.target.value);
  }

  // Analytics
  useEffect(() => {
    dataLayerPush({
      event: 'vpv',
      page: location.pathname,
      section: 'resetear contraseña',
      url: window.location.href,
    });
  }, [location.pathname]);


  return (
    <Layout pageCurrent={pageCurrent}>
      <div className={`container ${styles.containerCustom}`} style={{}}>
        <h1>Resetear contraseña</h1>
        <form>
          <div className={styles.inputBox}>
            <Input value={firstPassword} onChange={onFirstPasswordChange} type="password"
              placeholder="Ingresa tu nueva contraseña" fullWidth={true} />
            <Input value={secondPassword} onChange={onSecondPasswordChange} type="password"
              placeholder="Repite tu nueva contraseña" fullWidth={true} />
          </div>
          <div className={styles.buttonBox}>
            <Button onClick={onResetearContrasena} type="submit" color='btn-orange' size="small" fullWidth={true}>RESETEAR</Button>
          </div>
        </form>
      </div>
    </Layout>
  );
}

export default ResetPassword;
