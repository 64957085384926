/** 
  Agragar Clase a una etiqueta
  @param: nombre de la classe a agregar.
**/
Node.prototype.addClass = function(className) {
    this.classList.add(className);
  };
  /** 
    Elimina un Clase a una etiqueta
    @param: nombre de la classe a agregar.
  **/
  Node.prototype.removeClass = function(className) {
    this.classList.remove(className);
  };
  /** 
    Agraga o elimina una clase a una etiqueta
    @param: nombre de la classe .
  **/
  Node.prototype.toggleClass = function(className) {
    this.classList.contains(className)
      ? this.classList.remove(className)
      : this.classList.add(className);
  };


