import React from 'react';


// Devuelve el precio mínimo de las bodegas del proyecto
const PrecioMensualBodegas = ({ proyecto }) => {

  const precio = proyecto.precioMinBodegas;

  return (
    <span>${precio.toLocaleString()}</span>
  );
}

export default PrecioMensualBodegas;
