import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { BREAKPOINT_MOBILE } from '../../../../config/constant';
import useResize from '../../../../hooks/useResize';
import { selectReserva, setReserva } from '../../../../redux/preevaluacionSlice';
import { selectSecundariosBodegas, selectSecundariosEstacionamientoAuto, selectSecundariosEstacionamientoAutoApi, selectSecundariosEstacionamientoMoto }
  from '../../../../redux/proyectosSlice';
import { copyArray, copyArrayToSelectArray, copyArrayUniqueValues } from '../../../../utils';
import { Button, Checkbox, Icon, Select, TextInfo } from '../../../ui/index';
import './styles/SeleccionaSecundario.scss';


const DEFAULT_CANTIDAD_FORMS = 1;
const DEFAULT_PISO = '';
const DEFAULT_DISTANCIA_ASCENSOR = '';
const DEFAULT_SECUNDARIO_NUM = '';
const DEFAULT_SECUNDARIO_ID = { id: '', tipo: '' };
const AUTO = 0;
const MOTO = 1;
const TYPE = {
  ESTACIONAMIENTO: 'estacionamiento',
  BODEGA: 'bodega',
};

const indexToTipoEstacionamientoStr = (index) => {
  switch (index) {
    case AUTO:
      return 'auto';

    case MOTO:
      return 'moto';

    default:
      return '';
  }
}


const InnerSecundarioForm = ({
  proyecto, type, formIndex, onConfirmarClick,
  agregarSecundario, removerSecundario,
  tiposEstacionamientoCheckboxState, updateTiposEstacionamientoCheckboxState,
  tiposEstacionamiento, updateTiposEstacionamiento,
  pisos, updatePisos,
  distanciasAscensor, updateDistanciasAscensor,
  secundariosNum, updateSecundariosNum,
  secundariosIds, updateSecundariosIds, isCotizacion, currentCotizacion }) => {
  const selectSecundario = secundariosIds.filter((item) => item.tipo == 'auto' || item.tipo == 'moto' || item.tipo == 'bodega')

  const [rizeWidth] = useResize();
  const proyectoId = isCotizacion ? currentCotizacion.proyecto.id : proyecto.id
  // Secundarios
/*   const secundariosEstacionamientoAuto = useSelector(selectSecundariosEstacionamientoAuto(proyectoId));
  const secundariosEstacionamientoMoto = useSelector(selectSecundariosEstacionamientoMoto(proyectoId));
  const secundariosBodegas = useSelector(selectSecundariosBodegas(proyectoId));
 */

  const [secundariosEstacionamientoAuto, setSecundariosEstacionamientoAuto] = useState([]);
  const [secundariosEstacionamientoMoto, setSecundariosEstacionamientoMoto] = useState([])
  const [secundariosBodegas, setSecundariosBodegas] = useState([])
  const [secundariosNumDataList, setSecundariosNumDataList] = useState([]);

  const obtenerSegundario = async () => {
    const resultSecundario = await selectSecundariosEstacionamientoAutoApi(currentCotizacion.tipologia.proyecto_nombre, 0);

    const secundariosEstacionamientoAutoList = resultSecundario.filter((item) => item.NOMBRETIPOPRODUCTO === 'ESTACIONAMIENTO CUBIERTO')
    /* secundariosEstacionamientoAutoList.map((item) => {
      const secundario = currentCotizacion.proyecto.secundarios.filter((itemPro) => (itemPro.nivel_numero === item.NIVELNUMERO && itemPro.nombre === 'ESTACIONAMIENTO CUBIERTO'));
      item.id = secundario[0].id
    }) */
    const secundariosEstacionamientoMotoList = resultSecundario.filter((item) => (item.NOMBRETIPOPRODUCTO === 'MOTO ESTACIONAMIENTO') ||  (item.NOMBRETIPOPRODUCTO === 'ESTACIONAMIENTO MOTOS'))
   /*  secundariosEstacionamientoMotoList.map((item) => {
      const secundario = currentCotizacion.proyecto.secundarios.filter((itemPro) => (itemPro.nivel_numero === item.NIVELNUMERO && (itemPro.nombre === 'MOTO ESTACIONAMIENTO') ||  (item.nombre === 'ESTACIONAMIENTO MOTOS')));
      item.id = secundario[0].id
    }) */
    const secundariosBodegasList = resultSecundario.filter((item) => item.NOMBRETIPOPRODUCTO === 'BODEGA')
   /*  secundariosBodegasList.map((item) => {
      const secundario = currentCotizacion.proyecto.secundarios.filter((itemPro) => (itemPro.nivel_numero === item.NIVELNUMERO && itemPro.nombre === 'BODEGA'));
      item.id = secundario[0].id
    }) */

    setSecundariosEstacionamientoAuto(secundariosEstacionamientoAutoList);
    setSecundariosEstacionamientoMoto(secundariosEstacionamientoMotoList);
    setSecundariosBodegas(secundariosBodegasList);

    estacionamientosAutoPisos.current = getSecundarioUniquePisos(secundariosEstacionamientoAutoList);
    estacionamientosMotoPisos.current = getSecundarioUniquePisos(secundariosEstacionamientoMotoList);
    bodegasPisos.current = getSecundarioUniquePisos(secundariosBodegasList);

    // Los pisos de las bodegas los setteamos de una acá porque no dependen de un radiobutton
    if (type === TYPE.BODEGA) {
      pisosData.current = copyArrayToSelectArray(bodegasPisos.current);
    } 

  }


  // Pisos de auto/moto/bodega
  const estacionamientosAutoPisos = useRef([]);
  const estacionamientosMotoPisos = useRef([]);
  const bodegasPisos = useRef([]);

  // Datos para los selects
  const pisosData = useRef([]);
  const distanciasData = useRef([]);
  const secundariosNumData = useRef([]);


  // Toma un array de secundarios y devuelve los pisos únicos, ordenados de menor a mayor
  const getSecundarioUniqueEstacionamiento = (secundario) => {
    let uniquePisos = [];

    for (let i = 0; i < secundario.length; i++) {
      uniquePisos.push(secundario[i].PRODUCTOSECUNDARIONUMERO);
    }

    uniquePisos = copyArrayUniqueValues(uniquePisos);
    uniquePisos.sort((a, b) => a - b);

    return uniquePisos;
  }
  // Toma un array de secundarios y devuelve los pisos únicos, ordenados de menor a mayor
  const getSecundarioUniquePisos = (secundario) => {
    let uniquePisos = [];

    for (let i = 0; i < secundario.length; i++) {
      uniquePisos.push(secundario[i].NIVELNUMERO);
    }

    uniquePisos = copyArrayUniqueValues(uniquePisos);
    uniquePisos.sort((a, b) => a - b);

    return uniquePisos;
  }

  // Actualizar los pisos cuando cambian los secundarios
  useEffect(() => {
    obtenerSegundario();
  }, [type, tiposEstacionamiento]);

  // Devuelve el secundario actual, dependiendo del tipo de SeleccionaSecundario y el tipo de estacionamiento (si
  // corresponde)
  const getCurrentSecundarios = () => {
    if (type === TYPE.BODEGA) {
      return secundariosBodegas;
    }

    switch (tiposEstacionamiento[formIndex]) {
      case 'auto':
        return secundariosEstacionamientoAuto;

      case 'moto':
        return secundariosEstacionamientoMoto;

      default:
        console.log(`SeleccionaSecundario: tiposEstacionamiento[formIndex] inválido`);
        break;
    }
  }

  // Resetea los pisos (i.e. el select queda solo con el placeholder)
  const resetPiso = () => {
    pisosData.current = [];
    setSecundariosNumDataList([]);
    /* const newPisos = copyArray(pisos);
    newPisos[formIndex] = DEFAULT_PISO; */
    //updatePisos(newPisos);
  }

  // Resetea las distancias
  const resetDistancia = () => {
    distanciasData.current = [];
    const newDistancias = copyArray(distanciasAscensor);
    newDistancias[formIndex] = DEFAULT_DISTANCIA_ASCENSOR;
    updateDistanciasAscensor(newDistancias);
  }

  // Resetea los números de secundario
  const resetSecundariosNum = () => {
    secundariosNumData.current = [];
    const newSecundariosNum = copyArray(secundariosNum);
    newSecundariosNum[formIndex] = DEFAULT_SECUNDARIO_NUM;
    updateSecundariosNum(newSecundariosNum);
  }

  // Resetea los ids de secundario
  const resetSecundariosIds = () => {
    const newSecundariosIds = copyArray(secundariosIds);
    newSecundariosIds[formIndex] = DEFAULT_SECUNDARIO_ID;
    updateSecundariosIds(newSecundariosIds);
  }

  const onEstacionamientoCheckboxChange = (event, index) => {
    resetPiso();
    resetDistancia();
    resetSecundariosNum();
    resetSecundariosIds();

    // Actualizar estado de checkboxes
    const newCheckboxState = copyArray(tiposEstacionamientoCheckboxState);
    newCheckboxState[formIndex] = [false, false];
    newCheckboxState[formIndex][index] = event.target.checked;  // todos deschequeados es válido
    updateTiposEstacionamientoCheckboxState(newCheckboxState);

    // Actualizar tipo de estacionamiento
    const newTiposEstacionamiento = copyArray(tiposEstacionamiento);
    newTiposEstacionamiento[formIndex] = event.target.checked ? indexToTipoEstacionamientoStr(index) : '';
    updateTiposEstacionamiento(newTiposEstacionamiento);

    // El radiobutton clickeado fue deschequeado: no hay que mostrar ningún piso
    if (!event.target.checked) {
      pisosData.current = [];
      return;
    }

    // Un radiobutton fue chequeado, mostrar los pisos correspondientes
    switch (index) {
      case AUTO:
        pisosData.current = copyArrayToSelectArray(estacionamientosAutoPisos.current);
        break;

      case MOTO:
        pisosData.current = copyArrayToSelectArray(estacionamientosMotoPisos.current);
        break;

      default:
        console.log(`SeleccionaSecundario: index "${index}" desconocido`);
        break;
    }
  }

  const onPisoChange = async (event) => {
    let newPiso = parseInt(event.target.value, 10);

    // Manejar caso en donde el usuario selecciona el placeholder
    if (isNaN(newPiso)) {
      newPiso = DEFAULT_PISO;
    }

    updatePisos(newPiso);

    const resultSecundario = await selectSecundariosEstacionamientoAutoApi(currentCotizacion.tipologia.proyecto_nombre, true, newPiso);
    let listaEstacioaniento = [];
    if(type === 'estacionamiento'){
      if(tiposEstacionamientoCheckboxState[0]){
        listaEstacioaniento= resultSecundario.filter((item) => item.NOMBRETIPOPRODUCTO === 'ESTACIONAMIENTO CUBIERTO')
        
      } else {
        listaEstacioaniento = resultSecundario.filter((item) => (item.NOMBRETIPOPRODUCTO === 'MOTO ESTACIONAMIENTO') ||  (item.NOMBRETIPOPRODUCTO === 'ESTACIONAMIENTO MOTOS'))
      }
    } else {
      listaEstacioaniento= resultSecundario.filter((item) => item.NOMBRETIPOPRODUCTO === 'BODEGA')

    }
    
    listaEstacioaniento.sort((a, b) => a - b);

    let listEstaFiltro = getSecundarioUniqueEstacionamiento(listaEstacioaniento);

  
    const listSelect = copyArrayToSelectArray(listEstaFiltro);
    setSecundariosNumDataList(listSelect);

    /* const newPisoArr = copyArray(pisos);
    newPisoArr[formIndex] = newPiso;
    updatePisos(newPisoArr);

    if (newPiso === DEFAULT_PISO) {
      resetDistancia();
      resetSecundariosNum();
      resetSecundariosIds();
      return;
    }

    const secundarios = getCurrentSecundarios(); */

    // Nos quedamos con los números de producto (producto_numero) de los secundarios que:
    // 1) están en el piso actual, 2) tienen distancia igual a newDistancia, 3) están disponibles
    /* let productosArr = [];

    for (let i = 0; i < secundarios.length; i++) {
      const currSecundario = secundarios[i];

      if (currSecundario.nivel_numero === pisos[formIndex] && currSecundario.estado === 'DISPONIBLE') {
        productosArr.push(currSecundario.producto_numero);
      }
    }

    productosArr = copyArrayUniqueValues(productosArr);
    productosArr.sort((a, b) => a - b);

    secundariosNumData.current = copyArrayToSelectArray(productosArr); */

    /* // Nos quedamos con las distancias de los secundarios que están en el piso actual
    let newDistancias = [];

    for (let i = 0; i < secundarios.length; i++) {
      const currSecundario = secundarios[i];

      if (currSecundario.nivel_numero === newPiso) {
        if (currSecundario.distancia_ascensor) {
          newDistancias.push(currSecundario.distancia_ascensor);
        }
      }
    }

    newDistancias = copyArrayUniqueValues(newDistancias);
    newDistancias.sort((a, b) => a - b);

    distanciasData.current = copyArrayToSelectArray(newDistancias); */
  }

  const onDistanciaChange = (event) => {
    resetSecundariosNum();
    resetSecundariosIds();

    let newDistancia = parseInt(event.target.value, 10);

    // Manejar caso en donde el usuario selecciona el placeholder
    if (isNaN(newDistancia)) {
      newDistancia = DEFAULT_DISTANCIA_ASCENSOR;
    }

    const newDistanciaArr = copyArray(distanciasAscensor);
    newDistanciaArr[formIndex] = newDistancia;
    updateDistanciasAscensor(newDistanciaArr);

    // Actualizar números de productos (secundariosNum) si newDistancia no es el default
    if (newDistancia === DEFAULT_PISO) {
      resetSecundariosNum();
      resetSecundariosIds();
      return;
    }

    const secundarios = getCurrentSecundarios();

    // Nos quedamos con los números de producto (producto_numero) de los secundarios que:
    // 1) están en el piso actual, 2) tienen distancia igual a newDistancia, 3) están disponibles
    let productosArr = [];

    for (let i = 0; i < secundarios.length; i++) {
      const currSecundario = secundarios[i];

      if (currSecundario.nivel_numero === pisos[formIndex] && currSecundario.distancia_ascensor &&
        currSecundario.distancia_ascensor === newDistancia && currSecundario.estado === 'DISPONIBLE') {
        productosArr.push(currSecundario.producto_numero);
      }
    }

    productosArr = copyArrayUniqueValues(productosArr);
    productosArr.sort((a, b) => a - b);

    secundariosNumData.current = copyArrayToSelectArray(productosArr);
  }

  const onEstacionamientoNumChange = (event) => {
    let newValue = parseInt(event.target.value, 10);

    // Manejar caso en donde el usuario selecciona el placeholder
    if (isNaN(newValue)) {
      newValue = DEFAULT_SECUNDARIO_NUM;
    }

    // Actualizar secundariosNum
    const newSecundariosNum = copyArray(secundariosNum);
    newSecundariosNum[formIndex] = newValue;
    updateSecundariosNum(newSecundariosNum);

    if (newValue === DEFAULT_SECUNDARIO_NUM) {
      return;
    }

    const secundarios = getCurrentSecundarios();

    let newSecundarioId = null;

    // Buscar id del secundario y actualizarlo
    for (let i = 0; i < secundarios.length; i++) {
      const currSecundario = secundarios[i];

      if (currSecundario.PRODUCTOSECUNDARIONUMERO === newValue) {
        newSecundarioId = currSecundario.PRODUCTOSECUNDARIOID;
        break;
      }
    }

    // Actualizar secundariosIds
    const tipo = type === TYPE.ESTACIONAMIENTO ? tiposEstacionamiento[formIndex] : TYPE.BODEGA;
    const newSecundariosIds = copyArray(secundariosIds);
    newSecundariosIds[formIndex] = { id: newSecundarioId, tipo, };
    updateSecundariosIds(newSecundariosIds);
  }

  const agregarStr = `+ agregar ${type}`;
  const removerStr = `- remover ${type}`;

  // Responsive styles/classnames
  const inputContainerClass = rizeWidth <= BREAKPOINT_MOBILE ? '' : 'input-container-flex';
  const checkboxWidth = rizeWidth <= BREAKPOINT_MOBILE ? '40%' : '';
  const fullWidth = rizeWidth <= BREAKPOINT_MOBILE;
  const btnStyle = rizeWidth <= BREAKPOINT_MOBILE ? { marginBottom: '100px' } : {};

  return (
    <>
      <div id="selecciona-estacionamiento" className="form-layout">
        <div className="layout-group full">
          {
            type === TYPE.ESTACIONAMIENTO &&

            <div className={inputContainerClass}>
              <TextInfo
                className="space-right"
                textWeight="bold"
                size={14}
                color="black"
              >
                Tipo de estacionamiento
              </TextInfo>
              <Checkbox
                onChange={(e) => onEstacionamientoCheckboxChange(e, AUTO)}
                checked={tiposEstacionamientoCheckboxState[formIndex][AUTO]}
                position="right"
                width={checkboxWidth}
                marginTop={`${rizeWidth <= BREAKPOINT_MOBILE ? '20px' : ''}`}
              >
                <TextInfo color="black">Auto</TextInfo>
              </Checkbox>
              <Checkbox
                onChange={(e) => onEstacionamientoCheckboxChange(e, MOTO)}
                checked={tiposEstacionamientoCheckboxState[formIndex][MOTO]}
                width={checkboxWidth}
                position="right"
              >
                <TextInfo color="black">Moto</TextInfo>
              </Checkbox>
            </div>
          }
          {
            type === TYPE.BODEGA &&

            <div className="input-container-flex">
              <TextInfo className="space-right" textWeight="bold" size={14} color="black">Bodega</TextInfo>
            </div>
          }
          <div className={`${inputContainerClass} flex-between flex-wrap`}>
            <Select
              value={pisos[formIndex]}
              data={pisosData.current}
              onChange={onPisoChange}
              placeholder="Selecciona un piso"
              className="bold"
              typeStyle="extra-small"
              fullWidth={true}
              halfWidth={!fullWidth}
            />
            {/* <Select
              value={distanciasAscensor[formIndex]}
              data={distanciasData.current}
              onChange={onDistanciaChange}
              placeholder="Distancia al ascensor (m)"
              className="bold"
              typeStyle="extra-small"
              fullWidth={true}
              halfWidth={!fullWidth}
            /> */}
            <Select
              value={secundariosNum[formIndex]}
              data={secundariosNumDataList}
              onChange={onEstacionamientoNumChange}
              placeholder= {type == 'estacionamiento' ? 'N°. Estacionamiento':'N°. Bodega'}
              className="bold"
              typeStyle="extra-small"
              fullWidth={true}
              halfWidth={!fullWidth}
            />
          </div>
        </div>
      </div>
      {agregarSecundario && <Button onClick={agregarSecundario} className="btn btn-gray-oscuro bold mb-1" size="extra-small" fullWidth={true}>{agregarStr}</Button>}
      {removerSecundario && <Button onClick={removerSecundario} className="btn btn-gray-oscuro bold mb-1" size="extra-small" fullWidth={true}>{removerStr}</Button>}
      {onConfirmarClick && <Button onClick={onConfirmarClick} className="btn bold" color='btn-outline-orange' size="extra-small" fullWidth={true} style={btnStyle} disabled={selectSecundario == 0}>SIGUIENTE</Button>}
    </>
  );
}


// Componente para solicitar datos de secundario (estacionamientos auto/moto y bodegas).
// Guarda los datos en el objeto 'reserva' del slice preevaluacionSlice.
const SeleccionaSecundario = ({ proyecto, type, active, checked, onHeaderClick, nextForm , isCotizacion, currentCotizacion}) => {

  const [rizeWidth] = useResize();
  const dispatch = useDispatch();
  const reserva = useSelector(selectReserva);

  const [cantidadForms, setCantidadForms] = useState(DEFAULT_CANTIDAD_FORMS);
  const [tiposEstacionamientoCheckboxState, setTiposEstacionamientoCheckboxState] = useState(Array(DEFAULT_CANTIDAD_FORMS).fill([false, false]));
  const [tiposEstacionamiento, setTiposEstacionamiento] = useState(Array(DEFAULT_CANTIDAD_FORMS).fill(''));
  const [pisos, setPisos] = useState(Array(DEFAULT_CANTIDAD_FORMS).fill(DEFAULT_PISO));
  const [distanciasAscensor, setDistanciasAscensor] = useState(Array(DEFAULT_CANTIDAD_FORMS).fill(DEFAULT_DISTANCIA_ASCENSOR));
  const [secundariosNum, setSecundariosNum] = useState(Array(DEFAULT_CANTIDAD_FORMS).fill(DEFAULT_SECUNDARIO_NUM));
  const [secundariosIds, setSecundariosIds] = useState(Array(DEFAULT_CANTIDAD_FORMS).fill(DEFAULT_SECUNDARIO_ID));


  const agregarSecundario = () => {
    setCantidadForms(cantidadForms + 1);

    // Agregar un elemento más a todos los arrays
    const newTiposEstacionamientoCheckboxState = copyArray(tiposEstacionamientoCheckboxState);
    newTiposEstacionamientoCheckboxState.push([false, false]);
    setTiposEstacionamientoCheckboxState(newTiposEstacionamientoCheckboxState);

    const newTiposEstacionamiento = copyArray(tiposEstacionamiento);
    newTiposEstacionamiento.push('');
    setTiposEstacionamiento(newTiposEstacionamiento);

    //const newPisos = copyArray(pisos);
    //newPisos.push(DEFAULT_PISO);
    //setPisos(newPisos);

    /* const newDistanciasAscensor = copyArray(distanciasAscensor);
    newDistanciasAscensor.push(DEFAULT_DISTANCIA_ASCENSOR);
    setDistanciasAscensor(newDistanciasAscensor);*/

    const newSecundariosNum = copyArray(secundariosNum);
    newSecundariosNum.push(DEFAULT_SECUNDARIO_NUM);
    setSecundariosNum(newSecundariosNum);

    const newSecundariosIds = copyArrayUniqueValues(secundariosIds);
    newSecundariosIds.push(DEFAULT_SECUNDARIO_ID);
    setSecundariosIds(newSecundariosIds);
  }

  const removerSecundario = () => {
    const newCantidadForms = cantidadForms - 1;

    if (newCantidadForms >= DEFAULT_CANTIDAD_FORMS) {
      setCantidadForms(cantidadForms - 1);

      // Remover el último elemento de todos los arrays
      const newTiposEstacionamientoCheckboxState = copyArray(tiposEstacionamientoCheckboxState);
      newTiposEstacionamientoCheckboxState.pop();
      setTiposEstacionamientoCheckboxState(newTiposEstacionamientoCheckboxState);

      const newTiposEstacionamiento = copyArray(tiposEstacionamiento);
      newTiposEstacionamiento.pop();
      setTiposEstacionamiento(newTiposEstacionamiento);

      const newPisos = copyArray(pisos);
      newPisos.pop();
      setPisos(newPisos);

      const newDistanciasAscensor = copyArray(distanciasAscensor);
      newDistanciasAscensor.pop();
      setDistanciasAscensor(newDistanciasAscensor);

      const newSecundariosNum = copyArray(secundariosNum);
      newSecundariosNum.pop();
      setSecundariosNum(newSecundariosNum);

      const newSecundariosIds = copyArray(secundariosIds);
      newSecundariosIds.pop();
      setSecundariosIds(newSecundariosIds);
    }
  }

  const updateTiposEstacionamientoCheckboxState = (newCheckboxState) => {
    setTiposEstacionamientoCheckboxState(newCheckboxState);
  }

  const updateTiposEstacionamiento = (newTiposEstacionamiento) => {
    setTiposEstacionamiento(newTiposEstacionamiento);
  }

  const updatePisos = (newPisos) => {
    setPisos(newPisos);
  }

  const updateDistanciasAscensor = (newDistanciasAscensor) => {
    setDistanciasAscensor(newDistanciasAscensor);
  }

  const updateSecundariosNum = (newSecundariosNum) => {
    setSecundariosNum(newSecundariosNum);
  }

  const updateSecundariosIds = (newSecundariosIds) => {
    setSecundariosIds(newSecundariosIds);
  }

  const onConfirmarClick = () => {
    // Advertir si hay secundarios repetidos
    for (let i = 0; i < secundariosIds.length; i++) {
      for (let k = i + 1; k < secundariosIds.length; k++) {
        if (secundariosIds[i].id === secundariosIds[k].id) {
          switch (secundariosIds[i].tipo) {
            case 'auto':
              toast.error(`Estacionamiento de auto duplicado!`);
              return;

            case 'moto':
              toast.error(`Estacionamiento de moto duplicado!`);
              return;

            case 'bodega':
              toast.error(`Bodega duplicada!`);
              return;

            default:
              return;
          }
        }
      }
    }

    // Procesar datos y enviarlos a Redux
    if (type === TYPE.ESTACIONAMIENTO) {
      const estacionamientos = [];

      for (let i = 0; i < cantidadForms; i++) {
        if (secundariosIds[i].id !== DEFAULT_SECUNDARIO_ID.id) {
          estacionamientos.push(secundariosIds[i]);
        }
      }

      dispatch(setReserva({
        ...reserva,
        estacionamientos: estacionamientos.length > 0 ? estacionamientos : [],
      }));
    } else if (type === TYPE.BODEGA) {
      const bodegas = [];

      for (let i = 0; i < cantidadForms; i++) {
        if (secundariosIds[i].id !== DEFAULT_SECUNDARIO_ID.id) {
          bodegas.push(secundariosIds[i]);
        }
      }

      dispatch(setReserva({
        ...reserva,
        bodegas: bodegas.length > 0 ? bodegas : [],
      }));
    }

    // Pasar al siguiente formulario
    nextForm();
  }

  const formItems = [];

  for (let i = 0; i < cantidadForms; i++) {
    const isLastItem = i === (cantidadForms - 1);

    formItems.push(
      <>
        <InnerSecundarioForm
          key={i}
          proyecto={proyecto}
          type={type}
          formIndex={i}
          onConfirmarClick={isLastItem ? onConfirmarClick : null}
          agregarSecundario={isLastItem ? agregarSecundario : null}
          removerSecundario={isLastItem && cantidadForms > 1 ? removerSecundario : null}
          tiposEstacionamientoCheckboxState={tiposEstacionamientoCheckboxState}
          updateTiposEstacionamientoCheckboxState={updateTiposEstacionamientoCheckboxState}
          tiposEstacionamiento={tiposEstacionamiento}
          updateTiposEstacionamiento={updateTiposEstacionamiento}
          pisos={pisos}
          updatePisos={updatePisos}
          distanciasAscensor={distanciasAscensor}
          updateDistanciasAscensor={updateDistanciasAscensor}
          secundariosNum={secundariosNum}
          updateSecundariosNum={updateSecundariosNum}
          secundariosIds={secundariosIds}
          updateSecundariosIds={updateSecundariosIds}
          isCotizacion = {isCotizacion}
          currentCotizacion = {currentCotizacion}
        />
        <br />
      </>
    );
  }

  const headerStr = `Selecciona ${type}`;


  return (
    <div
      className={`_itemStep-form ${checked ? 'checked' : ''} ${active ? 'active' : ''}`}
      style={{
        display: `${(rizeWidth <= BREAKPOINT_MOBILE && !active) ? 'none' : ''}`,
      }}
    >
      <div className="_step-header" onClick={onHeaderClick}>
        <TextInfo textWeight="bold" size={18} color="black">{headerStr} <span className="obligatorio">*</span> <Icon size={16} name="icon-tick" /></TextInfo>
        <div className="_info">{checked ? <small>$000.000/mes</small> : ''} <Icon name="icon-question" /></div>
      </div>
      <div className="_step-body">
        {formItems}
      </div>
    </div>
  );
}

export default SeleccionaSecundario;
