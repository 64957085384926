import React from 'react';
import { useDispatch } from 'react-redux';
import useModalScrolling from '../../../hooks/useModalScrolling';
import {
  setBodegas,
  setEstacionamientosAuto,
  setEstacionamientosMoto
} from '../../../redux/preevaluacionSlice';
import Header from '../../Header';
import { Modal } from '../../ui/index';
import CotizarMobile, { COTIZACION_TYPE } from './CotizarMobile';
import styles from './styles/CotizarMobileModal.module.scss';


const CotizarMobileModal = ({
  proyecto,
  show,
  close,
  cotizacionType = COTIZACION_TYPE.ESTACIONAMIENTOS,
}) => {

  const dispatch = useDispatch();

  useModalScrolling();

  let topBoxHeight;
  let bottomBoxHeight;

  switch (cotizacionType) {
    case COTIZACION_TYPE.ESTACIONAMIENTOS:
      topBoxHeight = '480px';
      bottomBoxHeight = '420px';
      break;

    case COTIZACION_TYPE.BODEGAS:
      topBoxHeight = '390px';
      bottomBoxHeight = '350px';
      break;

    default:
      console.log(`Tipo de cotización "${cotizacionType}" desconocido`);
  }

  // Cierra el modal y reinicia los estacionamientos de auto/moto y las bodegas a 0
  const closeAndResetEstacionamientos = () => {
    dispatch(setEstacionamientosAuto(0));
    dispatch(setEstacionamientosMoto(0));
    dispatch(setBodegas(0));
    close();
  }

  return (
    <Modal show={show} close={closeAndResetEstacionamientos}>
      <div className={`modal-content fade ${styles.customModalContent}`}>
        <Header
          pageCurrent="estacionamientos"
          onBackArrowClick={closeAndResetEstacionamientos}
          showToasts={false}
          mobileMenuBar={false}
        />
        <div className={`body-modal ${styles.customBodyModal}`}>
          <CotizarMobile
            cotizacionType={cotizacionType}
            proyecto={proyecto}
            topBoxStyle={{ paddingTop: '50px', height: topBoxHeight }}
            bottomBoxStyle={{ height: bottomBoxHeight }}
          />
        </div>
      </div>
    </Modal>
  );
}

export default CotizarMobileModal;
