import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon, TextInfo } from '../ui/index';
import notFound from '../../static/images/notFound.png';
import { BACKEND_BASE_URL, FEATURE_ENABLED } from '../../config/constant';
import {
  dataLayerPush,
  getMinAndMaxBanos,
  getMinAndMaxDormitorios,
  getPlaceUrl
} from '../../utils';
import PrecioMensual from '../PrecioMensual';
import GastoComunProyecto from '../GastoComunProyecto';
import PrecioMensualEstacionamientos from '../PrecioMensualEstacionamientos';
import PrecioMensualBodegas from '../PrecioMensualBodegas';


const ProyectosSliderCard = ({ proyecto, cardType, cardImgClickCallback }) => {

  const history = useHistory();
  const [like, setLike] = useState(false);

  const onHeartClick = () => {
    if (like) {
      // Se removió el like
      dataLayerPush({
        event: 'click',
        idProyecto: proyecto.proyecto_nombre,
        eventCategory: 'favoritos',
        eventAction: 'click',
        eventLabel: 'quitar de favoritos',
      });
    } else {
      // Se agregó el like
      dataLayerPush({
        event: 'click',
        idProyecto: proyecto.proyecto_nombre,
        eventCategory: 'favoritos',
        eventAction: 'click',
        eventLabel: 'agregar a favoritos',
      });
    }

    setLike(!like);
  }

  let imageUrl = notFound;
  let dormitoriosStr = '';
  let banosStr = '';

  if (proyecto) {
    // Seleccionar galería según tipo de card
    let galeriaName;

    switch (cardType) {
      case 'estacionamientos':
        galeriaName = 'estacionamiento_galeria';
        break;

      case 'bodegas':
        galeriaName = 'bodega_galeria';
        break;

      default:
        galeriaName = 'galeria';
    }

    const galeria = proyecto[galeriaName];

    if (galeria && galeria.length > 0 && galeria[0].formats.small) {
      imageUrl = `${BACKEND_BASE_URL}${galeria[0].formats.small.url}`;
    }

    const dormitorios = getMinAndMaxDormitorios(proyecto);
    dormitoriosStr = `${dormitorios.min}-${dormitorios.max}`;

    const banos = getMinAndMaxBanos(proyecto);
    banosStr = `${banos.min}-${banos.max}`;
  }

  const onImageClick = () => {
    switch (cardType) {
      case 'estacionamientos':
        if (cardImgClickCallback) {
          cardImgClickCallback(proyecto);
        }
        break;

      case 'bodegas':
        if (cardImgClickCallback) {
          cardImgClickCallback(proyecto);
        }
        break;

      default:
        history.push(getPlaceUrl(proyecto));
    }
  }

  let gastosComunes = null;
  let precioMensual = null;
  let cardStyle = {};

  switch (cardType) {
    case 'estacionamientos':
      precioMensual = <PrecioMensualEstacionamientos proyecto={proyecto} />;
      cardStyle = { paddingTop: '15px' };
      break;

    case 'bodegas':
      precioMensual = <PrecioMensualBodegas proyecto={proyecto} />;
      cardStyle = { paddingTop: '15px' };
      break;

    default:
      gastosComunes = <TextInfo>Gastos comunes desde <GastoComunProyecto proyecto={proyecto} /></TextInfo>;
      precioMensual = <PrecioMensual proyecto={proyecto} />;
  }


  return (
    <div className="card-place-map">
      <div onClick={onImageClick} className="_image-map" style={{ backgroundImage: `url(${imageUrl})` }}>
        {FEATURE_ENABLED.LIKES && <Icon onClick={onHeartClick} name={like ? 'icon-heart-like' : 'icon-heart'} border="round" background="white" color="#FF6D00" width={24} size={11} />}
      </div>
      <div className="_info-place-map" style={cardStyle}>
        <h3>{proyecto.proyecto_nombre}</h3>
        <div className="_info" style={{ whiteSpace: 'nowrap' }}>
          <TextInfo iconName="icon-location-pin" iconColor="#FF6D00">{proyecto.comuna}</TextInfo>
          <TextInfo iconName="icon-bed">{dormitoriosStr}</TextInfo>
          <TextInfo iconName="icon-bath">{banosStr}</TextInfo>
        </div>
        <div className="_gastos-footer">
          {gastosComunes}
          <TextInfo color="orange" textWeight="bold">Arriendo mensual desde {precioMensual}</TextInfo>
        </div>
      </div>
    </div>
  );
}

export default ProyectosSliderCard;
