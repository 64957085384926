import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BACKEND_BASE_URL } from '../config/constant';

import padreBebe from '../static/images/quienes_somos/padre_bebe.png';
import firma from '../static/images/tmp/firma_mock.png';
import piscina from '../static/images/quienes_somos/piscina.png';
import gimnasio from '../static/images/quienes_somos/gimnasio.png';
import museoCielo from '../static/images/quienes_somos/museo_cielo_abierto.png';
import levelHall from '../static/images/quienes_somos/level_hall_2.png';
import mercadoFrutas from '../static/images/quienes_somos/mercado_frutas.png';
import equipo from '../static/images/quienes_somos/equipo.png';
import { snakeToCamel } from '../utils';

const axios = require('axios');


// Mínimo número de imágenes que se necesitan para mostrar bien el componente <SobreLevel> de la página
// "Nosotros". Si Strapi nos mandó menos imágenes de las necesarias, usamos las default.
const SOBRE_LEVEL_MIN_IMAGES = 5;

export const INITIAL_HEADER = 'Vive una nueva experiencia en arriendo de departamentos';
export const INITIAL_SUBHEADER = 'Vivimos por entregar una experiencia excepcional a nuestros arrendatarios. Nuestros departamentos están pensados para mejorar la calidad de vida de sus habitantes, desde el diseño, construcción, administración y mantenimiento.';
export const INITIAL_DEPTOS = 0;
export const INITIAL_DEPTOS_TEXT = `Departamentos en arriendo.`;
export const INITIAL_FAMILIAS = 0;
export const INITIAL_FAMILIAS_TEXT = `Familias confiaron en Level para su arriendo.`;

// proyectosSlice ya exporta INITIAL_EDIFICIOS. Quizás sería una buena idea que cada slice exporte un objeto
// con todas las constantes, para que funcione como namespacing y evite este tipo de colisiones.
export const INITIAL_NOSOTROS_EDIFICIOS = 0;
export const INITIAL_EDIFICIOS_TEXT = `Edificios construidos pensados para ti.`;
export const INITIAL_MAIN_TEXT = `
Somos parte del holding Eurocorp, siendo nuestro sello la pasión por las personas y la innovación en el mercado de propiedades, a través de un servicio integral que permite que sea más accesible, ágil y, a la vez, sustentable.

La empatía, flexibilidad y honestidad son los valores que nos llevan a facilitar la vida de las personas, mejorando su acceso a los servicios y al transporte.

Trabajamos para que nuestros clientes se sientan en casa, siendo parte de la comunidad Level.

Representamos con orgullo el concepto de multifamily, que acompaña a las necesidades de un nuevo Chile, haciendo la vida más simple y segura, con la mejor experiencia en arriendos.

Proyectamos para el 2022: 25 edificios, en 7 comunas del Gran`;
export const INITIAL_EDIFICIOS_SLIDER_TEXT = `La calidad es una de nuestras preocupaciones, por eso todos nuestros edificios están diseñados y construidos sobre el estándar. Cada departamento pasa por un riguroso control de calidad antes de la entrega a cualquier arrendatario, para que cada persona que llega lo encuentre como nuevo.`;
export const INITIAL_IMAGES = [
  { url: piscina, alt: 'piscina' },
  { url: gimnasio, alt: 'gimnasio' },
  { url: museoCielo, alt: 'museoCielo' },
  { url: levelHall, alt: 'levelHall' },
  { url: mercadoFrutas, alt: 'mercadoFrutas' },
];
export const INITIAL_FIRMA_IMAGE = { url: firma, alt: 'firma' };
export const INITIAL_FIRMA_TEXT = 'Descripción puesto';
export const INITIAL_BANNER_TOP = { url: padreBebe, alt: 'padre-bebe' };
export const INITIAL_BANNER_BOTTOM = { url: equipo, alt: 'equipo' };
export const INITIAL_GET_NOSOTROS_DATA_STATUS = 'idle';
export const INITIAL_EJECUTIVOS_ARRIENDO_TEXT = `Ellos te asesorarán y entregarán la mejor alternativa de arriendo en Level.`;
export const INITIAL_PROPERTY_MANAGERS_TEXT = `Tienen como rol brindar herramientas para facilitar las tareas de administración; mejoran políticas y procedimientos.`;
export const INITIAL_JEFES_TEXT = `Es el equipo encargado de velar por la buena conveniencia, manejo de los bienes y servicios del edificio.`;
export const INITIAL_MANTENIMIENTO_TEXT = `En LEVEL cuentas con un equipo profesional especialmente dedicado en atender tus necesidades y en tener los departamentos como nuevos, para que tu experiencia de arriendo sea la mejor.`;
export const INITIAL_ATENCION_CLIENTE_TEXT = `Un equipo dedicado en atender tus solicitudes, dudas o consultas, con el propósito de mejorar tu experiencia en Level.`;


const initialState = {
  header: INITIAL_HEADER,
  subheader: INITIAL_SUBHEADER,
  deptos: INITIAL_DEPTOS,
  deptosText: INITIAL_DEPTOS_TEXT,
  familias: INITIAL_FAMILIAS,
  familiasText: INITIAL_FAMILIAS_TEXT,
  edificios: INITIAL_NOSOTROS_EDIFICIOS,
  edificiosText: INITIAL_EDIFICIOS_TEXT,
  mainText: INITIAL_MAIN_TEXT,
  edificiosSliderText: INITIAL_EDIFICIOS_SLIDER_TEXT,
  images: INITIAL_IMAGES,
  firmaImage: INITIAL_FIRMA_IMAGE,
  firmaText: INITIAL_FIRMA_TEXT,
  bannerTop: INITIAL_BANNER_TOP,
  bannerBottom: INITIAL_BANNER_BOTTOM,
  getNosotrosDataStatus: INITIAL_GET_NOSOTROS_DATA_STATUS,
  ejecutivosArriendoText: INITIAL_EJECUTIVOS_ARRIENDO_TEXT,
  propertyManagersText: INITIAL_PROPERTY_MANAGERS_TEXT,
  jefesText: INITIAL_JEFES_TEXT,
  mantenimientoText: INITIAL_MANTENIMIENTO_TEXT,
  atencionClienteText: INITIAL_ATENCION_CLIENTE_TEXT,
};


export const getNosotrosData = createAsyncThunk('nosotros/getData', async () => {
  try {
    const response = await axios.get(`${BACKEND_BASE_URL}/nosotros`);
    return { response };
  } catch (error) {
    console.log(`Error intentando obtener data de "Nosotros": ${error}`);
    throw error;
  }
});

// Copia una string de datos al estado, siempre y cuando la string exista y no
// esté vacía. `dataFieldSnake` debe ser el nombre del campo a copiar, que se
// asume está en snake case.
const copyStringFieldToState = (state, data, dataFieldSnake) => {
  if (data[dataFieldSnake] && data[dataFieldSnake] !== '') {
    const stateField = snakeToCamel(dataFieldSnake);
    state[stateField] = data[dataFieldSnake];
  }
}

// Copia strings de datos al estado, asume que `dataFieldSnakeArray` está
// en snake case
const copyStringFieldArrayToState = (state, data, dataFieldSnakeArray) => {
  for (const dataField of dataFieldSnakeArray) {
    copyStringFieldToState(state, data, dataField);
  }
}

// Copia números al estado, siempre y cuando las variables estén definidas
const copyNumberFieldToState = (state, data, dataFieldSnake) => {
  if (typeof data[dataFieldSnake] !== 'undefined') {
    const stateField = snakeToCamel(dataFieldSnake);
    state[stateField] = data[dataFieldSnake];
  }
}

// Copia array de números al estado
const copyNumberArrayFieldToState = (state, data, dataFieldSnakeArray) => {
  for (const dataField of dataFieldSnakeArray) {
    copyNumberFieldToState(state, data, dataField);
  }
}

// Copia imágenes (actually, URLs y alts de imágenes) al estado
const copyImageFieldToState = (state, data, dataFieldSnake) => {
  if (data[dataFieldSnake]) {
    const stateField = snakeToCamel(dataFieldSnake);
    state[stateField] = data[dataFieldSnake];
    state[stateField].alt = state[stateField].alternativeText;
    state[stateField].url = `${BACKEND_BASE_URL}${state[stateField].url}`;
  }
}

// Copia array de imágenes al estado
const copyImageFieldArrayToState = (state, data, dataFieldSnakeArray) => {
  for (const dataField of dataFieldSnakeArray) {
    copyImageFieldToState(state, data, dataField);
  }
}

const nosotrosSlice = createSlice({
  name: 'nosotros',
  initialState,
  extraReducers: {
    [getNosotrosData.pending]: (state, action) => {
      state.getNosotrosDataStatus = 'loading';
    },
    [getNosotrosData.fulfilled]: (state, action) => {
      state.getNosotrosDataStatus = 'succeeded';

      const { data } = action.payload.response;

      copyNumberArrayFieldToState(state, data, [
        'deptos',
        'familias',
        'edificios',
      ]);

      copyStringFieldArrayToState(state, data, [
        'header',
        'subheader',
        'deptos_text',
        'familias_text',
        'edificios_text',
        'main_text',
        'edificios_slider_text',
        'firma_text',
        'ejecutivos_arriendo_text',
        'property_managers_text',
        'jefes_text',
        'mantenimiento_text',
        'atencion_cliente_text',
      ]);

      copyImageFieldArrayToState(state, data, [
        'firma_image',
        'banner_top',
        'banner_bottom',
      ]);

      if (data.images && data.images.length >= SOBRE_LEVEL_MIN_IMAGES) {
        state.images = data.images;

        for (let i = 0; i < state.images.length; i++) {
          const currImage = state.images[i];
          currImage.alt = currImage.alternativeText;
          currImage.url = `${BACKEND_BASE_URL}${currImage.url}`;
        }
      }
    },
    [getNosotrosData.rejected]: (state, action) => {
      state.getNosotrosDataStatus = 'failed';
    },
  }
});

export default nosotrosSlice.reducer;

export const selectHeader = state => state.nosotros.header;
export const selectSubheader = state => state.nosotros.subheader;
export const selectDeptos = state => state.nosotros.deptos;
export const selectFamilias = state => state.nosotros.familias;
export const selectEdificios = state => state.nosotros.edificios;
export const selectMainText = state => state.nosotros.mainText;
export const selectImages = state => state.nosotros.images;
export const selectFirmaImage = state => state.nosotros.firmaImage;
export const selectFirmaText = state => state.nosotros.firmaText;
export const selectBannerTop = state => state.nosotros.bannerTop;
export const selectBannerBottom = state => state.nosotros.bannerBottom;
export const selectGetNosotrosDataStatus = state => state.nosotros.getNosotrosDataStatus;
export const selectAllNosotrosData = state => state.nosotros;
