import React from 'react';
import ReactHtmlParser from 'react-html-parser'
import './styles/CardAlert.scss'
import { Icon, TextInfo } from '../ui/index';


const CardAlerta = ({
  icon = '',
  text = '',
  typeAlert = '',
  cardStyle,
  textColor = 'orange',
  iconColor = '#FF6D00',
  textInfoStyle,
}) => {

  return (
    <div className={`card-alert-place ${typeAlert}`} style={cardStyle}>
      <Icon name={icon} size="22" color={iconColor} />
      <TextInfo containerStyle={textInfoStyle} color={textColor}>{ReactHtmlParser(text)}</TextInfo>
    </div>
  );
};

export default CardAlerta;
