import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Layout from '../components/Layout';
import PlaceHeader from '../components/containers/PlaceHeader';
import SliderPlace from '../components/containers/SliderPlace';
import InfoPlace from '../components/containers/InfoPlace';
import CardPlace from '../components/containers/CardPlace';
import CardPlaceDepartamento, { DISPONIBILIDAD } from '../components/containers/CardPlaceDepartamento';
import CardPlaceUbicacion from '../components/containers/CardPlaceUbicacion';
import PlaceSimilares from '../components/containers/PlaceSimilares';
import ModalShared from '../components/containers/ModalShared';
import useResize from '../hooks/useResize';
import { BREAKPOINT_MOBILE } from '../config/constant';
import PlaceHeaderMobile from '../components/PlaceHeaderMobile';
import CardPlaceMobile from '../components/CardPlaceMobile';

import PlaceSimilaresMobile from '../components/containers/PlaceSimilaresMobile';
import CardUnidadesMobile from '../components/CardUnidadesMobile';
import CardPlaceBarrioMobile from '../components/CardPlaceBarrioMobile';

import Entrada from '../components/Cotizar/layers/Entrada';
import GuardadoCotizacion from '../components/Cotizar/layers/GuardadoCotizacion';
import ResumenCotizacion from '../components/Cotizar/layers/ResumenCotizacion';
import OnBoarding from '../components/Cotizar/layers/OnBoarding';

import PreEvaluacion from '../components/Cotizar/layers/PreEvaluacion';
import PreEvaluacionReserva from '../components/Cotizar/layers/PreEvaluacionReserva/PreEvaluacionReserva';
import PagarCotizacion from '../components/Cotizar/layers/PagarCotizacion';
import FirmarContrato from '../components/Cotizar/layers/FirmarContrato';
import FirmaConfirmada from '../components/Cotizar/layers/FirmarContrato/FirmaConfirmada';

import {
  INITIAL_PLACES,
  getDepartamentos,
  selectProyectoBySlug,
  setPlaces
} from '../redux/proyectosSlice';
import { selectLoggedIn } from '../redux/userSlice';
import { dataLayerPush, scrollToTop } from '../utils';
import ModalLogin from '../components/ModalLogin';
import ModalRegister from '../components/ModalRegister';
import {
  selectTipologiaIndex,
  sendCotizacion,
  setBodegas,
  setEstacionamientosAuto,
  setEstacionamientosMoto,
} from '../redux/preevaluacionSlice';
import CotizarMobile from '../components/Cotizar/layers/CotizarMobile';
import useCurrentTipologiaCheapestDepto from '../hooks/useCurrentTipologiaCheapestDepto';
import Videos from '../components/containers/Videos';


const INFO_PLACE_ENABLED = false;


const Place = () => {

  const [showShared, setShowShared] = useState(false);
  const [switchDetails, setSwitchDetails] = useState(0);
  const [cotizar, setCotizar] = useState(false);
  const [saveCotizar, setSaveCotizar] = useState(false);
  const [showResumen, setShowResumen] = useState(false);
  const [showOnboarding, setOnboarding] = useState(false);
  const [showPreEvaluacion, setPreEvaluacion] = useState(false);
  const [showPreEvaluacionReserva, setPreEvaluacionReserva] = useState(false);
  const [showPagar, setPagar] = useState(false);
  const [showFirma, setShowFirma] = useState(false);
  const [showcitaConfirmada, setShowcitaConfirmada] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [showCotizarMobile, setShowCotizarMobile] = useState(false);

  const [rizeWidth] = useResize();
  const { slug, reserva } = useParams();

  const dispatch = useDispatch();
  const userLoggedIn = useSelector(selectLoggedIn);

  // Proyecto que se está viendo
  const proyecto = useSelector(selectProyectoBySlug(slug));
  // Departamento más barato del proyecto/tipología que se está viendo en /nuestros-edificios (i.e. "place")
  const cheapestDepto = useCurrentTipologiaCheapestDepto(proyecto);

  // Tipología que se está viendo
  const tipologiaIndex = useSelector(selectTipologiaIndex);
  const tipologia = proyecto ? proyecto.tipologias[tipologiaIndex] : null;
  const videos = (proyecto && proyecto.videos && proyecto.videos.length > 0) ? proyecto.videos : null;
  // Disponibilidad de la tipología que se está viendo
  const disponibilidad = tipologia && tipologia.precio_min_amoblado !== -1 ?
    DISPONIBILIDAD.DISPONIBLE : DISPONIBILIDAD.NO_DISPONIBLE;

  // Resetear places para que los puntos de interés de una búsqueda anterior no aparezcan de entrada.
  // Resetear bodegas y estacionamientos de auto y moto para evitar el case en donde el usuario setea eso
  // en la card de un depto y después navega a otro depto y le vuelven a aparecer los mismos valores,
  // independientemente de si la tipología tiene o no bodegas / estacionamientos disponibles.
  useEffect(() => {
    dispatch(setPlaces(INITIAL_PLACES));
    dispatch(setBodegas(null));
    dispatch(setEstacionamientosAuto(null));
    dispatch(setEstacionamientosMoto(null));
  }, [dispatch]);

  // Scrollear hasta arriba cuando cambia el proyecto visto
  useEffect(() => {
    scrollToTop();
  }, [slug]);

  // Obtener departamentos del proyecto actual si no los tiene
  useEffect(() => {
    if (proyecto && !proyecto.departamentos) {
      dispatch(getDepartamentos(proyecto));
    }
  }, [dispatch, proyecto]);

  if (reserva && !showPreEvaluacionReserva && proyecto && proyecto.departamentos) {
    setPreEvaluacionReserva(true);
  }

  // Analytics
  const location = useLocation();

  useEffect(() => {
    dataLayerPush({
      event: 'vpv',
      page: location.pathname,
      section: 'nuestros edificios',
      url: window.location.href,
    });
  }, [location.pathname]);

  const openShared = () => {
    setShowShared(true);
  }

  const closeShared = () => {
    setShowShared(false);
  }

  const loggedIn = useSelector(selectLoggedIn);

  const modalCotizar = () => {
    /*  if (!loggedIn) {
       setShowLogin(true);
       return;
     } */

    setCotizar(!cotizar);
  }

  const openRegister = () => {
    setShowRegister(true);
  }

  const closeRegister = () => {
    setShowRegister(false);
  }

  const selectDetails = (menu) => {
    setSwitchDetails(menu);
    const navHeader = document.querySelector('.__place-header-stick');
    window.scrollTo({ behavior: 'smooth', top: navHeader.offsetTop })
  }

  const handleSaveCotizacion = () => {
    setCotizar(false);
    if (!loggedIn) {
      setShowLogin(true);
      return;
    }


    if (cheapestDepto) {
      dispatch(sendCotizacion({
        isMinimalCotizacion: true,
        proyectoId: proyecto.id,
        deptoStrapiId: cheapestDepto.id,
      }));
      // enviamos la conversión
      dataLayerPush('event', 'conversion', { 'send_to': 'AW-10979021325/VxJFCIjLo9sDEI2kmvMo' })

    } else {
      toast.error(`No se pudo guardar la cotización`)
    }
  }

  const handleCloseSaveCotizacion = () => {
    setSaveCotizar(!saveCotizar);
  }

  const handleResumenCotizacion = () => {
    setShowResumen(!showResumen);
    setSaveCotizar(false);
  }

  const handleCloseResumenCotizacion = () => {
    setShowResumen(!showResumen);
  }

  const handleOnboarding = () => {
    setCotizar(false);
    if (userLoggedIn) {
      setOnboarding(!showOnboarding);
      if (cheapestDepto) {
        // Se guarda la cotización para ser usada en la reserva
        dispatch(sendCotizacion({
          isMinimalCotizacion: true,
          proyectoId: proyecto.id,
          deptoStrapiId: cheapestDepto.id,
        }));
      } else {
        toast.error(`No se pudo guardar la cotización`)
      }
    } else {
      openLogin();
    }
  }

  const handlePreEvaluacion = () => {
    setPreEvaluacion(!showPreEvaluacion);
    setOnboarding(false);
  }

  const handlePreEvaluacionReserva = () => {
    setPreEvaluacionReserva(!showPreEvaluacionReserva);
    setPreEvaluacion(false);
  }

  const handlePagar = () => {
    setPreEvaluacionReserva(false);
    setPagar(!showPagar);
  }

  const handleFirmar = () => {
    setShowFirma(!showFirma);
    setPagar(false);
  }

  const handleCitaConfirmada = () => {
    setShowFirma(false);
    setShowcitaConfirmada(!showcitaConfirmada);
  }

  const openLogin = () => {
    setShowLogin(true);
  }

  const closeLogin = () => {
    setShowLogin(false);
  }

  const cotizarMobile = () => {
    setShowCotizarMobile(true);
  }

  return (
    <>
      {
        !showCotizarMobile &&

        <Layout pageCurrent='ficha'>
          {rizeWidth > BREAKPOINT_MOBILE ? (
            <>
              <PlaceHeader
                openShared={openShared}
                proyecto={proyecto}
              />
              <div className="container">
                <SliderPlace proyecto={proyecto} containerStyle={{ marginBottom: '-3px' }} buttonText={null} />
                {INFO_PLACE_ENABLED && <InfoPlace proyecto={proyecto} />}
                <div className="bg-container-place-white">
                  <CardPlace proyecto={proyecto} />
                  {proyecto && <CardPlaceDepartamento
                    proyecto={proyecto}
                    disponibilidad={disponibilidad}
                    cheapestDepto={cheapestDepto}
                    openShared={openShared}
                    cotizar={modalCotizar}
                  />
                  }
                  {videos != null && <Videos videos={videos} />}
                  <CardPlaceUbicacion proyecto={proyecto} />
                </div>
                <PlaceSimilares proyecto={proyecto} />
              </div></>) : (
            <>
              <PlaceHeaderMobile openShared={openShared} selectDetails={selectDetails} proyecto={proyecto} />
              {switchDetails === 0 && <><CardPlaceMobile proyecto={proyecto} /> {videos != null && <Videos videos={videos} />} <PlaceSimilaresMobile proyecto={proyecto} /> </>}
              {switchDetails === 1 && <CardUnidadesMobile proyecto={proyecto} openShared={openShared} cotizar={cotizarMobile} />}
              {switchDetails === 2 && <CardPlaceBarrioMobile proyecto={proyecto} />}
            </>
          )
          }
          <ModalShared
            proyecto={proyecto}
            show={showShared}
            close={closeShared}
          />

          <ModalLogin
            show={showLogin}
            close={closeLogin}
            openRegister={openRegister}
            greeting={false}
          />

          <ModalRegister
            show={showRegister}
            close={closeRegister}
            openLogin={openLogin}
          />

          {cotizar && <Entrada proyecto={proyecto} close={modalCotizar} saveCotizacion={handleSaveCotizacion} OnBoarding={handleOnboarding} />}
          {saveCotizar && <GuardadoCotizacion close={handleCloseSaveCotizacion} detalle={handleResumenCotizacion} />}
          {showResumen && <ResumenCotizacion proyecto={proyecto} close={handleCloseResumenCotizacion} />}
          {/* 1. onboarding cartel*/}{showOnboarding && <OnBoarding preEvaluacion={handlePreEvaluacion} />}
          {/* 2. ingreso de datos y PEP */}{showPreEvaluacion && <PreEvaluacion isCotizacion={false} proyecto={proyecto} resumenCotizacion={handleCloseResumenCotizacion} reserva={handlePreEvaluacionReserva} />}
          {/* 3. reserva y resumen */}{showPreEvaluacionReserva && <PreEvaluacionReserva proyecto={proyecto} pagar={handlePagar} resumenCotizacion={handleCloseResumenCotizacion} />}
          {/* 4. Pagar/Contratos a pagar*/ showPagar && <PagarCotizacion proyecto={proyecto} resumenCotizacion={handleCloseResumenCotizacion} firmar={handleFirmar} />}
          {showFirma && <FirmarContrato citaConfirmada={handleCitaConfirmada} />}

          {showcitaConfirmada && <FirmaConfirmada close={handleCitaConfirmada} />}
        </Layout>
      }
      {
        showCotizarMobile &&
        <Layout pageCurrent='ficha' mobileMenuBar={false}>
          <CotizarMobile
            proyecto={proyecto}
            openPreevaluacion={() => setPreEvaluacion(true)}
            close={() => setShowCotizarMobile(false)}
          />
          {showPreEvaluacion && <PreEvaluacion isCotizacion={false} proyecto={proyecto} resumenCotizacion={handleCloseResumenCotizacion} reserva={handlePreEvaluacionReserva} />}
          {showPreEvaluacionReserva && <PreEvaluacionReserva proyecto={proyecto} pagar={handlePagar} resumenCotizacion={handleCloseResumenCotizacion} />}
        </Layout>
      }
    </>
  );
};

export default Place;
