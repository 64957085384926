/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Modal, Icon, TextInfo } from '../../../ui/index';


const ModalAyuda = ({ title, header, body, close, theClass = "" }) => {
  return (
    <Modal show={true} close={close}>
      <div className={`modal-content help md fade ${theClass}`}>
        <div className="header-modal">
          <a onClick={close}><Icon name="icon-close" size="14" /> {title}</a>
        </div>
        <div className="_body-help detalleSlicitud">
          <span className="_text_black">{header}</span>
          <p className="_text" dangerouslySetInnerHTML={{__html: body}}></p>
        </div>
        <div onClick={close} className="_footer-help">
            <TextInfo>CERRAR Y CONTINUAR</TextInfo>
        </div>
      </div>
  </Modal>
  );
};

export default ModalAyuda;
