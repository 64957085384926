/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Icon,
  Button,
  Input,
  TextInfo,
  Modal,
  Checkbox
} from './ui/index';
import {
  BREAKPOINT_MOBILE,
} from '../config/constant';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles/ModalLogin.scss';
import {
  getEmailPlaceholder,
  getRUTPlaceholder,
  getInvalidEmailString,
  getInvalidPasswordString,
  isValidEmail,
  isValidPassword,
  isValidGenericDocumento,
  getInvalidGenericDocumentoString,
} from '../validation';
import {
  loginUser,
  selectLoginUserStatus,
} from '../redux/userSlice';
import ModalResetPassword from './ModalResetPassword';
import useResize from '../hooks/useResize';
import { Link } from 'react-router-dom';
import { dottifyDocumento, quitarFormatoRut } from '../utils';


// Determina si aparece un checkbox de términos y políticas que debe aceptarse para poder
// loggearse
const ACEPTAR_TERMINOS_ENABLED = false;

// Determina si mostramos el input de email.
const EMAIL_ENABLED = false;


const ModalLogin = ({ show, close, openRegister }) => {

  const dispatch = useDispatch();
  const [rizeWidth] = useResize();
  const [email, setEmail] = useState('');
  const [numeroDocumento, setNumeroDocumento] = useState('');
  const [password, setPassword] = useState('');
  const [showModalResetPassword, setShowModalResetPassword] = useState(false);
  const [terminosChecked, setTerminosChecked] = useState(false);

  const onEmailChange = (event) => {
    setEmail(event.target.value);
  }

  const onNumeroDocumentoChange = (event) => {
    const newValue = event.target.value;

    // FIXME: Acá en realidad debería levantarse el tipo de documento, pero el cliente tiene que
    // definir bien qué quiere hacer con eso. La "puntificación" que uso por ahora es la más genérica (la de DNI,
    // que sólo agrega puntos pero no guión de RUT).
    const dottified = dottifyDocumento(newValue, 'RUT');
    setNumeroDocumento(dottified);
  }

  const onPasswordChange = (event) => {
    setPassword(event.target.value);
  }

  const onCrearCuentaClick = () => {
    // Abrir modal de registro y cerrar el de login
    openRegister();
    close();
  }

  const onIngresarClick = () => {
    if (EMAIL_ENABLED && !isValidEmail(email)) {
      toast.error(getInvalidEmailString());
      return;
    }

    if (!isValidPassword(password)) {
      toast.error(getInvalidPasswordString());
      return;
    }

    const numeroDocumentoSanitized = quitarFormatoRut(numeroDocumento);

    if (!isValidGenericDocumento(numeroDocumentoSanitized)) {
      toast.error(getInvalidGenericDocumentoString());
      return;
    }

    const userData = {
      numeroDocumento: numeroDocumentoSanitized,
      password,
    };

    dispatch(loginUser(userData)).then((response) => {
      console.log(response);
    });

    // Cerrar modal
    close();
  }

  const onOlvideContrasenaClick = () => {
    setShowModalResetPassword(true);
  }

  const closeModalResetPassword = () => {
    setShowModalResetPassword(false);
  }

  const onTerminosChange = (event) => {
    setTerminosChecked(event.target.checked);
  }

  const loginStatus = useSelector(selectLoginUserStatus);
  const headerStr = loginStatus === 'succeeded' ? `Bienvenido, ${email}` : 'Inicia Sesión';

  let top = '';

  if (rizeWidth < BREAKPOINT_MOBILE) {
    top = '30px';
  }

  const loginParagraphStyle = { marginBottom: '0' };
  const checkmarkStyle = rizeWidth > BREAKPOINT_MOBILE ? { left: '40px' } : {};
  const separadorStyle = ACEPTAR_TERMINOS_ENABLED ? {} : { marginTop: '10px' };


  return (
    <>
      <Modal show={show} bgClose={false} className="modalLoginHome">
        <div className="modal-content modal-login fade" style={{ top }}>
          <div className="header-modal">
            <Icon onClick={close} name="icon-close" size="14" />
          </div>
          <div className="body-modal">
            <div className="form-content">
              <h3>{headerStr}</h3>
              <p className="loginParagraph" style={loginParagraphStyle}>¿No tienes una cuenta? <a onClick={onCrearCuentaClick} className="link">Crear cuenta</a></p>
              <br />
              {
                ACEPTAR_TERMINOS_ENABLED &&

                <Checkbox
                  checked={terminosChecked}
                  onChange={onTerminosChange}
                  position="left"
                  textAlign="left"
                  justifyContent="center"
                  marginZero={true}
                  checkmarkStyle={checkmarkStyle}
                >
                  <TextInfo className="footer-login" size="12">Acepto los <Link className="link" to="/terminos-y-politica">términos y la política de privacidad</Link></TextInfo>
                </Checkbox>
              }
              <div className="separador-form" style={separadorStyle} />
              <div className="formLogin">
                <form>
                  {
                    EMAIL_ENABLED &&

                    <Input
                      type="email"
                      name="email"
                      typeStyle="filtro-white"
                      fullWidth={true}
                      placeholder={getEmailPlaceholder()}
                      value={email}
                      onChange={onEmailChange}
                      formGroupStyle={{ margin: "0" }}
                    />
                  }

                  <Input
                    type="text"
                    name="numeroDocumento"
                    typeStyle="filtro-white"
                    fullWidth={true}
                    placeholder={getRUTPlaceholder()}
                    value={numeroDocumento}
                    onChange={onNumeroDocumentoChange}
                    formGroupStyle={{ margin: "0" }}
                    maxLength="12"
                  />

                  <Input
                    type="password"
                    name="password"
                    typeStyle="filtro-white"
                    fullWidth={true}
                    placeholder="Contraseña"
                    value={password}
                    onChange={onPasswordChange}
                    formGroupStyle={{ margin: "0" }}
                  />
                </form>
              </div>
              <a onClick={onOlvideContrasenaClick} className="forget-password" style={{ cursor: 'pointer' }}>Olvidé mi contraseña</a>
              <Button onClick={onIngresarClick} style={{ marginBottom: '50px' }} className="btn btn-submit" color='btn-orange' size="small">INGRESAR</Button>
              <br />
            </div>
          </div>
        </div>
      </Modal>
      <ModalResetPassword show={showModalResetPassword} close={closeModalResetPassword} />
    </>
  );
};

export default ModalLogin;
