/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import './styles/MenuMobile.scss';
import styles from './styles/MenuMobileCustom.module.scss';
import { Icon, TextInfo } from '../ui/index';
import logoLevel from '../../static/images/logo_level.png';
import logoEurocorp from '../../static/images/logo_eurocorp.png';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectLoggedIn } from '../../redux/userSlice';
import { selectProyectos } from '../../redux/proyectosSlice';
import {
  LEVEL_COPYRIGHT,
  LEVEL_LINEA_ETICA_URL,
  LEVEL_MI_CUENTA_URL,
  LEVEL_PAGAR_ARRIENDO_URL,
  LEVEL_WEB_ANTERIOR,
  MENU_ENABLED,
  MENU_QUIENES_SOMOS_ENABLED
} from '../../config/constant';
import { getPlaceUrl } from '../../utils';
import InstagramIcon from '../InstagramIcon';
import FacebookIcon from '../../FacebookIcon';
import YoutubeIcon from '../YoutubeIcon';


const MenuMobile = ({ show, close, openLogin, closeLogin, openSearchMobile, openUserProfile }) => {

  useEffect(() => {
    const eventClickNav = document.querySelectorAll('.sidenav .nav-bar-side .down-list');

    eventClickNav.forEach((item) => {
      item.addEventListener('click', (e) => {
        e.preventDefault();
        e.target.toggleClass('show');
      });
    })
  }, []);

  const onArrendarClick = () => {
    openSearchMobile();
  }

  const history = useHistory();

  const onContactoClick = () => {
    history.push('/contacto');
  }

  const loggedIn = useSelector(selectLoggedIn);

  const onPerfilClick = () => {
    if (loggedIn) {
      openUserProfile();
    } else {
      openLogin();
    }
  }

  const handleLink = (link) => {
    window.location.href = link;
    close()
  }

  // Armar el menu de "edificios" (de hecho, proyectos)
  const proyectos = useSelector(selectProyectos);
  const edificiosItems = proyectos.map((proyecto, index) => {
    const isFirstItem = index === 0;
    const isLastItem = index === (proyectos.length - 1);

    return (
      <Link
        to={getPlaceUrl(proyecto)}
        className="child-item"
        key={proyecto.id}
        style={{
          whiteSpace: 'nowrap',
          paddingTop: `${isFirstItem ? '40px' : '20px'}`,
          paddingBottom: `${isLastItem ? '40px' : '20px'}`
        }}
      >
        {proyecto.proyecto_nombre}
      </Link>
    );
  });

  return (
    <>
      <div id="menu-mobile-sidenav" className={`sidenav ${show}`}>
        <Icon onClick={close} className="close-side" name="icon-close" size="16" color="#fff" />
        <div className="header-help">
          <div className="options">
            <div className="item-options">
              <Icon onClick={onArrendarClick} name="icon-add" border="round" background="gray-dark"
                color="#fff" width={45} size={24} />
              <p>Arrendar</p>
            </div>
            <div className="item-options">
              <Icon onClick={onContactoClick} name="icon-chat-conversation" border="round" background="gray-dark" color="#fff" width={45} size={24} />
              <p>Contacto</p>
            </div>
            <div className="item-options" onClick={onPerfilClick}>
              <Icon name="icon-user" border="round" background="gray-dark" color="#fff" width={45} size={24} />
              <p>Perfil</p>
            </div>
          </div>
        </div>
        <div id="menu-mobile-nav-bar-side" className="nav-bar-side">
          <Link to="/"><div className="parent-item">Inicio</div></Link>
          {loggedIn && <Link to="/mis-cotizaciones"><div className="parent-item">Mis cotizaciones</div></Link>}
          {/* <a href={LEVEL_WEB_ANTERIOR} target="_blank" rel="noopener noreferrer"><div className="parent-item">Versión web anterior</div></a> */}

          {
            MENU_ENABLED.SOBRE_LEVEL &&

            <>
              <div id="menu-mobile-sobre-level-down-list" className="parent-item down-list">Sobre Level</div>
              <div className="child-parent">
                <Link to="/quienes-somos-nosotros" style={{ padding: "20px 0px" }}><a className="child-item">Nosotros</a></Link>
                <Link to="/quienes-somos-beneficios" style={{ padding: "20px 0px" }}><a className="child-item">Beneficios</a></Link>
                {MENU_QUIENES_SOMOS_ENABLED.COMUNIDAD && <Link to="/quienes-somos-comunidad" style={{ padding: "20px 0px" }}><a className="child-item">Comunidad</a></Link>}
                {MENU_QUIENES_SOMOS_ENABLED.BLOG && <Link to="/blog" style={{ padding: "20px 0px" }}><a className="child-item">Blog</a></Link>}
              </div>
            </>
          }
          <Link to="/quienes-somos-preguntas-frecuentes"><div className="parent-item">Preguntas frecuentes</div></Link>
          {
            MENU_ENABLED.CONOCE_TU_BARRIO &&

            <Link to="/conoce-tu-barrio"><div className="parent-item">Conoce tu barrio</div></Link>
          }

          {MENU_ENABLED.COMO_ARRENDAR && <Link to="/como-arrendar"><div className="parent-item">¿Cómo arrendar?</div></Link>}

          <div id="menu-mobile-edificios-down-list" className="parent-item down-list">Acceso residentes</div>
          <div className="child-parent">
            <a href={LEVEL_MI_CUENTA_URL} className="child-item" style={{ whiteSpace: 'nowrap', paddingTop: '40px' }}>Mi cuenta</a>
            <Link className="child-item" to="/ingreso-mantencion" style={{ whiteSpace: 'nowrap' }}>Asistencia técnica</Link>
            <a href={LEVEL_PAGAR_ARRIENDO_URL} className="child-item" style={{ whiteSpace: 'nowrap', paddingBottom: '40px' }}>Pagar arriendo</a>

          </div>

          <div id="menu-mobile-edificios-down-list" className="parent-item down-list">Edificios</div>

          <div className="child-parent">
            {edificiosItems}
          </div>

          {MENU_ENABLED.ESTACIONAMIENTOS && <Link to="/estacionamientos"><div className="parent-item">Estacionamientos</div></Link>}
          {MENU_ENABLED.MENU_MANTENIMIENTO_PREVENTIDO && <a onClick={() => handleLink('/#/ingreso-mantencion/mantenimiento')} className="parent-item" style={{ whiteSpace: 'nowrap' }}>Mantenimiento Preventivo</a>}
          {MENU_ENABLED.MENU_EMERGENCIA && <a onClick={() => handleLink('/#/ingreso-mantencion')} className="parent-item">Emergencias</a>}
          {MENU_ENABLED.BODEGAS && <Link to="/bodegas"><div className="parent-item">Bodegas</div></Link>}
        </div>
        <div className="sidenav-footer">
          <div className="social-nav">
            <InstagramIcon target="mobile" />
            <FacebookIcon target="mobile" />
            <YoutubeIcon target="mobile" />
          </div>
          <TextInfo size="12" color="white">{LEVEL_COPYRIGHT}</TextInfo>
          <br />
          <a className={styles.lineaEtica} href={LEVEL_LINEA_ETICA_URL} target="_blank" rel="noopener noreferrer">Canal de Denuncia</a>
          <div className="nav-footer-logos">
            <img className="logo-level" src={logoLevel} alt="logo Level" />
            <img className="logo-eurocorp" src={logoEurocorp} alt="logo Eurocorp" />
          </div>
        </div>
      </div>
      <div className={`overlay-side ${show}`}></div>
    </>
  );
};

export default MenuMobile;
