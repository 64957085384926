import React, { useEffect, useState } from 'react';
import { Modal, Button, TextInfo } from '../../ui/index';
import axios from 'axios';
import './styles/Onboarding.scss';
import { BACKEND_BASE_URL } from '../../../config/constant';


const OnBoarding = ({ close, preEvaluacion }) => {
  const [step, setStep] = useState(1);
  const [infoboxesData, setInfoboxesData] = useState(null);

  // Obtener info del back en el primer render
  useEffect(() => {
    axios
      .get(`${BACKEND_BASE_URL}/cajas-informativas`)
      .then((response) => {
        setInfoboxesData(response.data[0].infobox);
      })
      .catch((err) => {
        console.log(`Error while trying to get cajas informativas: ${err}`);
      });
  }, []);

  const handleStep = () => {
    const newStep = step + 1;

    if (newStep > 2) {
      preEvaluacion();
    } else {
      setStep(newStep);
    }
  }

  const infoBoxes = infoboxesData ? infoboxesData.map((infoboxData) => {
    return (
      <div key={infoboxData.id} className="_itemBox-on">
        <img src={`${BACKEND_BASE_URL}${infoboxData?.icon?.url}`} alt={infoboxData?.icon?.alternativeText} />
        <span>{infoboxData.titulo}</span>
      </div>
    );
  }) : null;

  return (
    <Modal show={true} close={close}>
      <div className="modal-content small fade">
        <div className="_body-on">
          <div className="_steps-on">
            <div className={`_step-item text-center ${step === 1 ? 'active' : ''}`}>
              <h2 className="_titular">¡Bienvenido!</h2>
              <h3 className="_preTitular">Estás a un click de tu próximo hogar</h3>
              <p className="_descripcion">En Level buscamos entregar una experiencia excepcional a nuestros arrendatarios. Sigue estos consejos para lograr una evaluación exitosa y arrendar tu nuevo hogar de manera totalmente online.</p>
              <div className="_box-on">
                {infoBoxes}
              </div>
            </div>
            <div className={`_step-item ${step === 2 ? 'active' : ''}`}>
              <TextInfo iconName="icon-location-pin" size="12" iconSize={19} iconColor="#FF6D00" color="orange" textWeight="bold">Solicita una evaluación</TextInfo>
              <h2 className="_titular-regular">¿Cómo lograr ser aprobado?</h2>
              <p className="_text">La evaluación que debes realizar depende del tipo de cliente (trabajador que eres). Podrás evaluarte siendo cliente Dependiente, Independiente y No residente. Un contrato debe tener un Titular y un Aval. Si es necesario podrás apoyarte con alguien adicional que será responsable como tú al ser Co-Titular. Más adelante verás el detalle en cada caso.</p>
              <span className="_text_orange">Te explicamos qué tipo de cliente/trabajador puedes ser:</span>
              <p className="_text"><strong>Cliente Dependiente: </strong>Se considera aquel trabajador que tiene un contrato laboral vigente. Chilenos o extranjeros con rut. </p>
              <p className="_text"><strong>Cliente Independiente: </strong>Aquel trabajador que presta servicios y emite boleta por estos (persona natural), así como quienes tienen su empresa (persona jurídica). Chilenos o extranjeros con rut. </p>
              <p className="_text"><strong>No residente/visa responsabilidad democrática: </strong>Aquel cliente extranjero con pasaporte vigente o con Visa en trámite o con visa responsabilidad democrática.</p>
            </div>
          </div>
          <div className="_footer-on">
            <div className="_paginacion">
              <span onClick={() => setStep(1)} className={`_itemPage ${step === 1 ? 'active' : ''}`}></span>
              <span onClick={() => setStep(2)} className={`_itemPage ${step === 2 ? 'active' : ''}`}></span>
            </div>
            <div className="_btn-footer">
              <Button onClick={handleStep} color='btn-orange' size="extra-small" fullWidth={true}>{step === 1 ? 'SIGUIENTE' : '¡COMENZAR AHORA!'}</Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default OnBoarding;
