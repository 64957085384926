import React from 'react';
import InputFiltro from './ui/InputFiltro/InputFiltro';

// Componente que muestra un input para ingresar cantidad de dormitorios.
// El componente es controlado: se le pasa el valor actual con
// el prop `dormitorio`, y se ejecutan los callbacks `onIncrease` y
// `onDecrease` cuando la cantidad de dormitorios aumenta o disminuye,
// respectivamente.
const InputDormitorio = ({ dormitorio, onIncrease, onDecrease }) => {

  return (
    <InputFiltro
      type="dormitorio"
      label="Cantidad de dormitorios"
      controlledValue={dormitorio}
      onIncrease={onIncrease}
      onDecrease={onDecrease}
      name="dormitorio"
      flex={true}
      between={true}
      marginBottom="10"
      margin={false}
    />
  );
}

export default InputDormitorio;
