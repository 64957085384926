import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectFiltroUbicaciones, selectUniqueComunas,
  setFiltroUbicaciones
} from '../redux/proyectosSlice';
import { Checkbox, Icon, TextInfo } from './ui/index';
import { useLocation } from 'react-router-dom';


// Componente con una lista de checkboxes para cada comuna única.
// Setea el filtro correspondiente en Redux.
const ComunasCheckboxes = ({ hideDropdown }) => {
  const location = useLocation();

  const dispatch = useDispatch();
  const filtroUbicaciones = useSelector(selectFiltroUbicaciones);
  const uniqueComunas = useSelector(selectUniqueComunas);

  const onUbicacionCheckboxChange = (event, comuna) => {
    const paramsSearch = new URLSearchParams(location.search);

    if (hideDropdown) {
      hideDropdown();
    }

    if (event.target.checked) {
      // Ubicación ticked, agregarla al filtro de ubicaciones
      const newUbicaciones = filtroUbicaciones.slice();
      newUbicaciones.push(comuna);
      dispatch(setFiltroUbicaciones(newUbicaciones));

      paramsSearch.set('comuna', comuna);
      window.history.pushState({}, '/#/edificios', '/#/edificios' + '?' + paramsSearch.toString());
    } else {
      // Ubicación unticked, removerla del filtro de ubicaciones
      const newUbicaciones = filtroUbicaciones.filter(ubi => ubi !== comuna);
      dispatch(setFiltroUbicaciones(newUbicaciones));
      paramsSearch.delete('comuna');
      window.history.pushState({}, '/#/edificios', '/#/edificios' + '?' + paramsSearch.toString());
    }
  }

  const barriosCheckboxes = uniqueComunas.map((comuna, index) => {
    const marginZero = index === uniqueComunas.length - 1;

    return (
      <Checkbox onChange={(event) => onUbicacionCheckboxChange(event, comuna)} key={comuna} position="right" marginZero={marginZero}>
        <TextInfo color="black"><Icon name="icon-location-pin" border="round" size="12px" /> {comuna}</TextInfo>
      </Checkbox>
    );
  });

  return (
    <>
      {barriosCheckboxes}
    </>
  );
}

export default ComunasCheckboxes;
