import React from 'react'
import './GroupRow.scss';

const GroupRow = (props) => {
    const {
        children,
        className= ''
    } = props;

const styles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
}

return(<div className={`groupRow ${className}`} style={styles}>{children}</div>);

}

export default GroupRow;