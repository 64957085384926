import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FEATURE_ENABLED } from '../config/constant';
import {
  getComentarios,
  selectComentarios,
  selectGetComentariosStatus
} from '../redux/proyectosSlice';


// Hook de comentarios. Si los comentarios ya fueron obtenidos, los devuelve.
// Si no fueron obtenidos, los obtiene y devuelve. Si la feature de comentarios
// no está activada, no se hace el request al backend y se devuelve el valor default
// de los comentarios, i.e. un array vacío.
const useComentarios = () => {

  const dispatch = useDispatch();
  const comentarios = useSelector(selectComentarios);
  const getComentariosStatus = useSelector(selectGetComentariosStatus);

  useEffect(() => {
    if (FEATURE_ENABLED.COMENTARIOS && comentarios.length === 0 && getComentariosStatus === 'idle') {
      dispatch(getComentarios());
    }
  }, [dispatch, comentarios, getComentariosStatus]);

  return comentarios;
}

export default useComentarios;
