import React from 'react';
import styles from './styles/Equipo.module.scss';
import { Icon as Iconify } from '@iconify/react';
import handshake from '@iconify/icons-mdi/handshake-outline';
import useResize from '../../../hooks/useResize';
import { BREAKPOINT_MOBILE } from '../../../config/constant';
import SliderMobile from '../../containers/SliderMobile';


const CualidadCard = ({ title, text }) => {

  return (
    <div className={styles.cualidadCard}>
      <div className={styles.cualidadCardBack}>
        {text}
      </div>
      <div className={styles.cualidadCardFront}>
        <div className={styles.iconCircle}>
          <Iconify icon={handshake} width="45px" className={styles.iconify} color="white" style={{ marginLeft: 'auto', marginRight: 'auto' }} />
        </div>
        <h2>{title}</h2>
      </div>
    </div>
  );
}


const Equipo = ({ nosotrosData }) => {

  const [rizeWidth] = useResize();

  return (
    <div className="container" style={{ padding: '0' }}>
      <div className={styles.topContainer}>
        <div>
          <img src={nosotrosData.bannerBottom.url} alt={nosotrosData.bannerBottom.alt} />
        </div>
        {
          rizeWidth > BREAKPOINT_MOBILE &&

          <div className={styles.cualidadesContainer}>
            <h2>Un equipo comprometido con entregarte
              <span className={styles.highlight}> la mejor experiencia en arriendos.</span></h2>
            <div className={styles.cualidadesInnerContainer}>
              <CualidadCard
                title="Ejecutivos de arriendo"
                text={<p>{nosotrosData.ejecutivosArriendoText}</p>}
              />
              <CualidadCard
                title="Property Managers"
                text={<p>{nosotrosData.propertyManagersText}</p>}
              />
              <CualidadCard
                title={<span>Jefes de administración y conserjes</span>}
                text={<p>{nosotrosData.jefesText}</p>}
              />
              <CualidadCard
                title={<span>Equipo de mantenimiento</span>}
                text={<p>{nosotrosData.mantenimientoText}</p>}
              />
              <CualidadCard
                title={<span>Servicio de atención al cliente</span>}
                text={<p>{nosotrosData.atencionClienteText}</p>}
              />
            </div>
          </div>
        }
        {
          rizeWidth <= BREAKPOINT_MOBILE &&

          <div>
            <div className={styles.equipoComprometidoMobile}>
              <p>Un equipo comprometido con entregarte la mejor   experiencia en arriendos.</p>
            </div>

            <SliderMobile
              home={false}
              rawArray={[
                <CualidadCard
                  title="Ejecutivos de arriendo"
                  text={<p>{nosotrosData.ejecutivosArriendoText}</p>}
                />,
                <CualidadCard
                  title="Property Managers"
                  text={<p>{nosotrosData.propertyManagersText}</p>}
                />,
                <CualidadCard
                  title={<span>Jefes de administración y conserjes</span>}
                  text={<p>{nosotrosData.jefesText}</p>}
                />,
                <CualidadCard
                  title={<span>Equipo de mantenimiento</span>}
                  text={<p>{nosotrosData.mantenimientoText}</p>}
                />,
                <CualidadCard
                  title={<span>Servicio de atención al cliente</span>}
                  text={<p>{nosotrosData.atencionClienteText}</p>}
                />,
              ]}
            />
          </div>
        }
      </div>
    </div>
  );
}

export default Equipo;
