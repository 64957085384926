import React, { useEffect, useState } from 'react';
import Spinner from '../Spinner';
import style from './ModalContentZoom.module.scss';
import spinnerStyle from './ModalContentZoomSpinner.module.scss';
import iconAgenda from '../../static/images/sala_arriendo/icon-agenda-light.png';


// Timeout para desactivar spinner y mostrar mensaje de error
const SPINNER_TIMEOUT_IN_SECONDS = 1;

const LOADING_MSG = 'Cargando ejecutivos...';

// Componente de contenido del modal de sala de arriendo para el botón de Zoom.
const ModalContentZoom = () => {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, SPINNER_TIMEOUT_IN_SECONDS * 1000);
  }, []);

  const onAgendaClick = () => {
    alert('todo: implementar!');
  }


  return (
    <div className={style.container}>
      <Spinner enabled={loading} spinnerStyle={spinnerStyle} />
      {loading && <p className={style.loadingMsg}>{LOADING_MSG}</p>}
      {
        !loading &&

        <>
          <div className={style.failContainer}>
            <p className={style.noDisponible}>En este momento nuestros ejecutivos no se encuentran disponibles.</p>

            <p className={style.atencion}>Atención de lunes a domingo de 11:00 a 19:30 hs.</p>

            <p className={style.atencion}>Agenda tu atención haciendo click en el siguiente botón</p>
          </div>

          <div onClick={onAgendaClick} className={style.agendaContainer}>
            <img src={iconAgenda} alt="icon-agenda" />
            <p>Agenda tu atención online</p>
          </div>
        </>
      }
    </div>
  );
}

export default ModalContentZoom;
