/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import './styles/CardDetalle.scss';
import { Title, TextInfo, Button, Icon } from '../../ui/index';
import notFound from '../../../static/images/notFound.png'
import { useSelector } from 'react-redux';
import {
  selectAmoblado,
  selectBodegas,
  selectEstacionamientosAuto,
  selectEstacionamientosMoto,
  selectTipologiaIndex
}
  from '../../../redux/preevaluacionSlice';
import {
  getBodegaPrecio,
  getEstacionamientoAutoPrecio,
  getEstacionamientoMotoPrecio,
  getPrecioMensual
} from '../../../utils';
import { BACKEND_BASE_URL } from '../../../config/constant';

import { formatPrice } from '../../../utils'

const CardDetalle = ({ proyecto, detalle, isCotizacion }) => {
  const [showOpcion, setShowOpcion] = useState(false);

  const handleOpcion = () => {
    setShowOpcion(!showOpcion);
  }

  const amoblado = useSelector(selectAmoblado);
  const tipologiaIndex = useSelector(selectTipologiaIndex);
  let estacionamientosAuto = useSelector(selectEstacionamientosAuto);
  let estacionamientosMoto = useSelector(selectEstacionamientosMoto);
  let estacionamientosTotal = estacionamientosAuto + estacionamientosMoto;
  let bodegas = useSelector(selectBodegas);
  let precioMensual = 0;
  let nombre = '';
  let direccion = '';
  let galeriaThumbUrl = notFound;

  if (proyecto) {
    if(isCotizacion){
      nombre = proyecto.tipologia.titulo
      direccion = proyecto.proyecto.proyecto_nombre + ' | '+ proyecto.proyecto.comuna
  
      if(proyecto.resumenContrato == null){
        let sumaPrecio = 0
        let sumaGastoComunes = 0
        for (let index = 0; index < proyecto.productos.length; index++) {
          const item = proyecto.productos[index];

          sumaPrecio = sumaPrecio + item.productoprecio;
          sumaGastoComunes = sumaGastoComunes + item.productogastocomun
          
        }

        //const totalSumaArriendo = proyecto.productos.filter((item) => item.productoprecio).reduce((a, b) => a + b);
        precioMensual = sumaPrecio + sumaGastoComunes;
      } else {
        precioMensual = proyecto.resumenContrato.montoArriendo + proyecto.resumenContrato.montoGastoComun
      }
 
      // Agarrar URL de la primer imagen de la galería, if any
      if (proyecto.tipologia.galeria.length > 0) {
        galeriaThumbUrl = `${BACKEND_BASE_URL}${proyecto.tipologia.galeria[0].url}`;
      }

      estacionamientosTotal = proyecto.estacionamientos.length
      bodegas = proyecto.bodegas.length


    } else{
      nombre = proyecto.proyecto_nombre;
      direccion = proyecto.direccion;

      precioMensual = getPrecioMensual(proyecto, amoblado);

      const arriendoEstacionamientosAuto = getEstacionamientoAutoPrecio(proyecto) * estacionamientosAuto;
      const arriendoEstacionamientosMoto = getEstacionamientoMotoPrecio(proyecto) * estacionamientosMoto;
      const arriendoBodegas = getBodegaPrecio(proyecto) * bodegas;
      precioMensual += arriendoEstacionamientosAuto + arriendoEstacionamientosMoto + arriendoBodegas;

      // Agarrar URL de la primer imagen de la galería, if any
      if (proyecto.tipologias[tipologiaIndex].galeria.length > 0) {
        galeriaThumbUrl = `${BACKEND_BASE_URL}${proyecto.tipologias[tipologiaIndex].galeria[0].url}`;
      }
    }
    
  }

  return (
    <div className="_card-c">
      <div className="_thumbs-c">
        <img src={galeriaThumbUrl} alt="galeria-thumb" />
      </div>
      <div className="_detalle-c">
        <Title size="16">{nombre}</Title>
        <TextInfo iconName="icon-location-pin" iconColor="#ff6d00" size={12}> {direccion}</TextInfo>
        <TextInfo color="orange" className="_fo">{(isCotizacion) ? 'Precio Mensual + Gastos Comunes:': 'Precio mensual'} <strong>${formatPrice(precioMensual)}</strong></TextInfo>
              

        {/* <div className="_btn-menu" onClick={handleOpcion}>
          <span></span>
          <span></span>
          <span></span>
        </div> */}
      </div>

      <div className={`_modalOpcion fade ${showOpcion ? 'show' : ''}`}>
        <div className="_itemOpcion">
          <Button onClick={detalle} color='btn-outline-orange' size="extra-small">VER DETALLES</Button>
          <Button color='btn-outline-orange' size="extra-small">MODIFICAR</Button>
          <Button color='btn-gray-opaco' size="extra-small">QUITAR</Button>

          <a onClick={handleOpcion} className="close_opcion"><Icon name="icon-close" size="14" /></a>
        </div>
      </div>

      <div className="_footer-c">
        <div className="_item-f"> <strong>1</strong> departamento</div>
        <div className="_item-f"> <strong>{estacionamientosTotal}</strong> estacionamiento{`${estacionamientosTotal > 1 ? 's' : ''}`}</div>
        <div className="_item-f"> <strong>{bodegas}</strong> bodega{`${bodegas > 1 ? 's' : ''}`}</div>
      </div>
    </div>
  );
};

export default CardDetalle;
