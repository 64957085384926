import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Icon, TextInfo, Button } from '../ui/index';
import './styles/CardEdificio.scss';
import notFound from '../../static/images/notFound.png';
import {
  dataLayerPush,
  getBodegaPortada,
  getDormitoriosAndBanosString,
  getEstacionamientoPortada,
  getPlaceUrl,
  getPortadaData,
} from '../../utils';
import GastoComunProyecto from '../GastoComunProyecto';
import { FEATURE_ENABLED } from '../../config/constant';
import PrecioMensualProyecto from '../PrecioMensualProyecto';
import GastoComunEstacionamientos from '../GastoComunEstacionamientos';
import GastoComunBodegas from '../GastoComunBodegas';
import PrecioMensualEstacionamientos from '../PrecioMensualEstacionamientos';
import PrecioMensualBodegas from '../PrecioMensualBodegas';


const CardEdificio = ({
  proyecto,
  pocasUnidadesAlert,
  garantiaAlertCuotas,
  estacionamientoCard = false,
  bodegaCard = false,
  openEntrada = null,
}) => {

  // Trackea si el usuario likeó el edificio o no.
  // FIXME: Esto lo tendríamos que levantar y settear en el backend cuando el usuario lo cambia.
  const [liked, setLiked] = useState(false);

  // Corazón "lleno" si like = true, vacío otherwise
  let heartLikeName = liked ? 'icon-heart-like' : 'icon-heart';

  const likeClicked = () => {
    if (liked) {
      // Se removió el like
      dataLayerPush({
        event: 'click',
        idProyecto: proyecto.proyecto_nombre,
        eventCategory: 'favoritos',
        eventAction: 'click',
        eventLabel: 'quitar de favoritos',
      });
    } else {
      // Se agregó el like
      dataLayerPush({
        event: 'click',
        idProyecto: proyecto.proyecto_nombre,
        eventCategory: 'favoritos',
        eventAction: 'click',
        eventLabel: 'agregar a favoritos',
      });
    }

    setLiked(!liked);
  }

  const onSecundarioCardClick = () => {
    if (openEntrada) {
      openEntrada(proyecto);
    }
  }

  let dormitoriosStr = 'dormitorios';
  let banosStr = 'baños';

  let portadaData = {
    url: notFound,
    alt: 'imagen-edificio',
  };

  let dptTerceros = false;

  if (proyecto) {
    const bothStrs = getDormitoriosAndBanosString(proyecto);
    dormitoriosStr = bothStrs.dormitoriosStr;
    banosStr = bothStrs.banosStr;

    if (!estacionamientoCard && !bodegaCard) {
      portadaData = getPortadaData(proyecto);
    }

    if (estacionamientoCard) {
      portadaData = getEstacionamientoPortada(proyecto);
    }

    if (bodegaCard) {
      portadaData = getBodegaPortada(proyecto);
    }

    dptTerceros = proyecto.terceros;

  }



  return (
    <div className="card-container-place">
      <div className="card-place">
        <div className="card-image-place">
          <img src={portadaData.url} alt={portadaData.alt} />
          {pocasUnidadesAlert && <span className="alert-place danger">Pocas unidades disponibles</span>}
          {garantiaAlertCuotas && <span className="alert-place succes">Garantía en {garantiaAlertCuotas} cuotas</span>}
          {!dptTerceros && <span className="alert-place info">Edificio Multifamily</span>}
        </div>
        <div className="card-body-place">
          <div className="card-header-place">
            <TextInfo color="black" size="20" textWeight="bold">{proyecto ? proyecto.proyecto_nombre : ''}</TextInfo>
            {FEATURE_ENABLED.LIKES && <Icon color="#FF6D00" name={heartLikeName} size="20" onClick={likeClicked} />}
          </div>
          <div className="card-info-place">
            <TextInfo iconName="icon-location-pin" iconColor="#FF6D00">{proyecto ? proyecto.direccion : ''}</TextInfo>
            {
              (!estacionamientoCard && !bodegaCard) &&
              <>
                <TextInfo iconName="icon-bed" iconColor="#848390">{dormitoriosStr}</TextInfo>
                <TextInfo iconName="icon-bath" iconColor="#848390">{banosStr}</TextInfo>
              </>
            }
          </div>

          <div className="card-commun-place">
            {!dptTerceros && <>
              {
                (!estacionamientoCard && !bodegaCard) &&
                <TextInfo>Gastos comunes <strong>desde <GastoComunProyecto proyecto={proyecto} /></strong></TextInfo>
              }
              {
                estacionamientoCard &&
                <TextInfo>Gastos comunes <strong>desde <GastoComunEstacionamientos proyecto={proyecto} /></strong></TextInfo>
              }
              {
                bodegaCard &&
                <TextInfo>Gastos comunes <strong>desde <GastoComunBodegas proyecto={proyecto} /></strong></TextInfo>
              }
            </>
            }

          </div>

          <div className="card-footer-place">
            <div className="card-footer-place-info">
              <TextInfo color="black">Precio mensual desde</TextInfo>
              {
                (!estacionamientoCard && !bodegaCard) &&
                <TextInfo color="orange" textWeight="bold" size="20"><PrecioMensualProyecto proyecto={proyecto} /></TextInfo>
              }
              {
                estacionamientoCard &&
                <TextInfo color="orange" textWeight="bold" size="20"><PrecioMensualEstacionamientos proyecto={proyecto} /></TextInfo>
              }
              {
                bodegaCard &&
                <TextInfo color="orange" textWeight="bold" size="20"><PrecioMensualBodegas proyecto={proyecto} /></TextInfo>
              }
            </div>
            {
              (!estacionamientoCard && !bodegaCard) &&

              <Link to={getPlaceUrl(proyecto)} >
                <Button color='btn-orange' size="extra-small">VER DETALLES</Button>
              </Link>
            }
            {
              (estacionamientoCard || bodegaCard) &&

              <Button onClick={onSecundarioCardClick} color='btn-orange' size="extra-small">VER DETALLES</Button>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardEdificio;
