/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './styles/PlaceSimilares.scss';
import { Title, Button, TextInfo } from '../ui/index';
import Carousel from 'react-elastic-carousel';
import LazyLoad from 'react-lazyload';
import { selectProyectosSimilares } from '../../redux/proyectosSlice';
import { useSelector } from 'react-redux';
import {
  getPortadaData,
  getDormitoriosAndBanosString,
  dataLayerPush,
  getPlaceUrl
} from '../../utils';
import { Link } from 'react-router-dom';


const PlaceSimilares = ({ proyecto }) => {

  const proyectosSimilares = useSelector(selectProyectosSimilares(proyecto ? proyecto.id : null));
  let sliderItems = <div></div>;  // a Carousel no le gustan los nulls

  const onArrendarClick = (proyectoNombre, position) => {
    dataLayerPush({
      event: 'click',
      bannerName: proyectoNombre,
      section: 'place - edificios similares',
      position,
    });
  }

  if (proyecto) {
    sliderItems = proyectosSimilares.map((proyecto, index) => {
      const portadaData = getPortadaData(proyecto);
      const amenities = getDormitoriosAndBanosString(proyecto, false);

      // pointerEvents: 'none' lo que efectivamente hace es que el carousel se desplace si se
      // "agarra" la carta por la imagen. De lo contrario, el browser hace que se arrastre sólo la
      // imagen en vez de la carta.
      return (
        <div key={proyecto.id} className="item-slider">
          <div className="image" style={{ pointerEvents: 'none' }}>
            <LazyLoad throttle={50} height={100}>
              <img src={portadaData.url} alt={portadaData.alt} />
            </LazyLoad>
            {!proyecto.terceros && <span className="alert-place infoPla">Edificio Multifamily</span>}
          </div>
          <div className="info">
            <TextInfo textWeight="bold" color="black" size="18">{proyecto.proyecto_nombre}</TextInfo>
            <TextInfo iconColor="#FF6D00" iconName="icon-location-pin" size="14">{proyecto.comuna}</TextInfo>
            <div className="option-edificio">
              <TextInfo iconColor="#848390" iconName="icon-bed" size="14">{amenities.dormitoriosStr}</TextInfo>
              <TextInfo iconColor="#848390" iconName="icon-bath" size="14">{amenities.banosStr}</TextInfo>
            </div>
            <Link to={getPlaceUrl(proyecto)} onClick={() => onArrendarClick(proyecto.proyecto_nombre, index + 1)}><Button color="btn-blue" fullWidth={true}>ARRENDAR AQUI</Button></Link>
          </div>
        </div>
      );
    });
  }


  return (
    <div className="place-similares">
      <div className="row">
        <div className="place-similares-header">
          <Title className="muli" size="30" marginBotton={20}>Edificios similares</Title>
          <Link to="/edificios"><span className="link">Ver todos los edificios</span></Link>
        </div>
        <div className="slide-container-similares">
          <Carousel
            itemsToScroll={4}
            itemsToShow={4}
            enableAutoPlay={false}
            showArrows={false}
            autoPlaySpeed={5000}
            className="slider-edificio"
          >
            {sliderItems}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default PlaceSimilares;
