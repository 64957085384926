import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import locationIcon from '@iconify/icons-mdi/map-marker';
import restaurantIcon from '@iconify/icons-mdi/local-restaurant';
import bankIcon from '@iconify/icons-mdi/bank';
import pharmacyIcon from '@iconify/icons-mdi/pharmacy';
import shoppingMallIcon from '@iconify/icons-mdi/local-mall';
import supermarketIcon from '@iconify/icons-mdi/local-grocery-store';
import hospitalIcon from '@iconify/icons-mdi/hospital';
import schoolIcon from '@iconify/icons-mdi/school';
import gasStationIcon from '@iconify/icons-mdi/gas-pump';
import styles from './styles/LocationPin.module.css';
import PlaceCard from './PlaceCard';


// Devuelve el icono correspondiente al tipo. Si no lo encuentra o type es null, devuelve el icono
// genérico locationIcon
const getIconFromType = (type) => {
    if (!type) {
      return locationIcon;
    }
  
    switch(type) {
      case 'restaurant':
        return restaurantIcon;
      case 'bank':
        return bankIcon;
      case 'pharmacy':
        return pharmacyIcon;
      case 'shopping_mall':
        return shoppingMallIcon;
      case 'supermarket':
        return supermarketIcon;
      case 'hospital':
        return hospitalIcon;
      case 'school':
        return schoolIcon;
      case 'gas_station':
        return gasStationIcon;
      default:
        return locationIcon;
    }
  }

// Pin para mostrar una ubicación en Google Maps
const LocationPin = ({ text, icon, name, address }) => {
  const [showCard, setShowCard] = useState(false);
  const iconName = getIconFromType(icon);
  const iconClass = icon === 'proyecto' ? styles.pinIconProyecto : styles.pinIconPlace;

  const onClick = name && address ? () => {
    setShowCard(!showCard);
  } : null;

  const closePlaceCard = () => {
    setShowCard(false);
  }

  return (
    <div className={styles.pin}>
      <PlaceCard show={showCard} close={closePlaceCard} name={name} address={address} />
      <Icon onClick={onClick} icon={iconName} className={iconClass} style={{ cursor: name ? 'pointer' : '' }} color="#EB6B08" />
      {text && <p className={styles.pinText}>{text}</p>}
    </div>
  );
}

export default LocationPin;
