import React, { useState } from 'react';
import { Button, Icon } from '../../ui/index';
import styles from './styles/OnBoardingMobile.module.scss';


const CARD = {
  FIRST_CARD: 0,
  COMO_ARRENDAR: 0,
  COMO_SER_APROBADO: 1,
  CLIENTES_INFO: 2,
  LAST_CARD: 2,
};

// Componente auxiliar. Cartas stackeadas con información.
const StackedCards = ({ totalCards, currentCard }) => {

  const navDots = [];

  for (let i = 0; i < totalCards; i++) {
    const isActive = i === currentCard;

    navDots.push(
      <div key={i} className={`${styles.dot} ${isActive ? styles.dotActive : ''}`} />
    );
  }

  return (
    <div>
      <div className={styles.cardBack2} />
      <div className={styles.cardBack1} />
      <div className={styles.cardTop}>
        {currentCard === CARD.COMO_ARRENDAR && <h1>¿Cómo arrendar?</h1>}
        {currentCard === CARD.COMO_SER_APROBADO && <h1>¿Cómo lograr ser aprobado?</h1>}
        <div className={styles.textContainer}>
          {currentCard === CARD.COMO_ARRENDAR && <p>Sigue estos consejos para lograr una evaluación exitosa
            y arrendar tu nuevo hogar de manera totalmente online.</p>}
          {
            currentCard === CARD.COMO_SER_APROBADO &&
            <>
              <p>La evaluación que debes realizar depende del tipo de cliente (trabajador) que eres. Podrás
                evaluarte siendo cliente Dependiente, Independiente y No Residente.</p>
              <p>Un contrato debe tener un Titular y un Aval. Si es necesario podrás apoyarte con alguien
                adicional que será responsable como tú al ser Co-Titular. Más adelante verás el detalle en cada caso.</p>
              <p style={{ marginTop: '10px', fontFamily: 'Roboto-bold', fontSize: '14px' }}>Te explicamos qué tipo de
              cliente/trabajador puedes ser:</p>
            </>
          }
          {
            currentCard === CARD.CLIENTES_INFO &&
            <>
              <p style={{ marginBottom: '10px' }}><span style={{ fontFamily: 'Roboto-bold' }}>Cliente Dependiente: </span>
              Se considera aquel trabajador que tiene un contrato laboral vigente. Chilenos o extranjeros con RUT.</p>

              <p style={{ marginBottom: '10px' }}><span style={{ fontFamily: 'Roboto-bold' }}>Cliente Independiente: </span>
              Aquel trabajador que presta servicios y emite boleta por estos (persona natural), así como quienes
              tienen su empresa (persona jurídica). Chilenos o extranjeros con RUT.</p>

              <p><span style={{ fontFamily: 'Roboto-bold' }}>No residente / visa responsabilidad democrática: </span>
              Aquel cliente extranjero con pasaporte vigente o con Visa en trámite o con visa responsabilidad democráctica.</p>
            </>
          }
        </div>
        {
          currentCard === CARD.COMO_ARRENDAR &&

          <div className={styles.enviaInformacion}>
            <div className={styles.iconContainer}>
              <Icon name="icon-text-document" color="white" width={40} size="40" style={{ marginLeft: 'auto', marginRight: 'auto' }} />
            </div>
            <h2>Envía tu <br />información</h2>
          </div>
        }
        <div className={styles.pagination}>
          {navDots}
        </div>
      </div>
    </div>
  );
}


// Componente de boarding mobile. Muestra la info al principio del flow de cotización.
const OnBoardingMobile = ({ openPreevaluacion, close }) => {

  const [currentCard, setCurrentCard] = useState(CARD.COMO_ARRENDAR);

  const onAtrasClick = () => {
    if (currentCard === CARD.FIRST_CARD && close) {
      return close();
    }

    if (currentCard > CARD.FIRST_CARD) {
      setCurrentCard(currentCard - 1);
    }
  }

  const onSiguienteClick = () => {
    if (currentCard < CARD.LAST_CARD) {
      setCurrentCard(currentCard + 1);
    }
  }

  const onComenzarClick = () => {
    openPreevaluacion();
  }


  return (
    <div className={styles.container}>
      <div className={styles.backContainer}>
        <Icon name="icon-left-arrow" color="white" width={30} size="14" />
        <span onClick={onAtrasClick}>Atrás</span>
      </div>
      {currentCard === CARD.COMO_ARRENDAR && <h1>¡Bienvenido!</h1>}
      {(currentCard === CARD.COMO_SER_APROBADO || currentCard === CARD.CLIENTES_INFO) &&
        <h1>Solicita una evaluación</h1>}
      <StackedCards totalCards={3} currentCard={currentCard} />
      <div className={styles.forwardContainer}>
        <span onClick={onSiguienteClick}>SIGUIENTE</span>
        <div className={styles.arrowFix}>
          <Icon name="icon-left-arrow" color="white" width={30} size="14" />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button onClick={onComenzarClick} color='btn-orange' size="small" fullWidth={true}>¡COMENZAR AHORA!</Button>
      </div>
    </div>
  );
}

export default OnBoardingMobile;
