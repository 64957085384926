import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SelectHora from '../SelectHora';
import SelectProyecto from '../SelectProyecto';
import StyledDatePicker from '../StyledDatePicker';
import { Button, Input } from '../ui/index';
import styles from './ModalContentAgenda.module.scss';


// Componente auxiliar. Contenido del modal de sala de arriendo para el botón de Zoom.
const ModalContentAgenda = () => {

  const [edificio, setEdificio] = useState('');
  const [fecha, setFecha] = useState(new Date());
  const [hora, setHora] = useState('');
  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [telefono, setTelefono] = useState('');
  const [email, setEmail] = useState('');

  const onAgendarClick = () => {
    alert('todo: implementame!');
    console.log(`onAgendarClick:`);
    console.log(`Edificio: ${edificio}\nFecha: ${fecha}\nHora: ${hora}\nNombre: ${nombre}
Apellido: ${apellido}\nTeléfono: ${telefono}\nEmail: ${email}`);
  }


  return (
    <div className={styles.container}>
      <p>Para agendar tu atención en nuestra Sala de Arriendo Online</p>

      <SelectProyecto
        title="Selecciona el edificio donde quieres arrendar"
        onProyectoChange={(nombre) => setEdificio(nombre)}
      />

      <div className={styles.dateContainer}>
        <StyledDatePicker
          date={fecha}
          updateDate={(newDate) => setFecha(newDate)}
          label="Selecciona una fecha"
          labelStyle={{
            display: 'block',
            color: 'white',
          }}
          containerStyle={{
            width: '100%',
          }}
          fullwidth={true}
        />

        <SelectHora
          title="Selecciona una hora"
          startHour={9}
          endHour={20}
          onHourChange={(newHour) => setHora(newHour)}
          selectStyle={{
            height: '35.59px', // mismo alto que el StyledDatePicker
            position: 'relative',
            top: '-2px',
          }}
        />
      </div>

      <div className={styles.inputContainer}>
        <Input
          value={nombre}
          onChange={(event) => setNombre(event.target.value)}
          label="Nombre"
          labelStyle={{
            color: 'white',
          }}
          type="text"
          classes="extra-small"
          fullWidth={true}
          placeholder="Ingresa un nombre"
          formGroupStyle={{
            margin: '0',
            marginBottom: '10px',
          }}
        />

        <Input
          value={apellido}
          onChange={(event) => setApellido(event.target.value)}
          label="Apellido"
          labelStyle={{
            color: 'white',
          }}
          type="text"
          classes="extra-small"
          fullWidth={true}
          placeholder="Ingresa un apellido"
          formGroupStyle={{
            margin: '0',
            marginBottom: '10px',
          }}
        />
      </div>

      <div className={styles.inputContainer}>
        <Input
          value={telefono}
          onChange={(event) => setTelefono(event.target.value)}
          label="Teléfono"
          labelStyle={{
            color: 'white',
          }}
          type="text"
          classes="extra-small"
          fullWidth={true}
          placeholder="Ingresa un teléfono"
          formGroupStyle={{
            margin: '0',
          }}
        />

        <Input
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          label="Email"
          labelStyle={{
            color: 'white',
          }}
          type="text"
          classes="extra-small"
          fullWidth={true}
          placeholder="Ingresa un email"
          formGroupStyle={{
            margin: '0',
          }}
        />
      </div>

      <div className={styles.buttonContainer}>
        <Button onClick={onAgendarClick} color="btn-outline-orange" startIcon="icon-clock">AGENDAR</Button>
      </div>

      <p className={styles.pregunta}>Si tienes una pregunta con tu arriendo o necesitas contactarte con
      "Servicio al Cliente" haz click <Link to="/contacto" className={styles.link}>aquí</Link></p>
    </div>
  );
}

export default ModalContentAgenda;
