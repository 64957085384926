import React from 'react';
import { Icon } from '../../ui/index';
import styles from './styles/IngresoResidentes.module.scss';


const IngresoResidentes = ({ comunidadData, topContainerStyle, showModalLogin }) =>  {

  const onIngresoClick = () => {
    showModalLogin();
  }

  return (
    <>
      <div className={styles.topContainer} style={topContainerStyle}>
        <div className={styles.innerContainer}>
          <div className={styles.iconContainer}>
            <Icon name="icon-user" size="28" style={{ position: 'relative', left: '0px', top: '1px' }}></Icon>
          </div>
          <h2>{comunidadData.ingresoResidentesHeader}</h2>
          <p>{comunidadData.ingresoResidentesText}</p>
        </div>
        <div className={styles.ingresoTab} onClick={onIngresoClick}>
          <h2>INGRESO RESIDENTES</h2>
          <span className={styles.arrow}>➔</span>
        </div>
      </div>
      
    </>
  );
}

export default IngresoResidentes;
