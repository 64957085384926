import { useEffect } from 'react';
import { BREAKPOINT_MOBILE } from '../config/constant';
import { hideWspButton, showWspButton } from '../utils';
import useResize from './useResize';


// Hook para ocultar el botón de WhatsApp en mobile. El hook vuelve a mostrar
// el botón de WhatsApp cuando el componente que lo usa es desmontado.
// `show` es un prop opcional que está pensado para pasar el valor correspondiente de los
// componentes de modales (e.g. ModalRegister), para que el hook *no* surta efecto si
// el componente está montando en el DOM pero oculto.
const useHideWspButton = (show = true) => {

  const [rizeWidth] = useResize();

  useEffect(() => {
    if (rizeWidth <= BREAKPOINT_MOBILE && show) {
      hideWspButton();
    }

    return () => {
      if (rizeWidth <= BREAKPOINT_MOBILE && show) {
        showWspButton();
      }
    };
  }, [rizeWidth, show]);
};

export default useHideWspButton;
