import React, { useEffect } from 'react';
import Layout from '../components/Layout';
import { scrollToTop } from '../utils';
import styles from './styles/TerminosPolitica.module.scss';


const TerminosPolitica = ({ match }) => {

  const pageCurrent = match.path;

  useEffect(() => {
    scrollToTop();
  }, []);


  return (
    <Layout pageCurrent={pageCurrent}>
      <div className={`container ${styles.containerCustom}`}>
        <h1>POLITICAS DE PRIVACIDAD DE DATOS - LEVEL</h1>

        <h2>1. Antecedentes Generales.</h2>

        <p>Este documento tiene por finalidad indicar a todos los usuarios del sitio web
        “Levelarriendos.cl” (en adelante el <span style={{ textDecoration: 'underline' }}>“Sitio Web”</span>)
        acerca del tipo de información recolectada con motivo del acceso y uso del Sitio Web, así como,
        nuestro compromiso con su privacidad.</p>

        <p>Para registrarse en el Sitio Web, se solicitarán ciertos antecedentes, tales como: nombre
        completo; documento de identificación y su respectivo número; dirección de correo
        electrónico y número de teléfono. Mediante el envío de éstos, usted consiente y acepta
        expresamente a Euro Rentas S.A. y a todas las sociedades en que ésta tenga propiedad
        directa o indirecta (en adelante <span style={{ textDecoration: 'underline' }}>“Level”</span>)
        el uso de dicha información o datos personales.</p>

        <h2>2. Uso de la Información.</h2>

        <p>Level efectuará el tratamiento de información o datos personales que se le
        proporcionen de conformidad a lo dispuesto en la Ley 19.628 y solo para fines
        estadísticos internos; enviar cotizaciones, ofertas, iniciativas comerciales o
        promocionales, u otros contenidos relacionados a las unidades habitacionales que se
        ofrezcan en arrendamiento mediante el Sitio Web; y en general, enviar comunicaciones
        que contengan información que pueda ser de su interés, o con contenido publicitario
        relacionado a Level al correo electrónico que hubiere proporcionado, además de la
        información que se hubiere señalado expresamente en la sección de “preferencias” del
        Sitio Web.</p>

        <h2>3. Confidencialidad de la información.</h2>

        <p>Level se compromete a adoptar todas las medidas razonables para que la información
        que usted nos haya proporcionado sea mantenida bajo confidencialidad. Empleamos
        diversas técnicas de seguridad dentro de los estándares de la industria para proteger
        tales datos de accesos no autorizados por usuarios tanto dentro como fuera de nuestra
        compañía. Sin embargo, es necesario tener muy en cuenta que la seguridad perfecta no
        existe en Internet, por lo cual Level no se responsabilizará en caso alguno por
        interceptaciones ilegales o accesos no autorizados a sus sistemas o bases de datos por
        parte de personas no autorizadas.</p>



        <h2>4. Cookies</h2>
        <p>Usted conoce y acepta que el Sitio Web podría utilizar “cookies”, es decir, pequeños
        archivos de texto que se guardan en el computador del usuario. La utilización de
        “cookies” se hace con el objeto de reconocer automáticamente las opciones
        seleccionadas en su última visita y para recopilar datos estadísticos -no asociables a un
        usuario identificado o identificable- con el fin de conocer los intereses, el
        comportamiento y la demografía de quienes visitan o son usuarios del Sitio Web.</p>

        <h2>5. Orden de autoridades componentes – Requerimientos Legales.</h2>

        <p>Level podría verse obligada a revelar cierta información personal de los usuarios del Sitio
        Web para cumplir con mandatos legales, o bien, requerimientos de las autoridades
        judiciales o gubernamentales competentes para efectos de investigaciones conducidas
        por ellas.</p>

        <h2>6. Cambio en las preferencias de e-mails.</h2>

        <p>No obstante lo anterior, usted siempre tendrá la alternativa de solicitar su exclusión de
        las bases de datos de la compañía, para lo cual deberá dirigir una comunicación escrita
        en tal sentido a la casilla de correo electrónico <a href="mailto:contacto@levelarriendos.cl">contacto@levelarriendos.cl</a>.</p>
      </div>
    </Layout>
  );
}

export default TerminosPolitica;
