/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from 'react';
import addDays from 'date-fns/addDays';
import { Modal, Icon, TextInfo, Button, Input, Select, TextArea } from '../ui/index';
import DatePicker from 'react-datepicker';
import '../styles/ModalGenerico.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { mailAgendaVisita } from '../../redux/userSlice';
import { toast } from 'react-toastify';

const ModalVisitaCotizacion = ({ show, close, proyecto }) => {
  const dispatch = useDispatch()
  const { nombre, telefono, email } = useSelector(store => store.user);
  const [name, setName] = useState(nombre);
  const [phone, setPhone] = useState(telefono);
  const mensajeRef = useRef()
  const [loadingBtn, setLoadingBtn] = useState(false);

  const saveVisita = () => {  
    setLoadingBtn(true);
    dispatch(mailAgendaVisita({
      name: name,
      email: email,
      telefono: phone,
      mensaje: mensajeRef.current.value,
      tipologia: proyecto.tipologia,
      idCotizacion: proyecto.id
    })).then((response) => {
      const { payload } = response
      if (payload === 200) {
        setLoadingBtn(false);
        close();
        toast.success('Agenda enviado con exito..');
      } else {
        toast.error('Error al intentar enviar el formulario. Intente nuevamente.');
        setLoadingBtn(false);
      }
    })

  }

  return (
    <Modal show={true} close={close}>
      <div className="modal-content modal-generico min fade">
        <div className="header-modal">
          <a onClick={close}><Icon name="icon-close" size="14" /></a>
          Agendar visita
        </div>
        <div className="modal-body">
          <h4 className="card-title">Agenda una visita a {proyecto.proyecto.proyecto_nombre}</h4>
          <div className="box-cards">
            <div className="box-card">
              <TextInfo className="title-card" textWeight="bold" color="black">Información de contacto</TextInfo>
              <Input type="text" classes="small mb-1" fullWidth={true} defaultValue={name} onChange={(e) => setName(e.targe.value)} placeholder="Ingresa tu nombre" />
              <Input type="text" classes="small mb-1" fullWidth={true} defaultValue={phone} onChange={(e) => setPhone(e.targe.value)} placeholder="Ingresa tu teléfono" />
              <TextArea className="mb-1" ref={mensajeRef} rows="5" placeholder="Si tiene alguna solicitud especial, háganoslo saber. Muchas gracias." />


            </div>

          </div>
          <Button onClick={saveVisita} className="btn btn-submit" color='btn-orange' disabled={loadingBtn} fullWidth={true} size="small">{loadingBtn ? 'AGENDANDO VISITA...':'AGENDAR VISITA'}</Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalVisitaCotizacion;
