/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPlaces, setPlaces } from '../../redux/proyectosSlice';
import { Modal, Button, Checkbox, TextInfo, Icon as IconCustom } from '../ui/index';
import { Icon } from '@iconify/react';
import restaurantIcon from '@iconify/icons-mdi/local-restaurant';
import bankIcon from '@iconify/icons-mdi/bank';
import pharmacyIcon from '@iconify/icons-mdi/pharmacy';
import shoppingMallIcon from '@iconify/icons-mdi/local-mall';
import supermarketIcon from '@iconify/icons-mdi/local-grocery-store';
import hospitalIcon from '@iconify/icons-mdi/hospital';
import schoolIcon from '@iconify/icons-mdi/school';
import gasStationIcon from '@iconify/icons-mdi/gas-pump';
import useHideCliengoButtons from '../../hooks/useHideCliengoButtons';


// Componente modal que muestra los puntos de interés que se pueden mostrar en el mapa.
// Setea el filtro correspondiente (places del slice proyectosSlice) de Redux.
const ModalFiltroInteres = ({ show, close }) => {

  const dispatch = useDispatch();
  const places = useSelector(selectPlaces);

  useHideCliengoButtons(show);

  const onCheckboxChange = (event, type) => {
    if (event.target.checked) {
      // Chequeado: agregarlo a places
      const newPlaces = places.slice(0, places.length);
      newPlaces.push(type);
      dispatch(setPlaces(newPlaces));
    } else {
      // Deschequeado: sacarlo de places
      const index = places.indexOf(type);

      if (index !== -1) {
        const newPlaces = [...places.slice(0, index), ...places.slice(index + 1)]
        dispatch(setPlaces(newPlaces));
      }
    }
  }

  const iconStyle = {
    fontSize: '1.5em',
    position: 'relative',
    top: '-2px',
    marginRight: '5px',
  };

  return (
    <Modal show={show} close={close}>
      <div className="modal-content modal-generico extra-small fade">
        <div className="header-modal">
          <a onClick={close}><IconCustom name="icon-close" size="14" /></a>
            Filtrar puntos de interés
        </div>
        <div className="body-modal">
          <div className="modal-body">
            <Checkbox onChange={(event) => onCheckboxChange(event, 'restaurant')} position="right">
              <TextInfo color="black"><Icon icon={restaurantIcon} style={iconStyle} color="#EB6B08" /> Restaurantes</TextInfo>
            </Checkbox>
            <Checkbox onChange={(event) => onCheckboxChange(event, 'bank')} position="right">
              <TextInfo color="black"><Icon icon={bankIcon} style={iconStyle} color="#EB6B08" /> Bancos</TextInfo>
            </Checkbox>
            <Checkbox onChange={(event) => onCheckboxChange(event, 'pharmacy')} position="right">
              <TextInfo color="black"><Icon icon={pharmacyIcon} style={iconStyle} color="#EB6B08" /> Farmacias</TextInfo>
            </Checkbox>
            <Checkbox onChange={(event) => onCheckboxChange(event, 'shopping_mall')} position="right">
              <TextInfo color="black"><Icon icon={shoppingMallIcon} style={iconStyle} color="#EB6B08" /> C. Comerciales</TextInfo>
            </Checkbox>
            <Checkbox onChange={(event) => onCheckboxChange(event, 'supermarket')} position="right">
              <TextInfo color="black"><Icon icon={supermarketIcon} style={iconStyle} color="#EB6B08" /> Supermercados</TextInfo>
            </Checkbox>
            <Checkbox onChange={(event) => onCheckboxChange(event, 'hospital')} position="right">
              <TextInfo color="black"><Icon icon={hospitalIcon} style={iconStyle} color="#EB6B08" /> Hospitales</TextInfo>
            </Checkbox>
            <Checkbox onChange={(event) => onCheckboxChange(event, 'school')} position="right">
              <TextInfo color="black"><Icon icon={schoolIcon} style={iconStyle} color="#EB6B08" /> Educación</TextInfo>
            </Checkbox>
            <Checkbox onChange={(event) => onCheckboxChange(event, 'gas_station')} marginZero={true} position="right">
              <TextInfo color="black"><Icon icon={gasStationIcon} style={iconStyle} color="#EB6B08" /> Servicentros</TextInfo>
            </Checkbox>
            <Button onClick={close} color='btn-orange' className="btn bold mt-4" fullWidth={true} size="small">FILTRAR</Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalFiltroInteres;
