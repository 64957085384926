import React, { useEffect } from 'react';
import Layout from '../components/Layout';

const Agendamiento = ({ match }) => {

  return (
    <Layout>
        <>
            <p style={{paddingTop: '180px', textAlign: 'center', fontWeight: 'bold', fontSize: '32px'}}>ID de cotización: 50770</p>

            <div className="container" style={{
                position: "relative",
                overflow: "hidden",
                width: "100%",
                height: "100vh"
            }}>
                <iframe style={
                    {
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        width: "100%",
                        height: "100%",
                        border: 0,
                    }
                } height="200" width="300" title="Iframe Example" title="title" src="https://outlook.office365.com/owa/calendar/Eurocorp2@eurocorp.cl/bookings/s/MEFRUa39bkeu5GI_JGROIw2"></iframe>
            </div>
        </>
    </Layout>
  );
}

export default Agendamiento;
