// Este archivo contiene constantes usadas en los componentes de /contacto


// Pseudoenum para tipo de consulta.
export const CONSULTA = {
  RENOVACION_CONTRATO: 0,
  CUPON_PAGO: 1,
  RECOMIENDA_AMIGO: 2,
  SOLICITUD_SALIDA: 3,
  SOLICITUD_CAMBIO_DEPTO: 4,
  SUGERENCIAS_FELICITACIONES_RECLAMOS: 5,
  REEMBOLSO_SERVICIOS_BASICOS: 6,
  //OTRAS_CONSULTAS: 7,
  //HORARIOS: 8,
  ASESOR_EJECUTIVO: 9,
  NO_POSEO_RUT: 10,
};

export const tipoConsultaToStr = (consulta) => {
  switch (consulta) {
    case CONSULTA.RENOVACION_CONTRATO:
      return 'Renovación de contrato';

    case CONSULTA.CUPON_PAGO:
      return 'Cupón de pago';

    case CONSULTA.RECOMIENDA_AMIGO:
      return 'Recomienda un amigo';

    case CONSULTA.SOLICITUD_SALIDA:
      return 'Solicitud de salida';

    case CONSULTA.SOLICITUD_CAMBIO_DEPTO:
      return 'Solicitud de cambio de depto';

    case CONSULTA.SUGERENCIAS_FELICITACIONES_RECLAMOS:
      return 'Sugerencias / Felicitaciones / Reclamos';

    case CONSULTA.REEMBOLSO_SERVICIOS_BASICOS:
      return 'Reembolso de servicios básicos';

    case CONSULTA.OTRAS_CONSULTAS:
      return 'Otras consultas';

    case CONSULTA.HORARIOS:
      return 'Horarios';
    
    case CONSULTA.ASESOR_EJECUTIVO:
      return 'Contacto de un ejecutivo de arriendo';

    case CONSULTA.NO_POSEO_RUT:
      return 'No poseo RUT, necesito cotizar un departamento, estacionamiento y/o bodega en LEVEL';
  
    default:
      return '';
  }
}

// Shared between desktop & mobile
export const DEFAULT_EDIFICIO_TEXT = 'Selecciona un edificio';

// Desktop
export const DEFAULT_CONSULTA_DESK = CONSULTA.RENOVACION_CONTRATO;
export const DEFAULT_TIPO_CONSULTA_TEXT_DESK = 'Selecciona un tipo de consulta';

// Mobile
export const DEFAULT_CONSULTA_MOBILE = CONSULTA.RENOVACION_CONTRATO;
export const DEFAULT_TIPO_CONSULTA_TEXT_MOBILE = 'Selecciona un motivo';
