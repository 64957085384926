import React from 'react'
import PropTypes from 'prop-types'
import './InputButton.scss'
import { Icon } from '../index'
import useCount from '../../../hooks/useCount'


// Se agregó customCount para pasar un hook desde "afuera" y así tener acceso a 'cantidad' y los
// distintos eventos. Se hace el chequeo 'customCount ? ' para mantener la retrocompatibilidad, ya
// que el componente es usado en muchas partes.
const InputButton = props => {
    const {
        cantidadDisponible,
        type = "text",
        classes = '',
        fullWidth = false,
        between = false,
        flex = false,
        marginBottom = 0,
        children,
        margin = true,
        customCount = null,
        iconMenosColors = {
            enabled: '#2C2B42',
            disabled: '#6a689c',
        },
        iconAddColors = {
            enabled: '#FF6D00',
            disabled: '#ffa866',
        },
        countColor,
        ...other
    } = props;

    const { inputRef, cantidad, eventAum, eventDis } = useCount();

    let marginIcon = '';
    const width = fullWidth ? 'full-width' : '';
    const betweenSpace = between ? 'justifi-between' : '';
    const displayFlex = flex ? 'flex' : '';
    const style = {
        marginBottom: marginBottom + 'px'
    }

    if (!margin) marginIcon = 'none';

    let iconMenosCurrColor = iconMenosColors.enabled;
    let iconAddCurrColor = iconAddColors.enabled;

    if (typeof cantidadDisponible !== 'undefined') {
        iconMenosCurrColor = cantidadDisponible > 0 ? iconMenosColors.enabled : iconMenosColors.disabled;
        iconAddCurrColor = cantidadDisponible > 0 ? iconAddColors.enabled : iconAddColors.disabled;
    }

    const onIconMenosClick = () => {
        if (typeof cantidadDisponible !== 'undefined') {
            if (cantidadDisponible > 0) {
                if (customCount) {
                    customCount.eventDis();
                } else {
                    eventDis();
                }
            }
        } else {
            if (customCount) {
                customCount.eventDis();
            } else {
                eventDis();
            }
        }
    }

    const onIconAddClick = () => {
        if (typeof cantidadDisponible !== 'undefined') {
            if (cantidadDisponible > 0) {
                if (customCount) {
                    customCount.eventAum();
                } else {
                    eventAum();
                }
            }
        } else {
            if (customCount) {
                customCount.eventAum();
            } else {
                eventAum();
            }
        }
    }

    return (
        <div className={`form-group-button ${displayFlex} ${betweenSpace}`} style={style}>
            { children && <small>{children}</small>}
            <div className="group-input">
                <Icon size="16" name="icon-menos" color={iconMenosCurrColor} className={marginIcon} onClick={onIconMenosClick} />
                <input
                    ref={customCount ? customCount.inputRef : inputRef}
                    value={customCount ? customCount.cantidad : cantidad}
                    type={type}
                    className={`form-input-button  ${classes} ${width}`}
                    style={{ color: countColor ? countColor : '' }}
                    readOnly={true}
                    {...other}
                />
                <Icon size="16" name="icon-add" color={iconAddCurrColor} className={marginIcon} onClick={onIconAddClick} />
            </div>

        </div>
    );

}

InputButton.propTypes = {
    name: PropTypes.string,
    classes: PropTypes.object,
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    id: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    between: PropTypes.bool,
    type: PropTypes.string,
    value: PropTypes.any,
    typeStyle: PropTypes.oneOf(['defauld', 'filtro', 'filtro-gray']),

}

export default InputButton;