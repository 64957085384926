import React from 'react';
import MapSection from './Map';
import { getCoordenadas } from '../../utils';


const Wrapper = ({
  proyecto,
  height = '',
  updatePlacesData = null,
  coordenadas,
  direccion,
 }) => {

  let location = null;

  if (proyecto && proyecto.ubicacion_coordenadas) {
    const coords = getCoordenadas(proyecto);

    location = {
      address: proyecto.direccion,
      lat: coords.lat,
      lng: coords.lng,
    };
  } else if (coordenadas && direccion) {
    location = {
      address: direccion,
      lat: coordenadas.lat,
      lng: coordenadas.lng,
    };
  }


  return (
    <MapSection location={location} height={height} updatePlacesData={updatePlacesData} />
  );
}

export default Wrapper;
