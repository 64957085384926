import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  INITIAL_BANOS,
  INITIAL_DORMITORIOS,
  MAX_BANOS,
  MAX_DORMITORIOS,
  selectFiltroBanos,
  selectFiltroDormitorios,
  setFiltroBanos,
  setFiltroDormitorios,
  setFiltroSuperficieMax,
  setFiltroSuperficieMin
} from '../../redux/proyectosSlice';
import { Icon, Modal, Input, GroupRow, Button } from '../ui/index';
import InputFiltro from '../ui/InputFiltro/InputFiltro';


const ModalFiltroPlace = ({ show, close }) => {

  const dispatch = useDispatch();
  const filtroBanos = useSelector(selectFiltroBanos);
  const filtroDormitorios = useSelector(selectFiltroDormitorios);
  const [superficieDesde, setSuperficieDesde] = useState('');
  const [superficieHasta, setSuperficieHasta] = useState('');

  const eventAumBanos = () => {
    const newFiltroBanos = filtroBanos + 1;

    if (newFiltroBanos <= MAX_BANOS) {
      dispatch(setFiltroBanos(newFiltroBanos));
    }
  }

  const eventDisBanos = () => {
    const newFiltroBanos = filtroBanos - 1;

    if (newFiltroBanos >= INITIAL_BANOS) {
      dispatch(setFiltroBanos(newFiltroBanos));
    }
  }

  const eventAumDormitorios = () => {
    const newFiltroDormitorios = filtroDormitorios + 1;

    if (newFiltroDormitorios <= MAX_DORMITORIOS) {
      dispatch(setFiltroDormitorios(newFiltroDormitorios));
    }
  }

  const eventDisDormitorios = () => {
    const newFiltroDormitorios = filtroDormitorios - 1;

    if (newFiltroDormitorios >= INITIAL_DORMITORIOS) {
      dispatch(setFiltroDormitorios(newFiltroDormitorios));
    }
  }

  const onSuperficieDesdeChange = (event) => {
    const newSuperficieMin = event.target.value !== '' ? event.target.value : null;
    setSuperficieDesde(newSuperficieMin);
    dispatch(setFiltroSuperficieMin(parseFloat(newSuperficieMin)));
  }

  const onSuperficieHastaChange = (event) => {
    const newSuperficieMax = event.target.value !== '' ? event.target.value : null;
    setSuperficieHasta(newSuperficieMax);
    dispatch(setFiltroSuperficieMax(parseFloat(newSuperficieMax)));
  }

  return (
    <Modal show={show}>
      <div className="modal-content modal-generico extra-small fade">
        <div className="header-modal">
          <Icon onClick={close} name="icon-close" size="14" />
              Filtrar
        </div>
        <div className="body-modal">
          <div className="modal-body">
            <InputFiltro
              type="dormitorio"
              controlledValue={filtroDormitorios}
              onIncrease={eventAumDormitorios}
              onDecrease={eventDisDormitorios}
              label="Cantidad de dormitorios"
              flex={true}
              between={true}
              marginBottom="20"
              margin={false}
            />
            <InputFiltro
              type="bano"
              controlledValue={filtroBanos}
              onIncrease={eventAumBanos}
              onDecrease={eventDisBanos}
              label="Cantidad de baños"
              flex={true}
              between={true}
              marginBottom="40"
              margin={false}
            />
            <GroupRow>
              <Input
                value={superficieDesde}
                onChange={onSuperficieDesdeChange}
                type="text"
                typeStyle="filtro-gray"
                fullWidth={true}
                help="Superficie desde"
                placeholder="30m2"
              />
              <Input
                value={superficieHasta}
                onChange={onSuperficieHastaChange}
                type="text"
                typeStyle="filtro-gray"
                fullWidth={true}
                help="Superficie hasta"
                placeholder="190m2"
              />
            </GroupRow>
            <Button onClick={close} color='btn-orange' className="btn bold mt-4" fullWidth={true} size="small">FILTRAR</Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalFiltroPlace;
