import React from 'react';
import { BREAKPOINT_MOBILE } from '../../../config/constant';
import useResize from '../../../hooks/useResize';
import styles from './styles/Hero.module.scss';


const Hero = ({ comunidadData }) => {

  const [rizeWidth] = useResize();


  return (
    <div className={styles.innerContainer}>
      <h1>{comunidadData.bannerHeader}</h1>
      <h2>{comunidadData.bannerText}</h2>
      <div className={styles.imageContainer}>
        {rizeWidth > BREAKPOINT_MOBILE && <img src={comunidadData.bannerDesk.url} alt={comunidadData.bannerDesk.alt} />}
        {rizeWidth <= BREAKPOINT_MOBILE &&
          <div className={styles.imageContainerMobile}>
            <img src={comunidadData.bannerMobile.url} alt={comunidadData.bannerMobile.alt} />
            <div className={styles.veil} />
          </div>
        }
        <div className={styles.imgFilter} />
      </div>
    </div>
  );
}

export default Hero;
