/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { BREAKPOINT_MOBILE } from '../../../../config/constant';
import useResize from '../../../../hooks/useResize';
import {
  Modal,
  Icon,
  TextInfo,
  Button,
  InputButton
} from '../../../ui/index';


const ModalUnidadExtra = ({ nextPage, reserva, close }) => {

  const [rizeWidth] = useResize();
  const modalContentStyle = rizeWidth <= BREAKPOINT_MOBILE ? {
    width: '90%',
    height: '90%',
    overflow: 'auto',
    borderRadius: '5px',
  } : {};

  const bodyStyle = rizeWidth <= BREAKPOINT_MOBILE ? {
    padding: '0px 30px 30px 30px',
  } : {};

  const itemUnidadStyle = rizeWidth <= BREAKPOINT_MOBILE ? {
    padding: '10px',
    minWidth: '125px',
  } : {};


  return (
    <Modal show={true} close={close}>
      <div className="modal-content modal-login medium fade" style={modalContentStyle}>
        <div className="header-modal">
          <a onClick={close}><Icon name="icon-close" size="14" /></a>
        </div>
        <div className="_body-extra flex-colunm-center" style={bodyStyle}>
          <TextInfo textWeight="bold" color="orange" size={30}>¡Agrega unidades!</TextInfo>
          <TextInfo size={25} className="_nota" color="black">¡Perfecto! Tu evaluación te permite agregar <br />  más unidades secundarias</TextInfo>
          <div className="_boxExtra">

            <div className="_boxUnidades">
              <div className="_itemUnidad" style={itemUnidadStyle}>
                <Icon name="icon-garage" color="#FF6D00" size={34} />
                <span className="_tituloExtra">Estacionamiento</span>
                <InputButton type="text" name="bano" placeholder="2" margin={false}></InputButton>
              </div>

              <div className="_itemUnidad" style={itemUnidadStyle}>
                <Icon name="icon-warehouse" color="#FF6D00" size={34} />
                <span className="_tituloExtra">Bodega</span>
                <InputButton type="text" name="bano" placeholder="1" margin={false}></InputButton>
              </div>
            </div>

            <Button onClick={() => nextPage(3)} color='btn-orange' size="extra-small" fullWidth={true}>AGREGAR Y CONTINUAR</Button>
            <a className="mt-2 text-link" onClick={reserva}>Continuar sin agregar</a>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalUnidadExtra;
