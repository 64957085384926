import React from 'react';
import { Icon } from './components/ui/index';
import { LEVEL_FACEBOOK_URL } from './config/constant';


const FacebookIcon = ({ url = LEVEL_FACEBOOK_URL, target = 'desk' }) => {

  return (
    <>
    {
      target === 'desk' &&
      <a href={url} target="_blank" rel="noopener noreferrer">
        <Icon size="15" name="icon-facebook" />
      </a>
    }
    {
      target === 'mobile' &&
      <a href={url} target="_blank" rel="noopener noreferrer">
        <Icon name="icon-facebook" background="gray-color" color="#fff" border="round" width={30} size="15" />
      </a>
    }
    {
      target === 'mobile-light' &&
      <a href={url} target="_blank" rel="noopener noreferrer">
        <Icon name="icon-facebook" background="gray-blue" color="#2C2B42" border="round" width={30} size="15" />
      </a>
    }
    </>
  );
}

export default FacebookIcon;
