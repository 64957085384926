/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';


const ChatBox = () => {
  return (
    <a className="chatBox"></a>
  );
}

export default ChatBox;
