/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logoColors from '../static/images/logo.png';
import {
  BREAKPOINT_MOBILE,
  FEATURE_ENABLED,
  LEVEL_MI_CUENTA_URL,
  LEVEL_PAGAR_ARRIENDO_URL,
  LEVEL_WEB_ANTERIOR,
  MENU_ENABLED,
  MENU_QUIENES_SOMOS_ENABLED,
  PREGUNTAS_FRECUENTES_CONTENT,
  PRODUCTION_TEMP
} from '../config/constant';
import { TextInfo, Button, Icon } from './ui/index';
import './styles/Header.scss';
import styles from './styles/HeaderCustom.module.scss';
import ModalRegister from './ModalRegister';
import ModalArrendar from './ModalArrendar';
import useResize from '../hooks/useResize';
import MenuMobile from './containers/MenuMobile';
import SearchMobile from './containers/SearchMobile';
import MenuBar from './containers/MenuBar';
import ModalVisitaMobile from './containers/ModalVisitaMobile';
import ModalLogin from './ModalLogin';
import { useDispatch, useSelector } from 'react-redux';
import {
  INITIAL_NOMBRE,
  selectLevelCrearNaturalErrorCount,
  selectLevelCrearNaturalErrorMessage,
  selectLoggedIn,
  selectLoginUserErrorCount,
  setLoginUserErrorCount,
  selectLoginUserErrorMessage,
  selectLogoutUserStatus,
  setLogoutUserStatus,
  setLevelCrearNaturalErrorCount,
  selectFirstName,
  setNombre
} from '../redux/userSlice';
import ModalUserProfile from './ModalUserProfile';
import { placeHeaderFix } from '../utils';
import ChatBox from './ChatBox';
import { toast } from 'react-toastify';

// Inline styles
const bareLink = {
  textDecoration: 'none',
  color: 'inherit',
  margin: '0px',
  padding: '0px',
  width: '100%',
  cursor: 'pointer',
};


const Header = ({
  pageCurrent,
  showLogin,
  openLogin,
  closeLogin,
  mobileMenuBar = true,
  onBackArrowClick = null,
  showToasts = true,
  iconSearch = true,
}) => {

  const [rizeWidth] = useResize();
  const dispatch = useDispatch();
  const classHeader = (pageCurrent !== '/' && rizeWidth < BREAKPOINT_MOBILE) ? 'white' : 'colors';
  const [showRegister, setShowRegister] = useState(false);
  const [showArrendar, setShowArrendar] = useState(false);
  const [showUserProfile, setShowUserProfile] = useState(false);
  const [showSideMenu, setShowSideMenu] = useState('');
  const [showVisitaMobile, setShowVisitaMobile] = useState('');
  const [showSearchMobile, setSearchMobile] = useState('');
  const [showQuienesSomosDropdown, setShowQuienesSomosDropdown] = useState(false);
  const [accesoResidentesDropdown, setAccesoResidentesDropdown] = useState(false);
  const [userMenuDropdown, setUserMenuDropdown] = useState(false);


  // Mostrar mensaje de error si hubo error al crear la cuenta de Level
  const levelCrearNaturalErrorMessage = useSelector(selectLevelCrearNaturalErrorMessage);
  const levelCrearNaturalErrorCount = useSelector(selectLevelCrearNaturalErrorCount);

  useEffect(() => {
    if (showToasts && levelCrearNaturalErrorCount > 0) {
      toast.error(levelCrearNaturalErrorMessage);
      dispatch(setLevelCrearNaturalErrorCount(levelCrearNaturalErrorCount - 1));
    }
  }, [dispatch, levelCrearNaturalErrorCount, levelCrearNaturalErrorMessage, showToasts]);

  // Mostrar mensaje de bienvenida cuando el usuario se loggea exitosamente
  const firstName = useSelector(selectFirstName);

  useEffect(() => {
    if (showToasts && firstName !== INITIAL_NOMBRE) {
      toast.success(`Bienvenido, ${firstName}!`);
    }
  }, [firstName, showToasts]);

  // Mostrar mensaje de error si hubo error al loggearse
  const loginErrorMessage = useSelector(selectLoginUserErrorMessage);
  const loginErrorCount = useSelector(selectLoginUserErrorCount);

  useEffect(() => {
    if (showToasts && loginErrorCount > 0) {
      toast.error(`Error: ${loginErrorMessage}`);
      dispatch(setLoginUserErrorCount(loginErrorCount - 1));
    }
  }, [loginErrorMessage, loginErrorCount, dispatch, showToasts]);

  // Mostrar mensaje de despedida cuando el usuario se desloggea
  const logoutUserStatus = useSelector(selectLogoutUserStatus);

  useEffect(() => {
    if (showToasts && logoutUserStatus === 'succeeded') {
      toast.success(`Adiós, ${firstName}!`);
      dispatch(setLogoutUserStatus(null));
      dispatch(setNombre(null));
    }
  }, [dispatch, logoutUserStatus, firstName, showToasts]);

  const openRegister = () => {
    setShowRegister(true);
  }

  const closeRegister = () => {
    setShowRegister(false);
  }

  const openArrendar = () => {
    setShowArrendar(true);
  }

  const closeArrendar = () => {
    setShowArrendar(false);
  }

  const openSideMenu = () => {
    setShowSideMenu('show');
  }

  const closeSideMenu = () => {
    setShowSideMenu('');
  }

  const openSearchMobile = () => {
    setSearchMobile('show');
  }

  const closeSearchMobile = () => {
    setSearchMobile('');
  }

  const openVisitaMobile = () => {
    setShowVisitaMobile('show');
  }

  const closeVisitaMobile = () => {
    setShowVisitaMobile('');
  }

  const closeUserProfileModal = () => {
    setShowUserProfile(false);
  }

  const openUserProfile = () => {
    setShowUserProfile(true);
  }

  const onQuienesSomosClick = () => {
    // Hack! Hay un problema raro con el z-index. Esto es lo que pude averiguar:
    // Header.scss y PlaceHeader.scss tienen el mismo z-index, 500. El dropdown de "¿Quiénes somos?"
    // tiene z-index 600, por lo que *debería* funcionar... except it doesn't. Si disminuyo el z-index
    // de PlaceHeader en 1 (500 - 1 = 499), el dropdown de "¿Quiénes somos?" empieza a funcionar... pero
    // entonces el header principal ya no funciona bien cuando el modal de visita está activo (se ve todo
    // sombreado menos el header principal). Por esa razón, en PlaceHeader.js vas a ver que hago lo contrario
    // de lo que hago acá, i.e. reestablezco el valor del zIndex de placeHeader a 500. Lo ideal sería llegar a
    // la raíz del problema para no tener que hacer este hack feo, pero por ahora it'll have to do.
    placeHeaderFix();
    setShowQuienesSomosDropdown(!showQuienesSomosDropdown);
  }

  const onAccesoResidentesClick = () => {
    placeHeaderFix(); // ver comentario en onQuienesSomosClick
    setAccesoResidentesDropdown(!accesoResidentesDropdown);
  }

  const onLoggedInClick = () => {
    placeHeaderFix(); // ver comentario en onQuienesSomosClick
    setUserMenuDropdown(!userMenuDropdown);
    if (PRODUCTION_TEMP) {
      setShowUserProfile(true);
    }
  }

  const onMisDatosClick = () => {
    setUserMenuDropdown(false);
    setShowUserProfile(true);
  }

  const loggedIn = useSelector(selectLoggedIn);
  const loginElement = loggedIn ? <Icon name="icon-user" size="20" style={{ height: "21px" }}></Icon> : 'LOGIN';
  const loginOnClick = loggedIn ? () => onLoggedInClick() : openLogin;


  return (
    <>
      <div className={`header ${classHeader}`} tabIndex="-1">
        <div className="container">

          <section id="menu-top">
            <div className="row">
              <div className="menuTop">
                {
                  pageCurrent === 'ficha' &&
                  <>
                    {rizeWidth < BREAKPOINT_MOBILE &&
                      <Link to="/result">
                        <Icon name="icon-left-arrow" color="#fff" size="20"></Icon>
                      </Link>
                    }
                  </>
                }

                {
                  pageCurrent === 'estacionamientos' &&
                  <>
                    {rizeWidth < BREAKPOINT_MOBILE &&
                      <Icon onClick={() => onBackArrowClick && onBackArrowClick()} name="icon-left-arrow" color="#fff" size="20"></Icon>
                    }
                  </>
                }

                {
                  pageCurrent !== 'ficha' && pageCurrent !== 'estacionamientos' &&
                  <a onClick={openSideMenu} className="menuMobile"></a>
                }

                <div className="item-menu-left">
                  <Link to="/"><img className={`logo ${classHeader}`} src={logoColors} alt="Logo Level" /></Link>
                  <nav className="nav-menu-top">
                    {MENU_ENABLED.WEB_ANTERIOR && <a href={LEVEL_WEB_ANTERIOR} target="_blank" rel="noopener noreferrer"><TextInfo color="black" textWeight="normal" style={{ textTransform: 'none' }}>Versión web anterior</TextInfo></a>}
                    {false && <a><TextInfo color="black" textWeight="bold">Sobre Level</TextInfo></a>}
                    {MENU_ENABLED.COMO_ARRENDAR && <Link to="/como-arrendar"><TextInfo color="black" textWeight="bold">¿Cómo arrendar?</TextInfo></Link>}
                    {
                      MENU_ENABLED.ACCESO_RESIDENTES &&

                      <div>
                        <TextInfo color="black" textWeight="bold" iconName="icon-secured-lock" iconColor="#FF6D00"
                          className={styles.accesoResidentesDropdown} style={{ cursor: 'pointer' }}
                          onClick={onAccesoResidentesClick}
                        >
                          ACCESO RESIDENTES
                          <div className={accesoResidentesDropdown ?
                            `${styles.accesoResidentesDropdownContent} ${styles.accesoResidentesDropdownContentShow}`
                            : styles.accesoResidentesDropdownContent}>
                            <ul>
                              <a href={LEVEL_MI_CUENTA_URL} style={bareLink}><li>Mi cuenta</li></a>
                              <Link to="/ingreso-mantencion" style={bareLink}><li>Asistencia técnica</li></Link>
                              <a href={LEVEL_PAGAR_ARRIENDO_URL} style={bareLink}><li>Pagar arriendo</li></a>
                            </ul>
                          </div>
                        </TextInfo>
                      </div>
                    }
                  </nav>
                </div>
                <div className="item-menu-right" style={{ position: 'relative' }}>
                  <Button style={{ height: "33px" }} onClick={openArrendar} color="btn-outline-blue" size="extra-small">ARRENDAR ONLINE</Button>
                  <Button style={{ height: "33px", position: "relative", left: "-20px" }} onClick={loginOnClick} color="btn-outline-blue-inverted" size="extra-small">{loginElement}</Button>
                  {!PRODUCTION_TEMP && (
                    <div className={userMenuDropdown ?
                      `${styles.userMenuDropdownContent} ${styles.userMenuDropdownContentShow}`
                      : styles.userMenuDropdownContent}>
                      <ul>
                        <a onClick={onMisDatosClick} style={bareLink}><li>MIS DATOS</li></a>
                        <Link to="/mis-cotizaciones" style={bareLink}><li>MIS COTIZACIONES</li></Link>
                      </ul>
                    </div>
                  )}
                </div>
                {
                  iconSearch &&
                  <a className="searchMobile" onClick={openSearchMobile}>
                    <Icon name="icon-search-fill" size="20"></Icon>
                  </a>
                }
              </div>
            </div>
          </section>

          <section id="menu-top-bottom">
            <div className="row">
              <nav className={`nav-menu-bottom ${!MENU_ENABLED.MENU_FLEX ? 'noFlex' : ''}`}>
                {MENU_ENABLED.EDIFICIOS && <Link to="/edificios"><TextInfo color="white">Edificios</TextInfo></Link>}
                {MENU_ENABLED.ESTACIONAMIENTOS && <Link to="/estacionamientos"><TextInfo color="white">Estacionamientos</TextInfo></Link>}
                {MENU_ENABLED.BODEGAS && <Link to="/bodegas"><TextInfo color="white">Bodegas</TextInfo></Link>}
                {
                  MENU_ENABLED.QUIENES_SOMOS &&
                  <a>
                    <TextInfo color="white" className={styles.quienesSomosDropdown}
                      onClick={onQuienesSomosClick}>
                      ¿Quiénes somos?
                      <div className={showQuienesSomosDropdown ?
                        `${styles.quienesSomosDropdownContent} ${styles.quienesSomosDropdownContentShow}`
                        : styles.quienesSomosDropdownContent}>
                        <ul>
                          <Link to="/quienes-somos-nosotros" style={bareLink}><li>NOSOTROS</li></Link>
                          <Link to="/quienes-somos-beneficios" style={bareLink}><li>BENEFICIOS</li></Link>
                          {MENU_QUIENES_SOMOS_ENABLED.COMUNIDAD && <Link to="/quienes-somos-comunidad" style={bareLink}><li>COMUNIDAD</li></Link>}
                          {MENU_QUIENES_SOMOS_ENABLED.BLOG && <Link to="/quienes-somos-blog" style={bareLink}><li>BLOG</li></Link>}
                          {MENU_QUIENES_SOMOS_ENABLED.BASES_NOTARIALES && <Link to="/quienes-somos-bases-notariales" style={bareLink}><li className={styles.lastLink}>BASES NOTARIALES</li></Link>}
                        </ul>
                      </div>
                    </TextInfo>
                  </a>
                }
                <Link to="/quienes-somos-preguntas-frecuentes"><TextInfo color="white">Preguntas frecuentes</TextInfo></Link>
                {MENU_ENABLED.BENEFICIOS && <a><TextInfo color="white">Beneficios</TextInfo></a>}
                {MENU_ENABLED.CONOCE_TU_BARRIO && <Link to="/conoce-tu-barrio"><TextInfo color="white">Conoce tu Barrio</TextInfo></Link>}
                {MENU_ENABLED.COMUNIDAD && <a><TextInfo color="white">Comunidad</TextInfo></a>}
                {MENU_ENABLED.BLOG && <a><TextInfo color="white">Blog</TextInfo></a>}
                {MENU_ENABLED.CONTACTO && <Link to="/contacto"><TextInfo color="white">Contacto</TextInfo></Link>}
                {MENU_ENABLED.MENU_MANTENIMIENTO_PREVENTIDO && <Link to="/ingreso-mantencion/mantenimiento" ><TextInfo color="white">Mantenimiento Preventivo</TextInfo></Link>}
                {MENU_ENABLED.MENU_EMERGENCIA && <Link to="/ingreso-mantencion"><TextInfo color="white">Emergencias</TextInfo></Link>}

              </nav>
            </div>
          </section>
        </div>
      </div>

      {
        showRegister &&
        <ModalRegister
          show={showRegister}
          close={closeRegister}
          openLogin={openLogin}
          toasts={true}
        />
      }

      {
        showLogin &&
        <ModalLogin
          show={showLogin}
          close={closeLogin}
          openRegister={openRegister}
          toasts={true}
        />
      }

      {
        showUserProfile &&
        <ModalUserProfile
          show={showUserProfile}
          close={closeUserProfileModal}
        />
      }

      {
        showArrendar &&
        <ModalArrendar
          show={showArrendar}
          close={closeArrendar}
        />
      }

      {
        showVisitaMobile &&
        <ModalVisitaMobile
          show={showVisitaMobile}
          close={closeVisitaMobile}
        />
      }

      {rizeWidth < parseInt(BREAKPOINT_MOBILE) && <MenuMobile show={showSideMenu} close={closeSideMenu} openLogin={openLogin}
        openSearchMobile={openSearchMobile} openUserProfile={openUserProfile} />}
      {rizeWidth < BREAKPOINT_MOBILE && <SearchMobile show={showSearchMobile} close={closeSearchMobile} />}

      {rizeWidth < BREAKPOINT_MOBILE && mobileMenuBar && <MenuBar openSearchMobile={openSearchMobile} openVisitaMobile={openVisitaMobile} />}

      {FEATURE_ENABLED.CHATBOX && <ChatBox />}
    </>
  );
}

export default Header;
