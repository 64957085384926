import React from 'react';
import imgHeader from '../../static/images/header_contacto.png';
import './styles/Slider.scss';
import styles from './styles/Hero.module.scss';


const Hero = React.memo(() => {

  return (
    <div className="container">
      <section id="corousel-principal" style={{ overflow: 'hidden' }}>
        <div style={{ position: 'relative', top: '0', left: '0' }}>
          <div className={styles.headerContainer}>
            <img id="contacto-hero-img" src={imgHeader} alt="slider 1" className={styles.headerImg}/>
          </div>
          <div className={styles.headerImgContainer}/>
        </div>
      </section>
    </div>
  );
});

export default Hero;
