import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getNosotrosData,
  INITIAL_DEPTOS,
  INITIAL_GET_NOSOTROS_DATA_STATUS,
  selectAllNosotrosData,
  selectDeptos,
  selectGetNosotrosDataStatus,
} from '../redux/nosotrosSlice';


// Hook de datos de la página "Nosotros". Obtiene los datos de la página "Nosotros" si no fueron
// obtenidos previamente.
// Devuelve un objeto de la forma { header, subheader, deptos, familias, edificios, mainText, Images,
// firmaImage, firmaText, bannerTop, bannerBottom }, que corresponde al estado de Redux.
const useNosotrosData = () => {

  const dispatch = useDispatch();
  const nosotrosData = useSelector(selectAllNosotrosData);
  const deptos = useSelector(selectDeptos);
  const getNosotrosDataStatus = useSelector(selectGetNosotrosDataStatus);

  useEffect(() => {
    if (deptos === INITIAL_DEPTOS && getNosotrosDataStatus === INITIAL_GET_NOSOTROS_DATA_STATUS) {
      dispatch(getNosotrosData());
    }
  }, [deptos, dispatch, getNosotrosDataStatus]);

  return nosotrosData;
}

export default useNosotrosData;
