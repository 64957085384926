import React from 'react';
import PropTypes from 'prop-types';
import './Select.scss';


const Select = props => {
  const { 
    className = '',
    fullWidth = false,
    halfWidth = false,
    typeStyle = 'default',
    help = '',
    placeholder = '',
    data = [],
    noMargin = false,
    selectStyle,
    selected = null,
    ...other
  } = props;

  let width = fullWidth ? 'full-width' : '';
  width = halfWidth ? 'half-width' : width;

  const noMarginClass = noMargin ? 'select--no-margin' : '';

  return(
    <div className={`form-group select ${width} ${noMarginClass}`} style={{ margin: `${noMargin ? '0' : ''}` }}>
      <select
        className={`form-input ${className} ${typeStyle}`} 
        style={selectStyle}
        {...other} 
      >
      <option>{placeholder}</option>
      { data.length > 0 && data.map((item, i) => {
        return <option key={i} selected={item.id == selected} value={item.id}>{item.name}</option>
      })}
      </select>
      <div className='arrow'></div>
      {help && <small>{help}</small>}
    </div>
  );
}

Select.propTypes = {
  name: PropTypes.string,
}

export default Select;
