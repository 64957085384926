import React from 'react';
import { Button, Icon, Modal } from './ui/index';
import styles from './styles/ModalBarrios.module.scss';
import BarriosCheckboxes from './BarriosCheckboxes';


const ModalBarrios = ({ show, close }) => {

  const onAplicarClick = () => {
    if (close) {
      close();
    }
  }


  return (
    <Modal show={show} close={close}>
      <div className="modal-content modal-login fade">
        <div className={styles.headerContainer}>
          <div className={styles.headerModalCustom}>
            <Icon onClick={close} name="icon-close" size="14" style={{ top: '0px' }} />
            <span>Selecciona un barrio</span>
          </div>
        </div>
        <div className={`body-modal ${styles.bodyModalCustom}`}>
          <BarriosCheckboxes />
          <Button
            onClick={onAplicarClick}
            color="btn-outline-orange"
            size="medium"
            style={{ marginTop: '20px' }}
          >
            APLICAR
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ModalBarrios;
