import React from 'react';
import InputFiltro from './ui/InputFiltro/InputFiltro';


// Componente que muestra un input para ingresar cantidad de bodegas (la "cantidad" de bodegas la
// usamos para la página /bodegas).El componente es controlado: se le pasa el valor actual con el
// prop`bodega`, y se ejecutan los callbacks`onIncrease` y `onDecrease` cuando la cantidad
// de estacionamientos aumenta o disminuye, respectivamente.
const InputBodegaCantidad = ({ bodega, onIncrease, onDecrease }) => {

  return (
    <InputFiltro
      type="filtroBodegasCantidad"
      label="Seleccione cantidad"
      controlledValue={bodega}
      onIncrease={onIncrease}
      onDecrease={onDecrease}
      name="filtro-bodegas-cantidad"
      flex={true}
      between={true}
      marginBottom="10"
      margin={false}
    />
  );
}

export default InputBodegaCantidad;
