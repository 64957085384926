import React, { useEffect } from 'react';
import Layout from '../../components/Layout';
import Imagenes from '../../components/QuienesSomos/Beneficios/Imagenes';
import ImagenesMobile from '../../components/QuienesSomos/Beneficios/ImagenesMobile';
import styles from '../../components/QuienesSomos/Beneficios/styles/Beneficios.module.scss';
import SocialButtons from '../../components/SocialButtons';
import { BREAKPOINT_MOBILE } from '../../config/constant';
import useBeneficiosData from '../../hooks/useBeneficiosData';
import useResize from '../../hooks/useResize';
import { scrollToTop } from '../../utils';


const QuienesSomosBeneficios = () => {

  const [rizeWidth] = useResize();
  const beneficiosData = useBeneficiosData();

  useEffect(() => {
    scrollToTop();
  }, []);


  return (
    <Layout>
      <div className="container">
        <div className={styles.innerContainer}>
          <div className={styles.header}>
            <h1>Beneficios de arrendar con Level.</h1>
            {rizeWidth > BREAKPOINT_MOBILE &&
              <SocialButtons
                text="Comparte"
                target="mobile-light"
                containerStyle={{ position: 'relative', left: '-10px' }}
                youtubeIconTop={7}
              />
            }
          </div>
          {
            rizeWidth <= BREAKPOINT_MOBILE &&
            <SocialButtons
              target="mobile-light"
              youtubeIconTop={7}
              containerStyle={{
                width: '110px',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '10px',
                marginBottom: '20px',
              }}
            />
          }
          {rizeWidth > BREAKPOINT_MOBILE && <Imagenes beneficiosData={beneficiosData} />}
          {rizeWidth <= BREAKPOINT_MOBILE && <ImagenesMobile beneficiosData={beneficiosData} />}
        </div>
      </div>
    </Layout >
  );
}

export default QuienesSomosBeneficios;
