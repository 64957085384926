import React from 'react';
import InputFiltro from './ui/InputFiltro/InputFiltro';


// Componente que muestra un input para ingresar cantidad de baños.
// El componente es controlado: se le pasa el valor actual con
// el prop `bano`, y se ejecutan los callbacks `onIncrease` y
// `onDecrease` cuando la cantidad de baños aumenta o disminuye,
// respectivamente.
const InputBano = ({ bano, onIncrease, onDecrease }) => {

  return (
    <InputFiltro
      type="bano"
      label="Cantidad de baños"
      controlledValue={bano}
      onIncrease={onIncrease}
      onDecrease={onDecrease}
      name="bano"
      flex={true}
      between={true}
      margin={false}
    />
  );
}

export default InputBano;
