/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { BREAKPOINT_MOBILE } from '../../config/constant';
import useResize from '../../hooks/useResize';
import { Icon } from '../ui/index';
import SearchMobile from './SearchMobile';
import './styles/DestacadoInfoMobile.scss';


const DestacadoInfoMobile = () => {

  const [rizeWidth] = useResize();
  const [showSearchMobile, setSearchMobile] = useState('');

  const openSearchMobile = () => {
    setSearchMobile('show');
  }

  const closeSearchMobile = () => {
    setSearchMobile('');
  }

  return (
    <section className="content-destacado-mobile">
      <div className="container">
        <div className="row">
          <div className="DestacadoInfoMobile">
            <div className="itemsInfo">
              <div className="itemInfo">
                <Icon name="icon-edificio" color="#fff" size={25} />
              </div>
              <div className="itemInfo">
                <Icon name="icon-garage" color="#fff" size={25} />
              </div>
              <div className="itemInfo">
                <Icon name="icon-warehouse" color="#fff" size={25} />
              </div>
            </div>
            <div className="item-continue">
              <div className="item-continue-inner-container">
                <p><strong>Encuentra tu próximo hogar en Level.</strong> <br /> Arrienda un departamento, bodega o estacionamiento.</p>
                <Icon onClick={openSearchMobile} name="icon-button-arrow-ight" color="#fff" size={20} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {rizeWidth < BREAKPOINT_MOBILE && <SearchMobile show={showSearchMobile} close={closeSearchMobile} identifyStep="2" />}

    </section>
  );
}

export default DestacadoInfoMobile;
