import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { FILTER_TYPE } from "../config/constant";
import {
  INITIAL_BODEGAS,
  INITIAL_ESTACIONAMIENTOS,
  selectAllFiltros,
  setFiltroAmoblado,
  setFiltroBanos,
  setFiltroBodegas,
  setFiltroBodegasPrecioMax,
  setFiltroBodegasPrecioMin,
  setFiltroDormitorios,
  setFiltroEdificios,
  setFiltroEntregaInmediata,
  setFiltroEstacionamientos,
  setFiltroEstacionamientosPrecioMax,
  setFiltroEstacionamientosPrecioMin,
  setFiltroEstrenar,
  setFiltroMascota,
  setFiltroOfertas,
  setFiltroPrecioMax,
  setFiltroPrecioMin,
  setFiltroSuperficieMax,
  setFiltroSuperficieMin,
  setFiltroUbicaciones,
} from "../redux/proyectosSlice";
import { comunaFilterSetGet, edificioFilterSetGet } from "../utils";
import { Badge } from "./ui/index";

// Componente que muestra badges de todos los filtros de Redux.
const FiltrosBadges = ({ filterType, segmento }) => {
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const filtros = useSelector(selectAllFiltros);

  const onDormitorioBadgeClick = () => {
    dispatch(setFiltroDormitorios(null));
  };

  const onBodegaBadgeClick = () => {
    dispatch(setFiltroBodegas(null));
    const paramsSearch = new URLSearchParams(location.search);
    paramsSearch.delete("bodegas");
    history.push({
      pathname: segmento,
      search: paramsSearch.toString(),
    });
  };

  const onEstacionamientoBadgeClick = () => {
    dispatch(setFiltroEstacionamientos(null));
    const paramsSearch = new URLSearchParams(location.search);
    paramsSearch.delete("estacionamiento");
    history.push({
      pathname: segmento,
      search: paramsSearch.toString(),
    });
  };

  const onBanoBadgeClick = () => {
    dispatch(setFiltroBanos(null));
  };

  const onUbicacionBadgeClick = (ubicacion) => {
    const newFiltroUbicaciones = filtros.ubicaciones.filter(
      (ubi) => ubi !== ubicacion
    );
    dispatch(setFiltroUbicaciones(newFiltroUbicaciones));
    const paramsSearch = new URLSearchParams(location.search);
    if (newFiltroUbicaciones.length === 0) {
      paramsSearch.delete("comuna");
    } else {
      const getComunas = comunaFilterSetGet(newFiltroUbicaciones);
      paramsSearch.set("comuna", getComunas);
    }
    history.push({
      pathname: segmento,
      search: paramsSearch.toString(),
    });
  };

  const onEdificioBadgeClick = (direccion) => {
    const newFiltroEdificios = filtros.edificios.filter(
      (dir) => dir !== direccion
    );
    dispatch(setFiltroEdificios(newFiltroEdificios));

    const paramsSearch = new URLSearchParams(location.search);
    if (newFiltroEdificios.length === 0) {
      paramsSearch.delete("edificio");
    } else {
      const getEdificios = edificioFilterSetGet(newFiltroEdificios);
      paramsSearch.set("edificio", getEdificios);
    }
    history.push({
      pathname: segmento,
      search: paramsSearch.toString(),
    });
  };

  const onMascotaBadgeClick = () => {
    dispatch(setFiltroMascota(false));
    const paramsSearch = new URLSearchParams(location.search);
    paramsSearch.delete("mascota");
    history.push({
      pathname: segmento,
      search: paramsSearch.toString(),
    });
  };

  const onEntregaInmediataBadgeClick = () => {
    dispatch(setFiltroEntregaInmediata(false));
    const paramsSearch = new URLSearchParams(location.search);
    paramsSearch.delete("entregaInmediata");
    history.push({
      pathname: segmento,
      search: paramsSearch.toString(),
    });
  };

  const onOfertasBadgeClick = () => {
    dispatch(setFiltroOfertas(false));
    const paramsSearch = new URLSearchParams(location.search);
    paramsSearch.delete("oferta");
    history.push({
      pathname: segmento,
      search: paramsSearch.toString(),
    });
  };

  const onEstrenarBadgeClick = () => {
    dispatch(setFiltroEstrenar(false));
    const paramsSearch = new URLSearchParams(location.search);
    paramsSearch.delete("estrenara");
    history.push({
      pathname: segmento,
      search: paramsSearch.toString(),
    });
  };

  const onPrecioMinBadgeClick = () => {
    switch (filterType) {
      case FILTER_TYPE.PROYECTOS:
        document.getElementById("filtro-input-precio-min").value = "";
        dispatch(setFiltroPrecioMin(null));
        break;

      case FILTER_TYPE.ESTACIONAMIENTOS:
        document.getElementById("filtro-input-precio-min").value = "";
        dispatch(setFiltroEstacionamientosPrecioMin(null));
        break;

      case FILTER_TYPE.BODEGAS:
        document.getElementById("filtro-input-precio-min").value = "";
        dispatch(setFiltroBodegasPrecioMin(null));
        break;

      default:
        console.log(
          `Filtro -> onPrecioMinBadgeClick: tipo filtro ${filterType} desconocido!`
        );
        break;
    }
    const paramsSearch = new URLSearchParams(location.search);
    paramsSearch.delete("precioMin");
    history.push({
      pathname: segmento,
      search: paramsSearch.toString(),
    });
  };

  const onPrecioMaxBadgeClick = () => {
    switch (filterType) {
      case FILTER_TYPE.PROYECTOS:
        document.getElementById("filtro-input-precio-max").value = "";
        dispatch(setFiltroPrecioMax(null));
        break;

      case FILTER_TYPE.ESTACIONAMIENTOS:
        document.getElementById("filtro-input-precio-max").value = "";
        dispatch(setFiltroEstacionamientosPrecioMax(null));
        break;

      case FILTER_TYPE.BODEGAS:
        document.getElementById("filtro-input-precio-max").value = "";
        dispatch(setFiltroBodegasPrecioMax(null));
        break;

      default:
        console.log(
          `Filtro -> onPrecioMaxBadgeClick: tipo filtro ${filterType} desconocido!`
        );
        break;
    }

    const paramsSearch = new URLSearchParams(location.search);
    paramsSearch.delete("precioMax");
    history.push({
      pathname: segmento,
      search: paramsSearch.toString(),
    });
  };

  const onSuperficieMinBadgeClick = () => {
    dispatch(setFiltroSuperficieMin(null));
    const paramsSearch = new URLSearchParams(location.search);
    paramsSearch.delete("superficieMin");
    history.push({
      pathname: segmento,
      search: paramsSearch.toString(),
    });
  };

  const onSuperficieMaxBadgeClick = () => {
    dispatch(setFiltroSuperficieMax(null));
    const paramsSearch = new URLSearchParams(location.search);
    paramsSearch.delete("superficieMax");
    history.push({
      pathname: segmento,
      search: paramsSearch.toString(),
    });
  };

  const onAmobladoBadgeClick = () => {
    dispatch(setFiltroAmoblado(null));
    const paramsSearch = new URLSearchParams(location.search);
    paramsSearch.delete("amueblado");
    history.push({
      pathname: segmento,
      search: paramsSearch.toString(),
    });
  };

  // Badges de filtros activos
  const dormitoriosBadge = (
    <Badge onClick={onDormitorioBadgeClick}>{`${filtros.dormitorios} ${
      filtros.dormitorios > 1 ? "dormitorios" : "dormitorio"
    }`}</Badge>
  );

  const bodegasBadge =
    filtros.bodegas > INITIAL_BODEGAS ? (
      <Badge onClick={onBodegaBadgeClick}>{`${filtros.bodegas} ${
        filtros.bodegas === 0 || filtros.bodegas > 1 ? "bodegas" : "bodega"
      }`}</Badge>
    ) : null;

  const estacionamientosBadge =
    filtros.estacionamientos > INITIAL_ESTACIONAMIENTOS ? (
      <Badge onClick={onEstacionamientoBadgeClick}>{`${
        filtros.estacionamientos
      } ${
        filtros.estacionamientos === 0 || filtros.estacionamientos > 1
          ? "estacionamientos"
          : "estacionamiento"
      }`}</Badge>
    ) : null;

  const banosBadge = (
    <Badge onClick={onBanoBadgeClick}>{`${filtros.banos} ${
      filtros.banos === 0 || filtros.banos > 1 ? "baños" : "baño"
    }`}</Badge>
  );

  const ubicacionesBadges = filtros.ubicaciones.map((ubi) => (
    <Badge key={ubi} onClick={() => onUbicacionBadgeClick(ubi)}>
      {ubi}
    </Badge>
  ));

  const precioMinBadge =
    filtros.precioMin !== Number.MIN_SAFE_INTEGER ? (
      <Badge onClick={() => onPrecioMinBadgeClick()}>
        Precio min ${filtros.precioMin.toLocaleString()}
      </Badge>
    ) : null;

  const precioMaxBadge =
    filtros.precioMax !== Number.MAX_SAFE_INTEGER ? (
      <Badge onClick={() => onPrecioMaxBadgeClick()}>
        Precio max ${filtros.precioMax.toLocaleString()}
      </Badge>
    ) : null;

  const superficieMinBadge =
    filtros.superficieMin !== Number.MIN_SAFE_INTEGER ? (
      <Badge onClick={() => onSuperficieMinBadgeClick()}>
        Superficie min {filtros.superficieMin}m<sup>2</sup>
      </Badge>
    ) : null;

  const superficieMaxBadge =
    filtros.superficieMax !== Number.MAX_SAFE_INTEGER ? (
      <Badge onClick={() => onSuperficieMaxBadgeClick()}>
        Superficie max {filtros.superficieMax}m<sup>2</sup>
      </Badge>
    ) : null;

  const amobladoBadge = filtros.amoblado ? (
    <Badge onClick={() => onAmobladoBadgeClick()}>Amoblado</Badge>
  ) : null;

  const edificiosBadges = filtros.edificios.map((dir) => (
    <Badge key={dir} onClick={() => onEdificioBadgeClick(dir)}>
      {dir}
    </Badge>
  ));

  const mascotaBadge = filtros.mascota ? (
    <Badge onClick={() => onMascotaBadgeClick()}>Acepta mascota</Badge>
  ) : null;

  const entregaInmediataBadge = filtros.entregaInmediata ? (
    <Badge onClick={() => onEntregaInmediataBadgeClick()}>
      Entrega inmediata
    </Badge>
  ) : null;

  const ofertasBadge = filtros.ofertas ? (
    <Badge onClick={() => onOfertasBadgeClick()}>Sólo ofertas</Badge>
  ) : null;

  const estrenarBadge = filtros.estrenar ? (
    <Badge onClick={() => onEstrenarBadgeClick()}>A estrenar</Badge>
  ) : null;

  return (
    <div className="tag-search hide-mobile">
      {ubicacionesBadges}
      {edificiosBadges}
      {(filterType === FILTER_TYPE.PROYECTOS ||
        filterType === FILTER_TYPE.TIPOLOGIAS_MOBILE) && (
        <>
          {dormitoriosBadge}
          {banosBadge}
        </>
      )}
      {bodegasBadge}
      {estacionamientosBadge}
      {precioMinBadge}
      {precioMaxBadge}
      {superficieMinBadge}
      {superficieMaxBadge}
      {amobladoBadge}
      {mascotaBadge}
      {entregaInmediataBadge}
      {ofertasBadge}
      {estrenarBadge}
    </div>
  );
};

export default FiltrosBadges;
