import React from 'react';
import { BREAKPOINT_MOBILE } from '../../../config/constant';
import useResize from '../../../hooks/useResize';
import SliderMobile from '../../containers/SliderMobile';
import { Button } from '../../ui/index';
import IngresoResidentes from './IngresoResidentes';
import styles from './styles/CreamosComunidad.module.scss';


const CreamosComunidad = ({ showModalLogin, comunidadData }) => {

  const [rizeWidth] = useResize();

  return (
    <div className={styles.innerContainer}>
      <div className={styles.leftBlock}>
        <h2>{comunidadData.header}</h2>
        <p>{comunidadData.text}</p>
        <p>{comunidadData.textBold}</p>
      </div>
      {
        rizeWidth > BREAKPOINT_MOBILE &&

        <div className={styles.rightBlock}>
          <IngresoResidentes
            comunidadData={comunidadData}
            showModalLogin={showModalLogin}
            topContainerStyle={{ position: 'relative', top: '25px' }}
          />
        </div>
      }
      {
        rizeWidth <= BREAKPOINT_MOBILE &&

        <>
          <Button
            color='btn-outline-orange'
            size="medium"
            startIcon="icon-secured-lock"
            fullWidth={true}
            style={{
              marginBottom: '30px',
            }}
          >
            ACCESO RESIDENTES
          </Button>

          <SliderMobile
            home={false}
            imgArray={comunidadData.sliderMobile.map((slide) => {
              return {
                src: slide.url,
                alt: slide.alt,
              };
            })}
          />
        </>
      }
    </div>
  );
}

export default CreamosComunidad;
