import React from 'react';
import {
  BREAKPOINT_MOBILE,
  DESK_SCROLL_HEADER_OFFSET,
  MOBILE_SCROLL_HEADER_OFFSET
} from '../../../config/constant';
import useResize from '../../../hooks/useResize';
import { scrollTo } from '../../../utils';
import styles from './styles/HtmlFaq.module.scss';


// Renderiza un elemento FAQ del backend. Asume que el objeto `faq` tiene la forma
// { html }.
const HtmlFaq = ({ faq }) => {

  const [rizeWidth] = useResize();

  if (!faq) {
    return null;
  }

  const scrollOffset = rizeWidth > BREAKPOINT_MOBILE ? DESK_SCROLL_HEADER_OFFSET : MOBILE_SCROLL_HEADER_OFFSET;

  let sanitizedHtml = faq.html.replace(/className={styles.topContainer}/g, `class=${styles.topContainer}`);
  sanitizedHtml = sanitizedHtml.replace(/className={styles.guiaContainer}/g, `class=${styles.guiaContainer}`);
  sanitizedHtml = sanitizedHtml.replace(/className={styles.link}/g, `class=${styles.link}`);
  sanitizedHtml = sanitizedHtml.replace(/className={styles.listaRequisitos}/g, `class=${styles.listaRequisitos}`);
  sanitizedHtml = sanitizedHtml.replace(/style={{ marginBottom: '(.*)' }}/g, `style="margin-bottom:$1;"`);

  const onHtmlClick = (event) => {
    // Los elementos de la lista del índice empiezan con "list-[content]", y los headers del contenido de la
    // sección correspondiente tienen id "[content]". Por ejemplo, el contenido "¿Qué es la reserva?" tiene
    // un header cuyo id es "reserva", y el elemento de lista correspondiente en el índice es "list-reserva".
    // Lo que hacemos acá es implementar el scrolling hacia el contenido adecuado cuando el elemento de lista
    // es clickeado.
    if (event.target.id.startsWith('list-')) {
      const scrollTargetId = event.target.id.replace('list-', '');
      scrollTo(scrollTargetId, scrollOffset);
    }
  }


  return (
    <div onClick={onHtmlClick} dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
  );
}

export default HtmlFaq;
