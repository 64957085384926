import React from 'react';
import styles from './SalaArriendoPopup.module.scss';
import backgroundAccesoSala from '../../static/images/sala_arriendo/background-acceso-sala.jpg';
import iconCerrar from '../../static/images/sala_arriendo/cerrar.png';
import iconZoom from '../../static/images/sala_arriendo/icon-zoom.png';
import iconAgenda from '../../static/images/sala_arriendo/icon-agenda.png';
import useResize from '../../hooks/useResize';
import { BREAKPOINT_MOBILE } from '../../config/constant';


// Componente auxiliar: Cada una de los botones del popup (e.g. "Inicia sesión en Zoom..." y
// "Agenda tu...")
const OptionButton = ({ containerStyle, img, text, onClick }) => {

  return (
    <div className={styles.optionButtonContainer} style={containerStyle}>
      <div className={styles.optionButtonInnerContainer}>
        {img && <img src={img.url} alt={img.alt} onClick={onClick} />}
        <p onClick={onClick} style={text.style}>{text.content}</p>
      </div>
    </div>
  );
}

// Propiedad `top` del botón de Zoom, expresado en porcentaje
let ZOOM_BTN_TOP_PERCENTAGE = 55;

// Componente auxiliar: Botón de Zoom
const ZoomButton = ({ onClick }) => {

  const [rizeWidth] = useResize();

  if (rizeWidth <= BREAKPOINT_MOBILE) {
    ZOOM_BTN_TOP_PERCENTAGE = 50;
  }

  return (
    <OptionButton
      containerStyle={{ top: `${ZOOM_BTN_TOP_PERCENTAGE}%` }}
      img={{ url: iconZoom, alt: 'icon-zoom' }}
      text={{ content: "Inicia sesión en Zoom y conéctate con un ejecutivo disponible" }}
      onClick={onClick}
    />
  );
}

// Propiedad `top` del botón de Agenda, expresado en porcentaje
const AGENDA_TOP_PERCENTAGE = ZOOM_BTN_TOP_PERCENTAGE + 22;

// Componente auxiliar: Botón de Agenda
const AgendaButton = ({ onClick }) => {

  return (
    <OptionButton
      containerStyle={{ top: `${AGENDA_TOP_PERCENTAGE}%` }}
      img={{ url: iconAgenda, alt: 'icon-agenda' }}
      text={{ content: "Agenda tu atención online", style: { width: '70%' } }}
      onClick={onClick}
    />
  );
}


// Componente de popup de sala de arriendo
const SalaArriendoPopup = ({ onCloseClick, zoomOnClick, agendaOnClick }) => {

  return (
    <div className={styles.container}>
      <img onClick={onCloseClick} className={styles.iconCerrar} src={iconCerrar} alt="cerrar" />
      <img src={backgroundAccesoSala} alt="sala-arriendo" />
      <ZoomButton onClick={zoomOnClick} />
      <AgendaButton onClick={agendaOnClick} />
    </div>
  );
}

export default SalaArriendoPopup;
