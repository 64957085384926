/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Modal, Icon, TextInfo } from '../../../ui/index';
import styles from './styles/ModalImagen.module.scss';
import notFound from '../../../../static/images/notFound.png';


// Modal para mostrar una imagen.
const ModalImagen = ({ show, close, imagenUrl, altText, closeText }) => {
  const imgUrl = imagenUrl && imagenUrl !== '' ? imagenUrl : notFound;

  return (
    <Modal show={show} close={close}>
      <div className="modal-content help md fade" style={{ width: 'initial' }}>
        <div className="header-modal" style={{ borderBottom: 'none' }}>
          <a onClick={close}><Icon name="icon-close" size="14" /></a>
        </div>
        <div className="_body-help detalleSlicitud">
          <div className={styles.imgContainer}>
            <img src={imgUrl} alt={altText ? altText : 'imagen-modal'} />
          </div>
        </div>
        <div onClick={close} className="_footer-help">
          <TextInfo>{closeText}</TextInfo>
        </div>
      </div>
  </Modal>
  );
};

export default ModalImagen;
