import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectFilteredProyectosByBodegas } from '../redux/proyectosSlice';
import Layout from '../components/Layout';
import ModalVisita from '../components/ModalVisita.js';
import Filtro from '../components/containers/Filtro';
import Oferta from '../components/containers/Oferta';
import CardEdificio from '../components/containers/CardEdificio';
import CardAlerta from '../components/containers/CardAlerta';
import CardEdificioExtra from '../components/containers/CardEdificioExtra';
import Paginate from '../components/containers/Paginate';
import useResize from '../hooks/useResize';
import { BREAKPOINT_MOBILE, FILTER_TYPE } from '../config/constant';
import ResultMobile, { TIPO_RESULT } from '../components/ResultMobile';
import Entrada, { TIPO_ENTRADA } from '../components/Cotizar/layers/Entrada';
import { scrollToTop } from '../utils';


const Bodegas = ({ match }) => {

  const [rizeWidth] = useResize();
  const [showVisita, setShowVisita] = useState(false);
  const [showEntrada, setShowEntrada] = useState(false);
  const [entradaProyecto, setEntradaProyecto] = useState(null);
  const pageCurrent = match.path;

  // Para mostrar un alert si hay mucha gente buscando en la misma zona.
  // FIXME: Esto habría levantarlo del backend.
  const hotZone = false;

  // Para mostrar edificios extra o no (FIXME: quizás podemos prescindir de esto?)
  const extraBuildings = false;

  // Proyectos filtrados de acuerdo a los filtros que hay en Redux
  const proyectosFiltered = useSelector(selectFilteredProyectosByBodegas);

  useEffect(() => {
    scrollToTop();
  }, []);

  const openEntrada = (proyecto) => {
    setEntradaProyecto(proyecto);
    setShowEntrada(true);
  }

  const closeEntrada = () => {
    setShowEntrada(false);
  }

  // Array de cartas de edificios. Mapeamos sobre los proyectos filtrados.
  const edificioCardItems = proyectosFiltered.map((proy) => {
    return (
      <CardEdificio key={proy._id} proyecto={proy} bodegaCard={true} openEntrada={openEntrada} />
    );
  });

  // Determina si se muestra la paginación o no
  const PAGINATION_ENABLED = false;

  // Open Modal login
  const openVisita = () => {
    setShowVisita(true);
  }

  // Close Modal Login
  const closeVisita = () => {
    setShowVisita(false);
  }

  return (
    <Layout pageCurrent={pageCurrent}>
      {rizeWidth > BREAKPOINT_MOBILE ? (
        <>
          <div className="container">
            <div className="content-result">
              <Filtro
                openVisita={openVisita}
                resultMap={false}
                type={FILTER_TYPE.BODEGAS}
              />
              <div className="result-main">
                <Oferta />
                {hotZone && <CardAlerta icon="icon-alarm" text="¡Muchas personas buscando en esta misma zona. <strong>Cotiza tu arriendo ahora.</strong>" />}
                {edificioCardItems}
                {extraBuildings && <CardEdificioExtra />}
                {PAGINATION_ENABLED && <Paginate />}
              </div>
            </div>
          </div>
          <ModalVisita
            show={showVisita}
            close={closeVisita}
          />
          {
            showEntrada &&

            <Entrada
              proyecto={entradaProyecto}
              close={closeEntrada}
              tipoEntrada={TIPO_ENTRADA.BODEGAS}
            />
          }
        </>
      ) : (
        <ResultMobile tipoResult={TIPO_RESULT.BODEGAS} />
      )}
    </Layout>
  );
}

export default Bodegas;
