import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-elastic-carousel';
import { TextInfo, Button, Icon } from '../ui/index';
import PrecioMensual from '../PrecioMensual';
import {
  getMinAndMaxBanos,
  getMinAndMaxDormitorios,
  getPlaceUrl
} from '../../utils';
import notFound from '../../static/images/notFound.png';
import './styles/CardPlaceGenerico.scss';
import { BACKEND_BASE_URL, FEATURE_ENABLED } from '../../config/constant';
import GastoComunProyecto from '../GastoComunProyecto';
import PrecioMensualEstacionamientos from '../PrecioMensualEstacionamientos';
import PrecioMensualBodegas from '../PrecioMensualBodegas';


const GALLERY_MAX_IMAGES = 6;


// Componente de carta de mapa. Esta es la carta que se muestra cuando el usuario clickea sobre un
// pin en el mapa. Contiene información sobre el proyecto.
const CardPlaceGenerico = ({
  proyecto,
  gastos,
  btnPreview = false,
  pocasUnidades = false,
  cardType,
  detallesClickCallback,
}) => {

  let totalImage = 1;
  const [currentImage, setCurrentImage] = useState(1);
  const btnRef = useRef();
  const [liked, setLiked] = useState(false);


  const eventPreview = (preview) => {
    if (preview === 'PREV') {
      btnRef.current.slidePrev()
    }
    if (preview === 'NEXT') {
      btnRef.current.slideNext()
    }
  }

  let direccionStr = '';
  let comunaStr = '';
  let dormitoriosStr = '';
  let banosStr = '';
  let itemsSlider = [
    <div key={0} className="item-slider">
      <img src={notFound} alt="not-found" />
    </div>,
  ];

  if (proyecto) {
    direccionStr = proyecto.direccion;
    comunaStr = proyecto.comuna;

    const minMaxDormitorios = getMinAndMaxDormitorios(proyecto);
    dormitoriosStr = `${minMaxDormitorios.min} - ${minMaxDormitorios.max}`;

    const minMaxBanos = getMinAndMaxBanos(proyecto);
    banosStr = `${minMaxBanos.min} - ${minMaxBanos.max}`;

    // Seleccionar galería dependiendo del tipo de card
    let galeriaName;

    switch (cardType) {
      case 'estacionamientos':
        galeriaName = 'estacionamiento_galeria';
        break;

      case 'bodegas':
        galeriaName = 'bodega_galeria';
        break;

      default:
        galeriaName = 'galeria';
    }

    if (proyecto[galeriaName].length > 0) {
      const galeria = proyecto[galeriaName].slice(0, GALLERY_MAX_IMAGES);
      totalImage = galeria.length;

      itemsSlider = galeria.map((galeriaItem) => {
        const imageUrl = galeriaItem.formats.medium ? `${BACKEND_BASE_URL}${galeriaItem.formats.medium.url}`
          : galeriaItem.formats.small ? `${BACKEND_BASE_URL}${galeriaItem.formats.small.url}` : notFound;

        return (
          <div key={galeriaItem.id} className="item-slider">
            <img src={imageUrl} alt={galeriaItem.alternativeText} />
          </div>
        );
      });
    }
  }

  const onLikeClick = () => {
    setLiked(!liked);
  }

  const onDetallesClick = () => {
    if (detallesClickCallback) {
      detallesClickCallback(proyecto);
    }
  }

  const heartClass = liked ? 'icon-heart-like' : 'icon-heart';

  let precioMensual = null;
  let verDetalles = null;

  switch (cardType) {
    case 'estacionamientos':
      precioMensual = <PrecioMensualEstacionamientos proyecto={proyecto} />;
      verDetalles = <Button onClick={onDetallesClick} color="btn-orange" className="btn bold extra-small">VER DETALLES</Button>;
      break;

    case 'bodegas':
      precioMensual = <PrecioMensualBodegas proyecto={proyecto} />;
      verDetalles = <Button onClick={onDetallesClick} color="btn-orange" className="btn bold extra-small">VER DETALLES</Button>;
      break;

    default:
      precioMensual = <PrecioMensual proyecto={proyecto} />;
      verDetalles = (
        <Link to={getPlaceUrl(proyecto)}>
          <Button color="btn-orange" className="btn bold extra-small">VER DETALLES</Button>
        </Link>
      );
  }

  return (
    <div className={`placeItems`}>
      <div className="slider-image-map">
        {pocasUnidades && <span className="alert-place danger">Pocas unidades disponibles</span>}
        {
          FEATURE_ENABLED.LIKES &&

          <Icon onClick={onLikeClick} name={heartClass} border="round" background="white" color="#FF6D00" width={26} size={12} />
        }
        <span className="countImage">{currentImage} / {totalImage}</span>

        {btnPreview &&
          <div className="btn-preview">
            <button className="bt-prev" onClick={() => eventPreview('PREV')}></button>
            <button className="bt-next" onClick={() => eventPreview('NEXT')}></button>
          </div>
        }

        <Carousel
          ref={btnRef}
          itemsToScroll={1}
          itemsToShow={1}
          enableAutoPlay={false}
          showArrows={false}
          autoPlaySpeed={5000}
          onNextStart={(currentItem, nextItem) => {
            if (currentImage < totalImage) {
              setCurrentImage(currentImage + 1);
            }
          }}
          onPrevStart={(currentItem, nextItem) => {
            if (currentImage > 0) {
              setCurrentImage(currentImage - 1);
            }
          }}
          onChange={(_, currentIndex) => setCurrentImage(currentIndex + 1)}
          className="slider-galery-image-place">
          {itemsSlider}
        </Carousel>

      </div>
      <div className="info">
        <TextInfo textWeight="bold" color="black" size="18" className="proyecto-direccion">{direccionStr}</TextInfo>
        <TextInfo iconColor="#FF6D00" className="lugar" iconName="icon-location-pin" size="14">{comunaStr}</TextInfo>
        <div className="option-edificio">
          <TextInfo iconColor="#848390" iconName="icon-bed" size="14">{dormitoriosStr}</TextInfo>
          <TextInfo iconColor="#848390" iconName="icon-bath" size="14">{banosStr}</TextInfo>
        </div>
        <div className="info-option">
          <div className="info-price">
            <span className="price-text">Arriendo desde</span>
            <span className="price text-orange">{precioMensual}</span>
          </div>
          {verDetalles}
        </div>
      </div>
      {gastos && <div className="gastos">Gastos comunes desde <GastoComunProyecto proyecto={proyecto} /></div>}
    </div>
  );
};

export default CardPlaceGenerico;
