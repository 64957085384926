/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import addDays from 'date-fns/addDays';
import { Modal, Icon, TextInfo, Button, Input, Select, TextArea } from './ui/index';
import DatePicker from 'react-datepicker';
import './styles/ModalGenerico.scss';
import 'react-datepicker/dist/react-datepicker.css';


const ModalVisita = ({ show, close }) => {

  const [startDate, setStartDate] = useState(new Date());

  return (
    <Modal show={show} close={close}>
      <div className="modal-content modal-generico large fade">
        <div className="header-modal">
          <a onClick={close}><Icon name="icon-close" size="14" /></a>
          Agendar visita
        </div>
        <div className="modal-body">
          <h4 className="card-title">Agenda una visita a nuestros edificios</h4>
          <div className="box-cards">
            <div className="box-card">
              <TextInfo className="title-card" textWeight="bold" color="black">Información de contacto</TextInfo>
              <Input type="text" classes="small mb-1" fullWidth={true} placeholder="Ingresa tu nombre" />
              <Input type="text" classes="small mb-1" fullWidth={true} placeholder="Ingresa tu teléfono" />
              <TextArea className="mb-1" rows="3" placeholder="¿Quieres dejar un mensaje?" />

              <TextInfo className="title-card" textWeight="bold" color="black">¿Cuál edificio quieres visitar?</TextInfo>
              <Select className="small" placeholder="Selecciona un edificio" typeStyle="small" fullWidth={true} />
            </div>
            <div className="box-card">
              <TextInfo className="title-card" textWeight="bold" color="black">Selecciona una fecha deseada</TextInfo>
              <DatePicker
                dateFormat="dd.MM.yyyy"
                selected={startDate}
                onChange={date => setStartDate(date)}
                minDate={new Date()}
                maxDate={addDays(new Date(), 5)}
                inline
              />
            </div>
          </div>
          <Button className="btn btn-submit" color='btn-orange' fullWidth={true} size="small">AGENDAR VISITA</Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalVisita;
