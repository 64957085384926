import React, { useState } from 'react';
import './styles/CardPlaceUbicacion.scss';
import { Title, TextInfo, Button } from '../ui/index';
import ModalFiltroInteres from './ModalFiltroInteres';
import Map from './../Map/index';


const CardPlaceUbicacion = ({ proyecto }) => {

  const [showFiltro, setShowFiltro] = useState(false);

  const openFiltro = () => {
    setShowFiltro(true);
  }

  const closeFiltro = () => {
    setShowFiltro(false);
  }

  const metro = proyecto && proyecto.cercano_metro ? proyecto.cercano_metro : null;
  const misc = proyecto && proyecto.cercano_misc ? proyecto.cercano_misc : null;

  return (
    <div className="row">
      <div className="place-ubi-container">
        <Title id="card-place-ubicacion-header" className="muli" size="30" marginBotton={10}>Ubicación</Title>
        <div className="ubi-cols">
          <div className="ubi-col map">
            <Map proyecto={proyecto} />
          </div>
          <div className="ubi-col map-info">
            <TextInfo color="black" size="20">{proyecto ? proyecto.ubicacion_titulo : ''}</TextInfo>
            <TextInfo iconName="icon-tick" color="success" textWeight="bold">El 85% de los residentes destacan la seguridad del barrio.</TextInfo>
            <p>{proyecto ? proyecto.ubicacion_descripcion : ''}</p>
            {metro && <TextInfo textWeight="bold" iconName="icon-subte" iconSize="18" color="black">Cercano a Metro {metro}</TextInfo>}
            {misc && <TextInfo textWeight="bold" iconName="icon-arrow-side" color="black">Cercano a {misc}</TextInfo>}
            {/* <Button onClick={() => openFiltro()} className="btn bold" size="extra-small" color="btn-outline-orange" fullWidth={false} startIcon="icon-adjust">PUNTOS DE INTERÉS</Button> */}
          </div>
        </div>
      </div>
      <ModalFiltroInteres
        show={showFiltro}
        close={closeFiltro}
      />
    </div>
  );
};

export default CardPlaceUbicacion;
