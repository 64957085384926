import React from 'react';
import PropTypes from 'prop-types';
import './Input.scss';
import Icon from '../Icon';


const Input = props => {
  const { 
    label = '',
    labelStyle,
    type = 'text',
    className='',
    classes = '',
    fullWidth = false,
    typeStyle = 'default',
    help = '',
    info = false,
    infoOnClick = null,
    infoColor = false,
    formGroupStyle = null,
    inputStyle = null,
    ...other
  } = props;

  const width = fullWidth ? 'full-width' : '';

  return(
    <div className={`form-group ${width} ${className}`} style={formGroupStyle}>
      { label !== '' &&
        <label style={labelStyle}>{label} { info && <Icon onClick={infoOnClick} size={18} color={infoColor && '#FF6D00'} name="icon-question"/>}</label>
      }
      <input 
        type={type} 
        className={`form-input ${classes} ${typeStyle}`} 
        style={inputStyle}
        {...other} 
      />
      {help && <small>{help}</small>}
    </div>
  );
}

Input.propTypes = {
  name: PropTypes.string,
  classes: PropTypes.string,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.any,
  typeStyle: PropTypes.oneOf(['defauld', 'filtro', 'filtro-gray', 'filtro-white']),
}

export default Input;
