import React from 'react';


// Devuelve los gastos comunes mínimos de los estacionamientos (de auto y moto) del proyecto.
const GastoComunEstacionamientos = ({ proyecto }) => {

  const gastoMinEstMotos = proyecto.gastoComunMinEstacionamientosMoto;
  const gastoMinEstAutos = proyecto.gastoComunMinEstacionamientosAuto;
  const gastoMin = Math.min(gastoMinEstMotos, gastoMinEstAutos);

  return (
    <span>${gastoMin.toLocaleString()}</span>
  );
}

export default GastoComunEstacionamientos;
