import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import HubspotContactForm from '../HubspotContactForm';

import {
  selectSendMessageErrorMessage,
  selectSendMessageStatus
} from '../../redux/proyectosSlice';
import { DEFAULT_TIPO_CONSULTA_TEXT_MOBILE } from './constants';
import ContactoFormMobile from './ContactoFormMobile';
import MotivoContacto from './MotivoContacto';
import styles from './styles/ContactoBoxMobile.module.scss';


const ContactoBoxMobile = ({ defaultMotivo = DEFAULT_TIPO_CONSULTA_TEXT_MOBILE }) => {

  const [motivo, setMotivo] = useState(defaultMotivo);

  const updateMotivo = (newMotivo) => {
    setMotivo(newMotivo);
  }

  // Mostrar toast de éxito/error.
  const sendMessageStatus = useSelector(selectSendMessageStatus);
  const sendMessageErrorMessage = useSelector(selectSendMessageErrorMessage);

  useEffect(() => {
    if (sendMessageStatus === 'succeeded') {
      toast.success('Mensaje enviado exitosamente!');
    } else if (sendMessageStatus === 'failed' && sendMessageErrorMessage !== '') {
      toast.error(`No se pudo enviar el mensaje!`);
      console.log(`Error al enviar mensaje a Strapi: ${sendMessageErrorMessage}`);
    }
  }, [sendMessageErrorMessage, sendMessageStatus]);

  return (
    <div className={`container ${styles.topLevelContainer}`}>
      <div className={styles.innerContainer}>
        <HubspotContactForm portalId='39563790' formId='ff9a01b7-88f3-44a9-a774-464d85715fd6' />
        {/* <iframe
          title='form'
          src="https://apps1.buildingclerk.cl/postventa/formularios/level/sac.html"
          style={{ width: '100%', height: '98%' }}
        >
        </iframe> */}
      </div>
    </div>
  );
}

export default ContactoBoxMobile;
