import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Layout from '../components/Layout';
import Hero from '../components/Contacto/Hero';
import useResize from '../hooks/useResize';
import { BREAKPOINT_MOBILE, CONTACTO_TABS } from '../config/constant';
import 'react-toastify/dist/ReactToastify.css';
import ContactoBox from '../components/Contacto/ContactoBox';
import ContactoBoxMobile from '../components/Contacto/ContactoBoxMobile';
import EjecutivosArriendo from '../components/Contacto/EjecutivosArriendo';
import { resetFiltros } from '../redux/proyectosSlice';
import HeroMobile from '../components/Contacto/HeroMobile';
import { dataLayerPush, scrollToTop } from '../utils';
import { useLocation } from 'react-router-dom';
import {
  CONSULTA,
  DEFAULT_TIPO_CONSULTA_TEXT_MOBILE,
  tipoConsultaToStr
} from '../components/Contacto/constants';


const EJECUTIVOS_ARRIENDO_ENABLED = true;


const Contacto = ({ match }) => {

  const [rizeWidth] = useResize();
  const pageCurrent = match.path;
  const dispatch = useDispatch();


  let defaultTab = CONTACTO_TABS.FORM;
  let defaultMotivo = DEFAULT_TIPO_CONSULTA_TEXT_MOBILE;

  if (pageCurrent === '/contacto/horarios') {
    defaultTab = CONTACTO_TABS.HORARIOS;
    defaultMotivo = tipoConsultaToStr(CONSULTA.HORARIOS);
  }

  // Resetear filtros y scrollear para arriba
  useEffect(() => {
    dispatch(resetFiltros());
    scrollToTop();
  }, [dispatch]);

  // Analytics
  const location = useLocation();

  useEffect(() => {
    dataLayerPush({
      event: 'vpv',
      page: location.pathname,
      section: 'contacto',
      url: window.location.href,
    });
  }, [location.pathname]);


  return (
    <Layout pageCurrent={pageCurrent}>
      {rizeWidth > BREAKPOINT_MOBILE &&
        <>
          <Hero />
          <ContactoBox defaultTab={defaultTab} />
          {EJECUTIVOS_ARRIENDO_ENABLED && <EjecutivosArriendo />}
        </>
      }
      {rizeWidth <= BREAKPOINT_MOBILE &&
        <>
          <HeroMobile />
          <ContactoBoxMobile defaultMotivo={defaultMotivo} />
          {EJECUTIVOS_ARRIENDO_ENABLED && <EjecutivosArriendo />}
        </>
      }
    </Layout>
  );
}

export default Contacto;
