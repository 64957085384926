import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { getInvalidEmailString, isValidEmail } from '../validation';
import { Button, Icon, Input, Modal } from './ui/index';
import { useDispatch } from 'react-redux';
import { changeEmail } from '../redux/userSlice';


const ModalChangeEmail = ({ show, close }) => {

  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');

  const onCambiarEmailClick = () => {
    if (email !== confirmEmail) {
      toast.error('Los emails no coinciden!');
      return;
    }

    if (!isValidEmail(email) || !isValidEmail(confirmEmail)) {
      toast.error(getInvalidEmailString());
      return;
    }

    const userData = {
      email,
    };

    dispatch(changeEmail(userData));

    if (close) {
      close();
    }
  }


  return (
    <Modal show={show} close={close}>
      <div className="modal-content modal-login fade">
        <div className="header-modal">
          <Icon onClick={close} name="icon-close" size="14" />
        </div>
        <div className="body-modal">
          <div className="form-content">
            <div className="formLogin">
              <form>
                <Input
                  type="email"
                  name="email-1"
                  typeStyle="filtro-white"
                  fullWidth={true}
                  placeholder="Nuevo email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  formGroupStyle={{ margin: "0" }}
                />

                <Input
                  type="email"
                  name="email-2"
                  typeStyle="filtro-white"
                  fullWidth={true}
                  placeholder="Confirmar nuevo email"
                  value={confirmEmail}
                  onChange={(e) => setConfirmEmail(e.target.value)}
                  formGroupStyle={{
                    margin: '0',
                  }}
                />
              </form>
            </div>
            <Button
              onClick={onCambiarEmailClick}
              className="btn btn-submit"
              color="btn-orange"
              size="small"
              style={{
                marginTop: '20px',
                marginBottom: '20px',
              }}
            >
              CAMBIAR EMAIL
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalChangeEmail;
