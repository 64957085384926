import React from 'react';
import { GroupRow, Input } from './ui/index';


// Componente que muestra dos inputs para ingresar superficie mínima/máxima.
// El componente es controlado: se le pasan los valores actuales con
// los props `superficieMin` y `superficieMax`, y se ejecutan los callbacks
// `onSuperficieMinChange` y `onSuperficieMaxChange` con el event.target.value
// como argumento cuando cambian los valores.
const InputSuperficie = ({
  superficieMin,
  superficieMax,
  onSuperficieMinChange,
  onSuperficieMaxChange,
}) => {

  return (
    <GroupRow>
      <Input
        min={0}
        value={superficieMin !== Number.MIN_SAFE_INTEGER ? superficieMin : ''}
        type="number"
        typeStyle="filtro"
        fullWidth={true}
        help="Desde"
        onChange={(event) => onSuperficieMinChange(event.target.value)}
      />
      <Input
        min={0}
        value={superficieMax !== Number.MAX_SAFE_INTEGER ? superficieMax : ''}
        type="number"
        typeStyle="filtro"
        fullWidth={true}
        help="Hasta"
        onChange={(event) => onSuperficieMaxChange(event.target.value)}
      />
    </GroupRow>
  );
}

export default InputSuperficie;
