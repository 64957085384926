import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectFilteredProyectos,
  selectFiltroAmoblado,
  selectGetProyectosStatus,
} from "../redux/proyectosSlice";
import Layout from "../components/Layout";
import ModalVisita from "../components/ModalVisita.js";
import Filtro from "../components/containers/Filtro";
import Oferta from "../components/containers/Oferta";
import CardEdificio from "../components/containers/CardEdificio";
import CardAlerta from "../components/containers/CardAlerta";
import CardEdificioExtra from "../components/containers/CardEdificioExtra";
import Paginate from "../components/containers/Paginate";
import useResize from "../hooks/useResize";
import { BREAKPOINT_MOBILE } from "../config/constant";
import ResultMobile from "../components/ResultMobile";
import { dataLayerPush, scrollToTop } from "../utils";
import { useLocation } from "react-router-dom";

const Result = ({ match }) => {
  const [rizeWidth] = useResize();
  const [showVisita, setShowVisita] = useState(false);
  const pageCurrent = match.path;

  // Para mostrar un alert si hay mucha gente buscando en la misma zona.
  // FIXME: Esto habría levantarlo del backend.
  const hotZone = false;

  // Para mostrar edificios extra o no (FIXME: quizás podemos prescindir de esto?)
  const extraBuildings = false;

  // Proyectos filtrados de acuerdo a los filtros que hay en Redux
  const proyectosFiltered = useSelector(selectFilteredProyectos);
  console.log('oro ', proyectosFiltered)
  // Indica si los precios son amoblados o no
  const amoblado = useSelector(selectFiltroAmoblado);

  // Array de cartas de edificios. Mapeamos sobre los proyectos filtrados.
  const edificioCardItems = proyectosFiltered.map((proy) => {
    return <CardEdificio key={proy._id} proyecto={proy} amoblado={amoblado} />;
  });

  // Determina si se muestra la paginación o no
  const PAGINATION_ENABLED = false;

  // Open Modal login
  const openVisita = () => {
    setShowVisita(true);
  };

  // Close Modal Login
  const closeVisita = () => {
    setShowVisita(false);
  };

  // Scrollear hasta arriba
  useEffect(() => {
    scrollToTop();
  }, []);

  // Analytics
  const location = useLocation();

  useEffect(() => {
    dataLayerPush({
      event: "vpv",
      page: location.pathname,
      section: "resultados de búsqueda",
      url: window.location.href,
    });
  }, [location.pathname]);

  const getProyectosStatus = useSelector(selectGetProyectosStatus);

  return (
    <Layout pageCurrent={pageCurrent}>
      {rizeWidth > BREAKPOINT_MOBILE ? (
        <>
          <div className="container">
            <div className="content-result">
              <Filtro openVisita={openVisita} resultMap={false} />
              <div className="result-main">
                <Oferta />
                {getProyectosStatus === "succeeded" &&
                  edificioCardItems.length === 0 && (
                    <CardAlerta
                      icon="icon-alarm"
                      text="¡No se han encontrado resultados para tu búsqueda!"
                    />
                  )}
                {hotZone && (
                  <CardAlerta
                    icon="icon-alarm"
                    text="¡Muchas personas buscando en esta misma zona. <strong>Cotiza tu arriendo ahora.</strong>"
                  />
                )}
                {edificioCardItems}
                {extraBuildings && <CardEdificioExtra />}
                {PAGINATION_ENABLED && <Paginate />}
              </div>
            </div>
          </div>
          <ModalVisita show={showVisita} close={closeVisita} />
        </>
      ) : (
        <ResultMobile />
      )}
    </Layout>
  );
};

export default Result;
