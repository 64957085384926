import React from 'react';
import './styles/CardInfoPlace.scss';
import { TextInfo } from '../ui/index';
import { BACKEND_BASE_URL } from '../../config/constant';


const CardInfoPlace = ({ proyecto }) => {

  let equipamientoItems = null;
  let seguridadItems = null;

  // Construir items de equipamiento y seguridad cuando la info del proyecto está disponible.
  if (proyecto) {
    equipamientoItems = proyecto.equipamientos.map((equip) => {
      console.log(equip);
      const iconUrl = equip.hasOwnProperty('icono') ? `${BACKEND_BASE_URL}${equip.icono.url}` : '';
      const iconAlt = equip.hasOwnProperty('alternativeText') ? equip.icono.alternativeText : '';

      return (
        <TextInfo key={equip.titulo_equipamiento} iconUrl={iconUrl} iconAlt={iconAlt}>{equip.titulo_equipamiento}</TextInfo>
      );
    });

    seguridadItems = proyecto.seguridad_items.map((seg) => {
      const iconUrl = seg.hasOwnProperty('icono') ? `${BACKEND_BASE_URL}${seg.icono.url}` : '';
      const iconAlt = seg.hasOwnProperty('alternativeText') ? seg.icono.alternativeText : '';

      return (
        <TextInfo key={seg.titulo_seguridad} iconUrl={iconUrl} iconAlt={iconAlt}>{seg.titulo_seguridad}</TextInfo>
      );
    });
  }

  return (
    <div className="place-info-list">
      <div className="place-info-list-item">
        <span className="item-place-title">EQUIPAMIENTO</span>
        <div className="item-place-list">
          {equipamientoItems}
        </div>
      </div>
      <div className="place-info-list-item">
        <span className="item-place-title">SERVICIOS</span>
        <TextInfo className="text">{proyecto ? proyecto.servicios : ''}</TextInfo>

        <span className="item-place-title mt-3">SEGURIDAD</span>
        <div className="item-place-list">
          {seguridadItems}
        </div>
      </div>
    </div>
  );
};

export default CardInfoPlace;
