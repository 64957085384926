/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import logoColors from '../../../../static/images/logo.png'
import { Button } from '../../../ui/index';
import CardDetalle from '../CardDetalle';
import ModalSolicitud from '../ayuda/ModalSolicitud';
import ModalAyuda from '../ayuda/ModalAyuda';
import ModalAvalExtra from '../ayuda/ModalAvalExtra';
import ModalPep from '../ayuda/ModalPep';
import ModalCotizacionSpinner from '../ayuda/ModalCotizacionSpinner';
import './styles/main.scss';
import FormPersona from './FormPersona';
import {
  setTitular,
  selectSendCotizacionStatus,
  selectLastCotizacionId,
  getDropdownData,
} from '../../../../redux/preevaluacionSlice';
import {
  selectApellidoMaterno,
  selectEmail,
  selectLevelClienteId,
  selectNombre,
  selectNumeroDocumento,
  selectStrapiId,
  selectTelefono,
  selectTipoDocumento,
  selectTipoPersona,
  levelModificarNatural,
  selectTipoPersonaId,
  selectGeneroId,
  selectPaisId,
  selectProfesionId,
  selectEstadoCivilId,
  setUserPreEvaluacion
} from '../../../../redux/userSlice';
import {
  BREAKPOINT_MOBILE,
  FLUJO_ENTRADA_AUTOFILL,
  PREEVALUACION_MAX_WIDTH,
  TIPO_DOCUMENTO
} from '../../../../config/constant';
import useResize from '../../../../hooks/useResize';
// import useCurrentTipologiaCheapestDepto from '../../../../hooks/useCurrentTipologiaCheapestDepto';
import {
  getEvaluationUrl,
  scrollToTop,
} from '../../../../utils';
import { Link } from 'react-router-dom';
import useHideCliengoButtons from '../../../../hooks/useHideCliengoButtons';

// Información mostrada en los modales de ayuda
const AVAL_INFO = {
  title: 'Sobre aval',
  header: '¿Qué es el aval?',
  body: 'El aval es la figura que respalda al titular en caso de ausencia o incumplimiento.',
};

const COMPLEMENTO_INFO = {
  title: 'Sobre complemento',
  header: '¿Qué es el complemento?',
  body: 'El complemento es la figura que apoyará con su renta al titular para calificar en evaluación, debe vivir en el departamento.',
};

const TITULAR_INFO = {
  title: 'Sobre titular',
  header: '¿Qué es el titular?',
  body: 'El titular es la figura principal del contrato y a nombre de quien quedará el arriendo del departamento, estacionamiento o bodega.',
};

const PEP_INFO = {
  title: 'Sobre PEP',
  header: '¿Qué es persona PEP?',
  body: `Se considera Personas Expuestas Políticamente (PEP) a “los chilenos o extranjeros que desempeñan o hayan desempeñado funciones públicas destacadas en un país, hasta a lo menos un año de finalizado el ejercicio de las mismas”. Se incluyen en esta categoría a jefes de Estado o de un Gobierno, políticos de alta jerarquía, funcionarios gubernamentales, judiciales o militares de alta jerarquía, altos ejecutivos de empresas estatales, así como sus cónyuges, sus parientes hasta el segundo grado de consanguinidad, y las personas naturales con las que hayan celebrado un pacto de actuación conjunta, mediante el cual tengan poder de voto suficiente para influir en sociedades constituidas en Chile.
  Así, en nuestro país, a lo menos deberán estar calificadas como PEP las siguientes personas, sin que este enunciado sea taxativo: <br/><br/>
  <ul>
    <li>Presidente de la República.</li>
      <li>Senadores, diputados y alcaldes.</li>
      <li>Ministros de la Corte Suprema y Cortes de Apelaciones.</li>
      <li>Ministros de Estado, subsecretarios, intendentes, gobernadores, secretarios regionales ministeriales, embajadores, jefes superiores de Servicio, tanto centralizados como descentralizados, y el directivo superior inmediato que deba subrogar a cada uno de ellos.</li>
      <li>Comandantes en Jefe de las Fuerzas Armadas, director General de Carabineros, director General de Investigaciones, y el oficial superior inmediato que deba subrogar a cada uno de ellos.</li>
      <li>Fiscal Nacional del Ministerio Público y fiscales regionales.</li>
      <li>Contralor General de la República.</li>
      <li>Consejeros del Banco Central de Chile.</li>
      <li>Consejeros del Consejo de Defensa del Estado.</li>
      <li>Ministros del Tribunal Constitucional.</li>
      <li>Ministros del Tribunal de la Libre Competencia.</li>
      <li>Integrantes titulares y suplentes del Tribunal de Contratación Pública.</li>
      <li>Consejeros del Consejo de Alta Dirección Pública.</li>
      <li>Directores y ejecutivos principales de empresas públicas, según lo definido por la Ley Nº 18.045.</li>
      <li>Directores de sociedades anónimas nombrados por el Estado o sus organismos.</li>
      <li>Miembros de las directivas de los partidos políticos.</li>
    </ul>`
};

const dummyData = FLUJO_ENTRADA_AUTOFILL ? {
  nombre: `Titular Test`,
  apellidoPaterno: `ApellidoTitular`,
  tipoDocumento: TIPO_DOCUMENTO[0], // changed to RUT per LEV-10
  numeroDocumento: '36467521',
  email: `testemail@gmail.com`,
  telefono: '111111111',
  rentaMensual: '111111111',
} : null;


const PreEvaluacion = ({ proyecto, reserva, resumenCotizacion, testInitialData = dummyData, isCotizacion = false }) => {

  const dispatch = useDispatch();
  const [rizeWidth] = useResize();

  // Estado para mostrar/ocultar modales
  const [ayudaSolicitud, setAyudaSolicitud] = useState(false);
  const [ayudaAval, setAyudaAval] = useState(false);
  const [ayudaComplemento, setAyudaComplemento] = useState(false);
  const [ayudaTitular, setAyudaTitular] = useState(false);
  const [ayudaPep, setAyudaPep] = useState(false);
  const [modalAvalExtra, setModalAvalExtra] = useState(false);
  const [personaPep, setPersonaPep] = useState(false);



  // Estado para mostrar/ocultar modal de spinner de cotización
  const sendCotizacionStatus = useSelector(selectSendCotizacionStatus);
  const modalCotizacionSpinner = sendCotizacionStatus === 'loading';

  const levelClienteId = useSelector(selectLevelClienteId);
  const lastCotizacionId = useSelector(selectLastCotizacionId);

  // Si la cotización fue exitosa, redirigir a página externa de evaluación
  if (sendCotizacionStatus === 'succeeded') {
    // window.location.href = getEvaluationUrl(levelClienteId, lastCotizacionId);
  }

  // Departamento más barato del proyecto/tipología que se está viendo en /nuestros-edificios (i.e. "place")
  // const cheapestDepto = useCurrentTipologiaCheapestDepto(proyecto);

  const helpSolicitud = () => {
    setAyudaSolicitud(!ayudaSolicitud);
  }

  const closeModelPep = () => {
    setPersonaPep(false)
  }

  const helpAval = () => {
    setAyudaAval(!ayudaAval);
  }

  const helpComplemento = () => {
    setAyudaComplemento(!ayudaComplemento);
  }

  const helpTitular = () => {
    setAyudaTitular(!ayudaTitular);
  }

  const helpPep = () => {
    setAyudaPep(!ayudaPep);
  }

  const handleModalAval = () => {
    setModalAvalExtra(!modalAvalExtra);
  }

  // Ocultar botones de Cliengo en mobile
  useHideCliengoButtons();

  useEffect(() => {
    dispatch(getDropdownData())
    scrollToTop();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const titularProcessData = (personaData) => {
    const newTitular = { ...personaData, apellidoMaterno, userId };
    // Actualizar datos de titular
    dispatch(setUserPreEvaluacion(newTitular));

    dispatch(levelModificarNatural({ personaData }));
  }

  const [evalucacionLine, setEvaluacionLinea] = useState(null);

  const handleEvaluation = (isPep) => {
    dispatch(levelModificarNatural({ clientepep: isPep }));
    // Empezar flow de reserva
    if (isPep) {
      setPersonaPep(true)
    } else {
      setEvaluacionLinea(getEvaluationUrl(levelClienteId, lastCotizacionId))
      //window.location.href = getEvaluationUrl(levelClienteId, lastCotizacionId);
    }
  }

  const userId = useSelector(selectStrapiId);

  // Seleccionar datos del usuario loggeado para pasárselos al form de titular y rellenar datos
  //const nombre = useSelector(selectNombre);
  //const apellidoPaterno = useSelector(selectApellidoPaterno);
  const apellidoMaterno = useSelector(selectApellidoMaterno);
  const nombre = useSelector(selectNombre).split(" ")[0];
  const apellidoPaterno = useSelector(selectNombre).split(" ")[1];
  const tipoDocumento = useSelector(selectTipoDocumento);
  const numeroDocumento = useSelector(selectNumeroDocumento);
  const email = useSelector(selectEmail);
  const tipoPersona = useSelector(selectTipoPersona);
  const telefono = useSelector(selectTelefono);
  const tipoPersonaId = useSelector(selectTipoPersonaId);
  const generoId = useSelector(selectGeneroId);
  const paisId = useSelector(selectPaisId);
  const profesionId = useSelector(selectProfesionId);
  const estadoCivilId = useSelector(selectEstadoCivilId);

  

  const titularInitialData = {
    nombre,
    apellidoPaterno,
    apellidoMaterno,
    tipoDocumento,
    numeroDocumento,
    email,
    tipoPersona:tipoPersonaId,
    telefono,
    generoId,
    paisId,
    profesionId,
    estadoCivilId
  };

  const rowStyle = rizeWidth <= BREAKPOINT_MOBILE ? { paddingLeft: '0', paddingRight: '0' } : {};
  const mainContainerPreStyle = rizeWidth <= BREAKPOINT_MOBILE ? { borderRadius: '0' } : {};

  // En desk, el height tiene que coincidir con el height de _main-container-pre. Por qué no lo cambio directamente
  // en main.scss en vez de sobreescribirlo acá? Porque cambiar CSS global tiende a romper cosas
  // en maneras inesperadas, y es mucho más seguro sobreescribirlo acá y listo.
  const itemFormStyle = { height: `${rizeWidth <= BREAKPOINT_MOBILE ? '68vh' : '80vh'}` };

  const stepFormStyle = { height: '100%' };


  return (
    <>
      <div className="_preContainer info-adicional">
        <div className="_headerPre">
          <div className="container" style={{ maxWidth: PREEVALUACION_MAX_WIDTH ? PREEVALUACION_MAX_WIDTH : '' }}>
            <div className="row" style={rowStyle}>
              <div className="_header-container">
                <div className="_logo">
                  <Link to="/"><img className={`_logo-white`} src={logoColors} alt="Logo Level" /></Link>
                </div>
                <div className="_option-pre">
                  <a href="#">Guardar y salir</a>
                  <Button onClick={helpSolicitud} color="btn-blue-dark" size="extra-small" endIcon="icon-question">AYUDA</Button>
                </div>
              </div>

              <div className="_main-container-pre" style={mainContainerPreStyle}>
                {
                  rizeWidth > BREAKPOINT_MOBILE &&

                  <div className="_item-fijo">
                    <div className="_fijo-titular">
                      <span className="_circle"></span>
                      {`¡Hola, ${nombre}!`}
                    </div>
                    <p className="_info">Esta información es necesaria para iniciar el proceso de arriendo online.</p>

                    <Button color="btn-blue-dark" size="extra-small" className="btn flex justify-center mb-3" fullWidth={true} endIcon="icon-question">¿Cómo enviar una correcta evaluación?</Button>

                    <CardDetalle proyecto={proyecto} detalle={resumenCotizacion} isCotizacion={isCotizacion} />
                  </div>
                }
                {
                  rizeWidth <= BREAKPOINT_MOBILE &&

                  <div className="progressbar-container">
                    <h1>INGRESO DE DATOS</h1>
                    <p>Esta información es necesaria para iniciar el proceso de arriendo online.</p>
                  </div>
                }
                <div className="_item-form" style={itemFormStyle}>
                  <div className="_step-form active" style={stepFormStyle}>
                    <FormPersona
                     isCotizacion={isCotizacion}
                      proyecto={proyecto}
                      title="Pre-evaluación"
                      show={true}
                      checked={false}
                      processData={titularProcessData}
                      initialData={testInitialData ? testInitialData : titularInitialData}
                      showModalAval={() => setAyudaAval(true)}
                      showModalComplemento={() => setAyudaComplemento(true)}
                      showModalTitular={() => setAyudaTitular(true)}
                      showModalPep={() => setAyudaPep(true)}
                      showModalAvalExtra={() => setModalAvalExtra(true)}
                      handleEvaluation={(isPep) => handleEvaluation(isPep)}
                      evalucacionLine = {evalucacionLine}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {personaPep && <ModalPep close={closeModelPep} />}
      {ayudaSolicitud && <ModalSolicitud close={helpSolicitud} />}
      {ayudaAval && <ModalAyuda title={AVAL_INFO.title} header={AVAL_INFO.header} body={AVAL_INFO.body} close={helpAval} />}
      {ayudaComplemento && <ModalAyuda title={COMPLEMENTO_INFO.title} header={COMPLEMENTO_INFO.header} body={COMPLEMENTO_INFO.body} close={helpComplemento} />}
      {ayudaTitular && <ModalAyuda title={TITULAR_INFO.title} header={TITULAR_INFO.header} body={TITULAR_INFO.body} close={helpTitular} />}
      {ayudaPep && <ModalAyuda title={PEP_INFO.title} header={PEP_INFO.header} body={PEP_INFO.body} close={helpPep} theClass="ayuda-pep" />}
      {modalAvalExtra && <ModalAvalExtra nextPage={handleModalAval} close={handleModalAval} />}
      {modalCotizacionSpinner && <ModalCotizacionSpinner />}
    </>
  );
};

export default PreEvaluacion;
