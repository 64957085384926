/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import './styles/ModalVisitaMobile.scss'
import { TextInfo, Icon, Button, Checkbox, TextArea, Input } from '../ui/index';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


const ModalVisitaMobile = ({ show, close }) => {
  const [startDate, setStartDate] = useState(new Date());

  return (
    <div className={`modal-search-mobile ${show}`}>
      <div className="header--search">
        <div className="header-step show" onClick={close}>
          <TextInfo iconName="icon-close" color="white" textWeight="bold">Solicitar una visita</TextInfo>
        </div>
      </div>
      <div className="search-body searchArrendaronline">
        <div className="body-step show">
          <h4 className="card-title">¿Qué quieres visitar?</h4>
          <div className="box-cards">
            <div className="box-card">
              <TextInfo className="title-card" textWeight="bold" color="black">Selecciona una ubicación</TextInfo>
              <Checkbox checked={true} position="right">
                <TextInfo color="black"><Icon name="icon-location-pin" color="#848390" border="round" size="12px" />San Diego, 895</TextInfo>
              </Checkbox>
              <Checkbox position="right">
                <TextInfo color="black"><Icon name="icon-location-pin" color="#848390" border="round" size="12px" /> Arturo Prat, 1237</TextInfo>
              </Checkbox>
              <Checkbox position="right" checked="checked">
                <TextInfo color="black"><Icon name="icon-location-pin" color="#848390" border="round" size="12px" /> Lira, 254</TextInfo>
              </Checkbox>
              <Checkbox marginZero={true} position="right">
                <TextInfo color="black"><Icon name="icon-location-pin" color="#848390" border="round" size="12px" /> Ignacio de Loyola, 952</TextInfo>
              </Checkbox>
            </div>
            <div className="box-card">
              <TextInfo className="title-card" textWeight="bold" color="black">Selecciona una fecha deseada</TextInfo>
              <DatePicker
                dateFormat="dd.MM.yyyy"
                selected={startDate}
                onChange={date => setStartDate(date)}
                inline
              />
            </div>
            <div className="box-card">
              <TextInfo className="title-card" textWeight="bold" color="black">Horario deseado</TextInfo>
              <div className="_box-schedule">
                <span className="_hour">09:00</span>
                <span className="_hour">10:00</span>
                <span className="_hour">11:00</span>
                <span className="_hour">12:00</span>
                <span className="_hour active">13:00</span>
                <span className="_hour">14:00</span>
                <span className="_hour">15:00</span>
                <span className="_hour">16:00</span>
                <span className="_hour">17:00</span>
                <span className="_hour">18:00</span>
                <span className="_hour">19:00</span>
                <span className="_hour">20:00</span>
              </div>
            </div>
            <div className="box-card">
              <TextInfo className="title-card" textWeight="bold" color="black">Información de contacto</TextInfo>
              <Input type="text" classes="small mb-2" fullWidth={true} placeholder="Ingresa tu teléfono" />
              <TextArea className="mb-1" rows="3" placeholder="¿Quieres dejar un mensaje?" />
              <Checkbox classes="mt-2" checked={true}>
                <TextInfo color="black">Entiendo que Level deberá confirmar disponibilidad de fecha y horario para mi visita. <a className="link-default">Términos y condiciones.</a></TextInfo>
              </Checkbox>
            </div>
          </div>
        </div>
      </div>
      <div className="search-footer">
        <div className="footer-step show">
          <Button color='btn-orange' fullWidth={true} size="extra-small">Solicitar visita</Button>
        </div>
      </div>
    </div>
  );
};

export default ModalVisitaMobile;
