import React from 'react';
import './Dropdown.scss';

const Dropdown = (props) => {
  const { 
    title = '',
    children,
    ...other
  } = props;

  const tabindex = (Math.floor(Math.random() * (20 - 0)) + 0);

  return(
    <div className="dropdown" tabIndex={tabindex+1} {...other}>
      <div className="dropdown-header">{title}</div>
      <div className="dropdown-body">
        {children}
      </div>
    </div>
  );
}

export default Dropdown;
