/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Modal } from '../../../ui/index';
import Spinner from '../../../Spinner';
import spinnerStyle from './styles/ModalCotizacionSpinner.module.scss';


const MSG = 'Procesando cotización, espera por favor...';

const ModalCotizacionSpinner = ({ close }) => {

  return (
    <Modal show={true} close={close}>
      <div className="modal-content help md fade" style={{ padding: '50px' }}>
        <h2>{MSG}</h2>
        <Spinner enabled spinnerStyle={spinnerStyle} />
      </div>
    </Modal>
  );
};

export default ModalCotizacionSpinner;
