import React from 'react';
import PropTypes from 'prop-types';
import './Button.scss';
import Icon from '../Icon/index';


const Button = props => {

  const { 
    className = 'btn', 
    size = '', 
    color = '', 
    disabled = false,
    endIcon = '', 
    startIcon = '', 
    type = 'button', 
    fullWidth = false,
    children ,
    iconSize = '',
    alingIcon = '',
    iconColor = '',
    ...other
  } = props;

  const startIconPro = startIcon && (
    <Icon name={startIcon} alingIcon={alingIcon} size={iconSize} />
  );
  const endIconPro = endIcon && (
    <Icon name={endIcon}  alingIcon={alingIcon} size={iconSize} />
  );

  const width = fullWidth ? 'full-width' : '';

  return (
    <button 
      type={type}
      className={`${className} ${color} ${iconColor} ${size} ${width}`} 
      disabled={disabled} {...other}
    >
      {startIconPro}
      {children}
      {endIconPro}
    </button>
  );
}

Button.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'extra-small', 'small-icon']),
  type: PropTypes.string,
  children: PropTypes.node,
};

export default Button;
