import React from 'react';
import CardAlerta from '../containers/CardAlerta';


const CotizacionesAlerta = ({
  cardStyle,
  icon = 'icon-alarm',
  text = 'Recuerda: tus cotizaciones se mantienen <strong>vigentes durante 5 días.</strong>',
  centerText = false,
  status
}) => {

  const textInfoStyle = centerText ? {
    marginLeft: 'auto',
    marginRight: 'auto',
  } : {};

  const bgColor = status ? 'white' : "#FBE8E8"
  return (
    <CardAlerta
      cardStyle={{
        backgroundColor: bgColor,
        padding: '10px',
        ...cardStyle,
      }}
      textColor="red"
      icon={icon}
      iconColor="#E82B00"
      text={status ? '' : text}
      textInfoStyle={textInfoStyle}
    />
  );
}

export default CotizacionesAlerta;
