import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectProyectosFuturos } from '../../redux/proyectosSlice';
import Carousel from 'react-elastic-carousel'
import './styles/ProximosEdificiosMobile.scss'
import { TextInfo } from '../ui/index';
import {
  getDormitoriosAndBanosString,
  getPlaceUrl,
  getPortadaData
} from '../../utils';
import { useHistory } from 'react-router-dom';


const ProximosEdificiosMobile = () => {

  const history = useHistory();
  const proyectosFuturos = useSelector(selectProyectosFuturos);
  const [currentImage, setCurrentImage] = useState(0);    

  const sliderItems = proyectosFuturos.map((proy, index) => {
    const portadaData = getPortadaData(proy);

    // Recorrer tipologías del proyecto para encontrar número mínimo y máximo de dormitorios
    let dormitorios = [];

    proy.tipologias.forEach((tipo) => {
    dormitorios.push(tipo.dormitorios);
    });

    const bothStrs = getDormitoriosAndBanosString(proy, false);
    const dormitoriosStr = bothStrs.dormitoriosStr;

    const classNameStr = index === 0 ? 'item-slider placeNext active' : 'item-slider placeNext';

    // FIXME: Fecha de inauguración sigue hardcodeada
    return (
      <div onClick={() => history.push(getPlaceUrl(proy))} key={proy._id} data-position={index} className={classNameStr}>
        <div className="header-title">
          <span>INAGURACIÓN 2021</span>
        </div>
        <div className="image small" style={{ backgroundImage: `url(${portadaData.url})` }}>

        </div>
        <div className="body">
          <span className="title">{proy.proyecto_nombre}</span>
          <div className="footer">
            <TextInfo iconColor="#FF6D00" iconName="icon-location-pin" color="white">{proy.direccion}</TextInfo>
            <TextInfo iconColor="#FFFFFF" iconName="icon-bed" color="white">{dormitoriosStr}</TextInfo>
          </div>
        </div>
      </div>
      );
  });

  const removeActive = () => {
    const itemsSlider = document.querySelector('.slider-places-mobile-items.prox .item-slider.active');
    if (itemsSlider) {
      itemsSlider.removeClass('active');
    }
  }

  return (
    <div className="proximos-edificios-mobile">

      <h2 className="titleMobile white mb-4 mt-2">Próximos Edificios</h2>

      <Carousel
          itemsToScroll={1}
          itemsToShow={1}
          enableAutoPlay={false}
          showArrows={false}
          autoPlaySpeed={5000}
          initialFirstItem={0}

          onNextStart={(currentItem, nextItem) => {
              if (currentImage < proyectosFuturos.length - 1) {
                  const { index } = currentItem;
                  setCurrentImage(currentImage + 1);
                  const currentThumb = document.querySelector('.slider-places-mobile-items.prox .item-slider[data-position="' + (index + 1) + '"]');
                  removeActive();
                  currentThumb.addClass('active');
              }
          }}
          onPrevStart={(currentItem, nextItem) => {
              if (currentImage > 1) {
                  const { index } = currentItem;
                  setCurrentImage(currentImage - 1);
                  const currentThumb = document.querySelector('.slider-places-mobile-items.prox .item-slider[data-position="' + (index - 1) + '"]');
                  removeActive();
                  currentThumb.addClass('active');
              }
          }}

          className="slider-places-mobile-items prox small paginate-white"
        >
          {sliderItems}
      </Carousel>
    </div>
  );
}

export default ProximosEdificiosMobile;
