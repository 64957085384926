import React, { useState } from 'react';
import Carousel from 'react-elastic-carousel';
import LazyLoad from 'react-lazyload';
import {
  HOME_MOBILE_IMAGE_HEIGHT,
  HOME_MOBILE_IMAGE_WIDTH
} from '../../config/constant';
import useHomeData from '../../hooks/useHomeData';
import './styles/SliderMobile.scss';


const ADDITIONAL_INFO = false;

const handleClickLink = (link) => {
  if(link!=""){
    window.location.href = link
  }
}

const SliderMobile = ({ home = true, imgArray, rawArray }) => {

  const homeData = useHomeData();
  const totalSlider = home ? 3 : imgArray ? imgArray.length : rawArray.length;
  const [currentImage, setCurrentImage] = useState(home || rawArray ? 1 : 2);

  const buildSlidesFromImgArray = (imgArray) => {
    const slides = [];

    for (let i = 0; i < imgArray.length; i++) {
      const currImg = imgArray[i];
      const isActive = currentImage === i + 1;

      slides.push(
        <div key={i} data-position={`${i}`}  className={`item-slider ${isActive ? 'active' : ''}`} >
          <LazyLoad>
            <img src={currImg.src} alt={currImg.alt} style={{ height: '100%' }} />
          </LazyLoad>
        </div>
      );
    }

    return slides;
  }

  const slides = home ?
    homeData.bannersMobile.map((bannerMobile, index) => {
      return (
        <div data-position={`${index}`} className="item-slider active" onClick={()=>handleClickLink(bannerMobile.alternativeText)} key={bannerMobile.id}>
          <LazyLoad>
            <picture>
              <img
                src={bannerMobile.url}
                alt={bannerMobile.alt}
                style={{
                  width: HOME_MOBILE_IMAGE_WIDTH,
                  height: HOME_MOBILE_IMAGE_HEIGHT,
                  objectFit: 'cover',
                }}
              />
            </picture>
          </LazyLoad>
          {
            ADDITIONAL_INFO &&

            <>
              <span className="alert-header">Paga la garantía y comisión en 3 cuotas</span>
              <div className="info-footer">
                <p><strong>¡Arrienda en Lira 320!</strong> Encuentra arriendos desde $265.000 y paga la garantía y comisión en 3 cuotas  </p>
              </div>
            </>
          }
        </div>
      );
    }) : imgArray ? buildSlidesFromImgArray(imgArray) : rawArray;

  const removeActive = () => {
    const itemsSlider = document.querySelector('.slider-mobile-items .item-slider.active');
    if (itemsSlider) {
      itemsSlider.removeClass('active');
    }
  }

  const bgColor = home ? { backgroundColor: '' } : { backgroundColor: 'transparent', paddingBottom: '0' };

  return (
    <div className="slider-header-mobile" style={bgColor}>
      <Carousel
        itemsToScroll={1}
        itemsToShow={1}
        enableAutoPlay={false}
        showArrows={false}
        autoPlaySpeed={5000}
        initialFirstItem={home || rawArray ? 0 : 1}
        onNextStart={(currentItem, nextItem) => {
          if (currentImage < totalSlider) {
            const { index } = currentItem;
            setCurrentImage(currentImage + 1);
            const currentThumb = document.querySelector('.slider-mobile-items .item-slider[data-position="' + (index + 1) + '"]');
            removeActive();
            currentThumb.addClass('active');
          }
        }}
        onPrevStart={(currentItem, nextItem) => {
          if (currentImage > 1) {
            const { index } = currentItem;
            setCurrentImage(currentImage - 1);
            const currentThumb = document.querySelector('.slider-mobile-items .item-slider[data-position="' + (index - 1) + '"]');
            removeActive();
            currentThumb.addClass('active');
          }
        }}
        className="slider-mobile-items"
      >
        {slides}
      </Carousel>
    </div>
  );
}

export default SliderMobile;
