/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Icon,
  Button,
  Input,
  TextInfo,
  Modal,
  Checkbox,
  Radiobox,
  ButtonDropdown,
  Dropdown
} from './ui/index';
import { levelCrearNatural } from '../redux/userSlice';
import {
  isValidEmail,
  isValidDocumento,
  isValidPassword,
  isValidNombre,
  isValidApellido,
  isValidTelefono,
  getInvalidDocumentString,
  getInvalidNombreString,
  getInvalidApellidoString,
  getInvalidEmailString,
  getInvalidPasswordString,
  getInvalidTelefonoString,
  getEmailPlaceholder,
  getNombrePlaceHolder,
  getApellidoPaternoPlaceholder,
  getApellidoMaternoPlaceholder,
  getDocumentoPlaceholder,
  getTelefonoPlaceholder,
  getRUTPlaceholder
} from '../validation';
import {
  ACEPTAR_TERMINOS_ERROR,
  BREAKPOINT_MOBILE,
  TIPO_PERSONA
} from '../config/constant';
import { toast } from 'react-toastify';
import './styles/ModalRegister.scss';
import useResize from '../hooks/useResize';
import { Link } from 'react-router-dom';
import {
  dottifyDocumento,
  documentTypeStrToId,
  quitarFormatoRut,
} from '../utils';
import DropdownTipoDocumento from './DropdownTipoDocumento';
import useHideCliengoButtons from '../hooks/useHideCliengoButtons';


//const TIPO_PERSONA_DEFAULT = TIPO_PERSONA[0];


const ModalRegister = ({ show, close, openLogin }) => {

  const dispatch = useDispatch();
  const [rizeWidth, rizeHeight] = useResize();
  const [nombre, setNombre] = useState('');
  const [apellidoPaterno, setApellidoPaterno] = useState('');
  const [apellidoMaterno, setApellidoMaterno] = useState('');
  const tipoDocumento = 'RUT';
  const [numeroDocumento, setNumeroDocumento] = useState('');
  const [telefono, setTelefono] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [terminosChecked, setTerminosChecked] = useState(false);
  //const [tipoPersona, setTipoPersona] = useState(TIPO_PERSONA_DEFAULT);
  //const tipoPersona = TIPO_PERSONA_DEFAULT;

  // Ocultar botones de Cliengo en mobile
  useHideCliengoButtons(show);

  const onRegistrarseClick = () => {
    if (!terminosChecked) {
      toast.error(ACEPTAR_TERMINOS_ERROR);
      return;
    }

    if (!isValidNombre(nombre)) {
      toast.error(getInvalidNombreString());
      return;
    }

    if (!isValidApellido(apellidoPaterno)) {
      toast.error(getInvalidApellidoString());
      return;
    }

    if (!isValidApellido(apellidoMaterno)) {
      toast.error(getInvalidApellidoString());
      return;
    }

    if (!isValidDocumento(tipoDocumento, numeroDocumento.trim())) {
      toast.error(getInvalidDocumentString(tipoDocumento, numeroDocumento));
      return;
    }

    if (!isValidEmail(email)) {
      toast.error(getInvalidEmailString());
      return;
    }

    if (!isValidPassword(password)) {
      toast.error(getInvalidPasswordString());
      return;
    }

    if (!isValidTelefono(telefono)) {
      toast.error(getInvalidTelefonoString());
      return;
    }

    /*
    if (!isValidPersona(tipoPersona)) {
      toast.error(`Tipo de persona inválido!`);
      return;
    }
    */

    const levelUser = {
      tipoDocumentoId: documentTypeStrToId(tipoDocumento),
      numeroDocumento: quitarFormatoRut(numeroDocumento),
      apellidoPaterno,
      apellidoMaterno,
      nombre,
      telefono,
      email,
      password,
    };

    // Crear usuario en Level
    dispatch(levelCrearNatural(levelUser));

    // Ocultar modal sin importar resultado
    close();
  }

  const onNombreChange = (event) => {
    setNombre(event.target.value);
  }

  const onApellidoPaternoChange = (event) => {
    setApellidoPaterno(event.target.value);
  }

  const onApellidoMaternoChange = (event) => {
    setApellidoMaterno(event.target.value);
  }

  const onNumeroDocumentoChange = (event) => {
    const newValue = event.target.value;
    const dottified = dottifyDocumento(newValue, tipoDocumento);
    setNumeroDocumento(dottified);
  }

  const onNumeroDocumentoBlur = () => {
    if (!isValidDocumento(tipoDocumento, numeroDocumento)) {
      toast.error(getInvalidDocumentString(tipoDocumento, numeroDocumento));
      return;
    }
  }

  const onTelefonoChange = (event) => {
    setTelefono(event.target.value);
  }

  const onEmailChange = (event) => {
    setEmail(event.target.value);
  }

  const onPasswordChange = (event) => {
    setPassword(event.target.value);
  }

  const onTerminosChange = (event) => {
    setTerminosChecked(event.target.checked);
  }

  const onTipoPersonaChange = (event) => {
    /*
    // Ocultar dropdown
    const dropdownPersona = document.getElementById('modal-register-dropdown-persona');
    dropdownPersona.click();

    // Actualizar tipo persona
    setTipoPersona(event.target.value);
    */
  }

  const onIniciarSesion = () => {
    // Abrir modal de login y cerrar modal de registro
    openLogin();
    close();
  }

  const headerStr = 'Crea una cuenta';

  //const tipoPersonaStr = tipoPersona !== '' ? `Tipo de persona: ${tipoPersona}` : 'Tipo de persona';
  const tipoPersonaStr = 'Tipo de persona';

  const tipoPersonaRadioboxes = TIPO_PERSONA.map((currTipo) => {
    return (
      <Radiobox key={currTipo} name="tipo-persona" value={currTipo}>
        <TextInfo color="black">{currTipo}</TextInfo>
      </Radiobox>
    );
  });

  let top = '';
  let maxHeight = '';
  let overflow = '';

  if (rizeWidth < BREAKPOINT_MOBILE) {
    top = '150px';
  }

  // Ajustar alto para pantallas tipo notebook
  if (rizeHeight <= 768 && rizeWidth > BREAKPOINT_MOBILE) {
    maxHeight = '760px';
    overflow = 'auto';
  }

  const loginParagraphStyle = { marginBottom: '0' };
  const separadorFormStyle = { marginTop: '10px' };
  const bodyModalStyle = rizeWidth <= BREAKPOINT_MOBILE ? { marginBottom: '70px' } : {};


  return (
    <>
      <Modal show={show} bgClose={false} className="modalRegisterHome">
        <div className="modal-content modal-login fade" style={{ top, maxHeight, overflow }}>
          <div className="header-modal">
            <Icon onClick={close} name="icon-close" size="14" />
          </div>
          <div className="body-modal" style={bodyModalStyle}>
            <div className="form-content">
              <h3>{headerStr}</h3>
              <p className="loginParagraph" style={loginParagraphStyle}>¿Ya tienes una cuenta? <a onClick={onIniciarSesion} className="link">Iniciar sesión</a></p>
              <br />
              <div className="separador-form" style={separadorFormStyle} />
              <div className="formLogin">
                <form>
                  <Input
                    type="text"
                    name="nombre"
                    typeStyle="filtro-white"
                    fullWidth={true}
                    placeholder={getNombrePlaceHolder()}
                    value={nombre}
                    onChange={onNombreChange}
                    formGroupStyle={{ margin: "0" }}
                  />
                  <Input
                    type="text"
                    name="apellido-paterno"
                    typeStyle="filtro-white"
                    fullWidth={true}
                    placeholder={getApellidoPaternoPlaceholder()}
                    value={apellidoPaterno}
                    onChange={onApellidoPaternoChange}
                    formGroupStyle={{ margin: "0" }}
                  />
                  <Input
                    type="text"
                    name="apellido-materno"
                    typeStyle="filtro-white"
                    fullWidth={true}
                    placeholder={getApellidoMaternoPlaceholder()}
                    value={apellidoMaterno}
                    onChange={onApellidoMaternoChange}
                    formGroupStyle={{ margin: "0" }}
                  />

                  {/*<DropdownTipoDocumento
                    tipoDocumento={tipoDocumento}
                    onTipoDocumentoChange={onTipoDocumentoChange}
                  />*/}

                  <Input type="text" name="numero-documento" typeStyle="filtro-white" fullWidth={true}
                    placeholder={getDocumentoPlaceholder()} value={numeroDocumento}
                    onChange={onNumeroDocumentoChange} onBlur={onNumeroDocumentoBlur}
                    formGroupStyle={{ margin: "0" }}
                    onPaste={(e) => e.preventDefault()}
                    maxLength="12"
                  />
                  <Input type="email" name="email" typeStyle="filtro-white" fullWidth={true}
                    placeholder={getEmailPlaceholder()} value={email} onChange={onEmailChange}
                    formGroupStyle={{ margin: "0" }}
                  />
                  <Input type="password" name="password" typeStyle="filtro-white" fullWidth={true}
                    placeholder="Contraseña" value={password} onChange={onPasswordChange}
                    formGroupStyle={{ margin: "0" }}
                  />
                  <Input type="text" name="telefono" typeStyle="filtro-white" fullWidth={true} placeholder={getTelefonoPlaceholder()}
                    value={telefono} onChange={onTelefonoChange}
                    formGroupStyle={{ margin: "0" }}
                  />
                  {
                    false &&

                    <div className="formGroup">
                      <div className="overlayDropdown"></div>
                      <ButtonDropdown id="modal-register-dropdown-persona" color="btn-white-gray" size="large" fullWidth={true} placeholder={tipoPersonaStr}>
                        <Dropdown title="Selecciona un tipo de persona" style={{ top: "-240px" }}>
                          <div onChange={onTipoPersonaChange}>
                            {tipoPersonaRadioboxes}
                          </div>
                        </Dropdown>
                      </ButtonDropdown>
                    </div>
                  }
                </form>
              </div>
              <div style={{ marginTop: '20px' }}>
                <TextInfo className="footer-login" size="12">¿No posees RUT? <Link className="link" to="/contacto">Contáctanos</Link>.</TextInfo>
              </div>
              <div style={{ marginTop: '20px' }}>
                <Checkbox checked={terminosChecked} onChange={onTerminosChange} position="left" textAlign="left">
                  <TextInfo className="footer-login" size="12">Acepto los <Link className="link" to="/terminos-y-politica">términos y la política de privacidad</Link></TextInfo>
                </Checkbox>
              </div>
              <Button style={{ margin: '0 0 30px 0' }} className="btn btn-submit" color='btn-orange' size="small" onClick={onRegistrarseClick}>REGISTRARSE</Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalRegister;
