import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { selectGetMesesArrayData } from '../redux/preevaluacionSlice';
import {
  setGetMesesArrayErrorCount,
  setGetMesesArraySuccessCount
} from '../redux/preevaluacionSlice';


// Hook para mostrar mensaje de éxito/error del thunk getMesesArray. Devuelve data relacionada
// al thunk.
const useGetMesesArrayMessage = () => {

  const dispatch = useDispatch();
  const getMesesArrayData = useSelector(selectGetMesesArrayData);

  useEffect(() => {
    switch (getMesesArrayData.status) {
      case 'succeeded':
        if (getMesesArrayData.successCount > 0) {
          // No mostramos nada si el thunk fue exitoso, solo reseteamos el counter
          //toast.success(getMesesArrayData.successMessage);
          dispatch(setGetMesesArraySuccessCount(0));
        }
        break;

      case 'failed':
        if (getMesesArrayData.errorCount > 0) {
          toast.error(`No se pudo obtener el array de meses: ${getMesesArrayData.errorMessage}`)
          dispatch(setGetMesesArrayErrorCount(0));
        }
        break;

      default:
        break;
    }
  }, [dispatch, getMesesArrayData.errorCount, getMesesArrayData.errorMessage, getMesesArrayData.status,
    getMesesArrayData.successCount, getMesesArrayData.successMessage]);


  return getMesesArrayData;
}

export default useGetMesesArrayMessage;
