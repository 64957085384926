import React from 'react';
import styles from './styles/Imagenes.module.scss';
import ImageInfoCard from './ImageInfoCard';


const LARGE_IMAGE_WIDTH = '100%';
const LARGE_IMAGE_HEIGHT = '492px';

const SMALL_IMAGE_WIDTH = '100%';
const SMALL_IMAGE_HEIGHT = '297px';

const largeImg = {
  width: LARGE_IMAGE_WIDTH,
  height: LARGE_IMAGE_HEIGHT,
  objectFit: 'cover',
};

const smallImg = {
  width: SMALL_IMAGE_WIDTH,
  height: SMALL_IMAGE_HEIGHT,
  objectFit: 'cover',
};


const Imagenes = ({ beneficiosData }) => {

  return (
    <div className={styles.innerContainer}>
      <div className={styles.imageColumn}>
        <ImageInfoCard
          src={beneficiosData.ubicacion.image.url}
          alt={beneficiosData.ubicacion.image.alt}
          imgStyle={largeImg}
          frontText={beneficiosData.ubicacion.title}
          backText={
            <>
              <h2 style={{ paddingLeft: '52px' }}>{beneficiosData.ubicacion.title}</h2>
              <p>{beneficiosData.ubicacion.text}</p>
            </>
          }
        />
        <ImageInfoCard
          src={beneficiosData.espaciosComunes.image.url}
          alt={beneficiosData.espaciosComunes.image.alt}
          imgStyle={smallImg}
          frontText={beneficiosData.espaciosComunes.title}
          backText={
            <>
              <h2 style={{ paddingLeft: '54px' }}>{beneficiosData.espaciosComunes.title}</h2>
              <p>{beneficiosData.espaciosComunes.text}</p>
            </>
          }
        />
      </div>
      <div className={styles.imageColumn}>
        <ImageInfoCard
          src={beneficiosData.gastosFijos.image.url}
          alt={beneficiosData.gastosFijos.image.alt}
          imgStyle={smallImg}
          frontText={beneficiosData.gastosFijos.title}
          backText={
            <>
              <h2 style={{ paddingLeft: '56px' }}>{beneficiosData.gastosFijos.title}</h2>
              <p>{beneficiosData.gastosFijos.text}</p>
            </>
          }
        />
        <ImageInfoCard
          src={beneficiosData.seguridad.image.url}
          alt={beneficiosData.seguridad.image.alt}
          imgStyle={largeImg}
          frontText={beneficiosData.seguridad.title}
          backText={
            <>
              <h2 style={{ paddingLeft: '60px' }}>{beneficiosData.seguridad.title}</h2>
              <p>{beneficiosData.seguridad.text}</p>
            </>
          }
        />
      </div>
      <div className={styles.imageColumn} style={{ marginBottom: '90px' }}>
        <ImageInfoCard
          src={beneficiosData.departamentos.image.url}
          alt={beneficiosData.departamentos.image.alt}
          imgStyle={largeImg}
          frontText={beneficiosData.departamentos.title}
          backText={
            <>
              <h2 style={{ paddingLeft: '50px' }}>{beneficiosData.departamentos.title}</h2>
              <p>{beneficiosData.departamentos.text}</p>
            </>
          }
        />
        <ImageInfoCard
          src={beneficiosData.mantencion.image.url}
          alt={beneficiosData.mantencion.image.alt}
          imgStyle={smallImg}
          frontText={beneficiosData.mantencion.title}
          backText={
            <>
              <h2 style={{ paddingLeft: '55px' }}>{beneficiosData.mantencion.title}</h2>
              <p>{beneficiosData.mantencion.text}</p>
            </>
          }
        />
      </div>
    </div>
  );
}

export default Imagenes;
