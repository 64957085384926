import React, { useState } from 'react';
import { getFriendlyPlaceType, getPlaceType } from './Map/mapUtils';
import styles from './styles/CardLugarInteres.module.scss';

import restaurant from '../static/images/conoce_tu_barrio/restaurant.png';
import banco from '../static/images/conoce_tu_barrio/bank.jpg';
import farmacia from '../static/images/conoce_tu_barrio/farmacia.jpg';
import mall from '../static/images/conoce_tu_barrio/mall.jpg';
import supermarket from '../static/images/conoce_tu_barrio/supermarket.jpg';
import hospital from '../static/images/conoce_tu_barrio/hospital.jpg';
import escuela from '../static/images/conoce_tu_barrio/escuela.jpg';
import gasStation from '../static/images/conoce_tu_barrio/gas_station.jpg';
import ModalLugarInteres from './ModalLugarInteres';


/*
const FAKE_PLACE_DATA = {
  "business_status":"OPERATIONAL",
  "geometry":{
    "location":{
      "lat":-33.446317,
      "lng":-70.6503745
    },
    "viewport":{
      "south":-33.4475565302915,
      "west":-70.6517468302915,
      "north":-33.4448585697085,
      "east":-70.6490488697085
    }
  },
  "icon":"https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/shopping-71.png",
  "name":"Centro Comercial China (Mall Chino)",
  "opening_hours":{
    "open_now":true
  },
  "photos":[
    {
      "height":3024,
      "html_attributions":[
        "<a href=\"https://maps.google.com/maps/contrib/104624444585818578897\">Lampinho Da Bahía</a>"
      ],
      "width":4032
    }
  ],
  "place_id":"ChIJ_32DqqDFYpYR28bVqccWokQ",
  "plus_code":{
    "compound_code":"H83X+FV Santiago, Chile",
    "global_code":"47RFH83X+FV"
  },
  "rating":4,
  "reference":"ChIJ_32DqqDFYpYR28bVqccWokQ",
  "scope":"GOOGLE",
  "types":[
    "shopping_mall",
    "point_of_interest",
    "establishment"
  ],
  "user_ratings_total":12075,
  "vicinity":"Padre Alonso de Ovalle 1065, Santiago, Santiago",
  "html_attributions":[
    
  ]
};
*/


// Carta de lugar de interés
const CardLugarInteres = ({ placeData, marginTop = '' }) => {

  const [showModal, setShowModal] = useState(false);

  if (!placeData || placeData.length === 0) {
    return null;
  }

  const getImageObjFromType = (type) => {
    switch(type) {
      case 'restaurant':
      return { src: restaurant, alt: 'restaurante' };

    case 'bank':
      return { src: banco, alt: 'banco' };

    case 'pharmacy':
      return { src: farmacia, alt: 'farmacia' };

    case 'shopping_mall':
      return { src: mall, alt: 'centro-comercial' };

    case 'supermarket':
      return { src: supermarket, alt: 'supermercado' };

    case 'hospital':
      return { src: hospital, alt: 'hospital' };

    case 'school':
      return { src: escuela, alt: 'educacion' };

    case 'gas_station':
      return { src: gasStation, alt: 'servicentro' };

    default:
      return { src: banco, alt: 'banco' };
    }
  }

  const imgObj = getImageObjFromType(getPlaceType(placeData));

  const onCardClick = () => {
    setShowModal(!showModal);
  }

  const friendlyPlaceType = getFriendlyPlaceType(placeData).toUpperCase();

  return (
    <div className={styles.container} style={{ marginTop }} onClick={onCardClick}>
      <img src={imgObj.src} alt={imgObj.alt} />
      <div className={styles.textContainer}>
        <p className={styles.placeType}>{friendlyPlaceType}</p>
        <h1 className={styles.placeName}>{placeData.name}</h1>
        <p className={styles.placeAddress}>{placeData.vicinity}</p>
        <a className={styles.link} href="/">Más información</a>
      </div>
      {
        showModal &&
        
        <ModalLugarInteres
          show={showModal}
          close={() => setShowModal(false)}
          imgObj={imgObj}
          friendlyPlaceType={friendlyPlaceType}
          name={placeData.name}
          address={placeData.vicinity}
        />
      }
    </div>
  );
}

export default CardLugarInteres;
