import { useEffect } from 'react';
import { disableBodyScroll, enableBodyScroll } from '../utils';


// Hook para desactivar el scroll del body.
// El scroll del body es restaurado cuando el componente que usa el hook es desmontado.
// `show` es un prop opcional que está pensado para pasar el valor correspondiente de los
// componentes de modales (e.g. ModalRegister), para que el hook *no* surta efecto si
// el componente está montando en el DOM pero oculto.
const useModalScroll = (show = true) => {

  useEffect(() => {
    if (show) {
      disableBodyScroll();
    }

    return () => {
      if (show) {
        enableBodyScroll();
      }
    }
  }, [show]);
}

export default useModalScroll;
