/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './styles/EdificiosDestacados.module.scss';
import Carousel from 'react-elastic-carousel';
import LazyLoad from 'react-lazyload';
import { useSelector } from 'react-redux';
import { selectProyectosDestacados } from '../../../redux/proyectosSlice';
import {
  dataLayerPush,
  getDormitoriosAndBanosString,
  getPlaceUrl,
  getPortadaData
} from '../../../utils';
import { Button, TextInfo } from '../../ui/index';
import useResize from '../../../hooks/useResize';
import { BREAKPOINT_MOBILE } from '../../../config/constant';
import PlaceSimilaresMobile from '../../containers/PlaceSimilaresMobile';


const EdificiosDestacados = () => {

  const [rizeWidth] = useResize();

  // FIXME: Ojo porque parece que el CSS está hardcodeado para espaciar exactamente 4 elementos; si
  // ponés menos, el spacing queda medio feo.
  const proyectosDestacados = useSelector(selectProyectosDestacados);

  const onArrendarClick = (proyectoNombre, position) => {
    dataLayerPush({
      event: 'click',
      bannerName: proyectoNombre,
      section: 'quiénes somos nosotros - edificios destacados',
      position,
    });
  }

  const sliderItems = proyectosDestacados.map((proy, index) => {
    const portadaData = getPortadaData(proy);
    const arrendarLinkUrl = getPlaceUrl(proy);

    // Recorrer tipologías del proyecto para encontrar número mínimo y máximo de dormitorios y baños
    let dormitorios = [];
    let banos = [];

    proy.tipologias.forEach((tipo) => {
      dormitorios.push(tipo.dormitorios);
      banos.push(tipo.banos);
    });

    const bothStrs = getDormitoriosAndBanosString(proy, false);
    const dormitoriosStr = bothStrs.dormitoriosStr;
    const banosStr = bothStrs.banosStr;


    return (
      <div key={proy._id} className="item-slider placeNext">
        <div className="image">
          <LazyLoad throttle={50} height={100}>
            <div className="image" style={{ backgroundImage: `url(${portadaData.url})` }}></div>
          </LazyLoad>
        </div>
        <div className="info">
          <TextInfo textWeight="bold" color="black" size="16">{proy.proyecto_nombre}</TextInfo>
          <TextInfo iconColor="#FF6D00" iconName="icon-location-pin" size="14" style={{ whiteSpace: 'nowrap' }}>{proy.direccion}</TextInfo>
          <div className="option-edificio">
            <TextInfo iconColor="#848390" iconName="icon-bed" size="14">{dormitoriosStr}</TextInfo>
            <TextInfo iconColor="#848390" iconName="icon-bath" size="14">{banosStr}</TextInfo>
          </div>
          <Link to={arrendarLinkUrl} onClick={() => onArrendarClick(proy.proyecto_nombre, index + 1)}><Button color="btn-orange" fullWidth={true}>ARRENDAR AQUI</Button></Link>
        </div>
      </div>
    );
  });

  // Usado para acceder al carousel cuando se tocan las flechitas de adelante/atrás
  let carousel;

  return (
    <>
      {
        rizeWidth > BREAKPOINT_MOBILE &&

        <>
          <div className="destacados-edicicios">
            <h2 className="title" style={{ color: '#2C2B42' }}>Edificios destacados</h2>
            <div className="option-slider">
              <Button color='btn-blue-dark' size="small-icon" startIcon="icon-left-arrow" onClick={() => carousel.slidePrev()}></Button>
              <Button color='btn-blue-dark' size="small-icon" startIcon="icon-button-arrow-ight" onClick={() => carousel.slideNext()}></Button>
            </div>
          </div>
          {proyectosDestacados.length === 0 && <div className={styles.loader}>Loading...</div>}
          <div id="corousel-edificios-destacados">
            <Carousel
              ref={el => carousel = el}
              itemsToScroll={4}
              itemsToShow={4}
              enableAutoPlay={false}
              showArrows={false}
              autoPlaySpeed={5000}
              className="slider-edificio">
              {sliderItems}
            </Carousel>
          </div>
        </>
      }
      {
        rizeWidth <= BREAKPOINT_MOBILE &&

        <PlaceSimilaresMobile showAll={true} />
      }
    </>
  );
}

export default EdificiosDestacados;
