import React from 'react';
import { BREAKPOINT_MOBILE } from '../../../config/constant';
import useResize from '../../../hooks/useResize';
import styles from './styles/InfoAdicional.module.scss';


const InfoAdicional = ({ nosotrosData }) => {

  const [rizeWidth] = useResize();


  return (
    <div>
      {
        rizeWidth > BREAKPOINT_MOBILE &&

        <div className={styles.topContainer}>
          <div className={styles.innerContainer}>
            <div>
              <h1>{nosotrosData.deptos}</h1>
              <h2>{nosotrosData.deptosText}</h2>
            </div>
            <div>
              <h1>+{nosotrosData.familias}</h1>
              <h2>{nosotrosData.familiasText}</h2>
            </div>
            <div>
              <h1>{nosotrosData.edificios}</h1>
              <h2>{nosotrosData.edificiosText}</h2>
            </div>
          </div>
        </div>
      }
      {
        rizeWidth <= BREAKPOINT_MOBILE &&

        <div className={styles.mobileContainer}>
          <div className={styles.infoBox}>
            <h1>{nosotrosData.deptos}</h1>
            <span>{nosotrosData.deptosText}</span>
          </div>
          <div style={{ display: 'flex', gap: '10px' }}>
            <div className={styles.infoBox}>
              <h1>{nosotrosData.familias}</h1>
              <span>{nosotrosData.familiasText}</span>
            </div>
            <div className={styles.infoBox}>
              <h1>{nosotrosData.edificios}</h1>
              <span>{nosotrosData.edificiosText}</span>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default InfoAdicional;
