/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './styles/Footer.scss';
import styles from './styles/FooterCustom.module.scss';
import { TextInfo } from './ui/index';
import LazyLoad from 'react-lazyload';
import logoLevel from '../static/images/logo_level.png';
import logoEurocorp from '../static/images/logo_eurocorp.png';
import { useSelector } from 'react-redux';
import {
  selectProyectos,
  selectProyectosFuturos,
  selectUniqueComunas
} from '../redux/proyectosSlice';
import { Link } from 'react-router-dom';
import { getPlaceUrl } from '../utils';
import { LEVEL_COPYRIGHT, LEVEL_LINEA_ETICA_URL } from '../config/constant';
import YoutubeIcon from './YoutubeIcon';
import InstagramIcon from './InstagramIcon';
import FacebookIcon from '../FacebookIcon';


// Habilita/deshabilita el menu item correspondiente
const ENABLED = {
  SOBRE_LEVEL: false,
  COMO_ARRENDAR: false,
  CONTACTO: true,
};


const Footer = () => {

  const proyectos = useSelector(selectProyectos);
  const uniqueComunas = useSelector(selectUniqueComunas);
  const comunasArray = [];

  for (let index = 0; index < 4; index++) {
    const comuna = uniqueComunas[index];
    comunasArray.push(comuna);

  }

  const comunasItems = comunasArray.map((comuna) => {

    // Agarrar proyectos de esta comuna
    const comunaProyectos = proyectos.filter((proy) => proy.comuna === comuna);

    // Armar elementos de la lista
    const proyectosList = comunaProyectos.map((proy) => {
      return (
        <Link key={proy.id} to={getPlaceUrl(proy)}><li>{proy.proyecto_nombre}</li></Link>
      );
    });

    return (
      <div key={comuna} className="navItem">
        <ul className="navList">
          <h2>{comuna}</h2>
          {proyectosList}
        </ul>
      </div>
    );
  })

  const proyectosFuturos = useSelector(selectProyectosFuturos);

  const proximosProyectosItems = proyectosFuturos.map((proy) => {
    return (
      <Link key={proy.id} to={getPlaceUrl(proy)}><li>{proy.proyecto_nombre}</li></Link>
    );
  });


  return (
    <div className="container" style={{ zIndex: '50' }}>
      <section className="footer-container">
        <div className="sociales-container">
          <div className="row">
            <div className="sociales">
              <nav className="links">
                {ENABLED.SOBRE_LEVEL && <a>Sobre Level</a>}
                {ENABLED.COMO_ARRENDAR && <a>¿Cómo arrendar?</a>}
                {ENABLED.CONTACTO && <Link to="/contacto"><TextInfo color="white" textWeight="bold">Contacto</TextInfo></Link>}
              </nav>
              <div className="social">
                <InstagramIcon />
                <FacebookIcon />
                <YoutubeIcon />
              </div>
            </div>
          </div>
        </div>

        <div className="nav-container">
          <div className="row">
            <div className="nav-footer">
              <div className="navItem logos">
                <LazyLoad throttle={50} height={100} offsetVertical={500}>
                  <img className="logo-level" src={logoLevel} alt="logo Level" />
                </LazyLoad>
                <LazyLoad throttle={50} height={100} offsetVertical={500}>
                  <img className="logo-eurocorp" src={logoEurocorp} alt="logo Eurocorp" />
                </LazyLoad>
              </div>
              {/* {comunasItems}
              <div className="navItem">
                <ul className="navList">
                  {proximosProyectosItems.length > 0 && <h2>Próximos Proyectos</h2>}
                  {proximosProyectosItems}
                </ul>
              </div> */}
            </div>
          </div>
        </div>

        <div className="copy-container">
          <div className="row">
            <div className="copy-right">
              <small>{LEVEL_COPYRIGHT}</small>
              <br />
              <a className={styles.lineaEtica} href={LEVEL_LINEA_ETICA_URL} target="_blank" rel="noopener noreferrer">Canal de Denuncia</a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer;
