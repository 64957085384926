import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import Carousel from 'react-elastic-carousel';
import { Modal, Icon, TextInfo, Button } from '../../ui/index';
import {
  selectAmoblado,
  selectBodegas,
  selectEstacionamientosAuto,
  selectEstacionamientosMoto,
  selectTipologiaIndex
}
  from '../../../redux/preevaluacionSlice';
import {
  getBodegaPrecio,
  getEstacionamientoAutoPrecio,
  getEstacionamientoMotoPrecio,
  getPrecioMensual
} from '../../../utils';
import { BACKEND_BASE_URL, BREAKPOINT_MOBILE } from '../../../config/constant';
import notFound from '../../../static/images/notFound.png';
import './styles/ResumenCotizacion.scss';
import GastoComunProyecto from '../../GastoComunProyecto';
import useResize from '../../../hooks/useResize';
import useHideCliengoButtons from '../../../hooks/useHideCliengoButtons';


const THUMB_MAX_WIDTH = '200px';
const THUMB_MAX_HEIGHT = '133px';


// Función auxiliar para construir las slides del slider. Devuelve un array de slides armados a partir
// de la galería de la tipología, o un array vacío si hubo algún problema (e.g. tipología o galería de tipología
// inexistentes).
function buildSlidesFromTipologiaGallery(tipologia) {
  if (!tipologia || tipologia.galeria.length <= 0) {
    return [];
  }

  const slides = tipologia.galeria.map((galeriaItem) => {
    return (
      <div key={galeriaItem.id} className="item-slider">
        <div className="image">
          <img
            src={`${BACKEND_BASE_URL}${galeriaItem.url}`}
            alt={galeriaItem.alternativeText}
            style={{ maxWidth: THUMB_MAX_WIDTH, maxHeight: THUMB_MAX_HEIGHT }}
          />
        </div>
      </div>
    );
  });

  return slides;
}


// Componente que muestra un resumen de una cotización. Tiene dos versiones: una pasando
// 'proyecto', en donde se levanta la data (e.g. estacionamientos y bodegas seleccionados)
// de Redux, y otra, pasando 'cotizacion', en donde la data relevante se extra del objeto
// de cotización. Esta distinción es necesaria porque en el flujo de entrada se muestra
// este componente *antes* de que de hecho se cree el objeto de cotización en Level.
// 'onArrendarClick' es un callback opcional que se llama cuando se pasa 'cotizacion' y se
// clickea el botón "ARRENDAR ONLINE".
const ResumenCotizacion = ({ proyecto, cotizacion, onArrendarClick, close }) => {

  const [rizeWidth] = useResize();

  useHideCliengoButtons();

  let totalImage = 1;
  const [currentImage, setCurrentImage] = useState(1);
  const carouselRef = useRef();

  const tipologiaIndex = useSelector(selectTipologiaIndex);
  const estacionamientosAuto = useSelector(selectEstacionamientosAuto);
  const estacionamientosMoto = useSelector(selectEstacionamientosMoto);
  const bodegas = useSelector(selectBodegas);
  const amoblado = useSelector(selectAmoblado);

  let gastoComun = '';
  let estacionamientosTotal = 0;
  let bodegasTotal = 0;
  let direccion = '';
  let comuna = '';

  let itemsSlider = [
    <div className="item-slider img-not-expand">
      <img src={notFound} alt="not-found" style={{ maxWidth: THUMB_MAX_WIDTH, maxHeight: THUMB_MAX_HEIGHT }} />
    </div>
  ];

  let arriendoDpto = 0;
  let arriendoEstacionamientosAuto = 0;
  let arriendoEstacionamientosMoto = 0;
  let arriendoBodegas = 0;
  let arriendoMensual = 0;
  let buttonText = '';

  if (proyecto) {
    gastoComun = <GastoComunProyecto proyecto={proyecto} />;
    estacionamientosTotal = estacionamientosAuto + estacionamientosMoto;
    bodegasTotal = bodegas;
    buttonText = 'CONTINUAR ARRIENDO';

    direccion = proyecto.direccion;
    comuna = proyecto.comuna;

    arriendoDpto = getPrecioMensual(proyecto, amoblado);

    arriendoEstacionamientosAuto = getEstacionamientoAutoPrecio(proyecto) * estacionamientosAuto;
    arriendoEstacionamientosMoto = getEstacionamientoMotoPrecio(proyecto) * estacionamientosMoto;
    arriendoBodegas = getBodegaPrecio(proyecto) * bodegasTotal;
    arriendoMensual += arriendoDpto + arriendoEstacionamientosAuto + arriendoEstacionamientosMoto + arriendoBodegas;

    // Armar slides
    itemsSlider = buildSlidesFromTipologiaGallery(proyecto.tipologias[tipologiaIndex]);
    totalImage = itemsSlider.length || 1;

  } else if (cotizacion) {

    gastoComun = `$${cotizacion.departamento.gastoComun.toLocaleString()}`;
    estacionamientosTotal = cotizacion.estacionamientos.length;
    bodegasTotal = cotizacion.bodegas.length;
    arriendoMensual = cotizacion.departamento.precio;
    direccion = cotizacion.proyecto.direccion;
    comuna = cotizacion.proyecto.comuna;
    buttonText = 'ARRENDAR ONLINE';

    // Armar slides
    itemsSlider = buildSlidesFromTipologiaGallery(cotizacion.tipologia);
    totalImage = itemsSlider.length || 1;
  }

  // Estilos dinámicos
  const modalContentStyle = rizeWidth <= BREAKPOINT_MOBILE ? {
    width: '90%',
  } : {};

  const cardRStyle = rizeWidth <= BREAKPOINT_MOBILE ? {
    flexDirection: 'column',
    height: 'fit-content',
    alignItems: 'flex-start',
    gap: '10px',
    marginTop: '10px',
    marginBottom: '15px',
  } : {};

  const opcionRStyle = rizeWidth <= BREAKPOINT_MOBILE ? {
    textAlign: 'left',
  } : {};

  const bodyRStyle = rizeWidth <= BREAKPOINT_MOBILE ? {
    paddingTop: '10px',
    paddingBottom: '10px',
  } : {};

  const titularStyle = rizeWidth <= BREAKPOINT_MOBILE ? {
    display: 'flex',
    flexDirection: 'column',
  } : {};


  return (
    <Modal show={true} close={close}>
      <div className="modal-content modal-login small fade" style={{ ...modalContentStyle, borderRadius: '5px' }}>
        <div className="header-modal small">
          <Icon onClick={close} name="icon-close" size="14" />  <TextInfo textWeight="bold" color="black">Resumen de tu cotización</TextInfo>
        </div>
        <div className="_bodyResumen">
          <div className="_galeria-r">
            <span className="countImage">{currentImage} / {totalImage}</span>
            <Carousel
              ref={carouselRef}
              itemsToScroll={1}
              itemsToShow={1}
              enableAutoPlay={true}
              showArrows={false}
              autoPlaySpeed={5000}
              onChange={(currentItem, pageIndex) => {
                if (pageIndex === totalImage - 1) {
                  setCurrentImage(pageIndex + 1);
                  setTimeout(() => {
                    if (carouselRef.current) {
                      carouselRef.current.goTo(0);
                      setCurrentImage(1);
                    }
                  }, 3000);
                } else {
                  setCurrentImage(pageIndex + 1);
                }
              }}

              className="slider-galery-image-place">
              {itemsSlider}
            </Carousel>
          </div>
          <div className="_body-r" style={bodyRStyle}>
            <div className="_titular" style={titularStyle}>
              {
                rizeWidth > BREAKPOINT_MOBILE &&
                <>
                  <TextInfo iconName="icon-location-pin" iconColor="#ff6d00" size={18} textWeight="bold" color="black">{direccion} </TextInfo>
                  <TextInfo size={16}>- {comuna}</TextInfo>
                </>
              }
              {
                rizeWidth <= BREAKPOINT_MOBILE &&
                <>
                  <TextInfo size={18} textWeight="bold" color="black">{direccion}</TextInfo>
                  <TextInfo iconName="icon-location-pin" iconColor="#ff6d00" size={16}>{comuna}</TextInfo>
                </>
              }
            </div>

            <div className="_card-r" style={cardRStyle}>
              <div className="_item-left">
                <TextInfo iconName="icon-edificio" iconSize="19">1 Departamento</TextInfo>
                <TextInfo iconName="icon-garage" iconSize="19">{estacionamientosTotal} Estacionamiento{`${estacionamientosTotal > 1 ? 's' : ''}`}</TextInfo>
                <TextInfo iconName="icon-warehouse" iconSize="19">{bodegasTotal} Bodega{`${bodegasTotal > 1 ? 's' : ''}`}</TextInfo>
              </div>
              <div className="_item-right">
                <div className="_opcion-r" style={opcionRStyle}>
                  <TextInfo size={12}>Gastos comunes desde</TextInfo>
                  <TextInfo size={15} textWeight="bold" color="black"> {gastoComun}</TextInfo>
                </div>
                <div className="_opcion-r" style={opcionRStyle}>
                  <TextInfo size={12}>Arriendo mensual desde</TextInfo>
                  <TextInfo size={18} textWeight="bold" color="orange"> ${arriendoMensual.toLocaleString()}</TextInfo>
                </div>
              </div>
            </div>

            <div className="_bottom-c">
              <Button onClick={cotizacion ? onArrendarClick : close} color='btn-orange' size="small" fullWidth={true}>{buttonText}</Button>
              {proyecto && <TextInfo color="orange" className="link mt-2">Modificar selección</TextInfo>}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ResumenCotizacion;
