import React, { useEffect } from 'react';
import imgHeader from '../../static/images/header_edificios.png';
import { ButtonDropdown, Dropdown } from '../ui/index';
import ComunasCheckboxes from '../ComunasCheckboxes';
import './styles/Slider.scss';
import styles from './styles/Hero.module.scss';
import { useLocation } from 'react-router-dom';
import {
  selectFiltroUbicaciones,
  setFiltroUbicaciones
} from '../../redux/proyectosSlice';
import { useDispatch, useSelector } from 'react-redux';

const Hero = React.memo(() => {
  const location = useLocation();
  const dispatch = useDispatch();
  const filtroUbicaciones = useSelector(selectFiltroUbicaciones);

  const initSearchLoadingDom = () => {
    const paramsSearch = new URLSearchParams(location.search);

    // obtenemos el valor de la comuna
    if (Object.fromEntries(paramsSearch).hasOwnProperty('comuna')) {
      const comuna = paramsSearch.get('comuna');
      const newFiltroUbicaciones = filtroUbicaciones.slice();
      newFiltroUbicaciones.push(comuna);
      dispatch(setFiltroUbicaciones(newFiltroUbicaciones));
    }

    const allDropdownToggle = document.querySelector('.dropdown.show');
    const overlayDropdow = document.querySelector('.overlayDropdown');

    if (overlayDropdow) {
      overlayDropdow.toggleClass('show');
    }


    if (overlayDropdow) {
      overlayDropdow.addEventListener('click', (e) => {
        // allDropdownToggle.removeClass('show');
        overlayDropdow.removeClass('show');
      });
    }

  }
  useEffect(() => {
    initSearchLoadingDom();
  }, []);

  const hideDropdown = () => {
    const dropdown = document.getElementById('comunas-button-dropdown');
    dropdown.click();
  }

  return (
    <div className="container">
      <section id="corousel-principal" style={{ overflow: 'visible' }}>
        <div style={{ position: 'relative', top: '0', left: '0' }}>
          <div className={styles.headerContainer}>
            <img id="edificios-hero-img" src={imgHeader} alt="edificios-header" className={styles.headerImg} />
          </div>
          <div className={styles.headerImgFilter} />
          <div className="row body-center-right extra">
            <div className="card-slider">
              <h2 className={`card-title-slider ${styles.headerText}`}>Conoce todos nuestros edificios y <br />elige dónde quieres vivir.</h2>
              <div className={styles.searchContainer}>
                <div className="overlayDropdown"></div>

                <ButtonDropdown id="comunas-button-dropdown" color="btn-white-gray" placeholder="Selecciona un barrio" fullWidth={true}>

                  <Dropdown title="Barrios">
                    <ComunasCheckboxes />
                  </Dropdown>
                </ButtonDropdown>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
});

export default Hero;
