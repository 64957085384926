/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Modal, Icon, TextInfo, Button } from '../../../ui/index';
import './styles/ModalAvalExtra.scss';


const ModalAvalExtra = ({nextPage, close}) => {
    return (
        <Modal show={true} close={close}>
        <div className="modal-content modal-login md fade">
            <div className="header-modal">
                <Icon onClick={close} name="icon-close" size="14" />
            </div>
            <div className="_body-extra flex-colunm-center">
               <TextInfo textWeight="bold" color="black" size={25}>¡Importante!</TextInfo>
               <TextInfo size={16} className="_nota" color="black">Es necesario agregar un aval o un complemento adicional a tu solicitud para que la evaluación de la cotización sea exitosa.</TextInfo>
               <Button onClick={()=>nextPage(2)} color='btn-orange' size="extra-small" fullWidth={true}>SUMAR UN AVAL O COMPLEMENTO</Button>
                <a href="#" className="mt-2 text-link">Ver arriendos disponibles según tu respaldo</a>
            </div>
        </div>
 </Modal>
    );
};

export default ModalAvalExtra;
