import React from 'react';
import {
  INITIAL_PRECIO_MAX,
  INITIAL_PRECIO_MIN,
  PRECIO_MAX_MAX
} from '../redux/proyectosSlice';
import { GroupRow, Input } from './ui/index';


// Componente que muestra dos inputs para ingresar precio mínimo/máximo.
// El componente es controlado: se le pasan los valores actuales con
// los props `precioMin` y `precioMax`, y se ejecutan los callbacks
// `onPrecioMinChange` y `onPrecioMaxChange` con el event.target.value
// como argumento cuando cambian los valores.
const InputPrecio = ({
  precioMin,
  precioMax,
  onPrecioMinChange,
  onPrecioMaxChange,
}) => {

  return (
    <GroupRow>
      <Input
        id="filtro-input-precio-min"
        type="number"
        min={0}
        value={`${precioMin !== INITIAL_PRECIO_MIN ? precioMin : ''}`}
        step={25000}
        typeStyle="filtro"
        fullWidth={true}
        help="Mínimo"
        placeholder="$ Desde"
        onChange={(event) => onPrecioMinChange(event.target.value)}
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        }}
      />
      <Input
        id="filtro-input-precio-max"
        type="number"
        min={0}
        max={PRECIO_MAX_MAX}
        value={`${precioMax !== INITIAL_PRECIO_MAX ? precioMax : ''}`}
        step={25000}
        typeStyle="filtro"
        fullWidth={true}
        help="Máximo"
        placeholder="$ Hasta"
        onChange={(event) => onPrecioMaxChange(event.target.value)}
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        }}
      />
    </GroupRow>
  );
}

export default InputPrecio;
