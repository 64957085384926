import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectBarrios,
  selectFiltroBarrios,
  setFiltroBarrios
} from '../redux/proyectosSlice';
import { Checkbox, Icon, TextInfo } from './ui/index';


// Componente con una lista de checkboxes para cada barrio.
// Setea el filtro correspondiente en Redux.
const BarriosCheckboxes = ({ hideDropdown }) => {

  const dispatch = useDispatch();
  const barrios = useSelector(selectBarrios);
  const filtroBarrios = useSelector(selectFiltroBarrios);

  const onCheckboxChange = (event, barrio) => {
    if (hideDropdown) {
      hideDropdown();
    }

    // No permitimos deschequear: tiene que haber siempre activo al menos un barrio
    if (event.target.checked) {
      dispatch(setFiltroBarrios(barrio));
    }
  }

  const barriosCheckboxes = barrios.map((barrio, index) => {
    const marginZero = index === barrios.length - 1;

    return (
      <Checkbox
        key={barrio.id}
        checked={filtroBarrios === barrio.direccion}
        onChange={(event) => onCheckboxChange(event, barrio.direccion)}
        position="right"
        marginZero={marginZero}
      >
        <TextInfo color="black"><Icon name="icon-location-pin" border="round" size="12px" /> {barrio.direccion}</TextInfo>
      </Checkbox>
    );
  });

  return (
    <>
      {barriosCheckboxes}
    </>
  );
}

export default BarriosCheckboxes;
