import React from 'react';
import useResize from '../../../hooks/useResize';
import { BREAKPOINT_MOBILE } from '../../../config/constant';
import SliderMobile from '../../containers/SliderMobile';
import styles from './styles/SobreLevel.module.scss';
import { INITIAL_FIRMA_IMAGE } from '../../../redux/nosotrosSlice';


const SobreLevel = ({ nosotrosData }) => {

  const [rizeWidth] = useResize();

  const showFirma = nosotrosData.firmaImage !== INITIAL_FIRMA_IMAGE;

  return (
    <div>
      {
        rizeWidth > BREAKPOINT_MOBILE &&

        <div className={styles.topContainer}>
          <div className={styles.flexContainer}>
            <div className={styles.textContainer}>
              <h1>Sobre Level</h1>
              <p className={styles.quoteStart}>Trabajamos para que nuestros clientes se</p>
              <p className={styles.quoteEnd}>sientan en casa, siendo parte de la comunidad <br />Level.</p>

              <div className={styles.mainText}>
                <p>{nosotrosData.mainText}</p>
              </div>
              <div className={styles.firmaContainer}>
                {
                  showFirma &&
                  <img src={nosotrosData.firmaImage.url} alt={nosotrosData.firmaImage.alt}></img>
                }
                <p>{nosotrosData.firmaText}</p>
              </div>
            </div>
            <div className={styles.imageContainer}>
              <div className={styles.innerImageContainerLeft}>
                <img src={nosotrosData.images[0].url} alt={nosotrosData.images[0].alt} />
                <img src={nosotrosData.images[1].url} alt={nosotrosData.images[1].alt} />
                <img src={nosotrosData.images[2].url} alt={nosotrosData.images[2].alt} />
              </div>
              <div className={styles.innerImageContainerRight}>
                <img src={nosotrosData.images[3].url} alt={nosotrosData.images[3].alt} />
                <img src={nosotrosData.images[4].url} alt={nosotrosData.images[4].alt} />
              </div>
            </div>
          </div>
        </div>
      }
      {
        rizeWidth <= BREAKPOINT_MOBILE &&

        <div className={styles.mobileContainer}>
          <p className={styles.quoteStart}>Trabajamos para que nuestros clientes se sientan en casa, siendo parte de la comunidad Level.</p>

          <div className={styles.mainText}>
            <p>{nosotrosData.mainText}</p>
          </div>

          <div className={styles.firmaContainer}>
            {
              showFirma &&
              <img src={nosotrosData.firmaImage.url} alt={nosotrosData.firmaImage.alt}></img>
            }
            <p>{nosotrosData.firmaText}</p>
          </div>

          <SliderMobile
            home={false}
            imgArray={nosotrosData.images.map(image => {
              return {
                src: image.url,
                alt: image.alt,
              };
            })}
          />
        </div>
      }
    </div>
  );
}

export default SobreLevel;
