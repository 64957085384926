/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextInfo, Icon, Button, Checkbox } from '../ui/index';
import {
  INITIAL_BANOS,
  INITIAL_DORMITORIOS,
  selectFiltroUbicaciones,
  selectUniqueComunas,
  setFiltroUbicaciones
} from '../../redux/proyectosSlice';
import './styles/SearchMobile.scss'
import { Link } from 'react-router-dom';
import { MENU_ENABLED } from '../../config/constant';
import InputFiltro from '../ui/InputFiltro/InputFiltro';
import { scrollToTop } from '../../utils';
import useHideCliengoButtons from '../../hooks/useHideCliengoButtons';


const ARRIENDO_TYPE = {
  DEPTOS: 0,
  ESTACIONAMIENTOS: 1,
  BODEGAS: 2,
};

// Toma un ARRIENDO_TYPE y devuelve la URL a la que debería navegarse cuando se le hace click
const arriendoTypeToUrl = (arriendoType) => {
  switch (arriendoType) {
    case ARRIENDO_TYPE.DEPTOS:
      return '/result';

    case ARRIENDO_TYPE.ESTACIONAMIENTOS:
      return '/estacionamientos';

    case ARRIENDO_TYPE.BODEGAS:
      return '/bodegas';

    default:
      console.log(`arriendoTypeToUrl -> tipo de arriendo "${arriendoType}" desconocido!`);
      return '';
  }
}


const SearchMobile = ({ show, close, identifyStep = 'searchSup' }) => {

  const [arriendoType, setArriendoType] = useState(ARRIENDO_TYPE.DEPTOS);

  useHideCliengoButtons(show);

  useEffect(() => {
    const stepNext = document.querySelectorAll('.stepNext');
    stepNext.forEach((item) => {
      item.addEventListener('click', (event) => {
        event.preventDefault();

        scrollToTop();

        const nexStep = item.getAttribute('data-step');

        const stepHeaderCurrent = document.querySelector(`.h-${identifyStep} .header-step.show`);
        const stepHeaderShow = document.querySelector(`.h-${identifyStep} .header-step.step-${nexStep}`);

        const stepBodyCurrent = document.querySelector(`.b-${identifyStep} .body-step.show`);
        const stepBodyShow = document.querySelector(`.b-${identifyStep} .body-step.step-${nexStep}`);

        const stepFooterCurrent = document.querySelector(`.f-${identifyStep} .footer-step.show`);
        const stepFooterShow = document.querySelector(`.f-${identifyStep} .footer-step.step-${nexStep}`);

        if (stepHeaderCurrent) {
          stepHeaderCurrent.removeClass('show');
        }
        stepHeaderShow.addClass('show');

        if (stepBodyCurrent) {
          stepBodyCurrent.removeClass('show');
        }
        stepBodyShow.addClass('show');

        if (stepFooterCurrent) {
          stepFooterCurrent.removeClass('show');
        }
        stepFooterShow.addClass('show');
      });
    })
  }, [identifyStep]);

  // Armar checkboxes de "ubicaciones" (i.e. comunas)
  const dispatch = useDispatch();
  const filtroUbicaciones = useSelector(selectFiltroUbicaciones);
  const uniqueComunas = useSelector(selectUniqueComunas);

  const onUbicacionCheckboxChange = (event, comuna) => {
    if (event.target.checked) {
      // Ubicación ticked, agregarla al filtro de ubicaciones
      const newFiltroUbicaciones = filtroUbicaciones.slice();
      newFiltroUbicaciones.push(comuna);
      dispatch(setFiltroUbicaciones(newFiltroUbicaciones));
    } else {
      // Ubicación unticked, removerla del filtro de ubicaciones
      const newFiltroUbicaciones = filtroUbicaciones.filter(ubi => ubi !== comuna);
      dispatch(setFiltroUbicaciones(newFiltroUbicaciones));
    }
  }

  const ubicacionCheckboxes = uniqueComunas.map((comuna) => {
    return (
      <Checkbox key={comuna} onChange={(event) => onUbicacionCheckboxChange(event, comuna)} position="right">
        <TextInfo color="black"><Icon name="icon-location-pin" color="#848390" border="round" size="12px" /> {comuna}</TextInfo>
      </Checkbox>
    );
  });

  const onDepartamentosClick = () => {
    setArriendoType(ARRIENDO_TYPE.DEPTOS);
  }

  const onEstacionamientosClick = () => {
    if (MENU_ENABLED.ESTACIONAMIENTOS) {
      setArriendoType(ARRIENDO_TYPE.ESTACIONAMIENTOS);
    }
  }

  const onBodegasClick = () => {
    if (MENU_ENABLED.BODEGAS) {
      setArriendoType(ARRIENDO_TYPE.BODEGAS);
    }
  }

  const onBuscarClick = () => {
    scrollToTop();
    close();
  }

  const estacionamientosColor = MENU_ENABLED.ESTACIONAMIENTOS ? '' : 'darkgray';
  const bodegasColor = MENU_ENABLED.BODEGAS ? '' : 'darkgray';

  return (
    <div className={`modal-search-mobile ${show}`}>
      <div className={`header--search h-${identifyStep}`}>
        <div className="header-step step-1 show" onClick={close}>
          <TextInfo iconName="icon-close" color="white" textWeight="bold">Buscar</TextInfo>
        </div>
        <div className="header-step step-2 stepNext" data-step="1" >
          <TextInfo iconName="icon-left-arrow" color="white" textWeight="bold">Atrás</TextInfo>
        </div>
      </div>
      <div className={`search-body searchArrendaronline  b-${identifyStep}`}>

        <div className="body-step step-1 show">

          <h4 className="card-title">¿Qué quieres arrendar?</h4>
          <div className="items-select">

            <div onClick={onDepartamentosClick} className={`item-card ${arriendoType === ARRIENDO_TYPE.DEPTOS ? 'selected' : ''}`}>
              <Icon name="icon-hotel" size="30" />
              <div className="card-info">
                <p>Departamentos</p>
              </div>
            </div>

            <div onClick={onEstacionamientosClick} className={`item-card ${arriendoType === ARRIENDO_TYPE.ESTACIONAMIENTOS ? 'selected' : ''}`}>
              <Icon name="icon-garage" size="30" color={estacionamientosColor} />
              <div className="card-info">
                <p style={{ color: estacionamientosColor }}>Estacionamientos</p>
              </div>
            </div>

            <div onClick={onBodegasClick} className={`item-card ${arriendoType === ARRIENDO_TYPE.BODEGAS ? 'selected' : ''}`}>
              <Icon name="icon-warehouse" size="30" color={bodegasColor} />
              <div className="card-info">
                <p style={{ color: bodegasColor }}>Bodegas</p>
              </div>
            </div>
          </div>

          {
            MENU_ENABLED.QUIENES_SOMOS &&

            <div className="help">
              <TextInfo size="12">Luego podrás agregar más opciones. ¿Necesitas ayuda? <a>Aprende a arrendar online.</a></TextInfo>
            </div>
          }

        </div>

        <div className="body-step step-2 ">
          <h4 className="card-title">Filtros más comunes</h4>
          <div className="box-cards">
            <div className="box-card">
              <TextInfo className="title-card" textWeight="bold" color="black">Selecciona una ubicación</TextInfo>
              {ubicacionCheckboxes}
            </div>

            <div className="box-card">
              <TextInfo className="title-card" textWeight="bold" color="black">Cantidad de dormitorios y baños</TextInfo>
              <div className="boder-box">
                <InputFiltro type="dormitorio" initialValue={INITIAL_DORMITORIOS} label="Cantidad de dormitorios"
                  name="dormitorio" flex={true} between={true} marginBottom="20" />
                <InputFiltro type="bano" initialValue={INITIAL_BANOS} label="Cantidad de baños"
                  name="bano" flex={true} between={true} />
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className={`search-footer  f-${identifyStep}`}>
        <div className="footer-step step-1 show">
          <Button color='btn-blue' className="btn stepNext" fullWidth={true} size="extra-small" data-step="2">Siguiente</Button>
        </div>
        <div className="footer-step step-2">
          <Link to={arriendoTypeToUrl(arriendoType)}><Button onClick={onBuscarClick} color='btn-orange' fullWidth={true} size="extra-small">Buscar</Button></Link>
        </div>
      </div>
    </div>
  );
};

export default SearchMobile;
