import React, { useEffect } from 'react';
import Layout from '../components/Layout';
import Hero from '../components/Home/Hero';
import InfoDetalle from '../components/Home/InfoDetalle';
import ProximosEdificios from '../components/Home/ProximosEdificios';
import Barrios from '../components/Home/Barrios';
import Experiencia from '../components/Home/Experiencia';
import Comentarios from '../components/Home/Comentarios';
import Destacados from '../components/Home/Destacados';
import useResize from '../hooks/useResize';
import SliderMobile from '../components/containers/SliderMobile';
import EdificiosDestacadosMobile from '../components/containers/EdificiosDestacadosMobile';
import DestacadoInfoMobile from '../components/containers/DestacadoInfoMobile';
import ProximosEdificiosMobile from '../components/containers/ProximosEdificiosMobile';
import ExperienciaMobil from '../components/containers/ExperienciaMobil';
import 'react-toastify/dist/ReactToastify.css';
import {
  BREAKPOINT_MOBILE,
  FEATURE_ENABLED,
} from '../config/constant';
import { dataLayerPush, scrollToTop } from '../utils';
import { useSelector } from 'react-redux';
import {
  selectProyectosFuturos
} from '../redux/proyectosSlice';
import { useLocation } from 'react-router-dom';


const BARRIOS_ENABLED = false;
const EXPERIENCIA_ENABLED = false;


const Home = ({ match }) => {

  const [rizeWidth] = useResize();
  const pageCurrent = match.path;
  const proyectosFuturos = useSelector(selectProyectosFuturos);

  // Scrollear hasta arriba
  useEffect(() => {
    scrollToTop();
  }, []);

  // Analytics
  const location = useLocation();

  useEffect(() => {
    dataLayerPush({
      event: 'vpv',
      page: location.pathname,
      section: 'home',
      url: window.location.href,
    });
  }, [location.pathname]);


  return (
    <Layout pageCurrent={pageCurrent}>
      {rizeWidth > BREAKPOINT_MOBILE && <Hero />}
      <Destacados sizeWidth={rizeWidth} />
      {rizeWidth < BREAKPOINT_MOBILE && <SliderMobile />}
      {rizeWidth < BREAKPOINT_MOBILE && <DestacadoInfoMobile />}
      {rizeWidth < BREAKPOINT_MOBILE && <EdificiosDestacadosMobile />}
      <InfoDetalle />
      {rizeWidth < BREAKPOINT_MOBILE && proyectosFuturos.length > 0 && <ProximosEdificiosMobile />}
      {rizeWidth < BREAKPOINT_MOBILE && EXPERIENCIA_ENABLED && <ExperienciaMobil />}
      {rizeWidth > BREAKPOINT_MOBILE && proyectosFuturos.length > 0 && <ProximosEdificios />}
      {(BARRIOS_ENABLED && (rizeWidth > BREAKPOINT_MOBILE)) && <Barrios />}
      {(EXPERIENCIA_ENABLED && (rizeWidth > BREAKPOINT_MOBILE)) && <Experiencia />}
      {FEATURE_ENABLED.COMENTARIOS && <Comentarios rizeWidth={rizeWidth} />}
    </Layout>
  );
}

export default Home;
