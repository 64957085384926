import React, { useState, useRef, useEffect } from 'react';
import './styles/CardMap.scss';
import GoogleMapReact from 'google-map-react';
import PlaceMap from './containers/PlaceMap';
import { GOOGLE_MAPS_API_KEY } from '../config/constant';
import { selectFilteredProyectos, selectPlaces } from '../redux/proyectosSlice';
import { useSelector } from 'react-redux';
import { getCoordenadas } from '../utils';
import LocationPin from './Map/LocationPin';
import {
  DEFAULT_CENTER,
  DEFAULT_LOCATION,
  DEFAULT_RADIUS,
  DEFAULT_ZOOM,
  getPlaceType,
  nearbySearchPromise
} from './Map/mapUtils';


// Return map bounds based on list of places
// Credits to Michael Diego: https://github.com/google-map-react/google-map-react/issues/590
const getMapBounds = (mapsApi, places) => {
  const bounds = new mapsApi.LatLngBounds();

  places.forEach((place) => {
    bounds.extend(new mapsApi.LatLng(
      place ? place.lat : null,
      place ? place.lng : null,
    ));
  });
  return bounds;
};

// Re-center map when resizing the window
// Credits to Michael Diego: https://github.com/google-map-react/google-map-react/issues/590
const bindResizeListener = (map, maps, bounds) => {
  maps.event.addDomListenerOnce(map, 'idle', () => {
    maps.event.addDomListener(window, 'resize', () => {
      map.fitBounds(bounds);
    });
  });
};

// Fit map to its bounds after the api is loaded
// Credits to Michael Diego: https://github.com/google-map-react/google-map-react/issues/590
const apiIsLoaded = (map, mapsApi, places) => {
  // Get bounds by our places
  const bounds = getMapBounds(mapsApi, places);
  // Fit map to bounds
  map.fitBounds(bounds);
  // Bind the resize listener
  bindResizeListener(map, mapsApi, bounds);
};


const CardMap = ({ onMobilePinClick, disablePlaceCard, cardType, detallesClickCallback }) => {

  const mapRef = useRef();
  const mapsApiRef = useRef();
  const [mapSel, setMapSel] = useState(-1);
  const places = useSelector(selectPlaces);
  const [placesPins, setPlacesPins] = useState(null);
  const filteredProyectos = useSelector(selectFilteredProyectos);

  // Actualizar bounding box (i.e. la caja imaginaria que contiene a todos los marcadores
  // y sirve para setear el centro y nivel de zoom del mapa) cuando los proyectos son obtenidos
  useEffect(() => {
    if (filteredProyectos.length > 0 && mapRef.current && mapsApiRef.current) {
      const places = filteredProyectos.map((proyecto) => getCoordenadas(proyecto));
      apiIsLoaded(mapRef.current, mapsApiRef.current, places);
    }
  }, [filteredProyectos, mapRef, mapsApiRef]);

  const _onClick = (obj, cen, proyecto) => {
    if (mapSel !== obj) {
      setMapSel(obj);
      mapRef.current.panTo({ lat: cen.lat, lng: cen.lng })
    } else {
      setMapSel(-1);
    }

    if (onMobilePinClick) {
      onMobilePinClick(proyecto);
    }
  }

  // Actualizar los pines cuando cambia el punto de interés
  useEffect(() => {
    if (places.length > 0 && mapRef.current && mapsApiRef.current) {
      // Tenemos que usar promesas porque Google deprecó la versión de "nearbySearch" que
      // tomaba un array en type, así que ahora hay que hacer un request separado por
      // cada type distinto
      const promiseArray = [];

      for (let i = 0; i < places.length; i++) {
        const request = {
          location: DEFAULT_LOCATION,
          radius: DEFAULT_RADIUS,
          type: places[i],
        };

        promiseArray.push(nearbySearchPromise(mapRef, mapsApiRef, request, (results, status) => results));
      }

      Promise
        .all(promiseArray)
        .then((results) => {
          const placesData = [];

          for (let i = 0; i < results.length; i++) {
            placesData.push(...results[i]);
          }

          const newPlacesPin = placesData.map((placeData, index) => {
            const lat = placeData.geometry.location.lat();
            const lng = placeData.geometry.location.lng();
            const type = getPlaceType(placeData);
            const name = placeData.name;
            const address = placeData.vicinity.split(',')[0];

            return (
              <LocationPin
                key={index}
                lat={lat}
                lng={lng}
                icon={type}
                name={name}
                address={address}
              />
            );
          });

          setPlacesPins(newPlacesPin);
        })
        .catch((err) => console.log(`Error obteniendo places: ${err}`));
    } else {
      setPlacesPins(null);
    }
  }, [places, mapsApiRef]);

  let proyectosPins = null;

  if (filteredProyectos.length > 0) {
    proyectosPins = filteredProyectos.map((proyecto, index) => {
      const coords = getCoordenadas(proyecto);

      return (
        <PlaceMap
          proyecto={proyecto}
          key={index}
          id={index}
          lat={coords ? coords.lat : null}
          lng={coords ? coords.lng : null}
          selectPlace={mapSel}
          handleClick={_onClick}
          disablePlaceCard={disablePlaceCard}
          cardType={cardType}
          detallesClickCallback={detallesClickCallback}
        />
      );
    });
  }

  const createMapOptions = (maps) => {
    return {
      zoomControlOptions: {
        position: maps.ControlPosition.RIGHT_CENTER,
        style: maps.ZoomControlStyle.SMALL
      },
      mapTypeControlOptions: {
        position: maps.ControlPosition.BOTTOM_RIGHT
      },
      mapTypeControl: false,
      gestureHandling: 'greedy',
    };
  }

  return (
    <div className="content-map">
      <div style={{ height: '100%', width: '100%' }}>
        <GoogleMapReact
          onGoogleApiLoaded={({ map, maps }) => {
            mapRef.current = map;
            mapsApiRef.current = maps;

            if (filteredProyectos.length > 0 && mapRef.current && mapsApiRef.current) {
              const places = filteredProyectos.map((proyecto) => getCoordenadas(proyecto));
              apiIsLoaded(mapRef.current, mapsApiRef.current, places);
            }
          }}
          yesIWantToUseGoogleMapApiInternals={true}
          bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY, libraries: ['places'] }}
          center={DEFAULT_CENTER}
          defaultZoom={DEFAULT_ZOOM}
          hoverDistance={30}
          distanceToMouse={() => { }}
          options={createMapOptions}
        >
          {proyectosPins}
          {placesPins}
        </GoogleMapReact>
      </div>
    </div>
  );

}

export default CardMap;
