import React from 'react';
import { Button, Title } from '../ui/index';
import Carousel from 'react-elastic-carousel';
import './styles/Videos.scss';
import useResize from '../../hooks/useResize';
import { BREAKPOINT_MOBILE } from '../../config/constant';

const Videos = ({videos}) => {
  const [rizeWidth] = useResize();

  // Usado para referenciar el carousel
  let carouselProximo;

  // Construir slider items loopeando sobre los proyectos futuros
  const sliderItems = videos.map((vid, index) => {
    return (
      <div key={vid._id} className={`item-slider placeNext ${index > 0 ? 'margin':''}`}>
        <iframe height="200" class="videoFramer" src={`${vid.url_video}?autoplay=0`} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <span className="titleVideo">{vid.name_video}</span>
      </div>
    );
  });

  return (
    <div className="row">
      <section className="sectionVideos">
          <div className="proximos-edificios">
          <Title id="card-place-videos-header" className="muli" size="30">Videos</Title>

            {(videos && videos.length > 3) && (
              <div className="option-slider">
                <Button color='btn-white-gray' size="small-icon" startIcon="icon-left-arrow" onClick={() => carouselProximo.slidePrev()}></Button>
                <Button color='btn-white-gray' size="small-icon" startIcon="icon-button-arrow-ight" onClick={() => carouselProximo.slideNext()}></Button>
              </div>
            )}
          </div>

          <Carousel
            ref={ref => (carouselProximo = ref)}
            itemsToScroll={rizeWidth > BREAKPOINT_MOBILE ? 3 : 1}
            itemsToShow={rizeWidth > BREAKPOINT_MOBILE ? 3 : 1}
            enableAutoPlay={false}
            showArrows={false}
            autoPlaySpeed={5000}
            className="slider-edificio-proximo">
            {sliderItems}
            
          </Carousel>
      </section>
    </div>
  );
}


export default Videos;
