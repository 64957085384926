import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectProyectos } from '../redux/proyectosSlice';
import { Select } from './ui/index';
import styles from './styles/SelectProyecto.module.scss';


const PLACEHOLDER = 'Seleccionar';


// Componente selector de proyecto. Muestra un <select> estileado con los
// proyectos como opciones. Cuando se cambia la selección, se ejecuta
// onProyectoChange con el nombre del proyecto seleccionado. Si se selecciona
// el placeholder, se llama onProyectoChange con la string vacía ''.
// Si se pasa una string `title`, se muestra como un <p> encima del select.
const SelectProyecto = ({ onProyectoChange, title }) => {

  const [currentProyectoNombre, setCurrentProyectoNombre] = useState('');
  const proyectos = useSelector(selectProyectos);

  let proyectoData = [];

  if (proyectos.length > 0) {
    proyectoData = proyectos.map((proyecto) => {
      return {
        id: proyecto.proyecto_nombre,
        name: proyecto.proyecto_nombre,
      };
    });
  }

  const onValueChange = (event) => {
    setCurrentProyectoNombre(event.target.value);

    if (onProyectoChange) {
      const str = event.target.value === PLACEHOLDER ? '' : event.target.value;
      onProyectoChange(str);
    }
  }


  return (
    <div className={styles.container}>
      {title && <p>{title}</p>}
      <Select
        value={currentProyectoNombre}
        data={proyectoData}
        onChange={onValueChange}
        placeholder={PLACEHOLDER}
        typeStyle="small"
        className="fill"
        fullWidth={true}
      />
    </div>
  );
}

export default SelectProyecto;
