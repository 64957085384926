import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';
import {
  BACKEND_BASE_URL,
  MIN_GASTO_COMUN,
  MIN_PRECIO,
} from '../config/constant';
import {
  cotizacionIsPastGracePeriod,
  getCotizacionExpirationInfo,
  getMinGastosTotales,
  getProyectoByNombre,
  getTipologiaByTitulo,
  getToken,
  productoIsBodega,
  productoIsEstacionamiento,
  sortTipologiaArray
} from '../utils';
const axios = require('axios');


// Define si se loggean mensajes adicionales de debug en la consola
const LOG_DEBUG = false;

export const INITIAL_PLACES = [];
export const INITIAL_DORMITORIOS = 1;
export const MAX_DORMITORIOS = 3;
export const INITIAL_BANOS = 1;
export const MAX_BANOS = 2;
export const INITIAL_REGIONES = [];
export const INITIAL_COMUNAS = [];
export const INITIAL_UBICACIONES = [];
export const INITIAL_SUPERFICIE_MIN = Number.MIN_SAFE_INTEGER;
export const INITIAL_SUPERFICIE_MAX = Number.MAX_SAFE_INTEGER;
export const INITIAL_AMOBLADO = false;
export const INITIAL_PRECIO_MIN = Number.MIN_SAFE_INTEGER;
export const INITIAL_PRECIO_MAX = Number.MAX_SAFE_INTEGER;
export const PRECIO_MAX_MAX = 800000; // máximo valor posible del precio máximo (valga la redundancia)
export const INITIAL_BODEGAS = 0;
export const MAX_BODEGAS = 100; // FIXME: Chequear esto, probablemente hay que calcularlo dinámicamente
export const INITIAL_ESTACIONAMIENTOS = 0;
export const INITIAL_EDIFICIOS = [];
export const INITIAL_MASCOTA = false;
export const INITIAL_ENTREGA_INMEDIATA = false;
export const INITIAL_OFERTAS = false;
export const INITIAL_ESTRENAR = false;
export const INITIAL_ORDENAR = '';
export const INITIAL_TIPO_ESTACIONAMIENTO_AUTO = false;
export const INITIAL_TIPO_ESTACIONAMIENTO_MOTO = false;
export const INITIAL_ESTACIONAMIENTOS_PRECIO_MIN = Number.MIN_SAFE_INTEGER;
export const INITIAL_ESTACIONAMIENTOS_PRECIO_MAX = Number.MAX_SAFE_INTEGER;
export const INITIAL_ESTACIONAMIENTOS_CANTIDAD = 0;
export const MAX_ESTACIONAMIENTOS_CANTIDAD = 50;  // FIXME: Chequear esto, probablemente hay que calcularlo dinámicamente
export const INITIAL_BODEGAS_PRECIO_MIN = Number.MIN_SAFE_INTEGER;
export const INITIAL_BODEGAS_PRECIO_MAX = Number.MAX_SAFE_INTEGER;
export const INITIAL_BODEGAS_CANTIDAD = 0;
export const INITIAL_BARRIOS = [];
export const INITIAL_FILTRO_BARRIOS = 'Santiago';
export const INITIAL_GET_BARRIO_DATA_STATUS = 'idle';
export const INITIAL_ESTACIONAMIENTOS_DISPONIBLES = 0;
export const INITIAL_BODEGAS_DISPONIBLES = 0;
export const MAX_BODEGAS_CANTIDAD = 50; // FIXME: Chequear esto, probablemente hay que calcularlo dinámicamente


const initialState = {
  // Array de proyectos, el schema se puede ver en
  // http://localhost:1337/documentation/v1.0.0#/Proyecto/get_proyectos
  proyectos: [],

  // Proyecto a cotizar
  currentProyect: {},
  // Comentarios. Array de objetos de la forma { nombre, apellido, descripcion, direccion, estrellas }
  comentarios: [],

  // Horarios. Array de objectos de la forma { direccion, comuna, descripcion }
  horarios: [],

  // Ejecutivos de arriendo. Array de objetos de la forma { nombre, telefono, edificios, foto_perfil }
  ejecutivosArriendos: [],

  // Cotizaciones del usuario.
  cotizaciones: [],

  // Estas variables nos dicen en qué estado estamos cuando hacemos un request al back,
  // puede ser 'idle' (no hicimos un request todavía), 'loading' (el request está siendo procesado),
  // 'succeeded' (el request fue exitoso), o 'failed' (el request falló). Hay una por cada async thunk.
  getProyectosStatus: 'idle',
  getComentariosStatus: 'idle',
  getHorariosStatus: 'idle',
  getEjecutivosArriendosStatus: 'idle',
  sendMessageStatus: 'idle',
  getDepartamentosStatus: 'idle',
  getCotizacionesStatus: 'idle',

  // Mensajes de error de algunos thunks
  sendMessageErrorMessage: '',
  getCotizacionesErrorMessage: '',

  // Filtros de /result
  filtroDormitorios: INITIAL_DORMITORIOS,
  filtroBanos: INITIAL_BANOS,
  filtroRegiones: INITIAL_REGIONES,
  filtroComunas: INITIAL_COMUNAS,
  filtroUbicaciones: INITIAL_UBICACIONES,
  filtroSuperficieMin: INITIAL_SUPERFICIE_MIN,
  filtroSuperficieMax: INITIAL_SUPERFICIE_MAX,
  filtroAmoblado: INITIAL_AMOBLADO,
  filtroPrecioMin: INITIAL_PRECIO_MIN,
  filtroPrecioMax: INITIAL_PRECIO_MAX,
  filtroBodegas: INITIAL_BODEGAS,
  filtroEstacionamientos: INITIAL_ESTACIONAMIENTOS,
  filtroEdificios: INITIAL_EDIFICIOS,
  filtroMascota: INITIAL_MASCOTA,
  filtroEntregaInmediata: INITIAL_ENTREGA_INMEDIATA,
  filtroOfertas: INITIAL_OFERTAS,
  filtroEstrenar: INITIAL_ESTRENAR,
  filtroOrdenar: INITIAL_ORDENAR,

  // Filtros de /estacionamientos
  filtroTipoEstacionamientoAuto: INITIAL_TIPO_ESTACIONAMIENTO_AUTO,
  filtroTipoEstacionamientoMoto: INITIAL_TIPO_ESTACIONAMIENTO_MOTO,
  filtroEstacionamientosPrecioMin: INITIAL_ESTACIONAMIENTOS_PRECIO_MIN,
  filtroEstacionamientosPrecioMax: INITIAL_ESTACIONAMIENTOS_PRECIO_MAX,
  filtroEstacionamientosCantidad: INITIAL_ESTACIONAMIENTOS_CANTIDAD,

  // Filtros de /bodegas
  filtroBodegasPrecioMin: INITIAL_BODEGAS_PRECIO_MIN,
  filtroBodegasPrecioMax: INITIAL_BODEGAS_PRECIO_MAX,
  filtroBodegasCantidad: INITIAL_BODEGAS_CANTIDAD,

  // Filtros de /conoce-tu-barrio
  barrios: INITIAL_BARRIOS,
  filtroBarrios: INITIAL_FILTRO_BARRIOS,
  getBarrioDataStatus: INITIAL_GET_BARRIO_DATA_STATUS,
  getBarrioDataStatusAll: INITIAL_GET_BARRIO_DATA_STATUS,

  // Cantidad de primarios y secundarios disponibles
  estacionamientosDisponibles: INITIAL_ESTACIONAMIENTOS_DISPONIBLES,
  bodegasDisponibles: INITIAL_BODEGAS_DISPONIBLES,

  // Puntos de interés seleccionados en el modal ModalFiltroInteres
  places: INITIAL_PLACES,
  spinnerEnabled: false
};

export const sendFirma = createAsyncThunk('proyectos/sendFirma', async ({ reservaid, contratoid }) => {
  const response = await axios.post(`${BACKEND_BASE_URL}/level-api/CrearSolicitudFirma`, {
    reservaid,
    contratoid,
    "tipodocumento": "Contrato de Arriendo",
    "Remota": 1,
    "Flujo": "RESERVA"
  });
  return response;
});

export const getContratoPDF = createAsyncThunk('proyectos/getContratoPDF', async ({ reservaid }) => {
  const response = await axios.post(`${BACKEND_BASE_URL}/level-api/ArchivoPDFContrato`, {
    reservaid,
    "tipodocumento": "Contrato"
  });
  console.log('response :>> ', response);
  return response;
});

export const getContratoByte = createAsyncThunk('proyectos/getContratoByte', async ({ reservaid, contratoid }, { dispatch }) => {
  const { data: { byte } } = await axios.post(`${BACKEND_BASE_URL}/level-api/obtenerArchivoByteContrato`, {
    reservaid,
    contratoid,
    "tipodocumento": "Contrato"
  });

  let pdfWindow = window.open("")
  pdfWindow.document.write(
    "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
    encodeURI(byte) + "'></iframe>"
  )
  dispatch(setSpinner(false))
});

// *NO* intentar agarrar excepciones para NINGUNO de estos thunks! Si se agarra
// la excepción, la función [thunk].rejected no se ejecuta!
export const getProyectos = createAsyncThunk('proyectos/get', async () => {
  try {
    const proyectosResponse = await axios.get(`${BACKEND_BASE_URL}/proyectos`);

    for (let i = 0; i < proyectosResponse.data.length; i++) {
      const currProyecto = proyectosResponse.data[i];

      // Ordenar tipologías de menor a mayor
      currProyecto.tipologias = sortTipologiaArray(currProyecto.tipologias);

      // Settear gastos comunes mínimos de los deptos, recorriendo las tipologías del proyecto
      let gastoComunMinDeptos = Number.MAX_SAFE_INTEGER;

      for (let i = 0; i < currProyecto.tipologias.length; i++) {
        const currTipologia = currProyecto.tipologias[i];

        if (currTipologia.gastocomun_min < gastoComunMinDeptos) {
          gastoComunMinDeptos = currTipologia.gastocomun_min;
        }
      }

      if (gastoComunMinDeptos === Number.MAX_SAFE_INTEGER) {
        gastoComunMinDeptos = '-';
      }

      currProyecto.gastoComunMinDeptos = gastoComunMinDeptos;
    }

    return { proyectosResponse };
  } catch (err) {
    throw new Error(`Error al obtener proyectos o características de deptos: ${err}`);
  }
});

export const getComentarios = createAsyncThunk('proyectos/getComentarios', async () => {
  const response = await axios.get(`${BACKEND_BASE_URL}/comentarios`);
  return response;
});

export const getHorarios = createAsyncThunk('proyectos/getHorarios', async () => {
  const response = await axios.get(`${BACKEND_BASE_URL}/horarios`);
  return response;
});

export const getEjecutivosArriendos = createAsyncThunk('proyectos/getEjecutivosArriendos', async () => {
  const response = await axios.get(`${BACKEND_BASE_URL}/ejecutivos-arriendos`);
  return response;
});

export const getDepartamentos = createAsyncThunk('proyectos/getDepartamentos', async (proyecto) => {
  const urlPrimarios = `${BACKEND_BASE_URL}/level-api/obtenerPrimarios`

  const { data } = await axios.post(urlPrimarios, {
    PROYECTONOMBRE: proyecto.proyecto_nombre
  });

  const response = data.map(({
    NOMBREESTADO, PRODUCTOPRIMARIOPERMITEMASCOTA, PROYECTOID,
    PRODUCTOPRIMARIOID, NOMBREMODELO, GASTOCOMUN,
    PRODUCTOPRIMARIOPISO, PRODUCTOPRIMARIONUMERO, TORREID,
    PRODUCTOPRIMARIOPRECIONOAMOBLADO, PRODUCTOPRIMARIOPRECIOAMOBLADO
  }) => {
    return (
      {
        id: PRODUCTOPRIMARIOID,
        estado: NOMBREESTADO,
        tipologia: {
          titulo: NOMBREMODELO.replaceAll("+", "-")
        },
        piso: PRODUCTOPRIMARIOPISO,
        mascotas: PRODUCTOPRIMARIOPERMITEMASCOTA === 1 ? true : false,
        nombre: PRODUCTOPRIMARIONUMERO.toString(),
        gastocomun: GASTOCOMUN,
        precio_no_amoblado: PRODUCTOPRIMARIOPRECIONOAMOBLADO,
        precio_amoblado: PRODUCTOPRIMARIOPRECIOAMOBLADO,
        torreid: TORREID,
        level_productoprimarioid: PRODUCTOPRIMARIOID,
        proyecto_id: PROYECTOID.toString()
      }
    )
  })
  const responseDeptos = await axios.get(`${BACKEND_BASE_URL}/departamentos?proyecto_nombre=${proyecto.proyecto_nombre}`);

  return { response, proyecto, responseDeptos };
});

export const getCotizaciones = createAsyncThunk('proyectos/getCotizaciones', async (levelClienteId, thunkAPI) => {

  const { getState } = thunkAPI;
  const { user, proyectos } = getState();

  const authObject = {
    email: user.email,
    clienteid: user.levelClienteId,
  };
  let resumenContrato = null
  const jwt = getToken();
  try {
    const { data: { cotizaciones } } = await axios.post(`${BACKEND_BASE_URL}/level-api/obtenercotizacion/${levelClienteId}`, { auth: authObject }, { headers: { 'x-api-key': `Bearer ${jwt}` } });

    for (const cotizacion of cotizaciones) {
      //Obtener proyecto de la cotización y agregarla al objeto
      const proyecto = getProyectoByNombre(cotizacion.proyecto, cotizacion.proyectonombre);
      const producto = cotizacion.productos.filter(item => item.productotipo == "DEPARTAMENTO");
      if (producto.length > 0) {
        const responseTipologia = await axios.get(`${BACKEND_BASE_URL}/departamentos?proyecto_nombre=${cotizacion.proyectonombre}&nombre=${producto[0].productonumero}`);
        const depto = responseTipologia.data[0]
        if (depto) {
          const tipologia = getTipologiaByTitulo(proyecto, depto.tipologia.titulo);
          cotizacion.tipologia = tipologia;
          cotizacion.piso = depto.piso;
        }
      }


    }

    /*  for (const cotizacion of cotizaciones) {
       const { data: { jsonObject: statusEvaluacion } } = await axios.post(`${BACKEND_BASE_URL}/level-api/obtenerEstado`,
         {
           cotizacionId: cotizacion.cotizacionid // === 53050 ? 52985 : cotizacion.cotizacionid
         });
       
         // estado de la evaluación
       cotizacion.status = { evaluacion: statusEvaluacion?.detalleEstado }
       
       if (statusEvaluacion?.detalleEstado && statusEvaluacion?.reservaId) {
        
         const { data: { jsonObject: { descripcionPaso, detalleEstado, descripcionEstado } } } = await axios.post(`${BACKEND_BASE_URL}/level-api/obtenerEstado`,
           {
             reservaId: statusEvaluacion.reservaId
           });
 
           if(descripcionPaso === 'Reserva'){
             cotizacion.status = { reserva: descripcionEstado == 'Pendiente Pago Reserva' ? 0 : 1, ...cotizacion.status }
           }
 
           if(descripcionPaso === 'Contrato' ){
             cotizacion.status = { reserva: 1, ...cotizacion.status }
             cotizacion.status = { contrato: descripcionEstado == 'Pendiente Pago Contrato' ? 0 : 1, ...cotizacion.status }
           }
 
           if(descripcionPaso === 'Firma'){
             cotizacion.status = { reserva: 1, ...cotizacion.status }
             cotizacion.status = { contrato: 1, ...cotizacion.status }
             cotizacion.status = { firma: 1, ...cotizacion.status }
           }
         
           
         // datos del contrato
         const { data: { jsonObject } } = await axios.post(`${BACKEND_BASE_URL}/level-api/obtenerContrato`,
           {
             idReserva: statusEvaluacion.reservaId
           });
 
           jsonObject.idReserva = statusEvaluacion.reservaId
 
         resumenContrato = jsonObject
       }
     } */
    return { cotizaciones }
  } catch (error) {
    const errorMsg = error.response.data.message || error.response.data;
    throw new Error(errorMsg);
  }
});

// Thunk para mandar un mensaje (página /contacto) a Strapi.
// Asume que 'message' es un objeto con la forma { nombre, apellido, email, telefono, mensaje, [archivo] },
// donde 'archivo' es opcional.
export const sendMessage = createAsyncThunk('proyectos/sendMessage', async (message) => {
  try {
    const data = {
      tipo_consulta: message.tipoConsultaText,
      nombre: message.nombre,
      apellido: message.apellido,
      email: message.email,
      telefono: message.telefono,
      rut: message.rut,
      depto: message.nroDepto,
      edificio: message.edificio,
      fecha_salida: message.fechaSalida,
      mensaje: message.mensaje,
    };

    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('files.archivo', message.archivo);

    const response = await axios.post(`${BACKEND_BASE_URL}/contacto-mensajes`, formData);

    return response.data;
  } catch (err) {
    // Capturar excepción y relanzarla con mensaje más descriptivo
    throw new Error(`${err.response.data.statusCode} - ${err.response.data.message}`);
  }
});

// Obtiene los datos del barrio cuya dirección coincide con `barrioDireccion`. Enriquece la entrada
// correspondiente del array "barrios" de este slice.
export const getBarrioData = createAsyncThunk('proyectos/getBarrioData', async (barrioDireccion) => {
  try {
    const response = await axios.get(`${BACKEND_BASE_URL}/barrios?direccion=${barrioDireccion}`);
    return { response };
  }
  catch (err) {
    throw new Error(`Error intentando obtener data del barrio "${barrioDireccion}": ${err}`);
  }
});

export const getBarrioDataAll = createAsyncThunk('proyectos/getBarrioDataAll', async (barrioDireccion) => {
  try {
    const response = await axios.get(`${BACKEND_BASE_URL}/barrios`);
    return { response };
  }
  catch (err) {
    throw new Error(`Error intentando obtener data del barrio "${barrioDireccion}": ${err}`);
  }
});


const proyectosSlice = createSlice({
  name: 'proyectos',
  initialState,
  reducers: {
    setSpinner(state, { payload }) {
      state.spinnerEnabled = payload
    },
    setFiltroDormitorios(state, action) {
      state.filtroDormitorios = action.payload ? action.payload : INITIAL_DORMITORIOS;

      // Analytics
      /*
      dataLayerPush({
        event: 'click',
        dormitorios: state.filtroDormitorios,
      });
      */
    },
    setFiltroBanos(state, action) {
      state.filtroBanos = action.payload ? action.payload : INITIAL_BANOS;

      // Analytics
      /*
      dataLayerPush({
        event: 'click',
        baños: state.filtroBanos,
      });
      */
    },
    setFiltroRegiones(state, action) {
      state.filtroRegiones = action.payload ? action.payload : INITIAL_REGIONES;
    },
    setFiltroComunas(state, action) {
      state.filtroComunas = action.payload ? action.payload : INITIAL_COMUNAS;
    },
    setFiltroUbicaciones(state, action) {
      state.filtroUbicaciones = action.payload ? action.payload : INITIAL_UBICACIONES;

      // Analytics
      /*
      dataLayerPush({
        event: 'click',
        comuna: state.filtroUbicaciones,
      });
      */
    },
    setFiltroSuperficieMin(state, action) {
      state.filtroSuperficieMin = action.payload ? action.payload : INITIAL_SUPERFICIE_MIN;

      // Analytics
      /*
      dataLayerPush({
       event: 'click',
       superficieDesde: state.filtroSuperficieMin,
     });
     */
    },
    setFiltroSuperficieMax(state, action) {
      state.filtroSuperficieMax = action.payload ? action.payload : INITIAL_SUPERFICIE_MAX;

      // Analytics
      /*
      dataLayerPush({
        event: 'click',
        superficieHasta: state.filtroSuperficieMax,
      });
      */
    },
    setFiltroAmoblado(state, action) {
      state.filtroAmoblado = action.payload ? action.payload : INITIAL_AMOBLADO;
    },
    setFiltroPrecioMin(state, action) {
      state.filtroPrecioMin = action.payload ? action.payload : INITIAL_PRECIO_MIN;

      // Analytics
      /*
      dataLayerPush({
        event: 'click',
        rangoPrecioMin: state.filtroPrecioMin,
      });
      */
    },
    setFiltroPrecioMax(state, action) {

      if (action.payload) {
        if (action.payload <= PRECIO_MAX_MAX) {
          state.filtroPrecioMax = action.payload;
        } else {
          state.filtroPrecioMax = PRECIO_MAX_MAX;
        }
      } else {
        state.filtroPrecioMax = INITIAL_PRECIO_MAX;
      }

      // Analytics
      /*
      dataLayerPush({
        event: 'click',
        rangoPrecioMax: state.filtroPrecioMax,
      });
      */
    },
    setFiltroBodegas(state, action) {
      state.filtroBodegas = action.payload ? action.payload : INITIAL_BODEGAS;

      // Analytics
      /*
      dataLayerPush({
        event: 'click',
        bodegas: state.filtroBodegas,
      });
      */
    },
    setFiltroEstacionamientos(state, action) {
      state.filtroEstacionamientos = action.payload ? action.payload : INITIAL_ESTACIONAMIENTOS;
    },
    setFiltroEdificios(state, action) {
      state.filtroEdificios = action.payload ? action.payload : INITIAL_EDIFICIOS;

      // Analytics
      /*
      dataLayerPush({
        event: 'click',
        seleccionEdificio: state.filtroEdificios,
      });
      */
    },
    setFiltroMascota(state, action) {
      state.filtroMascota = action.payload ? action.payload : INITIAL_MASCOTA;

      // Analytics
      /*
      dataLayerPush({
        event: 'click',
        mascotas: state.filtroMascota,
      });
      */
    },
    setFiltroEntregaInmediata(state, action) {
      state.filtroEntregaInmediata = action.payload ? action.payload : INITIAL_ENTREGA_INMEDIATA;

      // Analytics
      /*
      dataLayerPush({
        event: 'click',
        entregaInmediata: state.filtroEntregaInmediata,
      });
      */
    },
    setFiltroOfertas(state, action) {
      state.filtroOfertas = action.payload ? action.payload : INITIAL_OFERTAS;

      // Analytics
      /*
      dataLayerPush({
        event: 'click',
        ofertas: state.filtroOfertas,
      });
      */
    },
    setFiltroEstrenar(state, action) {
      state.filtroEstrenar = action.payload ? action.payload : INITIAL_ESTRENAR;

      // Analytics
      /*
      dataLayerPush({
        event: 'click',
        estrenar: state.filtroEstrenar,
      });
      */
    },
    setFiltroOrdenar(state, action) {
      state.filtroOrdenar = action.payload ? action.payload : INITIAL_ORDENAR;
    },
    setFiltroTipoEstacionamientoAuto(state, action) {
      state.filtroTipoEstacionamientoAuto = action.payload ? action.payload : INITIAL_TIPO_ESTACIONAMIENTO_AUTO;
    },
    setFiltroTipoEstacionamientoMoto(state, action) {
      state.filtroTipoEstacionamientoMoto = action.payload ? action.payload : INITIAL_TIPO_ESTACIONAMIENTO_MOTO;
    },
    setFiltroEstacionamientosPrecioMin(state, action) {
      state.filtroEstacionamientosPrecioMin = action.payload ? action.payload : INITIAL_ESTACIONAMIENTOS_PRECIO_MIN;
    },
    setFiltroEstacionamientosPrecioMax(state, action) {
      state.filtroEstacionamientosPrecioMax = action.payload ? action.payload : INITIAL_ESTACIONAMIENTOS_PRECIO_MAX;
    },
    setFiltroEstacionamientosCantidad(state, action) {
      state.filtroEstacionamientosCantidad = action.payload ? action.payload : INITIAL_ESTACIONAMIENTOS_CANTIDAD;
    },
    setFiltroBodegasPrecioMin(state, action) {
      state.filtroBodegasPrecioMin = action.payload ? action.payload : INITIAL_BODEGAS_PRECIO_MIN;
    },
    setFiltroBodegasPrecioMax(state, action) {
      state.filtroBodegasPrecioMax = action.payload ? action.payload : INITIAL_BODEGAS_PRECIO_MAX;
    },
    setFiltroBodegasCantidad(state, action) {
      state.filtroBodegasCantidad = action.payload ? action.payload : INITIAL_BODEGAS_CANTIDAD;
    },
    setFiltroBarrios(state, action) {
      state.filtroBarrios = action.payload ? action.payload : INITIAL_FILTRO_BARRIOS;
    },
    setCotizaciones(state, action) {
      state.cotizaciones = action.payload ? action.payload : [];
    },

    resetFiltros(state, action) {
      state.filtroDormitorios = INITIAL_DORMITORIOS;
      state.filtroBanos = INITIAL_BANOS;
      state.filtroRegiones = INITIAL_REGIONES;
      state.filtroComunas = INITIAL_COMUNAS;
      state.filtroUbicaciones = INITIAL_UBICACIONES;
      state.filtroSuperficieMin = INITIAL_SUPERFICIE_MIN;
      state.filtroSuperficieMax = INITIAL_SUPERFICIE_MAX;
      state.filtroAmoblado = INITIAL_AMOBLADO;
      state.filtroPrecioMin = INITIAL_PRECIO_MIN;
      state.filtroPrecioMax = INITIAL_PRECIO_MAX;
      state.filtroBodegas = INITIAL_BODEGAS;
      state.filtroEstacionamientos = INITIAL_ESTACIONAMIENTOS;
      state.filtroEdificios = INITIAL_EDIFICIOS;
      state.filtroMascota = INITIAL_MASCOTA;
      state.filtroEntregaInmediata = INITIAL_ENTREGA_INMEDIATA;
      state.filtroOfertas = INITIAL_OFERTAS;
      state.filtroEstrenar = INITIAL_ESTRENAR;
      state.filtroOrdenar = INITIAL_ORDENAR;
      state.filtroTipoEstacionamientoAuto = INITIAL_TIPO_ESTACIONAMIENTO_AUTO;
      state.filtroTipoEstacionamientoMoto = INITIAL_TIPO_ESTACIONAMIENTO_MOTO;
      state.filtroEstacionamientosPrecioMin = INITIAL_ESTACIONAMIENTOS_PRECIO_MIN;
      state.filtroEstacionamientosPrecioMax = INITIAL_ESTACIONAMIENTOS_PRECIO_MAX;
      state.filtroEstacionamientosCantidad = INITIAL_ESTACIONAMIENTOS_CANTIDAD;
      state.filtroBodegasPrecioMin = INITIAL_BODEGAS_PRECIO_MIN;
      state.filtroBodegasPrecioMax = INITIAL_BODEGAS_PRECIO_MAX;
      state.filtroBodegasCantidad = INITIAL_BODEGAS_CANTIDAD;
    },
    setPlaces(state, action) {
      state.places = action.payload ? action.payload : INITIAL_PLACES;
    },
    setAllResultFiltros(state, action) {
      if (action.payload.filtroDormitorios) {
        state.filtroDormitorios = action.payload.filtroDormitorios;
      }

      if (action.payload.filtroBanos) {
        state.filtroBanos = action.payload.filtroBanos;
      }

      if (action.payload.filtroRegiones) {
        state.filtroRegiones = action.payload.filtroRegiones;
      }

      if (action.payload.filtroComunas) {
        state.filtroComunas = action.payload.filtroComunas;
      }

      if (action.payload.filtroUbicaciones) {
        state.filtroUbicaciones = action.payload.filtroUbicaciones;
      }

      if (action.payload.filtroSuperficieMin) {
        state.filtroSuperficieMin = action.payload.filtroSuperficieMin;
      }

      if (action.payload.filtroSuperficieMax) {
        state.filtroSuperficieMax = action.payload.filtroSuperficieMax;
      }

      if (action.payload.filtroAmoblado) {
        state.filtroAmoblado = action.payload.filtroAmoblado;
      }

      if (action.payload.filtroPrecioMin) {
        state.filtroPrecioMin = action.payload.filtroPrecioMin;
      }

      if (action.payload.filtroPrecioMax) {
        state.filtroPrecioMax = action.payload.filtroPrecioMax;
      }

      if (action.payload.filtroBodegas) {
        state.filtroBodegas = action.payload.filtroBodegas;
      }

      if (action.payload.filtroEstacionamientos) {
        state.filtroEstacionamientos = action.payload.filtroEstacionamientos;
      }

      if (action.payload.filtroEdificios) {
        state.filtroEdificios = action.payload.filtroEdificios;
      }

      if (action.payload.filtroMascota) {
        state.filtroMascota = action.payload.filtroMascota;
      }

      if (action.payload.filtroEntregaInmediata) {
        state.filtroEntregaInmediata = action.payload.filtroEntregaInmediata;
      }

      if (action.payload.filtroOfertas) {
        state.filtroOfertas = action.payload.filtroOfertas;
      }

      if (action.payload.filtroEstrenar) {
        state.filtroEstrenar = action.payload.filtroEstrenar;
      }

      if (action.payload.filtroOrdenar) {
        state.filtroOrdenar = action.payload.filtroOrdenar;
      }
    }
  },
  extraReducers: {
    // getProyectos
    [getProyectos.pending]: (state, action) => {
      state.getProyectosStatus = 'loading';
    },
    [getProyectos.fulfilled]: (state, action) => {
      state.getProyectosStatus = 'succeeded';

      const listaProyectosDisponibles = action.payload.proyectosResponse.data.filter((proy) => proy.publicado === true);

      state.proyectos = listaProyectosDisponibles;

      listaProyectosDisponibles.sort(function (a, b) {
        return (a.posicion - b.posicion)
      });
      // Armar barrios
      const comunasRepeated = state.proyectos.map((proy) => proy.comuna);
      const comunasUnique = [...new Set(comunasRepeated)];

      /*  for (const comuna of comunasUnique) {
         state.barrios.push({
           direccion: comuna,
           id: nanoid(),
         });
       } */

      // Guardamos los últimos valores válidos que vimos, para fallbackear a esos valores en caso
      // de no haber detectado ningún valor válido en un proyecto dado.
      let lastValidGastoComunMinEstacionamientosAuto = 0;
      let lastValidGastoComunMinEstacionamientosMoto = 0;
      let lastValidGastoComunMinBodegas = 0;

      let lastValidPrecioMinEstacionamientosAuto = 0;
      let lastValidPrecioMinEstacionamientosMoto = 0;
      let lastValidPrecioMinBodegas = 0;

      // Calcular cantidad de cada secundario (i.e. estacionamientos y bodega) y gastos mínimos
      // comunes de los estacionamientos de auto/moto y de las bodegas
      for (let i = 0; i < state.proyectos.length; i++) {
        const currProyecto = state.proyectos[i];

        currProyecto.cantidadBodegas = 0;
        currProyecto.cantidadEstacionamientosCubiertos = 0;
        currProyecto.cantidadEstacionamientosCubiertosDiscapacitados = 0;
        currProyecto.cantidadEstacionamientosDescubiertos = 0;
        currProyecto.cantidadEstacionamientosMotos = 0;
        currProyecto.cantidadEstacionamientosComerciales = 0;
        currProyecto.cantidadP6bs = 0;

        let gastoComunMinEstacionamientosAuto = Number.MAX_SAFE_INTEGER;
        let gastoComunMinEstacionamientosMoto = Number.MAX_SAFE_INTEGER;
        let gastoComunMinBodegas = Number.MAX_SAFE_INTEGER;

        let precioMinEstacionamientosAuto = Number.MAX_SAFE_INTEGER;
        let precioMinEstacionamientosMoto = Number.MAX_SAFE_INTEGER;
        let precioMinBodegas = Number.MAX_SAFE_INTEGER;

        for (let k = 0; k < currProyecto.secundarios.length; k++) {
          const currSecundario = currProyecto.secundarios[k];

          if (currSecundario.estado === 'DISPONIBLE') {
            switch (currSecundario.nombre) {
              case 'BODEGA':
                currProyecto.cantidadBodegas += 1;

                if (currSecundario.gastocomun >= MIN_GASTO_COMUN.BODEGA &&
                  currSecundario.gastocomun < gastoComunMinBodegas) {
                  gastoComunMinBodegas = currSecundario.gastocomun;
                }

                if (currSecundario.precio >= MIN_PRECIO.BODEGA &&
                  currSecundario.precio < precioMinBodegas) {
                  precioMinBodegas = currSecundario.precio;
                }

                break;

              case 'BODEGA COMERCIAL':
                currProyecto.cantidadBodegas += 1;
                break;

              case 'ESTACIONAMIENTO CUBIERTO':
                currProyecto.cantidadEstacionamientosCubiertos += 1;

                if (currSecundario.gastocomun >= MIN_GASTO_COMUN.EST_CUBIERTO &&
                  currSecundario.gastocomun < gastoComunMinEstacionamientosAuto) {
                  gastoComunMinEstacionamientosAuto = currSecundario.gastocomun;
                }

                if (currSecundario.precio >= MIN_PRECIO.EST_CUBIERTO &&
                  currSecundario.precio < precioMinEstacionamientosAuto) {
                  precioMinEstacionamientosAuto = currSecundario.precio;
                }

                break;

              case 'ESTACIONAMIENTO CUBIERTO DISCAPACITADO':
                currProyecto.cantidadEstacionamientosCubiertosDiscapacitados += 1;
                break;

              case 'ESTACIONAMIENTO DESCUBIERTO':
                currProyecto.cantidadEstacionamientosDescubiertos += 1;
                break;

              case 'ESTACIONAMIENTO COMERCIAL':
                currProyecto.cantidadEstacionamientosComerciales += 1;
                break;

              case 'MOTO ESTACIONAMIENTO':
              case 'ESTACIONAMIENTO MOTOS':
                currProyecto.cantidadEstacionamientosMotos += 1;

                if (currSecundario.gastocomun >= MIN_GASTO_COMUN.EST_MOTO &&
                  currSecundario.gastocomun < gastoComunMinEstacionamientosMoto) {
                  gastoComunMinEstacionamientosMoto = currSecundario.gastocomun;
                }

                if (currSecundario.precio >= MIN_PRECIO.EST_MOTO &&
                  currSecundario.precio < precioMinEstacionamientosMoto) {

                  precioMinEstacionamientosMoto = currSecundario.precio;
                }

                break;

              case 'P6B':
                currProyecto.cantidadP6bs += 1;
                break;

              case 'BP6':
                currProyecto.cantidadP6bs += 1;
                break;

              default:
              // console.log(`Nombre de secundario desconocido: ${currSecundario.nombre}`);
            }
          }

          // Actualizar lastValids independientemente del estado de reserva
          switch (currSecundario.nombre) {
            case 'BODEGA':
              if (currSecundario.gastocomun >= MIN_GASTO_COMUN.BODEGA &&
                currSecundario.gastocomun < gastoComunMinBodegas) {
                lastValidGastoComunMinBodegas = currSecundario.gastocomun;
              }

              if (currSecundario.precio >= MIN_PRECIO.BODEGA &&
                currSecundario.precio < precioMinBodegas) {
                lastValidPrecioMinBodegas = currSecundario.precio;
              }

              break;

            case 'ESTACIONAMIENTO CUBIERTO':
              if (currSecundario.gastocomun >= MIN_GASTO_COMUN.EST_CUBIERTO &&
                currSecundario.gastocomun < gastoComunMinEstacionamientosAuto) {
                lastValidGastoComunMinEstacionamientosAuto = currSecundario.gastocomun;
              }

              if (currSecundario.precio >= MIN_PRECIO.EST_CUBIERTO &&
                currSecundario.precio < precioMinEstacionamientosAuto) {
                lastValidPrecioMinEstacionamientosAuto = currSecundario.precio;
              }

              break;

            case 'MOTO ESTACIONAMIENTO':
            case 'ESTACIONAMIENTO MOTOS':
              if (currSecundario.gastocomun >= MIN_GASTO_COMUN.EST_MOTO &&
                currSecundario.gastocomun < gastoComunMinEstacionamientosMoto) {
                lastValidGastoComunMinEstacionamientosMoto = currSecundario.gastocomun;
              }

              if (currSecundario.precio >= MIN_PRECIO.EST_MOTO &&
                currSecundario.precio < precioMinEstacionamientosMoto) {
                lastValidPrecioMinEstacionamientosMoto = currSecundario.precio;
              }

              break;

            default:
              break;
          }
        }

        // Si no encontramos gastos comunes o precios, fallbackeamos al último válido
        if (gastoComunMinEstacionamientosAuto === Number.MAX_SAFE_INTEGER) {
          gastoComunMinEstacionamientosAuto = lastValidGastoComunMinEstacionamientosAuto;
        }

        if (gastoComunMinEstacionamientosMoto === Number.MAX_SAFE_INTEGER) {
          gastoComunMinEstacionamientosMoto = lastValidGastoComunMinEstacionamientosMoto;
        }

        if (gastoComunMinBodegas === Number.MAX_SAFE_INTEGER) {
          gastoComunMinBodegas = lastValidGastoComunMinBodegas;
        }

        if (precioMinEstacionamientosAuto === Number.MAX_SAFE_INTEGER) {
          precioMinEstacionamientosAuto = lastValidPrecioMinEstacionamientosAuto;
        }

        if (precioMinEstacionamientosMoto === Number.MAX_SAFE_INTEGER) {
          precioMinEstacionamientosMoto = lastValidPrecioMinEstacionamientosMoto;
        }

        if (precioMinBodegas === Number.MAX_SAFE_INTEGER) {
          precioMinBodegas = lastValidPrecioMinBodegas;
        }

        currProyecto.gastoComunMinEstacionamientosAuto = gastoComunMinEstacionamientosAuto;
        currProyecto.gastoComunMinEstacionamientosMoto = gastoComunMinEstacionamientosMoto;
        currProyecto.gastoComunMinBodegas = gastoComunMinBodegas;

        currProyecto.precioMinEstacionamientosAuto = precioMinEstacionamientosAuto;
        currProyecto.precioMinEstacionamientosMoto = precioMinEstacionamientosMoto;
        currProyecto.precioMinBodegas = precioMinBodegas;

        // Sumar estacionamientos y bodegas disponibles totales
        state.estacionamientosDisponibles += currProyecto.cantidadEstacionamientosCubiertos +
          currProyecto.cantidadEstacionamientosCubiertosDiscapacitados +
          currProyecto.cantidadEstacionamientosDescubiertos +
          currProyecto.cantidadEstacionamientosMotos +
          currProyecto.cantidadEstacionamientosComerciales;

        state.bodegasDisponibles += currProyecto.cantidadBodegas;
      }
    },
    [getProyectos.rejected]: (state, action) => {
      state.getProyectosStatus = 'failed';
    },

    // getComentarios
    [getComentarios.pending]: (state, action) => {
      state.getComentariosStatus = 'loading';
    },
    [getComentarios.fulfilled]: (state, action) => {
      state.getComentariosStatus = 'succeeded';
      state.comentarios = action.payload.data;
    },
    [getComentarios.rejected]: (state, action) => {
      state.getComentariosStatus = 'failed';
    },

    // getHorarios
    [getHorarios.pending]: (state, action) => {
      state.getHorariosStatus = 'loading';
    },
    [getHorarios.fulfilled]: (state, action) => {
      state.getHorariosStatus = 'succeeded';
      state.horarios = action.payload.data;
    },
    [getHorarios.rejected]: (state, action) => {
      state.getHorariosStatus = 'failed';
    },

    // getEjecutivosArriendos
    [getEjecutivosArriendos.pending]: (state, action) => {
      state.getEjecutivosArriendosStatus = 'loading';
    },
    [getEjecutivosArriendos.fulfilled]: (state, action) => {
      state.getEjecutivosArriendosStatus = 'succeeded';
      state.ejecutivosArriendos = action.payload.data;
    },
    [getEjecutivosArriendos.rejected]: (state, action) => {
      state.getEjecutivosArriendosStatus = 'failed';
    },

    // getDepartamentos
    [getDepartamentos.pending]: (state, action) => {
      state.getDepartamentosStatus = 'loading';
    },
    [getDepartamentos.fulfilled]: (state, action) => {
      state.getDepartamentosStatus = 'succeeded';

      const departamentos = action.payload.response;
      const proyectoId = action.payload.proyecto.id;
      const { data } = action.payload.responseDeptos
      state.currentProyect.departamentos = data
      // Buscar proyecto y actualizar sus deptos
      const proyecto = state.proyectos.filter((proy) => proy.id === proyectoId)[0];
      proyecto.departamentos = departamentos;
    },
    [getDepartamentos.rejected]: (state, action) => {
      state.getDepartamentosStatus = 'failed';
    },

    // getCotizaciones
    [getCotizaciones.pending]: (state, action) => {
      state.getCotizacionesStatus = 'loading';
    },
    [getCotizaciones.fulfilled]: (state, action) => {
      state.getCotizacionesStatus = 'succeeded';

      const { cotizaciones, resumenContrato, } = action.payload;
      state.resumenContrato = resumenContrato
      // Procesar datos
      const newCotizaciones = [];

      for (const currCotizacion of cotizaciones) {
        // Descartar cotizaciones mal formadas
        if (currCotizacion.productos.length === 0) {
          continue;
        }

        const cotizacionExpirationInfo = getCotizacionExpirationInfo(currCotizacion.cotizacionfechasegumiento);

        let cleanedCotizacion = {
          id: currCotizacion.cotizacionid,
          proyecto: null,
          status: currCotizacion.status,
          productos: currCotizacion.productos,
          resumenContrato: currCotizacion.hasOwnProperty('resumenContrato') ? currCotizacion.resumenContrato : null,
          tipologia: currCotizacion.tipologia,
          departamento: null,
          estacionamientos: [],
          bodegas: [],
          configuracionReserva: currCotizacion.configuracionReserva,
          mesesArriendo: currCotizacion.mesesArriendo,
          ...cotizacionExpirationInfo,
        };

        // Si la cotización expiró y pasó el período de gracia, la descartamos
        if (cotizacionIsPastGracePeriod(cleanedCotizacion)) {
          continue;
        }

        // Obtener proyecto de la cotización y agregarla al objeto
        const proyecto = getProyectoByNombre(currCotizacion.proyecto, currCotizacion.proyectonombre);
        cleanedCotizacion.proyecto = proyecto;

        // Recorrer array de productos y armar información de deptos, estacionamientos y bodegas
        for (const currProducto of currCotizacion.productos) {
          // Agregar info depto
          if (currProducto.productotipo === 'DEPARTAMENTO') {
            cleanedCotizacion.departamento = {
              numero: currProducto.productonumero,
              precio: currProducto.productoprecio,
              gastoComun: currProducto.productogastocomun,
              piso: currCotizacion.piso
            };

          }

          // Agregar info estacionamiento
          if (productoIsEstacionamiento(currProducto.productotipo)) {
            cleanedCotizacion.estacionamientos.push({
              tipo: currProducto.productotipo,
              numero: currProducto.productonumero,
              precio: currProducto.productoprecio,
              gastoComun: currProducto.productogastocomun,
            });
          }

          // Agregar info bodega
          if (productoIsBodega(currProducto.productotipo)) {
            cleanedCotizacion.bodegas.push({
              numero: currProducto.productonumero,
              precio: currProducto.productoprecio,
              gastoComun: currProducto.productogastocomun,
            });
          }
        }

        newCotizaciones.push(cleanedCotizacion);
      }

      state.cotizaciones = newCotizaciones;
    },
    [getCotizaciones.rejected]: (state, action) => {
      state.getCotizacionesStatus = 'failed';
      state.getCotizacionesErrorMessage = action.error.message;
    },

    // getBarrioDataALL
    [getBarrioDataAll.pending]: (state, action) => {
      state.getBarrioDataAllStatus = 'loading';
    },
    [getBarrioDataAll.fulfilled]: (state, action) => {
      state.getBarrioDataAllStatus = 'succeeded';

      const barrios = action.payload.response.data;
      for (const comuna of barrios) {
        state.barrios.push({
          direccion: comuna.direccion,
          id: comuna.id,
        });
      }

    },
    [getBarrioDataAll.rejected]: (state, action) => {
      state.getBarrioDataAllStatus = 'failed';
    },
    // getBarrioData
    [getBarrioData.pending]: (state, action) => {
      state.getBarrioDataStatus = 'loading';
    },
    [getBarrioData.fulfilled]: (state, action) => {
      state.getBarrioDataStatus = 'succeeded';

      const { data } = action.payload.response;

      let barrioData;

      if (data.length > 0) {
        barrioData = data[0];
      } else {
        return;
      }

      for (let i = 0; i < state.barrios.length; i++) {
        const currBarrio = state.barrios[i];

        if (currBarrio.direccion === barrioData.direccion) {
          currBarrio.direccionFull = barrioData.direccion_full;
          currBarrio.descripcion = barrioData.descripcion;

          currBarrio.coordenadas = barrioData.coordenadas;
          const splitCoordenadas = currBarrio.coordenadas.split(',');
          currBarrio.coordenadas = {
            lat: parseFloat(splitCoordenadas[0]),
            lng: parseFloat(splitCoordenadas[1]),
          };

          currBarrio.slider = barrioData.slider;

          for (let i = 0; i < currBarrio.slider.length; i++) {
            const currSlide = currBarrio.slider[i];
            currSlide.alt = currSlide.alternativeText;
            currSlide.url = `${BACKEND_BASE_URL}${currSlide.url}`;
          }
        }
      }
    },
    [getBarrioData.rejected]: (state, action) => {
      state.getBarrioDataStatus = 'failed';
    },

    // sendMessage
    [sendMessage.pending]: (state, action) => {
      state.sendMessageStatus = 'loading';
    },
    [sendMessage.fulfilled]: (state, action) => {
      state.sendMessageStatus = 'succeeded';
      state.sendMessageErrorMessage = '';
    },
    [sendMessage.rejected]: (state, action) => {
      state.sendMessageStatus = 'failed';
      state.sendMessageErrorMessage = action.error.message;
    },
  },
});


export default proyectosSlice.reducer;

export const selectGetProyectosStatus = state => state.proyectos.getProyectosStatus;
export const selectGetComentariosStatus = state => state.proyectos.getComentariosStatus;
export const selectGetHorariosStatus = state => state.proyectos.getHorariosStatus;
export const selectGetEjecutivosArriendosStatus = state => state.proyectos.getEjecutivosArriendosStatus;
export const selectGetDepartamentosStatus = state => state.proyectos.getDepartamentosStatus;
export const selectGetCotizacionesStatus = state => state.proyectos.getCotizacionesStatus;
export const selectSendMessageStatus = state => state.proyectos.sendMessageStatus;
export const selectSendMessageErrorMessage = state => state.proyectos.sendMessageErrorMessage;
export const selectGetCotizacionesErrorMessage = state => state.proyectos.getCotizacionesErrorMessage;
export const selectProyectos = state => state.proyectos.proyectos;

export const selectProyectoById = (proyectoId) => {
  return (state) => {
    return state.proyectos.proyectos.filter((proy) => proy.id === proyectoId)[0];
  }
}

export const selectProyectoBySlug = (proyectoSlug) => {
  return (state) => {
    return state.proyectos.proyectos.filter((proy) => proy.slug === proyectoSlug)[0];
  }
}

export const selectProyectosDestacados = (state) => {
  // veridicamos y filtramos las propiedades que tienen dpt disponibles.
  const proyectosDisponibilidad = state.proyectos.proyectos.filter((proyecto) => {
    const disponibilidad = proyecto.tipologias.find((tipologia) => tipologia.precio_min_amoblado !== -1);
    if (disponibilidad) {
      return proyecto;
    }
  })
  return proyectosDisponibilidad.filter((proy) => {
    return proy.destacado;
  });
}

export const selectProyectosFuturos = (state) => {
  return state.proyectos.proyectos.filter((proy) => {
    return proy.futuro;
  });
}

export const selectProyectosByRegions = (regions) => {
  return (state) => {
    if (!regions) {
      // Devolver todos los proyectos si nos pasaron null
      return state.proyectos.proyectos;
    }

    return state.proyectos.proyectos.filter((proy) => regions.indexOf(proy.region) !== -1);
  }
}

export const selectPlaces = state => state.proyectos.places;

export const selectCotizaciones = state => state.proyectos.cotizaciones;

// Funciones de filtrado. Devuelven true si pasa el filtro, false otherwise.
const checkFiltroRegiones = (filtro, proyecto) => {
  if (filtro.length === 0) {
    return true;
  } else {
    return filtro.indexOf(proyecto.region) !== -1;
  }
}

const checkFiltroUbicaciones = (filtro, proyecto) => {
  if (filtro.length === 0) {
    return true;
  } else {
    return filtro.indexOf(proyecto.comuna) !== -1;
  }
}

const checkFiltroBanos = (filtroBanos, proyecto) => {
  for (let i = 0; i < proyecto.tipologias.length; i++) {
    const tipologia = proyecto.tipologias[i];
    if (tipologia.banos >= filtroBanos) {
      return true;
    }
  }

  return false;
}

const checkFiltroDormitorios = (filtroDormitorios, proyecto) => {
  for (let i = 0; i < proyecto.tipologias.length; i++) {
    const tipologia = proyecto.tipologias[i];

    if (tipologia.dormitorios >= filtroDormitorios) {
      return true;
    }
  }

  return false;
}

const checkFiltroSuperficie = (filtroSuperficieMin, filtroSuperficieMax, proyecto) => {
  for (let i = 0; i < proyecto.tipologias.length; i++) {
    const currTipo = proyecto.tipologias[i];

    if (currTipo.superficie <= filtroSuperficieMax) {
      // currTipo cumple condición de superficie máxima, chequear si también cumple condición de superficie mínima
      if (filtroSuperficieMin <= currTipo.superficie) {
        return true;
      }
    }
  }

  return false;
}

const checkFiltroPrecio = (filtroPrecioMin, filtroPrecioMax, amoblado, proyecto) => {
  // Si no están los datos relevantes en el backend, pasar el filtro
  if (!proyecto.precioAvgAmoblado || !proyecto.precioAvgNoAmoblado) {
    return true;
  }

  if (amoblado) {
    return filtroPrecioMin <= proyecto.precioAvgAmoblado &&
      proyecto.precioAvgAmoblado <= filtroPrecioMax;
  } else {
    return filtroPrecioMin <= proyecto.precioAvgNoAmoblado &&
      proyecto.precioAvgNoAmoblado <= filtroPrecioMax;
  }
}

const checkFiltroBodegas = (filtroBodegas, proyecto) => {
  return proyecto.cantidadBodegas >= filtroBodegas;
}

const checkFiltroEstacionamientos = (filtroEstacionamientos, proyecto) => {
  // Los "estacionamientos" a secas se cuentan de hecho como estacionamientos cubiertos
  return proyecto.cantidadEstacionamientosCubiertos >= filtroEstacionamientos ||
    proyecto.cantidadEstacionamientosDescubiertos >= filtroEstacionamientos;
}

const checkFiltroEdificios = (filtroEdificios, proyecto) => {
  if (filtroEdificios.length === 0) {
    return true;
  } else {
    return filtroEdificios.indexOf(proyecto.proyecto_nombre) !== -1;
  }
}

const checkFiltroMascota = (filtroMascota, proyecto) => {
  // Si el filtro está en false, devolvemos true sin chequear nada
  if (!filtroMascota) {
    return true;
  }

  return proyecto.mascotas;
}

const checkFiltroEntregaInmediata = (filtroEntregaInmediata, proyecto) => {
  // Si el filtro está en false, devolvemos true sin chequear nada
  if (!filtroEntregaInmediata) {
    return true;
  }

  return proyecto.entrega_inmediata;
}

const checkFiltroOfertas = (filtroOfertas, proyecto) => {
  // Si el filtro está en false, devolvemos true sin chequear nada
  if (!filtroOfertas) {
    return true;
  }

  return proyecto.descuento;
}

const checkFiltroEstrenar = (filtroEstrenar, proyecto) => {
  // Si el filtro está en false, devolvemos true sin chequear nada
  if (!filtroEstrenar) {
    return true;
  }

  // FIXME: Implementar. Acá hay el mismo problema que para el filtro de mascotas. Además, actualmente
  // este campo no está en el schema de Departamentos en Strapi, así que habría que agregar eso primero.
  return false;
}

const checkFiltroAmoblado = (filtroAmoblado, proyecto) => {
  if (!filtroAmoblado) {
    return true;
  }

  // Si filtroAmoblado = true, tenemos que chequear que al menos una de las tipologías del proyecto
  // tenga amoblado = true
  for (let i = 0; i < proyecto.tipologias.length; i++) {
    const currTipo = proyecto.tipologias[i];

    if (currTipo.amoblado) {
      return true;
    }
  }

  return false;
}

// Checkea todos los filtros con el proyecto pasado. Devuelve true si pasa todos los filtros, false otherwise.
const checkAllFiltros = (state, proyecto) => {
  let regionFilterResult, ubicacionFilterResult, banoFilterResult, dormitorioFilterResult,
    superficieFilterResult, precioFilterResult, bodegaFilterResult, estacionamientoFilterResult,
    edificioFilterResult, mascotaFilterResult, entregaInmediataFilterResult, ofertasFilterResult,
    estrenarFilterResult, amobladoFilterResult;

  regionFilterResult = checkFiltroRegiones(state.proyectos.filtroRegiones, proyecto);

  // Shortcircuits, para hacerlo más eficiente (y de paso loggeamos data para debug)
  if (!regionFilterResult) {
    if (LOG_DEBUG) {
      console.log(`${proyecto.proyecto_nombre}: regionFilterResult = false!`);
    }
    return false;
  }

  ubicacionFilterResult = checkFiltroUbicaciones(state.proyectos.filtroUbicaciones, proyecto);

  if (!ubicacionFilterResult) {
    if (LOG_DEBUG) {
      console.log(`${proyecto.proyecto_nombre}: ubicacionFilterResult = false!`);
    }
    return false;
  }

  banoFilterResult = checkFiltroBanos(state.proyectos.filtroBanos, proyecto);


  if (!banoFilterResult) {
    if (LOG_DEBUG) {
      console.log(`${proyecto.proyecto_nombre}: banoFilterResult = false!`);
    }
    return false;
  }

  dormitorioFilterResult = checkFiltroDormitorios(state.proyectos.filtroDormitorios, proyecto);

  if (!dormitorioFilterResult) {
    if (LOG_DEBUG) {
      console.log(`${proyecto.proyecto_nombre}: dormitorioFilterResult = false!`);
    }
    return false;
  }

  superficieFilterResult = checkFiltroSuperficie(state.proyectos.filtroSuperficieMin,
    state.proyectos.filtroSuperficieMax, proyecto);

  if (!superficieFilterResult) {
    if (LOG_DEBUG) {
      console.log(`${proyecto.proyecto_nombre}: superficieFilterResult = false!`);
    }
    return false;
  }

  precioFilterResult = checkFiltroPrecio(state.proyectos.filtroPrecioMin, state.proyectos.filtroPrecioMax,
    state.proyectos.filtroAmoblado, proyecto);

  if (!precioFilterResult) {
    if (LOG_DEBUG) {
      console.log(`${proyecto.proyecto_nombre}: precioFilterResult = false!`);
    }
    return false;
  }

  bodegaFilterResult = checkFiltroBodegas(state.proyectos.filtroBodegas, proyecto);

  if (!bodegaFilterResult) {
    if (LOG_DEBUG) {
      console.log(`${proyecto.proyecto_nombre}: bodegaFilterResult = false!`);
    }
    return false;
  }

  estacionamientoFilterResult = checkFiltroEstacionamientos(state.proyectos.filtroEstacionamientos, proyecto);

  if (!estacionamientoFilterResult) {
    if (LOG_DEBUG) {
      console.log(`${proyecto.proyecto_nombre}: estacionamientoFilterResult = false!`);
    }
    return false;
  }

  edificioFilterResult = checkFiltroEdificios(state.proyectos.filtroEdificios, proyecto);

  if (!edificioFilterResult) {
    if (LOG_DEBUG) {
      console.log(`${proyecto.proyecto_nombre}: edificioFilterResult = false!`);
    }
    return false;
  }

  mascotaFilterResult = checkFiltroMascota(state.proyectos.filtroMascota, proyecto);

  if (!mascotaFilterResult) {
    if (LOG_DEBUG) {
      console.log(`${proyecto.proyecto_nombre}: mascotaFilterResult = false!`);
    }
    return false;
  }

  entregaInmediataFilterResult = checkFiltroEntregaInmediata(state.proyectos.filtroEntregaInmediata, proyecto);

  if (!entregaInmediataFilterResult) {
    if (LOG_DEBUG) {
      console.log(`${proyecto.proyecto_nombre}: entregaInmediataFilterResult = false!`);
    }
    return false;
  }

  ofertasFilterResult = checkFiltroOfertas(state.proyectos.filtroOfertas, proyecto);

  if (!ofertasFilterResult) {
    if (LOG_DEBUG) {
      console.log(`${proyecto.proyecto_nombre}: ofertasFilterResult = false!`);
    }
    return false;
  }

  estrenarFilterResult = checkFiltroEstrenar(state.proyectos.filtroEstrenar, proyecto);

  if (!estrenarFilterResult) {
    if (LOG_DEBUG) {
      console.log(`${proyecto.proyecto_nombre}: estrenarFilterResult = false!`);
    }
    return false;
  }

  amobladoFilterResult = checkFiltroAmoblado(state.proyectos.filtroAmoblado, proyecto);

  if (!amobladoFilterResult) {
    if (LOG_DEBUG) {
      console.log(`${proyecto.proyecto_nombre}: amobladoFilterResult = false!`);
    }
    return false;
  }

  return true;
}

export const selectFilteredProyectos = (state) => {
  const { proyectos, filtroOrdenar, filtroAmoblado } = state.proyectos;
  // veridicamos y filtramos las propiedades que tienen dpt disponibles.
  const proyectosSinDisponibilidad = proyectos.filter((proyecto) => {
    const disponibilidad = proyecto.tipologias.find((tipologia) => tipologia.precio_min_amoblado !== -1 && tipologia.banos >= state.proyectos.filtroBanos && tipologia.dormitorios >= state.proyectos.filtroDormitorios && (state.proyectos.filtroSuperficieMin <= tipologia.superficie &&
      tipologia.superficie <= state.proyectos.filtroSuperficieMax));
    if (disponibilidad) {
      return proyecto;
    }
  })


  const filteredProyectos = proyectosSinDisponibilidad.filter((proyecto) => checkAllFiltros(state, proyecto));

  if (filtroOrdenar !== '') {
    filteredProyectos.sort((a, b) => {
      // Actualizar esto si cambia TIPO_ORDENAR en src/config/constant.js
      switch (filtroOrdenar) {
        case 'Recomendados':
          // FIXME: Implementar este tipo de ordenamiento. Por ahora, dejamos `a` y `b` en el orden
          // que estaban
          return 0;

        case 'Menor precio':
          {
            const gastoTotalA = getMinGastosTotales(a, filtroAmoblado);
            const gastoTotalB = getMinGastosTotales(b, filtroAmoblado);
            return gastoTotalA - gastoTotalB;
          }

        case 'Mayor precio':
          {
            const gastoTotalA = getMinGastosTotales(a, filtroAmoblado);
            const gastoTotalB = getMinGastosTotales(b, filtroAmoblado);
            return gastoTotalB - gastoTotalA;
          }

        case 'Más solicitados':
          // FIXME: Implementar este tipo de ordenamiento. Por ahora, dejamos `a` y `b` en el orden
          // que estaban
          return 0;

        default:
          console.log(`selectFilteredProyectos -> filtroOrdenar "${filtroOrdenar}" inválido!`);
          return 0;
      }
    });
  }

  return filteredProyectos;
}

function tipologiaPassesFilters(tipologia, filtroDormitorios, filtroBanos, filtroSuperficieMin,
  filtroSuperficieMax) {
  if (tipologia.dormitorios < filtroDormitorios) {
    return false;
  }

  if (tipologia.banos < filtroBanos) {
    return false;
  }

  return filtroSuperficieMin <= tipologia.superficie &&
    tipologia.superficie <= filtroSuperficieMax;
}

export const selectPlaceFilteredTipologias = (proyectoId) => {
  return (state) => {
    if (!proyectoId) {
      return [];
    }

    const {
      proyectos,
      filtroDormitorios,
      filtroBanos,
      filtroSuperficieMin,
      filtroSuperficieMax,
    } = state.proyectos;

    const currProyecto = proyectos.filter((proy) => proy.id === proyectoId)[0];

    if (!currProyecto) {
      return [];
    }

    const filteredTipologias = [];

    for (let i = 0; i < currProyecto.tipologias.length; i++) {
      const currTipologia = currProyecto.tipologias[i];

      if (tipologiaPassesFilters(currTipologia, filtroDormitorios, filtroBanos,
        filtroSuperficieMin, filtroSuperficieMax)) {
        filteredTipologias.push(currTipologia);
      }
    }

    return filteredTipologias;
  }
}

const checkFiltroEstacionamientosPrecio = (precioMin, precioMax, secundarios, cantidad,
  estacionamientosAuto, estacionamientosMoto) => {
  // En la UI tenemos un solo input de "cantidad", lo estamos interpretando como indicando la cantidad
  // mínima de estacionamientos de CADA tipo seleccionado, i.e., si el usuario selecciona ambos tipos de
  // estacionamientos (moto y auto) y pone "cantidad" en 3, los proyectos que van a pasar el filtro son
  // aquellos que tienen *al menos* 3 estacionamientos de auto y 3 estacionamientos de moto.
  let countAutos = 0;
  let countMotos = 0;

  for (let i = 0; i < secundarios.length; i++) {
    const currSecundario = secundarios[i];

    if (estacionamientosAuto) {
      if (currSecundario.nombre === 'ESTACIONAMIENTO CUBIERTO' && currSecundario.estado === 'DISPONIBLE' &&
        precioMin <= currSecundario.precio && currSecundario.precio <= precioMax) {
        countAutos += 1;
      }
    }

    if (estacionamientosMoto) {
      if ((currSecundario.nombre === 'MOTO ESTACIONAMIENTO' || currSecundario.nombre === 'ESTACIONAMIENTO MOTOS')
        && currSecundario.estado === 'DISPONIBLE' && precioMin <= currSecundario.precio &&
        currSecundario.precio <= precioMax) {
        countMotos += 1;
      }
    }
  }

  const passesAuto = estacionamientosAuto ? countAutos >= cantidad : true;
  const passesMoto = estacionamientosMoto ? countMotos >= cantidad : true;

  return passesAuto && passesMoto;
}

const checkFiltroEstacionamientosCantidad = (filtroEstacionamientosCantidad,
  filtroTipoEstacionamientoAuto, filtroTipoEstacionamientoMoto, cantidadEstacionamientosCubiertos,
  cantidadEstacionamientosMotos) => {

  if (filtroTipoEstacionamientoAuto) {
    if (cantidadEstacionamientosCubiertos < filtroEstacionamientosCantidad) {
      return false;
    }
  }

  if (filtroTipoEstacionamientoMoto) {
    if (cantidadEstacionamientosMotos < filtroEstacionamientosCantidad) {
      return false;
    }
  }
}

const checkEstacionamientosFiltros = (state, proyecto) => {
  let ubicacionFilterResult, edificioFilterResult, precioFilterResult, cantidadEstacionamientosFilterResult;

  ubicacionFilterResult = checkFiltroUbicaciones(state.proyectos.filtroUbicaciones, proyecto);

  if (!ubicacionFilterResult) {
    if (LOG_DEBUG) {
      console.log(`${proyecto.proyecto_nombre}: ubicacionFilterResult = false! (filtro estacionamientos)`);
    }
    return false;
  }

  edificioFilterResult = checkFiltroEdificios(state.proyectos.filtroEdificios, proyecto);

  if (!edificioFilterResult) {
    if (LOG_DEBUG) {
      console.log(`${proyecto.proyecto_nombre}: edificioFilterResult = false! (filtro estacionamientos)`);
    }
    return false;
  }

  precioFilterResult = checkFiltroEstacionamientosPrecio(state.proyectos.filtroEstacionamientosPrecioMin,
    state.proyectos.filtroEstacionamientosPrecioMax, proyecto.secundarios,
    state.proyectos.filtroEstacionamientosCantidad, state.proyectos.filtroTipoEstacionamientoAuto,
    state.proyectos.filtroTipoEstacionamientoMoto);

  if (!precioFilterResult) {
    if (LOG_DEBUG) {
      console.log(`${proyecto.proyecto_nombre}: precioFilterResult = false! (filtro estacionamientos)`);
    }
    return false;
  }

  cantidadEstacionamientosFilterResult = checkFiltroEstacionamientosCantidad(state.proyectos.filtroEstacionamientosCantidad,
    state.proyectos.filtroTipoEstacionamientoAuto, state.proyectos.filtroTipoEstacionamientoMoto,
    proyecto.cantidadEstacionamientosCubiertos, proyecto.cantidadEstacionamientosMotos);

  if (!cantidadEstacionamientosFilterResult) {
    if (LOG_DEBUG) {
      console.log(`${proyecto.proyecto_nombre}: cantidadEstacionamientosFilterResult = false! (filtro estacionamientos)`);
    }
  }

  return true;
}

export const selectFilteredProyectosByEstacionamientos = (state) => {
  return state.proyectos.proyectos.filter((proyecto) => checkEstacionamientosFiltros(state, proyecto));
}

const checkFiltroBodegasPrecio = (precioMin, precioMax, secundarios, cantidad) => {
  let countBodegas = 0;

  for (let i = 0; i < secundarios.length; i++) {
    const currSecundario = secundarios[i];

    if (currSecundario.nombre === 'BODEGA' && currSecundario.estado === 'DISPONIBLE' &&
      precioMin <= currSecundario.precio && currSecundario.precio <= precioMax) {
      countBodegas += 1;
    }
  }

  return countBodegas >= cantidad;
}

const checkFiltroBodegasCantidad = (proyecto, filtroBodegasCantidad) => {
  return proyecto.cantidadBodegas >= filtroBodegasCantidad;
}

const checkBodegasFiltros = (state, proyecto) => {
  let ubicacionFilterResult, edificioFilterResult, precioFilterResult, cantidadFilterResult;

  ubicacionFilterResult = checkFiltroUbicaciones(state.proyectos.filtroUbicaciones, proyecto);

  if (!ubicacionFilterResult) {
    if (LOG_DEBUG) {
      console.log(`${proyecto.proyecto_nombre}: ubicacionFilterResult = false! (filtro bodegas)`);
    }
    return false;
  }

  edificioFilterResult = checkFiltroEdificios(state.proyectos.filtroEdificios, proyecto);

  if (!edificioFilterResult) {
    if (LOG_DEBUG) {
      console.log(`${proyecto.proyecto_nombre}: edificioFilterResult = false! (filtro bodegas)`);
    }
    return false;
  }

  precioFilterResult = checkFiltroBodegasPrecio(state.proyectos.filtroBodegasPrecioMin,
    state.proyectos.filtroBodegasPrecioMax, proyecto.secundarios, state.proyectos.filtroBodegasCantidad);

  if (!precioFilterResult) {
    if (LOG_DEBUG) {
      console.log(`${proyecto.proyecto_nombre}: precioFilterResult = false! (filtro bodegas)`);
    }
    return false;
  }

  cantidadFilterResult = checkFiltroBodegasCantidad(proyecto, state.proyectos.filtroBodegasCantidad)

  if (!cantidadFilterResult) {
    if (LOG_DEBUG) {
      console.log(`${proyecto.proyecto_nombre}: cantidadFilterResult = false! (filtro bodegas)`);
    }
    return false;
  }

  return true;
}

export const selectFilteredProyectosByBodegas = (state) => {
  return state.proyectos.proyectos.filter((proyecto) => checkBodegasFiltros(state, proyecto));
}

// Selecciona las tipologías del proyecto con id 'proyectoId' que pasan los filtros, en donde tiene
// sentido (e.g. se filtra por las propiedades que tienen las tipologías y se ignoran las propiedades de
// los proyectos como región, comuna, etc.)
export const selectFilteredTipologias = (proyectoId) => {
  return (state) => {
    if (!proyectoId) {
      return null;
    }

    const proyecto = state.proyectos.proyectos.filter((proy) => proy.id === proyectoId)[0];

    if (!proyecto) {
      return null;
    }

    return proyecto.tipologias.filter((tipologia) => {
      const banoFilterResult = tipologia.banos >= state.proyectos.filtroBanos;
      const dormitorioFilterResult = tipologia.dormitorios >= state.proyectos.filtroDormitorios;
      const superficieResult = state.proyectos.filtroSuperficieMin <= tipologia.superficie &&
        tipologia.superficie <= state.proyectos.filtroSuperficieMax;

      return banoFilterResult && dormitorioFilterResult && superficieResult;
    });
  }
}

export const selectFiltroDormitorios = (state) => {
  return state.proyectos.filtroDormitorios;
}

export const selectFiltroBanos = (state) => {
  return state.proyectos.filtroBanos;
}

export const selectFiltroRegiones = (state) => {
  return state.proyectos.filtroRegiones;
}

export const selectFiltroComunas = (state) => {
  return state.proyectos.filtroComunas;
}

export const selectFiltroUbicaciones = (state) => {
  return state.proyectos.filtroUbicaciones;
}

export const selectFiltroSuperficieMin = (state) => {
  return state.proyectos.filtroSuperficieMin;
}

export const selectFiltroSuperficieMax = (state) => {
  return state.proyectos.filtroSuperficieMax;
}

export const selectFiltroAmoblado = (state) => {
  return state.proyectos.filtroAmoblado;
}

export const selectFiltroPrecioMin = (state) => {
  return state.proyectos.filtroPrecioMin;
}

export const selectFiltroPrecioMax = (state) => {
  return state.proyectos.filtroPrecioMax;
}

export const selectFiltroBodegas = (state) => {
  return state.proyectos.filtroBodegas;
}

export const selectFiltroEstacionamientos = (state) => {
  return state.proyectos.filtroEstacionamientos;
}

export const selectFiltroEdificios = (state) => {
  return state.proyectos.filtroEdificios;
}

export const selectFiltroMascota = (state) => {
  return state.proyectos.filtroMascota;
}

export const selectFiltroEntregaInmediata = (state) => {
  return state.proyectos.filtroEntregaInmediata;
}

export const selectFiltroOfertas = (state) => {
  return state.proyectos.filtroOfertas;
}

export const selectFiltroEstrenar = (state) => {
  return state.proyectos.filtroEstrenar;
}

export const selectFiltroOrdenar = (state) => {
  return state.proyectos.filtroOrdenar;
}

export const selectFiltroTipoEstacionamientoAuto = (state) => {
  return state.proyectos.filtroTipoEstacionamientoAuto;
}

export const selectFiltroTipoEstacionamientoMoto = (state) => {
  return state.proyectos.filtroTipoEstacionamientoMoto;
}

export const selectFiltroEstacionamientosPrecioMin = (state) => {
  return state.proyectos.filtroEstacionamientosPrecioMin;
}

export const selectFiltroEstacionamientosPrecioMax = (state) => {
  return state.proyectos.filtroEstacionamientosPrecioMax;
}

export const selectFiltroEstacionamientosCantidad = (state) => {
  return state.proyectos.filtroEstacionamientosCantidad;
}

export const selectFiltroBodegasPrecioMin = (state) => {
  return state.proyectos.filtroBodegasPrecioMin;
}

export const selectFiltroBodegasPrecioMax = (state) => {
  return state.proyectos.filtroBodegasPrecioMax;
}

export const selectFiltroBodegasCantidad = (state) => {
  return state.proyectos.filtroBodegasCantidad;
}

export const selectAllFiltros = (state) => {
  return {
    ubicaciones: state.proyectos.filtroUbicaciones,
    dormitorios: state.proyectos.filtroDormitorios,
    banos: state.proyectos.filtroBanos,
    bodegas: state.proyectos.filtroBodegas,
    precioMin: state.proyectos.filtroPrecioMin,
    precioMax: state.proyectos.filtroPrecioMax,
    superficieMin: state.proyectos.filtroSuperficieMin,
    superficieMax: state.proyectos.filtroSuperficieMax,
    amoblado: state.proyectos.filtroAmoblado,
    edificios: state.proyectos.filtroEdificios,
    mascota: state.proyectos.filtroMascota,
    entregaInmediata: state.proyectos.filtroEntregaInmediata,
    ofertas: state.proyectos.filtroOfertas,
    estrenar: state.proyectos.filtroEstrenar,
    estacionamientosCantidad: state.proyectos.filtroEstacionamientosCantidad,
    bodegasCantidad: state.proyectos.filtroBodegasCantidad,
    estacionamientos: state.proyectos.filtroEstacionamientos,
  };
}

export const selectBarrios = state => state.proyectos.barrios;

export const selectBarrioByDireccion = (direccion) => {

  return (state) => {
    const barrios = state.proyectos.barrios;
    const filteredBarrio = barrios.filter((barrio) => barrio.direccion === direccion);

    if (filteredBarrio.length === 1) {
      return filteredBarrio[0];
    }

    return null;
  }
}

export const selectFiltroBarrios = state => state.proyectos.filtroBarrios;

export const selectGetBarrioDataStatus = state => state.proyectos.getBarrioDataStatus;

export const selectAllResultFiltros = (state) => {
  return {
    filtroDormitorios: state.proyectos.filtroDormitorios,
    filtroBanos: state.proyectos.filtroBanos,
    filtroRegiones: state.proyectos.filtroRegiones,
    filtroComunas: state.proyectos.filtroComunas,
    filtroUbicaciones: state.proyectos.filtroUbicaciones,
    filtroSuperficieMin: state.proyectos.filtroSuperficieMin,
    filtroSuperficieMax: state.proyectos.filtroSuperficieMax,
    filtroAmoblado: state.proyectos.filtroAmoblado,
    filtroPrecioMin: state.proyectos.filtroPrecioMin,
    filtroPrecioMax: state.proyectos.filtroPrecioMax,
    filtroBodegas: state.proyectos.filtroBodegas,
    filtroEstacionamientos: state.proyectos.filtroEstacionamientos,
    filtroEdificios: state.proyectos.filtroEdificios,
    filtroMascota: state.proyectos.filtroMascota,
    filtroEntregaInmediata: state.proyectos.filtroEntregaInmediata,
    filtroOfertas: state.proyectos.filtroOfertas,
    filtroEstrenar: state.proyectos.filtroEstrenar,
    filtroOrdenar: state.proyectos.filtroOrdenar,
  }
}


// Devuelve un array de comunas ("ubicaciones") sin repetir, se obtinen de la cotizaciones
export const selectUniqueComunasCotizaciones = (state) => {
  const comunasRepeated = state.proyectos.cotizaciones.map((proy) => proy.proyecto.comuna);
  const comunasUnique = [...new Set(comunasRepeated)];
  return comunasUnique;
}

// Devuelve un array de comunas ("ubicaciones") sin repetir, teniendo en cuenta todos los proyectos
export const selectUniqueComunas = (state) => {
  const comunasRepeated = state.proyectos.proyectos.map((proy) => proy.comuna);
  const comunasUnique = [...new Set(comunasRepeated)];
  return comunasUnique;
}

// Devuelve un array de regiones sin repetir, teniendo en cuenta todos los proyectos
export const selectUniqueRegiones = (state) => {
  const regionesRepeated = state.proyectos.proyectos.map((proy) => proy.region);
  const regionesUnique = [...new Set(regionesRepeated)];
  return regionesUnique;
}

// Devuelve un array de los nombres de proyectos, ordenados alfabéticamente se obtiene de la cotizaciones
export const selectUniqueNombresProyectosCotizaciones = (state) => {
  let uniqueNombres = state.proyectos.cotizaciones.map((proy) => proy.proyecto.proyecto_nombre);
  uniqueNombres = [...new Set(uniqueNombres)];

  return uniqueNombres.sort();
}

// Devuelve un array de los nombres de proyectos, ordenados alfabéticamente
export const selectUniqueNombresProyectos = (state) => {
  const uniqueNombres = state.proyectos.proyectos.map((proy) => proy.proyecto_nombre);
  return uniqueNombres.sort();
}

// Devuelve un array de las direcciones de proyectos, ordenadas alfabéticamente
export const selectUniqueDireccionesProyectos = (state) => {
  const uniqueDirecciones = state.proyectos.proyectos.map((proy) => proy.direccion);
  return uniqueDirecciones.sort();
}

export const selectComentarios = (state) => state.proyectos.comentarios;

export const selectHorarios = (state) => state.proyectos.horarios;

export const selectEjecutivosArriendos = (state) => state.proyectos.ejecutivosArriendos;

export const selectSecundariosEstacionamientoAutoApi = async (proyectoNombre, estacionamiento = false, piso = 0) => {

  const urlPrimarios = `${BACKEND_BASE_URL}/level-api/obtenersecundarios`
  let dataBody = {
    PROYECTONOMBRE: proyectoNombre,
    NOMBREESTADO: "DISPONIBLE"
  }

  if (estacionamiento) {
    dataBody = Object.assign(dataBody, { NIVELNUMERO: piso })
  }
  const resultData = await axios.post(urlPrimarios, dataBody);
  return resultData.data;
}

export const selectSecundariosEstacionamientoAuto = (proyectoId) => {
  return (state) => {
    if (!proyectoId) {
      return null;
    }
    const proyecto = state.proyectos.proyectos.filter((proy) => proy.id === proyectoId)[0];
    if (!proyecto) {
      return null;
    }

    return proyecto.secundarios.filter((secundario) => {
      // Solamente los secundarios con nombre 'ESTACIONAMIENTO CUBIERTO' cuentan como "estacionamiento de auto"
      return secundario.nombre === 'ESTACIONAMIENTO CUBIERTO';
    });
  }
}

export const selectSecundariosEstacionamientoMoto = (proyectoId) => {
  return (state) => {
    if (!proyectoId) {
      return null;
    }

    const proyecto = state.proyectos.proyectos.filter((proy) => proy.id === proyectoId)[0];

    if (!proyecto) {
      return null;
    }

    return proyecto.secundarios.filter((secundario) => {
      // Solamente los secundarios con nombre 'MOTO ESTACIONAMIENTO' y 'ESTACIONAMIENTO MOTOS' cuentan
      // como "estacionamiento de moto"
      return (secundario.nombre === 'MOTO ESTACIONAMIENTO') || (secundario.nombre === 'ESTACIONAMIENTO MOTOS');
    });
  }
}

export const selectSecundariosBodegas = (proyectoId) => {
  return (state) => {
    if (!proyectoId) {
      return null;
    }

    const proyecto = state.proyectos.proyectos.filter((proy) => proy.id === proyectoId)[0];

    if (!proyecto) {
      return null;
    }

    return proyecto.secundarios.filter((secundario) => secundario.nombre === 'BODEGA');
  }
}

export const selectTipologia = (proyectoId, tipologiaTitulo) => {
  return (state) => {
    if (!proyectoId) {
      return null;
    }

    const proyecto = state.proyectos.proyectos.filter((proy) => proy.id === proyectoId)[0];

    if (!proyecto) {
      return null;
    }

    const tipologias = proyecto.tipologias.filter((tipo) => tipo.titulo === tipologiaTitulo);

    if (tipologias.length > 0) {
      return tipologias[0];
    }

    return null;
  }
}

// Devuelve los proyectos similares al proyecto con id `proyectoId`. Un proyecto es considerado similar al
// proyecto con proyectoId cuando pasa los filtros actuales de búsqueda.
export const selectProyectosSimilares = (proyectoId) => {
  return (state) => {
    if (!proyectoId) {
      return [];
    }

    // veridicamos y filtramos las propiedades que tienen dpt disponibles.
    const proyectosSinDisponibilidad = state.proyectos.proyectos.filter((proyecto) => {
      const disponibilidad = proyecto.tipologias.find((tipologia) => tipologia.precio_min_amoblado !== -1);
      if (disponibilidad) {
        return proyecto;
      }
    })


    const proyectosSimilares = proyectosSinDisponibilidad.filter((proy) => (proy.id !== proyectoId) && checkAllFiltros(state, proy));
    return proyectosSimilares;
  }
}

export const selectDeptosDisponibles = (state) => {
  return state.proyectos.deptosDisponibles;
}

export const selectEstacionamientosDisponibles = (state) => {
  return state.proyectos.estacionamientosDisponibles;
}

export const selectBodegasDisponibles = (state) => {
  return state.proyectos.bodegasDisponibles;
}



// Thunk para enviar formulario de contcato
export const crearCargosContratos = createAsyncThunk('contrato/crearCargosContrato', async (formData) => {

  try {
    const response = await axios.post(`${BACKEND_BASE_URL}/level-api/crearCargosContrato`, formData);
    const { jsonObject } = response.data;
    return jsonObject;
  } catch (error) {
    const errorMsg = error.response.data.message || error.response.data;
    throw new Error(errorMsg);
  }
});

export const crearContratos = createAsyncThunk('contrato/crearContrato', async (reservaId) => {

  try {
    const response = await axios.post(`${BACKEND_BASE_URL}/level-api/crearContrato`, { ReservaId: reservaId });
    const { jsonObject } = response.data;
    return jsonObject;
  } catch (error) {
    const errorMsg = error.response.data.message || error.response.data;
    throw new Error(errorMsg);
  }
});

export const obtenerContratoReserva = createAsyncThunk('contrato/obtenerContratoReserva', async (reservaId) => {

  try {
    const response = await axios.post(`${BACKEND_BASE_URL}/level-api/obtenerContratoReserva`, { ReservaId: reservaId });
    const { jsonObject } = response.data;
    return jsonObject;
  } catch (error) {
    const errorMsg = error.response.data.message || error.response.data;
    throw new Error(errorMsg);
  }
});

export const {
  setPlaces,
  setSpinner,
  setFiltroDormitorios,
  setFiltroBanos,
  setFiltroRegiones,
  setFiltroComunas,
  setFiltroUbicaciones,
  setFiltroSuperficieMin,
  setFiltroSuperficieMax,
  setFiltroAmoblado,
  setFiltroPrecioMin,
  setFiltroPrecioMax,
  setFiltroBodegas,
  setFiltroEstacionamientos,
  setFiltroEdificios,
  setFiltroMascota,
  setFiltroEntregaInmediata,
  setFiltroOfertas,
  setFiltroEstrenar,
  setFiltroOrdenar,
  setFiltroTipoEstacionamientoAuto,
  setFiltroTipoEstacionamientoMoto,
  setFiltroEstacionamientosPrecioMin,
  setFiltroEstacionamientosPrecioMax,
  setFiltroEstacionamientosCantidad,
  setFiltroBodegasPrecioMin,
  setFiltroBodegasPrecioMax,
  setFiltroBodegasCantidad,
  setFiltroBarrios,
  setCotizaciones,
  resetFiltros,
  setAllResultFiltros,
} = proyectosSlice.actions;
