
import { useSelector } from 'react-redux';
import { selectTipologiaTitulo } from '../redux/preevaluacionSlice';


// Hook para obtener el depto más barato de la tipología actual seleccionada en /place.
// Devuelve null si no se lo pudo encontrar.
const useCurrentTipologiaCheapestDepto = (proyecto) => {

  const tipologiaTitulo = useSelector(selectTipologiaTitulo);
  const deptosInCurrentProyect = useSelector((state) => state.proyectos.currentProyect.departamentos)
  const cotizaciones = useSelector((state) => state.proyectos.cotizaciones)
  
  const departamentos = deptosInCurrentProyect || proyecto?.departamentos || [];
  const tipologia = tipologiaTitulo ? tipologiaTitulo : cotizaciones?.find(({ proyecto: _proyecto }) => _proyecto.proyecto_nombre === proyecto.proyecto_nombre)?.tipologia;

  // Filtrar deptos para quedarnos los que pertenecen al proyecto y tipologías actuales
  const filteredDeptos = departamentos.filter((depto) => {
    return depto.proyecto_id === proyecto.level_id && depto.tipologia.titulo === tipologia;
  });

  let minPrecioNoAmoblado = Number.MAX_SAFE_INTEGER;
  let cheapestDeptoIndex = -1;

  for (let i = 0; i < filteredDeptos.length; i++) {
    const currDepto = filteredDeptos[i];
    if (currDepto.precio_no_amoblado < minPrecioNoAmoblado) {
      minPrecioNoAmoblado = currDepto.precio_no_amoblado;
      cheapestDeptoIndex = i;
    }
  }

  return filteredDeptos[cheapestDeptoIndex];
}

export default useCurrentTipologiaCheapestDepto;
