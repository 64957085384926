import React, { useEffect, useState } from 'react';
import styles from './styles/SearchInput.module.scss';
import { Icon as Iconify } from '@iconify/react';
import lupaIcon from '@iconify/icons-mdi/magnify';
import { Icon, Input, Modal } from './ui/index';
import { BREAKPOINT_MOBILE } from '../config/constant';
import useResize from '../hooks/useResize';


// Input styleado con una lupita. El estado del componente es controlado a través de los props
// `text` y `updateText`. Los props `width` y `margin` controlan el ancho y margen.
// El prop `updateCurrentFaq` es un callback opcional que se ejecuta con el FAQ clickeado en este
// componente.
const SearchInput = ({ text, updateText, width = '100%', margin = '0', updateCurrentFaq, preguntasFrecuentesData }) => {

  const [rizeWidth] = useResize();
  const [showLupa, setShowLupa] = useState(true);
  const [showContentModal, setShowContentModal] = useState(false);
  const [preguntasFrecuentesOptions, setPreguntasFrecuentesOptions] = useState(null);

  const onChange = (e) => {
    if (e.target.value === '') {
      setShowLupa(true);
    } else {
      setShowLupa(false);
    }
    updateText(e.target.value);
  }

  // Armar opciones de preguntas frecuentes
  useEffect(() => {
    const updateCurrentFaqAndClose = (faq) => {
      updateCurrentFaq(faq);
      setShowContentModal(false);
    }

    if (preguntasFrecuentesData.faqs.length > 0) {
      const options = preguntasFrecuentesData.faqs.map((faq) => {
        return (
          <h1 key={faq.id} onClick={() => updateCurrentFaqAndClose(faq)}><Icon name="icon-question" /> {faq.name}</h1>
        );
      });

      setPreguntasFrecuentesOptions(options);
    }
  }, [preguntasFrecuentesData.faqs, updateCurrentFaq]);


  return (
    <div style={{ position: 'relative', width: width }}>
      {showLupa && <Iconify icon={lupaIcon} width="20px" color='black' className={styles.lupa} />}
      {
        rizeWidth <= BREAKPOINT_MOBILE &&

        <div className={styles.adjustIconContainer}>
          <Icon onClick={() => setShowContentModal(true)} name="icon-adjust" />
        </div>
      }
      <Input
        value={text}
        inputStyle={{ textIndent: '35px' }}
        onChange={onChange}
        formGroupStyle={{ margin: margin, width: '100%' }}
        placeholder="Busca ayuda..."
      />
      {
        updateCurrentFaq && showContentModal &&

        <Modal show={showContentModal} close={() => setShowContentModal(false)}>
          <div className="modal-content modal-generico extra-small fade">
            <div className="header-modal">
              <Icon onClick={() => setShowContentModal(false)} name="icon-close" size="14" />
                Índice de preguntas frecuentes
            </div>
            <div className="body-modal">
              <div className={`modal-body ${styles.modalCustomBody}`}>
                {preguntasFrecuentesOptions}
              </div>
            </div>
            <div className="header-modal" onClick={() => setShowContentModal(false)}>
              <p className={styles.modalFooterText}>CERRAR</p>
            </div>
          </div>
        </Modal>
      }
    </div>
  );
}

export default SearchInput;
