import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  INITIAL_BANOS,
  INITIAL_BODEGAS,
  INITIAL_BODEGAS_CANTIDAD,
  INITIAL_DORMITORIOS,
  INITIAL_ESTACIONAMIENTOS_CANTIDAD,
  MAX_BANOS,
  MAX_BODEGAS,
  MAX_BODEGAS_CANTIDAD,
  MAX_ESTACIONAMIENTOS_CANTIDAD,
  MAX_DORMITORIOS,
  setFiltroBanos,
  setFiltroBodegas,
  setFiltroBodegasCantidad,
  setFiltroDormitorios,
  setFiltroEstacionamientosCantidad,
} from '../../../redux/proyectosSlice';
import InputButton from '../InputButton';
import './InputFiltro.scss';
import {
  INITIAL_PREEV_ESTACIONAMIENTOS_AUTO,
  INITIAL_PREEV_ESTACIONAMIENTOS_MOTO,
  MAX_PREEV_ESTACIONAMIENTOS_AUTO,
  MAX_PREEV_ESTACIONAMIENTOS_MOTO,
  setEstacionamientosAuto,
  setEstacionamientosMoto
} from '../../../redux/preevaluacionSlice';


// Input button con un contador (circulitos de - y +) que actualiza un filtro de Redux.
// Si se pasa un valor distinto de null a controlledValue, el componente se vuelve controlado y delega
// la responsabilidad del estado en el parent, tomando el valor actual del prop controlledValue y ejecutando
// los callbacks onIncrease y onDecrease cuando se tocan los circulitos + y -, respectivamente.
const InputFiltro = ({
  cantidadDisponible,
  type,
  initialValue,
  label,
  labelColor,
  iconMenosColors,
  iconAddColors,
  countColor,
  controlledValue = null,
  onIncrease = null,
  onDecrease = null,
  name = null,
  flex = true,
  between = true,
  margin = true,
  marginBottom = "0",
}) => {

  const dispatch = useDispatch();
  const inputRefCantidad = useRef();
  const [cantidad, setCantidad] = useState(initialValue);

  // Función de utilería. Toma un tipo y una cantidad y actualiza el filtro correspondiente de Redux.
  const updateReduxFilter = (type, newValue) => {
    switch (type) {
      case 'dormitorio':
        dispatch(setFiltroDormitorios(newValue));
        break;

      case 'bano':
        dispatch(setFiltroBanos(newValue));
        break;

      case 'bodega':
        dispatch(setFiltroBodegas(newValue));
        break;

      case 'estacionamientoAuto':
        dispatch(setEstacionamientosAuto(newValue));
        break;

      case 'estacionamientoMoto':
        dispatch(setEstacionamientosMoto(newValue));
        break;

      case 'filtroEstacionamientosCantidad':
        dispatch(setFiltroEstacionamientosCantidad(newValue));
        break;

      case 'filtroBodegasCantidad':
        dispatch(setFiltroBodegasCantidad(newValue));
        break;

      default:
        console.log(`InputFiltro: Tipo de filtro "${type}" desconocido!`);
        break;
    }
  }

  // Chequea si el valor pasado es mayor o igual al valor por defecto del filtro de Redux correspondiente.
  const isGteToInitialFilter = (type, value) => {
    switch (type) {
      case 'dormitorio':
        return value >= INITIAL_DORMITORIOS;

      case 'bano':
        return value >= INITIAL_BANOS;

      case 'bodega':
        return value >= INITIAL_BODEGAS;

      case 'estacionamientoAuto':
        return value >= INITIAL_PREEV_ESTACIONAMIENTOS_AUTO;

      case 'estacionamientoMoto':
        return value >= INITIAL_PREEV_ESTACIONAMIENTOS_MOTO;

      case 'filtroEstacionamientosCantidad':
        return value >= INITIAL_ESTACIONAMIENTOS_CANTIDAD;

      case 'filtroBodegasCantidad':
        return value >= INITIAL_BODEGAS_CANTIDAD;

      default:
        console.log(`InputFiltro: Tipo de filtro "${type}" desconocido!`);
        break;
    }
  }

  // Chequea si el valor pasado es menor o igual al máximo valor permitido del filtro de Redux correspondiente.
  const isLtToMaxFilter = (type, value) => {
    switch (type) {
      case 'dormitorio':
        return value <= MAX_DORMITORIOS;

      case 'bano':
        return value <= MAX_BANOS;

      case 'bodega':
        return value <= MAX_BODEGAS;

      case 'estacionamientoAuto':
        return value <= MAX_PREEV_ESTACIONAMIENTOS_AUTO;

      case 'estacionamientoMoto':
        return value <= MAX_PREEV_ESTACIONAMIENTOS_MOTO;

      case 'filtroEstacionamientosCantidad':
        return value <= MAX_ESTACIONAMIENTOS_CANTIDAD;

      case 'filtroBodegasCantidad':
        return value <= MAX_BODEGAS_CANTIDAD;

      default:
        console.log(`InputFiltro: Tipo de filtro "${type}" desconocido!`);
        break;
    }
  }

  const eventAumCantidad = () => {
    if (controlledValue === null) {
      const newCantidad = cantidad + 1;
      if (isLtToMaxFilter(type, newCantidad)) {
        setCantidad(newCantidad);
        updateReduxFilter(type, newCantidad);
      }
    } else {
      onIncrease();
    }
  }

  const eventDisCantidad = () => {
    if (controlledValue === null) {
      const newCantidad = cantidad - 1;
      if (isGteToInitialFilter(type, newCantidad)) {
        setCantidad(newCantidad);
        updateReduxFilter(type, newCantidad);
      }
    } else {
      onDecrease();
    }
  }

  const cantidadCustomCount = {
    inputRef: inputRefCantidad,
    cantidad: controlledValue !== null ? controlledValue : cantidad,
    eventAum: eventAumCantidad,
    eventDis: eventDisCantidad,
  };

  return (
    <InputButton cantidadDisponible={cantidadDisponible} customCount={cantidadCustomCount}
      name={name} type="text" flex={flex} between={between} margin={margin} marginBottom={marginBottom}
      iconMenosColors={iconMenosColors} iconAddColors={iconAddColors} countColor={countColor}>
      <span style={{ color: labelColor ? labelColor : '' }}>{label}</span>
      {
        typeof cantidadDisponible !== 'undefined' ?
          cantidadDisponible > 0 ?
            <span style={{ display: 'block', color: '#64B36C', textAlign: 'left' }}>Disponibles</span>
            :
            <span style={{ display: 'block', color: '#E25141', textAlign: 'left' }}>No disponibles</span>
          :
          <span></span>
      }
    </InputButton>
  );
}

InputFiltro.propTypes = {
  type: PropTypes.oneOf([
    'dormitorio',
    'bano',
    'bodega',
    'estacionamientoAuto',
    'estacionamientoMoto',
    'filtroEstacionamientosCantidad',
    'filtroBodegasCantidad',
  ]),
  initialValue: PropTypes.number,
  label: PropTypes.string,
  name: PropTypes.string,
  flex: PropTypes.bool,
  between: PropTypes.bool,
  margin: PropTypes.bool,
  marginBottom: PropTypes.string,
};

export default InputFiltro;
