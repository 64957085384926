import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Carousel from 'react-elastic-carousel';
import { TextInfo, Button, Title, ToogleSwich } from '../ui/index';
import SocialLinks from './SocialLinks';
import CardAlerta from './CardAlerta';
import CardTipologia from './CardTipologia';
import ModalGaleriaPlace from './ModalGaleriaPlace';
import {
  BACKEND_BASE_URL,
  FEATURE_ENABLED,
  TOUR_360_STR,
} from '../../config/constant';
import {
  INITIAL_BODEGAS,
  setFiltroAmoblado
} from '../../redux/proyectosSlice';
import notFound from '../../static/images/notFound.png';
import './styles/FichaUnidadMobile.scss';
import InputFiltro from '../ui/InputFiltro/InputFiltro';
import { selectLoggedIn } from '../../redux/userSlice';
import ModalRegister from '../ModalRegister';
import ModalLogin from '../ModalLogin';
import {
  INITIAL_PREEV_ESTACIONAMIENTOS_AUTO,
  selectBodegas,
  selectEstacionamientosAuto,
  setBodegas,
  setEstacionamientosAuto
} from '../../redux/preevaluacionSlice';
import { getProyectoTourUrl } from '../../utils';
import useHideCliengoButtons from '../../hooks/useHideCliengoButtons';


const INITIAL_IMAGE_INDEX = 0;
const DETALLES_ENABLED = false;


const FichaUnidadMobile = ({
  proyecto,
  tipologia,
  title,
  show,
  close,
  openShared,
  cotizar,
  available = true }) => {

  let totalImage = 1;
  const dispatch = useDispatch();
  const [currentImage, setCurrentImage] = useState(INITIAL_IMAGE_INDEX);
  const [showGaleria, setGaleria] = useState('');
  const [defaulItemGaleria, setItemGaleria] = useState(0);
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const bodegas = useSelector(selectBodegas); // bodegas de preevaluación
  const showAmobladoSwitch = tipologia ? tipologia.amoblado : false;

  // Ocultar botones de Cliengo en mobile
  useHideCliengoButtons(show);

  const onBodegaDecrease = () => {
    const newBodegas = bodegas - 1;
    if (newBodegas >= INITIAL_BODEGAS) {
      dispatch(setBodegas(newBodegas));
    }
  }

  const onBodegaIncrease = () => {
    const newBodegas = bodegas + 1;
    dispatch(setBodegas(newBodegas));
  }

  const estacionamientosAuto = useSelector(selectEstacionamientosAuto); // est. de preevaluación

  const onEstacionamientosAutoDecrease = () => {
    const newEstacionamientosAuto = estacionamientosAuto - 1;
    if (newEstacionamientosAuto >= INITIAL_PREEV_ESTACIONAMIENTOS_AUTO) {
      dispatch(setEstacionamientosAuto(newEstacionamientosAuto));
    }
  }

  const onEstacionamientosAutoIncrease = () => {
    const newEstacionamientosAuto = estacionamientosAuto + 1;
    dispatch(setEstacionamientosAuto(newEstacionamientosAuto));
  }

  const openGaleria = (item) => {
    setGaleria('show');
    setItemGaleria(item);
  }

  const closeGaleria = () => {
    setGaleria('');
    setItemGaleria(0);
  }

  const onAmobladoSwitch = (event) => {
    dispatch(setFiltroAmoblado(event.target.checked));
  }

  let itemsSlider = [
    <div key={notFound} className="item-slider" onClick={() => openGaleria(0)}>
      <img src={notFound} alt="not-found" style={{ width: '208px', height: '156px' }} />
    </div>
  ];

  let tituloStr = '';
  let dormitoriosStr = '';
  let banosStr = '';
  let personasStr = '';
  let superficieStr = '';
  let descripcionStr = '';

  // Armar galería y strings si nos pasaron tipología
  if (tipologia) {
    tituloStr = title;

    dormitoriosStr = `${tipologia.dormitorios} dormitorio${tipologia.dormitorios === 0 || tipologia.dormitorios > 1 ?
      's' : ''}`;

    if (tipologia.studio) {
      dormitoriosStr += ' (Estudio)';
    }

    banosStr = `${tipologia.banos} baño${tipologia.banos === 0 || tipologia.banos > 1 ?
      's' : ''}`;

    personasStr = `${tipologia.personas} persona${tipologia.personas === 0 || tipologia.personas > 1 ?
      's' : ''}`;

    superficieStr = `Superficie ${tipologia.superficie} m`;

    descripcionStr = tipologia.descripcion;

    if (tipologia.galeria.length > 0) {
      totalImage = tipologia.galeria.length;

      itemsSlider = tipologia.galeria.map((galeriaItem, index) => {
        const thumbnailUrl = `${BACKEND_BASE_URL}${galeriaItem.formats.thumbnail.url}`;
        const alt = galeriaItem.alternativeText;

        return (
          <div key={galeriaItem.id} className="item-slider" onClick={() => openGaleria(index)}>
            <img src={thumbnailUrl} alt={alt} />
          </div>
        );
      });
    }
  }

  const loggedIn = useSelector(selectLoggedIn);

  const onCotizarClick = () => {
    if (!loggedIn) {
      setShowLogin(true);
      return;
    }

    close();
    cotizar();
  }

  const openLogin = () => {
    setShowLogin(true);
  }

  const closeLogin = () => {
    setShowLogin(false);
  }

  const openRegister = () => {
    setShowRegister(true);
  }

  const closeRegister = () => {
    setShowRegister(false);
  }


  return (
    <>
      <div className={`modal-search-mobile ${show}`}>
        <div className="header--search">
          <div className="header-step flex show">
            <TextInfo onClick={close} iconName="icon-close" color="white" textWeight="bold"></TextInfo>
            <SocialLinks openShared={openShared} />
          </div>
        </div>
        <div className="search-body __filtro-unidad" style={{ marginTop: '30px' }}>
          <div className="__slider-imagen-unidad">
            <div className={`placeItems`}>
              <div className="slider-image-map">
                {!available && <div className="avaliable"><span>No disponible</span></div>}
                {
                  available &&

                  <>
                    {FEATURE_ENABLED.TOUR_360 && <span className="info-360">360<sup>o</sup></span>}
                    <span className="countImage">{currentImage + 1} / {totalImage}</span>
                  </>
                }
                <Carousel
                  itemsToScroll={1}
                  itemsToShow={1}
                  enableAutoPlay={false}
                  showArrows={false}
                  autoPlaySpeed={5000}
                  onNextStart={(currentItem, nextItem) => {
                    if (currentImage + 1 < totalImage) {
                      setCurrentImage(currentImage + 1);
                    }
                  }}
                  onPrevStart={(currentItem, nextItem) => {
                    if (currentImage > 0) {
                      setCurrentImage(currentImage - 1);
                    }
                  }}

                  className="slider-galery-image-place">
                  {itemsSlider}
                </Carousel>
              </div>
            </div>
          </div>
          <div className="body-step show" style={{ marginTop: '0' }} >
            <div className="container">
              <div className="row">
                <div className={`_title-tipologia ${!available && 'disabled'}`}>
                  <Title size="18">{tituloStr}</Title>
                  <div className="__info-unidad">
                    <div className="_item-info-unidad">
                      <TextInfo iconName="icon-bed" iconSize="18">{dormitoriosStr}</TextInfo>
                    </div>
                    <div className="_item-info-unidad">
                      <TextInfo iconName="icon-bath" iconSize="18">{banosStr}</TextInfo>
                    </div>
                    <div className="_item-info-unidad">
                      <TextInfo iconName="icon-user" iconSize="18">{personasStr}</TextInfo>
                    </div>
                    <div className="_item-info-unidad">
                      <TextInfo iconName="icon-full-screen" iconSize="18">{superficieStr}<sup>2</sup></TextInfo>
                    </div>
                  </div>
                </div>
                {
                  FEATURE_ENABLED.TOUR_360 && available &&
                  <a href={getProyectoTourUrl(proyecto)} target="_blank" rel="noopener noreferrer">
                    <Button
                      color='btn-outline-orange'
                      fullWidth={true}
                      size="extra-small"
                      style={{
                        marginBottom: '25px',
                      }}
                    >
                      {TOUR_360_STR}
                    </Button>
                  </a>
                }
                {
                  FEATURE_ENABLED.PROMO_ALERTS && available &&
                  <CardAlerta text="<strong>¡Solo por febrero!</strong> Esta unidad permite pagar la garantía y la comisión en 4 cuotas." />
                }
                {
                  FEATURE_ENABLED.PROMO_ALERTS && !available &&
                  <CardAlerta text="<strong>¡Departamento no disponible!</strong> No te preocupes, puedes crear una alerta y te avisaremos cuando esté próximo a liberarse." typeAlert="error" />
                }
                {available && (
                  <>
                    <InputFiltro
                      cantidadDisponible={proyecto ? proyecto.cantidadBodegas : 0}
                      type="bodega"
                      controlledValue={bodegas}
                      onIncrease={onBodegaIncrease}
                      onDecrease={onBodegaDecrease}
                      label="Incluir Bodega"
                      name="bodega"
                      flex={true}
                      between={true}
                      margin={false}
                      marginBottom="25"
                    />

                    <InputFiltro
                      cantidadDisponible={proyecto ? proyecto.cantidadEstacionamientosCubiertos : 0}
                      type="estacionamientoAuto"
                      controlledValue={estacionamientosAuto}
                      onIncrease={onEstacionamientosAutoIncrease}
                      onDecrease={onEstacionamientosAutoDecrease}
                      label="Incluir Estacionamiento"
                      name="estacionamiento-auto"
                      flex={true}
                      between={true}
                      margin={false}
                      marginBottom="25"
                    />

                    {showAmobladoSwitch && <ToogleSwich onChange={onAmobladoSwitch} position="left">¿Amoblado?</ToogleSwich>}

                    <div className="__info-detalles">
                      {
                        DETALLES_ENABLED &&

                        <>
                          <h3 className="title">DETALLES</h3>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                        </>
                      }
                      <h3 className="title">DESCRIPCIÓN</h3>
                      <p style={{ whiteSpace: "pre-line" }}>{descripcionStr}</p>
                    </div>
                  </>)
                }

              </div>
            </div>
            {!available &&
              (<div className="__place-similares-unidad">
                <div className="container">
                  <div className="row">
                    <Title className="muli text-center" size="20" marginBotton={30}>Resultados similares</Title>
                    <CardTipologia openFicha={() => { }} alert={false} available={true} result={true} />
                    <CardTipologia openFicha={() => { }} alert={false} available={true} result={true} />
                  </div>
                </div>
              </div>)
            }

          </div>
        </div>

        {
          FEATURE_ENABLED.COTIZAR &&

          <div className="search-footer">
            <div className="footer-step show">
              {available ?
                (<Button onClick={onCotizarClick} color='btn-orange' fullWidth={true} size="extra-small">COTIZAR UNIDAD</Button>)
                :
                (<Button color='btn-orange' fullWidth={true} size="extra-small">CREAR ALERTA</Button>)
              }
            </div>
          </div>
        }
      </div>

      <ModalGaleriaPlace
        proyecto={proyecto}
        tipologia={tipologia}
        show={showGaleria}
        close={closeGaleria}
        defaultItem={defaulItemGaleria}
      />

      <ModalRegister
        show={showRegister}
        close={closeRegister}
        openLogin={openLogin}
      />

      <ModalLogin
        show={showLogin}
        close={closeLogin}
        openRegister={openRegister}
      />
    </>
  );
};

export default FichaUnidadMobile;
