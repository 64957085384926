import React, { useState } from 'react';
import { BREAKPOINT_MOBILE } from '../config/constant';
import useResize from '../hooks/useResize';
import BarriosCheckboxes from './BarriosCheckboxes';
import ModalBarrios from './ModalBarrios';
import { ButtonDropdown, Dropdown } from './ui/index';


const BarrioDropdown = ({
  width = '100%',
  top = '0',
  left = '0',
  center = false,
}) => {

  const [rizeWidth] = useResize();
  const [showBarriosModal, setShowBarriosModal] = useState(false);

  const onMobileBarrioDropdownClick = () => {
    setShowBarriosModal(!showBarriosModal);
  }

  const hideDropdown = () => {
    const dropdown = document.getElementById('barrios-button-dropdown');
    dropdown.click();
  }

  return (
    <div style={{
      position: 'absolute',
      top,
      left: center ? '0' : left,
      right: center ? '0' : '',
      textAlign: center ? 'center' : '',
      width,
      marginLeft: center ? 'auto' : '',
      marginRight: center ? 'auto' : '',
    }}>
      {
        rizeWidth <= BREAKPOINT_MOBILE &&

        <ButtonDropdown id="barrios-button-dropdown" onClick={onMobileBarrioDropdownClick} color="btn-white-gray" placeholder="Selecciona un barrio" fullWidth={true}>
          <Dropdown title="Barrios">
            <BarriosCheckboxes />
          </Dropdown>
        </ButtonDropdown>
      }
      {
        showBarriosModal &&

        <ModalBarrios
          show={showBarriosModal}
          close={() => setShowBarriosModal(false)}
        />
      }
      {
        rizeWidth > BREAKPOINT_MOBILE &&

        <ButtonDropdown id="barrios-button-dropdown" color="btn-white-gray" placeholder="Selecciona un barrio" fullWidth={true}>
          <Dropdown title="Barrios">
            <BarriosCheckboxes hideDropdown={hideDropdown} />
          </Dropdown>
        </ButtonDropdown>
      }
    </div>
  );
}

export default BarrioDropdown;
