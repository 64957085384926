import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import ModalVisita from '../components/ModalVisita.js';
import Filtro from '../components/containers/Filtro';
import CardMap from '../components/CardMap';
import { dataLayerPush } from '../utils';
import { useLocation, useParams } from 'react-router-dom';
import Entrada, { TIPO_ENTRADA } from '../components/Cotizar/layers/Entrada';
import { FILTER_TYPE } from '../config/constant';


const ResultMap = () => {

  const { tipo } = useParams();
  const [showEntrada, setShowEntrada] = useState(false);
  const [entradaProyecto, setEntradaProyecto] = useState(null);
  const [showVisita, setShowVisita] = useState(false);

  let filterType;
  let tipoEntrada;

  switch (tipo) {
    case 'estacionamientos':
      filterType = FILTER_TYPE.ESTACIONAMIENTOS;
      tipoEntrada = TIPO_ENTRADA.ESTACIONAMIENTOS;
      break;

    case 'bodegas':
      filterType = FILTER_TYPE.BODEGAS;
      tipoEntrada = TIPO_ENTRADA.BODEGAS;
      break;

    case 'mis-cotizaciones':
      filterType = FILTER_TYPE.MIS_COTIZACIONES;
      break;

    default:
      filterType = FILTER_TYPE.PROYECTOS;
  }


  // Open Modal login
  const openVisita = () => {
    setShowVisita(true);
  }

  // Close Modal Login
  const closeVisita = () => {
    setShowVisita(false);
  }

  // Callback llamado cuando se clickea "VER DETALLES" en una card del mapa.
  const detallesClickCallback = (proyecto) => {
    setEntradaProyecto(proyecto);
    setShowEntrada(true);
  }

  // Analytics
  const location = useLocation();

  useEffect(() => {
    dataLayerPush({
      event: 'vpv',
      page: location.pathname,
      section: 'mapa de resultados de búsqueda',
      url: window.location.href,
    });
  }, [location.pathname]);


  return (
    <Layout>
      <div className="container">
        <div className="content-result">
          <Filtro
            openVisita={openVisita}
            resultMap={true}
            type={filterType}
          />
          <CardMap
            cardType={tipo}
            detallesClickCallback={detallesClickCallback}
          />
        </div>
      </div>

      <ModalVisita
        show={showVisita}
        close={closeVisita}
      />
      {
        (showEntrada && (tipo === 'estacionamientos' || tipo === 'bodegas')) &&

        <Entrada
          proyecto={entradaProyecto}
          close={() => setShowEntrada(false)}
          tipoEntrada={tipoEntrada}
        />
      }
    </Layout>
  );
}

export default ResultMap;
