import React, { useState } from 'react';
import Layout from '../../components/Layout';
import CreamosComunidad from '../../components/QuienesSomos/Comunidad/CreamosComunidad';
import Hero from '../../components/QuienesSomos/Comunidad/Hero';
import styles from '../../components/QuienesSomos/Comunidad/styles/Comunidad.module.scss';
import SliderPlace from '../../components/containers/SliderPlace';
import ModalLogin from '../../components/ModalLogin';
import ModalRegister from '../../components/ModalRegister';
import useResize from '../../hooks/useResize';
import { BREAKPOINT_MOBILE } from '../../config/constant';
import useComunidadData from '../../hooks/useComunidadData';


const QuienesSomosComunidad = () => {

  const [rizeWidth] = useResize();
  const comunidadData = useComunidadData();
  const [showModalLogin, setShowModalLogin] = useState(false);
  const [showModalRegister, setShowModalRegister] = useState(false);

  const openModalLogin = () => {
    setShowModalLogin(true);
  }

  const closeModalLogin = () => {
    setShowModalLogin(false);
  }

  const openModalRegister = () => {
    setShowModalRegister(true);
  }

  const closeModalRegister = () => {
    setShowModalRegister(false);
  }

  return (
    <Layout>
      <div className="container">
        <div className={styles.innerContainer}>
          <Hero comunidadData={comunidadData} />
          <CreamosComunidad showModalLogin={openModalLogin} comunidadData={comunidadData} />
          {
            rizeWidth > BREAKPOINT_MOBILE &&

            <SliderPlace
              imgArray={comunidadData.sliderDesk.map((slide) => {
                return {
                  src: slide.url,
                  alt: slide.alt,
                };
              })}
              containerStyle={{ margin: '0', backgroundColor: 'white', position: 'relative', top: '4px' }}
              optionSliderStyle={{ position: 'absolute', top: '-40px', left: '900px' }}
              buttonText={<span>VER PRÓXIMOS EVENTOS</span>}
              buttonCallback={openModalLogin}
            />
          }
        </div>
      </div>
      <ModalLogin show={showModalLogin} close={closeModalLogin} openRegister={openModalRegister} greeting={false} />
      <ModalRegister show={showModalRegister} close={closeModalRegister} openLogin={openModalLogin} />
    </Layout>
  );
}

export default QuienesSomosComunidad;
