import React from 'react';
import EdificiosDestacados from './EdificiosDestacados';
import useResize from '../../hooks/useResize';
import { BREAKPOINT_MOBILE } from '../../config/constant';
import './styles/destacados.scss';


const Destacados = () => {
  const [rizeWidth] = useResize();

  return (
    <div className="container">
      <section id="destacados" style={{ backgroundColor: '#F0F4FA', paddingBottom: '60px', paddingTop: '60px' }}>
        <div className="row">
          {rizeWidth > BREAKPOINT_MOBILE && <EdificiosDestacados />}
        </div>
      </section>
    </div>
  );
};

export default Destacados;
