import React from 'react';
import { getTipologiaGastoComun } from '../utils';


// Devuelve el gasto común (entiéndase: el mínimo gasto común de los deptos de esta tipología,
// sin tener en cuenta disponibilidad) de una tipología.
const GastoComunTipologia = ({ tipologia }) => {

  let gastoComun = '';
  if (tipologia) {
    gastoComun = getTipologiaGastoComun(tipologia).toLocaleString();
  }

  return (
    <span>${gastoComun}</span>
  );
}

export default GastoComunTipologia;
