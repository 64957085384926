/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import './styles/CardUnidadesMobile.scss';
import CardTipologia from './containers/CardTipologia';
import ModalFiltroMobile from './containers/ModalFiltroMobile';
import FichaUnidadMobile from './containers/FichaUnidadMobile';
import { useDispatch, useSelector } from 'react-redux';
import {
  INITIAL_AMOBLADO,
  INITIAL_BANOS,
  INITIAL_BODEGAS,
  INITIAL_DORMITORIOS,
  INITIAL_ESTACIONAMIENTOS,
  INITIAL_SUPERFICIE_MAX,
  INITIAL_SUPERFICIE_MIN,
  selectFiltroAmoblado,
  selectFiltroBanos,
  selectFiltroBodegas,
  selectFiltroDormitorios,
  selectFiltroEstacionamientos,
  selectFiltroSuperficieMax,
  selectFiltroSuperficieMin,
  selectPlaceFilteredTipologias,
  setFiltroAmoblado,
  setFiltroBanos,
  setFiltroBodegas,
  setFiltroDormitorios,
  setFiltroEstacionamientos,
  setFiltroSuperficieMax,
  setFiltroSuperficieMin
} from '../redux/proyectosSlice';
import { getTipologiaIndex } from '../utils';
import { FILTER_TYPE } from '../config/constant';


const CardUnidadesMobile = ({ proyecto, openShared, cotizar }) => {

  const dispatch = useDispatch();
  const [showFiltro, setShowFiltro] = useState('');
  const [showFichaUnidad, setShowFichaUnidad] = useState('');
  const [availableFicha, setAvailableFicha] = useState(false);
  const [currentTipologia, setCurrentTipologia] = useState(null);
  const [currentTipologiaTitle, setCurrentTipologiaTitle] = useState('');

  const openFiltro = () => {
    setShowFiltro('show');
  }

  const closeFiltro = () => {
    setShowFiltro('');
  }

  const openFicha = (available, tipologiaIndex) => {
    setAvailableFicha(available);
    setShowFichaUnidad('show');

    if (proyecto) {
      setCurrentTipologia(proyecto.tipologias[tipologiaIndex]);

      // 'A' ASCII decimal code is 65, 'B' is 66, and so on
      const letter = String.fromCharCode(65 + tipologiaIndex);
      const newCurrentTipologiaTitle = `Tipología ${letter}`;
      setCurrentTipologiaTitle(newCurrentTipologiaTitle);
    }
  }

  const closeFicha = () => {
    setShowFichaUnidad('');
  }

  let tipologiasCards = null;
  const filteredTipologias = useSelector(selectPlaceFilteredTipologias(proyecto.id));

  if (filteredTipologias) {
    tipologiasCards = filteredTipologias.filter((item) => item.precio_min_amoblado !== -1).map((tipologia, index) => {
      const cantidadDormitorios = tipologia.dormitorios || 1;
      const dormitoriosStr = ` ${cantidadDormitorios} ${cantidadDormitorios > 1 ? "Dormitorios" : "Dormitorio"} + ${tipologia.superficie} m<sup>2</sup>`;
      // 'A' ASCII decimal code is 65, 'B' is 66, and so on
      /*  const letter = String.fromCharCode(65 + index);
       const title = `Tipologías ${letter}`; */
      const title = dormitoriosStr;

      // Notar que este índice es distinto de `index`: `index` es el índice de la tipología actual
      // en el *array de tipologías filtradas*, no en el array de tipologías del proyecto
      const currTipoIndex = getTipologiaIndex(proyecto, tipologia);

      return (
        <CardTipologia
          key={tipologia.id}
          title={title}
          proyecto={proyecto}
          tipologia={tipologia}
          tipologiaIndex={currTipoIndex}
          openFicha={() => openFicha(true, currTipoIndex)}
          alert={false}
          available={true} />
      );
    });
  }

  const onDormitoriosBadgeClick = () => {
    dispatch(setFiltroDormitorios(INITIAL_DORMITORIOS));
  }

  const onBanosBadgeClick = () => {
    dispatch(setFiltroBanos(INITIAL_BANOS));
  }

  const onEstacionamientosBadgeClick = () => {
    dispatch(setFiltroEstacionamientos(INITIAL_ESTACIONAMIENTOS));
  }

  const onBodegasBadgeClick = () => {
    dispatch(setFiltroBodegas(INITIAL_BODEGAS));
  }

  const onAmobladoBadgeClick = () => {
    dispatch(setFiltroAmoblado(INITIAL_AMOBLADO));
  }

  const onSuperficieMinBadgeClick = () => {
    dispatch(setFiltroSuperficieMin(INITIAL_SUPERFICIE_MIN));
  }

  const onSuperficieMaxBadgeClick = () => {
    dispatch(setFiltroSuperficieMax(INITIAL_SUPERFICIE_MAX));
  }

  // Armar badges de filtros
  const filtroDormitorios = useSelector(selectFiltroDormitorios);
  const dormitoriosStr = `${filtroDormitorios} dormitorio${filtroDormitorios === 0 || filtroDormitorios > 1 ? 's' : ''}`;
  const dormitoriosBadge = <a onClick={onDormitoriosBadgeClick} className="tag">{dormitoriosStr}</a>;

  const filtroBanos = useSelector(selectFiltroBanos);
  const banosStr = `${filtroBanos} baño${filtroBanos === 0 || filtroBanos > 1 ? 's' : ''}`;
  const banosBadge = <a onClick={onBanosBadgeClick} className="tag">{banosStr}</a>;

  const filtroEstacionamientos = useSelector(selectFiltroEstacionamientos);
  const estacionamientosStr = `${filtroEstacionamientos} estacionamiento${filtroEstacionamientos === 0 ||
    filtroEstacionamientos > 1 ? 's' : ''}`;
  const estacionamientosBadge = filtroEstacionamientos !== INITIAL_ESTACIONAMIENTOS ?
    <a onClick={onEstacionamientosBadgeClick} className="tag">{estacionamientosStr}</a> : null;

  const filtroBodegas = useSelector(selectFiltroBodegas);
  const bodegasStr = `${filtroBodegas} bodega${filtroBodegas === 0 || filtroBodegas > 1 ? 's' : ''}`;
  const bodegasBadge = filtroBodegas !== INITIAL_BODEGAS ?
    <a onClick={onBodegasBadgeClick} className="tag">{bodegasStr}</a> : null;

  const filtroAmoblado = useSelector(selectFiltroAmoblado);
  const amobladoStr = 'amoblado';
  const amobladoBadge = filtroAmoblado !== INITIAL_AMOBLADO ?
    <a onClick={onAmobladoBadgeClick} className="tag">{amobladoStr}</a> : null;

  const filtroSuperficieMin = useSelector(selectFiltroSuperficieMin);
  const superficieMinStr = `Superficie min. ${filtroSuperficieMin} m`;
  const superficieMinBadge = filtroSuperficieMin !== INITIAL_SUPERFICIE_MIN ?
    <a onClick={onSuperficieMinBadgeClick} className="tag">{superficieMinStr}<sup>2</sup></a> : null;

  const filtroSuperficieMax = useSelector(selectFiltroSuperficieMax);
  const superficieMaxStr = `Superficie max. ${filtroSuperficieMax} m`;
  const superficieMaxBadge = filtroSuperficieMax !== INITIAL_SUPERFICIE_MAX ?
    <a onClick={onSuperficieMaxBadgeClick} className="tag">{superficieMaxStr}<sup>2</sup></a> : null;

  return (
    <div className="__card-place-unidad initial-stick">
      <div className="container">
        <div className="row">
          <div className="tags-filters">
            {dormitoriosBadge}
            {banosBadge}
            {estacionamientosBadge}
            {bodegasBadge}
            {amobladoBadge}
            {superficieMinBadge}
            {superficieMaxBadge}
          </div>

          <div className="__place-list">
            {tipologiasCards}
          </div>
        </div>
      </div>
      <a onClick={openFiltro} className="mode-filtro fil"> FILTROS</a>
      <ModalFiltroMobile
        type={FILTER_TYPE.TIPOLOGIAS_MOBILE}
        proyecto={proyecto}
        show={showFiltro}
        close={closeFiltro}
        ubicacionSection={false}
      />
      <FichaUnidadMobile
        cotizar={cotizar}
        proyecto={proyecto}
        tipologia={currentTipologia}
        title={currentTipologiaTitle}
        show={showFichaUnidad}
        close={closeFicha}
        openShared={openShared}
        available={availableFicha}
      />
    </div>
  );
};

export default CardUnidadesMobile;
