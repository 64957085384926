import { parseBool } from "../utils";

export const BREAKPOINT_MOBILE = '850';

// URL de Strapi en localhost
//export const BACKEND_BASE_URL = 'http://localhost:1337';

// URL del Strapi de preproducción
export const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

// URL del Strapi de producción
//export const BACKEND_BASE_URL = 'https://admin.levelarriendos.cl';

// URL del server de testeo. Acordate de habilitar esto ANTES de correr el script de deploy!
//export const BACKEND_BASE_URL = 'http://100.25.183.39:1337';

export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const GOOGLE_ANALYTICS = process.env.REACT_APP_GOOGLE_ANALYTICS;
export const GOOGLE_TAGMANAGER = process.env.REACT_APP_GOOGLE_TAGMANAGER;

// Evaluación de producción
//export const LEVEL_EVALUACION_BASE_URL = 'https://www.levelges.cl/Evaluacion/home/index';

// Evaluación de testing - DEPRECATED (la dejo igual por las dudas)
//export const LEVEL_EVALUACION_BASE_URL = 'https://www.levelges.cl/ApisTestNodus/Evaluacion/home/index';

// Evaluación de QA
export const LEVEL_EVALUACION_BASE_URL = process.env.REACT_APP_LEVEL_EVALUACION_BASE_URL;

// Evaluación de PROD
// export const LEVEL_EVALUACION_BASE_URL = 'https://www.levelges.cl/Evaluacion/home/index';

export const ACEPTAR_TERMINOS_ERROR = `Debes aceptar los términos y la política de privacidad`;

// String de renta mensual
export const RENTA_MENSUAL_STR = 'Renta líquida mensual';

// Determina si se llenan los formularios de flujo de entrada automáticamente. Útil para testear
// el flujo de entrada sin tener que ingresar datos manualmente.
export const FLUJO_ENTRADA_AUTOFILL = process.env.REACT_APP_FLUJO_ENTRADA_AUTOFILL;

// Número de días que una cotización es válida
export const COTIZACIONES_DAYS_VALID = 5;

// Número de días de "gracia" de las cotizaciones, i.e. número de días, *después* de haber vencido,
// que las cotizaciones se siguen mostrando
export const COTIZACIONES_GRACE_PERIOD_IN_DAYS = 2;

// Tamaño de los banners de la home
export const HOME_DESK_IMAGE_WIDTH = '1920px';
export const HOME_DESK_IMAGE_HEIGHT = '329px';

export const HOME_MOBILE_IMAGE_WIDTH = '301px';
export const HOME_MOBILE_IMAGE_HEIGHT = '234px';

// Offsets de scrolling
export const DESK_SCROLL_HEADER_OFFSET = 220;
export const MOBILE_SCROLL_HEADER_OFFSET = 160;

// Tabs del form de la paǵina de contacto
export const CONTACTO_TABS = {
  FORM: 0,
  HORARIOS: 1,
};

export const LEVEL_LINEA_ETICA_URL = 'https://euroinmobiliaria.cl/linea-etica/';
export const LEVEL_COPYRIGHT = '© Todos los derechos reservados | LEVEL 2022';
export const LEVEL_FACEBOOK_URL = 'https://www.facebook.com/LEVELArriendos';
export const LEVEL_INSTAGRAM_URL = 'https://www.instagram.com/levelarriendos';
export const LEVEL_YOUTUBE_URL = 'https://www.youtube.com/channel/UC4DWWrOC_Af8O7LqlKGBkbQ/feature';
export const LEVEL_WEB_ANTERIOR = 'https://versionantigua.levelarriendos.cl';

// URLs de "Mi cuenta" y "Pagar arriendo"
export const LEVEL_MI_CUENTA_URL = 'https://levelges.cl/micuenta';
export const LEVEL_PAGAR_ARRIENDO_URL = 'https://ww3.servipag.com/pagoenlinea/portal/desktop/public/todas/!ut/p/b1/04_Sj9CPykssy0xPLMnMz0vMAfGjzOKNgs0CLZ0MHQ38zQPcDBx9gs1CzAK9Df0DTYAKIvEo8DanTL-HEXH6DXAARwNC-r30o9Jz8pOAXg3Xj8KrGOQXvApAjgUrwOMaP4_83FT9gtzQ0IjKYE9dR0VFADNEynw!/dl4/d5/L2dBISEvZ0FBIS9nQSEh/?idServicio=47&idBiller=151';

// Filename del archivo del formulario de contacto
export const DEFAULT_FILENAME = 'Sube un archivo del comprobante de pago o el del saldo pendiente';

// Id de la app de Facebook (para compartir en Facebook y demás). Este id corresponde a la app
// que yo (Laureano) registré con mi cuenta de Facebook.
export const FACEBOOK_APP_ID = '788726195024703';

// Valores mínimos que los gastos comunes tienen que tener para no ser considerados error de
// data entry
export const MIN_GASTO_COMUN = {
  BODEGA: 5,
  EST_CUBIERTO: 5,
  EST_MOTO: 5,
  TIPOLOGIA: 1000,
};

// Valores mínimos que los precios tienen que tener para no ser considerados errores de data entry
export const MIN_PRECIO = {
  BODEGA: 5,
  EST_CUBIERTO: 5,
  EST_MOTO: 5,
};

// Ancho máximo de la caja principal (el div con clase 'container') de PreEvaluacion. Si es null,
// deja el max-width original de la clase .container, cualquier otro valor es interpretado como una
// string válida de tamaño (e.g. '1600px', '80%', etc.) que sobreescribe dicho valor.
export const PREEVALUACION_MAX_WIDTH = null;

// Tipos de documento válidos
export const TIPO_DOCUMENTO = [
  'RUT',
  'DNI',
  'Pasaporte',
];

// Tipos de persona válidos
export const TIPO_PERSONA = [
  'Independiente',
  'Dependiente',
  'Sin Trabajo',
];

// Tipos de trabajador válidos
export const TIPO_TRABAJADOR = [
  'Independiente',
  'Dependiente',
  'Sin Trabajo',
];

// Tipos de género válidos
export const TIPO_GENERO = [
  'Masculino',
  'Femenino',
  'Otro',
];

// Tipos de género válidos
export const TIPO_ESTADO_CIVIL = [
  'Casado',
  'Soltero',
  'Divorciado',
  'Otro'
];

// URL relativa de lo que antes llamábamos 'place' y ahora llamamos 'nuestros-edificios'.
export const PLACE_REL_URL = '/nuestros-edificios';

// Tipos válidos de archivos para subir. El campo 'mime' se usa para validación y el campo 'userFriendly'
// es para mostrar de cara al usuario.
// Una lista de tipos mime comunes se puede encontrar en la siguiente URL:
// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
export const TIPO_ARCHIVO = [
  {
    mime: 'application/pdf',
    userFriendly: 'PDF',
  },
  {
    mime: 'image/jpeg',
    userFriendly: 'JPEG',
  },
  {
    mime: 'image/jpg',
    userFriendly: 'JPG',
  },
  {
    mime: 'image/png',
    userFriendly: 'PNG',
  },
];

// Tipos de modos de ordenamiento válidos (usado en /result, tanto mobile como desk)
export const TIPO_ORDENAR = [
  'Recomendados',
  'Menor precio',
  'Mayor precio',
  'Más solicitados',
];


// Define si algunas features están habilitadas o no en toda la UI, tanto desk como mobile
export const FEATURE_ENABLED = {
  TOUR_360: parseBool(process.env.REACT_APP_FEATURE_ENABLED_TOUR_360),
  PRINT: parseBool(process.env.REACT_APP_FEATURE_ENABLED_PRINT), // botón de imprimir
  LIKES: parseBool(process.env.REACT_APP_FEATURE_ENABLED_LIKES),  // corazoncitos de like
  COTIZAR: parseBool(process.env.REACT_APP_FEATURE_ENABLED_COTIZAR),
  CREAR_ALERTA: parseBool(process.env.REACT_APP_FEATURE_ENABLED_CREAR_ALERTA),
  CHATBOX: parseBool(process.env.REACT_APP_FEATURE_ENABLED_CHATBOX),

  // Si es true, reemplaza el botón "Arrendar online" del modal de cotización por un botón
  // "Obtener cotización" que pide la cotización a la API de Level y la envía por email, en
  // vez de iniciar el flow con todos los forms de avales y demás.
  OBTENER_COTIZACION: parseBool(process.env.REACT_APP_FEATURE_ENABLED_OBTENER_COTIZACION),

  // Determina si se muestra o no la cantidad disponible de cada producto
  DESTACADO_INFO_CANTIDAD: parseBool(process.env.REACT_APP_FEATURE_ENABLED_DESTACADO_INFO_CANTIDAD),

  // Determina si se muestran algunos alerts de promociones
  PROMO_ALERTS: parseBool(process.env.REACT_APP_FEATURE_ENABLED_PROMO_ALERTS),

  // Determina si se muestran botones relacionados a "Agendar visita"
  AGENDAR_VISITA: parseBool(process.env.REACT_APP_FEATURE_ENABLED_AGENDAR_VISITA),

  // Comentarios con estrellitas de la homepage
  COMENTARIOS: parseBool(process.env.REACT_APP_FEATURE_ENABLED_COMENTARIOS),

  // Determina si se muestra el modal de nuevo contenido. Si es false, el refresh se hace automáticamente
  // sin mostrar el modal.
  MODAL_NUEVO_CONTENIDO: parseBool(process.env.REACT_APP_FEATURE_ENABLED_MODAL_NUEVO_CONTENIDO),
};

// String del botón de tour virtual
export const TOUR_360_STR = 'VER TOUR VIRTUAL';

// Define los menues que se encuentran habilitados en toda la UI, tanto desk como mobile
export const MENU_ENABLED = {
  WEB_ANTERIOR: parseBool(process.env.REACT_APP_MENU_ENABLED_WEB_ANTERIOR),
  SOBRE_LEVEL: parseBool(process.env.REACT_APP_MENU_ENABLED_SOBRE_LEVEL),
  COMO_ARRENDAR: parseBool(process.env.REACT_APP_MENU_ENABLED_COMO_ARRENDAR),
  ACCESO_RESIDENTES: parseBool(process.env.REACT_APP_MENU_ENABLED_ACCESO_RESIDENTES),
  EDIFICIOS: parseBool(process.env.REACT_APP_MENU_ENABLED_EDIFICIOS),
  ESTACIONAMIENTOS: parseBool(process.env.REACT_APP_MENU_ENABLED_ESTACIONAMIENTOS),
  BODEGAS: parseBool(process.env.REACT_APP_MENU_ENABLED_BODEGAS),
  QUIENES_SOMOS: parseBool(process.env.REACT_APP_MENU_ENABLED_QUIENES_SOMOS),
  BENEFICIOS: parseBool(process.env.REACT_APP_MENU_ENABLED_BENEFICIOS),
  CONOCE_TU_BARRIO: parseBool(process.env.REACT_APP_MENU_ENABLED_CONOCE_TU_BARRIO),
  COMUNIDAD: parseBool(process.env.REACT_APP_MENU_ENABLED_COMUNIDAD),
  BLOG: parseBool(process.env.REACT_APP_MENU_ENABLED_BLOG),
  CONTACTO: parseBool(process.env.REACT_APP_MENU_ENABLED_CONTACTO),
  PLACE_VISITAR: parseBool(process.env.REACT_APP_MENU_ENABLED_PLACE_VISITAR),
  MENU_FLEX: parseBool(process.env.REACT_APP_MENU_ENABLED_MENU_FLEX),  // item "Visitar" del header de /place
  MENU_MANTENIMIENTO_PREVENTIDO: parseBool(process.env.REACT_APP_MENU_MANTENIMIENTO_PREVENTIDO),
  MENU_EMERGENCIA: parseBool(process.env.REACT_APP_MENU_EMERGENCIA),


};

// Subitems del menu "¿Quiénes somos?"
export const MENU_QUIENES_SOMOS_ENABLED = {
  BLOG: parseBool(process.env.REACT_APP_MENU_QUIENES_SOMOS_ENABLED_BLOG),
  BASES_NOTARIALES: parseBool(process.env.REACT_APP_MENU_QUIENES_SOMOS_ENABLED_BASES_NOTARIALES),
  COMUNIDAD: parseBool(process.env.REACT_APP_MENU_QUIENES_SOMOS_ENABLED_COMUNIDAD)
};

// Contenido de la página de "Preguntas frecuentes"
export const PREGUNTAS_FRECUENTES_CONTENT = {
  ARRENDAR_ONLINE: parseInt(process.env.REACT_APP_PREGUNTAS_FRECUENTES_CONTENT_ARRENDAR_ONLINE),
  GASTOS_COMUNES: parseInt(process.env.REACT_APP_PREGUNTAS_FRECUENTES_CONTENT_GASTOS_COMUNES),
  PAGOS_CONTRATOS: parseInt(process.env.REACT_APP_PREGUNTAS_FRECUENTES_CONTENT_PAGOS_CONTRATOS),
  EDIFICIOS: parseInt(process.env.REACT_APP_PREGUNTAS_FRECUENTES_CONTENT_EDIFICIOS),
  BASES_NOTARIALES: parseInt(process.env.REACT_APP_PREGUNTAS_FRECUENTES_CONTENT_BASES_NOTARIALES)
};

// Máximo tamaño de archivo que se puede subir, en MB
export const MAX_FILE_SIZE_MB = 5;

// Máxima cantidad de avales y complementos en PreEvaluacion. -1 indica "infinito"
export const MAX_AVALES = -1;
export const MAX_COMPLEMENTOS = -1;

// Define si la validación está habilitada o no
export const VALIDATION_ENABLED = true;

// Tipos de filtro del componente Filtro
export const FILTER_TYPE = {
  PROYECTOS: 0,
  ESTACIONAMIENTOS: 1,
  BODEGAS: 2,
  TIPOLOGIAS_MOBILE: 3,
  MIS_COTIZACIONES: 4,
};

export const PRODUCTION_TEMP = parseBool(process.env.REACT_APP_PRODUCTION_TEMP);
