import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Carousel from 'react-elastic-carousel';
import './styles/EdificiosDestacadosMobile.scss';
import styles from './styles/EdificiosDestacadosMobileCustom.module.scss';
import LazyLoad from 'react-lazyload';
import { TextInfo, Button } from '../ui/index';
import { selectProyectosDestacados } from '../../redux/proyectosSlice';
import {
  dataLayerPush,
  getDormitoriosAndBanosString,
  getPlaceUrl,
  getPortadaData
} from '../../utils';


const PROXIMOS_PROYECTOS_LINK_ENABLED = false;


const EdificiosDestacadosMobile = () => {

  const proyectosDestacados = useSelector(selectProyectosDestacados);

  const onArrendarClick = (proyectoNombre, position) => {
    dataLayerPush({
      event: 'click',
      bannerName: proyectoNombre,
      section: 'home (mobile) - nuestros edificios',
      position,
    });
  }

  const sliderItems = proyectosDestacados.map((proy, index) => {

    const portadaData = getPortadaData(proy);
    const classNameStr = index === 0 ? 'item-slider border-white active' : 'item-slider border-white';
    const arrendarLinkUrl = getPlaceUrl(proy);

    // Recorrer tipologías del proyecto para encontrar número mínimo y máximo de dormitorios y baños
    let dormitorios = [];
    let banos = [];

    proy.tipologias.forEach((tipo) => {
      dormitorios.push(tipo.dormitorios);
      banos.push(tipo.banos);
    });

    const bothStrs = getDormitoriosAndBanosString(proy, false);
    const dormitoriosStr = bothStrs.dormitoriosStr;
    const banosStr = bothStrs.banosStr;


    return (
      <div key={proy._id} data-position={index} className={classNameStr}>
        <div className="imageThumbs">
          <LazyLoad throttle={50} height={100}>
            <img src={portadaData.url} alt={portadaData.alt} style={{ maxHeight: "208px" }} />
          </LazyLoad>
          {!proy.terceros && <span className="alert-place infoPla">Edificio Multifamily</span>}
        </div>
        <div className="info">
          <TextInfo textWeight="bold" color="black" size="18" nowrap={true}>{proy.proyecto_nombre}</TextInfo>
          <TextInfo iconColor="#FF6D00" iconName="icon-location-pin" size="14">{proy.direccion}</TextInfo>
          <div className="option-edificio">
            <TextInfo iconColor="#848390" iconName="icon-bed" size="14">{dormitoriosStr}</TextInfo>
            <TextInfo iconColor="#848390" iconName="icon-bath" size="14">{banosStr}</TextInfo>
          </div>
          <Link to={arrendarLinkUrl} onClick={() => onArrendarClick(proy.proyecto_nombre, index + 1)}><Button color="btn-orange" fullWidth={true}>ARRENDAR AQUI</Button></Link>
        </div>
      </div>
    );
  });

  const [currentImage, setCurrentImage] = useState(0);

  const removeActive = () => {
    const itemsSlider = document.querySelector('.slider-places-mobile-items.place .item-slider.active');
    if (itemsSlider) {
      itemsSlider.removeClass('active');
    }
  }

  return (
    <div className="slider-destacados-mobile">

      <h2 className="titleMobile mb-4">Nuestros edificios</h2>

      {proyectosDestacados.length === 0 && <div className={styles.loader}>Loading...</div>}

      {
        proyectosDestacados.length > 0 &&

        <Carousel
          itemsToScroll={1}
          itemsToShow={1}
          enableAutoPlay={false}
          showArrows={false}
          autoPlaySpeed={5000}
          initialFirstItem={0}

          onNextStart={(currentItem, nextItem) => {

            if (currentImage < proyectosDestacados.length - 1) {
              const { index } = currentItem;
              setCurrentImage(currentImage + 1);

              removeActive();

              const currentThumb = document.querySelector('.slider-places-mobile-items.place .item-slider[data-position="' + (index + 1) + '"]');
              if (currentThumb) {
                currentThumb.addClass('active');
              }
            }
          }}
          onPrevStart={(currentItem, nextItem) => {
            if (currentImage >= 1) {
              const { index } = currentItem;
              setCurrentImage(currentImage - 1);

              removeActive();

              const currentThumb = document.querySelector('.slider-places-mobile-items.place .item-slider[data-position="' + (index - 1) + '"]');
              if (currentThumb) {
                currentThumb.addClass('active');
              }
            }
          }}

          className="slider-places-mobile-items place">
          {sliderItems}
        </Carousel>
      }

      {PROXIMOS_PROYECTOS_LINK_ENABLED && <a href="# " className="linkOption">Ver próximos proyectos</a>}

    </div>
  )
}

export default EdificiosDestacadosMobile;
