import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { BACKEND_BASE_URL } from '../config/constant';
import {
  dataLayerPush,
  getAuthenticatedRequestData,
  getBodegaPrecio,
  getEstacionamientoAutoPrecio,
  getEstacionamientoMotoPrecio,
  getMockEstacionamientoAutoArray,
  getMockEstacionamientoMotoArray,
  getMockBodegaArray,
  getPrecioMensual,
  getProyectoById,
  updateTitular,
  createAval,
  getStrapiResponseErrorMessage,
  getToken
} from '../utils';


export const INITIAL_PREEV_AMOBLADO = false;
export const INITIAL_PREEV_ESTACIONAMIENTOS_AUTO = 0;
export const MAX_PREEV_ESTACIONAMIENTOS_AUTO = 50;  // FIXME: Chequear, seguro hay que calcular dinámicamente
export const INITIAL_PREEV_ESTACIONAMIENTOS_MOTO = 0;
export const MAX_PREEV_ESTACIONAMIENTOS_MOTO = 50; // FIXME: Chequear, seguro hay que calcular dinámicamente
export const INITIAL_PREEV_BODEGAS = 0;
export const INITIAL_PREEV_TIPOLOGIA_INDEX = 0;
export const INITIAL_PREEV_TIPOLOGIA_TITULO = '';
export const INITIAL_PREEV_TITULAR_OBJ = {};
export const INITIAL_PREEV_TITULAR_STRAPI_ID = '';
export const INITIAL_PREEV_CURRENT_MESES_ARRAY = [];
export const INITIAL_PREEV_CURRENT_CONFIGURACION_RESERVA = {};
export const INITIAL_PREEV_TITULAR_USER_ID = '';
export const INITIAL_PREEV_TITULAR_NOMBRE = '';
export const INITIAL_PREEV_TITULAR_APELLIDO = '';
export const INITIAL_PREEV_TITULAR_TIPO_DOCUMENTO = '';
export const INITIAL_PREEV_TITULAR_NUMERO_DOCUMENTO = '';
export const INITIAL_PREEV_TITULAR_EMAIL = '';
export const INITIAL_PREEV_TITULAR_TIPO_PERSONA = '';
export const INITIAL_PREEV_TITULAR_TELEFONO = '';
export const INITIAL_PREEV_TITULAR_RENTA_MENSUAL = '';
export const INITIAL_PREEV_TITULAR_MESES_ARRIENDO_ID = '';
export const INITIAL_PREEV_AVALES_ARRAY = [];
export const INITIAL_PREEV_SEND_RESERVA_STATUS = 'idle';
export const INITIAL_PREEV_SEND_PREEV_ERROR_MSG = '';
export const INITIAL_PREEV_SEND_PREEV_ERROR_COUNT = 0;
export const INITIAL_PREEV_SEND_COTIZACION_ERROR_MSG = '';
export const INITIAL_PREEV_SEND_COTIZACION_SUCCESS_MSG = 'Cotización enviada a tu email!';
export const INITIAL_PREEV_SEND_COTIZACION_SUCCESS_COUNT = 0;
export const INITIAL_PREEV_SEND_COTIZACION_LOADING_MSG = 'Procesando cotización, espera por favor...';
export const INITIAL_PREEV_SEND_COTIZACION_LOADING_COUNT = 0;
export const INITIAL_PREEV_SEND_COTIZACION_ERROR_COUNT = 0;
export const INITIAL_PREEV_SEND_RESERVA_ERROR_MSG = '';
export const INITIAL_PREEV_SEND_RESERVA_ERROR_COUNT = 0;
export const INITIAL_PREEV_SEND_RESERVA_SUCCESS_MSG = 'Reserva exitosa!';
export const INITIAL_PREEV_SEND_RESERVA_SUCCESS_COUNT = 0;
export const INITIAL_PREEV_SEND_RESERVA_LOADING_MSG = 'Procesando reserva, espera por favor...';
export const INITIAL_PREEV_SEND_RESERVA_LOADING_COUNT = 0;
export const INITIAL_PREEV_GET_MESES_ARRAY_ERROR_MSG = '';
export const INITIAL_PREEV_GET_MESES_ARRAY_ERROR_COUNT = 0;
export const INITIAL_PREEV_GET_MESES_ARRAY_SUCCESS_MSG = '';
export const INITIAL_PREEV_GET_MESES_ARRAY_SUCCESS_COUNT = 0;
export const INITIAL_PREEV_GET_CONFIGURACION_RESERVA_ERROR_MSG = '';
export const INITIAL_PREEV_GET_CONFIGURACION_RESERVA_ERROR_COUNT = 0;
export const INITIAL_PREEV_GET_CONFIGURACION_RESERVA_SUCCESS_MSG = '';
export const INITIAL_PREEV_GET_CONFIGURACION_RESERVA_SUCCESS_COUNT = 0;
export const INITIAL_PREEV_LAST_COTIZACION_ID = '';
export const INITIAL_PREEV_LAST_COTIZACION_OBJ = {};
export const INITIAL_PREEV_RESERVA_DEPTO_STRAPI_ID = '';
export const INITIAL_PREEV_RESERVA_DEPTO_STRAPI_OBJ = null;
export const INITIAL_PREEV_RESERVA_ESTACIONAMIENTOS = [];
export const INITIAL_PREEV_RESERVA_AVALES = [];
export const INITIAL_PREEV_RESERVA_COMPLEMENTOS = [];
export const INITIAL_PREEV_RESERVA_BODEGAS = [];
export const INITIAL_PREEV_RESERVA_MASCOTA = false;
export const INITIAL_PREEV_RESERVA_PAGO_TOTAL = false;

export const INITIAL_PREEV_RESERVA_TITULAR = {
  nombre: '',
  apellidoPaterno: '',
  apellidoMaterno: '',
  tipoDocumento: '',
  numeroDocumento: '',
  email: '',
  tipoPersona: '',
  telefono: '',
  rentaMensual: '',
  nacionalidad: '',
  genero: '',
  anoNacimiento: '',
  profesion: '',
  actividad: '',
  tipoTrabajador: '',
  region: '',
  ciudad: '',
  comuna: '',
  direccion: '',
  numeroPisoDepto: '',
  poblacion: '',
  mesesArriendo: '',
  mesesArriendoId: '',
  fechaInicioContrato: '',
  clientePep: false,
};

export const INITIAL_PREEV_RESERVA = {
  deptoStrapiId: INITIAL_PREEV_RESERVA_DEPTO_STRAPI_ID, // id de Strapi del depto elegido
  deptoStrapiObj: INITIAL_PREEV_RESERVA_DEPTO_STRAPI_OBJ, // objeto del depto de Strapi elegido
  estacionamientos: INITIAL_PREEV_RESERVA_ESTACIONAMIENTOS,
  bodegas: INITIAL_PREEV_RESERVA_BODEGAS,
  mascota: INITIAL_PREEV_RESERVA_MASCOTA,
  pagoTotal: INITIAL_PREEV_RESERVA_PAGO_TOTAL,
  titular: INITIAL_PREEV_RESERVA_TITULAR,
  avales: INITIAL_PREEV_RESERVA_AVALES,
  complementos: INITIAL_PREEV_RESERVA_COMPLEMENTOS,
};


const initialState = {
  // Datos generales
  amoblado: INITIAL_PREEV_AMOBLADO,
  estacionamientosAuto: INITIAL_PREEV_ESTACIONAMIENTOS_AUTO,
  estacionamientosMoto: INITIAL_PREEV_ESTACIONAMIENTOS_MOTO,
  bodegas: INITIAL_PREEV_BODEGAS,
  tipologiaIndex: INITIAL_PREEV_TIPOLOGIA_INDEX,
  tipologiaTitulo: INITIAL_PREEV_TIPOLOGIA_TITULO,

  // Datos del titular. Es un objeto de la forma { nombre, apellido, tipoDocumento,
  // numeroDocumento, email, tipoPersona, telefono, rentaMensual }
  titular: INITIAL_PREEV_TITULAR_OBJ,

  // Id del objeto de titular creado en Strapi
  titularStrapiId: INITIAL_PREEV_TITULAR_STRAPI_ID,

  // Unidades a reservar
  reserva: INITIAL_PREEV_RESERVA,

  // Último resultado de getMesesArray
  currentMesesArray: INITIAL_PREEV_CURRENT_MESES_ARRAY,

  // Último resultado de getConfiguracionReserva
  currentConfiguracionReserva: INITIAL_PREEV_CURRENT_CONFIGURACION_RESERVA,

  // Estado del thunk sendPreevaluacion
  sendPreevaluacionStatus: INITIAL_PREEV_SEND_RESERVA_STATUS,
  sendPreevaluacionErrorMessage: INITIAL_PREEV_SEND_PREEV_ERROR_MSG,
  sendPreevaluacionErrorCount: INITIAL_PREEV_SEND_PREEV_ERROR_COUNT,

  // Estado del thunk sendCotizacion
  sendCotizacionStatus: 'idle',
  sendCotizacionErrorMessage: INITIAL_PREEV_SEND_COTIZACION_ERROR_MSG,
  sendCotizacionErrorCount: INITIAL_PREEV_SEND_COTIZACION_ERROR_COUNT,
  sendCotizacionSuccessMessage: INITIAL_PREEV_SEND_COTIZACION_SUCCESS_MSG,
  sendCotizacionSuccessCount: INITIAL_PREEV_SEND_COTIZACION_SUCCESS_COUNT,
  sendCotizacionLoadingMessage: INITIAL_PREEV_SEND_COTIZACION_LOADING_MSG,
  sendCotizacionLoadingCount: INITIAL_PREEV_SEND_COTIZACION_LOADING_COUNT,

  // Estado del thunk sendReserva
  sendReservaStatus: 'idle',
  sendReservaErrorMessage: INITIAL_PREEV_SEND_RESERVA_ERROR_MSG,
  sendReservaErrorCount: INITIAL_PREEV_SEND_RESERVA_ERROR_COUNT,
  sendReservaSuccessMessage: INITIAL_PREEV_SEND_RESERVA_SUCCESS_MSG,
  sendReservaSuccessCount: INITIAL_PREEV_SEND_RESERVA_SUCCESS_COUNT,
  sendReservaLoadingMessage: INITIAL_PREEV_SEND_RESERVA_LOADING_MSG,
  sendReservaLoadingCount: INITIAL_PREEV_SEND_RESERVA_LOADING_COUNT,

  // Estado del thunk getMesesArray
  getMesesArrayStatus: 'idle',
  getMesesArrayErrorMessage: INITIAL_PREEV_GET_MESES_ARRAY_ERROR_MSG,
  getMesesArrayErrorCount: INITIAL_PREEV_GET_MESES_ARRAY_ERROR_COUNT,
  getMesesArraySuccessMessage: INITIAL_PREEV_GET_MESES_ARRAY_SUCCESS_MSG,
  getMesesArraySuccessCount: INITIAL_PREEV_GET_MESES_ARRAY_SUCCESS_COUNT,

  // Estado del thunk getConfiguracionReserva
  getConfiguracionReservaStatus: 'idle',
  getConfiguracionReservaErrorMessage: INITIAL_PREEV_GET_CONFIGURACION_RESERVA_ERROR_MSG,
  getConfiguracionReservaErrorCount: INITIAL_PREEV_GET_CONFIGURACION_RESERVA_ERROR_COUNT,
  getConfiguracionReservaSuccessMessage: INITIAL_PREEV_GET_CONFIGURACION_RESERVA_SUCCESS_MSG,
  getConfiguracionReservaSuccessCount: INITIAL_PREEV_GET_CONFIGURACION_RESERVA_SUCCESS_COUNT,

  // Id de la última cotización creada exitosamente
  lastCotizacionId: INITIAL_PREEV_LAST_COTIZACION_ID,

  // Objeto con la información de la última cotización creada exitosamente
  lastCotizacionObj: INITIAL_PREEV_LAST_COTIZACION_OBJ,

  // Id del proyecto que se está reservando
  currentProyectoId: '',
};

// Thunk para mandar la información de preevaluación al backend. Asume que 'preevaluacionData' es un objeto
// de la forma { proyecto_id, amoblado, estacionamientosAuto, estacionamientosMoto, bodegas,
//  tipologiaIndex, titular: { ... }, }
export const sendPreevaluacion = createAsyncThunk('preevaluacion/sendPreevaluacion', async (preevaluacionData, thunkAPI) => {

  const { getState } = thunkAPI;
  const reduxState = getState();
  const { user } = reduxState;
  const clienteid = user.levelClienteId;

  try {
    let titularData = {
      clienteid,
      nombre: preevaluacionData.titular.nombre,
      apellido_paterno: preevaluacionData.titular.apellidoPaterno,
      tipo_documento: preevaluacionData.titular.tipoDocumento,
      numero_documento: preevaluacionData.titular.numeroDocumento,
      email: preevaluacionData.titular.email,
      tipo_persona: preevaluacionData.titular.tipoPersona,
      telefono: preevaluacionData.titular.telefono,
      renta_mensual: preevaluacionData.titular.rentaTitular,
      rentas_avales: preevaluacionData.titular.rentasAvales,
      rentas_complementos: preevaluacionData.titular.rentasComplementos,
    };

    titularData = getAuthenticatedRequestData(titularData, reduxState);

    let response = await axios.post(`${BACKEND_BASE_URL}/titulares`, titularData);

    const titularId = response.data.id;

    let preevaluacionRequestData = {
      clienteid,
      proyecto_id: preevaluacionData.proyecto_id,
      amoblado: preevaluacionData.amoblado,
      estacionamientos_auto: preevaluacionData.estacionamientosAuto,
      estacionamientos_moto: preevaluacionData.estacionamientosMoto,
      bodegas: preevaluacionData.bodegas,
      tipologia_index: preevaluacionData.tipologiaIndex,
      titulare: titularId,  // 'titulare' no es un typo, está bien así!!
    };

    preevaluacionRequestData = getAuthenticatedRequestData(preevaluacionRequestData, reduxState);

    response = await axios.post(`${BACKEND_BASE_URL}/preevaluaciones`, preevaluacionRequestData);

    return { titularId };
  } catch (err) {
    // Capturar error de Strapi y relanzarlo con mensajes apropiados
    switch (err.response.data.message[0].messages[0].id) {
      case 'Auth.form.error.email.taken':
        throw new Error(`El email ya está en uso!`);

      default:
        throw new Error(`Strapi error id: ${err.response.data.message[0].messages[0].id}`);
    }
  }
});

// Thunk para mandar la información de cotización al backend. Asume que 'cotizacionData' es un objeto
// de la forma { titular: { }, titularStrapiId, userStrapiId, avales: [ ],
// complementos: [ ], amoblado, proyectoId, isMinimalCotizacion }, en donde proyectoId se refiere al id
// de Strapi (no al de Level).
// isMinimalCotizacion indica si la cotización es una cotización "mínima", en donde no se incluye
// titular/avales (esto es usado para crear cotizaciones sin pasar por el "flujo de entrada" y
// todos sus formularios). Si isMinimalCotizacion es true, la función asume que la forma del objeto
// cotizacionData es { proyectoId }
export const sendCotizacion = createAsyncThunk('preevaluacion/sendCotizacion', async (cotizacionData, thunkAPI) => {
  const {
    titular,
    avales,
    complementos,
    amoblado,
    proyectoId,
    deptoStrapiId,
    estacionamientos,
    bodegas,
    isMinimalCotizacion,
  } = cotizacionData;

  const { getState } = thunkAPI;
  const reduxState = getState();
  const { proyectos, preevaluacion, user } = reduxState;
  const clienteid = user.levelClienteId;

  if (isMinimalCotizacion) {
    // Cotización mínima: No actualizamos titular, no creamos avales, y obtenemos productos secundarios
    // automáticamente en caso de haberlos, obteniendo la cantidad de cada uno del estado de Redux
    const proyecto = getProyectoById(proyectos.proyectos, proyectoId);

    const estacionamientos_auto = getMockEstacionamientoAutoArray(proyecto, preevaluacion.estacionamientosAuto);
    const estacionamientos_moto = getMockEstacionamientoMotoArray(proyecto, preevaluacion.estacionamientosMoto);
    const bodegas = getMockBodegaArray(proyecto, preevaluacion.bodegas);

    let newCotizacion = {
      clienteid,
      level_clienteid: user.levelClienteId,
      amoblado: preevaluacion.amoblado,
      titular: null,
      avales: null,
      proyecto_id: proyectoId,
      departamento: deptoStrapiId,
      estacionamientos_auto,
      estacionamientos_moto,
      bodegas,
    };

    newCotizacion = getAuthenticatedRequestData(newCotizacion, reduxState);

    try {
      // Crear cotización mínima
      const jwt = getToken();
      const responseCotizacion = await axios.post(`${BACKEND_BASE_URL}/cotizaciones`, newCotizacion, { headers: { 'x-api-key': `Bearer ${jwt}` } });
      return { response: responseCotizacion };
    } catch (error) {
      const errorMsg = getStrapiResponseErrorMessage(error);
      throw new Error(errorMsg);
    }
  }

  // Cotización "full": Actualizamos info de titular, creamos avales, y obtenemos los productos secundarios
  // elegidos por el usuario

  // Actualizar titular en Strapi
  const titularStrapi = await updateTitular({ clienteid, ...titular }, reduxState);

  const avalesIds = [];

  // Crear avales en Strapi
  for (let i = 0; i < avales.length; i++) {
    const currAval = avales[i];

    const response = await createAval({ clienteid, complemento: false, ...currAval }, reduxState);

    const { id: avalId } = response.data;
    avalesIds.push(avalId);
  }

  // Crear "complementos" (de hecho, son avales con el campo "complemento" puesto en true) en Strapi
  for (let i = 0; i < complementos.length; i++) {
    const currComplemento = complementos[i];

    const response = await createAval({ clienteid, complemento: true, ...currComplemento }, reduxState);

    const { id: complementoId } = response.data;
    avalesIds.push(complementoId);
  }

  // Hacemos esto porque Strapi necesita un array de strings de ids (e.g. ['5f91cc98c5a1f7943c6d27a8', 
  // '5f91cc98c5a1f7943c6d27aa']) y acá tenemos arrays de la forma [{ id: '5f91cc98c5a1f7943c6d27a8',
  // tipo: 'auto' }, { id: '5f91cc98c5a1f7943c6d27aa', tipo: 'moto' }]
  let estacionamientosAutoClean = estacionamientos.filter((estacionamiento) => estacionamiento.tipo === 'auto');
  estacionamientosAutoClean = estacionamientosAutoClean.map((estacionamiento) => estacionamiento.id);

  let estacionamientosMotoClean = estacionamientos.filter((estacionamiento) => estacionamiento.tipo === 'moto');
  estacionamientosMotoClean = estacionamientosMotoClean.map((estacionamiento) => estacionamiento.id);

  const bodegasClean = bodegas.map((bodega) => bodega.id);

  // Crear cotización en Strapi
  let newCotizacion = {
    clienteid,
    amoblado,
    titular: titularStrapi.id,
    avales: avalesIds,
    proyecto_id: proyectoId,
    departamento: deptoStrapiId,
    estacionamientos_auto: estacionamientosAutoClean,
    estacionamientos_moto: estacionamientosMotoClean,
    bodegas: bodegasClean,
  };

  newCotizacion = getAuthenticatedRequestData(newCotizacion, reduxState);

  try {
    // Crear cotización full
    const jwt = getToken();
    const responseCotizacion = await axios.post(`${BACKEND_BASE_URL}/cotizaciones`, newCotizacion, { headers: { 'x-api-key': `Bearer ${jwt}` } });
    return { response: responseCotizacion };
  } catch (error) {
    const errorMsg = getStrapiResponseErrorMessage(error);
    throw new Error(errorMsg);
  }
});

// Thunk para mandar la información de reserva al backend.
export const sendReserva = createAsyncThunk('preevaluacion/sendReserva', async (_unused, thunkAPI) => {

  const { getState } = thunkAPI;

  const reduxState = getState();

  const { user, preevaluacion } = reduxState;

  const { clienteid } = user;

  const { amoblado } = preevaluacion;

  const titularId = preevaluacion.lastCotizacionObj.titular.id;

  const avalesIds = [];

  for (const aval of preevaluacion.lastCotizacionObj.avales) {
    avalesIds.push(aval.id);
  }

  const proyectoId = preevaluacion.lastCotizacionObj.proyecto_id;

  const deptoId = preevaluacion.lastCotizacionObj.departamento.id;

  const estacionamientosAutoIds = [];

  for (const estAuto of preevaluacion.lastCotizacionObj.estacionamientos_auto) {
    estacionamientosAutoIds.push(estAuto.id);
  }

  const estacionamientosMotoIds = [];

  for (const estMoto of preevaluacion.lastCotizacionObj.estacionamientos_moto) {
    estacionamientosMotoIds.push(estMoto.id);
  }

  const bodegasIds = [];

  for (const bodega of preevaluacion.lastCotizacionObj.bodegas) {
    bodegasIds.push(bodega.id);
  }

  let newReserva = {
    clienteid,
    amoblado,
    titular: titularId,
    avales: avalesIds,
    proyecto_id: proyectoId,
    departamento: deptoId,
    estacionamientos_auto: estacionamientosAutoIds,
    estacionamientos_moto: estacionamientosMotoIds,
    bodegas: bodegasIds,
    cotizacionid: preevaluacion.lastCotizacionObj.cotizacionid,
  };

  newReserva = getAuthenticatedRequestData(newReserva, reduxState);

  try {
    const responseReserva = await axios.post(`${BACKEND_BASE_URL}/reservas`, newReserva);
    return { response: responseReserva };
  } catch (error) {
    const errorMsg = getStrapiResponseErrorMessage(error);
    throw new Error(errorMsg);
  }
});

// Thunk para obtener el array de meses para un torreId dado. ¿Qué es esto? Básicamente, es un array
// que trae la información sobre qué cantidades de meses puede alquilarse un depto dado. El array está
// determinado por la torre en donde está el depto, de ahí que el input sea un torreId. Es un array
// de la forma [{ datoId: 1, cantidad: 6 }], en donde 'cantidad' es una cantidad de meses que el
// depto es alquilable, y datoId es un id identificador de esa cantidad en la base de datos de Level.
// Cuando el thunk fue exitoso, guarda la información en 'currentMesesArray'.
export const getMesesArray = createAsyncThunk('preevaluacion/getMesesArray', async (torreId, thunkAPI) => {

  const reduxState = thunkAPI.getState();
  const requestData = getAuthenticatedRequestData({}, reduxState);

  try {
    const response = await axios.post(`${BACKEND_BASE_URL}/level-api/obtenermesescantidadarray/${torreId}`, requestData);
    return { response };
  } catch (error) {
    const errorMsg = getStrapiResponseErrorMessage(error);
    throw new Error(errorMsg);
  }
});

export const getDropdownData = createAsyncThunk('preevaluacion/getDropdownData', async () => {
  const dropdownData = ["GENERO", "TIPOTRABAJADOR", "ESTADOCIVIL", "PROFESION"]
  const response = {}
  try {
    for (const datoMaestro of dropdownData) {
      const { data } = await axios.post(`${BACKEND_BASE_URL}/level-api/datomaestro/`, { maestroCodigo: datoMaestro });
      response[datoMaestro] = data
    }
    const { data: paisesData } = await axios.post(`${BACKEND_BASE_URL}/level-api/obtenerPaises`,
      {
        paisId: 0,
        paisNacionalidad: ""
      });
    response.PAISES = paisesData
    return { response };
  } catch (error) {
    const errorMsg = getStrapiResponseErrorMessage(error);
    throw new Error(errorMsg);
  }
});

// Thunk para obtener la configuración de la reserva. ¿Qué es esto? Básicamente, un objeto con información
// sobre la reserva para un torreId dado. Devuelve un objeto de la forma { configuracion: { id, fechaInicio, fechaTermino, monto } }.
// Cuando el thunk fue exitoso, guarda la información en 'currentConfiguracionReserva'.
export const getConfiguracionReserva = createAsyncThunk('preevaluacion/getConfiguracionReserva', async (torreId, thunkAPI) => {

  const reduxState = thunkAPI.getState();
  const requestData = getAuthenticatedRequestData({}, reduxState);

  try {
    const response = await axios.post(`${BACKEND_BASE_URL}/level-api/obtenerconfiguracionreserva/${torreId}`, requestData);
    return { response };
  } catch (error) {
    const errorMsg = getStrapiResponseErrorMessage(error);
    throw new Error(errorMsg);
  }
});

export const guardarReserva = createAsyncThunk('preevaluacion/guardarReserva', async ({ reserva }) => {
  try {
    const response = await axios.post(
      `${BACKEND_BASE_URL}/level-api/guardarReserva`, reserva);
    return { response };
  } catch (error) {
    const errorMsg = getStrapiResponseErrorMessage(error);
    throw new Error(errorMsg);
  }
});

const preevaluacionSlice = createSlice({
  name: 'preevaluacion',
  initialState,
  reducers: {
    setSendCotizacionStatus(state, action) {
      state.sendCotizacionStatus = action.payload ? action.payload : INITIAL_PREEV_SEND_RESERVA_STATUS;
    },
    setAmoblado(state, action) {
      state.amoblado = action.payload ? action.payload : INITIAL_PREEV_AMOBLADO;
    },
    setEstacionamientosAuto(state, action) {
      state.estacionamientosAuto = action.payload ? action.payload : INITIAL_PREEV_ESTACIONAMIENTOS_AUTO;

      // Analytics
      dataLayerPush({
        event: 'click',
        estacionamientos: state.estacionamientosAuto,
      });
    },
    setEstacionamientosMoto(state, action) {
      state.estacionamientosMoto = action.payload ? action.payload : INITIAL_PREEV_ESTACIONAMIENTOS_MOTO;
    },
    setBodegas(state, action) {
      state.bodegas = action.payload ? action.payload : INITIAL_PREEV_BODEGAS;
    },
    setTipologiaIndex(state, action) {
      state.tipologiaIndex = action.payload ? action.payload : INITIAL_PREEV_TIPOLOGIA_INDEX;
    },
    setTipologiaTitulo(state, action) {
      state.tipologiaTitulo = action.payload ? action.payload : INITIAL_PREEV_TIPOLOGIA_TITULO;
    },
    setTitular(state, action) {
      if (!action.payload) {
        state.titular = INITIAL_PREEV_TITULAR_OBJ;
      } else {

        const newTitular = {
          userId: action.payload.userId ? action.payload.userId : INITIAL_PREEV_TITULAR_USER_ID,
          nombre: action.payload.nombre ? action.payload.nombre : INITIAL_PREEV_TITULAR_NOMBRE,
          apellidoPaterno: action.payload.apellidoPaterno ? action.payload.apellidoPaterno : INITIAL_PREEV_TITULAR_APELLIDO,
          apellidoMaterno: action.payload.apellidoMaterno ? action.payload.apellidoMaterno : INITIAL_PREEV_TITULAR_APELLIDO,
          tipoDocumento: action.payload.tipoDocumento ? action.payload.tipoDocumento : INITIAL_PREEV_TITULAR_TIPO_DOCUMENTO,
          numeroDocumento: action.payload.numeroDocumento ? action.payload.numeroDocumento : INITIAL_PREEV_TITULAR_NUMERO_DOCUMENTO,
          email: action.payload.email ? action.payload.email : INITIAL_PREEV_TITULAR_EMAIL,
          tipoPersona: action.payload.tipoPersona ? action.payload.tipoPersona : INITIAL_PREEV_TITULAR_TIPO_PERSONA,
          telefono: action.payload.telefono ? action.payload.telefono : INITIAL_PREEV_TITULAR_TELEFONO,
          rentaTitular: action.payload.rentaTitular ? action.payload.rentaTitular : INITIAL_PREEV_TITULAR_RENTA_MENSUAL,
          rentasAvales: action.payload.rentasAvales ? action.payload.rentasAvales : INITIAL_PREEV_TITULAR_RENTA_MENSUAL,
          rentasComplementos: action.payload.rentasComplementos ? action.payload.rentasComplementos : INITIAL_PREEV_TITULAR_RENTA_MENSUAL,
          mesesArriendoId: action.payload.mesesArriendoId ? action.payload.mesesArriendoId : INITIAL_PREEV_TITULAR_MESES_ARRIENDO_ID,
          estadoCivil: action.payload.estadocivil
          /*           nacionalidad: action.payload.nacionalidad,
                    generoId: action.payload.nacionalidad,
                    profesion: profesion[i], */
        };

        state.titular = newTitular;
      }
    },
    setReserva(state, action) {
      if (!action.payload) {
        state.reserva = INITIAL_PREEV_RESERVA;
      } else {
        state.reserva.deptoStrapiObj = action.payload.deptoStrapiObj ? action.payload.deptoStrapiObj : INITIAL_PREEV_RESERVA_DEPTO_STRAPI_OBJ;
        state.reserva.deptoStrapiId = action.payload.deptoStrapiId ? action.payload.deptoStrapiId : INITIAL_PREEV_RESERVA_DEPTO_STRAPI_ID;
        state.reserva.estacionamientos = action.payload.estacionamientos ? action.payload.estacionamientos : INITIAL_PREEV_RESERVA_ESTACIONAMIENTOS;
        state.reserva.bodegas = action.payload.bodegas ? action.payload.bodegas : INITIAL_PREEV_RESERVA_BODEGAS;
        state.reserva.mascota = action.payload.mascota ? action.payload.mascota : INITIAL_PREEV_RESERVA_MASCOTA;
        state.reserva.pagoTotal = action.payload.pagoTotal ? action.payload.pagoTotal : INITIAL_PREEV_RESERVA_PAGO_TOTAL;
        state.reserva.titular = action.payload.titular ? action.payload.titular : INITIAL_PREEV_RESERVA_TITULAR;
        state.reserva.avales = action.payload.avales ? action.payload.avales : INITIAL_PREEV_RESERVA_AVALES;
        state.reserva.complementos = action.payload.complementos ? action.payload.complementos : INITIAL_PREEV_RESERVA_COMPLEMENTOS;
      }
    },
    setReservaTitularMesesArriendoId(state, action) {
      state.reserva.titular.mesesArriendoId = action.payload;
    },
    setSendCotizacionLoadingCount(state, action) {
      state.sendCotizacionLoadingCount = action.payload ? action.payload : INITIAL_PREEV_SEND_COTIZACION_LOADING_COUNT;
    },
    setSendCotizacionSuccessCount(state, action) {
      state.sendCotizacionSuccessCount = action.payload ? action.payload : INITIAL_PREEV_SEND_COTIZACION_SUCCESS_COUNT;
    },
    setSendCotizacionErrorCount(state, action) {
      state.sendCotizacionErrorCount = action.payload ? action.payload : INITIAL_PREEV_SEND_COTIZACION_ERROR_COUNT;
    },
    setGetMesesArraySuccessCount(state, action) {
      state.getMesesArraySuccessCount = action.payload ? action.payload : INITIAL_PREEV_GET_MESES_ARRAY_SUCCESS_COUNT;
    },
    setGetMesesArrayErrorCount(state, action) {
      state.getMesesArrayErrorCount = action.payload ? action.payload : INITIAL_PREEV_GET_MESES_ARRAY_ERROR_COUNT;
    },
    setGetConfiguracionReservaSuccessCount(state, action) {
      state.getConfiguracionReservaSuccessCount = action.payload ? action.payload : INITIAL_PREEV_GET_CONFIGURACION_RESERVA_SUCCESS_COUNT;
    },
    setGetConfiguracionReservaErrorCount(state, action) {
      state.getConfiguracionReservaErrorCount = action.payload ? action.payload : INITIAL_PREEV_GET_CONFIGURACION_RESERVA_ERROR_COUNT;
    },
    setLastCotizacionId(state, action) {
      state.lastCotizacionId = action.payload;
    },
  },
  extraReducers: {
    // sendPreevaluacion thunk
    [sendPreevaluacion.pending]: (state, action) => {
      state.sendPreevaluacionStatus = 'loading';
    },
    [sendPreevaluacion.fulfilled]: (state, action) => {
      state.sendPreevaluacionStatus = 'succeeded';
      state.sendPreevaluacionErrorMessage = INITIAL_PREEV_SEND_PREEV_ERROR_MSG;
      state.sendPreevaluacionErrorCount = INITIAL_PREEV_SEND_PREEV_ERROR_COUNT;
      state.titularStrapiId = action.payload.titularId;
    },
    [sendPreevaluacion.rejected]: (state, action) => {
      state.sendPreevaluacionStatus = 'failed';
      state.sendPreevaluacionErrorMessage = action.error.message;
      state.sendPreevaluacionErrorCount += 1;
    },

    // sendCotizacion thunk
    [sendCotizacion.pending]: (state, action) => {
      state.sendCotizacionStatus = 'loading';
      state.currentProyectoId = action.meta.arg.proyectoId;
      state.sendCotizacionLoadingCount += 1;
    },
    [sendCotizacion.fulfilled]: (state, action) => {
      state.sendCotizacionStatus = 'succeeded';
      state.sendCotizacionErrorMessage = INITIAL_PREEV_SEND_COTIZACION_ERROR_MSG;
      state.sendCotizacionErrorCount = INITIAL_PREEV_SEND_COTIZACION_ERROR_COUNT;

      const { data } = action.payload.response;

      state.sendCotizacionSuccessCount += 1;

      // No sé si es necesaria la conversión a string, pero el doc de D2B dice literalmente
      // 'idLead': '%id de cotizacion%', así que hagámosle caso
      state.lastCotizacionId = '' + data.cotizacionid;

      state.lastCotizacionObj = data;
    },
    [sendCotizacion.rejected]: (state, action) => {
      state.sendCotizacionStatus = 'failed';
      state.sendCotizacionErrorMessage = action.error.message;
      state.sendCotizacionErrorCount += 1;
    },

    // sendReserva thunk
    [sendReserva.pending]: (state, action) => {
      state.sendReservaStatus = 'loading';
      state.sendReservaLoadingCount += 1;
    },
    [sendReserva.fulfilled]: (state, action) => {
      state.sendReservaStatus = 'succeeded';
      state.sendReservaErrorMessage = INITIAL_PREEV_SEND_RESERVA_ERROR_MSG;
      state.sendReservaErrorCount = INITIAL_PREEV_SEND_RESERVA_ERROR_COUNT;
      state.sendReservaSuccessCount += 1;
    },
    [sendReserva.rejected]: (state, action) => {
      state.sendReservaStatus = 'failed';
      state.sendReservaErrorMessage = action.error.message;
      state.sendReservaErrorCount += 1;
    },

    // getMesesArray thunk
    [getMesesArray.pending]: (state, action) => {
      state.getMesesArrayStatus = 'loading';
    },
    [getMesesArray.fulfilled]: (state, action) => {
      // Actualizar estado del thunk
      state.getMesesArrayStatus = 'succeeded';
      state.getMesesArrayErrorMessage = INITIAL_PREEV_GET_MESES_ARRAY_ERROR_MSG;
      state.getMesesArrayErrorCount = INITIAL_PREEV_GET_MESES_ARRAY_ERROR_COUNT;
      state.getMesesArraySuccessCount += 1;
      state.getMesesArraySuccessMessage = 'Meses array obtenido exitosamente';

      // Actualizar lo que de hecho importa, el array de meses
      state.currentMesesArray = action.payload.response.data.meses;
    },
    [getMesesArray.rejected]: (state, action) => {
      state.getMesesArrayStatus = 'failed';
      state.getMesesArrayErrorMessage = action.error.message;
      state.getMesesArrayErrorCount += 1;
    },

    // getConfiguracionReserva thunk
    [getConfiguracionReserva.pending]: (state, action) => {
      state.getConfiguracionReservaStatus = 'loading';
    },
    [getConfiguracionReserva.fulfilled]: (state, action) => {
      // Actualizar estado del thunk
      state.getConfiguracionReservaStatus = 'succeeded';
      state.getConfiguracionReservaErrorMessage = INITIAL_PREEV_GET_MESES_ARRAY_ERROR_MSG;
      state.getConfiguracionReservaErrorCount = INITIAL_PREEV_GET_MESES_ARRAY_ERROR_COUNT;
      state.getConfiguracionReservaSuccessCount += 1;
      state.getConfiguracionReservaSuccessMessage = 'Configuración de reserva obtenida exitosamente';

      // Actualizar lo que de hecho importa, la configuración de la reserva
      state.currentConfiguracionReserva = action.payload.response.data.configuracion;
    },
    [getConfiguracionReserva.rejected]: (state, action) => {
      state.getConfiguracionReservaStatus = 'failed';
      state.getConfiguracionReservaErrorMessage = action.error.message;
      state.getConfiguracionReservaErrorCount += 1;
    },
    [getDropdownData.fulfilled]: (draftState, { payload: { response } }) => {
      draftState.dropdownData = response
    },
  },
});


export default preevaluacionSlice.reducer;

export const selectTitular = state => state.preevaluacion.titular;
export const selectTitularStrapiId = state => state.preevaluacion.titularStrapiId;
export const selectAmoblado = state => state.preevaluacion.amoblado;
export const selectEstacionamientosAuto = state => state.preevaluacion.estacionamientosAuto;
export const selectEstacionamientosMoto = state => state.preevaluacion.estacionamientosMoto;
export const selectBodegas = state => state.preevaluacion.bodegas;
export const selectTipologiaIndex = state => state.preevaluacion.tipologiaIndex;
export const selectTipologiaTitulo = state => state.preevaluacion.tipologiaTitulo;
export const selectReserva = state => state.preevaluacion.reserva;
export const selectSendCotizacionErrorMessage = state => state.preevaluacion.sendCotizacionErrorMessage;
export const selectSendCotizacionErrorCount = state => state.preevaluacion.sendCotizacionErrorCount;
export const selectSendCotizacionSuccessMessage = state => state.preevaluacion.sendCotizacionSuccessMessage;
export const selectSendCotizacionSuccessCount = state => state.preevaluacion.sendCotizacionSuccessCount;
export const selectSendCotizacionLoadingMessage = state => state.preevaluacion.sendCotizacionLoadingMessage;
export const selectSendCotizacionLoadingCount = state => state.preevaluacion.sendCotizacionLoadingCount;
export const selectSendCotizacionStatus = state => state.preevaluacion.sendCotizacionStatus;
export const selectSendReservaErrorMessage = state => state.preevaluacion.sendReservaErrorMessage;
export const selectSendReservaErrorCount = state => state.preevaluacion.sendReservaErrorCount;
export const selectSendReservaSuccessMessage = state => state.preevaluacion.sendReservaSuccessMessage;
export const selectSendReservaSuccessCount = state => state.preevaluacion.sendReservaSuccessCount;
export const selectSendReservaLoadingMessage = state => state.preevaluacion.sendReservaLoadingMessage;
export const selectSendReservaLoadingCount = state => state.preevaluacion.sendReservaLoadingCount;
export const selectSendReservaStatus = state => state.preevaluacion.sendReservaStatus;
export const selectLastCotizacionId = state => state.preevaluacion.lastCotizacionId;
export const selectLastCotizacionObj = state => state.preevaluacion.lastCotizacionObj;
export const selectCurrentProyectoId = state => state.preevaluacion.currentProyectoId;
export const selectCurrentMesesArray = state => state.preevaluacion.currentMesesArray;
export const selectCurrentConfiguracionReserva = state => state.preevaluacion.currentConfiguracionReserva;
export const selectDropdownData = state => state.preevaluacion.dropdownData;


export const selectCurrentDeptoTorreId = state => {
  if (!state.preevaluacion.reserva.deptoStrapiObj) {
    return -1;
  }

  return state.preevaluacion.reserva.deptoStrapiObj.torreid;
}

export const selectDeptoByNombre = (departamentos, nombre) => {
  return () => {
    if (nombre === '-1') {
      return null;
    }

    for (let i = 0; i < departamentos.length; i++) {
      const depto = departamentos[i];

      if (depto.nombre === nombre) {
        return depto;
      }
    }

    return null;
  }
}

// Devuelve el precio (arriendo) mensual de un proyecto, teniendo en cuenta si es amoblado o no,
// y los productos secundarios (estacionamientos, bodegas, etc.) seleccionados
export const selectPrecioMensual = (proyecto) => {
  return (state) => {
    const amoblado = state.preevaluacion.amoblado;
    const estacionamientosAuto = state.preevaluacion.estacionamientosAuto;
    const estacionamientosMoto = state.preevaluacion.estacionamientosMoto;
    const bodegas = state.preevaluacion.bodegas;
    let precioMensual = 0;

    if (proyecto) {
      precioMensual = getPrecioMensual(proyecto, amoblado);

      const arriendoEstacionamientosAuto = getEstacionamientoAutoPrecio(proyecto) * estacionamientosAuto;
      const arriendoEstacionamientosMoto = getEstacionamientoMotoPrecio(proyecto) * estacionamientosMoto;
      const arriendoBodegas = getBodegaPrecio(proyecto) * bodegas;
      precioMensual += arriendoEstacionamientosAuto + arriendoEstacionamientosMoto + arriendoBodegas;

      return precioMensual;
    }
  }
}

// Devuelve los números de pisos de la tipología actual
export const selectCurrentPisos = (deptos, tipologia) => {
  return () => {
    const tipologiaTitulo = tipologia.titulo;
    const filteredDeptos = deptos.filter((depto) => depto.tipologia.titulo === tipologiaTitulo);
    const repeatedPisos = filteredDeptos.map((depto) => depto.piso);
    const uniquePisos = [...new Set(repeatedPisos)];
    uniquePisos.sort((a, b) => a - b);

    return uniquePisos;
  }
}

// Devuelve los números de departamentos del piso 'piso' de la tipología actual. Solamente se
// devuelven los números de deptos disponibles.
export const selectCurrentDeptosByPiso = (deptos, piso, tipologia, checkboxesMascota) => {
  return () => {
    if (piso === -1) {
      return [];
    }

    const tipologiaTitulo = tipologia.titulo;
    const filteredDeptos = deptos.filter((depto) => {
      return (
        depto.estado === 'DISPONIBLE'
        && depto.tipologia.titulo === tipologiaTitulo
        && depto.piso === piso
        && depto.mascotas === checkboxesMascota[0]
      )
    });
    const repeatedDeptosNums = filteredDeptos.map((depto) => parseInt(depto.nombre, 10));
    const uniqueDeptosNums = [...new Set(repeatedDeptosNums)];
    uniqueDeptosNums.sort((a, b) => a - b);

    return uniqueDeptosNums;
  }
}


export const selectPisosDptWithApi = async (proyectoNombre, titulo, piso = 0) => {

  const urlPrimarios = `${BACKEND_BASE_URL}/level-api/obtenerPrimarios`
  let dataBody = {
    PROYECTONOMBRE: proyectoNombre,
    NOMBREMODELO: titulo,
    NOMBREESTADO: "DISPONIBLE"
  }

  if (piso > 0) {
    dataBody = Object.assign(dataBody, { PRODUCTOPRIMARIOPISO: piso })
  }
  const resultData = await axios.post(urlPrimarios, dataBody);
  return resultData.data;
}

// Se retornan sólo los pisos que contienen deptos disponibles
export const selectPisosWithDeptos = (deptos, pisos, tipologia, checkboxesMascota) => {
  return () => {
    if (pisos.length === 0) {
      return [];
    }

    const tipologiaTitulo = tipologia.titulo
    const filteredPisos = pisos.map((piso) => {
      const deptosFilter = deptos.filter((depto) => {
        return (
          depto.estado === 'DISPONIBLE'
          && depto.tipologia.titulo === tipologiaTitulo
          && depto.piso === piso
          && depto.mascotas === checkboxesMascota[0]
        )
      });
      if (deptosFilter.length > 0) return piso
      return null
    }).filter((piso) => piso)

    return filteredPisos;
  }
}

export const selectGetMesesArrayData = (state) => {
  return {
    status: state.preevaluacion.getMesesArrayStatus,
    errorCount: state.preevaluacion.getMesesArrayErrorCount,
    errorMessage: state.preevaluacion.getMesesArrayErrorMessage,
    successCount: state.preevaluacion.getMesesArraySuccessCount,
    successMessage: state.preevaluacion.getMesesArraySuccessMessage,
  };
}

export const selectGetConfiguracionReservaData = (state) => {
  return {
    status: state.preevaluacion.getConfiguracionReservaStatus,
    errorCount: state.preevaluacion.getConfiguracionReservaErrorCount,
    errorMessage: state.preevaluacion.getConfiguracionReservaErrorMessage,
    successCount: state.preevaluacion.getConfiguracionReservaSuccessCount,
    successMessage: state.preevaluacion.getConfiguracionReservaSuccessMessage,
  };
}

export const selectSendCotizacionData = (state) => {
  return {
    status: state.preevaluacion.sendCotizacionStatus,
    errorCount: state.preevaluacion.sendCotizacionErrorCount,
    errorMessage: state.preevaluacion.sendCotizacionErrorMessage,
    successCount: state.preevaluacion.sendCotizacionSuccessCount,
    successMessage: state.preevaluacion.sendCotizacionSuccessMessage,
    loadingCount: state.preevaluacion.sendCotizacionLoadingCount,
    loadingMessage: state.preevaluacion.sendCotizacionLoadingMessage,
  };
}

export const {
  setSendCotizacionStatus,
  setAmoblado,
  setEstacionamientosAuto,
  setEstacionamientosMoto,
  setBodegas,
  setTipologiaIndex,
  setTipologiaTitulo,
  setTitular,
  setReserva,
  setReservaTitularMesesArriendoId,
  setSendCotizacionLoadingCount,
  setSendCotizacionSuccessCount,
  setSendCotizacionErrorCount,
  setGetMesesArraySuccessCount,
  setGetMesesArrayErrorCount,
  setGetConfiguracionReservaSuccessCount,
  setGetConfiguracionReservaErrorCount,
  setLastCotizacionId,
} = preevaluacionSlice.actions;
