import React from 'react';
import InputFiltro from './ui/InputFiltro/InputFiltro';


// Componente que muestra un input para ingresar estacionamientos (la diferencia con el componente
// `InputEstacionamientoCantidad` es que el estado de Redux que se modifica acá es el que se usa
// en la página /result, y no el de /estacionamientos). El componente es controlado: se le pasa
// el valor actual con el prop `estacionamiento`, y se ejecutan los callbacks `onIncrease` y
// `onDecrease` cuando la cantidad de estacionamientos aumenta o disminuye, respectivamente.
const InputEstacionamiento = ({ estacionamiento, onIncrease, onDecrease }) => {

  return (
    <InputFiltro
      type="estacionamientoAuto"
      label="Incluir estacionamiento"
      controlledValue={estacionamiento}
      onIncrease={onIncrease}
      onDecrease={onDecrease}
      name="estacionamiento"
      flex={true}
      between={true}
      marginBottom="10"
      margin={false}
    />
  );
}

export default InputEstacionamiento;
