import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  selectResetPasswordData,
  setResetPasswordErrorCount,
  setResetPasswordSuccessCount
} from '../redux/userSlice';


// Hook de mensaje del thunk resetPassword. Muestra un toast de éxito/error dependiendo de si el
// thunk resetPassword fue exitoso o no. Devuelve resetPasswordData para que el código cliente
// pueda acceder cómodamente a la data relacionada al thunk en caso de ser necesario.
const useResetPasswordMessage = () => {

  const dispatch = useDispatch();
  const resetPasswordData = useSelector(selectResetPasswordData);

  useEffect(() => {
    switch (resetPasswordData.status) {
      case 'succeeded':
        if (resetPasswordData.successCount > 0) {
          toast.success(`Contraseña reseteada exitosamente, revisa tu email!`);
          dispatch(setResetPasswordSuccessCount(0));
        }
        break;

      case 'failed':
        if (resetPasswordData.errorCount > 0) {
          toast.error(`No pudimos resetear tu contraseña`);
          dispatch(setResetPasswordErrorCount(0));
        }
        break;

      default:
        break;
    }
  }, [dispatch, resetPasswordData.errorCount, resetPasswordData.status, resetPasswordData.successCount]);

  return resetPasswordData;
}

export default useResetPasswordMessage;
