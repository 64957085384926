import React from 'react';
import './styles/ModalSolicitud.scss';

const Calendar = ({ close }) => {
  return (
      <iframe
        id="idFrame"
        title='form'
        src="https://outlook.office365.com/owa/calendar/EuroHolding@eurocorp.cl/bookings/"
        style={{ width: '100%', height: '70%' }}
      >
      </iframe>
  );
};

export default Calendar;
