import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getPreguntasFrecuentesData,
  INITIAL_GET_PREGUNTAS_FRECUENTES_DATA_STATUS,
  selectAllPreguntasFrecuentesData
} from '../redux/preguntasFrecuentesSlice';


// Hook de datos de la página "Preguntas Frecuentes". Obtiene los datos de la página "Preguntas Frecuentes"
// si no fueron obtenidos previamente.
// Devuelve un objeto con toda la data del slice de Redux correspondiente.
const usePreguntasFrecuentesData = () => {

  const dispatch = useDispatch();
  const preguntasFrecuentesData = useSelector(selectAllPreguntasFrecuentesData);

  useEffect(() => {
    if (preguntasFrecuentesData.getPreguntasFrecuentesDataStatus === INITIAL_GET_PREGUNTAS_FRECUENTES_DATA_STATUS) {
      dispatch(getPreguntasFrecuentesData());
    }
  }, [dispatch, preguntasFrecuentesData.getPreguntasFrecuentesDataStatus]);

  return preguntasFrecuentesData;
}

export default usePreguntasFrecuentesData;
