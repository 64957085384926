import React from 'react';
import './IconSvg.scss'

const IconSvg = (props) => {
    const { name } = props;
    return (
        <div className={`iconSvg ${name}`}></div>
    );
};

export default IconSvg;