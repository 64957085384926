import React, { useState, useEffect, useRef } from 'react';
import { TextInfo, Button } from '../ui/index';
import './styles/ModalGaleriaPlace.scss';
import Carousel from 'react-elastic-carousel';
import notFound from '../../static/images/notFound.png';
import { FEATURE_ENABLED } from '../../config/constant';
import { getProyectoTourUrl, getTipologiaGallery } from '../../utils';


const ModalGaleriaPlace = ({ proyecto, tipologia, show, close, defaultItem }) => {

  let totalImage = 1;
  let slides = [
    <div key={notFound} className="item-slider">
      <img src={notFound} alt="not-found" style={{ width: '100%' }} />
    </div>
  ];

  if (tipologia) {
    const gallery = getTipologiaGallery(tipologia);

    if (gallery.length > 0) {
      totalImage = gallery.length;

      slides = gallery.map((galleryItem) => {
        return (
          <div key={galleryItem.url} className="item-slider">
            <img src={galleryItem.url} alt={galleryItem.alt} style={{ width: '100%' }} />
          </div>
        );
      });
    }
  }

  const [currentImage, setCurrentImage] = useState(defaultItem);
  const carouselRef = useRef();

  useEffect(() => {
    setCurrentImage(defaultItem + 1);
    carouselRef.current.goTo(defaultItem);
  }, [defaultItem]);


  return (
    <div className={`modal-search-mobile ${show}`}>
      <div className="header--search">
        <div className="header-step flex show" onClick={close}>
          <TextInfo iconName="icon-close" color="white" textWeight="bold"></TextInfo>
          {
            FEATURE_ENABLED.TOUR_360 &&
            <a href={getProyectoTourUrl(proyecto)} target="_blank" rel="noopener noreferrer">
              <Button className="btn clear-filtro" color='btn-gray'>Ver departamento en 360<sup>o</sup></Button>
            </a>
          }
        </div>
      </div>
      <div className="search-body ModalGaleriaPlace">
        <div className="body-step show">
          <span className="countImage">{currentImage} / {totalImage}</span>
          <Carousel
            ref={carouselRef}
            itemsToScroll={1}
            itemsToShow={1}
            enableAutoPlay={false}
            showArrows={false}
            autoPlaySpeed={5000}
            initialFirstItem={currentImage}
            onNextStart={(currentItem, nextItem) => {
              if (currentImage < totalImage) {
                setCurrentImage(currentImage + 1);
              }
            }}
            onPrevStart={(currentItem, nextItem) => {
              if (currentImage > 1) {
                setCurrentImage(currentImage - 1);
              }
            }}
            className="slider-galery-image-place"
          >
            {slides}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default ModalGaleriaPlace;
