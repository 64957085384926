import React from 'react';
import { useState } from 'react';
import { getHourStr } from '../utils';
import { Select } from './ui/index';
import styles from './styles/SelectHora.module.scss';


const PLACEHOLDER = `Seleccionar`;


// Componente selector de hora. Asume hora de 24 horas. Se le pasa `startHour` para marcar
// la hora de inicio (por ejemplo, 9) y `endHour` para marcar la hora final (por ejemplo, 20).
// Muestra entonces todas las horas en punto entre `startHour` y `endHour`, por ejemplo,
// "9:00", "10:00", "11:00", ..., "20:00". Cuando una hora es seleccionada, se llama a
// onHourChange con el valor seleccionado. Si se selecciona el placeholder, se llama
// onHourChange con la string vacía ''. `fullWidth` indica si el <select> es fullwidth o no.
// Si se pasa una string `title`, se muestra como un <p> encima del <select>.
// `selectStyle` es opcional, es un objeto para estilear el <select> (el tag HTML) que implementa
// el componente <Select>.
const SelectHora = ({
  startHour,
  endHour,
  onHourChange,
  selectStyle,
  title = 'sarasa',
  fullWidth = true
}) => {

  const [currentHour, setCurrentHour] = useState('');

  const onValueChange = (event) => {
    setCurrentHour(event.target.value);

    if (onHourChange) {
      const str = event.target.value === PLACEHOLDER ? '' : event.target.value;
      onHourChange(str);
    }
  }

  let hourData = [];

  for (let i = startHour; i <= endHour; i++) {
    hourData.push({
      id: getHourStr(i),
      name: getHourStr(i),
    });
  }


  return (
    <div className={styles.container}>
      {title && <p>{title}</p>}
      <Select
        value={currentHour}
        data={hourData}
        onChange={onValueChange}
        placeholder={PLACEHOLDER}
        typeStyle="small"
        className="fill"
        fullWidth={fullWidth}
        selectStyle={selectStyle}
      />
    </div>
  );
}

export default SelectHora;
