import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectFiltroTipoEstacionamientoAuto,
  selectFiltroTipoEstacionamientoMoto,
  setFiltroTipoEstacionamientoAuto,
  setFiltroTipoEstacionamientoMoto
} from '../redux/proyectosSlice';
import { Checkbox, TextInfo } from './ui/index';


const CheckboxTipoEstacionamiento = () => {

  const dispatch = useDispatch();
  const filtroTipoEstacionamientoAuto = useSelector(selectFiltroTipoEstacionamientoAuto);
  const filtroTipoEstacionamientoMoto = useSelector(selectFiltroTipoEstacionamientoMoto);

  const onTipoEstacionamientoAutoChange = (event) => {
    dispatch(setFiltroTipoEstacionamientoAuto(event.target.checked));
  }

  const onTipoEstacionamientoMotoChange = (event) => {
    dispatch(setFiltroTipoEstacionamientoMoto(event.target.checked));
  }

  return (
    <>
      <Checkbox checked={filtroTipoEstacionamientoAuto} onChange={onTipoEstacionamientoAutoChange} >
        <TextInfo color="black">Auto</TextInfo>
      </Checkbox>
      <Checkbox checked={filtroTipoEstacionamientoMoto} onChange={onTipoEstacionamientoMotoChange} marginZero={true}>
        <TextInfo color="black">Moto</TextInfo>
      </Checkbox>
    </>
  );
}

export default CheckboxTipoEstacionamiento;
