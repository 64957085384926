/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, TextInfo } from '../ui/index';
import './styles/EdificiosDestacados.scss';
import Carousel from 'react-elastic-carousel';
import LazyLoad from 'react-lazyload';
import { useSelector } from 'react-redux';
import { selectProyectosDestacados } from '../../redux/proyectosSlice';
import {
  dataLayerPush,
  getDormitoriosAndBanosString,
  getPlaceUrl,
  getPortadaData
} from '../../utils';


const PROXIMOS_PROYECTOS_LINK_ENABLED = false;

// Tiene que ser igual a '$search-offset' en _HomeGlobals.css. No puede simplemente modificarse
// EdificiosDestacados.scss porque es CSS global y modifica otras partes del sitio.
const SEARCH_OFFSET = '-60px';


const EdificiosDestacados = () => {

  // FIXME: Ojo porque parece que el CSS está hardcodeado para espaciar exactamente 4 elementos; si
  // ponés menos, el spacing queda medio feo.
  const proyectosDestacados = useSelector(selectProyectosDestacados);

  const onArrendarClick = (proyectoNombre, position) => {
    dataLayerPush({
      event: 'click',
      bannerName: proyectoNombre,
      section: 'home - edificios destacados',
      position,
    });
  }

  const sliderItems = proyectosDestacados.map((proy, index) => {
    const portadaData = getPortadaData(proy);

    // Recorrer tipologías del proyecto para encontrar número mínimo y máximo de dormitorios y baños
    let dormitorios = [];
    let banos = [];


    proy.tipologias.forEach((tipo) => {
      dormitorios.push(tipo.dormitorios);
      banos.push(tipo.banos);
    });

    const bothStrs = getDormitoriosAndBanosString(proy, false);
    const dormitoriosStr = bothStrs.dormitoriosStr;
    const banosStr = bothStrs.banosStr;


    return (
      <div key={proy._id} className="item-slider placeNext">
        <div className="image">
          <LazyLoad throttle={50} height={100}>
            <div className="image" style={{ backgroundImage: `url(${portadaData.url})` }}></div>
          </LazyLoad>
          {!proy.terceros && <span className="alert-place infoPla">Edificio Multifamily</span>}
        </div>
        <div className="info">
          <TextInfo textWeight="bold" color="black" size="16">{proy.proyecto_nombre}</TextInfo>
          <TextInfo iconColor="#FF6D00" iconName="icon-location-pin" size="14" style={{ whiteSpace: 'nowrap' }}>{proy.direccion}</TextInfo>
          <div className="option-edificio">
            <TextInfo iconColor="#848390" iconName="icon-bed" size="14">{dormitoriosStr}</TextInfo>
            <TextInfo iconColor="#848390" iconName="icon-bath" size="14">{banosStr}</TextInfo>
          </div>
          <Link to={getPlaceUrl(proy)} onClick={() => onArrendarClick(proy.proyecto_nombre, index + 1)}><Button color="btn-orange" fullWidth={true}>ARRENDAR AQUI</Button></Link>
        </div>
      </div>
    );
  });

  // Usado para acceder al carousel cuando se tocan las flechitas de adelante/atrás
  let carousel;

  return (
    <>
      <div className="destacados-edicicios" style={{ position: 'relative', top: SEARCH_OFFSET }}>
        <h2 className="title">Edificios destacados</h2>
        <div className="option-slider">
          {PROXIMOS_PROYECTOS_LINK_ENABLED && <a href="#">Próximos proyectos</a>}
          <Button color='btn-blue-dark' size="small-icon" startIcon="icon-left-arrow" onClick={() => carousel.slidePrev()}></Button>
          <Button color='btn-blue-dark' size="small-icon" startIcon="icon-button-arrow-ight" onClick={() => carousel.slideNext()}></Button>
        </div>
      </div>
      {proyectosDestacados.length === 0 && <div className="loader">Loading...</div>}
      <div id="corousel-edificios-destacados" style={{ position: 'relative', top: SEARCH_OFFSET }}>
        <Carousel
          ref={el => carousel = el}
          itemsToScroll={4}
          itemsToShow={4}
          enableAutoPlay={false}
          showArrows={false}
          autoPlaySpeed={5000}
          className="slider-edificio">
          {sliderItems}
        </Carousel>
      </div>
    </>
  );
}

export default EdificiosDestacados;
