import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  selectChangePasswordData,
  setChangePasswordErrorCount,
  setChangePasswordSuccessCount
} from '../redux/userSlice';


// Hook para mostrar mensaje de éxito/error del thunk changePassword. Devuelve data relacionada
// al thunk.
const useChangePasswordMessage = () => {

  const dispatch = useDispatch();
  const changePasswordData = useSelector(selectChangePasswordData);

  useEffect(() => {
    switch (changePasswordData.status) {
      case 'succeeded':
        if (changePasswordData.successCount > 0) {
          toast.success('Contraseña actualizada!');
          dispatch(setChangePasswordSuccessCount(0));
        }
        break;

      case 'failed':
        if (changePasswordData.errorCount > 0) {
          toast.error(`No se pudo actualizar la contraseña: ${changePasswordData.errorMessage}`)
          dispatch(setChangePasswordErrorCount(0));
        }
        break;

      default:
        break;
    }
  }, [changePasswordData.errorCount, changePasswordData.errorMessage, changePasswordData.status,
  changePasswordData.successCount, dispatch]);


  return changePasswordData;
}

export default useChangePasswordMessage;
