import React from 'react';
import Search from './Search';
import EdificiosDestacados from './EdificiosDestacados';
import Destacadoinfo from './DestacadoInfo';
import useResize from '../../hooks/useResize';
import './styles/destacados.scss';
import { BREAKPOINT_MOBILE } from '../../config/constant';


const Destacados = () => {

  const [rizeWidth] = useResize();

  return (
    <div className="container">
      <section id="destacados" style={{ paddingBottom: '0px' }}>
        <div className="row">
          <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
            <Search rizeWidth={rizeWidth} />
            {rizeWidth > BREAKPOINT_MOBILE && <Destacadoinfo />}
            {rizeWidth > BREAKPOINT_MOBILE && <EdificiosDestacados />}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Destacados;
