import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BACKEND_BASE_URL } from '../config/constant';

const axios = require('axios');


export const INITIAL_FAQS = [];
export const INITIAL_GET_PREGUNTAS_FRECUENTES_DATA_STATUS = 'idle';


const initialState = {
  faqs: INITIAL_FAQS,
  getPreguntasFrecuentesDataStatus: INITIAL_GET_PREGUNTAS_FRECUENTES_DATA_STATUS,
};


export const getPreguntasFrecuentesData = createAsyncThunk('preguntasFrecuentes/getData', async () => {
  try {
    const response = await axios.get(`${BACKEND_BASE_URL}/faq-s`);
    return { response };
  } catch (error) {
    console.log(`Error intentando obtener data de "Preguntas Frecuentes": ${error}`);
    throw error;
  }
});


const preguntasFrecuentesSlice = createSlice({
  name: 'preguntasFrecuentes',
  initialState,
  extraReducers: {
    [getPreguntasFrecuentesData.pending]: (state, action) => {
      state.getPreguntasFrecuentesDataStatus = 'loading';
    },
    [getPreguntasFrecuentesData.fulfilled]: (state, action) => {
      state.getPreguntasFrecuentesDataStatus = 'succeeded';
      const { data } = action.payload.response;

      // Ordenamos por position, de menor a mayor
      state.faqs = data.sort((a, b) => a.position - b.position);
    },
    [getPreguntasFrecuentesData.rejected]: (state, action) => {
      state.getPreguntasFrecuentesDataStatus = 'failed';
    },
  }
});

export default preguntasFrecuentesSlice.reducer;

export const selectFaqs = state => state.preguntasFrecuentes.faqs;
export const selectGetPreguntasFrecuentesDataStatus = state => state.preguntasFrecuentes.getPreguntasFrecuentesDataStatus;
export const selectAllPreguntasFrecuentesData = state => state.preguntasFrecuentes;
