import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import HtmlFaq from '../../components/QuienesSomos/PreguntasFrecuentes/HtmlFaq';
import Search from '../../components/QuienesSomos/PreguntasFrecuentes/Search';
import styles from '../../components/QuienesSomos/PreguntasFrecuentes/styles/PreguntasFrecuentes.module.scss';
import { Icon } from '../../components/ui/index';
import { BREAKPOINT_MOBILE } from '../../config/constant';
import usePreguntasFrecuentesData from '../../hooks/usePreguntasFrecuentesData';
import useResize from '../../hooks/useResize';
import { scrollToTop } from '../../utils';


const QuienesSomosPreguntasFrecuentes = () => {

  const [rizeWidth] = useResize();
  const preguntasFrecuentesData = usePreguntasFrecuentesData();
  const [currentFaq, setCurrentFaq] = useState(null);
  const [tableOfContentsList, setTableOfContentsList] = useState(null);
  const [searchText, setSearchText] = useState('');
  const activeColor = '#EB6B08';

  useEffect(() => {
    scrollToTop();
  }, []);

  const updateSearchText = (newSearchText) => {
    setSearchText(newSearchText);
  }

  const updateCurrentFaq = (faq) => {
    setCurrentFaq(faq);
    scrollToTop();
  }

  // Actualizar table of contents cuando se cargan los FAQs y cambia el FAQ activo
  useEffect(() => {
    if (preguntasFrecuentesData.faqs.length > 0) {
      const toc = preguntasFrecuentesData.faqs.map((faq) => {
        return (
          <li
            key={faq.id}
            onClick={() => setCurrentFaq(faq)}
            style={{ color: faq === currentFaq ? activeColor : '' }}
          >
            <Icon
              name="icon-question" size="18"
              color={faq === currentFaq ? activeColor : ''}
            />{faq.name}
          </li>
        );
      });

      setTableOfContentsList(toc);

      if (!currentFaq) {
        setCurrentFaq(preguntasFrecuentesData.faqs[0]);
      }
    }
  }, [currentFaq, preguntasFrecuentesData.faqs]);


  return (
    <Layout>
      <div className="container">
        <div className={styles.innerContainer}>
          <Search
            text={searchText}
            updateText={updateSearchText}
            updateCurrentFaq={updateCurrentFaq}
            preguntasFrecuentesData={preguntasFrecuentesData}
          />
          <div className={styles.mainContainerFrecuente}>
            {
              rizeWidth > BREAKPOINT_MOBILE &&

              <div className={styles.tableOfContents}>
                <ul>
                  {tableOfContentsList}
                </ul>
              </div>
            }
            <div className={styles.mainContainer}>
              <div className={styles.content}>
                <HtmlFaq faq={currentFaq} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout >
  );
}

export default QuienesSomosPreguntasFrecuentes;
