/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, TextInfo } from '../ui/index';
import Carousel from 'react-elastic-carousel';
import LazyLoad from 'react-lazyload';
import { useSelector } from 'react-redux';
import { selectFilteredProyectos } from '../../redux/proyectosSlice';
import './styles/EdificiosDestacados.scss';
import {
  dataLayerPush,
  getDormitoriosAndBanosString,
  getPlaceUrl,
  getPortadaData
} from '../../utils';


const CAROUSEL_BREAKPOINT = 4;


const EdificiosDestacados = () => {

  const proyectos = useSelector(selectFilteredProyectos);

  const onArrendarClick = (proyectoNombre, position) => {
    dataLayerPush({
      event: 'click',
      bannerName: proyectoNombre,
      section: 'edificios',
      position,
    });
  }

  const sliderItems = proyectos.map((proy, index) => {
    const portadaData = getPortadaData(proy);
    const arrendarLinkUrl = getPlaceUrl(proy);

    // Recorrer tipologías del proyecto para encontrar número mínimo y máximo de dormitorios y baños
    let dormitorios = [];
    let banos = [];

    proy.tipologias.forEach((tipo) => {
      dormitorios.push(tipo.dormitorios);
      banos.push(tipo.banos);
    });

    const bothStrs = getDormitoriosAndBanosString(proy, false);
    const dormitoriosStr = bothStrs.dormitoriosStr;
    const banosStr = bothStrs.banosStr;

    return (
      <div key={proy._id} className="item-slider placeNext">
        <div className="image">
          <LazyLoad throttle={50} height={100}>
            <div className="image" style={{ backgroundImage: `url(${portadaData.url})` }}></div>
          </LazyLoad>
          {!proy.terceros && <span className="alert-place infoPla">Edificio Multifamily</span>}
        </div>
        <div className="info">
          <TextInfo textWeight="bold" color="black" size="16">{proy.proyecto_nombre}</TextInfo>
          <TextInfo iconColor="#FF6D00" iconName="icon-location-pin" size="14" style={{ whiteSpace: 'nowrap' }}>{proy.direccion}</TextInfo>
          <div className="option-edificio">
            <TextInfo iconColor="#848390" iconName="icon-bed" size="14">{dormitoriosStr}</TextInfo>
            <TextInfo iconColor="#848390" iconName="icon-bath" size="14">{banosStr}</TextInfo>
          </div>
          <Link to={arrendarLinkUrl} onClick={() => onArrendarClick(proy.proyecto_nombre, index + 1)}><Button color="btn-orange" fullWidth={true}>ARRENDAR AQUI</Button></Link>
        </div>
      </div>
    );
  });

  let carousels = [];
  const numCarousels = Math.ceil(sliderItems.length / CAROUSEL_BREAKPOINT);
  let start = 0;
  let end = CAROUSEL_BREAKPOINT;

  for (let i = 0; i < numCarousels; i++) {
    carousels.push(
      <div key={i}>
        <Carousel
          itemsToScroll={0}
          itemsToShow={CAROUSEL_BREAKPOINT}
          enableAutoPlay={false}
          showArrows={false}
          autoPlaySpeed={5000}
          className="slider-edificio">
          {sliderItems.slice(start, end)}
        </Carousel>
        {i !== numCarousels - 1 && <><br /><br /></>}
      </div>
    );
    start += CAROUSEL_BREAKPOINT;
    end += CAROUSEL_BREAKPOINT;
  }

  return (
    <>
      <div className="destacados-edicicios" style={{ margin: '0' }}>
      </div>
      <div id="corousel-edificios-destacados">
        {carousels}
      </div>
    </>
  );
}

export default EdificiosDestacados;
