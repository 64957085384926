import React, { useState, useRef } from 'react';
import { TextInfo, Button, Icon } from '../ui/index';
import Carousel from 'react-elastic-carousel';
import './styles/CardEdificioMobile.scss';
import { Link } from 'react-router-dom';
import {
  dataLayerPush,
  getDormitoriosAndBanosString,
  getPlaceUrl,
} from '../../utils';
import { BACKEND_BASE_URL, FEATURE_ENABLED } from '../../config/constant';
import notFound from '../../static/images/notFound.png';
import GastoComunProyecto from '../GastoComunProyecto';
import PrecioMensualProyecto from '../PrecioMensualProyecto';
import CotizarMobileModal from '../Cotizar/layers/CotizarMobileModal';
import { COTIZACION_TYPE } from '../Cotizar/layers/CotizarMobile';
import PrecioMensualEstacionamientos from '../PrecioMensualEstacionamientos';
import PrecioMensualBodegas from '../PrecioMensualBodegas';
import GastoComunEstacionamientos from '../GastoComunEstacionamientos';
import GastoComunBodegas from '../GastoComunBodegas';


const MAX_GALLERY_IMAGES = 6;

const CardEdificioMobile = ({
  proyecto,
  pocasUnidades = false,
  estacionamientoCard = false,
  bodegaCard = false,
}) => {

  const [currentImage, setCurrentImage] = useState(1);
  const [showCotizarMobile, setShowCotizarMobile] = useState(false);
  let totalImage = 1;
  console.log(proyecto)
  const btnRef = useRef();

  const cotizacionType = estacionamientoCard ? COTIZACION_TYPE.ESTACIONAMIENTOS :
    bodegaCard ? COTIZACION_TYPE.BODEGAS : '';

  const eventPreview = (preview) => {
    if (preview === 'PREV') {
      btnRef.current.slidePrev()
    }
    if (preview === 'NEXT') {
      btnRef.current.slideNext()
    }
  }

  // Trackea si el usuario likeó el edificio o no.
  // FIXME: Esto lo tendríamos que levantar y settear en el backend cuando el usuario lo cambia.
  const [liked, setLiked] = useState(false);

  // Corazón "lleno" si like = true, vacío otherwise
  let heartClass = liked ? 'icon-heart-like' : 'icon-heart';

  const likeClicked = () => {
    if (liked) {
      // Se removió el like
      dataLayerPush({
        event: 'click',
        idProyecto: proyecto.proyecto_nombre,
        eventCategory: 'favoritos',
        eventAction: 'click',
        eventLabel: 'quitar de favoritos',
      });
    } else {
      // Se agregó el like
      dataLayerPush({
        event: 'click',
        idProyecto: proyecto.proyecto_nombre,
        eventCategory: 'favoritos',
        eventAction: 'click',
        eventLabel: 'agregar a favoritos',
      });
    }

    setLiked(!liked);
  }

  // FIXME: Obtener esto de algún lado
  const btnPreview = true;
  const gastos = true;

  let proyectoNombreStr = '';
  let proyectoDireccionStr = '';
  let dormitoriosStr = 'dormitorios';
  let banosStr = 'baños';
  let detallesLinkUrl = '';

  let sliderItems = <div className="item-slider"><img src={notFound} alt="sin-foto" /></div>;
  let dptTerceros = false;

  if (proyecto) {
    proyectoNombreStr = proyecto ? proyecto.proyecto_nombre : '';
    proyectoDireccionStr = proyecto ? proyecto.direccion : '';
    dptTerceros = proyecto.terceros;
    const bothStrs = getDormitoriosAndBanosString(proyecto);
    dormitoriosStr = bothStrs.dormitoriosStr;
    banosStr = bothStrs.banosStr;

    detallesLinkUrl = getPlaceUrl(proyecto);

    let galleryName; // nombre del objeto de la galería de Strapi

    if (!estacionamientoCard && !bodegaCard) {
      galleryName = 'galeria';
    }

    if (estacionamientoCard) {
      galleryName = 'estacionamiento_galeria';
    }

    if (bodegaCard) {
      galleryName = 'bodega_galeria';
    }

    totalImage = proyecto[galleryName].length < MAX_GALLERY_IMAGES ? proyecto[galleryName].length : MAX_GALLERY_IMAGES;

    sliderItems = [];

    for (let i = 0; i < totalImage; i++) {
      const galeriaItem = proyecto[galleryName][i];

      if (!galeriaItem.formats.small) {
        totalImage -= 1;
        continue;
      }

      const imageUrl = `${BACKEND_BASE_URL}${galeriaItem.formats.small.url}`;
      const altText = (galeriaItem.alternativeText && galeriaItem.alternativeText !== '') ?
        galeriaItem.alternativeText : `${proyecto.proyecto_nombre}-foto-${i}`;

      sliderItems.push(<div key={imageUrl} className="item-slider"><img src={imageUrl} alt={altText} /></div>);
    }

    if (sliderItems.length === 0) {
      sliderItems = [<div key={notFound} className="item-slider"><img src={notFound} alt="sin-foto" /></div>];
      totalImage = 1;
    }
  }

  const nextImage = () => {
    if (currentImage < totalImage) {
      setCurrentImage(currentImage + 1);
    }
  }

  const previousImage = () => {
    if (currentImage > 1) {
      setCurrentImage(currentImage - 1);
    }
  }

  const onEstacionamientoCardClick = () => {
    setShowCotizarMobile(!showCotizarMobile);
  }


  return (
    <div className={`placeItems`}>
      <div className="slider-image-map">
        {pocasUnidades && <span className="alert-place danger">Pocas unidades disponibles</span>}
        {!dptTerceros && <span className="alert-place info">Edificio Multifamily</span>}
        {FEATURE_ENABLED.LIKES && <Icon name={heartClass} onClick={likeClicked} border="round" background="white" color="#FF6D00" width={26} size={12} />}
        <span className="countImage">{currentImage} / {totalImage}</span>

        {btnPreview &&
          <div className="btn-preview">
            <button className="bt-prev" onClick={() => { eventPreview('PREV'); previousImage() }}></button>
            <button className="bt-next" onClick={() => { eventPreview('NEXT'); nextImage() }}></button>
          </div>
        }

        <Carousel
          ref={btnRef}
          itemsToScroll={1}
          itemsToShow={1}
          enableAutoPlay={false}
          showArrows={false}
          autoPlaySpeed={5000}
          onNextStart={nextImage}
          onPrevStart={previousImage}
          className="slider-galery-image-place"
        >
          {sliderItems}
        </Carousel>

      </div>
      <div className="info">
        <TextInfo textWeight="bold" color="black" size="18">{proyectoNombreStr}</TextInfo>
        <TextInfo iconColor="#FF6D00" className="lugar" iconName="icon-location-pin" size="14">{proyectoDireccionStr}</TextInfo>
        <div className="option-edificio">
          {
            (!estacionamientoCard && !bodegaCard) &&
            <>
              <TextInfo iconColor="#848390" iconName="icon-bed" size="14">{dormitoriosStr}</TextInfo>
              <TextInfo iconColor="#848390" iconName="icon-bath" size="14">{banosStr}</TextInfo>
            </>
          }
        </div>
        <div className="info-option">
          <div className="info-price">
            <span className="price-text">Arriendo desde</span>
            {
              (!estacionamientoCard && !bodegaCard) &&
              <span className="price text-orange"><PrecioMensualProyecto proyecto={proyecto} /></span>
            }
            {
              estacionamientoCard &&
              <span className="price text-orange"><PrecioMensualEstacionamientos proyecto={proyecto} /></span>
            }
            {
              bodegaCard &&
              <span className="price text-orange"><PrecioMensualBodegas proyecto={proyecto} /></span>
            }
          </div>
          {
            !estacionamientoCard && !bodegaCard &&

            <Link to={detallesLinkUrl}>
              <Button color="btn-orange" className="btn bold extra-small">VER DETALLES</Button>
            </Link>
          }
          {
            (estacionamientoCard || bodegaCard) &&

            <>
              <Button onClick={onEstacionamientoCardClick} color="btn-orange" className="btn bold extra-small">VER DETALLES</Button>
              {showCotizarMobile &&
                <CotizarMobileModal
                  proyecto={proyecto}
                  show={showCotizarMobile}
                  close={() => setShowCotizarMobile(false)}
                  cotizacionType={cotizacionType}
                />}
            </>
          }
        </div>
      </div>
      {!dptTerceros &&
        <>
          {
            (gastos && !estacionamientoCard && !bodegaCard) &&
            <div className="gastos">Gastos comunes desde <GastoComunProyecto proyecto={proyecto} /></div>
          }
          {
            (gastos && estacionamientoCard) &&
            <div className="gastos">Gastos comunes desde <GastoComunEstacionamientos proyecto={proyecto} /></div>
          }
          {
            (gastos && bodegaCard) &&
            <div className="gastos">Gastos comunes desde <GastoComunBodegas proyecto={proyecto} /></div>
          }</>
      }
    </div>
  );
};

export default CardEdificioMobile;
