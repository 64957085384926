// Devuelve el primer tipo válido de place que reconocemos. El orden de los tipos es el mismo
// que el orden en el que aparecen en ModalFiltroInteres, i.e., 'restaurant', 'bank', 'pharmacy',
// 'shopping_mall', 'supermarket', 'hospital', 'school', 'gas_station'. Si no se encontró ninguno,
// se devuelve la string vacía ''
export const getPlaceType = (placeData) => {
  for (let i = 0; i < placeData.types.length; i++) {
    const type = placeData.types[i];

    switch(type) {
      case 'restaurant':
      case 'bank':
      case 'pharmacy':
      case 'shopping_mall':
      case 'supermarket':
      case 'hospital':
      case 'school':
      case 'gas_station':
        return type;
      default:
        continue;
    }
  }

  return '';
}

// Devuelve una traducción user-friendly de getPlaceType
export const getFriendlyPlaceType = (placeData) => {
  switch (getPlaceType(placeData)) {
    case 'restaurant':
      return 'Restaurante';

    case 'bank':
      return 'Banco';

    case 'pharmacy':
      return 'Farmacia';

    case 'shopping_mall':
      return 'Centro Comercial';

    case 'supermarket':
      return 'Supermercado';

    case 'hospital':
      return 'Hospital';

    case 'school':
      return 'Educación';

    case 'gas_station':
      return 'Servicentro';

    default:
      return '';
  }
}

export const DEFAULT_CENTER = { lat: -33.448771, lng: -70.663077 };

// Ubicación default que se muestra cuando no nos pasan un objeto de ubicación apropiado
export const DEFAULT_LOCATION = {
  address: 'Santiago de Chile',
  lat: -33.447791,
  lng: -70.653565,
};

export const DEFAULT_ZOOM = 17;

// Longitud (en metros) del radio en el que se buscan places alrededor de la ubicación del proyecto
export const DEFAULT_RADIUS = 1000;

// FIXME: Hack horrendo! Hay que ver por qué se está ejecutando tanto nearbySearchPromise, debe tener
// que ver con algo que se está re-renderizando más veces de lo que debería! Por ahora, para evitar
// hacer demasiados requests, pongo un timeout
let lastExecution = null;

// Tiempo mínimo, en milisegundos, que tiene que pasar entre cada ejecución de nearbySearchPromise
const SEARCH_TIMEOUT_MS = 200;

export const nearbySearchPromise = (mapRef, mapsApiRef, request, callback) => {

  if (!lastExecution) {
    lastExecution = new Date();
  } else {
    const now = new Date();

    // Diferencia entre la última ejecución y el momento actual, en milisegundos
    const diffMs = now - lastExecution;

    // Actualizar última ejecución
    lastExecution = now;

    // Devolver promesa rechazada si no pasó el tiempo suficiente
    if (diffMs < SEARCH_TIMEOUT_MS) {
      return Promise.reject(`You're doing that too much`);
    }
  }

  return new Promise((resolve, reject) => {
    const service = new mapsApiRef.current.places.PlacesService(mapRef.current);

    service.nearbySearch(request, (results, status) => {
      if (status === mapsApiRef.current.places.PlacesServiceStatus.OK) {
        return resolve(callback(results, status));
      } else {
        return reject(`nearbySearchPromise failed: ${status}`);
      }
    });
  });
}
