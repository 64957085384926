import React from 'react';
import './TextArea.scss'
const TextArea = React.forwardRef(({
    className = '',
    children,
    ...other
}, ref) => {
    return (
       <textarea className={`form-input ${className}`} {...other} ref={ref}></textarea>
    );
});

export default TextArea;