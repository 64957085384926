import React, { useState } from 'react';
import Carousel from 'react-elastic-carousel';
import { selectFilteredProyectos } from '../../redux/proyectosSlice';
import { useSelector } from 'react-redux';
import ProyectosSliderCard from './ProyectosSliderCard';


// Slider con los proyectos (filtrados). onItemChange es un callback ejecutado cuando se
// cambia de slide, y tiene la forma fn(proyecto)
const ProyectosSlider = ({ onItemChange, cardType, cardImgClickCallback }) => {

  const [currentSlide, setCurrentSlide] = useState(0);

  const proyectos = useSelector(selectFilteredProyectos);

  const cardPlaceItems = proyectos.map((proyecto, index) => {
    return (
      <ProyectosSliderCard
        key={proyecto.id}
        proyecto={proyecto}
        cardType={cardType}
        cardImgClickCallback={cardImgClickCallback}
      />
    )
  });

  return (
    <div className="list-place-map">
      <Carousel
        itemsToScroll={1}
        itemsToShow={1}
        enableAutoPlay={false}
        showArrows={false}
        autoPlaySpeed={5000}
        onNextStart={(currentItem, nextItem) => {
          if (currentSlide <= proyectos.length - 2) {
            setCurrentSlide(currentSlide + 1);
            onItemChange(nextItem.item.proyecto);
          }
        }}
        onPrevStart={(currentItem, nextItem) => {
          if (currentSlide >= 1) {
            setCurrentSlide(currentSlide - 1);
            onItemChange(nextItem.item.proyecto);
          }
        }}
        onResize={() => {
          const sliderItem = document.querySelector('.__slider-place-map .card-place-map');
          const carousel = document.querySelector('.__slider-place-map .rec-carousel');

          if (sliderItem && carousel) {
            carousel.style.height = `${sliderItem.clientHeight}px`;
          }
        }}
        className="__slider-place-map"
      >
        {cardPlaceItems}
      </Carousel>
    </div>
  );
}

export default ProyectosSlider;
