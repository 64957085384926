import React, { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { CONTACTO_TABS } from '../../config/constant';
import {
  getHorarios,
  selectGetHorariosStatus,
  selectHorarios,
  selectSendMessageErrorMessage,
  selectSendMessageStatus
} from '../../redux/proyectosSlice';
import SocialButtons from '../SocialButtons';
import { Checkbox, Icon } from '../ui/index';
import NecesitasHablar from './NecesitasHablar';
import styles from './styles/ContactoBox.module.scss';
import ContactoWhatsapp from './ContactoWhatsapp';
import HubspotContactForm from '../HubspotContactForm';


const tabs = [
  '¿QUÉ NECESITAS REALIZAR?',
  'HORARIOS DE OFICINA',
];

const ContactoBox = ({ defaultTab = CONTACTO_TABS.FORM }) => {

  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(defaultTab);
  const [checkboxState, setCheckboxState] = useState(Array(tabs.length).fill(false));

  const getHorariosStatus = useSelector(selectGetHorariosStatus);
  const horarios = useSelector(selectHorarios);

  // Obtener horarios si no están en Redux
  useEffect(() => {
    if (getHorariosStatus === 'idle') {
      dispatch(getHorarios());
    }
  }, [dispatch, getHorariosStatus]);

  // Actualizar checkbox tickeada cuando cambia activeTab
  useEffect(() => {
    const newCheckboxState = Array(tabs.length).fill(false);
    newCheckboxState[activeTab] = true;
    setCheckboxState(newCheckboxState);
  }, [activeTab]);

  const onCheckboxChange = (index) => {
    setActiveTab(index);
  }

  // Construir tabs de la caja izquierda
  const tabsItems = tabs.map((tab, index) => {
    let classes = styles.tab;

    if (index === 0) {
      classes += ` ${styles.tabFirst}`;
    }

    if (index === activeTab) {
      classes += ` ${styles.tabActive}`;
    }

    return (
      <div onClick={() => onCheckboxChange(index)} key={nanoid()} className={classes}>
        <div className={styles.tabInnerContainer}>
          {tab}
          <Checkbox checked={checkboxState[index]} onChange={() => onCheckboxChange(index)} position="right" />
        </div>
      </div>
    );
  });

  // Construir horarios
  const horariosItems = horarios.map((horario, index) => {
    return (
      <div key={horario.id}>
        <Icon name="icon-time" className={styles.iconTime} /><span className={styles.locationTextPrimary}>{horario.direccion}  <span className={styles.locationTextSecondary}>({horario.comuna})</span></span>
        <p className={styles.horarioDescripcion}>{horario.descripcion}</p>
        {index !== horarios.length - 1 && <><br /><br /></>}
      </div>
    );
  });

  // Mostrar toast de éxito/error
  const sendMessageStatus = useSelector(selectSendMessageStatus);
  const sendMessageErrorMessage = useSelector(selectSendMessageErrorMessage);

  useEffect(() => {
    if (sendMessageStatus === 'succeeded') {
      toast.success('Mensaje enviado exitosamente!');
    } else if (sendMessageStatus === 'failed' && sendMessageErrorMessage !== '') {
      toast.error(`No se pudo enviar el mensaje!`);
      console.log(`Error al enviar mensaje a Strapi: ${sendMessageErrorMessage}`);
    }
  }, [sendMessageErrorMessage, sendMessageStatus]);

  return (
    <>
    <div className={`container ${styles.topLevelContainer}`}>
      <div className={styles.innerContainer}>
        <div className={styles.leftBox}>
          {tabsItems}
        </div>
        <div className={styles.rightBox}>
          {activeTab === 0 && (
            <HubspotContactForm portalId='39563790' formId='ff9a01b7-88f3-44a9-a774-464d85715fd6' />
            // <iframe
            //   title='form'
            //   src="https://apps1.buildingclerk.cl/postventa/formularios/level/sac.html"
            //   style={{ width: '100%', height: '98%', border: 0 }}
            // >
            // </iframe>
          )}
          {activeTab === 1 &&
            horariosItems
          }
        </div>
        <div className="body-center-right contactHeader">
          <div className="card-slider">
            <h2 className={`card-title-slider ${styles.headerText}`}>Contáctanos.</h2>
          </div>
        </div>
        <div className={styles.socialIconsContainer}>
          <SocialButtons
            youtubeIconTop="7"
            target="mobile-light"
            containerStyle={{
              position: 'relative',
              top: '-20px',
            }}
          />
        </div>
      </div>
    </div>
    </>
  );
}

export default ContactoBox;
