import React from 'react';
import styles from './styles/Experiencia.module.scss';
import SocialButtons from '../../SocialButtons';
import InfoAdicional from './InfoAdicional';
import useResize from '../../../hooks/useResize';
import { BREAKPOINT_MOBILE } from '../../../config/constant';


const Experiencia = ({ nosotrosData }) => {

  const [rizeWidth] = useResize();

  const containerStyle = {
    paddingLeft: '0',
    paddingRight: '0',
  };


  return (
    <div className="container" style={containerStyle}>
      <div className={styles.mainContainer}>
        <div className={styles.imageContainer}>
          {
            rizeWidth <= BREAKPOINT_MOBILE &&

            <div className={styles.veil} />
          }
          <img src={nosotrosData.bannerTop.url} alt={nosotrosData.bannerTop.alt}></img>
        </div>
        <div className={styles.heroTextContainer}>
          <h1>{nosotrosData.header}</h1>
          {
            rizeWidth <= BREAKPOINT_MOBILE &&

            <SocialButtons
              target="mobile"
              youtubeIconTop={7}
              containerStyle={{
                height: 'fit-content',
                width: 'fit-content',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginBottom: '10px',
              }}
            />
          }
          <p>{nosotrosData.subheader}</p>
          {
            rizeWidth > BREAKPOINT_MOBILE &&

            <SocialButtons
              target="mobile"
              youtubeIconTop={7}
            />
          }
        </div>
      </div>
      <InfoAdicional nosotrosData={nosotrosData} />
    </div>
  );
}

export default Experiencia;
