import React from 'react';
import { LEVEL_INSTAGRAM_URL } from '../config/constant';
import { Icon } from './ui/index';


// Componente de icono de Instagram
const InstagramIcon = ({ url = LEVEL_INSTAGRAM_URL, target = "desk" }) => {

  return (
    <>
      {
        target === 'desk' &&
        <a href={url} target="_blank" rel="noopener noreferrer">
          <Icon size="15" name="icon-instagram" />
        </a>
      }
      {
        target === 'mobile' &&
        <a href={url} target="_blank" rel="noopener noreferrer">
          <Icon name="icon-instagram" background="gray-color" color="#fff" border="round" width={30} size="15" />
        </a>
      }
      {
        target === 'mobile-light' &&
        <a href={url} target="_blank" rel="noopener noreferrer">
          <Icon name="icon-instagram" background="gray-blue" color="#2C2B42" border="round" width={30} size="15" />
        </a>
      }
    </>
  );
}

export default InstagramIcon;
