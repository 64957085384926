import React from 'react'
import { Icon } from '../index'
import './Badge.scss';


const Badge = (props) => {

    const { children, ...other} = props;

    return(
        <span className="badge" {...other}>{ children } <Icon size="9px" name="icon-close" /></span>
    );

}

export default Badge;