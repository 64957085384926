import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectAllBeneficiosData,
  getBeneficiosData,
  INITIAL_GET_BENEFICIOS_DATA_STATUS
} from '../redux/beneficiosSlice';


// Hook de datos de la página "Beneficios". Obtiene los datos de la página "Beneficios" si no fueron
// obtenidos previamente.
// Devuelve un objeto con toda la data del slice de Redux correspondiente.
const useBeneficiosData = () => {

  const dispatch = useDispatch();
  const beneficiosData = useSelector(selectAllBeneficiosData);

  useEffect(() => {
    if (beneficiosData.getBeneficiosDataStatus === INITIAL_GET_BENEFICIOS_DATA_STATUS) {
      dispatch(getBeneficiosData());
    }
  }, [beneficiosData.getBeneficiosDataStatus, dispatch]);

  return beneficiosData;
}

export default useBeneficiosData;
