import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProyectos, selectGetProyectosStatus, selectProyectos } from '../redux/proyectosSlice';


// Hook de proyectos. Si los proyectos ya fueron obtenidos, los devuelve.
// Si no fueron obtenidos, los obtiene y devuelve.
const useProyectos = () => {

  const dispatch = useDispatch();
  const proyectos = useSelector(selectProyectos);
  const getProyectosStatus = useSelector(selectGetProyectosStatus);

  // Obtener proyectos si no están en Redux
  useEffect(() => {
    if (getProyectosStatus === 'idle') {
      dispatch(getProyectos());
    }
  }, [dispatch, getProyectosStatus]);

  return proyectos;
}

export default useProyectos;
