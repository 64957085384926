/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import logoColors from '../../../../static/images/logo.png'
import { Button, TextInfo, Icon } from '../../../ui/index';
import CardDetalle from '../CardDetalle';
import ModalSolicitud from '../ayuda/ModalSolicitud';
import Calendar from '../ayuda/Calendar';
import ModalUnidadExtra from './ModalUnidadExtra';
import SeleccionaDepartamento from './SeleccionaDepartamento';
import SeleccionaDatosAdicionales from './SeleccionaDatosAdicionales';
import {
  getConfiguracionReserva,
  getMesesArray,
  selectCurrentConfiguracionReserva,
  selectCurrentDeptoTorreId,
  selectCurrentMesesArray,
  selectGetConfiguracionReservaData,
  selectGetMesesArrayData,
  selectTitular,
  selectReserva
} from '../../../../redux/preevaluacionSlice';

import {
  selectEstacionamientosAuto,
  selectEstacionamientosMoto,
  selectBodegas,
  guardarReserva
} from '../../../../redux/preevaluacionSlice';

import {
  selectApellidoMaterno,
  selectEmail,
  selectLevelClienteId,
  selectNombre,
  selectNumeroDocumento,
  selectTelefono,
  selectTipoDocumento,
  selectTipoPersona
} from '../../../../redux/userSlice';

import {
  BREAKPOINT_MOBILE,
  FEATURE_ENABLED,
  FLUJO_ENTRADA_AUTOFILL,
  PREEVALUACION_MAX_WIDTH,
  TIPO_DOCUMENTO,
  TIPO_ESTADO_CIVIL,
  TIPO_GENERO,
  TIPO_PERSONA,
} from '../../../../config/constant';
import SeleccionaSecundario from './SeleccionaSecundario';
import useResize from '../../../../hooks/useResize';
import FormPersona from './FormPersona';
import useGetMesesArrayMessage from '../../../../hooks/useGetMesesArrayMessage';
import useGetConfiguracionReservaMessage from '../../../../hooks/useGetConfiguracionReservaMessage';
import ReservaStatus from './ReservaStatus';
import { formatPrice, isEmptyObj } from '../../../../utils';


const STEP = {
  DEPTO: 1,
  ESTACIONAMIENTO: 2,
  BODEGA: 3,
  DATOS_ADICIONALES: 4,
};

const dummyData = FLUJO_ENTRADA_AUTOFILL ? {
  nombre: `PrimerNombre SegundoNombre`,
  apellidoPaterno: 'TestApellidoPaterno',
  apellidoMaterno: 'TestApellidoMaterno',
  tipoDocumento: TIPO_DOCUMENTO[0], // DNI, para chequeo más laxo
  numeroDocumento: '8812296-8',
  email: 'emailtest@gmail.com',
  tipoPersona: TIPO_PERSONA[0],
  telefono: '111111111',
  rentaMensual: '111111111',
  nacionalidad: 'Nacionalidad Test',
  genero: TIPO_GENERO[0],
  anoNacimiento: '1979',
  profesion: 'QA Tester',
  actividad: 'QA Testing',
  estadocivil: TIPO_ESTADO_CIVIL[0],
  region: 'Test Region',
  ciudad: 'Test Ciudad',
  comuna: 'Test Comuna',
  direccion: 'Direccion 123',
  numeroPisoDepto: '6º C, piso 15',
  meses: 8,
  mesesArriendoId: '70',
  fechaInicioContrato: new Date(),
  clientePep: false,
} : null;

const PreEvaluacionReserva = ({
  proyecto,
  resumenCotizacion,
  pagar,
  currentCotizacion,
  pendientePago,
  setPreEvaluacionReserva,
  isCotizacion
}) => {

  const [rizeWidth] = useResize();
  const dispatch = useDispatch();
  const titular = useSelector(selectTitular);
  const levelClienteId = useSelector(selectLevelClienteId);

  const [seccionStep, setSeccionStep] = useState(0);
  const [stepForm, setStepForm] = useState(STEP.DEPTO);
  const [showCalendar, setShowCalendar] = useState(false)

  const [formCheck, setFormCheck] = useState(0);
  const [ayudaSolicitud, setAyudaSolicitud] = useState(false);
  const [modalUnidadExtra, setModalUnidadExtra] = useState(false);
  const [reservaChecked, /*  setReservaChecked */] = useState(false);
  const [disponibilidad, setdisponibilidad] = useState(true)
  const currentDeptoTorreId = useSelector(selectCurrentDeptoTorreId);
  const currentMesesArray = useSelector(selectCurrentMesesArray);
  const currentConfiguracionReserva = useSelector(selectCurrentConfiguracionReserva);
  const getMesesArrayData = useSelector(selectGetMesesArrayData);
  const getConfiguracionReservaData = useSelector(selectGetConfiguracionReservaData);
  const reservaState = useSelector(selectReserva);

  // Seleccionar datos del usuario loggeado para pasárselos al form de titular y rellenar datos
  //const nombre = useSelector(selectNombre);
  //const apellidoPaterno = useSelector(selectApellidoPaterno);
  const apellidoMaterno = useSelector(selectApellidoMaterno);
  const nombre = useSelector(selectNombre).split(" ")[0];
  const apellidoPaterno = useSelector(selectNombre).split(" ")[1];
  const tipoDocumento = useSelector(selectTipoDocumento);
  const numeroDocumento = useSelector(selectNumeroDocumento);
  const email = useSelector(selectEmail);
  const tipoPersona = useSelector(selectTipoPersona);
  const telefono = useSelector(selectTelefono);

  const titularInitialData = {
    nombre,
    apellidoPaterno,
    apellidoMaterno,
    tipoDocumento,
    numeroDocumento,
    email,
    tipoPersona,
    telefono,
  };
  // const { configuracionMonto } = currentCotizacion.configuracionReserva

  // Mostrar mensajes del thunk getMesesArray
  useGetMesesArrayMessage();

  // Mostrar mensajes del thunk getConfiguracionReserva
  useGetConfiguracionReservaMessage();

  useEffect(() => {

    /* const torreId = JSON.parse(proyecto.torres[0].torreid);
    console.log(torreId); */
    if (!proyecto || currentDeptoTorreId === -1) {
      return;
    }

    if (currentMesesArray.length === 0 && getMesesArrayData.status === 'idle') {
      dispatch(getMesesArray(currentDeptoTorreId));
    }

    if (isEmptyObj(currentConfiguracionReserva) && getConfiguracionReservaData.status === 'idle') {
      dispatch(getConfiguracionReserva(currentDeptoTorreId));
    }
  }, [currentConfiguracionReserva, currentDeptoTorreId, currentMesesArray.length, dispatch,
    getConfiguracionReservaData.status, getMesesArrayData.status, proyecto]);

  const handleStepForm = (step, acctionInitial = false) => {
    if (step === 2 || step === 3) {
      setModalUnidadExtra(false);
    }

    const nexStep = step + 1;

    if (step === 2 && seccionStep === 2) {
      setFormCheck(step);
      setStepForm(3);
      setSeccionStep(1);
    } else {
      setFormCheck(acctionInitial ? 0 : step);
      setStepForm(acctionInitial ? 1 : nexStep);
      if (acctionInitial) {
        setSeccionStep(0);

      }
    }
  }

  const handleHeaderStep = (step) => {
    if (formCheck >= (step + 1)) {
      handleStepForm(step);
    }
  }

  const helpSolicitud = () => {
    setAyudaSolicitud(!ayudaSolicitud);
  }

  const handleModalUnidadExtra = () => {
    setModalUnidadExtra(!modalUnidadExtra);
  }

  const handleReserva = () => {
    setModalUnidadExtra(false);
    handleStepForm(3);
    setSeccionStep(2);
  }

  const handleCheckReserva = () => {
    setStepForm(2);
    return;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    if (pendientePago) {
      setFormCheck(4);
    }
  }, []);

  const handleProcessData = ({ reserva }) => {

    let idsProductosSecundario = [];

    if (reservaState.estacionamientos.length > 0) {
      for (let index = 0; index < reservaState.estacionamientos.length; index++) {
        const row = reservaState.estacionamientos[index];
        idsProductosSecundario.push(row.id)
      }
    }
    if (reservaState.bodegas.length > 0) {
      for (let index = 0; index < reservaState.bodegas.length; index++) {
        const row = reservaState.bodegas[index];
        idsProductosSecundario.push(row.id)
      }
    }

    reserva.clienteId = levelClienteId
    reserva.cotizacionId = currentCotizacion.id
    reserva.productos = {
      productoPrimarioId: parseInt(reservaState.deptoStrapiObj?.level_productoprimarioid),
      productoSecundarioId: idsProductosSecundario
    }
    dispatch(guardarReserva({ reserva }))
  }

  const handleCalendar = () => {
    setShowCalendar(!showCalendar)
  }


  const numAvales = titular.rentasAvales ? titular.rentasAvales.split(',').length : 1;
  const numComplementos = titular.rentasComplementos ? titular.rentasComplementos.split(',').length : 1;

  // Texto de botón de "Pagar reserva" u "Obtener cotización"
  const buttonText = FEATURE_ENABLED.OBTENER_COTIZACION ? 'OBTENER COTIZACIÓN' : 'PAGAR RESERVA';

  // Sobreescribimos algunos estilos
  const rowStyle = rizeWidth <= BREAKPOINT_MOBILE ? { paddingLeft: '0', paddingRight: '0' } : {};

  // El height tiene que coincidir con el height de _main-container-pre. Por qué no lo cambio directamente
  // en main.scss en vez de sobreescribirlo acá? Porque cambiar CSS global tiende a romper cosas
  // en maneras inesperadas, y es mucho más seguro sobreescribirlo acá y listo.
  const itemFormStyle = { height: rizeWidth <= BREAKPOINT_MOBILE ? '80vh' : '100%' };

  const buttonContainerStyle = rizeWidth <= BREAKPOINT_MOBILE ? { position: 'relative', top: '-200px' } : {};


  let estacionamientosAuto = useSelector(selectEstacionamientosAuto);
  let estacionamientosMoto = useSelector(selectEstacionamientosMoto);
  let bodegas = useSelector(selectBodegas);
  let precioMensual = 0;

  if (isCotizacion > 0) {
    estacionamientosAuto = currentCotizacion.estacionamientos.length
    estacionamientosMoto = currentCotizacion.estacionamientos.length
    bodegas = currentCotizacion.bodegas.length

    let sumaPrecio = 0
    let sumaGastoComunes = 0
    for (let index = 0; index < currentCotizacion.productos.length; index++) {
      const item = currentCotizacion.productos[index];

      sumaPrecio = sumaPrecio + item.productoprecio;
      sumaGastoComunes = sumaGastoComunes + item.productogastocomun

    }

    precioMensual = sumaPrecio + sumaGastoComunes;
  }

  const pagarReservaLink = () => {
    const urlLink = 'https://ww3.servipag.com/pagoenlinea/portal/desktop/public/todas/!ut/p/b1/04_Sj9CPykssy0xPLMnMz0vMAfGjzOKNgs0CLZ0MHQ38zQPcDBx9gs1CzAK9Df0DTYAKIvEo8DanTL-HEXH6DXAARwNC-r30o9Jz8pOAXg3Xj8KrGOQXvApAjgUrwOMaP4_83FT9gtzQ0IjKYE9dR0VFADNEynw!/dl4/d5/L2dBISEvZ0FBIS9nQSEh/?idServicio=47&idBiller=151#'
    window.open(urlLink, '_blank');
  }

  return (
    <>
      <div className={`_preContainer reserva`}>
        <div className="_headerPre">
          <div className="container" style={{ maxWidth: PREEVALUACION_MAX_WIDTH ? PREEVALUACION_MAX_WIDTH : '' }}>
            <div className="row" style={rowStyle}>
              <div className="_header-container">
                <div className="_logo">
                  <a href="/"><img className={`_logo-white`} src={logoColors} alt="Logo Level" /></a>
                </div>
                {
                  rizeWidth > BREAKPOINT_MOBILE &&

                  <div className="_step-pre-evaluacion">
                    <div className={`_itemStep-pre ${formCheck >= STEP.DEPTO - 1 ? 'active' : ''}`}>
                      <span className={`_circle ${formCheck >= STEP.DEPTO ? 'checkedReady' : ''}`}></span>
                    </div>
                    <div className={`_itemStep-pre ${formCheck >= STEP.ESTACIONAMIENTO - 1 ? 'active' : ''}`}>
                      <span className={`_circle ${formCheck >= STEP.ESTACIONAMIENTO ? 'checkedReady' : ''}`}></span>
                    </div>
                    <div className={`_itemStep-pre ${formCheck >= STEP.BODEGA - 1 ? 'active' : ''}`}>
                      <span className={`_circle ${formCheck >= STEP.BODEGA ? 'checkedReady' : ''}`}></span>
                    </div>
                    <div className={`_itemStep-pre ${formCheck >= STEP.DATOS_ADICIONALES - 1 ? 'active' : ''}`}>
                      <span className={`_circle ${formCheck > STEP.DATOS_ADICIONALES ? 'checkedReady' : ''}`}></span>
                    </div>
                  </div>
                }
                <div className="_option-pre">
                  <a onClick={() => setPreEvaluacionReserva(false)}>Guardar y salir</a>
                  <Button onClick={helpSolicitud} color="btn-blue-dark" size="extra-small" endIcon="icon-question">AYUDA</Button>
                </div>
              </div>

              {!reservaChecked ? (
                <>
                  <div className="_main-container-pre">


                    <div className="_item-fijo">
                      <div className="_fijo-titular">
                        <span className="_circle"></span>
                        {seccionStep === 1 ? 'Escoge unidades a reservar' : 'Reserva tu arriendo'}
                      </div>
                      <p className="_info">{seccionStep === 1 ? 'Navega el plano y haz tu selección.' : 'Este es el último paso antes de pagar tu reserva.'}</p>

                      {rizeWidth > <Button color="btn-blue-dark" size="extra-small" className="btn flex justify-center mb-3" fullWidth={true} endIcon="icon-question">¿Cómo elegir unidades?</Button>}

                      {rizeWidth > BREAKPOINT_MOBILE && <CardDetalle proyecto={currentCotizacion} detalle={resumenCotizacion} isCotizacion={isCotizacion} />}

                      {formCheck > 0 &&
                        <div onClick={() => handleStepForm(-1, true)} className="_option-form blue">
                          <TextInfo iconName="icon-left-arrow">ATRÁS</TextInfo>
                        </div>
                      }
                    </div>

                    {/* {
                      rizeWidth <= BREAKPOINT_MOBILE &&

                      <div className="progressbar-container">
                        <h1>ESCOGE UNIDADES A RESERVAR</h1>
                        <p>Navega el plano y haz tu selección.</p>
                      </div>
                    } */}
                    <div className="_item-form" style={itemFormStyle}>

                      {pendientePago && <div className="successContratoEnviadoPagado">
                        <Icon name="icon-tick" color="green" size={60} />
                        <p className="title">Ya se encuentra una reserva vigente para la cotización ingresada</p>
                        <small className="nota mt-1">Presione en el botón de abajo para hacer el pago correspondiente, una vez ingresado coloque el número de RUT.</small>
                        <span className="priceTotal">Total a pagar. <strong>${formatPrice(currentCotizacion?.configuracionReserva?.configuracionMonto)}</strong></span>
                        <Button onClick={pagarReservaLink} color="btn-orange" size="extra-small" fullWidth={false}>IR A PAGAR RESERVA</Button>
                      </div>}

                      {/*  { !disponibilidad && <div className="successContratoEnviadoPagado">
                                <Icon name="icon-question" color="#FF6D00" size={60} />
                                <p className="title mt-1">No hay unidades disponibles.</p>
                                <small className="nota mt-1">Comuniquese con un corredor.</small>
                                <Button onClick={pagarReservaLink}  color="btn-orange" size="extra-small" fullWidth={false}>CORREDORES DISPONIBLES</Button>
                            </div>
                          } */}

                      <div className={`_step-form step-1 ${seccionStep === 0 ? 'active' : ''} `}>
                        {
                          proyecto.departamentos &&
                          <SeleccionaDepartamento
                            setdisponibilidad={setdisponibilidad}
                            disponibilidad={disponibilidad}
                            currentCotizacion={currentCotizacion}
                            proyecto={proyecto}
                            active={stepForm === STEP.DEPTO}
                            checked={formCheck > 0}
                            onHeaderClick={() => handleHeaderStep(0)}
                            nextForm={() => {
                              if (estacionamientosMoto > 0 || estacionamientosAuto > 0 || bodegas > 0) {
                                handleStepForm((estacionamientosMoto > 0 || estacionamientosAuto > 0) ? 1 : ((bodegas > 0) ? 2 : 4));
                              } else {
                                handleReserva()
                              }
                            }} />
                        }

                        {(estacionamientosMoto > 0 || estacionamientosAuto > 0) && <SeleccionaSecundario
                          isCotizacion={isCotizacion}
                          currentCotizacion={currentCotizacion}
                          proyecto={proyecto}
                          type="estacionamiento"
                          active={stepForm === STEP.ESTACIONAMIENTO}
                          checked={formCheck > 1}
                          onHeaderClick={() => handleHeaderStep(1)}
                          nextForm={() => bodegas > 0 ? handleStepForm(2) : handleReserva()} />}

                        {bodegas > 0 && <SeleccionaSecundario
                          isCotizacion={isCotizacion}
                          currentCotizacion={currentCotizacion}
                          proyecto={proyecto}
                          type="bodega"
                          active={stepForm === STEP.BODEGA}
                          checked={formCheck > 2}
                          onHeaderClick={() => handleHeaderStep(2)}
                          nextForm={() => handleReserva()} />}

                        {/* disabled by request */}
                        {false && <SeleccionaDatosAdicionales
                          active={stepForm === STEP.DATOS_ADICIONALES}
                          checked={formCheck > 3}
                          onHeaderClick={() => handleHeaderStep(3)}
                          nextForm={() => handleReserva()} />}


                      </div>

                      <div className={`_step-form step-2 ${seccionStep === 2 ? 'active' : ''}`} style={{ height: '100%' }}>


                        {!showCalendar ? (
                          <FormPersona
                            currentCotizacion={currentCotizacion}
                            proyecto={proyecto}
                            title="Datos del titular"
                            show={true}
                            checked={false}
                            numAvales={numAvales}
                            numComplementos={numComplementos}
                            processData={handleProcessData}
                            initialData={FLUJO_ENTRADA_AUTOFILL ? dummyData : titularInitialData}
                            callback={pagar}
                            handleCalendar={handleCalendar}
                          />
                        ) : (
                          <>
                            <Calendar id="idCalendar" />
                            <Button onClick={handleCalendar} >
                              Confirmar Calendario
                            </Button>
                            {/* <Button onClick={getCalendarData} >
                              GET DATA
                            </Button> */}
                          </>)
                        }
                      </div>

                      {stepForm >= 7 &&
                        <div className="_option-form white" style={buttonContainerStyle}>
                          <Button
                            onClick={handleCheckReserva}
                            color="btn-orange"
                            size="extra-small"
                            className="btn bold"
                            fullWidth={true}
                            disabled={stepForm < 7}
                          >
                            {buttonText}
                          </Button>
                        </div>
                      }
                    </div>
                  </div>
                </>
              ) : (
                <div className="_main-container-reserva">
                  <Icon name="icon-tick" size={54} />
                  <ReservaStatus onPagarContratoClick={pagar} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {ayudaSolicitud && <ModalSolicitud close={helpSolicitud} />}
      {modalUnidadExtra && <ModalUnidadExtra nextPage={handleStepForm} reserva={handleReserva} close={handleModalUnidadExtra} />}
    </>
  );
};

export default PreEvaluacionReserva;
