import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BACKEND_BASE_URL, BREAKPOINT_MOBILE } from '../../config/constant';
import {
  getEjecutivosArriendos, selectEjecutivosArriendos, selectFilteredProyectos,
  selectGetEjecutivosArriendosStatus, selectProyectos,  getHorarios,
  selectGetHorariosStatus,
  selectHorarios,
} from '../../redux/proyectosSlice';
import { capitalizeWholeStr } from '../../utils';
import CardEjecutivo from '../CardEjecutivo';
import ComunasCheckboxes from '../ComunasCheckboxes';
import { ButtonDropdown, Dropdown, Icon } from '../ui/index';
import styles from './styles/EjecutivosArriendo.module.scss';
import notFound from '../../static/images/notFound.png';
import useResize from '../../hooks/useResize';
import stylesCard from '../styles/CardHorario.module.scss';
import ContactoWhatsapp from './ContactoWhatsapp';


const EjecutivosArriendo = () => {

  const [rizeWidth] = useResize();
  const dispatch = useDispatch();
  const ejecutivosArriendos = useSelector(selectEjecutivosArriendos);
  const getEjecutivosArriendosStatus = useSelector(selectGetEjecutivosArriendosStatus);
  const proyectos = useSelector(selectProyectos);
  const filteredProyectos = useSelector(selectFilteredProyectos);

  const getHorariosStatus = useSelector(selectGetHorariosStatus);
  const horarios = useSelector(selectHorarios);

  // Obtener horarios si no están en Redux
  useEffect(() => {
    if (getHorariosStatus === 'idle') {
      dispatch(getHorarios());
    }
  }, [dispatch, getHorariosStatus]);

  useEffect(() => {
    if (getEjecutivosArriendosStatus === 'idle') {
      dispatch(getEjecutivosArriendos());
    }
  }, [dispatch, getEjecutivosArriendosStatus]);

  const passesFiltros = (ejecutivo) => {
    // El array de level_ids tiene que tener al menos uno de los proyectos filtrados
    const level_ids = ejecutivo.edificios.split(',');

    for (let i = 0; i < level_ids.length; i++) {
      for (let j = 0; j < filteredProyectos.length; j++) {
        if (level_ids[i] === filteredProyectos[j].level_id) {
          return true;
        }
      }
    }

    return false;
  }

  const ejecutivosItems = [];

  if (proyectos) {
    for (let i = 0; i < ejecutivosArriendos.length; i++) {
      const ejecutivo = ejecutivosArriendos[i];

      /* if (!passesFiltros(ejecutivo)) {
        continue;
      } */

      let picUrl = notFound;

      if (ejecutivo.foto_perfil && ejecutivo.foto_perfil.url) {
        picUrl = `${BACKEND_BASE_URL}${ejecutivo.foto_perfil.url}`;
      }

      // ejecutivo.edificios es un array de level_id's separados por una coma
      /* const level_ids = ejecutivo.edificios.split(',');
      const edificios = [];
      if(level_ids.length > 0){
        // Construir nombres de los edificios recorriendo los proyectos
        for (let j = 0; j < level_ids.length; j++) {
          for (let k = 0; k < proyectos.length; k++) {
            if (proyectos[k].level_id === level_ids[j]) {
              edificios.push(capitalizeWholeStr(proyectos[k].proyecto_nombre));
            }
          }
        }
      } */

      ejecutivosItems.push(
        <CardEjecutivo key={i} ejecutivo={{
          nombre: ejecutivo.nombre, telefono: ejecutivo.telefono,
          picUrl, email: ejecutivo.email
        }} />
      );
    }
  }

  // Construir horarios
  const horariosItems = horarios.map((horario, index) => {
    return (
  
      <div className={stylesCard.container}>
        <span className={stylesCard.nombre}>{horario.direccion}  <span className={stylesCard.locationTextSecondary}>({horario.comuna})</span></span>
        <span className={stylesCard.telefono}> <Icon name="icon-time" className={styles.iconTime} /> {horario.descripcion}</span>
      </div>
    );
  });

  return (
    <>
    {rizeWidth <= BREAKPOINT_MOBILE && 
      <div className="container" style={{ backgroundColor: '#FFFFFF',  maxWidth: '1410px' }}>
        <div className={styles.innerContainer}>
          <h1 className={styles.headerMobile}>Horarios de oficina</h1>
          <div className={`${styles.ejecutivosContainer} mt-4 mb-1`}>
             {horariosItems}
          </div>
        </div>
      </div>
    }
    <div className="container" style={{ backgroundColor: '#F0F4FA', maxWidth: '1410px' }}>

      <div className={styles.innerContainer}>
        <ContactoWhatsapp />
        {rizeWidth > BREAKPOINT_MOBILE && <h1 className={styles.header}>Nuestros ejecutivos de arriendo</h1>}
        {rizeWidth <= BREAKPOINT_MOBILE && <h1 className={styles.headerMobile}>Nuestros corredores</h1>}
        <div className={styles.searchContainer}>
          {/* <ButtonDropdown color="btn-white-gray" placeholder="Filtrar por zona" fullWidth={true}>
            <Dropdown title="Zonas">
              <ComunasCheckboxes />
            </Dropdown>
          </ButtonDropdown> */}
        </div>
        <div className={styles.ejecutivosContainer}>
          {ejecutivosItems}
        </div>
      </div>
    </div>
    </>
  );
}

export default EjecutivosArriendo;
