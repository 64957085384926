import React, { useEffect } from 'react';
import Layout from '../components/Layout';
import Hero from '../components/Edificios/Hero';
import InfoDetalle from '../components/Edificios/InfoDetalle';
import ProximosEdificios from '../components/Edificios/ProximosEdificios';
import Destacados from '../components/Edificios/Destacados';
import useResize from '../hooks/useResize';
import SliderMobile from '../components/containers/SliderMobile';
import EdificiosDestacadosMobile from '../components/containers/EdificiosDestacadosMobile';
import ProximosEdificiosMobile from '../components/containers/ProximosEdificiosMobile';
import { BREAKPOINT_MOBILE } from '../config/constant';
import 'react-toastify/dist/ReactToastify.css';
import { dataLayerPush, scrollToTop } from '../utils';
import { selectProyectosFuturos } from '../redux/proyectosSlice';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';


const Edificios = ({ match }) => {

  const [rizeWidth] = useResize();
  const pageCurrent = match.path;
  const proyectosFuturos = useSelector(selectProyectosFuturos);

  // Scrollear hasta arriba
  useEffect(() => {
    scrollToTop();
  }, []);

  // Analytics
  const location = useLocation();

  useEffect(() => {
    dataLayerPush({
      event: 'vpv',
      page: location.pathname,
      section: 'edificios',
      url: window.location.href,
    });
  }, [location.pathname]);


  return (
    <Layout pageCurrent={pageCurrent}>
      {rizeWidth > BREAKPOINT_MOBILE && <Hero />}
      <Destacados sizeWidth={rizeWidth} />
      {rizeWidth > BREAKPOINT_MOBILE && proyectosFuturos.length > 0 && <ProximosEdificios />}
      {rizeWidth < BREAKPOINT_MOBILE && <SliderMobile />}
      {rizeWidth < BREAKPOINT_MOBILE && <EdificiosDestacadosMobile />}
      <InfoDetalle />
      {rizeWidth < BREAKPOINT_MOBILE && proyectosFuturos.length > 0 && <ProximosEdificiosMobile />}
    </Layout>
  );
}

export default Edificios;
