import React, { useState } from 'react';
import { TextInfo, Button, Icon } from '../ui/index';
import Carousel from 'react-elastic-carousel';
import { BACKEND_BASE_URL, FEATURE_ENABLED } from '../../config/constant';
import notFound from '../../static/images/notFound.png';
import { dataLayerPush } from '../../utils';
import { useDispatch } from 'react-redux';
import {
  setBodegas,
  setEstacionamientosAuto,
  setEstacionamientosMoto,
  setTipologiaIndex,
  setTipologiaTitulo
} from '../../redux/preevaluacionSlice';
import { setFiltroBanos, setFiltroDormitorios } from '../../redux/proyectosSlice';
import { useHistory } from 'react-router-dom';
import styles from './styles/CardTipologia.module.scss';


const CardTipologia = ({
  proyecto,
  tipologia,
  tipologiaIndex,
  title,
  openFicha,
  alert,
  available,
  result = false }) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const [currentImage, setCurrentImage] = useState(0);
  let totalImage = 1;
  let tituloStr = '';
  let dormitoriosStr = '';
  let banosStr = '';
  let superficieStr = '';
  let itemsSlider = [
    <div key={notFound} className="item-slider img-not-expand">
      <img src={notFound} alt="not-found" style={{ width: '208px', height: '156px' }} />
    </div>
  ];
  let disponible = false;

  // Armar strings y items del slider si nos pasaron tipología
  if (tipologia) {
    if (tipologia.precio_min_amoblado !== -1) {
      disponible = true;
    }

    tituloStr = title;
    dormitoriosStr = tipologia.dormitorios || 1;
    banosStr = tipologia.banos;
    superficieStr = tipologia.superficie;

    if (tipologia.galeria.length > 0) {
      totalImage = tipologia.galeria.length;

      itemsSlider = tipologia.galeria.map((galeriaItem) => {
        const thumbnailUrl = `${BACKEND_BASE_URL}${galeriaItem.formats.thumbnail.url}`;
        const alt = galeriaItem.alternativeText;

        return (
          <div key={galeriaItem.id} className="item-slider img-not-expand">
            <img src={thumbnailUrl} alt={alt} />
          </div>
        );
      });
    }
  }

  // Corazón vacío/lleno cuando el usuario lo clickea
  const [liked, setLiked] = useState(false);
  const heartClass = liked ? 'icon-heart-like' : 'icon-heart';

  const likeClicked = () => {
    if (liked) {
      // Se removió el like
      dataLayerPush({
        event: 'click',
        idProyecto: proyecto.proyecto_nombre,
        eventCategory: 'favoritos',
        eventAction: 'click',
        eventLabel: 'quitar de favoritos',
      });
    } else {
      // Se agregó el like
      dataLayerPush({
        event: 'click',
        idProyecto: proyecto.proyecto_nombre,
        eventCategory: 'favoritos',
        eventAction: 'click',
        eventLabel: 'agregar a favoritos',
      });
    }

    setLiked(!liked);
  }

  const onVerDetallesClick = () => {
    dispatch(setTipologiaTitulo(tipologia.titulo));
    dispatch(setTipologiaIndex(tipologiaIndex));

    // Reiniciar bodegas y estacionamientos de auto y moto
    dispatch(setBodegas(null));
    dispatch(setEstacionamientosAuto(null));
    dispatch(setEstacionamientosMoto(null));

    openFicha();
  }

  const onTipologiasSimilaresClick = () => {
    dispatch(setFiltroDormitorios(tipologia ? tipologia.dormitorios : null));
    dispatch(setFiltroBanos(tipologia ? tipologia.banos : null));
    history.push('/result');
  }

  return (
    <div className={`placeItems mb-3`}>
      <div className="slider-image-map">
        {!available && <div className="avaliable"><span>No disponible</span></div>}
        {alert && <span className="alert-place danger">Muy solicitado</span>}
        {FEATURE_ENABLED.TOUR_360 && available && <span className="info-360">360<sup>o</sup></span>}
        {FEATURE_ENABLED.LIKES && available && <Icon name={heartClass} onClick={likeClicked} border="round" background="white" color="#FF6D00" width={26} size={12} />}
        {available && <span className="countImage">{currentImage + 1} / {totalImage}</span>}

        <Carousel
          itemsToScroll={1}
          itemsToShow={1}
          enableAutoPlay={false}
          showArrows={false}
          autoPlaySpeed={5000}
          onNextStart={(currentItem, nextItem) => {
            if ((currentImage + 1) < totalImage) {
              setCurrentImage(currentImage + 1);
            }
          }}
          onPrevStart={(currentItem, nextItem) => {
            if (currentImage > 0) {
              setCurrentImage(currentImage - 1);
            }
          }}
          className="slider-galery-image-place"
        >
          {itemsSlider}
        </Carousel>

      </div>
      <div className="info">
        <TextInfo textWeight="bold" color="black" size="18"><div dangerouslySetInnerHTML={{ __html: tituloStr }}>
        </div></TextInfo>
        <div className="option-edificio margi-none">
          <TextInfo iconColor="#848390" iconName="icon-bed" size="14">{dormitoriosStr}</TextInfo>
          <TextInfo iconColor="#848390" iconName="icon-bath" size="14">{banosStr}</TextInfo>
          <TextInfo iconColor="#848390" className="ml-1" iconName="icon-full-screen" size="12">Sup. Total. {superficieStr} m<sup>2</sup></TextInfo>
        </div>
        <div className="info-option boder-none" style={{ flexDirection: 'column' }}>
          {available ? (
            <>
              <Button onClick={onVerDetallesClick} color={`${result ? 'btn-blue' : 'btn-orange'}`}
                className="btn bold mt-1" endIcon="icon-button-arrow-ight" alingIcon="right"
                fullWidth={true} disabled={!disponible}>
                {result ? 'VER DEPARTAMENTO' : disponible ? 'VER DETALLES' : 'NO DISPONIBLE'}
              </Button>
              {
                !disponible &&
                <>
                  <br />
                  <span
                    onClick={onTipologiasSimilaresClick}
                    className={styles.tipologiasDisponibles}
                  >
                    Ver tipologías similares
                  </span>
                </>
              }
            </>
          ) :
            (
              <Button onClick={openFicha} color="btn-blue" className="btn bold mt-1" fullWidth={true} >CREAR ALERTA</Button>
            )}
        </div>

      </div>
    </div>
  );
};

export default CardTipologia;
