import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectBannersDesk,
  selectBannersMobile,
  selectGetHomeDataStatus,
  INITIAL_BANNERS_DESK,
  INITIAL_GET_HOME_DATA_STATUS,
  getHomeData,
 } from '../redux/homeSlice';


// Hook de datos de la home. Obtiene los datos de la home si no fueron obtenidos previamente.
// Devuelve un objeto de la forma { bannersDesk, bannersMobile }, que corresponde al estado
// de Redux.
const useHomeData = () => {

  const dispatch = useDispatch();
  const bannersDesk = useSelector(selectBannersDesk);
  const bannersMobile = useSelector(selectBannersMobile);
  const getHomeDataStatus = useSelector(selectGetHomeDataStatus);

  useEffect(() => {
    if (bannersDesk === INITIAL_BANNERS_DESK && getHomeDataStatus === INITIAL_GET_HOME_DATA_STATUS) {
      dispatch(getHomeData());
    }
  }, [bannersDesk, dispatch, getHomeDataStatus]);

  return { bannersDesk, bannersMobile };
}

export default useHomeData;
