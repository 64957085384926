import React from 'react';
import { useSelector } from 'react-redux';
import { selectFilteredTipologias, selectFiltroAmoblado } from '../redux/proyectosSlice';
import { getTipologiaPrecioMensual } from '../utils';
import PrecioMensualTipologia from './PrecioMensualTipologia';


// Devuelve el precio mensual de un proyecto, i.e. el menor precio de las tipologías
// que pasan el filtro.
const PrecioMensualProyecto = ({ proyecto }) => {

  const filteredTipologias = useSelector(selectFilteredTipologias(proyecto ? proyecto.id : null));
  const filtroAmoblado = useSelector(selectFiltroAmoblado);
  let tipologia = null;
  let precioMensual = Number.MAX_SAFE_INTEGER;
  let cheapestTipoIndex = -1;

  const filteredTipologiasDisponibles = filteredTipologias.filter(tipologia => tipologia.precio_min_amoblado !== -1)

  for (let i = 0; i < filteredTipologiasDisponibles.length; i++) {
    const currTipologia = filteredTipologiasDisponibles[i];
    const currPrecioMensual = getTipologiaPrecioMensual(currTipologia, filtroAmoblado);

    if (currPrecioMensual !== -1 && currPrecioMensual < precioMensual) {
      precioMensual = currPrecioMensual;
      cheapestTipoIndex = i;
    }
  }

  if (cheapestTipoIndex === -1) {
    cheapestTipoIndex = 0;
  }

  tipologia = filteredTipologiasDisponibles[cheapestTipoIndex];


  return (
    <PrecioMensualTipologia tipologia={tipologia} />
  );
}

export default PrecioMensualProyecto;
