import React, { useState } from 'react';
import { Title, TextInfo, Button } from './ui/index';
import './styles/CardPlaceBarrioMobile.scss';
import CardAlerta from './containers/CardAlerta';
import PlaceSimilaresMobile from './containers/PlaceSimilaresMobile';
import ModalFiltroInteres from './containers/ModalFiltroInteres';
import Map from './Map/index';


const CardPlaceBarrioMobile = ({ proyecto }) => {

  const [showFiltroInteres, setFiltroInteres] = useState('');

  const openFiltroInteres = () => {
    setFiltroInteres('show');
  }

  const closeFiltroInteres = () => {
    setFiltroInteres('');
  }

  const comuna = proyecto ? proyecto.comuna : '';
  const descripcion = proyecto ? proyecto.descripcion : '';
  const cercanoMetro = proyecto ? proyecto.cercano_metro : '';
  const cercanoMisc = proyecto ? proyecto.cercano_misc : '';


  return (
    <>
      <div className="__place-barrio">
        <div className="container">
          <div className="row">
            <Title className="muli" size="20" marginBotton={30}>{comuna}</Title>
            <p className="_parrafo">{descripcion}</p>
            <div className="_info-list">
              {
                cercanoMetro && cercanoMetro !== '' &&
                <TextInfo iconName="icon-subte" iconSize="16" color="black">Cercano a Metro {cercanoMetro}</TextInfo>
              }
              {
                cercanoMisc && cercanoMisc !== '' &&
                <TextInfo iconName="icon-arrow-side" color="black">Cercano a {cercanoMisc}</TextInfo>
              }
            </div>

            <CardAlerta icon="icon-tick" iconColor="#58BF81" typeAlert="default" text="<strong>El 85% de los residentes destacan la seguridad del barrio.</strong>" />
            <Button onClick={openFiltroInteres} color="btn-outline-orange" fullWidth={true} endIcon="icon-adjust" style={{ marginBottom: '20px' }}>Filtrar puntos de interés</Button>

            <div>
              <Map proyecto={proyecto} />
            </div>
          </div>
        </div>
      </div>
      <PlaceSimilaresMobile proyecto={proyecto} />
      <ModalFiltroInteres show={showFiltroInteres} close={closeFiltroInteres} />
    </>
  );
};

export default CardPlaceBarrioMobile;
