import React from 'react';
import { useSelector } from 'react-redux';
import { BREAKPOINT_MOBILE } from '../../config/constant';
import useResize from '../../hooks/useResize';
import { selectProyectosFuturos } from '../../redux/proyectosSlice';
import { Icon } from '../ui/index';
import './styles/InfoDetalle.scss';


const InfoDetalle = () => {

  const [rizeWidth] = useResize();
  const proyectosFuturos = useSelector(selectProyectosFuturos);
  let height = '';

  // Si no hay proyectos futuros tenemos que compensar un poco la altura para que el footer
  // no tape parcialmente este componente
  if (rizeWidth < BREAKPOINT_MOBILE && proyectosFuturos.length === 0) {
    height = '210px';
  }

  return (
    <div className="container">
      <section className="info-detalle" style={{ height }}>
        <div className="row">
          <div className="itemsDetalle">
            <div className="itemDetalle">
              <Icon name="icon-agreement" color="#FF6D00" size={45} />
              <h2>Un arriendo<br />profesional</h2>
            </div>
            <div className="itemDetalle">
              <Icon name="icon-edificio" color="#FF6D00" size={45} />
              <h2>Gastos<br />comunes fijos</h2>
            </div>
            <div className="itemDetalle">
              <Icon name="icon-seguridad" color="#FF6D00" size={45} />
              <h2>Seguridad<br />24 horas</h2>
            </div>
            <div className="itemDetalle">
              <Icon name="icon-pool-fill" color="#FF6D00" size={45} />
              <h2>Espacios<br />comunes</h2>
            </div>
            <div className="itemDetalle">
              <Icon name="icon-location-outline" color="#FF6D00" size={45} />
              <h2>Ubicación<br />estratégica</h2>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default InfoDetalle;
