import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getBarrioDataAll, selectGetBarrioDataStatus, selectBarrios } from '../redux/proyectosSlice';


// Hook de proyectos. Si los proyectos ya fueron obtenidos, los devuelve.
// Si no fueron obtenidos, los obtiene y devuelve.
const useBarrios = () => {

  const dispatch = useDispatch();
  const barrios = useSelector(selectBarrios);
  const barrioStatus = useSelector(selectGetBarrioDataStatus);

  // Obtener proyectos si no están en Redux
  useEffect(() => {
    if (barrioStatus === 'idle') {
      dispatch(getBarrioDataAll());
    }
  }, [dispatch, barrioStatus]);

  return barrios;
}

export default useBarrios;
