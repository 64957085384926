import React, { useState } from 'react';
import Carousel, { consts } from 'react-elastic-carousel';
import { useSelector } from 'react-redux';
import { selectProyectos } from '../../../redux/proyectosSlice';
import { Button, TextInfo } from '../../ui/index';
import styles from './styles/EdificiosSlider.module.scss';
import notFound from '../../../static/images/notFound.png';
import { getPortadaData } from '../../../utils';
import useResize from '../../../hooks/useResize';
import { BREAKPOINT_MOBILE } from '../../../config/constant';


const EdificiosSlider = () => {

  const [rizeWidth] = useResize();
  const proyectos = useSelector(selectProyectos);
  const [index, setIndex] = useState(0);

  const proyectoNombre = proyectos.length > 0 ? proyectos[index].proyecto_nombre : '';
  const proyectoComuna = proyectos.length > 0 ? proyectos[index].comuna : '';

  let slides = [
    <div className={styles.slide}>
      <img src={notFound} alt="not-found" />
    </div>
  ];

  if (proyectos.length > 0) {
    slides = proyectos.filter((item) => item.hasOwnProperty('portada')).map((proyecto, index) => {

      const portadaData = getPortadaData(proyecto);
      return (
        <div className={styles.slide}>
          <img src={portadaData.url} alt={portadaData.alt} />
        </div>
      );

    });
  }

  return (
    <>
      <Carousel
        itemsToScroll={1}
        itemsToShow={1}
        enableAutoPlay={false}
        showArrows={true}
        renderArrow={({ type, onClick, isEdge }) => {
          let prevArrowRight, nextArrowRight;

          if (rizeWidth > BREAKPOINT_MOBILE) {
            prevArrowRight = '10%';
            nextArrowRight = '2%';
          } else {
            prevArrowRight = '17%';
            nextArrowRight = '2%';
          }

          const boxArrow = {
            position: 'absolute',
            bottom: '7%',
            right: type === consts.PREV ? prevArrowRight : nextArrowRight,
            backgroundColor: '#4E4D5D',
            zIndex: '10',
            borderRadius: '5px',
          };

          const startIcon = type === consts.PREV ? 'icon-left-arrow' : 'icon-button-arrow-ight';

          return (
            <div style={boxArrow}>
              <Button onClick={onClick} color='btn-blue-dark' size="small-icon" startIcon={startIcon}></Button>
            </div>
          );
        }}
        renderPagination={() => <div></div>}
        autoPlaySpeed={5000}
        className={styles.customCarousel}
        onNextStart={(currentItem, nextItem) => {
          if (index < proyectos.length - 1) {
            setIndex(index + 1);
          }
        }}
        onPrevStart={(currentItem, nextItem) => {
          if (index >= 1) {
            setIndex(index - 1);
          }
        }}
      >
        {slides}
      </Carousel>
      <div className={styles.sliderTransparency}>
        <h2>{proyectoNombre}</h2>
        <div className={styles.textInfoWrapper}>
          <TextInfo color="white" iconName="icon-location-pin" iconColor="#EB6B08">{proyectoComuna}</TextInfo>
        </div>
      </div>
    </>
  );
}

export default EdificiosSlider;
