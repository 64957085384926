import React from 'react'
import PropTypes from 'prop-types'
import './Icon.scss'

const Icon = props => {
    const { 
        size = '', 
        color= '', 
        name = '',
        border = 'default',
        background = '',
        width = '',
        className = '',
        alingIcon = '',
        iconStyle,
        ...other
    } = props;

    let stylesIcon = {
        color: color,
        fontSize: size + 'px',
        ...iconStyle,
    };

    let stylesRound = {
       width: width + 'px',
       height: width + 'px',
       float: alingIcon
    };



    return(
        <div className={`icon${border} ${background}`} style={stylesRound} {...other}>
            <i className={`icon ${name} ${className}`} style={stylesIcon}></i>
        </div>
    );

}

Icon.propTypes = {
    name: PropTypes.string,
    color: PropTypes.string,
    border: PropTypes.string,
    background: PropTypes.string,
    width: PropTypes.number
    
}

export default Icon;