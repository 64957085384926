import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';
import { BACKEND_BASE_URL } from '../config/constant';
import defaultDeskBanner from '../static/images/header_latest_fixed.png';
import defaultMobileBanner from '../static/images/header_mobile.png';
const axios = require('axios');


export const INITIAL_BANNERS_DESK = [
  { url: defaultDeskBanner, alt: 'default-banner-desk', id: nanoid() },
  { url: defaultDeskBanner, alt: 'default-banner-desk', id: nanoid() },
];

export const INITIAL_BANNERS_MOBILE = [
  { url: defaultMobileBanner, alt: 'default-banner-mobile', id: nanoid() },
  { url: defaultMobileBanner, alt: 'default-banner-mobile', id: nanoid() },
];

export const INITIAL_GET_HOME_DATA_STATUS = 'idle';


const initialState = {
  bannersDesk: INITIAL_BANNERS_DESK,
  bannersMobile: INITIAL_BANNERS_MOBILE,
  getHomeDataStatus: INITIAL_GET_HOME_DATA_STATUS,
};


export const getHomeData = createAsyncThunk('home/getData', async () => {
  try {
    const response = await axios.get(`${BACKEND_BASE_URL}/home`);
    return { response };
  } catch (error) {
    console.log(`Error intentando obtener data de la home: ${error}`);
    throw error;
  }
});


const homeSlice = createSlice({
  name: 'home',
  initialState,
  extraReducers: {
    [getHomeData.pending]: (state, action) => {
      state.getHomeDataStatus = 'loading';
    },
    [getHomeData.fulfilled]: (state, action) => {
      state.getHomeDataStatus = 'succeeded';

      const { data } = action.payload.response;

      if (data.banners_desk && data.banners_desk.length > 0) {
        state.bannersDesk = data.banners_desk;

        // Crear una nueva variable "alt" a partir de la variable que viene de Strapi "alternativeText"
        // y prefijar las urls con la URL de Strapi. La idea es que los componentes clientes del slice
        // puedan decir bannerDesk.url o bannerDesk.alt de una sin preocuparse de *dónde* viene la imagen,
        // ya que para las imágenes de Strapi hay que prefijar BACKEND_BASE_URL, pero para las imágenes
        // locales default importadas con Webpack, no.
        for (let i = 0; i < state.bannersDesk.length; i++) {
          const currBannerDesk = state.bannersDesk[i];
          currBannerDesk.alt = currBannerDesk.alternativeText;
          currBannerDesk.url = `${BACKEND_BASE_URL}${currBannerDesk.url}`;
          currBannerDesk.id = nanoid();
        }
      }

      if (data.banners_mobile && data.banners_mobile.length > 0) {
        state.bannersMobile = data.banners_mobile;

        for (let i = 0; i < state.bannersMobile.length; i++) {
          const currBannerMobile = state.bannersMobile[i];
          currBannerMobile.alt = currBannerMobile.alternativeText;
          currBannerMobile.url = `${BACKEND_BASE_URL}${currBannerMobile.url}`;
          currBannerMobile.id = nanoid();
        }
      }
    },
    [getHomeData.rejected]: (state, action) => {
      state.getHomeDataStatus = 'failed';
    },
  }
});

export default homeSlice.reducer;

export const selectBannersDesk = state => state.home.bannersDesk;
export const selectBannersMobile = state => state.home.bannersMobile;
export const selectGetHomeDataStatus = state => state.home.getHomeDataStatus;
