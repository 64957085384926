import React, { useEffect, useRef, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { StaticGoogleMap, Marker, Path, } from 'react-static-google-map';
import styles from './styles/Map.module.scss';
import { GOOGLE_MAPS_API_KEY, } from '../../config/constant';
import { useSelector } from 'react-redux';
import { selectPlaces } from '../../redux/proyectosSlice';
import LocationPin from './LocationPin';
import {
  DEFAULT_LOCATION,
  DEFAULT_RADIUS,
  DEFAULT_ZOOM,
  nearbySearchPromise,
  getPlaceType,
} from './mapUtils';


// Componente de mapa usado en la página de /place. Se le pasa un objeto de ubicación 'location'
// { address, lat, lng } para ubicar un pin con el texto address en el lugar apropiado del mapa,
// y ademaś el mapa muestra el punto de interés que el usuario seleccionó con el widget
// "PUNTOS DE INTERÉS" (restaurantes, farmacias, etc.).
// `updatePlacesData` es un callback opcional que es llamado cada vez que se actualiza la data
// de los lugares de interés.
const Map = ({ location, height = '', updatePlacesData = null }) => {
  const [locationState, setLocationState] = useState(DEFAULT_LOCATION);
  const mapRef = useRef();
  const mapsApiRef = useRef();

  const places = useSelector(selectPlaces);
  const [placesPins, setPlacesPins] = useState(null);

  // Actualizar la ubicación cuando nos llega el prop
  useEffect(() => {
    if (location) {
      setLocationState(location);
    }
  }, [location]);

  // Actualizar los pines cuando cambia el punto de interés
  useEffect(() => {
    if (places.length > 0 && mapRef.current && mapsApiRef.current) {
      // Tenemos que usar promesas porque Google deprecó la versión de "nearbySearch" que
      // tomaba un array en type, así que ahora hay que hacer un request separado por
      // cada type distinto
      const promiseArray = [];

      for (let i = 0; i < places.length; i++) {
        const request = {
          location: locationState,
          radius: DEFAULT_RADIUS,
          type: places[i],
        };

        // console.log('places[i]', places[i]);

        promiseArray.push(nearbySearchPromise(mapRef, mapsApiRef, request, (results, status) => results));
      }

      Promise
        .all(promiseArray)
        .then((results) => {
          const placesData = [];

          for (let i = 0; i < results.length; i++) {
            placesData.push(...results[i]);
          }

          if (updatePlacesData) {
            updatePlacesData(placesData);
          }

          const newPlacesPin = placesData.map((placeData, index) => {
            const lat = placeData.geometry.location.lat();
            const lng = placeData.geometry.location.lng();
            const type = getPlaceType(placeData);
            const name = placeData.name;
            const address = placeData.vicinity.split(',')[0];

            return (
              <LocationPin
                key={index}
                lat={lat}
                lng={lng}
                icon={type}
                name={name}
                address={address}
              />
            );
          });

          setPlacesPins(newPlacesPin);
        })
        .catch((err) => console.log(`Error obteniendo places: ${err}`));
    } else {
      setPlacesPins(null);
    }
  }, [places, locationState, mapsApiRef, updatePlacesData]);

  return (
    <div className={styles.map}>
      <div className={styles.googleMap} style={{ height }}>
        {/* <GoogleMapReact
          onGoogleApiLoaded={({ map, maps }) => {
            mapRef.current = map;
            mapsApiRef.current = maps;
          }}
          yesIWantToUseGoogleMapApiInternals={true}
          bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY, libraries: ['places'] }}
          center={locationState}
          defaultZoom={DEFAULT_ZOOM}
          distanceToMouse={() => { }}
        >
          <LocationPin
            lat={locationState.lat}
            lng={locationState.lng}
            text={locationState.address}
            icon="proyecto"
          />
          {placesPins}
        </GoogleMapReact> */}
        <StaticGoogleMap size="600x600" apiKey={GOOGLE_MAPS_API_KEY}>
          <Marker
            iconURL={window.location.origin + '/pinmap.png'}
            location={{ lat: locationState.lat, lng: locationState.lng }}
            color="orange"
            // label="M"
          />
        </StaticGoogleMap>
      </div>
    </div>
  );
}

export default Map;
