import React, { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import {
  BREAKPOINT_MOBILE,
  DESK_SCROLL_HEADER_OFFSET,
  MOBILE_SCROLL_HEADER_OFFSET
} from '../../../config/constant';
import useResize from '../../../hooks/useResize';
import { scrollTo } from '../../../utils';
import SearchInput from '../../SearchInput';
import styles from './styles/Search.module.scss';


const Search = ({ text, updateText, updateCurrentFaq, preguntasFrecuentesData }) => {

  const [rizeWidth] = useResize();
  const [matchesList, setMatchesList] = useState(null);
  const width = rizeWidth > BREAKPOINT_MOBILE ? '50%' : '80%';
  const scrollOffset = rizeWidth > BREAKPOINT_MOBILE ? DESK_SCROLL_HEADER_OFFSET : MOBILE_SCROLL_HEADER_OFFSET;

  // Buscar matches en los elementos que tengan un data-tag.
  useEffect(() => {
    if (text === '') {
      setMatchesList(null);
      return;
    }

    const elements = document.querySelectorAll("[data-tag]");

    const matches = [];

    for (let i = 0; i < elements.length; i++) {
      const currEl = elements[i];

      if (currEl.dataset.tag.startsWith(text.trim().toLowerCase())) {
        matches.push(currEl);
      }
    }

    const onListClick = (el) => {
      scrollTo(el, scrollOffset);
      setMatchesList(null);
      updateText('');
    }

    if (matches.length > 0) {
      setMatchesList(
        <div className={styles.matchesList} style={{ width }}>
          <ul>
            {matches.map((match, index) => <li onClick={() => onListClick(match)} key={nanoid()}>{match.dataset.tag}</li>)}
          </ul>
        </div>
      );
    } else {
      setMatchesList(null);
    }
  }, [scrollOffset, text, updateText, width]);


  return (
    <>
      <div className={styles.topContainer}>
        <div className={styles.searchContainer}>
          {rizeWidth > BREAKPOINT_MOBILE && <h1>¿Cómo podemos ayudarte?</h1>}
          <div style={{ width }}>
            <SearchInput
              text={text}
              updateText={updateText}
              updateCurrentFaq={updateCurrentFaq}
              preguntasFrecuentesData={preguntasFrecuentesData}
            />
            {matchesList}
          </div>
        </div>
      </div>
    </>
  );
}

export default Search;
