/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import {
  Icon,
  Button,
  Modal,
} from './ui/index';
import {
  BREAKPOINT_MOBILE,
} from '../config/constant';
import 'react-toastify/dist/ReactToastify.css';
import './styles/ModalLogin.scss';
import ModalResetPassword from './ModalResetPassword';
import useResize from '../hooks/useResize';
import { serviceWorkerLoadNewContent } from '../utils';


const TEXT = '¡Hay nuevo contenido en el sitio web! Puedes continuar navegando y verlo en tu próxima visita, o recargar la página para que puedas mirar el nuevo contenido ahora.';


const ModalNuevoContenido = ({ waitingWorker, close }) => {

  const [rizeWidth] = useResize();
  const [showModalResetPassword, setShowModalResetPassword] = useState(false);

  const onContinuarClick = () => {
    close();
  }

  const onRecargarClick = () => {
    serviceWorkerLoadNewContent(waitingWorker);
    close();
  }

  const closeModalResetPassword = () => {
    setShowModalResetPassword(false);
  }

  let top = '';

  const buttonContainerStyle = {
    display: 'flex',
    marginBottom: '20px',
    gap: '10px',
  };

  if (rizeWidth < BREAKPOINT_MOBILE) {
    top = '30px';

    buttonContainerStyle.flexDirection = 'column';
  }

  const textStyle = {
    marginBottom: '20px',
    color: '#2C2B42',
  };


  return (
    <>
      <Modal show={waitingWorker} bgClose={false} className="modalLoginHome">
        <div className="modal-content modal-login fade" style={{ top }}>
          <div className="header-modal">
            <Icon onClick={close} name="icon-close" size="14" />
          </div>
          <div className="body-modal">
            <div className="form-content">
              <p style={textStyle}>{TEXT}</p>
              <div style={buttonContainerStyle}>
                <Button onClick={onContinuarClick} className="btn btn-submit" color='btn-orange' size="small">CONTINUAR</Button>
                <Button onClick={onRecargarClick} className="btn btn-submit" color='btn-orange' size="small">RECARGAR</Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <ModalResetPassword show={showModalResetPassword} close={closeModalResetPassword} />
    </>
  );
};

export default ModalNuevoContenido;
