/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './styles/CardPlaceDepartamento.scss';
import styles from './styles/CardPlaceDepartamentoCustom.module.scss';
import { toast } from 'react-toastify';
import { getInvalidEmailString, isValidEmail } from '../../validation';
import { sendContactame } from '../../redux/userSlice';

import {
  Title,
  Button,
  TextInfo,
  Icon,
  ToogleSwich,
  TitleFiltro,
  Input,
  TextArea
} from '../ui/index';
import notFound from '../../static/images/notFound.png';
import ModalGaleria from './ModalGaleria';
import ModalFiltroPlace from './ModalFiltroPlace';
import {
  INITIAL_PREEV_ESTACIONAMIENTOS_AUTO,
  selectBodegas,
  selectEstacionamientosAuto,
  selectTipologiaIndex,
  setAmoblado as reduxSetAmoblado,
  setBodegas,
  setEstacionamientosAuto,
  setEstacionamientosMoto,
  setTipologiaIndex as reduxSetTipologiaIndex,
  setTipologiaTitulo
} from '../../redux/preevaluacionSlice';
import {
  BACKEND_BASE_URL,
  FEATURE_ENABLED,
  TOUR_360_STR,
} from '../../config/constant';
import InputFiltro from '../ui/InputFiltro/InputFiltro';
import {
  INITIAL_BODEGAS,
  INITIAL_SUPERFICIE_MAX,
  INITIAL_SUPERFICIE_MIN,
  selectFiltroBanos,
  selectFiltroDormitorios,
  selectFiltroSuperficieMax,
  selectFiltroSuperficieMin,
  setFiltroBanos,
  setFiltroDormitorios,
} from '../../redux/proyectosSlice';
import { getProyectoTourUrl, pluralize } from '../../utils';
import { useHistory } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import deep from 'deep';


export const DISPONIBILIDAD = {
  NO_DISPONIBLE: 0,
  POCAS_UNIDADES_DISPONIBLES: 1,
  DISPONIBLE: 2,
};


const CardPlaceDepartamento = ({ proyecto, disponibilidad, cheapestDepto, openShared, cotizar }) => {

  const tipologiasNew = proyecto.tipologias.filter((item) => item.precio_min_amoblado !== -1)
  const proyectoNew = deep.clone(proyecto)
  let disponible = true;
  if (tipologiasNew.length > 0) {
    proyectoNew.tipologias = tipologiasNew
  } else {
    disponible = false
  }

  const dispatch = useDispatch();
  const history = useHistory();
  const filtroDormitorios = useSelector(selectFiltroDormitorios);
  const filtroBanos = useSelector(selectFiltroBanos);
  const filtroSuperficieMin = useSelector(selectFiltroSuperficieMin);
  const filtroSuperficieMax = useSelector(selectFiltroSuperficieMax);
  const tipologiaIndexRedux = useSelector(selectTipologiaIndex);

  const user = useSelector(store => store.user);

  //Datos para contcato
  const [emailContacto, setEmailContacto] = useState(user.email);
  const [nombreContacto, setNombreContacto] = useState(user.nombre);
  const [telefonoContacto, setTelefonoContacto] = useState(user.telefono);
  const [loadingFormContacto, setLoadingFormContacto] = useState(false);
  const [isSendContactame, setIsSendContactame] = useState(false);
  // Índice de la tipología actual
  const [tipologiaIndex, setTipologiaIndex] = useState(0);
  const currentTipologia = proyectoNew ? proyectoNew.tipologias[tipologiaIndex] : null;
  const showAmobladoSwitch = currentTipologia ? currentTipologia.amoblado : false;
  const [amoblado, setAmoblado] = useState(false);

  // Actualizar estado de Redux cuando cambia tipologiaIndex
  useEffect(() => {
    if (proyectoNew) {
      proyectoNew.tipologias.filter((item) => item.precio_min_amoblado !== -1)
      dispatch(reduxSetTipologiaIndex(tipologiaIndex));
      dispatch(setTipologiaTitulo(proyectoNew.tipologias[tipologiaIndex].titulo));
    }
  }, [dispatch, proyectoNew, tipologiaIndex]);

  // Devuelve true si la tipología pasa los filtros, false en caso contrario
  function tipologiaPassesFilters(tipologia) {
    if (tipologia.dormitorios < filtroDormitorios) {
      return false;
    }

    if (tipologia.banos < filtroBanos) {
      return false;
    }

    return filtroSuperficieMin <= tipologia.superficie &&
      tipologia.superficie <= filtroSuperficieMax;
  }

  // Devuelve el índice de la primer tipología que pasa los filtros. Si ninguna lo pasa,
  // devuelve 0.
  function getFirstPassingTipologiaIndex() {
    for (let i = 0; i < proyectoNew.tipologias.length; i++) {
      const currTipologia = proyectoNew.tipologias[i];

      if (tipologiaPassesFilters(currTipologia)) {
        return i;
      }
    }
    return 0;
  }

  // Tabs de tipologías
  let tipologiasTabs = null;

  // URL de la imagen de la galería. Agarramos la primera imagen de la tipología actual, o
  // defaulteamos a notFound si no existe
  let imagenGaleriaUrl = notFound;

  // Info de la tipología actual (valores default)
  let descripcion = '';
  let dormitorios = 1;
  let banos = 1;
  let personas = 1;
  let superficie = '';

  // Strings con la info de la tipología actual
  let descripcionStr = '';
  let dormitorioStr = 'Dormitorio';
  let banoStr = 'Baño';
  let personaStr = 'Persona';
  let supTotalStr = 'Sup. Total ';

  const setCurrentTipologiaTab = (tabIndex) => {
    setTipologiaIndex(tabIndex);
    //dispatch(reduxSetTipologiaIndex(tabIndex));
    //dispatch(setTipologiaTitulo(proyectoNew.tipologias[tabIndex].titulo));

    // Reiniciar bodegas y estacionamientos de auto y moto
    dispatch(setBodegas(null));
    dispatch(setEstacionamientosAuto(null));
    dispatch(setEstacionamientosMoto(null));
  }

  if (proyectoNew) {
    // Construir tabs
    tipologiasTabs = proyectoNew.tipologias.filter((item) => item.precio_min_amoblado !== -1).map((tipo, index) => {
      const indexTipologiaCurrent = proyectoNew.tipologias.findIndex((item) => item.id === tipo.id)
      const passesFilters = tipologiaPassesFilters(tipo);
      let classStr = passesFilters ? `tab-item enabled` : `tab-item`;

      const isCurrentTab = indexTipologiaCurrent === tipologiaIndex;

      if (isCurrentTab) {
        classStr += ' active';
      }

      // 'A' ASCII decimal code is 65, 'B' is 66, and so on
      //const letter = String.fromCharCode(65 + index);
      const { dormitorios, superficie } = proyectoNew.tipologias[indexTipologiaCurrent]
      const tipologiaStr = ` ${dormitorios} ${dormitorios > 1 ? "Dormitorios" : "Dormitorio"} + ${superficie} m<sup>2</sup>`;
      //const tipologiaStr = `Tipología ${letter}`;

      return (
        <span
          key={tipo.id}
          className={classStr}
          onClick={() => {
            setCurrentTipologiaTab(indexTipologiaCurrent);
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: tipologiaStr }}>
          </div>
        </span>
      );

    });


    // Agarrar URL de la primera imagen de la galería de la tipología actual, si existe
    if (proyectoNew.tipologias[tipologiaIndex].galeria.length > 0) {
      imagenGaleriaUrl = `${BACKEND_BASE_URL}${proyectoNew.tipologias[tipologiaIndex].galeria[0].url}`;
    }

    // Shorthands cómodos para variables del back. Asignamos defaults razonables si no había nada en el back.
    const currTipologia = proyectoNew.tipologias[tipologiaIndex];

    descripcion = currTipologia.descripcion || '';
    dormitorios = currTipologia.dormitorios || 1;
    banos = currTipologia.banos || 1;
    personas = currTipologia.personas || 1;
    superficie = currTipologia.superficie || '';

    // Construir strings de características de tipología (i.e. dormitorio(s), baño(s), persona(s), etc.).
    descripcionStr = descripcion;

    dormitorioStr = dormitorios;
    dormitorioStr += dormitorios > 1 ? ' Dormitorios' : ' Dormitorio';

    if (currTipologia.studio) {
      dormitorioStr += ' (Estudio)';
    }

    banoStr = banos;
    banoStr += banos > 1 ? ' Baños' : ' Baño';

    personaStr = personas;
    personaStr += personas > 1 ? ' Personas' : ' Persona';

    supTotalStr += (superficie === '') ? superficie : (superficie + ' m2');
  }

  const [showGaleria, setShowGaleria] = useState(false);
  const [showFiltro, setShowFiltro] = useState(false);

  const openGalery = () => {
    setShowGaleria(true);
  }

  const closeGaleria = () => {
    setShowGaleria(false);
  }

  const openFiltro = () => {
    setShowFiltro(true);
  }

  const closeFiltro = () => {
    setShowFiltro(false);
  }

  const onAmobladoSwitch = (event) => {
    setAmoblado(event.target.checked);

    // Actualizar también estado de preevaluacion en Redux
    dispatch(reduxSetAmoblado(event.target.checked));
  }

  // Armar la string de búsqueda. Mostramos todos los filtros que no tengan el valor default
  const unjoinedStrings = [];

  const filtroDormitoriosStr = `${filtroDormitorios} ${pluralize('dormitorio', filtroDormitorios)}`;
  if (filtroDormitoriosStr !== '') {
    unjoinedStrings.push(filtroDormitoriosStr);
  }

  const filtroBanosStr = `${filtroBanos} ${pluralize('baño', filtroBanos)}`;
  if (filtroBanosStr !== '') {
    unjoinedStrings.push(filtroBanosStr);
  }

  const filtroSuperficieMinStr = filtroSuperficieMin !== INITIAL_SUPERFICIE_MIN ?
    `Superficie min. ${filtroSuperficieMin} m2` : '';
  if (filtroSuperficieMinStr !== '') {
    unjoinedStrings.push(filtroSuperficieMinStr);
  }

  const filtroSuperficieMaxStr = filtroSuperficieMax !== INITIAL_SUPERFICIE_MAX ?
    `Superficie max. ${filtroSuperficieMax} m2` : '';
  if (filtroSuperficieMaxStr !== '') {
    unjoinedStrings.push(filtroSuperficieMaxStr);
  }

  let busquedaStr = unjoinedStrings.join(', ');

  const bodegas = useSelector(selectBodegas); // bodegas de preevaluación

  const onBodegaDecrease = () => {
    const newBodegas = bodegas - 1;
    if (newBodegas >= INITIAL_BODEGAS) {
      dispatch(setBodegas(newBodegas));
    }
  }

  const onBodegaIncrease = () => {
    const newBodegas = bodegas + 1;
    if (newBodegas <= proyectoNew.cantidadBodegas) {
      dispatch(setBodegas(newBodegas));
    }
  }

  const estacionamientosAuto = useSelector(selectEstacionamientosAuto); // est. de preevaluación
  //const estacionamientosAuto = proyectoNew.cantidadEstacionamientosCubiertos;
  const onEstacionamientosAutoDecrease = () => {
    const newEstacionamientosAuto = estacionamientosAuto - 1;
    if (newEstacionamientosAuto >= INITIAL_PREEV_ESTACIONAMIENTOS_AUTO) {
      dispatch(setEstacionamientosAuto(newEstacionamientosAuto));
    }
  }

  const onEstacionamientosAutoIncrease = () => {
    const newEstacionamientosAuto = estacionamientosAuto + 1;
    if (newEstacionamientosAuto <= proyectoNew.cantidadEstacionamientosCubiertos) {
      dispatch(setEstacionamientosAuto(newEstacionamientosAuto));
    }
  }

  const onTipologiasSimilaresClick = () => {
    dispatch(setFiltroDormitorios(dormitorios));
    dispatch(setFiltroBanos(banos));
    history.push('/result');
  }

  const dptInfoStyle = !FEATURE_ENABLED.COTIZAR ? { position: 'relative', left: '-270px' } : {};


  const handleSendContactame = () => {

    if (!isValidEmail(emailContacto)) {
      toast.error(getInvalidEmailString());
      return;
    }

    if (nombreContacto == '') {
      toast.error('Ingrese un nombre de contacto.');
      return false;
    }

    if (telefonoContacto == '') {
      toast.error('Ingrese un telefono de contacto.');
      return false;
    }

    setLoadingFormContacto(true);

    dispatch(sendContactame({
      email: emailContacto,
      nombre: nombreContacto,
      telefono: telefonoContacto
    })).then((response) => {
      const { payload } = response
      if (payload === 200) {
        setLoadingFormContacto(false);
        setIsSendContactame(true);
        toast.success('Contacto enviado con exito..');
      } else {
        toast.error('Error al intentar enviar el formulario. Intente nuevamente.');
      }
    })

  }


  console.log('tabs ', proyectoNew)

  return (
    <div className="row">
      <div className="place-dpt-container">
        <div className="place-dpt-header">
          <Title id="card-place-departamento-header" className="muli" size="30">Departamentos</Title>
          <div className="place-dpt-filtro-header">
            <span className="text-busqueda">Tu búsqueda: <strong>{busquedaStr}</strong></span>
            <Button onClick={() => openFiltro()} className="btn bold" color="btn-outline-orange" startIcon="icon-adjust">FILTROS</Button>
          </div>
        </div>

        <div className="tab-container">
          <div className="tab-header">
            {tipologiasTabs}
          </div>
          <div className={`tab-body`}>
            <div className="tab-body-item">

              <div className="dpt-container">
                <div className="dpt-header-info">
                  <div className={`dpt-header-icons ${disponibilidad === DISPONIBILIDAD.NO_DISPONIBLE && 'not-avaliable'}`}>
                    <TextInfo iconName="icon-bed" color="black" size="14">{dormitorioStr}</TextInfo>
                    <TextInfo iconName="icon-bath" color="black" size="14">{banoStr}</TextInfo>
                    <TextInfo iconName="icon-user" color="black" size="14">{personaStr}</TextInfo>
                    <TextInfo iconName="icon-full-screen" color="black" size="14">{supTotalStr}</TextInfo>
                  </div>
                  {disponibilidad === DISPONIBILIDAD.POCAS_UNIDADES_DISPONIBLES && (<span className="alert-danger">Pocas unidades disponibles</span>)}
                </div>

                <div className="dpt-body">
                  <div className="dpt-col dpt-images">
                    <LazyLoad width={307} height={230}>
                      <img src={imagenGaleriaUrl} alt="Galeria" style={{ width: '100%', objectFit: 'cover' }} />
                    </LazyLoad>
                    <Button onClick={openGalery} color='btn-white-gray' className="btn btn-galeria">
                      <Icon size="20" name="icon-camare" />
                    </Button>
                    {/* {disponibilidad === DISPONIBILIDAD.NO_DISPONIBLE && <div className="overlay"></div>} */}
                  </div>

                  <div className="dpt-col dpt-info" style={dptInfoStyle}>
                    <div className="dpt-info-text">
                      <p style={{ whiteSpace: "pre-line" }}>{descripcionStr}</p>
                    </div>
                    <div className="dtp-info-footer">
                      {
                        FEATURE_ENABLED.TOUR_360 &&

                        proyectoNew.tour_nombre_directorio !== undefined && <a href={getProyectoTourUrl(proyectoNew)} target="_blank" rel="noopener noreferrer"><Button color="btn-outline-orange" className="btn bold" size="medium">{TOUR_360_STR}</Button></a>
                      }
                      <div className="dpt--links">
                        {FEATURE_ENABLED.PRINT && <a><Icon name="icon-printer" border="round" background="gray-blue" width={30} size="14" /></a>}
                        <a onClick={() => openShared()}><Icon name="icon-share-option" border="round" background="gray-blue" width={30} size="14" /></a>
                        {FEATURE_ENABLED.LIKES && <a><Icon name=" icon-heart" border="round" background="gray-blue" width={30} size="14" /></a>}
                      </div>
                    </div>
                  </div>

                  {
                    FEATURE_ENABLED.COTIZAR &&

                    <div className={`dpt-col dpt-filtro`}>
                      <div
                        className={`dpt-filtro-form`}
                        style={{ marginBottom: '20px' }}
                      >
                        <InputFiltro
                          cantidadDisponible={proyectoNew ? proyectoNew.cantidadBodegas : 0}
                          type="bodega"
                          controlledValue={bodegas}
                          onIncrease={onBodegaIncrease}
                          onDecrease={onBodegaDecrease}
                          label="Incluir Bodega"
                          name="bodega"
                          flex={true}
                          between={true}
                          margin={false}
                          marginBottom="25"
                        />

                        <InputFiltro
                          cantidadDisponible={proyectoNew ? proyectoNew.cantidadEstacionamientosCubiertos : 0}
                          type="estacionamientoAuto"
                          controlledValue={estacionamientosAuto}
                          onIncrease={onEstacionamientosAutoIncrease}
                          onDecrease={onEstacionamientosAutoDecrease}
                          label="Incluir Estacionamiento"
                          name="estacionamiento-auto"
                          flex={true}
                          between={true}
                          margin={false}
                          marginBottom={`${showAmobladoSwitch ? '25' : '0'}`}
                        />

                        {showAmobladoSwitch && <ToogleSwich checked={amoblado} onChange={onAmobladoSwitch} position="left">¿Amoblado?</ToogleSwich>}
                      </div>

                      {
                        FEATURE_ENABLED.PROMO_ALERTS &&

                        <div className="info-alert" style={{ marginBottom: '20px' }}>
                          <strong>¡Solo por febrero!</strong> Esta unidad permite pagar la garantía y la comisión en 4 cuotas.
                        </div>
                      }

                      <Button onClick={cotizar} color='btn-orange' size="small"
                        disabled={!disponible}>
                        {!disponible ? 'NO DISPONIBLE' : 'COTIZAR ARRIENDO'}
                      </Button>



                      {/*  {disponibilidad === DISPONIBILIDAD.NO_DISPONIBLE &&
                        (
                          <>
                            <Button onClick={onTipologiasSimilaresClick} color="btn-outline-orange" className="btn bold mt-1" size="extra_small">DEPARTAMENTOS SIMILARES DISPONIBLES</Button>

                            <div className="box-card mt-2">
                              <span className="text-info text-bold text-black title-card">Quiero que me contacten</span>
                              {isSendContactame && <><br /><TextInfo iconName="icon-tick" color="success" textWeight="bold">Pronto será contactado por un representante.</TextInfo></>}
                              {!isSendContactame && (
                                <>
                                  <div className="boder-box" style={{ boxShadow: '0px 0px 3px #d8d8d8', borderRadius: '5px', overflow: 'hidden', marginTop: '10px' }}>
                                    <form>
                                      <Input
                                        type="email"
                                        name="email-1"
                                        typeStyle="filtro-white"
                                        fullWidth={true}
                                        placeholder="Email"
                                        value={emailContacto}
                                        onChange={(e) => setEmailContacto(e.target.value)}
                                        formGroupStyle={{ margin: "0" }}
                                      />

                                      <Input
                                        type="text"
                                        name="email-2"
                                        typeStyle="filtro-white"
                                        fullWidth={true}
                                        placeholder="Nombre"
                                        value={nombreContacto}
                                        onChange={(e) => setNombreContacto(e.target.value)}
                                        formGroupStyle={{
                                          margin: '0',
                                        }}
                                      />

                                      <Input
                                        type="text"
                                        name="email-2"
                                        typeStyle="filtro-white"
                                        fullWidth={true}
                                        placeholder="Teléfono"
                                        value={telefonoContacto}
                                        onChange={(e) => setTelefonoContacto(e.target.value)}
                                        formGroupStyle={{
                                          margin: '0',
                                        }}
                                      />
                                    </form>
                                  </div>
                                  <Button onClick={handleSendContactame} disabled={loadingFormContacto} color='btn-orange mt-1' fullWidth={true} size="small">{loadingFormContacto ? 'Enviando formulario...' : 'Envíar formulario'}</Button>
                                </>
                              )}
                            </div>
                                                   </>
                        )} */}

                      {/* {
                        disponibilidad === DISPONIBILIDAD.NO_DISPONIBLE &&
                        <span
                          onClick={onTipologiasSimilaresClick}
                          className={styles.tipologiasDisponibles}
                        >
                          Ver tipologías similares
                        </span>
                      } */}
                    </div>
                  }

                  {
                    FEATURE_ENABLED.CREAR_ALERTA &&

                    <div className={`dpt-col dpt-filtro ${disponibilidad === DISPONIBILIDAD.POCAS_UNIDADES_DISPONIBLES && 'hidden'}`}>

                      <div className="dpt-filtro-no">
                        <Icon className="mb-3" name="icon-alarm" size="40" />
                        <TitleFiltro className="mb-4" title="Te notificaremos cuando el edificio esté listo" />
                        <TextInfo color="#927A2F" size="12"><strong>¡Departamento no disponible!</strong> No te preocupes, puedes crear una alerta y te avisaremos cuando esté próximo a liberarse.</TextInfo>
                      </div>

                      <Button className="btn bold" color='btn-blue' size="small">CREAR ALERTA</Button>
                    </div>
                  }

                </div>
              </div>

            </div>
          </div>
        </div>

      </div>

      {
        showGaleria &&
        <ModalGaleria
          show={showGaleria}
          close={closeGaleria}
          proyecto={proyecto}
          tipologiaIndex={tipologiaIndex}
        />
      }

      {
        showFiltro &&
        <ModalFiltroPlace
          show={showFiltro}
          close={closeFiltro}
        />
      }
    </div>
  );
};

export default CardPlaceDepartamento;
