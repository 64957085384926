
import useHideChatButton from './useHideChatButton';
import useHideWspButton from './useHideWspButton';


// Hook para ocultar los botones de WhatsApp y chat de Cliengo en mobile.
// El hook vuelve a mostrar los botones cuando el componente que lo usa es desmontado.
// `show` es un prop opcional que está pensado para pasar el valor correspondiente de los
// componentes de modales (e.g. ModalRegister), para que el hook *no* surta efecto si
// el componente está montando en el DOM pero oculto.
const useHideCliengoButtons = (show = true) => {

  useHideWspButton(show);
  useHideChatButton(show);
}

export default useHideCliengoButtons;
