import React from 'react'
import PropTypes from 'prop-types'
import './ToogleSwich.scss'

const ToogleSwich = props => {
    const { 
        classes = '',
        position = 'left',
        justifyContent = '',
        children,
        ...other
    } = props;

    let style = {}
    if (justifyContent) {
        style = { justifyContent: justifyContent }
    }

    return(
        <div style={style} className="form-group-swich">
            {position === 'left' && <span className="label">{children}</span>}
            <label className="switch-container">
                <input type="checkbox" className={`${classes}`} {...other}/>
                <span className={`slider round`}></span>
            </label>
            {position === 'right' && <span className="label">{children}</span>}
        </div>
        
    );

}

ToogleSwich.propTypes = {
    classes: PropTypes.object,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    value: PropTypes.any

}

export default ToogleSwich;