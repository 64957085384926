/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Icon, Title, TextInfo, Button } from '../../ui/index';
import Carousel from 'react-elastic-carousel';
import {
  INITIAL_PREEV_ESTACIONAMIENTOS_AUTO,
  INITIAL_PREEV_ESTACIONAMIENTOS_MOTO,
  selectTipologiaIndex,
  selectEstacionamientosAuto,
  selectAmoblado,
  selectEstacionamientosMoto,
  setEstacionamientosAuto,
  setEstacionamientosMoto,
  selectBodegas,
  setBodegas,
  sendCotizacion,
  selectReserva
} from '../../../redux/preevaluacionSlice';
import {
  BACKEND_BASE_URL,
  BREAKPOINT_MOBILE,
  FEATURE_ENABLED,
} from '../../../config/constant';
import {
  getBodegaPrecio,
  getEstacionamientoAutoPrecio,
  getEstacionamientoMotoPrecio,
  getTipologiaGastoComun,
  getTipologiaPrecioMensual,
  formatPrice
} from '../../../utils';
import notFound from '../../../static/images/notFound.png';
import './styles/custom.scss';
import InputFiltro from '../../ui/InputFiltro/InputFiltro';
import {
  INITIAL_LEVEL_CLIENTEID,
  selectLevelClienteId,
} from '../../../redux/userSlice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GastoComunTipologia from '../../GastoComunTipologia';
import PrecioMensualTipologia from '../../PrecioMensualTipologia';
import useCurrentTipologiaCheapestDepto from '../../../hooks/useCurrentTipologiaCheapestDepto';
import { INITIAL_BODEGAS } from '../../../redux/proyectosSlice';
import useResize from '../../../hooks/useResize';


const GUARDAR_COTIZACION_ENABLED = true;
const COMISION_ARRIENDO_ENABLED = false;
const IMAGE_MAX_HEIGHT = '500px';

export const TIPO_ENTRADA = {
  PROYECTO: 0,
  ESTACIONAMIENTOS: 1,
  BODEGAS: 2,
};

const Entrada = ({ saveButton = true, close, currentCotizacion, saveCotizacion, OnBoarding, proyecto, tipoEntrada = TIPO_ENTRADA.PROYECTO, isCotizacion = false }) => {

  const dispatch = useDispatch();
  const [rizeWidth] = useResize();

  let estacionamientosAuto = useSelector(selectEstacionamientosAuto);
  let estacionamientosMoto = useSelector(selectEstacionamientosMoto);
  let bodegas = useSelector(selectBodegas);

  if (isCotizacion) {
    const totalEstacionamientoAuto = currentCotizacion.productos.filter((item) => item.productotipo == 'ESTACIONAMIENTO CUBIERTO')
    const totalEstacionamientoMoto = currentCotizacion.productos.filter((item) => (item.productotipo === 'MOTO ESTACIONAMIENTO') || (item.productotipo === 'ESTACIONAMIENTO MOTOS'))
    const totalEstacionamientoBodega = currentCotizacion.productos.filter((item) => item.productotipo == 'BODEGA')

    estacionamientosAuto = totalEstacionamientoAuto.length;
    estacionamientosMoto = totalEstacionamientoMoto.length;
    bodegas = totalEstacionamientoBodega.length;

  }



  const tipologiaIndex = useSelector(selectTipologiaIndex);
  const carouselRef = useRef();

  // Calcular strings renderizadas
  const amoblado = useSelector(selectAmoblado);
  let proyectoNombre = '';
  let dormitoriosStr = 'Dormitorios';
  let banosStr = 'Baños';
  let personasStr = 'Personas';
  let superficieStr = 'Sup. total';
  let direccionStr = 'Dirección';
  let arriendoDpto = 0;
  let arriendoEstacionamientosAuto = 0;
  let arriendoEstacionamientosMoto = 0;
  let arriendoBodegas = 0;
  let arriendoComision = 0;
  let gastosComunesEstacionamientosAuto = 0;
  let gastosComunesEstacionamientosMoto = 0;
  let gastosComunesBodegas = 0;
  let precioMensual = 0;
  let gastosComunesTotal = 0;
  let precioMensualBruto = 0;
  let precioGastoBruto = 0;
  let gastoComunDpt = 0;

  // Items del slider de imágenes
  let itemsSlider = [
    <div className="item-slider">
      <div className="image">
        <img src={notFound} alt="sin-foto" />
      </div>
    </div>
  ];

  if (proyecto) {
    proyectoNombre = proyecto.proyecto_nombre;

    const cantidadDormitorios = proyecto.tipologias[tipologiaIndex].dormitorios || 1;
    const cantidadBanos = proyecto.tipologias[tipologiaIndex].banos || 1;
    const cantidadPersonas = proyecto.tipologias[tipologiaIndex].personas || 1;
    const superficie = proyecto.tipologias[tipologiaIndex].superficie || '';

    dormitoriosStr = `${cantidadDormitorios} Dormitorio${cantidadDormitorios > 1 ? 's' : ''}`;
    banosStr = `${cantidadBanos} Baño${cantidadBanos > 1 ? 's' : ''}`;
    personasStr = `${cantidadPersonas} Persona${cantidadPersonas > 1 ? 's' : ''}`;
    superficieStr = `Sup. total ${superficie} m2`;
    direccionStr = proyecto.direccion;

    arriendoDpto = getTipologiaPrecioMensual(proyecto.tipologias[tipologiaIndex], amoblado);
    arriendoEstacionamientosAuto = getEstacionamientoAutoPrecio(proyecto) * estacionamientosAuto;
    arriendoEstacionamientosMoto = getEstacionamientoMotoPrecio(proyecto) * estacionamientosMoto;
    arriendoBodegas = getBodegaPrecio(proyecto) * bodegas;

    // Por alguna razón estamos recibiendo un 0 negativo (sí, existe tal cosa:
    // https://dev.to/emnudge/identifying-negative-zero-2j1o), que cuando se convierte con
    // toLocaleString da la string "-0". Esto lo arregla. El 0 negativo parece ocurrir solamente
    // para arriendoEstacionamientosAuto, pero por las dudas seteamos todo a 0. 
    if (arriendoDpto === 0 || arriendoDpto === '-') {
      arriendoDpto = 0;
    }

    if (arriendoEstacionamientosAuto === 0) {
      arriendoEstacionamientosAuto = 0;
    }

    if (arriendoEstacionamientosMoto === 0) {
      arriendoEstacionamientosMoto = 0;
    }

    if (arriendoBodegas === 0) {
      arriendoBodegas = 0;
    }

    if (tipoEntrada === TIPO_ENTRADA.PROYECTO) {
      gastoComunDpt = getTipologiaGastoComun(proyecto.tipologias[tipologiaIndex]);
      precioMensual += gastoComunDpt + arriendoDpto;
    }

    precioMensual += arriendoEstacionamientosAuto + arriendoEstacionamientosMoto + arriendoBodegas;
    precioMensualBruto = arriendoDpto + arriendoEstacionamientosAuto + arriendoEstacionamientosMoto + arriendoBodegas;

    gastosComunesEstacionamientosAuto = estacionamientosAuto * proyecto.gastoComunMinEstacionamientosAuto;
    gastosComunesEstacionamientosMoto = estacionamientosMoto * proyecto.gastoComunMinEstacionamientosMoto;
    gastosComunesBodegas = bodegas * proyecto.gastoComunMinBodegas;
    gastosComunesTotal = gastosComunesEstacionamientosAuto + gastosComunesEstacionamientosMoto + gastosComunesBodegas;

    precioGastoBruto = gastosComunesTotal + gastoComunDpt;

    precioMensual += gastosComunesTotal;

    // Armar imágenes de la galería, si hay alguna
    if (proyecto.tipologias[tipologiaIndex].galeria.length > 0) {
      itemsSlider = proyecto.tipologias[tipologiaIndex].galeria.map((galeriaItem, index) => {
        return (
          <div key={galeriaItem.id} className="item-slider" style={{ maxHeight: '100%' }}>
            <div className="image">
              <img src={`${BACKEND_BASE_URL}${galeriaItem.url}`} alt={galeriaItem.alternativeText} style={{ width: '100%', maxHeight: IMAGE_MAX_HEIGHT }} />
            </div>
          </div>
        );
      });
    }
  }

  // Handlers filtros
  const onEstacionamientoAutoIncrease = () => {
    const newEstacionamientosAuto = estacionamientosAuto + 1;
    if (newEstacionamientosAuto <= proyecto.cantidadEstacionamientosCubiertos) {
      dispatch(setEstacionamientosAuto(newEstacionamientosAuto));
    }
  }

  const onEstacionamientoAutoDecrease = () => {
    const newEstacionamientosAuto = estacionamientosAuto - 1;
    if (newEstacionamientosAuto >= INITIAL_PREEV_ESTACIONAMIENTOS_AUTO) {
      dispatch(setEstacionamientosAuto(newEstacionamientosAuto));
    }
  }

  const onEstacionamientoMotoIncrease = () => {
    const newEstacionamientosMoto = estacionamientosMoto + 1;
    if (newEstacionamientosMoto <= proyecto.cantidadEstacionamientosMotos) {
      dispatch(setEstacionamientosMoto(newEstacionamientosMoto));
    }
  }

  const onEstacionamientoMotoDecrease = () => {
    const newEstacionamientosMoto = estacionamientosMoto - 1;
    if (newEstacionamientosMoto >= INITIAL_PREEV_ESTACIONAMIENTOS_MOTO) {
      dispatch(setEstacionamientosMoto(newEstacionamientosMoto));
    }
  }

  const onBodegaIncrease = () => {
    const newBodegas = bodegas + 1;
    if (newBodegas <= proyecto.cantidadBodegas) {
      dispatch(setBodegas(newBodegas));

    }
  }

  const onBodegaDecrease = () => {
    const newBodegas = bodegas - 1;
    if (newBodegas >= INITIAL_BODEGAS) {
      dispatch(setBodegas(newBodegas));
    }
  }

  const reserva = useSelector(selectReserva);
  const levelClienteId = useSelector(selectLevelClienteId);
  const cheapestDepto = useCurrentTipologiaCheapestDepto(proyecto);

  const obtenerCotizacion = () => {
    // Chequear que el usuario esté creado en Level, de lo contrario la cotización no se puede hacer
    if (!levelClienteId || levelClienteId === INITIAL_LEVEL_CLIENTEID) {
      toast.error('Error! Tu cuenta de Level no pudo ser creada');
      return;
    }

    // Si es entrada de estacionamientos o bodegas, tiene que haber al menos uno de esos secundarios
    // seleccionados, para no crear una cotización vacía
    if (tipoEntrada === TIPO_ENTRADA.ESTACIONAMIENTOS) {
      if (estacionamientosAuto === 0 && estacionamientosMoto === 0) {
        toast.error(`Tienes que elegir al menos un estacionamiento`);
        return;
      }
    }

    if (tipoEntrada === TIPO_ENTRADA.BODEGAS) {
      if (bodegas === 0) {
        toast.error(`Tienes que elegir al menos una bodega`);
        return;
      }
    }

    // Crear cotización en Strapi
    dispatch(sendCotizacion({
      ...reserva,
      amoblado,
      deptoStrapiId: cheapestDepto ? cheapestDepto.id : null,
      proyectoId: proyecto.id,
    }));

    // Cerrar Entrada
    closeAndResetEstacionamientos();
  }

  const cotizarButtonText = FEATURE_ENABLED.OBTENER_COTIZACION ? 'OBTENER COTIZACIÓN' : isCotizacion ? 'CONTINUAR ARRIENDO' : 'ARRENDAR ONLINE';

  // Cierra el modal y reinicia los estacionamientos de auto/moto y las bodegas a 0
  const closeAndResetEstacionamientos = () => {
    dispatch(setEstacionamientosAuto(0));
    dispatch(setEstacionamientosMoto(0));
    dispatch(setBodegas(0));
    close();
  }

  return (
    <>
      <Modal show={true} close={closeAndResetEstacionamientos}>
        <div className="modal-content modal-generico xxl fade">
          <div className="header-modal flex-between space-small">
            <a onClick={closeAndResetEstacionamientos}><Icon name="icon-close" size="14" /> Resumen de tu cotización</a>
          </div>
          <div className="modal-body padding0">
            <div className={`_container-divisor ${rizeWidth <= BREAKPOINT_MOBILE ? 'containerMobile' : ''}`}>
              <div className="_galeria">

                <Carousel
                  ref={carouselRef}
                  itemsToScroll={1}
                  itemsToShow={1}
                  enableAutoPlay={false}
                  showArrows={false}
                  autoPlaySpeed={5000}
                  className="_galeriaSlider">
                  {itemsSlider}
                </Carousel>

                {rizeWidth > BREAKPOINT_MOBILE &&
                  <div className="paginacion">
                    <Button color='btn-white-gray' size="small-icon" startIcon="icon-left-arrow" onClick={() => carouselRef.current.slidePrev()}></Button>
                    <Button color='btn-white-gray' size="small-icon" startIcon="icon-button-arrow-ight" onClick={() => carouselRef.current.slideNext()}></Button>
                  </div>
                }

              </div>
              <div className="_informacion-cotizar">
                <div className="_contenido">
                  <div className="_titular">
                    <Title size="16">{proyectoNombre}</Title>
                    <TextInfo size="12" iconName="icon-location-pin" iconColor="#ff6d00">{direccionStr}</TextInfo>
                  </div>
                  {
                    tipoEntrada === TIPO_ENTRADA.PROYECTO &&
                    <div className="_especificaciones_interna">
                      <TextInfo size="12" iconName="icon-bed">{dormitoriosStr}</TextInfo>
                      <TextInfo size="12" iconName="icon-bath">{banosStr}</TextInfo>
                      <TextInfo size="12" iconName="icon-user">{personasStr}</TextInfo>
                      <TextInfo size="12" iconName="icon-full-screen">{superficieStr}</TextInfo>
                    </div>
                  }
                  <div className="_especificaciones_externa">
                    {
                      (tipoEntrada === TIPO_ENTRADA.PROYECTO || tipoEntrada === TIPO_ENTRADA.ESTACIONAMIENTOS) &&
                      <>
                        <div className="_item">
                          <InputFiltro cantidadDisponible={proyecto ? proyecto.cantidadEstacionamientosCubiertos : 0}
                            type="estacionamientoAuto" controlledValue={estacionamientosAuto}
                            onIncrease={onEstacionamientoAutoIncrease} onDecrease={onEstacionamientoAutoDecrease}
                            label="Estacionamiento para auto" name="estacionamiento-auto" flex={true}
                            between={true} margin={false} ></InputFiltro>
                        </div>
                        <div className="_item">
                          <InputFiltro cantidadDisponible={proyecto ? proyecto.cantidadEstacionamientosMotos : 0}
                            type="estacionamientoMoto" controlledValue={estacionamientosMoto}
                            onIncrease={onEstacionamientoMotoIncrease} onDecrease={onEstacionamientoMotoDecrease}
                            label="Estacionamiento para moto" name="estacionamiento-moto" flex={true}
                            between={true} margin={false} />
                        </div>
                      </>
                    }
                    {
                      (tipoEntrada === TIPO_ENTRADA.PROYECTO || tipoEntrada === TIPO_ENTRADA.BODEGAS) &&

                      <div className="_item">
                        <InputFiltro cantidadDisponible={proyecto ? proyecto.cantidadBodegas : 0}
                          type="bodega" controlledValue={bodegas} label="Cantidad de bodegas"
                          onIncrease={onBodegaIncrease} onDecrease={onBodegaDecrease}
                          name="bodega" flex={true} between={true} margin={false} />
                      </div>
                    }
                  </div>
                </div>

                {rizeWidth <= BREAKPOINT_MOBILE &&
                  <div className="_contenido">
                    <div className="_detalleMobile">
                      {
                        tipoEntrada === TIPO_ENTRADA.PROYECTO &&
                        <div className="itemList">
                          <Icon name="icon-hotel" size={18} iconStyle={{ marginLeft: '0' }} />
                          <span><strong>1</strong> Departamento</span>
                        </div>
                      }

                      {
                        (tipoEntrada === TIPO_ENTRADA.PROYECTO || tipoEntrada === TIPO_ENTRADA.ESTACIONAMIENTOS) &&
                        <div className="itemList">
                          <Icon name="icon-garage" size={18} iconStyle={{ marginLeft: '0' }} />
                          <span><strong>{estacionamientosAuto + estacionamientosMoto}</strong> Estacionamientos</span>
                        </div>
                      }
                      {
                        (tipoEntrada === TIPO_ENTRADA.PROYECTO || tipoEntrada === TIPO_ENTRADA.BODEGAS) &&
                        <div className="itemList">
                          <Icon name="icon-warehouse" size={18} iconStyle={{ marginLeft: '0' }} />
                          <span><strong>{bodegas}</strong> Bodegas</span>
                        </div>
                      }

                      <div className="footerPrice">
                        <div className="_itemRow">
                          <p>Gastos comunes desde</p>
                          <strong>${formatPrice(precioGastoBruto)}</strong>
                        </div>
                        <div className="_itemRow orange">
                          <p>Arriendo mensual desde</p>
                          <strong>${formatPrice(precioMensualBruto)}</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                }


                {rizeWidth > BREAKPOINT_MOBILE &&
                  <div className="_contenido">
                    <div className="_detalles">
                      <div className="_header">
                        <div className="_unidad">
                          <strong>UNIDAD</strong>
                          <span>Lo que vas a arrendar</span>
                        </div>
                        <div className="_gastos_comunes">
                          <strong>GASTO COMÚN</strong>
                          <span>desde mensual</span>
                        </div>
                        <div className="_arriendo">
                          <strong>ARRIENDO</strong>
                          <span>desde mensual</span>
                        </div>
                      </div>
                      <div className="_body">
                        <div className="_unidad">
                          {
                            tipoEntrada === TIPO_ENTRADA.PROYECTO &&
                            <span><strong>1</strong> Departamento</span>
                          }
                          {
                            (tipoEntrada === TIPO_ENTRADA.PROYECTO || tipoEntrada === TIPO_ENTRADA.ESTACIONAMIENTOS) &&
                            <>
                              <span><strong>{estacionamientosAuto}</strong> Estacionamiento{estacionamientosAuto > 1 ? 's' : ''} para auto</span>
                              <span><strong>{estacionamientosMoto}</strong> Estacionamiento{estacionamientosMoto > 1 ? 's' : ''} para moto</span>
                            </>
                          }
                          {
                            (tipoEntrada === TIPO_ENTRADA.PROYECTO || tipoEntrada === TIPO_ENTRADA.BODEGAS) &&
                            <span><strong>{bodegas}</strong> Bodega{bodegas > 1 ? 's' : ''}</span>
                          }
                          {COMISION_ARRIENDO_ENABLED && <span>Comisión arriendo aproximada</span>}

                        </div>
                        <div className="_gastos_comunes">
                          {
                            tipoEntrada === TIPO_ENTRADA.PROYECTO &&
                            <GastoComunTipologia tipologia={proyecto ? proyecto.tipologias[tipologiaIndex] : null} />
                          }
                          {
                            (tipoEntrada === TIPO_ENTRADA.PROYECTO || tipoEntrada === TIPO_ENTRADA.ESTACIONAMIENTOS) &&
                            <>
                              <span>${gastosComunesEstacionamientosAuto.toLocaleString()}</span>
                              <span>${gastosComunesEstacionamientosMoto.toLocaleString()}</span>
                            </>
                          }
                          {
                            (tipoEntrada === TIPO_ENTRADA.PROYECTO || tipoEntrada === TIPO_ENTRADA.BODEGAS) &&
                            <span>${gastosComunesBodegas.toLocaleString()}</span>
                          }
                          {!COMISION_ARRIENDO_ENABLED && <span>${arriendoComision.toLocaleString()}</span>}
                        </div>
                        <div className="_arriendo">
                          {
                            tipoEntrada === TIPO_ENTRADA.PROYECTO &&
                            <PrecioMensualTipologia tipologia={proyecto ? proyecto.tipologias[tipologiaIndex] : null} color="#5FCE5C" />
                          }
                          {
                            (tipoEntrada === TIPO_ENTRADA.PROYECTO || tipoEntrada === TIPO_ENTRADA.ESTACIONAMIENTOS) &&
                            <>
                              <span className="green">${arriendoEstacionamientosAuto.toLocaleString()}</span>
                              <span className="green">${arriendoEstacionamientosMoto.toLocaleString()}</span>
                            </>
                          }
                          {
                            (tipoEntrada === TIPO_ENTRADA.PROYECTO || tipoEntrada === TIPO_ENTRADA.BODEGAS) &&
                            <span className="green">${arriendoBodegas.toLocaleString()}</span>
                          }
                          {COMISION_ARRIENDO_ENABLED && <span className="green">${arriendoComision.toLocaleString()}</span>}
                        </div>
                      </div>
                      <small className="_nota">*Este es un valor mensual estimado. El costo definitivo será confirmado al momento de reservar.</small>
                      <small className="_nota" style={{ display: 'block', position: 'relative', top: '5px' }}>*Al monto de la cotización se le agregará la comisión de corretaje.</small>
                    </div>
                  </div>
                }
                {rizeWidth > BREAKPOINT_MOBILE &&
                  <div className="_precio">
                    Precio mensual desde <strong>${precioMensual.toLocaleString()}</strong>
                  </div>
                }

                <div className="_contenido">
                  {
                    !FEATURE_ENABLED.OBTENER_COTIZACION &&
                    <div style={{
                      display: 'flex',
                      gap: '30px',
                    }}>
                      {
                        saveButton &&
                        <Button
                          onClick={saveCotizacion}
                          color="btn-orange"
                          size="small"
                          fullWidth={false}
                          style={{
                            width: '50%',
                            paddingLeft: '20px',
                            paddingRight: '20px',
                          }}
                        >
                          GUARDAR COTIZACIÓN
                        </Button>
                      }

                      <Button
                        onClick={OnBoarding}
                        color="btn-orange"
                        size="small"
                        fullWidth={isCotizacion}
                        style={{
                          width: `${GUARDAR_COTIZACION_ENABLED ? isCotizacion ? '100%' : '50%' : '100%'}`,
                          paddingLeft: '20px',
                          paddingRight: '20px',
                        }}
                      >
                        {cotizarButtonText}
                      </Button>
                    </div>
                  }
                  {FEATURE_ENABLED.OBTENER_COTIZACION && <Button onClick={saveCotizacion} color='btn-orange' size="small" fullWidth={true}>{cotizarButtonText}</Button>}
                </div>

              </div>
            </div>
          </div>
        </div>
      </Modal>

    </>
  );
};

export default Entrada;
