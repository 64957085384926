import { useState, useRef } from 'react';

const useCount = () => {
    const [cantidad, setCantidad] = useState(1)
    const inputRef = useRef()

    const eventAum = () => {
        const aumentar = cantidad + 1
        setCantidad(aumentar)
    }

    const eventDis = () => {
        const restar = cantidad - 1
        if (restar > 0)
            setCantidad(restar)
    }

    return { inputRef, cantidad, eventAum, eventDis }
};

export default useCount;