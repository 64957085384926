import React from 'react'
import PropTypes from 'prop-types'
import './Checkbox.scss'

const Checkbox = props => {
    const { 
        classes = '',
        position = 'left',
        marginZero = false,
        children,
        textAlign = '',
        width,
        marginTop,
        justifyContent,
        checkmarkStyle,
        paddingRight,
        ...other
    } = props;

    const margin = marginZero && 'margin-zero';

    return(
        <label className={`checkbox-container ${margin} ${classes}`} style={{ textAlign, width, marginTop, justifyContent, paddingRight }}>
            <span className={`label label-${position}`}>{children}</span>
            <input 
                type="checkbox"
                {...other}
                />
            <span className={`checkmark ${position}`} style={checkmarkStyle}></span>
        </label>
    );

}

Checkbox.propTypes = {
    name: PropTypes.string,
    classes: PropTypes.string,
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    between: PropTypes.bool,
    type: PropTypes.string,
    value: PropTypes.any,
    typeStyle: PropTypes.oneOf(['defauld', 'filtro', 'filtro-gray']),

}

export default Checkbox;