import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FEATURE_ENABLED } from '../../../../config/constant';
import {
  selectSendCotizacionErrorMessage,
  selectSendCotizacionStatus,
  selectSendReservaErrorMessage,
  selectSendReservaStatus
} from '../../../../redux/preevaluacionSlice';
import Spinner from '../../../Spinner';
import { Button } from '../../../ui/index';


const RESERVA_LOADING_MSG = 'Procesando reserva, espera por favor...';
const RESERVA_SUCCESS_MSG = '¡Genial! Tu reserva fue confirmada';
const RESERVA_ERROR_MSG = '¡Ocurrió un error al crear tu reserva!';


const ReservaStatus = ({ onPagarContratoClick }) => {

  const sendCotizacionStatus = useSelector(selectSendCotizacionStatus);
  const sendCotizacionErrorMessage = useSelector(selectSendCotizacionErrorMessage);

  const sendReservaStatus = useSelector(selectSendReservaStatus);
  const sendReservaErrorMessage = useSelector(selectSendReservaErrorMessage);

  let msg = RESERVA_LOADING_MSG; // mensaje principal
  let submsg = ''; // submensaje, usado para mostrar errores


  msg = RESERVA_SUCCESS_MSG;


  /*if (sendCotizacionStatus === 'failed') {
    // La cotización falló, mostrar mensaje de error
    msg = RESERVA_ERROR_MSG;
    submsg = sendCotizacionErrorMessage;
  }

  if (sendCotizacionStatus === 'succeeded') {
    // La cotización fue exitosa, chequear el estado de la reserva
    if (sendReservaStatus === 'succeeded') {
      msg = RESERVA_SUCCESS_MSG;
    }

    if (sendReservaStatus === 'failed') {
      msg = RESERVA_ERROR_MSG;
      submsg = sendReservaErrorMessage;
    }
  }*/

  const loading = msg !== RESERVA_SUCCESS_MSG && msg !== RESERVA_ERROR_MSG;


  return (
    <div>
      {
        /*FEATURE_ENABLED.OBTENER_COTIZACION*/ false ?
          <>
            <h2>{msg}</h2>
            <Spinner enabled={loading} />
          </>
          :
          <>
            <h2>{msg}</h2>
            {submsg !== '' && <p>{submsg}</p>}
            <Spinner enabled={loading} />
            {
              msg === RESERVA_SUCCESS_MSG &&
              <p>Tu reserva tiene un plazo de vigencia, paga el contrato de tu arriendo ahora.</p>
            }
            <Button
              color="btn-orange"
              onClick={onPagarContratoClick}
              className="btn mb-2"
              size="small"
              fullWidth={true}
              disabled={msg !== RESERVA_SUCCESS_MSG}
            >
              PAGAR CONTRATO AHORA
            </Button>



            <Link to="/mis-cotizaciones"><Button
              color="btn-gray"
              size="small"
              fullWidth={true}
              disabled={msg !== RESERVA_SUCCESS_MSG}
            >
              PAGAR EN OTRO MOMENTO
            </Button></Link>


            
          </>
      }
    </div>
  );
}

export default ReservaStatus;
