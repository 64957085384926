// Este archivo contiene funciones de utilería
import axios from "axios";

import {
  BACKEND_BASE_URL,
  PLACE_REL_URL,
  COTIZACIONES_DAYS_VALID,
  COTIZACIONES_GRACE_PERIOD_IN_DAYS,
  TIPO_PERSONA,
  LEVEL_EVALUACION_BASE_URL,
} from "./config/constant";
import notFound from "./static/images/notFound.png";

import {
  INITIAL_DORMITORIOS,
  INITIAL_BANOS,
  setFiltroUbicaciones,
  setFiltroBanos,
  setFiltroBodegas,
  setFiltroDormitorios,
  setFiltroPrecioMin,
  setFiltroPrecioMax,
  setFiltroAmoblado,
  setFiltroSuperficieMin,
  setFiltroSuperficieMax,
  setFiltroEdificios,
  setFiltroBodegasCantidad,
  setFiltroEstacionamientosPrecioMin,
  setFiltroEstacionamientosPrecioMax,
  setFiltroBodegasPrecioMin,
  setFiltroBodegasPrecioMax,
  setFiltroEstacionamientos,
  setFiltroEntregaInmediata,
  setFiltroEstrenar,
  setFiltroMascota,
  setFiltroOfertas,
} from "./redux/proyectosSlice";
import { logoutUser } from "./redux/userSlice";

// Valida si el usuario no tiene autorización.
export const validateJwt = (dispatch) => {
  axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
    if (err.response.status === 401 || err.response.message === '401 Unauthorized') {
      dispatch(logoutUser());
      window.location.href = '/';
    }
    return Promise.reject(err)
  });
}



// Id del elemento DOM del botón de WhatsApp de Cliengo.
const WSP_BUTTON_ID = "wspIframe";

// Id del elemento DOM del botón de chat de Cliengo.
const CHAT_BUTTON_ID = "chatIframe";

// Longitud mínima de un RUT válido (contando sólo números, sin tener en cuenta otros caracteres).
const RUT_MIN_LEN = 8;

// version del sitio para eliminar cache
const VERSION_SITE = "chatIframe";

const TOKEN_APP = `ARRIENDO_VERSION`;

export const setToken = (token) => {
  localStorage.setItem(TOKEN_APP, token);
};

export const getToken = () => {
  return localStorage.getItem(TOKEN_APP);
};

export const deleteToken = () => {
  localStorage.removeItem(TOKEN_APP);
};

export const setVersion = (version) => {
  localStorage.setItem(VERSION_SITE, version);
};

export const getVersion = () => {
  return localStorage.getItem(VERSION_SITE);
};


// Devuelve true si la string sólo contiene números, falso otherwise
export function isNumberStr(str) {
  const regex = /^\d+$/;
  return regex.test(str);
}

// Quita el formato de una string de RUT, dejando solo los números.
export function quitarFormatoRut(rut) {
  rut = rut.split("-").join("").split(".").join("").split(",").join("");
  return rut;
}

// Devuelve la URL del tour del proyecto. Si `proyecto` es null, devuelve una string vacía ''.
export function getProyectoTourUrl(proyecto) {
  if (!proyecto) {
    return "";
  }

  return `/tours/${proyecto.tour_nombre_directorio}/index.html`;
}

// Devuelve la URL de lo que antes llamábamos 'place' y ahora llamamos 'nuestros-edificios'
// para el proyecto pasado.
export function getPlaceUrl(proyecto) {
  if (proyecto) {
    return `${PLACE_REL_URL}/${proyecto.slug}`;
  } else {
    return `${PLACE_REL_URL}`;
  }
}

/**
 * Devuelve un número con el precio mensual del proyecto. Si los campos precioMinAmoblado o
 * precioMinNoAmoblado no existen en el proyecto, devuelve 0.
 * @param {Object} proyecto Un objeto de proyecto
 * @param {Boolean} amoblado Determina si el precio es de amoblado o no
 * @returns {Number} Precio mensual del objeto
 */
export function getPrecioMensual(proyecto, amoblado) {
  let precioMensual = 0;

  if (amoblado) {
    if (proyecto.precioMinAmoblado) {
      precioMensual = proyecto.precioMinAmoblado;
    }
  } else {
    if (proyecto.precioMinNoAmoblado) {
      precioMensual = proyecto.precioMinNoAmoblado;
    }
  }

  return precioMensual;
}

// Devuelve el precio mensual de tipología
export function getTipologiaPrecioMensual(tipologia, amoblado) {
  if (amoblado) {
    return tipologia.hasOwnProperty("precio_min_amoblado_absoluto")
      ? tipologia.precio_min_amoblado_absoluto
      : 0;
  } else {
    return tipologia.hasOwnProperty("precio_min_no_amoblado_absoluto")
      ? tipologia.precio_min_no_amoblado_absoluto
      : 0;
  }
}

// Devuelve el gasto común de tipología
export function getTipologiaGastoComun(tipologia) {
  return tipologia.hasOwnProperty("gastocomun_min")
    ? tipologia.gastocomun_min
    : 0;
}

// Devuelve el índice de una tipología dentro del array de tipologías del proyecto.
// Devuelve 0 si no se encontró la tipología en el proyecto.
export function getTipologiaIndex(proyecto, tipologia) {
  for (let i = 0; i < proyecto.tipologias.length; i++) {
    const currTipologia = proyecto.tipologias[i];

    if (currTipologia.titulo === tipologia.titulo) {
      return i;
    }
  }
  return 0;
}

// Devuelve la tipología del proyecto cuyo título es igual a tituloTipologia.
// Devuelve null si no se encuentra la tipología.
export function getTipologiaByTitulo(proyecto, tituloTipologia) {
  for (const tipologia of proyecto.tipologias) {
    if (tipologia.titulo === tituloTipologia) {
      return tipologia;
    }
  }

  return null;
}

// Devuelve la galería de la tipología como un array de objetos { url, alt }, en donde `url`
// ya viene prefijada con la URL del backend para ser usada directamente. Si la galería no
// existía en la tipología, o si la tipología es null/undefined, devuelve un array vacío.
export function getTipologiaGallery(tipologia) {
  if (!tipologia || !tipologia.galeria) {
    return [];
  }

  const gallery = [];

  for (const galleryItem of tipologia.galeria) {
    gallery.push({
      url: `${BACKEND_BASE_URL}${galleryItem.url}`,
      alt:
        galleryItem.alternativeText !== ""
          ? galleryItem.alternativeText
          : galleryItem.name,
    });
  }

  return gallery;
}

/**
 * Devuelve gastos totales (i.e. precio mensual + gastos comunes) mínimos del proyecto.
 * @param {Object} proyecto Un objeto de proyecto
 * @param {Boolean} amoblado Determina si el precio es de amoblado o no
 */
export function getMinGastosTotales(proyecto, amoblado) {
  //return getPrecioMensual(proyecto, amoblado) + proyecto.gastoComunMinDeptos;
  return getPrecioMensual(proyecto, amoblado);
}

/**
 * Devuelve un objeto { url, alt } con la URL y el alt text de la portada. Si el proyecto
 * no tiene portada, se devuelve la url de notFound (png genérico) con el alt text
 * `[proyecto_nombre]-portada-no-encontrada`. Si el alt text no existe o es la string vacía, se lo arma
 * de acuerdo al patrón `[proyecto_nombre]-portada`.
 * @returns {Object} Objeto con la forma { url, alt }
 */
export function getPortadaData(proyecto) {
  let url = notFound;
  let alt = `${proyecto.proyecto_nombre}-portada-no-encontrada`;

  if (proyecto.portada) {
    if (proyecto.portada.formats.small && proyecto.portada.formats.small.url) {
      url = `${BACKEND_BASE_URL}${proyecto.portada.formats.small.url}`;
    } else if (
      proyecto.portada.formats.thumbnail &&
      proyecto.portada.formats.thumbnail.url
    ) {
      url = proyecto.portada.formats.thumbnail.url;
    }

    if (
      proyecto.portada.alternativeText &&
      proyecto.portada.alternativeText !== ""
    ) {
      alt = proyecto.portada.alternativeText;
    }
  }

  return {
    url,
    alt,
  };
}

// Devuelve un objeto { url, alt } correspondiente a la primer imagen del slider estacionamiento_galeria del
// proyecto
export function getEstacionamientoPortada(proyecto) {
  let url = notFound;
  let alt = `${proyecto.proyecto_nombre}-portada-estacionamiento-no-encontrada`;

  if (
    proyecto.estacionamiento_galeria &&
    proyecto.estacionamiento_galeria.length > 0
  ) {
    const portadaSmall = proyecto.estacionamiento_galeria[0].formats.small;

    if (portadaSmall) {
      url = `${BACKEND_BASE_URL}${portadaSmall.url}`;
    }

    const portadaAlt = proyecto.estacionamiento_galeria[0].alternativeText;

    if (portadaAlt !== "") {
      alt = portadaAlt;
    }
  }

  return {
    url,
    alt,
  };
}

// Devuelve un objeto { url, alt } correspondiente a la primer imagen del slider bodega_galeria del
// proyecto
export function getBodegaPortada(proyecto) {
  let url = notFound;
  let alt = `${proyecto.proyecto_nombre}-portada-bodega-no-encontrada`;

  if (proyecto.bodega_galeria && proyecto.bodega_galeria.length > 0) {
    const portadaSmall = proyecto.bodega_galeria[0].formats.small;

    if (portadaSmall) {
      url = `${BACKEND_BASE_URL}${portadaSmall.url}`;
    }

    const portadaAlt = proyecto.bodega_galeria[0].alternativeText;

    if (portadaAlt !== "") {
      alt = portadaAlt;
    }
  }

  return {
    url,
    alt,
  };
}

/**
 * Devuelve las strings de dormitorios y baños (i.e. "1-2 dormitorios", "1-4 baños") para un proyecto
 * dado.
 * @param {Object} proyecto Un objeto de proyecto
 * @param {Boolean} includeText Determina si se agrega el texto 'dormitorio(s)' y 'baño(s)' al final
 * de la string.
 * @returns {Object} Un objeto de la forma { dormitoriosStr: "1-2", banosString: "1-4" }
 */
export function getDormitoriosAndBanosString(proyecto, includeText = true) {
  let dormitorios = [];
  let banos = [];

  proyecto.tipologias.forEach((tipo) => {
    dormitorios.push(tipo.dormitorios);
    banos.push(tipo.banos);
  });

  // Ignorar 0 dormitorios
  dormitorios = dormitorios.filter((dorm) => dorm !== 0);

  let dormitoriosStr = "";
  const minDormitorio = Math.min(...dormitorios);
  const maxDormitorio = Math.max(...dormitorios);

  if (minDormitorio === maxDormitorio) {
    dormitoriosStr = `${minDormitorio}${includeText ? " dormitorio" : ""}`;
  } else {
    dormitoriosStr = `${minDormitorio}-${maxDormitorio}${includeText ? " dormitorios" : ""
      }`;
  }

  // Ignorar 0 baños
  banos = banos.filter((bano) => bano !== 0);

  let banosStr = "";
  const minBano = Math.min(...banos);
  const maxBano = Math.max(...banos);

  if (minBano === maxBano) {
    banosStr = `${minBano}${includeText ? " baño" : ""}`;
  } else {
    banosStr = `${minBano}-${maxBano}${includeText ? " baños" : ""}`;
  }

  return { dormitoriosStr, banosStr };
}

/**
 * Devuelve la cantidad mínima y máxima de baños en un proyecto.
 * @param {Object} proyecto Un objeto de proyecto
 * @returns {Object} Un objeto de la forma { min: int, max: int }, representando la cantidad
 * mínima y máxima de baños en el proyecto.
 */
export function getMinAndMaxBanos(proyecto) {
  let banos = [];

  proyecto.tipologias.forEach((tipo) => {
    banos.push(tipo.banos);
  });

  return {
    min: Math.min(...banos),
    max: Math.max(...banos),
  };
}

/**
 * Devuelve la cantidad mínima y máxima de dormitorios en un proyecto.
 * @param {Object} proyecto Un objeto de proyecto
 * @returns {Object} Un objeto de la forma { min: int, max: int }, representando la cantidad
 * mínima y máxima de dormitorios en el proyecto.
 */
export function getMinAndMaxDormitorios(proyecto) {
  const dormitorios = [];

  proyecto.tipologias.forEach((tipo) => {
    dormitorios.push(tipo.dormitorios);
  });

  return {
    min: Math.min(...dormitorios),
    max: Math.max(...dormitorios),
  };
}

// Devuelve el precio de estacionamiento de auto (cubierto) del proyecto dado. Devuelve -1 si
// no pudo encontrar el precio.
export function getEstacionamientoAutoPrecio(proyecto) {
  return proyecto.precioMinEstacionamientosAuto;
}

export function getFirstEstacionamientoAuto(proyecto) {
  for (let i = 0; i < proyecto.secundarios.length; i++) {
    // Devolvemos el primer 'ESTACIONAMIENTO CUBIERTO' que encontramos. Las variaciones de precio
    // son mínimas y de todas maneras vamos a usarlo como una estimación.
    const secundario = proyecto.secundarios[i];

    if (secundario.nombre === "ESTACIONAMIENTO CUBIERTO") {
      return secundario;
    }
  }

  return null;
}

// Devuelve un array de ids (tomados del primer estacionamiento de auto del proyecto) de longitud 'length'.
// Devuelve un array vacío si ocurrió un error o length es menor o igual a 0.
export function getMockEstacionamientoAutoArray(proyecto, length) {
  if (length <= 0) {
    return [];
  }

  const firstEstacionamiento = getFirstEstacionamientoAuto(proyecto);

  if (!firstEstacionamiento) {
    return [];
  }

  const array = [];

  for (let i = 0; i < length; i++) {
    array.push(firstEstacionamiento.id);
  }

  return array;
}

// Devuelve el precio de estacionamiento de moto del proyecto dado. Devuelve -1 si
// no pudo encontrar el precio.
export function getEstacionamientoMotoPrecio(proyecto) {
  return proyecto.precioMinEstacionamientosMoto;
}

export function getFirstEstacionamientoMoto(proyecto) {
  for (let i = 0; i < proyecto.secundarios.length; i++) {
    const secundario = proyecto.secundarios[i];

    if (secundario.nombre === "MOTO ESTACIONAMIENTO") {
      return secundario;
    }
  }

  return null;
}

// Devuelve un array de ids (tomados del primer estacionamiento de moto del proyecto) de longitud 'length'.
// Devuelve un array vacío si ocurrió un error o length es menor o igual a 0.
export function getMockEstacionamientoMotoArray(proyecto, length) {
  if (length <= 0) {
    return [];
  }

  const firstEstacionamiento = getFirstEstacionamientoMoto(proyecto);

  if (!firstEstacionamiento) {
    return [];
  }

  const array = [];

  for (let i = 0; i < length; i++) {
    array.push(firstEstacionamiento.id);
  }

  return array;
}

// Devuelve el precio de bodega del proyecto dado. Devuelve -1 si no pudo encontrar el precio.
export function getBodegaPrecio(proyecto) {
  return proyecto.precioMinBodegas;
}

export function getFirstBodega(proyecto) {
  for (let i = 0; i < proyecto.secundarios.length; i++) {
    const secundario = proyecto.secundarios[i];

    if (secundario.nombre === "BODEGA") {
      return secundario;
    }
  }

  return null;
}

// Devuelve un array de ids (tomados de la primera bodega del proyecto) de longitud 'length'.
// Devuelve un array vacío si ocurrió un error o length es menor o igual a 0.
export function getMockBodegaArray(proyecto, length) {
  if (length <= 0) {
    return [];
  }

  const firstBodega = getFirstBodega(proyecto);

  if (!firstBodega) {
    return [];
  }

  const array = [];

  for (let i = 0; i < length; i++) {
    array.push(firstBodega.id);
  }

  return array;
}

// Toma un nombre y un valor y devuelve una string bien pluralizada (de acuerdo a las reglas del español).
// Ejemplos:
// pluralize('documento', 23) -> 'documentos'
// pluralize('documento', 0) -> 'documentos'
// pluralize('documento', 1) -> 'documento'
export function pluralize(name, quantity) {
  if (quantity === 1) {
    return name;
  }
  return `${name}s`;
}

// Toma un nombre y una cantidad, y devuelve una string '[nombre-pluralizado] [cantidad]'.
// Ejemplos:
// makePluralizedString('documento', 23) -> '23 documentos'
// makePluralizedString('documento', 0) -> '0 documentos'
// makePluralizedString('documento', 1) -> '1 documento'
export function makePluralizedString(name, quantity) {
  return `${quantity} ${pluralize(name, quantity)}`;
}

// Parsea la string "ubicacion_coordenadas" y las devuelve como un objeto { lat: float, lng: float }
// Si la string "ubicacion_coordenadas" no estaba seteada, devuelve null.
export function getCoordenadas(proyecto) {
  if (!proyecto.ubicacion_coordenadas) {
    return null;
  }

  const coords = proyecto.ubicacion_coordenadas.split(",");
  const lat = parseFloat(coords[0]);
  const lng = parseFloat(coords[1]);

  return {
    lat,
    lng,
  };
}

// Devuelve una copia del array pasado
export function copyArray(arr) {
  return arr.slice(0, arr.length);
}

// Devuelve una copia con los valores únicos (sin repetir) del array pasado
export function copyArrayUniqueValues(arr) {
  return [...new Set(arr)];
}

// Devuelve una copia del array para ser usado con el componente Select, e.g. para el array
// [1, 2, 3] devuelve [{ id: 1, name: 1 }, { id: 2, name: 2 }, { id: 3, name: 3 }]
export function copyArrayToSelectArray(arr) {
  return arr.map((item) => ({ id: item, name: item }));
}

// Toma una string de números y devuelve un RUT formateado.
// Ejemplo: toFormattedRut('312327862') --> '31.232.786-2'
export function toFormattedRut(numberStr) {
  const firstSection = numberStr.slice(0, 2);
  const secondSection = numberStr.slice(2, 5);
  const thirdSection = numberStr.slice(5, 8);
  let lastSection = "";

  // RUT puede ser de 8 o 9 dígitos
  if (numberStr.length > 8) {
    lastSection = "-" + numberStr.slice(8, numberStr.length);
  }

  return `${firstSection}.${secondSection}.${thirdSection}${lastSection}`;
}

// Capitaliza la primer letra de una string, sin tocar las demás letras.
export function capitalize(s) {
  if (typeof s !== "string") {
    return "";
  }

  return s.charAt(0).toUpperCase() + s.slice(1);
}

// Devuelve una nueva string capitalizada.
// Ejemplo: capitalize('ARTURO PRAT SANTIAGUILLO') --> 'Arturo Prat Santiaguillo'
export function capitalizeWholeStr(str) {
  const words = str.split(" ");

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].toLowerCase();
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }

  return words.join(" ");
}

// Scrollea suavemente hasta un elemento; `headerOffset` es opcional y defaultea al alto del
// header principal del sitio.
// `elementIdOrObject`, como el nombre lo sugiere, puede ser o bien una string con el id
// del elemento, o bien el propio elemento DOM per se.
export function scrollTo(elementIdOrObject, headerOffset = 220) {
  let element;

  switch (typeof elementIdOrObject) {
    case "string":
      element = document.getElementById(elementIdOrObject);
      break;

    case "object":
      element = elementIdOrObject;
      break;

    default:
      console.log(
        `tipo de elementIdOrObject desconocido: "${typeof elementIdOrObject}"`
      );
  }

  if (!element) {
    if (typeof elementIdOrObject === "string") {
      console.log(
        `scrollTo: Intentó scrollearse a elemento inexistente con id "${elementIdOrObject}"`
      );
    } else {
      console.log(`scrollTo: Intentó scrollerase a un elemento nulo`);
    }
    return;
  }

  const bodyRect = document.body.getBoundingClientRect().top;
  const elementRect = element.getBoundingClientRect().top;
  const elementPosition = elementRect - bodyRect;
  const offsetPosition = elementPosition - headerOffset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
}

// Scrollea suavamente hasta la parte superior de la pantalla
export function scrollToTop() {
  window.scrollTo({ behavior: "smooth", top: 0 });
}

// Convierte número de bytes a megabytes
export function bytesToMegabytes(bytes) {
  return bytes / 1024 / 1024;
}

// Redondea al múltiplo de 5 previo, e.g. round5(21) devuelve 20.
export function round5(x) {
  return Math.floor(x / 5) * 5;
}

// Pushea un objeto a un array `dataLayer` en el objeto `windows`, creándolo si no existe
export function dataLayerPush(object) {
  if (!window.dataLayer) {
    console.log(
      `Se intentó pushear data a dataLayer antes de que estuviera inicializado!`
    );
  }
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(object);
}

// Hack para arreglar un problema de z-fighting con el header de /place. Ver comentarios en la función
// `onQuienesSomosClick` en Header.js
export function placeHeaderFix() {
  const placeHeader = document.getElementById("place-header-header");
  if (placeHeader) {
    placeHeader.style.zIndex = 499;
  }
}

// Ordena in-place un array de tipologías, de menor a mayor. El ordenamiento se hace de acuerdo
// a estos campos: dormitorios, baños, personas, superficie. Por ejemplo, si dos tipologías tienen
// la misma cantidad de dormitorios, comparamos los baños para desempatar, and so on.
export function sortTipologiaArray(tipologiaArray) {
  return tipologiaArray.sort((a, b) => {
    if (a.dormitorios < b.dormitorios) {
      return -1;
    }

    if (a.dormitorios > b.dormitorios) {
      return 1;
    }

    // a.dormitorios === b.dormitorios, intentar desempatar con baños
    if (a.banos < b.banos) {
      return -1;
    }

    if (a.banos > b.banos) {
      return 1;
    }

    // a.banos === b.banos, intentar desempatar con personas
    if (a.personas < b.personas) {
      return -1;
    }

    if (a.personas > b.personas) {
      return 1;
    }

    // a.personas === b.personas, intentar desempatar con superficie
    if (a.superficie < b.superficie) {
      return -1;
    }

    if (a.superficie > b.superficie) {
      return 1;
    }

    // No queda nada con qué desempatar, todos los campos eran iguales
    return 0;
  });
}

// Desactiva el scrolling del body.
export function disableBodyScroll() {
  const body = document.getElementsByTagName("body")[0];
  body.style.position = "fixed";
}

// Restaura el scrolling del body.
export function enableBodyScroll() {
  const body = document.getElementsByTagName("body")[0];
  body.style.position = "initial";
}

// Toma una string en_snake_case y la devuelve enCamelCase
export function snakeToCamel(str) {
  return str.replace(/([-_]\w)/g, (g) => g[1].toUpperCase());
}

// Convierte una string de fecha en formato US (mm-dd-yyyy) a una string con fecha
// en formato español (dd-mm-yyyy)
export function convertUsDateToSpanishDate(usDate) {
  const strArr = usDate.split("-");

  const rearranged = [
    strArr[1], // dd
    strArr[0], // mm
    strArr[2], // yyyy
  ];

  return rearranged.join("-");
}

// Convierte una string de fecha en formato español (dd-mm-yyyy) a una string con fecha
// en formato US (mm-dd-yyyy)
export function convertSpanishDateToUsDate(spanishDate) {
  const strArr = spanishDate.split("-");

  const rearranged = [
    strArr[1], // mm
    strArr[0], // dd
    strArr[2], // yyyy
  ];

  return rearranged.join("-");
}

// Convierte una string de fecha en formato español (dd-mm-yyyy) a una string con fecha
// en formato US (yyyy/mm/dd)
export function convertDateToUsDateFormat(spanishDate) {
  const strArr = spanishDate.split("-");
  const rearranged = [
    strArr[2], // mm
    strArr[1], // dd
    strArr[0], // yyyy
  ];

  return rearranged.join("/");
}

// Devuelve la diferencia en días entre firstDate y secondDate. El resultado será positivo si
// firstDate es una fecha más tardía que secondDate, cero si son iguales, o negativo si
// firstDate es una fecha más temprana que secondDate.
// Asume que firstDate y secondDate son objetos Date.
export function getTimeDeltaInDays(firstDate, secondDate) {
  const deltaTime = firstDate.getTime() - secondDate.getTime();
  const deltaDays = Math.trunc(deltaTime / (1000 * 3600 * 24));

  return deltaDays;
}

export function isEmptyObj(obj) {
  return Object.keys(obj).length === 0;
}

// Busca un proyecto por nombre en un array de proyectos. Devuelve null si no encuentra nada.
export function getProyectoByNombre(proyectoArray, nombre) {
  for (const currProyecto of proyectoArray) {
    if (currProyecto.proyecto_nombre.trim() === nombre.trim()) {
      return currProyecto;
    }
  }

  return null;
}

// Busca un proyecto por id en un array de proyectos. Devuelve null si no encuentra nada.
export function getProyectoById(proyectoArray, proyectoId) {
  for (const currProyecto of proyectoArray) {
    if (currProyecto.id === proyectoId) {
      return currProyecto;
    }
  }

  return null;
}

// Devuelve true si el producto es un estacionamiento, false otherwise.
export function productoIsEstacionamiento(tipoProducto) {
  switch (tipoProducto) {
    case "ESTACIONAMIENTO CUBIERTO":
    case "ESTACIONAMIENTO CUBIERTO DISCAPACITADO":
    case "ESTACIONAMIENTO DESCUBIERTO":
    case "ESTACIONAMIENTO COMERCIAL":
    case "MOTO ESTACIONAMIENTO":
    case "ESTACIONAMIENTO MOTOS":
      return true;

    default:
      return false;
  }
}

// Devuelve true si el producto es una bodega, false otherwise.
export function productoIsBodega(tipoProducto) {
  switch (tipoProducto) {
    case "BODEGA":
    case "BODEGA COMERCIAL":
      return true;

    default:
      return false;
  }
}

// Devuelve un objeto { expirationInDays, expired } con la información de expiración de una cotización.
// Asume que 'fechaCreacion' es una string de fecha en formato español (dd-mm-yyyy).
export function getCotizacionExpirationInfo(fechaCreacion) {
  const now = new Date();
  const then = new Date(
    convertSpanishDateToUsDate(fechaCreacion).replace(/-/g, "/")
  );

  const deltaDays = getTimeDeltaInDays(now, then);

  const expirationInDays = COTIZACIONES_DAYS_VALID - deltaDays;

  const expired = expirationInDays <= 0;

  return {
    expirationInDays,
    expired,
  };
}

// Devuelve true si una cotización está vencida y ya pasó el período de gracia, false en caso contrario.
export function cotizacionIsPastGracePeriod(cotizacion) {
  return (
    cotizacion.expired &&
    Math.abs(cotizacion.expirationInDays) > COTIZACIONES_GRACE_PERIOD_IN_DAYS
  );
}

// Filtra 'cotizacionesArray' de acuerdo a los filtros pasados, devolviendo un nuevo array, sin modificar
// 'cotizacionesArray'. Asume que 'filtros' es un objeto que tiene la forma { ubicaciones, edificios, ordenar },
// en donde cada objeto corresponde al filtro de Redux correspondiente, i.e. filtroUbicaciones, filtroEdificios,
// etc., en donde cada filtro es opcional (i.e. si se pasa un objeto vacío, no se filtra nada y se devuelve
// el array original).
export function filterCotizaciones(cotizacionesArray, filtros) {
  // Creamos una copia de cotizacionesArray
  let filteredCotizaciones = [...cotizacionesArray];

  if (filtros.ubicaciones && filtros.ubicaciones.length > 0) {
    filteredCotizaciones = filteredCotizaciones.filter((cotizacion) =>
      filtros.ubicaciones.includes(cotizacion.proyecto.comuna)
    );
  }

  if (filtros.edificios && filtros.edificios.length > 0) {
    filteredCotizaciones = filteredCotizaciones.filter((cotizacion) =>
      filtros.edificios.includes(cotizacion.proyecto.proyecto_nombre)
    );
  }

  if (filtros.ordenar) {
    console.log(`filtros.ordenar: ${JSON.stringify(filtros.ordenar)}`);

    switch (filtros.ordenar) {
      case "Recomendados":
        break;

      case "Menor precio":
        filteredCotizaciones = filteredCotizaciones.sort(
          (cotizacionA, cotizacionB) => {
            return (
              cotizacionA.departamento.precio - cotizacionB.departamento.precio
            );
          }
        );
        break;

      case "Mayor precio":
        filteredCotizaciones = filteredCotizaciones.sort(
          (cotizacionA, cotizacionB) => {
            return (
              cotizacionB.departamento.precio - cotizacionA.departamento.precio
            );
          }
        );
        break;

      case "Más solicitados":
        break;

      default:
        console.log(
          `filterCotizaciones: Filtro desconocido "${filtros.ordenar}"`
        );
    }
  }

  return filteredCotizaciones;
}

// Quita todos los carácteres que no son dígitos (números) de una string
export function deleteNonDigits(str) {
  return str.replace(/\D/g, "");
}

// Toma un entero y devuelve una string representando la hora "en punto".
// Ejemplo: getHourStr(9) -> "9:00"
export function getHourStr(hour) {
  return `${hour}:00`;
}

// Toma una string de tipo de documento (tal cual están definidas en `TIPO_DOCUMENTO` en
// src/config/constant.js) y devuelve el id (un int) correspondiente que identifica ese tipo
// de documento en la API de Levelges. Devuelve -1 si la string no es reconocida como un tipo válido
// de documento.
export function documentTypeStrToId(documentType) {
  switch (documentType) {
    case "RUT":
      return 1;

    case "DNI":
      return 2;

    case "Pasaporte":
      return 3;

    default:
      return -1;
  }
}

// Obtiene la string de tipo de documento para el id de tipo de documento dado.
// Si el id no representa un tipo de documento válido, devuelve una string vacía ''.
// Ejemplo: getTipoDocumentoStr(1) -> "RUT"
export function getTipoDocumentoStr(tipoDocumentoId) {
  switch (tipoDocumentoId) {
    case 1:
      return "RUT";

    case 2:
      return "DNI";

    case 3:
      return "Pasaporte";

    default:
      console.log(
        `getTipoDocumentoStr -> id de documento inválido: ${tipoDocumentoId}`
      );
      return "";
  }
}

// Obtiene la string de tipo de persona para el id de tipo de persona dado.
// Si el id no representa un tipo de persona válido, devuelve una string vacía ''.
// Ejemplo: getTipoPersonaStr(0) -> "Persona-1"
export function getTipoPersonaStr(tipoPersonaId) {
  if (tipoPersonaId < 0 || tipoPersonaId > TIPO_PERSONA.length - 1) {
    return "";
  }

  return TIPO_PERSONA[tipoPersonaId];
}

// Devuelve la URL de la página de evaluación para una cotización de un usuario
export function getEvaluationUrl(levelClienteId, cotizacionId) {
  return `${LEVEL_EVALUACION_BASE_URL}/${levelClienteId}/${cotizacionId}`;
}

// Toma un objeto de request data y devuelve un nuevo objeto que tiene agregada la info de
// autenticación necesaria para que el request sea autenticado. 'state' tiene que ser un
// objeto con el estado de Redux.
export function getAuthenticatedRequestData(requestData, state) {
  const authenticatedRequest = { ...requestData };

  const { user } = state;

  authenticatedRequest.auth = {
    clienteid: user.levelClienteId,
    email: user.email,
  };

  return authenticatedRequest;
}

// Devuelve una cotización mock. Útil para testing.
export function getMockCotizacion() {
  return {
    departamento: {
      gastoComun: 48990,
      precio: 295000,
    },
    estacionamientos: [],
    bodegas: [],
    proyecto: {
      direccion: "PORTUGAL 755, SANTIAGO",
      comuna: "Santiago",
    },
    tipologia: {
      galeria: [
        { url: "/uploads/gimnasio_992533c774.jpeg", alternativeText: "" },
      ],
    },
  };
}

// Carga nuevo contenido detectado por el service worker, enviando un evento de SKIP_WAITING al
// service worker y recargando la página. 'waitingWorker' tiene que ser el service worker
// que está en estado de espera.
export function serviceWorkerLoadNewContent(waitingWorker) {
  console.log(
    `Nuevo contenido detectado! Enviando señal de SKIP_WAITING al service worker y refrescando página...`
  );
  waitingWorker.postMessage({ type: "SKIP_WAITING" });
  //window.location.reload();
}

// Actualiza un titular en Strapi. Asume que 'titular' es un objeto de la forma
// { clienteid, nombre, apellidoPaterno, tipoDocumento, numeroDocumento, email,
// tipoPersona, telefono, rentaMensual, rentasAvales, rentasComplementos,
// apellidoMaterno, nacionalidad, genero, profesion, actividad, tipoTrabajador,
// region, ciudad, comuna, direccion, numeroPisoDepto, poblacion,
// fechaInicioContrato, clientePep, mesesArriendo, mesesArriendoId,
// anoNacimiento }, y que 'reduxState' es el estado entero de Redux.
// Devuelve la promesa del request de axios.
export function updateTitular(titular, reduxState) {
  let requestData = {
    clienteid: titular.clienteid,
    nombre: titular.nombre,
    apellido_paterno: titular.apellidoPaterno,
    tipo_documento: titular.tipoDocumento,
    numero_documento: titular.numeroDocumento,
    email: titular.email,
    tipo_persona: titular.tipoPersona,
    telefono: titular.telefono,
    renta_mensual: titular.rentaTitular,
    rentas_avales: titular.rentasAvales,
    rentas_complementos: titular.rentasComplementos,
    apellido_materno: titular.apellidoMaterno,
    nacionalidad: titular.nacionalidad,
    genero: titular.genero,
    profesion: titular.profesion,
    actividad: titular.actividad,
    tipo_trabajador: titular.tipoTrabajador,
    region: titular.region,
    ciudad: titular.ciudad,
    comuna: titular.comuna,
    direccion: titular.direccion,
    numero_piso_depto: titular.numeroPisoDepto,
    poblacion: titular.poblacion,
    fecha_inicio_contrato: titular.fechaInicioContrato,
    cliente_pep: titular.clientePep,
    meses_arriendo: titular.mesesArriendo,
    meses_arriendo_id: titular.mesesArriendoId,
    ano_nacimiento: titular.anoNacimiento,
  };

  requestData = getAuthenticatedRequestData(requestData, reduxState);

  return axios.post(`${BACKEND_BASE_URL}/titulares`, requestData);
}

// Crea un aval en Strapi. Asume que el objeto 'aval' tiene la forma
// { clienteid, nombre, apellidoPaterno, apellidoMaterno, tipoDocumento,
// numeroDocumento, email, tipoPersona, telefono, rentaMensual, nacionalidad,
// genero, profesion, actividad, tipoTrabajador, region, ciudad, comuna,
// direccion, numeroPisoDepto, poblacion, fechaInicioContrato, clientePep,
// mesesArriendo, anoNacimiento, complemento }, y que 'reduxState' es el
// estado entero de Redux. Devuelve la promesa del request de axios.
export function createAval(aval, reduxState) {
  let requestData = {
    clienteid: aval.clienteid,
    nombre: aval.nombre,
    apellido_paterno: aval.apellidoPaterno,
    apellido_materno: aval.apellidoMaterno,
    tipo_documento: aval.tipoDocumento,
    numero_documento: aval.numeroDocumento,
    email: aval.email,
    tipo_persona: aval.tipoPersona,
    telefono: aval.telefono,
    renta_mensual: aval.rentaMensual,
    nacionalidad: aval.nacionalidad,
    genero: aval.genero,
    profesion: aval.profesion,
    actividad: aval.actividad,
    tipo_trabajador: aval.tipoTrabajador,
    region: aval.region,
    ciudad: aval.ciudad,
    comuna: aval.comuna,
    direccion: aval.direccion,
    numero_piso_depto: aval.numeroPisoDepto,
    poblacion: aval.poblacion,
    fecha_inicio_contrato: aval.fechaInicioContrato,
    cliente_pep: aval.clientePep,
    meses_arriendo: aval.mesesArriendo,
    ano_nacimiento: aval.anoNacimiento,
    complemento: aval.complemento,
  };

  requestData = getAuthenticatedRequestData(requestData, reduxState);

  return axios.post(`${BACKEND_BASE_URL}/avales`, requestData);
}

// Toma una string numérica y la devuelve en formato xxx.yyy.zzz-k
export function formatearRut(rut) {
  const tmp = quitarFormatoRut(rut);
  let rut_cleaned = tmp.substring(0, tmp.length - 1);
  let f = "";

  while (rut_cleaned.length > 3) {
    f = "." + rut_cleaned.substr(rut_cleaned.length - 3) + f;
    rut_cleaned = rut_cleaned.substring(0, rut_cleaned.length - 3);
  }

  return rut_cleaned.trim() === ""
    ? ""
    : rut_cleaned + f + "-" + tmp.charAt(tmp.length - 1);
}

// "Puntifica" una string de número de documento, agregando puntos cada 3 dígitos.
// Acepta tanto strings que contienen sólo números (e.g. DNI argentino) como strings
// que contienen guiones (e.g. RUT chileno) y puntos (i.e. strings ya puntificadas).
// En los casos con guiones, se puntifica únicamente la parte de la string anterior al guión.
// Si se pasa una string vacía / null / undefined, se devuelve una string vacía.
// 'tipoDocumento' debe ser uno de los sgtes. valores: 'RUT', 'DNI', 'Pasaporte'.
// Si el documento es de tipo RUT, se lo devuelve en formato 'xx.yyy.zzz-k'.
// Ejemplos:
// dottifyDocumento('36467528')   -> '36.467.528'
// dottifyDocumento('36467528-4') -> '36.467.528-4'
// dottifyDocumento('')           -> ''
export function dottifyDocumento(documentoStr, tipoDocumento) {
  if (!documentoStr || documentoStr === "") {
    return "";
  }

  switch (tipoDocumento) {
    case "RUT":
    case "DNI":
    case "Pasaporte":
      break;
    default:
      console.log(
        `dottifyDocumento -> Tipo de documento "${tipoDocumento}" inválido`
      );
      return "";
  }

  // La función 'formatearRut' está pensada para formatear un RUT entero y resulta un poco
  // confusa si se usa en RUTs parcialmente ingresados. Por eso, la aplicamos solamente si
  // tenemos un RUT "entero". De lo contrario, simplemente "dotificamos" el RUT parcial ingresado
  // tal como hacemos con el DNI/Pasaporte.
  if (tipoDocumento === "RUT" && documentoStr.length >= RUT_MIN_LEN) {
    return formatearRut(documentoStr);
  }

  const splitArr = documentoStr.split("-");

  const beforeDash = splitArr[0];
  const afterDash = splitArr[1];

  // El nuevo valor puede tener comas (por el llamado a toLocaleString que hacemos) y guiones (por
  // el caso de RUT), así que para que no falle la conversión a Number, removemos primero las comas
  // y los guiones.
  let onlyNumbersStr = quitarFormatoRut(beforeDash);

  if (!isNumberStr(onlyNumbersStr)) {
    return "";
  }

  let commifiedBeforeDash = Number(onlyNumbersStr).toLocaleString();

  // Reemplazamos las comas por puntos
  commifiedBeforeDash = commifiedBeforeDash.replace(/,/g, ".");

  let finalStr = commifiedBeforeDash;

  // Si afterDash es la string vacía, quiere decir que documentoStr terminaba en '-', así que agregamos
  // el guión final igualmente
  if (afterDash || afterDash === "") {
    finalStr = commifiedBeforeDash.concat("-", afterDash);
  }

  return finalStr;
}

// "Comifica" (i.e. agrega comas/puntos - dependiendo del locale - cada 3 dígitos) una string numérica.
// Devuelve una string vacía '' si la string no es numérica o es null / undefined / vacía.
export function commify(numericStr) {
  const decommified = decommify(numericStr);

  if (!decommified || decommified === "" || !isNumberStr(decommified)) {
    return "";
  }

  return Number(decommified).toLocaleString();
}

// "Decomifica" (quita las comas/puntos) una string. Se devuelve una *nueva* string, sin modificar
// la string original. Devuelve una string vacía '' si la string es null / undefined / vacía.
export function decommify(str) {
  if (!str || str === "") {
    return "";
  }

  return str.replace(/,|\./g, "");
}

// Toma un array de strings numéricas (pueden estar comificadas) y devuelve un nuevo array de números.
// Si el array tiene length 0, es nulo / undefined, o alguna de las strings del array no es numérica,
// se devuelve un array vacío.
export function convertStrArrayToNumArray(strArray) {
  if (!strArray || strArray.length === 0) {
    return [];
  }

  const numArray = [];

  for (const str of strArray) {
    const decommified = decommify(str);

    if (!isNumberStr(decommified)) {
      return [];
    }

    numArray.push(Number(decommified));
  }

  return numArray;
}

// Oculta el botón de WhatsApp de Cliengo
export function hideWspButton() {
  const wspIframe = document.getElementById(WSP_BUTTON_ID);

  if (wspIframe) {
    wspIframe.style.visibility = "hidden";
  }
}

// Muestra el botón de WhatsApp de Cliengo
export function showWspButton() {
  const wspIframe = document.getElementById(WSP_BUTTON_ID);

  if (wspIframe) {
    wspIframe.style.visibility = "visible";
  }
}

// Oculta el botón de chat de Cliengo
export function hideChatButton() {
  const chatIframe = document.getElementById(CHAT_BUTTON_ID);

  if (chatIframe) {
    chatIframe.style.visibility = "hidden";
  }
}

// Muestra el botón de chat de Cliengo
export function showChatButton() {
  const chatIframe = document.getElementById(CHAT_BUTTON_ID);

  if (chatIframe) {
    chatIframe.style.visibility = "visible";
  }
}

// Obtiene el mensaje de error de un request fallido a Strapi. Útil porque los mensajes de error difieren
// ligeramente entre los que envía Strapi y los custom que enviamos nosotros mismos desde el back.
// 'error' debe ser un objeto de error lanzado por axios. Si 'error' es nulo/undefined, se devuelve una string vacía.
export function getStrapiResponseErrorMessage(error) {
  if (!error) {
    return "";
  }

  switch (typeof error.response.data) {
    case "string":
      return error.response.data;

    case "object":
      return error.response.data.message;

    default:
      return "";
  }
}

//Convertimos los precios del formato 292000 a 292,00
export function formatPrice(price) {
  const nf = new Intl.NumberFormat();
  return nf.format(price).toLocaleString();
}

export const comunaFilterSetGet = (newFiltroUbicaciones) => {
  if (newFiltroUbicaciones.length === 0) return [];
  let listaComuna = "";
  for (let index = 0; index < newFiltroUbicaciones.length; index++) {
    const item = newFiltroUbicaciones[index];
    listaComuna += item + ",";
  }
  const comunasUnicas = [...new Set(listaComuna.slice(0, -1).split(","))];

  return comunasUnicas;
};

export const edificioFilterSetGet = (newFiltroEdificios) => {
  if (newFiltroEdificios.length === 0) return [];
  let listaEdificios = "";
  for (let index = 0; index < newFiltroEdificios.length; index++) {
    const item = newFiltroEdificios[index];
    listaEdificios += item + ",";
  }
  const edicifiosUnicas = [...new Set(listaEdificios.slice(0, -1).split(","))];

  return edicifiosUnicas;
};

export const resetFiltro = (dispatch) => {
  dispatch(setFiltroUbicaciones());
  dispatch(setFiltroBanos(INITIAL_BANOS));
  dispatch(setFiltroBodegas(null));
  dispatch(setFiltroDormitorios(INITIAL_DORMITORIOS));
  dispatch(setFiltroPrecioMin(null));
  dispatch(setFiltroPrecioMax(null));
  dispatch(setFiltroAmoblado(false));
  dispatch(setFiltroSuperficieMin(null));
  dispatch(setFiltroSuperficieMax(null));
  dispatch(setFiltroEdificios(null));
  dispatch(setFiltroBodegasCantidad(null));
  dispatch(setFiltroEstacionamientosPrecioMin(null));
  dispatch(setFiltroEstacionamientosPrecioMax(null));
  dispatch(setFiltroBodegasPrecioMin(null));
  dispatch(setFiltroBodegasPrecioMax(null));
  dispatch(setFiltroEstacionamientos(null));
  dispatch(setFiltroEntregaInmediata(false));
  dispatch(setFiltroEstrenar(false));
  dispatch(setFiltroMascota(false));
  dispatch(setFiltroOfertas(false));
};


export const parseBool = (valor) => {
  return JSON.parse(valor.toLowerCase());
}