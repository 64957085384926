/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import logoColors from '../../../../static/images/logo.png';
import { Button, TextInfo, Icon, Select, Spinner } from '../../../ui/index';
import CardDetalle from '../CardDetalle';
import ModalSolicitud from '../ayuda/ModalSolicitud';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import useResize from '../../../../hooks/useResize';

import {
  selectApellidoMaterno,
  selectNombre,
  selectNumeroDocumento,
  selectEstadoCivil,
  selectProfesion,
  selectNacionalidad,
  selectResumenContrato
} from '../../../../redux/userSlice';


import { crearCargosContratos } from '../../../../redux/proyectosSlice';

import { selectAmoblado, selectBodegas, selectEstacionamientosAuto, selectEstacionamientosMoto } from '../../../../redux/preevaluacionSlice';
import { formatPrice, getBodegaPrecio, getEstacionamientoAutoPrecio, getEstacionamientoMotoPrecio, getPrecioMensual, toFormattedRut } from '../../../../utils';
import './main.scss';
import { BREAKPOINT_MOBILE } from '../../../../config/constant';


const STEPS = {
  CONTRATOS_A_PAGAR: 0,
  COMPROBANTE_DE_PAGO: 1,
  FELICIDADES: 2,
};


const PagarCotizacion = ({ cotizacion, proyecto, resumenCotizacion, setShowContrato, setFirma, isCotizacion }) => {
  const dispatch = useDispatch()
  const [rizeWidth] = useResize();
  const [currentStep, setCurrentStep] = useState(STEPS.CONTRATOS_A_PAGAR);
  const [ayudaSolicitud, setAyudaSolicitud] = useState(false);
  const apellidoMaterno = useSelector(selectApellidoMaterno);
  const nombre = useSelector(selectNombre).split(" ")[0];
  const apellidoPaterno = useSelector(selectNombre).split(" ")[1];
  const numeroDocumento = useSelector(selectNumeroDocumento);
  const estadoCivil = useSelector(selectEstadoCivil)
  const profesion = useSelector(selectProfesion)
  const nacionalidad = useSelector(selectNacionalidad)
  //const resumenContrato = useSelector(selectResumenContrato)
  const titularFullname = `${nombre} ${apellidoPaterno} ${apellidoMaterno}`;
  const formattedRut = toFormattedRut(numeroDocumento);
  const { resumenContrato } = cotizacion
  const idReserva = resumenContrato.idReserva;

  console.log(cotizacion);

  const proyectoNombre = 'proyectoNombre';

  const [valueCuotaGarantia, setValueCuotaGarantia] = useState(1);
  const [valueCuotaComision, setValueCuotaComision] = useState(1);
  const [loadingPagoContrato, setLoadingPagoContrato] = useState(false);
  const [successCargoContrato, setSuccessCargoContrato ] = useState(false);
  const [textGarantia, setTextoGarantia] = useState('Monto Garantía');
  const [textComision, setTextoComision] = useState('Comisión');



  const precioArriendoStr = resumenContrato.montoArriendo
  const arriendomesSiguiente = resumenContrato.montoArriendo
  const gastoComunMesSiguiente = resumenContrato.montoGastoComun

  const mesesGarantiaStr = resumenContrato.mesesGarantia
  const comisionStr = resumenContrato.montoComision
  const reservaPagadaStr = resumenContrato.montoReservaPagado
  const gastosOperacionalesStr = resumenContrato.montoGastosOperacionales
  const montoGarantiaStr = resumenContrato.montoGarantia
  const arriendoPrimerMesStr = resumenContrato.montoArriendoPagar
  const gastosComunPrimerMesStr = resumenContrato.montoGastoComunPagar

  let totalStr = (comisionStr + gastosOperacionalesStr + montoGarantiaStr + arriendoPrimerMesStr + gastosComunPrimerMesStr) - reservaPagadaStr
  let totalPendienteCuotasSrt = 0;
  let mesesCuotasGarantia = [];
  let mesesCuotasComision = [];
  let mesCuotaGarantiaSel = 0;
  let mesCuotaComisionSel = 0;
  let subtotal = 0;
  
  if(resumenContrato.mesesCuotaGarantia.length > 0 ||  resumenContrato.mesesCuotaComision.length > 0){
      totalStr = gastosOperacionalesStr + arriendoPrimerMesStr + gastosComunPrimerMesStr;
      totalPendienteCuotasSrt = (montoGarantiaStr - reservaPagadaStr) + comisionStr;
      const sumaCuotas = comisionStr + montoGarantiaStr
      subtotal = totalStr + sumaCuotas;
      

      //select promo
      mesCuotaGarantiaSel = resumenContrato.mesesCuotaGarantia [resumenContrato.mesesCuotaGarantia .length - 1];
      mesCuotaComisionSel = resumenContrato.mesesCuotaComision [resumenContrato.mesesCuotaComision .length - 1];
    
      mesesCuotasGarantia = [{ id: mesCuotaGarantiaSel, name: mesCuotaGarantiaSel }];
      mesesCuotasComision = [{ id: mesCuotaComisionSel, name: mesCuotaComisionSel }];
    
  }
  
  
  const [totalPagar, setTotalPagar] = useState(totalStr);
  const [totalSubPagar, setTotalSubPagar] = useState(subtotal);
  const [totalPendienteCuota, setTotalpendientecuota] = useState(totalPendienteCuotasSrt);



  const [progressbar, setProgressbar] = useState([
    { active: true, checked: false },   // contratos a pagar
    { active: false, checked: false },  // comprobante de pago
    { active: false, checked: false },  // felicidades
  ]);


  const helpSolicitud = () => {
    setAyudaSolicitud(ayudaSolicitud ? false : true);
  }

  const previousStep = () => {
    setCurrentStep(currentStep - 1);
  }

  const nextStep = () => {
    //window.open('https://ww3.servipag.com/pagoenlinea/portal/desktop/public/todas/!ut/p/b1/04_Sj9CPykssy0xPLMnMz0vMAfGjzOKNgs0CLZ0MHQ38zQPcDBx9gs1CzAK9Df0DTYAKIvEo8DanTL-HEXH6DXAARwNC-r30o9Jz8pOAXg3Xj8KrGOQXvApAjgUrwOMaP4_83FT9gtzQ0IjKYE9dR0VFADNEynw!/dl4/d5/L2dBISEvZ0FBIS9nQSEh/?idServicio=47&idBiller=151#', '_blank').focus();
    setCurrentStep(currentStep + 1);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  // Actualizar barrar de progreso cuando cambia el currentStep
  useEffect(() => {
    switch (currentStep) {
      case STEPS.CONTRATOS_A_PAGAR:
        setProgressbar([
          { active: true, checked: false },   // contratos a pagar
          { active: false, checked: false },  // comprobante de pago
          { active: false, checked: false },  // felicidades
        ]);
        break;

      case STEPS.COMPROBANTE_DE_PAGO:
        setProgressbar([
          { active: true, checked: true },    // contratos a pagar
          { active: true, checked: false },   // comprobante de pago
          { active: false, checked: false },  // felicidades
        ]);
        break;

      case STEPS.FELICIDADES:
        setProgressbar([
          { active: true, checked: true },  // contratos a pagar
          { active: true, checked: true },  // comprobante de pago
          { active: true, checked: true },  // felicidades
        ]);
        break;

      default:
        break;
    }
  }, [currentStep]);

  const onContratosPagarHeaderClick = () => {
    if (currentStep > STEPS.CONTRATOS_A_PAGAR) {
      setCurrentStep(STEPS.CONTRATOS_A_PAGAR);
    }
  }

  const onComprobantePagoHeaderClick = () => {
    if (currentStep > STEPS.COMPROBANTE_DE_PAGO) {
      setCurrentStep(STEPS.COMPROBANTE_DE_PAGO);
    }
  }

  const handleEnviarContrato = () => {
    setLoadingPagoContrato(true);
    
    const formDataPost = {
      idReserva: idReserva,
      mesesCuotaComision: valueCuotaComision,
      mesesCuotaGarantia: valueCuotaGarantia
    }

    dispatch(crearCargosContratos(formDataPost)).then((response) => {
      setLoadingPagoContrato(false);
      if(response == 'Ocurrió un error'){
        return false;
      } 
      setSuccessCargoContrato(true);
    });
  }

  const handleRedirectContrato = () => {
    const urlLink = 'https://ww3.servipag.com/pagoenlinea/portal/desktop/public/todas/!ut/p/b1/04_Sj9CPykssy0xPLMnMz0vMAfGjzOKNgs0CLZ0MHQ38zQPcDBx9gs1CzAK9Df0DTYAKIvEo8DanTL-HEXH6DXAARwNC-r30o9Jz8pOAXg3Xj8KrGOQXvApAjgUrwOMaP4_83FT9gtzQ0IjKYE9dR0VFADNEynw!/dl4/d5/L2dBISEvZ0FBIS9nQSEh/?idServicio=47&idBiller=151#'
    window.open(urlLink, '_blank');
  }

  const calcularCuotas = (valueCuotaComision, valueCuotaGarantia) => {
    if(valueCuotaComision > 1 || valueCuotaGarantia > 1){
      let sumaCuotas = 0;

      if(valueCuotaGarantia > 1 && valueCuotaComision > 1){
        sumaCuotas = comisionStr + montoGarantiaStr
      } else {
          if(valueCuotaGarantia > 1){
            sumaCuotas = montoGarantiaStr;
          } 
          if(valueCuotaComision > 1){
            sumaCuotas = comisionStr;
          }
      }
      const totalPagarFirma = (precioArriendoStr - reservaPagadaStr) +  sumaCuotas;
      const subtotal = totalPagarFirma + sumaCuotas;
      setTotalpendientecuota(sumaCuotas);
      setTotalSubPagar(subtotal);
      setTotalPagar(totalPagarFirma);

    } else {
      setTotalPagar(totalStr);
    }

  }


  const handleValueGarantia = (e) => {
    const valorGarantia = e.target.value;
    if(valorGarantia > 1){
      setTextoGarantia(`Monto Garantía en ${valorGarantia} Cuotas`)
      calcularCuotas(valueCuotaComision, valorGarantia);

    } else {
      setTextoGarantia(`Monto Garantía`)
      calcularCuotas(valueCuotaComision, 1);

    }

    setValueCuotaGarantia(valorGarantia);

  }


  const handleValueComision = (e) => {
    const valorComision = e.target.value;
    if(valorComision > 1){
      setTextoComision(`Comisión en ${valorComision} Cuotas`)
      calcularCuotas(valorComision, valueCuotaGarantia);
    } else {
      setTextoComision(`Comisión`)
      calcularCuotas(1, valueCuotaGarantia);
    }
    
    setValueCuotaComision(valorComision);
  }

  return (
    <>
      <div className="_preContainer pagar-contrato">
        <div className={`_headerPre ${rizeWidth <= BREAKPOINT_MOBILE ? 'headerPreMovile':'' }`}>
          <div className="container">
            <div className="row">
              <div className="_header-container">
                <div className="_logo">
                  <a href="/"><img className={`_logo-white`} src={logoColors} alt="Logo Level" /></a>
                </div>
                <div className="_step-pre-evaluacion">
                  <div className={`_itemStep-pre ${progressbar[STEPS.CONTRATOS_A_PAGAR].active ? 'active' : ''}`}>
                    <span className={`_circle ${progressbar[STEPS.CONTRATOS_A_PAGAR].checked ? 'checkedReady' : ''}`}></span>
                  </div>
                  <div className={`_itemStep-pre ${progressbar[STEPS.COMPROBANTE_DE_PAGO].active ? 'active' : ''}`}>
                    <span className={`_circle ${progressbar[STEPS.COMPROBANTE_DE_PAGO].checked ? 'checkedReady' : ''}`}></span>
                  </div>
                  <div className={`_itemStep-pre ${progressbar[STEPS.FELICIDADES].active ? 'active' : ''}`}>
                    <span className={`_circle ${progressbar[STEPS.FELICIDADES].checked ? 'checkedReady' : ''}`}></span>
                  </div>
                </div>
                <div className="_option-pre">
                  <a  onClick={()=>setShowContrato(false)}>Guardar y salir</a>
                  <Button onClick={helpSolicitud} color="btn-blue-dark" size="extra-small" endIcon="icon-question">AYUDA</Button>
                </div>
              </div>
              {currentStep < STEPS.FELICIDADES && (
                <>
                  <div className={`_main-container-pre ${rizeWidth <= BREAKPOINT_MOBILE && cotizacion.status.hasOwnProperty('contrato') ? 'pay':'' }`} style={{ height: rizeWidth <= BREAKPOINT_MOBILE  ? '':'68vh' }}>
                  { rizeWidth <= BREAKPOINT_MOBILE && cotizacion.status.hasOwnProperty('contrato')   && 
                              cotizacion.status.contrato == 0 &&
                            <div className="successContratoEnviadoPagado">
                                <Icon name="icon-tick" color="green" size={60} />
                                <p className="title">Pago del contrato</p>
                                <small className="nota">Presione en el botón de abajo para hacer el pago correspondiente, una vez ingresado coloque el número de RUT.</small>
                                <span className="priceTotal">Total a pagar. <strong>${formatPrice(totalPagar)}</strong></span>
                                <Button onClick={handleRedirectContrato} color="btn-orange" size="extra-small" fullWidth={false}>IR A PAGAR CONTRATO</Button>
                            </div>
                          }
                    <div className="_item-fijo">
                      <div className="_fijo-titular">
                        <span className="_circle"></span>
                        {currentStep === STEPS.CONTRATOS_A_PAGAR ? 'Paga tus contratos' : 'Verifica tu pago'}
                      </div>
                      <p className="_info">Termina el proceso de arriendo online y recibe las llaves de tu nuevo hogar.</p>

{/*                       <Button color="btn-blue-dark" size="extra-small" className="btn flex justify-center mb-3" fullWidth={true} endIcon="icon-question">¿Cómo elegir unidades?</Button>
 */}
                      <CardDetalle proyecto={cotizacion} detalle={resumenCotizacion} isCotizacion={isCotizacion}/>

                      {currentStep > STEPS.CONTRATOS_A_PAGAR &&
                        <div onClick={previousStep} className="_option-form blue">
                          <TextInfo iconName="icon-left-arrow">ATRÁS</TextInfo>
                        </div>
                      }

                    </div>
                    <div className="_item-form">
                      <div className={`_step-form step-1 ${true ? 'active' : ''}`}>
                        <div className={`_itemStep-form ${currentStep > STEPS.CONTRATOS_A_PAGAR ? 'checked' : ''} ${currentStep === STEPS.CONTRATOS_A_PAGAR ? 'active' : ''}`}>
                          { successCargoContrato   && 
                            <div className="successContratoEnviadoPagado">
                                <Icon name="icon-tick" color="green" size={60} />
                                <p className="title">Contrato generado con exito.</p>
                                <small className="nota">Presione en el botón de abajo para hacer el pago correspondiente, una vez ingresado coloque el número de RUT.</small>
                                <span className="priceTotal">Total a pagar. <strong>${formatPrice(totalPagar)}</strong></span>
                                <Button onClick={handleRedirectContrato} color="btn-orange" size="extra-small" fullWidth={false}>IR A PAGAR CONTRATO</Button>
                            </div>
                          }

                         { rizeWidth > BREAKPOINT_MOBILE && cotizacion.status.hasOwnProperty('contrato')   && 
                              cotizacion.status.contrato == 0 &&
                            <div className="successContratoEnviadoPagado">
                                <Icon name="icon-tick" color="green" size={60} />
                                <p className="title">Pago del contrato</p>
                                <small className="nota">Presione en el botón de abajo para hacer el pago correspondiente, una vez ingresado coloque el número de RUT.</small>
                                <span className="priceTotal">Total a pagar. <strong>${formatPrice(totalPagar)}</strong></span>
                                <Button onClick={handleRedirectContrato} color="btn-orange" size="extra-small" fullWidth={false}>IR A PAGAR CONTRATO</Button>
                            </div>
                          }
                           
                          <div className="_step-header" onClick={onContratosPagarHeaderClick} >
                            <TextInfo textWeight="bold" size={18} color="black">
                              Tus contratos a pagar <Icon size={16} name="icon-tick" />
                            </TextInfo>
                            <div className="_info"><Icon name="icon-question" /></div>
                          </div>
                          <div className="_step-body">
                            <div>
                              <TextInfo textWeight="bold" size={14} color="black">
                                DATOS DEL CLIENTE
                              </TextInfo>
                            </div>
                            <div className="divider"></div>
                            <div className="flex margin-bottom-20">
                              <div className="one-third">
                                <TextInfo style={{ marginBottom: '10px' }} size={14} color="black">
                                  Nombre
                                </TextInfo><br />
                                <TextInfo textWeight="bold" size={14} color="black">
                                  {titularFullname}
                                </TextInfo>
                              </div>
                              <div className="one-third">
                                <TextInfo style={{ marginBottom: '10px' }} size={14} color="black">
                                  RUT Cliente
                                </TextInfo><br />
                                <TextInfo textWeight="bold" size={14} color="black">
                                  {formattedRut}
                                </TextInfo>
                              </div>
                              <div className="one-third">
                                <TextInfo style={{ marginBottom: '10px' }} size={14} color="black">
                                  Cotización
                                </TextInfo><br />
                                <TextInfo textWeight="bold" size={14} color="black">
                                  {cotizacion.id}
                                </TextInfo>
                              </div>
                            </div>
                            <div className="flex margin-bottom-20">
                              <div className="one-third">
                                <TextInfo style={{ marginBottom: '10px' }} size={14} color="black">
                                  Productos
                                </TextInfo><br />
                                <TextInfo textWeight="bold" size={14} color="black">
                                  719
                                </TextInfo>
                              </div>
                              <div className="one-third">
                                <TextInfo style={{ marginBottom: '10px' }} size={14} color="black">
                                  Nacionalidad
                                </TextInfo><br />
                                <TextInfo textWeight="bold" size={14} color="black">
                                  {nacionalidad}
                                </TextInfo>
                              </div>
                              <div className="one-third">
                                <TextInfo style={{ marginBottom: '10px' }} size={14} color="black">
                                  Estado Civil
                                </TextInfo><br />
                                <TextInfo textWeight="bold" size={14} color="black">
                                  {estadoCivil}
                                </TextInfo>
                              </div>
                            </div>
                            <div className="flex margin-bottom-20">
                              <div className="one-third">
                                <TextInfo style={{ marginBottom: '10px' }} size={14} color="black">
                                  Profesión
                                </TextInfo><br />
                                <TextInfo textWeight="bold" size={14} color="black">
                                  {profesion}
                                </TextInfo>
                              </div>
                            </div>

                         
                        { resumenContrato.mesesCuotaGarantia.length > 0 ||  resumenContrato.mesesCuotaComision.length > 0 && (
                          <>
                           <div className="divider"></div>
                            <div>
                              <TextInfo textWeight="bold" size={14} color="black">
                                COMISIÓN Y GARANTIA EN CUOTAS
                              </TextInfo>
                            </div>
                            <div className="divider"></div>
                            </>)
                          }

                            <div className="form-layout margin-bottom-10">
                              <div className='layout-group'>
                              { resumenContrato.mesesCuotaComision.length > 0 && (

                                  <div className="form-group full-width">
                                    <label>Cuotas de comisión</label>
                                    <Select
                                      value={mesCuotaComisionSel}
                                      data={mesesCuotasComision}
                                      onChange={handleValueComision} 
                                      placeholder="Seleccionar" 
                                      className="bold" 
                                      typeStyle="extra-small"
                                      fullWidth={true} noMargin={true}
                                    />
                                  </div>
                              )}
                               { resumenContrato.mesesCuotaGarantia.length > 0 && (
                                  <div className="form-group full-width">
                                    <label>Cuotas de garantia </label>
                                    <Select
                                      value={mesCuotaGarantiaSel}
                                      data={mesesCuotasGarantia}
                                      onChange={handleValueGarantia} 
                                      placeholder="Seleccionar" 
                                      className="bold" 
                                      typeStyle="extra-small"
                                      fullWidth={true} noMargin={true}
                                    />
                                  </div>
                               )}
                                </div>

                            </div>

                            {/* <div className="flex margin-bottom-10 space-between">
                              <div className="one-third">
                                <TextInfo size={14} color="black">
                                  Arriendo
                                </TextInfo>
                              </div>
                              <div className="one-third text-right">
                                <TextInfo size={14} color="orange" textWeight="bold">
                                  ${formatPrice(precioArriendoStr)}
                                </TextInfo>
                              </div>
                            </div> */}

                          <div className="divider"></div>
                         
                            {/* <div>
                              <TextInfo textWeight="bold" size={14} color="black">
                                RENTAS
                              </TextInfo>
                            </div>
                            <div className="divider"></div> */}
                            <div className="flex margin-bottom-10 space-between">
                              <div className="one-third">
                                <TextInfo size={14} color="black">
                                  Arriendo mes siguiente
                                </TextInfo>
                              </div>
                              <div className="one-third text-right">
                                <TextInfo size={14} color="orange" textWeight="bold">
                                  ${formatPrice(arriendomesSiguiente)}
                                </TextInfo>
                              </div>
                            </div>

                            <div className="flex margin-bottom-10 space-between">
                              <div className="one-third">
                                <TextInfo size={14} color="black">
                                  Gasto común mes siguiente
                                </TextInfo>
                              </div>
                              <div className="one-third text-right">
                                <TextInfo size={14} color="orange" textWeight="bold">
                                  ${formatPrice(gastoComunMesSiguiente)}
                                </TextInfo>
                              </div>
                            </div>
                            <div className="divider"></div>

                            <div>
                              <TextInfo textWeight="bold" size={14} color="black">
                                MONTO INICIO DEL CONTRATO
                              </TextInfo>
                            </div>
                            <div className="divider"></div>

                            <div className="flex margin-bottom-10 space-between">
                              <div className="one-third">
                                <TextInfo size={14} color="black">
                                  Meses Garantía
                                </TextInfo>
                              </div>
                              <div className="one-third text-right">
                                <TextInfo size={14} color="orange" textWeight="bold">
                                  {mesesGarantiaStr}
                                </TextInfo>
                              </div>
                            </div>
                            <div className="flex margin-bottom-10 space-between">
                              <div className="one-third">
                                <TextInfo size={14} color="black">
                                  {textComision}
                                </TextInfo>
                              </div>
                              <div className="one-third text-right">
                                <TextInfo size={14} color="orange" textWeight="bold">
                                  ${formatPrice(comisionStr)}
                                </TextInfo>
                              </div>
                            </div>
                            
                            <div className="flex margin-bottom-10 space-between">
                              <div className="one-third">
                                <TextInfo size={14} color="black">
                                  Gastos operacionales
                                </TextInfo>
                              </div>
                              <div className="one-third text-right">
                                <TextInfo size={14} color="orange" textWeight="bold">
                                  ${formatPrice(gastosOperacionalesStr)}
                                </TextInfo>
                              </div>
                            </div>
                            <div className="flex margin-bottom-10 space-between">
                              <div className="one-third">
                                <TextInfo size={14} color="black">
                                  {textGarantia}
                                </TextInfo>
                              </div>
                              <div className="one-third text-right">
                                <TextInfo size={14} color="orange" textWeight="bold">
                                  ${formatPrice(montoGarantiaStr)}
                                </TextInfo>
                              </div>
                            </div>
                            <div className="flex margin-bottom-10 space-between">
                              <div className="one-third">
                                <TextInfo size={14} color="black">
                                  Arriendo Primer Mes
                                </TextInfo>
                              </div>
                              <div className="one-third text-right">
                                <TextInfo size={14} color="orange" textWeight="bold">
                                  ${formatPrice(arriendoPrimerMesStr)}
                                </TextInfo>
                              </div>
                            </div>
                            <div className="flex margin-bottom-10 space-between">
                              <div className="one-third">
                                <TextInfo size={14} color="black">
                                  Gastos Común Primer Mes
                                </TextInfo>
                              </div>
                              <div className="one-third text-right">
                                <TextInfo size={14} color="orange" textWeight="bold">
                                  ${formatPrice(gastosComunPrimerMesStr)}
                                </TextInfo>
                              </div>
                            </div>
                            <div className="divider"></div>
                            <div className="flex margin-bottom-10 space-between">
                              <div className="one-third">
                                <TextInfo size={14} color="black">
                                  Reserva Pagada
                                </TextInfo>
                              </div>
                              <div className="one-third text-right">
                                <TextInfo size={14} color="orange" textWeight="bold">
                                  - ${formatPrice(reservaPagadaStr)}
                                </TextInfo>
                              </div>
                            </div>
                            { totalSubPagar > 0 && (
                              <>
                            <div className="divider"></div>
                            <div className="flex margin-bottom-10 space-between">
                              <div className="one-third">
                                <TextInfo size={14} color="black">
                                  Total Contrato
                                </TextInfo>
                              </div>
                              <div className="one-third text-right">
                                <TextInfo size={14} color="orange" textWeight="bold">
                                  ${formatPrice(totalSubPagar)}
                                </TextInfo>
                              </div>
                            </div>
                            <div className="flex margin-bottom-10 space-between">
                              <div className="one-third">
                                <TextInfo size={14} color="black">
                                  Total Pendiente cuotas
                                </TextInfo>
                              </div>
                              <div className="one-third text-right">
                                <TextInfo size={14} color="orange" textWeight="bold">
                                ${formatPrice(totalPendienteCuota)}
                                </TextInfo>
                              </div>
                            </div>
                            </>)}
                            {false && <div className="flex justify-center margin-top-20 margin-bottom-40">
                              <Button style={{ padding: '.295rem 2.2em' }} onClick={nextStep} color="btn-light-gray" size="extra-small" ><Icon name="icon-upload" />Subir comprobante de pago</Button>
                            </div>}
                          </div>
                        </div>
                      </div>
                      {currentStep === STEPS.CONTRATOS_A_PAGAR &&
                        <div className="_option-form white column">
                          <div className="pagoAttribute">
                          Total a pagar firma <strong>${formatPrice(totalPagar)}</strong>
                          </div>
                          <Button onClick={handleEnviarContrato} color="btn-orange" size="extra-small" fullWidth={true}>{ loadingPagoContrato ?  <Spinner msg='GENERANDO PAGO...' />:'PAGAR CONTRATO' }</Button>
                        </div>
                      }

                      <div className={`_step-form step-2 ${currentStep === STEPS.COMPROBANTE_DE_PAGO ? 'active' : ''}`}>
                        <div className={`_itemStep-form ${currentStep > STEPS.COMPROBANTE_DE_PAGO ? 'checked' : ''} ${currentStep === STEPS.COMPROBANTE_DE_PAGO ? 'active' : ''}`}>
                          <div className="_step-header" onClick={onComprobantePagoHeaderClick} >
                            <TextInfo textWeight="bold" size={18} color="black">
                              Comprobante de pago
                            </TextInfo>
                            <Icon name="icon-question" />
                          </div>
                          <div className="_step-body">
                            <div className="_form-departamento">
                              <div className="_header-form upload-box">
                                <Icon color="orange" size="33" name="icon-upload" />
                                <TextInfo textWeight="bold" size={14} color="default">Arrastra un archivo o</TextInfo>
                                <Button color="btn-outline-orange" size="extra-small" >SELECCIONA UN ARCHIVO</Button>
                                <TextInfo className="margin-top-20" size={12} color="default">Tamaño máximo de subida 98 MB</TextInfo>
                              </div>
                            </div>
                            <div className="_form-departamento">
                              <div className="_header-form flex space-between">
                                <div className="flex">
                                  <Icon color="orange" size="18" name="icon-paperclip" />
                                  <TextInfo textWeight="bold" size={14} color="default">nombre-de-archivo.jpg</TextInfo>
                                </div>
                                <Button color="btn-gray-opaco" size="extra-small" >QUITAR</Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {currentStep === STEPS.COMPROBANTE_DE_PAGO &&
                        <div className="_option-form white">
                          <Button onClick={nextStep} color="btn-orange" size="extra-small" className="btn bold" fullWidth={true} disabled={false}>SIGUIENTE PASO</Button>
                        </div>
                      }
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {ayudaSolicitud && <ModalSolicitud close={helpSolicitud} />}
    </>
  );
};

export default PagarCotizacion;
