/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FEATURE_ENABLED } from '../../config/constant';
import { Icon } from '../ui/index';
import './styles/SocialLinks.scss';


const SocialLinks = ({ openShared }) => {

  const onPrinterClick = () => {
    window.print();
  }

  return (
    <div className="place-header-links">
      {FEATURE_ENABLED.PRINT && <a><Icon onClick={onPrinterClick} name="icon-printer" border="round" background="white" width={28} size="14" /></a>}
      <a onClick={openShared}><Icon name="icon-share-option" border="round" background="white" width={28} size="14" /></a>
      {FEATURE_ENABLED.LIKES && <a><Icon name=" icon-heart" border="round" background="white" width={28} size="14" /></a>}
    </div>
  );
};

export default SocialLinks;
