import React from 'react';
import { BREAKPOINT_MOBILE } from '../../../config/constant';
import useResize from '../../../hooks/useResize';
import EdificiosSlider from './EdificiosSlider';
import styles from './styles/NuestrosEdificios.module.scss';


const NuestrosEdificios = ({ nosotrosData }) => {

  const [rizeWidth] = useResize();

  return (
    <div className="container" style={{ padding: '0' }}>
      {
        rizeWidth <= BREAKPOINT_MOBILE &&
        <h1 className={styles.mobileHeader}>Nuestros edificios</h1>
      }
      <div className={styles.topContainer}>
        <div className={styles.flexContainer}>
          {
            rizeWidth > BREAKPOINT_MOBILE &&

            <div className={styles.description}>
              <h1>Nuestros edificios</h1>
              <p>{nosotrosData.edificiosSliderText}</p>
            </div>
          }
          <div className={styles.edificioCard}>
            <EdificiosSlider />
          </div>
          {
            rizeWidth <= BREAKPOINT_MOBILE &&

            <div className={styles.mobileDescription}>
              <p>{nosotrosData.edificiosSliderText}</p>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default NuestrosEdificios;
