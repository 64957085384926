import React from 'react';
import { Title } from '../ui/index';
import './styles/CardPlace.scss';
import CardInfoPlace from './CardInfoPlace';


const CardPlace = ({ proyecto }) => {

  return (
    <div className="row">
      <div className="place-container">
        <Title id="card-place-edificio-header" className="muli" size="30" marginBotton={10}>Edificio</Title>
        <div className="place-place-wrapp">
          <div className="place-text">
            <p>{proyecto ? proyecto.descripcion : ''}</p>
          </div>
          <CardInfoPlace proyecto={proyecto} />
        </div>                    
      </div>
    </div>
  );
};

export default CardPlace;
