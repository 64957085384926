import React, { useEffect } from 'react';
import './styles/PlaceHeaderMobile.scss'
import imgHeader from '../static/images/sliderPlace/place4.jpg'
import { Title, Icon } from './ui/index';
import SocialLinks from './containers/SocialLinks';
import { MENU_ENABLED } from '../config/constant';


const PlaceHeaderMobile = ({ openShared, selectDetails, proyecto }) => {

  const handleNavLinks = () => {
    const navLinks = document.querySelectorAll('.__nav-header a');
    if (navLinks.length > 0) {
      navLinks.forEach(item => {
        item.addEventListener('click', (e) => {
          e.preventDefault();

          const activeRemove = document.querySelector('.__nav-header a.active');

          if (activeRemove) {
            activeRemove.removeClass('active');
          }

          e.target.addClass('active');
        });
      });
    }
  }

  useEffect(() => {
    handleNavLinks();
  }, []);


  return (
    <div className="container">
      <div className="__place-header-stick">
        <div className="__place-bg" style={{ backgroundImage: 'url(' + imgHeader + ')' }}>
          <div className="row">
            <Title size="18" className="muli white">{proyecto ? proyecto.proyecto_nombre : ''}</Title>
            <SocialLinks openShared={openShared} />
          </div>
        </div>
        <div className="__place-header-nav">
          <div className="row">
            <div className="__nav-header">
              <a href="# " onClick={() => selectDetails(0)} className="active"><Icon name="icon-hotel" background="gray" border="round" width={40} size="15" /><span className="text-f">Edificio</span></a>
              <a href="# " onClick={() => selectDetails(1)}><Icon name="icon-bed" border="round" width={40} size="15" /><span className="text-f">Departamentos</span></a>
              <a href="# " onClick={() => selectDetails(2)}><Icon name="icon-map-open" border="round" width={40} size="15" /><span className="text-f">Ubicación</span></a>
              {MENU_ENABLED.PLACE_VISITAR && <a href="# " onClick={() => selectDetails(3)}><Icon name="icon-clock" border="round" width={40} size="15" /><span className="text-f">Visitar</span></a>}
            </div>
          </div>
        </div>
      </div>

      <div className="__place-header-stick show">
        <div className="__place-bg" style={{ backgroundImage: 'url(' + imgHeader + ')' }}>
          <div className="row">
            <Title size="20" className="muli white">{proyecto ? proyecto.proyecto_nombre : ''}</Title>
            <SocialLinks openShared={openShared} />
          </div>
        </div>
        <div className="__place-header-nav">
          <div className="row">
            <div className="__nav-header">
              <a href="# " onClick={() => selectDetails(0)} className="active"><Icon name="icon-hotel" background="gray" border="round" width={40} size="15" /><span className="text-f">Edificio</span></a>
              <a id="mobile-header-deptos-anchor" href="# " onClick={() => selectDetails(1)}><Icon name="icon-bed" border="round" width={40} size="15" /><span className="text-f">Departamentos</span></a>
              <a href="# " onClick={() => selectDetails(2)}><Icon name="icon-map-open" border="round" width={40} size="15" /><span className="text-f">Ubicación</span></a>
              {MENU_ENABLED.PLACE_VISITAR && <a href="# " onClick={() => selectDetails(3)}><Icon name="icon-clock" border="round" width={40} size="15" /><span className="text-f">Visitar</span></a>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlaceHeaderMobile;
