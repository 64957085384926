import React, { useRef } from 'react';
import './styles/Comentarios.scss';
import Carousel from 'react-elastic-carousel';
import { TextInfo, Button } from '../ui/index';
import { BREAKPOINT_MOBILE } from '../../config/constant'
import { useSelector } from 'react-redux';
import { selectComentarios } from '../../redux/proyectosSlice';


const Comentarios = ({ rizeWidth }) => {
  const carouselRef = useRef();
  const comentarios = useSelector(selectComentarios);
  let itemsSlider = <div></div>;

  if (comentarios) {
    itemsSlider = comentarios.map((comentario) => {
      const estrellas = [];

      for (let i = 0; i < comentario.estrellas; i++) {
        estrellas.push(
          <span key={i} className="start active"></span>
        );
      }

      return (
        <div key={comentario.id} className="item-slider" >
          <div className="title"><TextInfo iconColor="#FF6D00" iconName="icon-quotation-mark">{comentario.nombre} {comentario.apellido}</TextInfo></div>
          <p>{comentario.descripcion}</p>
          <div className="footer">
            <TextInfo>{comentario.direccion}</TextInfo>
            <div className="start--">
              {estrellas}
            </div>
          </div>
        </div>
      );
    });
  }

  return (
    <div className="container">
      <section className="comentarios">
        <div className="row">
          <div className="container-comentarios">
            <h2>Experiencias Level</h2>
            <div className="box-comentario">
              <Carousel
                ref={carouselRef}
                itemsToScroll={1}
                itemsToShow={1}
                enableAutoPlay={false}
                showArrows={false}
                autoPlaySpeed={5000}
                onResize={currentBreakPoint => {
                  if (rizeWidth < BREAKPOINT_MOBILE) {
                    const sliderItem = document.querySelector('.item-slider').clientHeight;
                    document.querySelector('.slider-comentarios .rec-carousel').style.height = `${sliderItem}px`;
                  }
                }}
                className="slider-comentarios"
              >
                {itemsSlider}
              </Carousel>
            </div>
            <div className="paginacion">
              <Button color='btn-white-gray' size="small-icon" startIcon="icon-left-arrow" onClick={() => carouselRef.current.slidePrev()}></Button>
              <Button color='btn-white-gray' size="small-icon" startIcon="icon-button-arrow-ight" onClick={() => carouselRef.current.slideNext()}></Button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Comentarios;
