import React from 'react';
import { useSelector } from 'react-redux';
import { selectAmoblado, selectBodegas, selectEstacionamientosAuto, selectEstacionamientosMoto }
  from '../redux/preevaluacionSlice';
import { getBodegaPrecio, getEstacionamientoAutoPrecio, getEstacionamientoMotoPrecio, getPrecioMensual }
  from '../utils';

  
// Componente que muestra el precio mensual de un proyecto, teniendo en cuenta los productos
// secundarios seleccionados (estacionamientos y bodegas) y el filtro de amoblado.
// El precio es mostrado en un <span> con un signo '$' inicial, y nada más.
const PrecioMensual = ({ proyecto }) => {
  const amoblado = useSelector(selectAmoblado);
  const estacionamientosAuto = useSelector(selectEstacionamientosAuto);
  const estacionamientosMoto = useSelector(selectEstacionamientosMoto);
  const bodegas = useSelector(selectBodegas);
  let precioMensual = 0;
  
  if (proyecto) {
    precioMensual = getPrecioMensual(proyecto, amoblado);
    const arriendoEstacionamientosAuto = getEstacionamientoAutoPrecio(proyecto) * estacionamientosAuto;
    const arriendoEstacionamientosMoto = getEstacionamientoMotoPrecio(proyecto) * estacionamientosMoto;
    const arriendoBodegas = getBodegaPrecio(proyecto) * bodegas;
    precioMensual += arriendoEstacionamientosAuto + arriendoEstacionamientosMoto + arriendoBodegas;
  }

  return (
    <span>${precioMensual.toLocaleString()}</span>
  );
}

export default PrecioMensual;
