import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import ModalVisita from '../components/ModalVisita.js';
import Filtro from '../components/containers/Filtro';
import useResize from '../hooks/useResize';
import { BREAKPOINT_MOBILE, FILTER_TYPE } from '../config/constant';
import ResultMobile, { TIPO_RESULT } from '../components/ResultMobile';
import { dataLayerPush, scrollToTop } from '../utils';
import { useLocation } from 'react-router-dom';
import CotizacionesAlerta from '../components/MisCotizaciones/CotizacionesAlerta';
import CardCotizacion from '../components/MisCotizaciones/CardCotizacion';
import styles from './styles/MisCotizaciones.module.scss';
import useCotizaciones from '../hooks/useCotizaciones';
import Spinner from '../components/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectGetCotizacionesErrorMessage,
  selectGetCotizacionesStatus,
  setCotizaciones,
  selectProyectoBySlug,
  getDepartamentos
} from '../redux/proyectosSlice';
import {
  sendCotizacion,
  setLastCotizacionId
} from '../redux/preevaluacionSlice';
import { selectLevelClienteId } from '../redux/userSlice';
import spinnerStyle from './styles/MisCotizacionesSpinner.module.scss';
import Entrada from '../components/Cotizar/layers/Entrada';
import OnBoarding from '../components/Cotizar/layers/OnBoarding';
import PreEvaluacion from '../components/Cotizar/layers/PreEvaluacion';
import PreEvaluacionReserva from '../components/Cotizar/layers/PreEvaluacionReserva/PreEvaluacionReserva';
import Contrato from '../components/Cotizar/layers/PagarCotizacion';
import FirmarContrato from '../components/Cotizar/layers/FirmarContrato/index'
import ModalVisitaCotizacion from '../components/MisCotizaciones/ModalVisitaCotizacion';

const TIMEOUT_IN_SECONDS = 5;


const MisCotizaciones = ({ match }) => {

  const dispatch = useDispatch();
  const [rizeWidth] = useResize();
  const [showVisita, setShowVisita] = useState(false);
  const [cotizar, setCotizar] = useState(false);
  const [currentCotizacion, setCurrentCotizacion] = useState()
  const [showOnboarding, setOnboarding] = useState(false);
  const [showPreEvaluacion, setPreEvaluacion] = useState(false);
  const [showResumen, setShowResumen] = useState(false);
  const [showPreEvaluacionReserva, setPreEvaluacionReserva] = useState(false);
  const [showContrato, setShowContrato] = useState(false);
  const [showFirma, setFirma] = useState(false);
  const [proyecto, setProyecto] = useState(null);
  const pageCurrent = match.path;
  const [pendientePagoReserva, setPendientePagoReserva] = useState(false);
  const cotizaciones = useCotizaciones();
  const getCotizacionesStatus = useSelector(selectGetCotizacionesStatus);
  const getCotizacionesErrorMessage = useSelector(selectGetCotizacionesErrorMessage);
  const levelClienteId = useSelector(selectLevelClienteId);

  const [spinnerEnabled, setSpinnerEnabled] = useState(true);

  const proyectoBySlug = useSelector(selectProyectoBySlug(proyecto?.slug));

  const [modalVistaCotizacion, setModalVisitacotizacion] = useState(null);

  // Obtener departamentos del proyecto actual si no los tiene
  useEffect(() => {
    if (proyectoBySlug && !proyectoBySlug.departamentos) {
      dispatch(getDepartamentos(proyectoBySlug));
    }
  }, [dispatch, proyectoBySlug]);

  const handleSetFirma = () => {
    const urlLink = 'https://ww3.servipag.com/pagoenlinea/portal/desktop/public/todas/!ut/p/b1/04_Sj9CPykssy0xPLMnMz0vMAfGjzOKNgs0CLZ0MHQ38zQPcDBx9gs1CzAK9Df0DTYAKIvEo8DanTL-HEXH6DXAARwNC-r30o9Jz8pOAXg3Xj8KrGOQXvApAjgUrwOMaP4_83FT9gtzQ0IjKYE9dR0VFADNEynw!/dl4/d5/L2dBISEvZ0FBIS9nQSEh/?idServicio=47&idBiller=151#'
    window.location.href = urlLink;
  }
  const handleSaveCotizacion = () => {
    dispatch(sendCotizacion({
      isMinimalCotizacion: true,
      proyectoId: proyecto.id,
      //deptoStrapiId: cheapestDepto.id,
      deptoStrapiId: proyecto.id,
    }));
  }

  const modalCotizar = () => {
    setCotizar(!cotizar);
  }

  const handleOnboarding = () => {
    setCotizar(false);
    setOnboarding(!showOnboarding);
  }

  const handlePreEvaluacion = () => {
    setPreEvaluacion(!showPreEvaluacion);
    setOnboarding(false);
  }

  const handlePreEvaluacionReserva = () => {
    setPreEvaluacionReserva(!showPreEvaluacionReserva);
    setPreEvaluacion(false);
  }

  const onCardCotizacionArrendarClick = async ({
    cotizacion, statusEvaluacion, statusReserva, statusContrato
  }) => {

    setPendientePagoReserva( statusReserva == 0);

    setCurrentCotizacion(cotizacion)
    setProyecto(cotizacion.proyecto);
    if(statusContrato) {
      return setFirma(true)
    }
    if (statusReserva) {
      return setShowContrato(!showContrato);
    }
    if (statusEvaluacion) {
      return setPreEvaluacionReserva(!showPreEvaluacionReserva);
    } else {
      dispatch(setLastCotizacionId(cotizacion.id))
      return setCotizar(cotizacion);
    }
  }

  const handleCloseResumenCotizacion = () => {
    setPreEvaluacionReserva(!showPreEvaluacionReserva);
    setShowResumen(!showResumen);
  }


  const handleModalVisita = (proyecto) => {
    setModalVisitacotizacion(proyecto);
  }
  
  const cardsCotizaciones = cotizaciones.map((cotizacion) => {
    return (
      <CardCotizacion
        mobileCard={rizeWidth < BREAKPOINT_MOBILE}
        cotizacion={cotizacion}
        openVisita = {handleModalVisita}
        onArrendarClick={({
          statusEvaluacion,
          statusReserva,
          statusContrato
        }) => onCardCotizacionArrendarClick({
          cotizacion, statusEvaluacion, statusReserva, statusContrato
        })}
        key={cotizacion.id}
      />
    );
  });

  // Actualizar estado del spinner de acuerdo al estado del fetching de las cotizaciones
  useEffect(() => {
    if (getCotizacionesStatus === 'failed') {
      setSpinnerEnabled(false);

      if (!levelClienteId || levelClienteId === '') {
        setErrorMessage(`No se pudieron obtener las cotizaciones: <strong>Cuenta de Level no fue creada o vinculada.</strong>`);
      } else if (getCotizacionesErrorMessage !== '') {
        setErrorMessage(getCotizacionesErrorMessage);
      }
    }

    let timeoutId = null;

    // Es necesario hacer esto por la sgte razón: Cuando el thunk fue exitoso, se tarda un par de segundos en armar las cards
    // de cotizaciones (porque hay que fetchear data extra). Entonces, ni bien resulta exitoso el thunk, cardsCotizaciones.length es
    // 0. El problema entonces es que a priori *no* sabemos si cardsCotizaciones.length es 0 porque estamos armando las cards,
    // o porque no había cotizaciones en el backend. Lo que hacemos, entonces, es poner un timeout para decir: "Ok,
    // cardsCotizaciones.length es 0, esperemos un cachito a ver si se arman las cards". Si pasan TIMEOUT_IN_SECONDS segundos y
    // cardsCotizaciones.length sigue siendo 0, se ejecuta el timeout y se desactiva el spinner con el mensaje de que no hay
    // cotizaciones. Si en cambio cardsCotizaciones.length cambia *antes* de que se ejecute el timeout (porque armamos las
    // cards exitosamente), el timeout es cleareado, y en la próxima ejecución del useEffect vemos que cardsCotizaciones.length > 0
    // y desactivamos inmediatamente el spinner sin recrear el timeout.
    if (getCotizacionesStatus === 'succeeded') {
      if (cardsCotizaciones.length > 0) {
        setSpinnerEnabled(false);
        return;
      }

      timeoutId = setTimeout(() => {
        setSpinnerEnabled(false);

        if (cardsCotizaciones.length === 0) {
          setErrorMessage('No se encontraron cotizaciones.');
        }

      }, TIMEOUT_IN_SECONDS * 1000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    }
  }, [cardsCotizaciones.length, getCotizacionesErrorMessage, getCotizacionesStatus, levelClienteId]);

  const [errorMessage, setErrorMessage] = useState('');

  // Open Modal login
  const openVisita = () => {
    setShowVisita(true);
  }

  // Close Modal Login
  const closeVisita = () => {
    setShowVisita(false);
  }

  // Scrollear hasta arriba
  useEffect(() => {
    scrollToTop();
  }, []);

  // Resetear las cotizaciones cuando nos vamos de la página, para que la pŕoxima vez que entremos, no veamos
  // las cotizaciones anteriormente fetcheadas
  useEffect(() => {
    return function cleanup() {
      dispatch(setCotizaciones(null));
    }
  }, [dispatch]);

  // Analytics
  const location = useLocation();

  useEffect(() => {
    dataLayerPush({
      event: 'vpv',
      page: location.pathname,
      section: 'resultados de búsqueda',
      url: window.location.href,
    });
  }, [location.pathname]);

  return (
    <Layout pageCurrent={pageCurrent}>
      {rizeWidth > BREAKPOINT_MOBILE ? (
        <>
          <div className="container">
            <div className="content-result" style={{ backgroundColor: '#F0F4FA' }}>
              <Filtro
                openVisita={openVisita}
                resultMap={false}
                priceRangeSection={false}
                showTitle={false}
                showBadges={false}
                showFeatures={false}
                showExtras={false}
                showSurface={false}
                showOthers={false}
                type={FILTER_TYPE.MIS_COTIZACIONES}
                filtroCotizacion={true}
              />
              <div className="result-main" style={{ paddingRight: '140px' }}>
                <h1 className={styles.cotizacionesHeader}>Mis Cotizaciones</h1>
                <CotizacionesAlerta />
                {errorMessage !== '' && <CotizacionesAlerta text={errorMessage} icon='' />}
                <Spinner enabled={spinnerEnabled} spinnerStyle={spinnerStyle} />
                <div className={styles.cotizacionesLister}>
                  {cardsCotizaciones}
                </div>
              </div>
            </div>
          </div>
          <ModalVisita
            show={showVisita}
            close={closeVisita}
          />
        </>
      ) : (
        <ResultMobile
          tipoResult={TIPO_RESULT.MIS_COTIZACIONES}
          cardCotizacion = {cardsCotizaciones}
          cotizacionesData={{
            cotizaciones,
            spinnerEnabled,
            errorMessage,
          }}
          onCardCotizacionArrendarClick={onCardCotizacionArrendarClick}
        />
      )}
      {
        cotizar &&
        <Entrada 
        isCotizacion={true} 
        saveButton={false}
        currentCotizacion={currentCotizacion}
        proyecto={proyecto} 
        close={modalCotizar} 
        OnBoarding={handleOnboarding} />

        /*
        <ResumenCotizacion
          cotizacion={currentCotizacion}
          close={() => setCurrentCotizacion(null)}
          onArrendarClick={onResumenCotizacionArrendarClick}
        />*/
      }
      {showOnboarding && <OnBoarding preEvaluacion={handlePreEvaluacion} />}
      {showPreEvaluacion && (
        <PreEvaluacion
         isCotizacion={true}
          proyecto={currentCotizacion}
          resumenCotizacion={handleCloseResumenCotizacion}
          reserva={handlePreEvaluacionReserva}
        />
      )}
      {showPreEvaluacionReserva && (
        <PreEvaluacionReserva
          isCotizacion={true}
          proyecto={proyectoBySlug}
          currentCotizacion={currentCotizacion}
          pendientePago={pendientePagoReserva}
          setPreEvaluacionReserva={setPreEvaluacionReserva}
          /* pagar={handlePagar} */
          resumenCotizacion={handleCloseResumenCotizacion} />
      )}
      {showContrato && <Contrato  isCotizacion={true} cotizacion={currentCotizacion} setShowContrato={setShowContrato}  proyecto={proyecto} setFirma={handleSetFirma} />}
      {showFirma && <FirmarContrato cotizacion={currentCotizacion} close={setFirma} />}

      {modalVistaCotizacion != null && <ModalVisitaCotizacion proyecto = {modalVistaCotizacion} close={() => setModalVisitacotizacion(null)} /> }

    </Layout>
  );
}

export default MisCotizaciones;
