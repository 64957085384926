import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { registerLocale } from  'react-datepicker';
import es from 'date-fns/locale/es';
import 'react-datepicker/dist/react-datepicker.css';
import './styles/ReactDatePickerOverrides.scss'; // esto *tiene* que venir después de react-datepicker.css
import styles from './styles/StyledDatePicker.module.scss';


// Poner ReactDatePicker en español
registerLocale('es', es);


// Componente con un ReactDatePicker estileado como un Input, para ser usado
// dentro de un container con clase 'layout-group'.
const StyledDatePicker = ({
  label = 'Selecciona una fecha',
  date,
  updateDate,
  labelStyle,
  containerStyle,
  minDate,
  maxDate,
  fullwidth = false,
}) => {

  return (
    <div className={fullwidth ? styles.fullwidth : ''} style={containerStyle}>
      <label style={labelStyle}>{label}</label>
      <ReactDatePicker
        style={{ marginTop: '120px' }}
        locale="es"
        dateFormat="dd/MM/yyyy"
        selected={date}
        onChange={date => updateDate(date)}
        minDate={minDate}
        maxDate={maxDate}
      />
    </div>
  )
}

export default StyledDatePicker;
