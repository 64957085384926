import React, { useState } from 'react';
import ModalFiltroInteres from '../containers/ModalFiltroInteres';
import Map from '../Map/index';
import { Button } from '../ui/index';
import styles from './styles/MapPuntosInteres.module.scss';


// Componente de mapa que incluye un botón de "Puntos de interés" que despliega un modal para seleccionar
// los puntos de interés.
const MapPuntosInteres = ({
  proyecto,
  height = '',
  updatePlacesData = null,
  coordenadas,
  direccion,
}) => {

  const [showModalPuntosInteres, setShowModalPuntosInteres] = useState(false);


  return (
    <>
      <div className={styles.mapContainer}>
          <Map
            proyecto={proyecto}
            coordenadas={coordenadas}
            direccion={direccion}
            height={height}
            updatePlacesData={updatePlacesData}
          />
          <div className={styles.puntosDeInteres}>
            <Button
              color='btn-outline-orange'
              size="medium"
              startIcon="icon-adjust"
              fullWidth ={false}
              onClick={() => setShowModalPuntosInteres(true)}
            >
              Puntos de interés
            </Button>
          </div>
      </div>
      <ModalFiltroInteres
        show={showModalPuntosInteres}
        close={() => setShowModalPuntosInteres(false)}
      />
    </>
  );
}

export default MapPuntosInteres;
