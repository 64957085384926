import React, { useState } from 'react';
import SalaArriendoModal from './SalaArriendoModal';
import SalaArriendoPopup from './SalaArriendoPopup';
import ModalContentZoom from './ModalContentZoom';
import ModalContentAgenda from './ModalContentAgenda';
import useHideCliengoButtons from '../../hooks/useHideCliengoButtons';
import useResize from '../../hooks/useResize';
import { BREAKPOINT_MOBILE } from '../../config/constant';
import useModalScroll from '../../hooks/useModalScroll';


// Componente auxiliar. Muestra un modal de arriendo con el contenido adecuado para el botón de Zoom.
const SalaArriendoModalZoom = ({ show, close }) => {

  const [rizeWidth] = useResize();

  useHideCliengoButtons(show);
  useModalScroll(show);

  const contentStyle = rizeWidth <= BREAKPOINT_MOBILE ? {
    height: '70%',
  } : {
    // desk
    height: '60%',
  };


  return (
    <SalaArriendoModal
      show={show}
      close={close}
      contentStyle={contentStyle}
    >
      <ModalContentZoom />
    </SalaArriendoModal>
  );
}


// Componente auxiliar. Muestra un modal de arriendo con el contenido adecuado para el botón de Agenda.
const SalaArriendoModalAgenda = ({ show, close }) => {

  useHideCliengoButtons(show);
  useModalScroll(show);

  return (
    <SalaArriendoModal
      show={show}
      close={close}
      contentStyle={{
        height: '90%',
        overflow: 'auto',
      }}
    >
      <ModalContentAgenda />
    </SalaArriendoModal>
  );
}


// Componente top-level de la sala de arriendo. Contiene el popup, el modal, etc., y la
// lógica que los une.
const SalaArriendo = () => {

  const [showModalZoom, setShowModalZoom] = useState(false);
  const [showModalAgenda, setShowModalAgenda] = useState(false);

  const closeModalZoom = () => {
    setShowModalZoom(false);
  }

  const openModalZoom = () => {
    setShowModalZoom(true);
  }

  const closeModalAgenda = () => {
    setShowModalAgenda(false);
  }

  const openModalAgenda = () => {
    setShowModalAgenda(true);
  }


  return (
    <div>
      <SalaArriendoPopup
        onCloseClick={() => alert('close')}
        zoomOnClick={openModalZoom}
        agendaOnClick={openModalAgenda}
      />
      {showModalZoom && <SalaArriendoModalZoom show={showModalZoom} close={closeModalZoom} />}
      {showModalAgenda && <SalaArriendoModalAgenda show={showModalAgenda} close={closeModalAgenda} />}
    </div>
  );
}

export default SalaArriendo;
