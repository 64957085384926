import React from 'react';
import InputFiltro from './ui/InputFiltro/InputFiltro';


// Componente que muestra un input para ingresar cantidad de estacionamientos (la cantidad
// de estacionamientos es más genérica que los "estacionamientos", y tiene en cuenta estacionamientos
// de auto y moto, y es usada en la página /estacionamientos). El componente es controlado: se le pasa
// el valor actual con el prop `estacionamiento`, y se ejecutan los callbacks `onIncrease` y
// `onDecrease` cuando la cantidad de estacionamientos aumenta o disminuye, respectivamente.
const InputEstacionamientoCantidad = ({ estacionamiento, onIncrease, onDecrease }) => {

  return (
    <InputFiltro
      type="filtroEstacionamientosCantidad"
      label="Seleccione cantidad"
      controlledValue={estacionamiento}
      onIncrease={onIncrease}
      onDecrease={onDecrease}
      name="filtro-estacionamientos-cantidad"
      flex={true}
      between={true}
      marginBottom="10"
      margin={false}
    />
  );
}

export default InputEstacionamientoCantidad;
