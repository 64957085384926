import React from 'react';
import notFound from '../static/images/notFound.png';
import { getTipologiaGallery } from '../utils';


// Componente de portada de tipología. Devuelve un <img> con la primera imagen de la
// galería de la tipología, si existe, o sino devuelve una imagen de placeholder.
// `tipologia` debe ser un objeto de tipología de Strapi.
const TipologiaPortada = ({ tipologia }) => {

  const galeria = getTipologiaGallery(tipologia);

  const portada = galeria.length > 0 ? galeria[0] : {
    url: notFound,
    alt: 'sin-imagen-tipologia',
  };

  return (
    <img src={portada.url} alt={portada.alt} />
  );
}

export default TipologiaPortada;
