import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  selectChangeEmailData,
  setChangeEmailErrorCount,
  setChangeEmailSuccessCount
} from '../redux/userSlice';


// Hook para mostrar mensaje de éxito/error del thunk changeEmail. Devuelve data relacionada
// al thunk.
const useChangeEmailMessage = () => {

  const dispatch = useDispatch();
  const changeEmailData = useSelector(selectChangeEmailData);

  useEffect(() => {
    switch (changeEmailData.status) {
      case 'succeeded':
        if (changeEmailData.successCount > 0) {
          toast.success('Email actualizado!');
          dispatch(setChangeEmailSuccessCount(0));
        }
        break;

      case 'failed':
        if (changeEmailData.errorCount > 0) {
          toast.error(`No se pudo actualizar el email: ${changeEmailData.errorMessage}`)
          dispatch(setChangeEmailErrorCount(0));
        }
        break;

      default:
        break;
    }
  }, [changeEmailData.errorCount, changeEmailData.errorMessage, changeEmailData.status,
  changeEmailData.successCount, dispatch]);


  return changeEmailData;
}

export default useChangeEmailMessage;
