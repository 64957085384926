/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Icon, Button, Input, Modal } from './ui/index';
import 'react-toastify/dist/ReactToastify.css';
import './styles/ModalLogin.scss';
import {
  getDocumentoPlaceholder,
  getInvalidGenericDocumentoString,
  isValidGenericDocumento,
} from '../validation';
import { useDispatch } from 'react-redux';
import { resetPassword } from '../redux/userSlice';
import useResetPasswordMessage from '../hooks/useResetPasswordMessage';
import { toast } from 'react-toastify';
import { quitarFormatoRut } from '../utils';


const ModalResetPassword = ({ show, close }) => {

  const dispatch = useDispatch();
  const [numeroDocumento, setNumeroDocumento] = useState('');

  // Mostrar mensaje de error/éxito después de intentar resetear la password
  useResetPasswordMessage();

  const onNumeroDocumentoChange = (event) => {
    setNumeroDocumento(event.target.value);
  }

  const onResetearContrasena = () => {
    const numeroDocumentoSanitized = quitarFormatoRut(numeroDocumento);

    if (!isValidGenericDocumento(numeroDocumentoSanitized)) {
      toast.error(getInvalidGenericDocumentoString());
      return;
    }

    dispatch(resetPassword({
      numeroDocumento: numeroDocumentoSanitized,
    }));

    close();
  }

  const headerStr = 'Resetea tu contraseña';


  return (
    <Modal show={show} close={close} className="modalLoginHome">
      <div className="modal-content modal-login fade">
        <div className="header-modal">
          <Icon onClick={close} name="icon-close" size="14" />
        </div>
        <div className="body-modal" style={{ height: '180px' }}>
          <div className="form-content">
            <h3>{headerStr}</h3>
            <div className="formLogin" style={{ marginBottom: '20px' }}>
              <form>
                <Input
                  type="text"
                  name="numeroDocumento"
                  typeStyle="filtro-white"
                  fullWidth={true}
                  placeholder={getDocumentoPlaceholder()}
                  value={numeroDocumento}
                  onChange={onNumeroDocumentoChange}
                  formGroupStyle={{ margin: "0" }} />
              </form>
            </div>
            <Button onClick={onResetearContrasena} className="btn btn-submit" color='btn-orange' size="small">RESETEAR CONTRASEÑA</Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalResetPassword;
