import React from 'react';
import styles from './styles/ImagenesMobile.module.scss';
import ImageInfoCard from './ImageInfoCard';


const LARGE_IMAGE_WIDTH = '147.5px';
const LARGE_IMAGE_HEIGHT = '201px';

const SMALL_IMAGE_WIDTH = '147.5px';
const SMALL_IMAGE_HEIGHT = '121px';

const largeImg = {
  width: LARGE_IMAGE_WIDTH,
  height: LARGE_IMAGE_HEIGHT,
  objectFit: 'cover',
};

const smallImg = {
  width: SMALL_IMAGE_WIDTH,
  height: SMALL_IMAGE_HEIGHT,
  objectFit: 'cover',
};


const ImagenesMobile = ({ beneficiosData }) => {

  return (
    <div className={styles.container}>
      <div className={styles.topFlexContainer}>
        <div className={styles.flexColumn}>
          <ImageInfoCard
            src={beneficiosData.ubicacion.image.url}
            alt={beneficiosData.ubicacion.image.alt}
            imgStyle={largeImg}
            target="mobile"
            frontText={beneficiosData.ubicacion.title}
            backText={
              <>
                <h2>{beneficiosData.ubicacion.title}</h2>
                <p>{beneficiosData.ubicacion.text}</p>
              </>
            }
          />
          <ImageInfoCard
            src={beneficiosData.departamentos.image.url}
            alt={beneficiosData.departamentos.image.alt}
            imgStyle={largeImg}
            target="mobile"
            frontText={beneficiosData.departamentos.title}
            backText={
              <>
                <h2>{beneficiosData.departamentos.title}</h2>
                <p>{beneficiosData.departamentos.text}</p>
              </>
            }
          />
          <ImageInfoCard
            src={beneficiosData.mantencion.image.url}
            alt={beneficiosData.mantencion.image.alt}
            imgStyle={smallImg}
            target="mobile"
            frontText={beneficiosData.mantencion.title}
            backText={
              <>
              <h2>{beneficiosData.mantencion.title}</h2>
              <p>{beneficiosData.mantencion.text}</p>
              </>
            }
          />
        </div>

        <div className={styles.flexColumn}>
          <ImageInfoCard
            src={beneficiosData.gastosFijos.image.url}
            alt={beneficiosData.gastosFijos.image.alt}
            imgStyle={smallImg}
            target="mobile"
            frontText={beneficiosData.gastosFijos.title}
            backText={
              <>
                <h2>{beneficiosData.gastosFijos.title}</h2>
                <p>{beneficiosData.gastosFijos.text}</p>
              </>
            }
          />
          <ImageInfoCard
            src={beneficiosData.espaciosComunes.image.url}
            alt={beneficiosData.espaciosComunes.image.alt}
            imgStyle={largeImg}
            target="mobile"
            frontText={beneficiosData.espaciosComunes.title}
            backText={
              <>
                <h2>{beneficiosData.espaciosComunes.title}</h2>
                <p>{beneficiosData.espaciosComunes.text}</p>
              </>
            }
          />
          <ImageInfoCard
            src={beneficiosData.seguridad.image.url}
            alt={beneficiosData.seguridad.image.alt}
            imgStyle={largeImg}
            target="mobile"
            frontText={beneficiosData.seguridad.title}
            backText={
              <>
                <h2>{beneficiosData.seguridad.title}</h2>
                <p>{beneficiosData.seguridad.text}</p>
              </>
            }
          />
        </div>
      </div>
    </div>
  );
}

export default ImagenesMobile;
