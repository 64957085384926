import React from 'react';
import './TextInfo.scss';
import { Icon } from '../index';
import IconSvg from '../IconSvg';


const TextInfo = (props) => {

  // Nota: iconUrl fue agregada para mostrar iconos svg desde el backend. Para no romper la
  // interfaz de TextInfo  (ya que es usado en muchos lugares), se optó por dejar el comportamiento que
  // tiene (i.e. usar Icon o IconSvg) cuando no se pasa iconUrl, para retrocompatibilidad, y si se pasa
  // iconUrl implementa el nuevo comportamiento (i.e. usar un img)
  const { 
    className='',
    iconName = '',
    iconUrl= '',
    iconAlt= '',
    size = '14',
    children,
    color = 'default',
    textWeight = 'regular',
    iconColor = '',
    iconSize = '',
    iconSvg = false,
    nowrap = false,
    marginBottom,
    containerStyle,
    ...other
  } = props;

  const styles = {
    fontSize: `${size}px`,
    whiteSpace: `${nowrap ? 'nowrap' : ''}`,
    marginBottom,
    ...containerStyle,
  };

  return (
    <span className={`text-info text-${textWeight} text-${color} ${className}`} style={styles} {...other}>
      {iconUrl === '' && iconName !== '' && iconSvg === false  &&  <Icon name = { iconName } color={iconColor} size={iconSize}/>}
      {iconUrl === '' && iconName !== '' && iconSvg === true  &&  <IconSvg name = { iconName } />}
      {iconUrl !== '' && <img src={iconUrl} alt={iconAlt} style={{ width: "10%", height: "auto", marginRight: "10px" }} />}
      { children }
    </span>
  );
}

export default TextInfo;
