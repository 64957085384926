import React from 'react';
import './Title.scss';


const Title = (props) => {

  const {
    id = '',
    className = '',
    size = '20px', 
    marginTop = '0',
    marginBotton = '0',
    children,
  } = props;

  const style = {
    fontSize: size + 'px',
    marginTop: marginTop + 'px',
    marginBottom: marginBotton + 'px',
  };

  return (
    <div id={id} className={`title-content ${className}`} style={style}>{children}</div>
  );
};

export default Title;
