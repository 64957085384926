import React, { useEffect, useState } from 'react';
import { Button, Icon } from '../../ui/index';
import styles from './styles/CotizarMobile.module.scss';
import InputFiltro from '../../ui/InputFiltro/InputFiltro';
import {
  BACKEND_BASE_URL,
  FEATURE_ENABLED,
} from '../../../config/constant';
import {
  INITIAL_PREEV_ESTACIONAMIENTOS_AUTO,
  INITIAL_PREEV_ESTACIONAMIENTOS_MOTO,
  selectAmoblado,
  selectBodegas,
  selectEstacionamientosAuto,
  selectEstacionamientosMoto,
  selectReserva,
  selectTipologiaIndex,
  sendCotizacion,
  setBodegas,
  setEstacionamientosAuto,
  setEstacionamientosMoto,
} from '../../../redux/preevaluacionSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBodegaPrecio,
  getEstacionamientoAutoPrecio,
  getEstacionamientoMotoPrecio,
  getTipologiaGastoComun,
  getTipologiaPrecioMensual,
  pluralize,
  scrollToTop
} from '../../../utils';
import {
  INITIAL_LEVEL_CLIENTEID,
  selectLevelClienteId,
} from '../../../redux/userSlice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OnBoardingMobile from './OnBoardingMobile';
import GastoComunTipologia from '../../GastoComunTipologia';
import useCurrentTipologiaCheapestDepto from '../../../hooks/useCurrentTipologiaCheapestDepto';
import { INITIAL_BODEGAS } from '../../../redux/proyectosSlice';
import useHideCliengoButtons from '../../../hooks/useHideCliengoButtons';


// Máxima cantidad de imágenes de la tipología que se muestran
const MAX_IMAGENES = 4;

// Tamaños de los íconos
const ICON_WIDTH = 40;
const ICON_SIZE = "17";


// Componente auxiliar
const InfoSection = ({ cantidad, nombre, gastosComunes, arriendo, marginBottom = '15px' }) => {

  return (
    <div style={{ marginBottom: marginBottom }}>
      <div className={styles.infoHeader}>
        <span>{cantidad} {nombre}</span>
        <span>Por unidad*</span>
      </div>
      <div className={styles.gastosComunes}>
        <span>Gastos comunes desde</span>
        <span>{gastosComunes}/mes</span>
      </div>
      <div className={styles.arriendo}>
        <span>Arriendo desde</span>
        <span>{arriendo}/mes</span>
      </div>
    </div>
  );
}

// Componente auxiliar
const PrecioMensual = ({ precio }) => {

  return (
    <div className={styles.precioMensualBox}>
      <div className={styles.precioMensualBoxInnerContainer}>
        <span>Precio mensual desde</span>
        <span>{precio}</span>
      </div>
    </div>
  );
}

// Secciones de cotizar
const SECTION = {
  ENTRADA: 0,
  BIENVENIDO: 1,
};

// Tipo de cotización (proyecto, estacionamientos, bodegas)
export const COTIZACION_TYPE = {
  PROYECTO: 0,
  ESTACIONAMIENTOS: 1,
  BODEGAS: 2,
};


// Muestra la entrada de cotización para mobile
const CotizarMobile = ({
  proyecto,
  openPreevaluacion,
  cotizacionType = COTIZACION_TYPE.PROYECTO,
  topBoxStyle = {},
  bottomBoxStyle = {},
}) => {

  const dispatch = useDispatch();
  const reserva = useSelector(selectReserva);
  const levelClienteId = useSelector(selectLevelClienteId);
  const cheapestDepto = useCurrentTipologiaCheapestDepto(proyecto);
  const [section, setSection] = useState(SECTION.ENTRADA);

  const iconMenosColors = {
    enabled: 'white',
    disabled: '#999999',
  };

  const iconAddColors = {
    enabled: 'white',
    disabled: '#999999',
  };

  // Ocultar botones de Cliengo en mobile
  useHideCliengoButtons();

  useEffect(() => {
    scrollToTop();
  }, []);

  const onArrendarOnlineClick = () => {
    setSection(SECTION.BIENVENIDO);
  }

  const onObtenerCotizacionClick = () => {
    // Chequear que el usuario esté creado en Level, de lo contrario la cotización no se puede hacer
    if (!levelClienteId || levelClienteId === INITIAL_LEVEL_CLIENTEID) {
      toast.error('Error! Tu cuenta de Level no pudo ser creada');
      return;
    }

    // Si es cotización de estacionamientos o bodegas, tiene que haber al menos uno de esos secundarios
    // seleccionados, para no crear una cotización vacía
    if (cotizacionType === COTIZACION_TYPE.ESTACIONAMIENTOS) {
      if (estacionamientosAuto === 0 && estacionamientosMoto === 0) {
        toast.error(`Tienes que elegir al menos un estacionamiento`);
        return;
      }
    }

    if (cotizacionType === COTIZACION_TYPE.BODEGAS) {
      if (bodegas === 0) {
        toast.error(`Tienes que elegir al menos una bodega`);
        return;
      }
    }

    // Crear reserva en Strapi
    dispatch(sendCotizacion({
      ...reserva,
      amoblado,
      deptoStrapiId: cheapestDepto ? cheapestDepto.id : null,
      proyectoId: proyecto.id,
    }));

    // Reiniciar estacionamientos de auto/moto y bodegas
    dispatch(setEstacionamientosAuto(0));
    dispatch(setEstacionamientosMoto(0));
    dispatch(setBodegas(0));
  }

  // FIXME: Repetido, deberíamos realmente hacer un componente de más alto nivel que InputFiltro para
  // manejar cada tipo de Input
  const onEstacionamientosAutoDecrease = () => {
    const newEstacionamientosAuto = estacionamientosAuto - 1;
    if (newEstacionamientosAuto >= INITIAL_PREEV_ESTACIONAMIENTOS_AUTO) {
      dispatch(setEstacionamientosAuto(newEstacionamientosAuto));
    }
  }

  const onEstacionamientosAutoIncrease = () => {
    const newEstacionamientosAuto = estacionamientosAuto + 1;
    dispatch(setEstacionamientosAuto(newEstacionamientosAuto));
  }

  const onEstacionamientosMotoDecrease = () => {
    const newEstacionamientosMoto = estacionamientosMoto - 1;
    if (newEstacionamientosMoto >= INITIAL_PREEV_ESTACIONAMIENTOS_MOTO) {
      dispatch(setEstacionamientosMoto(newEstacionamientosMoto));
    }
  }

  const onEstacionamientosMotoIncrease = () => {
    const newEstacionamientosMoto = estacionamientosMoto + 1;
    dispatch(setEstacionamientosMoto(newEstacionamientosMoto));
  }

  const onBodegaDecrease = () => {
    const newBodegas = bodegas - 1;
    if (newBodegas >= INITIAL_BODEGAS) {
      dispatch(setBodegas(newBodegas));
    }
  }

  const onBodegaIncrease = () => {
    const newBodegas = bodegas + 1;
    dispatch(setBodegas(newBodegas));
  }

  const tipologiaIndex = useSelector(selectTipologiaIndex);
  const estacionamientosAuto = useSelector(selectEstacionamientosAuto);
  const estacionamientosMoto = useSelector(selectEstacionamientosMoto);
  const bodegas = useSelector(selectBodegas);
  const amoblado = useSelector(selectAmoblado);

  let nombre = '';
  let dormitorios = '';
  let banos = '';
  let superficie = '';
  let gastosComunesEstacionamientosAuto = '';
  let gastosComunesEstacionamientosMoto = '';
  let gastosComunesBodegas = '';
  let arriendoDpto = '';
  let arriendoEstacionamientosAuto = '';
  let arriendoEstacionamientosMoto = '';
  let arriendoBodegas = '';
  let precioMensual = 0;
  let imagenes = null;

  if (proyecto) {
    nombre = proyecto.proyecto_nombre;

    const currentTipologia = proyecto.tipologias[tipologiaIndex];

    dormitorios = currentTipologia.dormitorios;
    banos = currentTipologia.banos;
    superficie = currentTipologia.superficie;

    gastosComunesEstacionamientosAuto = estacionamientosAuto * proyecto.gastoComunMinEstacionamientosAuto;
    gastosComunesEstacionamientosMoto = estacionamientosMoto * proyecto.gastoComunMinEstacionamientosMoto;
    gastosComunesBodegas = bodegas * proyecto.gastoComunMinBodegas;

    arriendoDpto = getTipologiaPrecioMensual(proyecto.tipologias[tipologiaIndex], amoblado);
    arriendoEstacionamientosAuto = getEstacionamientoAutoPrecio(proyecto) * estacionamientosAuto;
    arriendoEstacionamientosMoto = getEstacionamientoMotoPrecio(proyecto) * estacionamientosMoto;
    arriendoBodegas = getBodegaPrecio(proyecto) * bodegas;

    const gastosComunesTotal = gastosComunesEstacionamientosAuto + gastosComunesEstacionamientosMoto +
      gastosComunesBodegas;

    if (cotizacionType === COTIZACION_TYPE.PROYECTO) {
      precioMensual += getTipologiaGastoComun(proyecto.tipologias[tipologiaIndex]) + arriendoDpto;
    }

    precioMensual += arriendoEstacionamientosAuto + arriendoEstacionamientosMoto + arriendoBodegas +
      gastosComunesTotal;

    // Armar imágenes de la galería, si hay alguna
    if (proyecto.tipologias[tipologiaIndex].galeria.length > 0) {
      imagenes = proyecto.tipologias[tipologiaIndex].galeria.map((galeriaItem, index) => {
        return (
          <img key={galeriaItem.id} src={`${BACKEND_BASE_URL}${galeriaItem.url}`}
            alt={galeriaItem.alternativeText} />
        );
      });

      if (imagenes.length >= MAX_IMAGENES) {
        imagenes = imagenes.slice(0, MAX_IMAGENES);
      }
    }
  }


  // FIXME: Mover la primer pantalla (la que tiente toda la info de lo que se está por arrendar)
  // a su propio componente para que sea más prolijo
  return (
    <>
      {
        section === SECTION.ENTRADA &&

        <div className={`container ${styles.containerCustom}`}>
          <div className={styles.topBox} style={topBoxStyle}>
            {
              cotizacionType === COTIZACION_TYPE.PROYECTO &&
              <h1>Departamento en</h1>
            }
            {
              cotizacionType === COTIZACION_TYPE.ESTACIONAMIENTOS &&
              <h1>Estacionamientos en</h1>
            }
            {
              cotizacionType === COTIZACION_TYPE.BODEGAS &&
              <h1>Bodegas en</h1>
            }
            <h2>{nombre}</h2>
            <div className={styles.iconsContainer}>
              <div className={styles.iconLabel}>
                <Icon name="icon-bed" border="round" color="white" background="dark" width={ICON_WIDTH} size={ICON_SIZE} />
                <span>{dormitorios} {pluralize('dormitorio', dormitorios)}</span>
              </div>
              <div className={styles.iconLabel}>
                <Icon name="icon-bath" border="round" color="white" background="dark" width={ICON_WIDTH} size={ICON_SIZE} />
                <span>{banos} {pluralize('baño', banos)}</span>
              </div>
              <div className={styles.iconLabel}>
                <Icon name="icon-arrow-side" border="round" color="white" background="dark" width={ICON_WIDTH} size={ICON_SIZE} />
                <span>Sup. total {superficie}m<sup>2</sup></span>
              </div>
            </div>
            <div className={styles.imageContainer}>
              {imagenes}
            </div>
            <div className={styles.inputContainer}>
              {
                (cotizacionType === COTIZACION_TYPE.PROYECTO ||
                  cotizacionType === COTIZACION_TYPE.ESTACIONAMIENTOS) &&

                <>
                  <InputFiltro
                    cantidadDisponible={proyecto ? proyecto.cantidadEstacionamientosCubiertos : 0}
                    type="estacionamientoAuto"
                    controlledValue={estacionamientosAuto}
                    onIncrease={onEstacionamientosAutoIncrease}
                    onDecrease={onEstacionamientosAutoDecrease}
                    label="Estacionamiento para auto"
                    labelColor="white"
                    iconMenosColors={iconMenosColors}
                    iconAddColors={iconAddColors}
                    countColor="white"
                    flex={true}
                    between={true}
                    marginBottom="20"
                    margin={false}
                  />

                  <InputFiltro
                    cantidadDisponible={proyecto ? proyecto.cantidadEstacionamientosMotos : 0}
                    type="estacionamientoMoto"
                    controlledValue={estacionamientosMoto}
                    onIncrease={onEstacionamientosMotoIncrease}
                    onDecrease={onEstacionamientosMotoDecrease}
                    label="Estacionamiento para moto"
                    labelColor="white"
                    iconMenosColors={iconMenosColors}
                    iconAddColors={iconAddColors}
                    countColor="white"
                    flex={true}
                    between={true}
                    marginBottom="20"
                    margin={false}
                  />
                </>
              }

              {
                (cotizacionType === COTIZACION_TYPE.PROYECTO ||
                  cotizacionType === COTIZACION_TYPE.BODEGAS) &&

                <InputFiltro
                  cantidadDisponible={proyecto ? proyecto.cantidadBodegas : 0}
                  type="bodega"
                  controlledValue={bodegas}
                  onIncrease={onBodegaIncrease}
                  onDecrease={onBodegaDecrease}
                  label="Cantidad de bodegas"
                  labelColor="white"
                  iconMenosColors={iconMenosColors}
                  iconAddColors={iconAddColors}
                  countColor="white"
                  flex={true}
                  between={true}
                  marginBottom="0"
                  margin={false}
                />
              }
            </div>
          </div>
          <div className={styles.bottomBox} style={bottomBoxStyle}>
            {
              cotizacionType === COTIZACION_TYPE.PROYECTO &&
              <InfoSection
                cantidad={1}
                nombre="departamento"
                gastosComunes={<GastoComunTipologia tipologia={proyecto ? proyecto.tipologias[tipologiaIndex] : null} />}
                arriendo={`$${arriendoDpto.toLocaleString()}`}
              />
            }

            {
              (cotizacionType === COTIZACION_TYPE.PROYECTO ||
                cotizacionType === COTIZACION_TYPE.ESTACIONAMIENTOS) &&

              <>
                <InfoSection
                  cantidad={estacionamientosAuto}
                  nombre="estacionamientos para auto"
                  gastosComunes={`$${gastosComunesEstacionamientosAuto.toLocaleString()}`}
                  arriendo={`$${arriendoEstacionamientosAuto.toLocaleString()}`}
                />

                <InfoSection
                  cantidad={estacionamientosMoto}
                  nombre="estacionamientos para moto"
                  gastosComunes={`$${gastosComunesEstacionamientosMoto.toLocaleString()}`}
                  arriendo={`$${arriendoEstacionamientosMoto.toLocaleString()}`}
                />
              </>
            }

            {
              (cotizacionType === COTIZACION_TYPE.PROYECTO ||
                cotizacionType === COTIZACION_TYPE.BODEGAS) &&

              <InfoSection
                cantidad={bodegas}
                nombre="bodega"
                gastosComunes={`$${gastosComunesBodegas.toLocaleString()}`}
                arriendo={`$${arriendoBodegas.toLocaleString()}`}
                marginBottom="25px"
              />
            }

            <p className={styles.disclaimer}>*Este es un valor mensual estimado. El costo definitivo será confirmado al momento de reservar.</p>
            <p className={styles.disclaimer}>*Al monto de la cotización se le agregará la comisión de corretaje.</p>
          </div>
          <PrecioMensual precio={`$${precioMensual.toLocaleString()}`} />
          <div className={styles.arrendarContainer}>
            <div className={styles.arrendarButtonContainer}>
              {
                FEATURE_ENABLED.OBTENER_COTIZACION ?

                  <Button onClick={onObtenerCotizacionClick} color='btn-orange' size="small" fullWidth={true}>OBTENER COTIZACIÓN</Button>
                  :
                  <Button onClick={onArrendarOnlineClick} color='btn-orange' size="small" fullWidth={true}>ARRENDAR ONLINE</Button>
              }
            </div>
          </div>
        </div>
      }
      {
        section === SECTION.BIENVENIDO &&
        <OnBoardingMobile
          openPreevaluacion={openPreevaluacion}
          close={() => setSection(SECTION.ENTRADA)}
        />
      }
    </>
  );
}

export default CotizarMobile;
