import React from 'react';
import './Modal.scss';


// Componente de modal.
// `children`: Hijos del componente.
// `bgClose`: Indica si la función `close` debe llamarse cuando se clickea FUERA del contenido del modal.
// `close`: Función opcional a ser llamada cuando se clickea fuera del contenido del modal.
// `show`: Indica si debe mostrarse el modal. Notar que el modal está SIEMPRE montado en el DOM, independientemente
// del valor de `show`.
// `veilColor`: Indica el color del velo.
const Modal = ({ children, close, bgClose = true, show = false, veilColor = "rgba(44,43,66, .7)" }) => {

  const onBgClick = () => {
    if (bgClose && close) {
      close();
    }
  }

  return (
    <div className={`modal ${show && 'show'}`} style={{ backgroundColor: veilColor }} tabIndex="-1">
      <div onClick={onBgClick} className="bg-close"></div>
      <div className="modal-dialog">
        {children}
      </div>
    </div>
  );
};

export default Modal;
