/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  INITIAL_DORMITORIOS,
  MAX_DORMITORIOS,
  INITIAL_BANOS,
  MAX_BANOS,
  INITIAL_BODEGAS,
  MAX_BODEGAS,
  INITIAL_ESTACIONAMIENTOS_CANTIDAD,
  INITIAL_BODEGAS_CANTIDAD,
  MAX_BODEGAS_CANTIDAD,
  selectFiltroUbicaciones,
  setFiltroUbicaciones,
  selectFiltroBanos,
  setFiltroBanos,
  selectFiltroBodegas,
  setFiltroBodegas,
  selectFiltroDormitorios,
  setFiltroDormitorios,
  selectFiltroPrecioMin,
  setFiltroPrecioMin,
  selectFiltroPrecioMax,
  setFiltroPrecioMax,
  selectFiltroAmoblado,
  setFiltroAmoblado,
  selectFiltroSuperficieMin,
  setFiltroSuperficieMin,
  selectFiltroSuperficieMax,
  setFiltroSuperficieMax,
  selectFiltroEdificios,
  setFiltroEdificios,
  selectFiltroMascota,
  selectFiltroEntregaInmediata,
  selectFiltroOfertas,
  selectFiltroEstrenar,
  selectFiltroEstacionamientosCantidad,
  setFiltroEstacionamientosCantidad,
  selectFiltroBodegasCantidad,
  setFiltroBodegasCantidad,
  setFiltroEstacionamientosPrecioMin,
  setFiltroEstacionamientosPrecioMax,
  setFiltroBodegasPrecioMin,
  setFiltroBodegasPrecioMax,
  selectUniqueNombresProyectos,
  selectFiltroEstacionamientos,
  setFiltroEstacionamientos,
  selectUniqueComunasCotizaciones,
  selectUniqueNombresProyectosCotizaciones,
  setFiltroEntregaInmediata,
  setFiltroEstrenar,
  setFiltroMascota,
  setFiltroOfertas,
} from "../../redux/proyectosSlice";
import { Link, useLocation, useHistory } from "react-router-dom";
import "./styles/Filtro.scss";
import {
  Title,
  Button,
  TitleFiltro,
  Checkbox,
  TextInfo,
  ToogleSwich,
  ButtonDropdown,
  Dropdown,
} from "../ui/index";
import { selectUniqueComunas } from "../../redux/proyectosSlice";
import {
  BREAKPOINT_MOBILE,
  TIPO_ORDENAR,
  FEATURE_ENABLED,
  FILTER_TYPE,
} from "../../config/constant";
import useResize from "../../hooks/useResize";
import OrdenarCheckboxes from "../OrdenarCheckboxes";
import {
  comunaFilterSetGet,
  dataLayerPush,
  edificioFilterSetGet,
  resetFiltro,
  scrollToTop,
} from "../../utils";
import {
  INITIAL_PREEV_ESTACIONAMIENTOS_AUTO,
  MAX_PREEV_ESTACIONAMIENTOS_AUTO,
  MAX_PREEV_ESTACIONAMIENTOS_MOTO,
} from "../../redux/preevaluacionSlice";
import InputPrecio from "../InputPrecio";
import InputSuperficie from "../InputSuperficie";
import InputBano from "../InputBano";
import InputDormitorio from "../InputDormitorio";
import FiltrosBadges from "../FiltrosBadges";
import InputEstacionamientoCantidad from "../InputEstacionamientoCantidad";
import InputBodegaCantidad from "../InputBodegaCantidad";
import InputEstacionamiento from "../InputEstacionamiento";
import InputBodega from "../InputBodega";
import CheckboxOtros from "../CheckboxOtros";
import CheckboxTipoEstacionamiento from "../CheckboxTipoEstacionamiento";

const DEFAULT_ORDENAR_PLACEHOLDER = TIPO_ORDENAR[0];

const hideOrdenarDropdown = () => {
  const dropdownElement = document.getElementById(
    "filtro-ordenar-buttondropdown"
  );
  dropdownElement.click();
};

// Componente de filtro. Usado para todos los tipos de filtros, tanto desktop como mobile. El
// prop `type` determina el tipo de filtro devuelto.
const Filtro = ({
  openVisita,
  resultMap,
  showTitle = true,
  showBadges = true,
  showFeatures = true,
  showExtras = true,
  showSurface = true,
  showOthers = true,
  priceRangeSection = true,
  ubicacionSection = true,
  filtroCotizacion,
  close,
  type = FILTER_TYPE.PROYECTOS,
}) => {
  const dispatch = useDispatch();
  const [rizeWidth] = useResize();
  const history = useHistory();
  const filtroUbicaciones = useSelector(selectFiltroUbicaciones);
  const filtroDormitorios = useSelector(selectFiltroDormitorios);
  const filtroBanos = useSelector(selectFiltroBanos);
  const filtroBodegas = useSelector(selectFiltroBodegas);
  const filtroPrecioMin = useSelector(selectFiltroPrecioMin);
  const filtroPrecioMax = useSelector(selectFiltroPrecioMax);
  const filtroSuperficieMin = useSelector(selectFiltroSuperficieMin);
  const filtroSuperficieMax = useSelector(selectFiltroSuperficieMax);
  const filtroAmoblado = useSelector(selectFiltroAmoblado);
  const filtroEdificios = useSelector(selectFiltroEdificios);
  const filtroMascota = useSelector(selectFiltroMascota);
  const filtroEntregaInmediata = useSelector(selectFiltroEntregaInmediata);
  const filtroOfertas = useSelector(selectFiltroOfertas);
  const filtroEstrenar = useSelector(selectFiltroEstrenar);
  const filtroEstacionamientosCantidad = useSelector(
    selectFiltroEstacionamientosCantidad
  );
  const filtroBodegasCantidad = useSelector(selectFiltroBodegasCantidad);
  const filtroEstacionamientos = useSelector(selectFiltroEstacionamientos);
  const [ordenarPlaceholder, setOrdenarPlaceholder] = useState(
    DEFAULT_ORDENAR_PLACEHOLDER
  );

  // Analytics
  const location = useLocation();
  let segmento = "/result";
  switch (type) {
    case FILTER_TYPE.PROYECTOS:
      segmento = "/result";
      break;

    case FILTER_TYPE.ESTACIONAMIENTOS:
      segmento = "/estacionamientos";
      break;

    case FILTER_TYPE.BODEGAS:
      segmento = "/bodegas";
      break;

    case FILTER_TYPE.MIS_COTIZACIONES:
      break;

    default:
      console.log(
        `Filtro -> onPrecioMinInputChange: tipo filtro ${type} desconocido!`
      );
      break;
  }

  const initSearchLoadingDom = () => {
    const paramsSearch = new URLSearchParams(location.search);

    // obtenemos el valor de la comuna
    if (Object.fromEntries(paramsSearch).hasOwnProperty("comuna")) {
      const comuna = paramsSearch.get("comuna");
      const comunasUnicas = [...new Set(comuna.split(","))];
      dispatch(setFiltroUbicaciones(comunasUnicas));
    }

    // Obtenemos el valor del precio minimo del la url
    if (Object.fromEntries(paramsSearch).hasOwnProperty("precioMin")) {
      switch (type) {
        case FILTER_TYPE.PROYECTOS:
          dispatch(setFiltroPrecioMin(paramsSearch.get("precioMin")));
          break;

        case FILTER_TYPE.ESTACIONAMIENTOS:
          dispatch(
            setFiltroEstacionamientosPrecioMin(paramsSearch.get("precioMin"))
          );
          break;

        case FILTER_TYPE.BODEGAS:
          dispatch(setFiltroBodegasPrecioMin(paramsSearch.get("precioMin")));
          break;

        case FILTER_TYPE.MIS_COTIZACIONES:
          break;

        default:
          console.log(
            `Filtro -> onPrecioMinInputChange: tipo filtro ${type} desconocido!`
          );
          break;
      }
    }

    //Obtenemos de la url el valor del precio max
    if (Object.fromEntries(paramsSearch).hasOwnProperty("precioMax")) {
      switch (type) {
        case FILTER_TYPE.PROYECTOS:
          dispatch(setFiltroPrecioMax(paramsSearch.get("precioMax")));
          break;

        case FILTER_TYPE.ESTACIONAMIENTOS:
          dispatch(
            setFiltroEstacionamientosPrecioMax(paramsSearch.get("precioMax"))
          );
          break;

        case FILTER_TYPE.BODEGAS:
          dispatch(setFiltroBodegasPrecioMax(paramsSearch.get("precioMax")));
          break;

        default:
          console.log(
            `Filtro -> onPrecioMaxInputChange: tipo filtro ${type} desconocido!`
          );
          break;
      }
    }

    if (Object.fromEntries(paramsSearch).hasOwnProperty("superficieMin")) {
      dispatch(
        setFiltroSuperficieMin(parseFloat(paramsSearch.get("superficieMin")))
      );
    }

    if (Object.fromEntries(paramsSearch).hasOwnProperty("superficieMax")) {
      dispatch(
        setFiltroSuperficieMin(
          setFiltroSuperficieMax(paramsSearch.get("superficieMax"))
        )
      );
    }

    if (Object.fromEntries(paramsSearch).hasOwnProperty("edificio")) {
      const edificios = paramsSearch.get("edificio");
      const edificiosUnicas = [...new Set(edificios.split(","))];
      dispatch(setFiltroEdificios(edificiosUnicas));
    }

    if (Object.fromEntries(paramsSearch).hasOwnProperty("amueblado")) {
      dispatch(setFiltroAmoblado(paramsSearch.get("amueblado")));
    }

    if (Object.fromEntries(paramsSearch).hasOwnProperty("banos")) {
      dispatch(setFiltroBanos(parseFloat(paramsSearch.get("banos"))));
    }

    if (Object.fromEntries(paramsSearch).hasOwnProperty("dormitorio")) {
      dispatch(
        setFiltroDormitorios(parseFloat(paramsSearch.get("dormitorio")))
      );
    }

    if (Object.fromEntries(paramsSearch).hasOwnProperty("bodegas")) {
      dispatch(setFiltroBodegas(parseFloat(paramsSearch.get("bodegas"))));
    }

    if (Object.fromEntries(paramsSearch).hasOwnProperty("estacionamiento")) {
      dispatch(
        setFiltroEstacionamientos(
          parseFloat(paramsSearch.get("estacionamiento"))
        )
      );
    }

    if (
      Object.fromEntries(paramsSearch).hasOwnProperty("estacionamientoAuto")
    ) {
      dispatch(
        setFiltroEstacionamientosCantidad(
          parseFloat(paramsSearch.get("estacionamientoAuto"))
        )
      );
    }

    if (Object.fromEntries(paramsSearch).hasOwnProperty("bodegaCantidad")) {
      dispatch(
        setFiltroBodegasCantidad(parseFloat(paramsSearch.get("bodegaCantidad")))
      );
    }

    if (Object.fromEntries(paramsSearch).hasOwnProperty("mascota")) {
      dispatch(setFiltroMascota(paramsSearch.get("mascota")));
    }

    if (Object.fromEntries(paramsSearch).hasOwnProperty("entregaImediata")) {
      dispatch(setFiltroEntregaInmediata(paramsSearch.get("entregaImediata")));
    }

    if (Object.fromEntries(paramsSearch).hasOwnProperty("oferta")) {
      dispatch(setFiltroOfertas(paramsSearch.get("oferta")));
    }

    if (Object.fromEntries(paramsSearch).hasOwnProperty("estrenar")) {
      dispatch(setFiltroEstrenar(paramsSearch.get("estrenar")));
    }

    scrollToTop();
  };

  useEffect(() => {
    initSearchLoadingDom();
    return () => resetFiltro(dispatch);
  }, []);

  useEffect(() => {
    dataLayerPush({
      event: "filtro",
      page: location.pathname,
      section: "resultados de búsqueda",
      url: window.location.href,
      comuna: filtroUbicaciones,
      dormitorios: filtroDormitorios,
      baños: filtroBanos,
      rangoPrecioMin: filtroPrecioMin,
      rangoPrecioMax: filtroPrecioMax,
      superficieDesde: filtroSuperficieMin,
      superficieHasta: filtroSuperficieMax,
      seleccionEdificio: filtroEdificios,
      mascotas: filtroMascota,
      entregaInmediata: filtroEntregaInmediata,
      ofertas: filtroOfertas,
      estrenar: filtroEstrenar,
    });
  }, [
    filtroBanos,
    filtroDormitorios,
    filtroEdificios,
    filtroEntregaInmediata,
    filtroEstrenar,
    filtroMascota,
    filtroOfertas,
    filtroPrecioMax,
    filtroPrecioMin,
    filtroSuperficieMax,
    filtroSuperficieMin,
    filtroUbicaciones,
    location.pathname,
  ]);

  const onPrecioMinInputChange = (newValue) => {
    // Asumimos que si hay un punto es un separador de miles y lo removemos de la string
    // antes de convertir a float. "150.000" se convertiría en el float 150,000
    const rawStr = newValue.replace(".", "");
    const newPrecioMin = rawStr !== "" ? parseFloat(rawStr) : null;
    const paramsSearch = new URLSearchParams(location.search);

    if (newValue == 0) {
      if (Object.fromEntries(paramsSearch).hasOwnProperty("precioMin")) {
        paramsSearch.delete("precioMin");
      }
    } else {
      paramsSearch.set("precioMin", newValue);
    }

    history.push({
      pathname: segmento,
      search: paramsSearch.toString(),
    });

    switch (type) {
      case FILTER_TYPE.PROYECTOS:
        dispatch(setFiltroPrecioMin(newPrecioMin));
        break;

      case FILTER_TYPE.ESTACIONAMIENTOS:
        dispatch(setFiltroEstacionamientosPrecioMin(newPrecioMin));
        break;

      case FILTER_TYPE.BODEGAS:
        dispatch(setFiltroBodegasPrecioMin(newPrecioMin));
        break;

      case FILTER_TYPE.MIS_COTIZACIONES:
        break;

      default:
        console.log(
          `Filtro -> onPrecioMinInputChange: tipo filtro ${type} desconocido!`
        );
        break;
    }
  };

  const onPrecioMaxInputChange = (newValue) => {
    // Asumimos que si hay un punto es un separador de miles y lo removemos de la string
    // antes de convertir a float. "150.000" se convertiría en el float 150,000
    const rawStr = newValue.replace(".", "");
    const newPrecioMax = rawStr !== "" ? parseFloat(rawStr) : null;

    const paramsSearch = new URLSearchParams(location.search);

    if (newValue === 0) {
      if (Object.fromEntries(paramsSearch).hasOwnProperty("precioMax")) {
        paramsSearch.delete("precioMax");
      }
    } else {
      paramsSearch.set("precioMax", newValue);
    }

    history.push({
      pathname: segmento,
      search: paramsSearch.toString(),
    });

    switch (type) {
      case FILTER_TYPE.PROYECTOS:
        dispatch(setFiltroPrecioMax(newPrecioMax));
        break;

      case FILTER_TYPE.ESTACIONAMIENTOS:
        dispatch(setFiltroEstacionamientosPrecioMax(newPrecioMax));
        break;

      case FILTER_TYPE.BODEGAS:
        dispatch(setFiltroBodegasPrecioMax(newPrecioMax));
        break;

      default:
        console.log(
          `Filtro -> onPrecioMaxInputChange: tipo filtro ${type} desconocido!`
        );
        break;
    }
  };

  // Handler para el input de superficie "desde"
  const onSuperficieDesdeChange = (newValue) => {
    const newSuperficieMin = newValue !== "" ? newValue : null;
    const paramsSearch = new URLSearchParams(location.search);
    paramsSearch.set("superficieMin", newSuperficieMin);
    history.push({
      pathname: segmento,
      search: paramsSearch.toString(),
    });
    dispatch(setFiltroSuperficieMin(parseFloat(newSuperficieMin)));
  };

  const onSuperficieHastaChange = (newValue) => {
    const newSuperficieMax = newValue !== "" ? newValue : null;
    const paramsSearch = new URLSearchParams(location.search);
    paramsSearch.set("superficieMax", newSuperficieMax);
    history.push({
      pathname: segmento,
      search: paramsSearch.toString(),
    });
    dispatch(setFiltroSuperficieMax(parseFloat(newSuperficieMax)));
  };

  const onUbicacionCheckboxChange = (event, comuna) => {
    const paramsSearch = new URLSearchParams(location.search);

    if (event.target.checked) {
      // Ubicación ticked, agregarla al filtro de ubicaciones
      const newFiltroUbicaciones = filtroUbicaciones.slice();
      newFiltroUbicaciones.push(comuna);

      dispatch(setFiltroUbicaciones(newFiltroUbicaciones));

      const getComunas = comunaFilterSetGet(newFiltroUbicaciones);
      paramsSearch.set("comuna", getComunas);

      // Veridcamos si hay edificios seleccionadas
      const getEdificios = edificioFilterSetGet(filtroEdificios);
      if (getEdificios.length > 0) {
        paramsSearch.set("edificio", getEdificios);
      }
      history.push({
        pathname: segmento,
        search: paramsSearch.toString(),
      });
    } else {
      // Ubicación unticked, removerla del filtro de ubicaciones
      const newFiltroUbicaciones = filtroUbicaciones.filter(
        (ubi) => ubi !== comuna
      );
      dispatch(setFiltroUbicaciones(newFiltroUbicaciones));

      if (newFiltroUbicaciones.length === 0) {
        paramsSearch.delete("comuna");
      } else {
        const getEdificios = edificioFilterSetGet(filtroEdificios);
        paramsSearch.set("comuna", getEdificios);
      }

      // Veridcamos si hay edificios seleccionadas
      const getEdificios = comunaFilterSetGet(filtroEdificios);
      if (getEdificios.length > 0) {
        paramsSearch.set("edificio", getEdificios);
      }

      history.push({
        pathname: segmento,
        search: paramsSearch.toString(),
      });
    }
    scrollToTop();
    {
      rizeWidth <= BREAKPOINT_MOBILE && close();
    }
  };

  const onEdificioCheckboxChange = (event, direccion) => {
    const paramsSearch = new URLSearchParams(location.search);

    if (event.target.checked) {
      // Edificio ticked, agregarlo al filtro
      const newFiltroEdificios = filtroEdificios.slice();
      newFiltroEdificios.push(direccion);
      dispatch(setFiltroEdificios(newFiltroEdificios));

      const getEdificios = edificioFilterSetGet(newFiltroEdificios);
      paramsSearch.set("edificio", getEdificios);

      // Veridcamos si hay comunas seleccionadas
      const getComunas = comunaFilterSetGet(filtroUbicaciones);
      if (getComunas.length > 0) {
        paramsSearch.set("comuna", getComunas);
      }

      history.push({
        pathname: segmento,
        search: paramsSearch.toString(),
      });
    } else {
      // Edificio unticked, removerlo del filtro
      const newFiltroEdificios = filtroEdificios.filter(
        (dir) => dir !== direccion
      );
      dispatch(setFiltroEdificios(newFiltroEdificios));

      if (newFiltroEdificios.length === 0) {
        paramsSearch.delete("edificio");
      } else {
        const getEdificios = edificioFilterSetGet(newFiltroEdificios);
        paramsSearch.set("edificio", getEdificios);
      }

      // Veridcamos si hay comunas seleccionadas
      const getComunas = comunaFilterSetGet(filtroUbicaciones);
      if (getComunas.length > 0) {
        paramsSearch.set("comuna", getComunas);
      }

      history.push({
        pathname: segmento,
        search: paramsSearch.toString(),
      });
    }
    scrollToTop();
    {
      rizeWidth <= BREAKPOINT_MOBILE && close();
    }
  };

  const onAmobladoSwitched = (event) => {
    const paramsSearch = new URLSearchParams(location.search);

    if (event.target.checked) {
      paramsSearch.set("amueblado", event.target.checked);
    } else {
      paramsSearch.delete("amueblado");
    }
    dispatch(setFiltroAmoblado(event.target.checked));
    history.push({
      pathname: segmento,
      search: paramsSearch.toString(),
    });
    scrollToTop();
  };

  const onAgendarVisitaClick = () => {
    openVisita();

    // Analytics
    dataLayerPush({
      event: "click",
      page: "/result",
      section: "agendar visita",
    });
  };

  // Handlers para el aumento/decremento de banos
  const eventAumBanos = () => {
    const newFiltroBanos = filtroBanos + 1;
    const paramsSearch = new URLSearchParams(location.search);

    if (newFiltroBanos <= MAX_BANOS) {
      dispatch(setFiltroBanos(newFiltroBanos));
      paramsSearch.set("banos", newFiltroBanos);

      history.push({
        pathname: segmento,
        search: paramsSearch.toString(),
      });
    }
  };

  const eventDisBanos = () => {
    const newFiltroBanos = filtroBanos - 1;
    const paramsSearch = new URLSearchParams(location.search);

    if (newFiltroBanos >= INITIAL_BANOS) {
      dispatch(setFiltroBanos(newFiltroBanos));
      paramsSearch.set("banos", newFiltroBanos);
      history.push({
        pathname: segmento,
        search: paramsSearch.toString(),
      });
    }
  };

  // Handlers para el aumento/decremento de dormitorios
  const eventAumDormitorios = () => {
    const newFiltroDormitorios = filtroDormitorios + 1;
    const paramsSearch = new URLSearchParams(location.search);
    if (newFiltroDormitorios <= MAX_DORMITORIOS) {
      paramsSearch.set("dormitorio", newFiltroDormitorios);
      dispatch(setFiltroDormitorios(newFiltroDormitorios));
      history.push({
        pathname: segmento,
        search: paramsSearch.toString(),
      });
    }
  };

  const eventDisDormitorios = () => {
    const newFiltroDormitorios = filtroDormitorios - 1;
    const paramsSearch = new URLSearchParams(location.search);

    if (newFiltroDormitorios >= INITIAL_DORMITORIOS) {
      paramsSearch.set("dormitorio", newFiltroDormitorios);
      dispatch(setFiltroDormitorios(newFiltroDormitorios));
      history.push({
        pathname: segmento,
        search: paramsSearch.toString(),
      });
    }
  };

  // Handlers para el aumento/decremento de bodegas
  const eventAumBodegas = () => {
    const newFiltroBodegas = filtroBodegas + 1;
    const paramsSearch = new URLSearchParams(location.search);

    if (newFiltroBodegas <= MAX_BODEGAS) {
      dispatch(setFiltroBodegas(newFiltroBodegas));
      paramsSearch.set("bodegas", newFiltroBodegas);
      history.push({
        pathname: segmento,
        search: paramsSearch.toString(),
      });
    }
  };

  const eventDisBodegas = () => {
    const newFiltroBodegas = filtroBodegas - 1;
    const paramsSearch = new URLSearchParams(location.search);

    if (newFiltroBodegas >= INITIAL_BODEGAS) {
      dispatch(setFiltroBodegas(newFiltroBodegas));
      paramsSearch.set("bodegas", newFiltroBodegas);
      history.push({
        pathname: segmento,
        search: paramsSearch.toString(),
      });
    }
  };

  // Handlers para el aumento/decremento de estacionamientos de auto
  const eventAumFiltroEstacionamientos = () => {
    const newFiltroEstacionamientos = filtroEstacionamientos + 1;
    const paramsSearch = new URLSearchParams(location.search);


    dispatch(setFiltroEstacionamientos(newFiltroEstacionamientos));
    paramsSearch.set("estacionamiento", newFiltroEstacionamientos);
    history.push({
      pathname: segmento,
      search: paramsSearch.toString(),
    });

  };

  const eventDisFiltroEstacionamientos = () => {
    const newFiltroEstacionamientos = filtroEstacionamientos - 1;
    const paramsSearch = new URLSearchParams(location.search);

    if (newFiltroEstacionamientos >= INITIAL_PREEV_ESTACIONAMIENTOS_AUTO) {
      dispatch(setFiltroEstacionamientos(newFiltroEstacionamientos));
      paramsSearch.set("estacionamiento", newFiltroEstacionamientos);
      history.push({
        pathname: segmento,
        search: paramsSearch.toString(),
      });
    }
  };

  // Handlers para el aumento/decremento de cantidad de estacionamientos
  const eventAumFiltroEstacionamientosCantidad = () => {
    const newFiltroEstacionamientosCantidad =
      filtroEstacionamientosCantidad + 1;
    const paramsSearch = new URLSearchParams(location.search);

    if (newFiltroEstacionamientosCantidad <= MAX_PREEV_ESTACIONAMIENTOS_MOTO) {
      dispatch(
        setFiltroEstacionamientosCantidad(newFiltroEstacionamientosCantidad)
      );
      paramsSearch.set(
        "estacionamientoAuto",
        newFiltroEstacionamientosCantidad
      );
      history.push({
        pathname: segmento,
        search: paramsSearch.toString(),
      });
    }
  };

  const eventDisFiltroEstacionamientosCantidad = () => {
    const newFiltroEstacionamientosCantidad =
      filtroEstacionamientosCantidad - 1;
    const paramsSearch = new URLSearchParams(location.search);

    if (
      newFiltroEstacionamientosCantidad >= INITIAL_ESTACIONAMIENTOS_CANTIDAD
    ) {
      dispatch(
        setFiltroEstacionamientosCantidad(newFiltroEstacionamientosCantidad)
      );
      paramsSearch.set(
        "estacionamientoAuto",
        newFiltroEstacionamientosCantidad
      );
      history.push({
        pathname: segmento,
        search: paramsSearch.toString(),
      });
    }
  };

  // Handlers para el aumento/decremento de cantidad de bodegas
  const eventAumFiltroBodegasCantidad = () => {
    const newFiltroBodegasCantidad = filtroBodegasCantidad + 1;
    const paramsSearch = new URLSearchParams(location.search);

    if (newFiltroBodegasCantidad <= MAX_BODEGAS_CANTIDAD) {
      dispatch(setFiltroBodegasCantidad(newFiltroBodegasCantidad));
      paramsSearch.set("bodegaCantidad", newFiltroBodegasCantidad);
      history.push({
        pathname: segmento,
        search: paramsSearch.toString(),
      });
    }
  };

  const eventDisFiltroBodegasCantidad = () => {
    const newFiltroBodegasCantidad = filtroBodegasCantidad - 1;
    const paramsSearch = new URLSearchParams(location.search);

    if (newFiltroBodegasCantidad >= INITIAL_BODEGAS_CANTIDAD) {
      dispatch(setFiltroBodegasCantidad(newFiltroBodegasCantidad));
      paramsSearch.set("bodegaCantidad", newFiltroBodegasCantidad);
      history.push({
        pathname: segmento,
        search: paramsSearch.toString(),
      });
    }
  };

  const uniqueComunas = useSelector(selectUniqueComunas);
  const uniqueComunasCotizacion = useSelector(selectUniqueComunasCotizaciones);
  let ubicacionCheckboxes = [];

  // Lo que en la UI llamamos "Edificios", son de hecho los nombres de los proyectos
  const uniqueNombresProyectos = useSelector(selectUniqueNombresProyectos);
  const uniqueNombresProyectosCotizacion = useSelector(
    selectUniqueNombresProyectosCotizaciones
  );

  let edificioCheckboxes = [];

  if (filtroCotizacion) {
    ubicacionCheckboxes = uniqueComunasCotizacion.map((comuna, index) => {
      // Checkbox ticked si la comuna actual está en el filtro de ubicaciones
      const checked = filtroUbicaciones.indexOf(comuna) !== -1;

      return (
        <Checkbox
          key={comuna}
          checked={checked}
          marginZero={false}
          onChange={(event) => onUbicacionCheckboxChange(event, comuna)}
        >
          <TextInfo color="black">{comuna}</TextInfo>
        </Checkbox>
      );
    });

    edificioCheckboxes = uniqueNombresProyectosCotizacion.map(
      (direccion, index) => {
        // Checkbox ticked si la dirección actual está en el filtro de edificios
        const checked = filtroEdificios.indexOf(direccion) !== -1;
        // El último elemento lleva marginZero={true}
        const marginZero = index === uniqueNombresProyectos.length - 1;
        return (
          <Checkbox
            key={direccion}
            checked={checked}
            marginZero={marginZero}
            onChange={(event) => onEdificioCheckboxChange(event, direccion)}
          >
            <TextInfo color="black">{direccion}</TextInfo>
          </Checkbox>
        );
      }
    );
  } else {
    ubicacionCheckboxes = uniqueComunas.map((comuna, index) => {
      // Checkbox ticked si la comuna actual está en el filtro de ubicaciones
      const checked = filtroUbicaciones.indexOf(comuna) !== -1;

      return (
        <Checkbox
          key={comuna}
          checked={checked}
          marginZero={false}
          onChange={(event) => onUbicacionCheckboxChange(event, comuna)}
        >
          <TextInfo color="black">{comuna}</TextInfo>
        </Checkbox>
      );
    });

    edificioCheckboxes = uniqueNombresProyectos.map((direccion, index) => {
      // Checkbox ticked si la dirección actual está en el filtro de edificios
      const checked = filtroEdificios.indexOf(direccion) !== -1;
      // El último elemento lleva marginZero={true}
      const marginZero = index === uniqueNombresProyectos.length - 1;
      return (
        <Checkbox
          key={direccion}
          checked={checked}
          marginZero={marginZero}
          onChange={(event) => onEdificioCheckboxChange(event, direccion)}
        >
          <TextInfo color="black">{direccion}</TextInfo>
        </Checkbox>
      );
    });
  }

  // Determina si el conversor a dólares es mostrado en el rango de precios
  const RANGO_PRECIOS_USD = false;

  let title = "";
  let edificioTitle = "Edificio";
  let resultMapUrl = "#";
  let listadoUrl = "#";

  switch (type) {
    case FILTER_TYPE.PROYECTOS:
      title =
        filtroUbicaciones.length >= 1
          ? `Departamentos en Arriendo en ${filtroUbicaciones.join(", ")}`
          : `Departamentos en Arriendo en todas las comunas`;
      resultMapUrl = "/result-map";
      listadoUrl = "/result";
      break;

    case FILTER_TYPE.ESTACIONAMIENTOS:
      title = `Arrienda un estacionamiento`;
      edificioTitle = `Selecciona un edificio`;
      resultMapUrl = "/result-map/estacionamientos";
      listadoUrl = "/estacionamientos";
      break;

    case FILTER_TYPE.BODEGAS:
      title = `Arrienda una bodega`;
      edificioTitle = `Selecciona un edificio`;
      resultMapUrl = "/result-map/bodegas";
      listadoUrl = "/bodegas";
      break;

    case FILTER_TYPE.TIPOLOGIAS_MOBILE:
      title = "";
      edificioTitle = "";
      break;

    case FILTER_TYPE.MIS_COTIZACIONES:
      resultMapUrl = "/result-map/mis-cotizaciones";
      listadoUrl = "/mis-cotizaciones";
      break;

    default:
      console.log(`Tipo de Filtro ${type} desconocido!`);
      break;
  }

  return (
    <div className="sidebar-filtro">
      {showTitle && (
        <Title className="hide-mobile" size="20">
          {title}
        </Title>
      )}
      {showBadges && <FiltrosBadges filterType={type} segmento={segmento} />}
      {type === FILTER_TYPE.ESTACIONAMIENTOS && (
        <>
          {rizeWidth <= BREAKPOINT_MOBILE && (
            <h1 style={{ marginBottom: "20px", fontFamily: "Roboto-bold" }}>
              Arrienda un estacionamiento
            </h1>
          )}

          <InputEstacionamientoCantidad
            estacionamiento={filtroEstacionamientosCantidad}
            onIncrease={eventAumFiltroEstacionamientosCantidad}
            onDecrease={eventDisFiltroEstacionamientosCantidad}
          />
        </>
      )}

      {type === FILTER_TYPE.BODEGAS && (
        <>
          {rizeWidth <= BREAKPOINT_MOBILE && (
            <h1 style={{ marginBottom: "20px", fontFamily: "Roboto-bold" }}>
              Arrienda una bodega
            </h1>
          )}
          <InputBodegaCantidad
            bodega={filtroBodegasCantidad}
            onIncrease={eventAumFiltroBodegasCantidad}
            onDecrease={eventDisFiltroBodegasCantidad}
          />
        </>
      )}

      {!resultMap ? (
        <Link to={resultMapUrl} className="hide-mobile">
          <Button
            className="btn bold flex"
            color="btn-outline-orange"
            size="medium"
            fullWidth={true}
            iconSize="20"
            startIcon="icon-map-open"
          >
            VER MAPA
          </Button>
        </Link>
      ) : (
        <Link to={listadoUrl}>
          <Button
            className="btn bold flex"
            color="btn-outline-orange"
            size="medium"
            fullWidth={true}
            iconSize="20"
            startIcon="icon-list"
          >
            VER LISTADO
          </Button>
        </Link>
      )}

      {type === FILTER_TYPE.PROYECTOS && rizeWidth > BREAKPOINT_MOBILE && (
        <>
          <TitleFiltro
            className="border hide-mobile"
            size="16"
            title="Ordenar por:"
          />
          <ButtonDropdown
            id="filtro-ordenar-buttondropdown"
            color="btn-white-gray"
            size="large"
            fullWidth={true}
            placeholder={ordenarPlaceholder}
          >
            <Dropdown title="Selecciona ordenamiento">
              <OrdenarCheckboxes
                updatePlaceholder={(newOrdenarPlaceholder) =>
                  setOrdenarPlaceholder(newOrdenarPlaceholder)
                }
                defaultPlaceholder={DEFAULT_ORDENAR_PLACEHOLDER}
                hideDropdown={hideOrdenarDropdown}
              />
            </Dropdown>
          </ButtonDropdown>
        </>
      )}

      {(type === FILTER_TYPE.ESTACIONAMIENTOS ||
        type === FILTER_TYPE.BODEGAS) && (
          <>
            <TitleFiltro
              className="border"
              size="16"
              title="¿Dónde quieres arrendar?"
            />
            {ubicacionCheckboxes}
          </>
        )}

      {type !== FILTER_TYPE.TIPOLOGIAS_MOBILE && (
        <>
          {priceRangeSection && (
            <>
              <TitleFiltro
                className="border border-mobile-none mt-1"
                size="16"
                title="Rango de precios"
              >
                <div className="option-title-filtro">
                  {RANGO_PRECIOS_USD ? (
                    <>
                      <a className="active">
                        $CLP
                      </a>
                      <span>|</span>
                      <a>U$S</a>
                    </>
                  ) : (
                    <>
                      <a className="active">
                        $CLP
                      </a>
                    </>
                  )}
                </div>
              </TitleFiltro>

              <InputPrecio
                precioMin={filtroPrecioMin}
                precioMax={filtroPrecioMax}
                onPrecioMinChange={onPrecioMinInputChange}
                onPrecioMaxChange={onPrecioMaxInputChange}
              />
            </>
          )}
        </>
      )}

      {(type === FILTER_TYPE.PROYECTOS ||
        type === FILTER_TYPE.TIPOLOGIAS_MOBILE) && (
          <>
            {showFeatures && (
              <>
                <TitleFiltro
                  className="border"
                  size="16"
                  title="Características"
                />

                <InputDormitorio
                  dormitorio={filtroDormitorios}
                  onIncrease={eventAumDormitorios}
                  onDecrease={eventDisDormitorios}
                />

                <InputBano
                  bano={filtroBanos}
                  onIncrease={eventAumBanos}
                  onDecrease={eventDisBanos}
                />
              </>
            )}
          </>
        )}

      {ubicacionSection &&
        (type === FILTER_TYPE.PROYECTOS ||
          type === FILTER_TYPE.MIS_COTIZACIONES) && (
          <>
            <TitleFiltro className="border" size="16" title="Comuna" />
            {ubicacionCheckboxes}
          </>
        )}

      {type === FILTER_TYPE.PROYECTOS && (
        <>
          {showExtras && (
            <>
              <TitleFiltro className="border" size="16" title="Adicionales" />

              <InputEstacionamiento
                estacionamiento={filtroEstacionamientos}
                onIncrease={eventAumFiltroEstacionamientos}
                onDecrease={eventDisFiltroEstacionamientos}
              />

              <InputBodega
                bodega={filtroBodegas}
                onIncrease={eventAumBodegas}
                onDecrease={eventDisBodegas}
              />

              <ToogleSwich
                checked={filtroAmoblado}
                position="left"
                onChange={(event) => onAmobladoSwitched(event)}
              >
                ¿Amoblado?
              </ToogleSwich>
            </>
          )}
        </>
      )}

      {(type === FILTER_TYPE.PROYECTOS ||
        type === FILTER_TYPE.TIPOLOGIAS_MOBILE) && (
          <>
            {showSurface && (
              <>
                <TitleFiltro
                  className="border"
                  size="16"
                  title="Superficie total (m2)"
                />
                <InputSuperficie
                  superficieMin={filtroSuperficieMin}
                  superficieMax={filtroSuperficieMax}
                  onSuperficieMinChange={onSuperficieDesdeChange}
                  onSuperficieMaxChange={onSuperficieHastaChange}
                />
              </>
            )}
          </>
        )}

      {type !== FILTER_TYPE.TIPOLOGIAS_MOBILE && (
        <>
          <TitleFiltro className="border" size="16" title={edificioTitle} />
          {edificioCheckboxes}
        </>
      )}

      {type === FILTER_TYPE.PROYECTOS && (
        <>
          {showOthers && (
            <>
              <TitleFiltro className="border" size="16" title="Otros" />
              <CheckboxOtros />
            </>
          )}
        </>
      )}

      {type === FILTER_TYPE.ESTACIONAMIENTOS && (
        <>
          <TitleFiltro
            className="border"
            size="16"
            title="Tipo de estacionamiento"
          />
          <CheckboxTipoEstacionamiento />
        </>
      )}

      <br />
      <br />

      {FEATURE_ENABLED.AGENDAR_VISITA && (
        <Button
          onClick={onAgendarVisitaClick}
          className="btn bold flex btn-visita"
          color="btn-blue"
          size="medium"
          fullWidth={true}
          iconSize="20"
          startIcon="icon-clock"
        >
          AGENDAR VISITA
        </Button>
      )}
    </div>
  );
};

export default Filtro;
