import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextInfo, Button } from '../ui/index';
import Filtro from './Filtro';
import {
  resetFiltros,
  selectFilteredProyectos,
  selectPlaceFilteredTipologias
} from '../../redux/proyectosSlice';
import { FILTER_TYPE } from '../../config/constant';
import useHideCliengoButtons from '../../hooks/useHideCliengoButtons';


const ModalFiltroMobile = ({
  type,
  proyecto,
  show,
  close,
  ubicacionSection = true,
  showResultsNumber = true,
  filtroCotizacion= false
}) => {

  const dispatch = useDispatch();

  useHideCliengoButtons(show);

  const onLimpiarFiltrosClick = () => {
    dispatch(resetFiltros());
  }

  const onMostrarResultadosClick = () => {
    const closeIcon = document.getElementById('modal-filtro-mobil-close-icon');
    closeIcon.click();
  }

  const filteredProyectos = useSelector(selectFilteredProyectos);
  const filteredTipologias = useSelector(selectPlaceFilteredTipologias(proyecto ? proyecto.id : null));

  let resultadosStr = '';

  if (showResultsNumber) {
    switch (type) {
      case FILTER_TYPE.PROYECTOS:
        resultadosStr = `MOSTRAR ${filteredProyectos.length} RESULTADO${filteredProyectos.length > 1 ? 'S' : ''}`;
        break;

      case FILTER_TYPE.TIPOLOGIAS_MOBILE:
        resultadosStr = `MOSTRAR ${filteredTipologias.length} RESULTADO${filteredTipologias.length > 1 ? 'S' : ''}`;
        break;

      default:
        resultadosStr = 'MOSTRAR RESULTADOS';
    }
  }


  return (
    <div className={`modal-search-mobile ${show}`}>
      <div className="header--search">
        <div className="header-step flex show" onClick={close}>
          <TextInfo id="modal-filtro-mobil-close-icon" iconName="icon-close" color="white" textWeight="bold"></TextInfo>
          <Button onClick={onLimpiarFiltrosClick} className="btn clear-filtro" color='btn-gray'>Limpiar filtros</Button>
        </div>
      </div>
      <div className="search-body searchArrendaronline">
        <div className="body-step show">
          <Filtro
            filtroCotizacion={filtroCotizacion}
            type={type}
            ubicacionSection={ubicacionSection}
            priceRangeSection={type !== FILTER_TYPE.MIS_COTIZACIONES}
            showTitle={type !== FILTER_TYPE.MIS_COTIZACIONES}
            showBadges={type !== FILTER_TYPE.MIS_COTIZACIONES}
            showFeatures={type !== FILTER_TYPE.MIS_COTIZACIONES}
            showExtras={type !== FILTER_TYPE.MIS_COTIZACIONES}
            showSurface={type !== FILTER_TYPE.MIS_COTIZACIONES}
            showOthers={type !== FILTER_TYPE.MIS_COTIZACIONES}
            close={close}
          />
        </div>
      </div>
      <div className="search-footer">
        <div className="footer-step show">
          <Button onClick={onMostrarResultadosClick} color='btn-blue' fullWidth={true} size="extra-small">{resultadosStr}</Button>
        </div>
      </div>
    </div>
  );
};

export default ModalFiltroMobile;
