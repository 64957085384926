import React, { useState } from 'react';
import Carousel from 'react-elastic-carousel'
import './styles/CardPlaceMobile.scss'
import { Button } from './ui/index';
import CardInfoPlace from './containers/CardInfoPlace';
import {
  BACKEND_BASE_URL,
  FEATURE_ENABLED,
  TOUR_360_STR
} from '../config/constant';
import notFound from '../static/images/notFound.png';
import { getProyectoTourUrl } from '../utils';


// Índice de la imagen inicial que es mostrada como activa, contando desde 0
const INITIAL_IMAGE_INDEX = 1;


const CardPlaceMobile = ({ proyecto }) => {

  let totalSlider = 0;
  const [currentImage, setCurrentImage] = useState(INITIAL_IMAGE_INDEX);

  let carouselSlides = null;

  // Si tenemos la info del proyecto, construir los slides del carousel
  if (proyecto) {
    totalSlider = proyecto.galeria.length;

    let dataPosition = 0;

    carouselSlides = proyecto.galeria.map((galeriaItem, index) => {
      const imageUrl = galeriaItem.formats.medium ? `url(${BACKEND_BASE_URL}${galeriaItem.formats.medium.url})`
        : notFound;

      return (
        <div key={galeriaItem.id} data-position={dataPosition++} className={`item-slider ${index === currentImage ? 'active' : ''}`}
          style={{ backgroundImage: imageUrl }}></div>
      );
    });
  }

  const removeActive = () => {
    const itemsSlider = document.querySelector('.slider-mobile-items .item-slider.active');
    if (itemsSlider) {
      itemsSlider.removeClass('active');
    }
  }

  const onArrendarClick = () => {
    const deptosAnchor = document.getElementById('mobile-header-deptos-anchor');
    deptosAnchor.click();
  }


  return (
    <>
      <div className="__card-place initial-stick container">
        <div className="row">
          <div className="_place-text">
            <p>{proyecto ? proyecto.descripcion : ''}</p>
          </div>
        </div>
      </div>
      {
        carouselSlides &&

        <div className="__content-slide-place">
          <Carousel
            itemsToScroll={1}
            itemsToShow={1}
            enableAutoPlay={false}
            showArrows={false}
            autoPlaySpeed={5000}
            initialFirstItem={INITIAL_IMAGE_INDEX}

            onNextStart={(currentItem, nextItem) => {
              if (currentImage < totalSlider - 1) {
                const { index } = currentItem;
                setCurrentImage(currentImage + 1);

                removeActive();

                const currentThumb = document.querySelector(`.slider-mobile-items .item-slider[data-position="${index + 1}"]`);
                if (currentThumb) {
                  currentThumb.addClass('active');
                }
              }
            }}
            onPrevStart={(currentItem, nextItem) => {
              if (currentImage > 0) {
                const { index } = currentItem;
                setCurrentImage(currentImage - 1);

                removeActive();

                const currentThumb = document.querySelector(`.slider-mobile-items .item-slider[data-position="${index - 1}"]`);
                if (currentThumb) {
                  currentThumb.addClass('active');
                }
              }
            }}

            className="slider-mobile-items">
            {carouselSlides}
          </Carousel>

          <div className="row">
            <Button
              onClick={onArrendarClick}
              color="btn-orange"
              className="btn bold mt-3"
              size="medium"
              fullWidth={true}
            >
              ARRENDAR ONLINE
              </Button>
          </div>

          {
            FEATURE_ENABLED.TOUR_360 &&

            <div className="row">
              <a href={getProyectoTourUrl(proyecto)} target="_blank" rel="noopener noreferrer">
                <Button
                  color="btn-outline-orange"
                  className="btn bold mt-3"
                  size="medium"
                  fullWidth={true}
                >
                  {TOUR_360_STR}
                </Button>
              </a>
            </div>
          }

          <div className="__place-info-place">
            <div className="row">
              <CardInfoPlace proyecto={proyecto} />
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default CardPlaceMobile;
