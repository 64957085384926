import React, { useState } from 'react';
import Carousel from 'react-elastic-carousel';
import { Button } from './ui/index';
import styles from './styles/SliderGallery.module.scss';
import './styles/SliderGalleryCustomCarousel.scss';
import MapPuntosInteres from './MapPuntosInteres/MapPuntosInteres';


// Componente que muestra un slider acompañado de una galería de miniaturas de 2x3 a su derecha.
// Asume que imgArray tiene al menos 6 objetos de imagen de la forma { src, alt }.
// Si `mapOnLastThumbnail` es true, el último thumbnail, en vez de cargar una imagen, carga un mapa
// con puntos de interés (i.e. un componente MapPuntosInteres).
const SliderGallery = ({ barrioData, mapOnLastThumbnail = true, updatePlacesData = null }) => {

  const [currentSlide, setCurrentSlide] = useState(0);
  let carousel;

  const nextSlide = () => {
    // Hacemos esto incondicionalmente para tener la animación de bounce cuando llegamos a los extremos
    carousel.slideNext();

    if (currentSlide <= barrioData.slider.length - 2) {
      setCurrentSlide(currentSlide + 1);
    }
  }

  const previousSlide = () => {
    // Hacemos esto incondicionalmente para tener la animación de bounce cuando llegamos a los extremos
    carousel.slidePrev();

    if (currentSlide >= 1) {
      setCurrentSlide(currentSlide - 1);
    }
  }

  // Devuelve el nombre correspondiente a la clase activada si la imagen es la slide actual,
  // o una string vacía '' si no lo es
  const imgGetActiveClassName = (imgIndex) => {
    if (imgIndex === currentSlide) {
      return styles.imgActive;
    }

    return '';
  }

  const onImgClick = (imgIndex) => {
    setCurrentSlide(imgIndex);
    carousel.goTo(imgIndex);
  }


  return (
    <div className={styles.topContainer}>
      <div className={styles.carouselContainer}>
        {
          barrioData && barrioData.slider &&

          <Carousel
            ref={el => carousel = el}
            itemsToScroll={1}
            itemsToShow={1}
            showArrows={false}
            className="sliderGalleryCustomCarousel"
            enableSwipe={false}
          >
            {
              barrioData.slider.map((item, index) => {
                if (mapOnLastThumbnail && index === barrioData.slider.length - 1) {
                  return (
                    <MapPuntosInteres
                      coordenadas={barrioData.coordenadas}
                      direccion={barrioData.direccion}
                      height="484px"
                      updatePlacesData={updatePlacesData}
                    />
                  );
                }

                return (
                  <img key={item.id} src={item.url} alt={item.alt} className={styles.sliderImage} />
                );
              })
            }
          </Carousel>

        }
        <div className={styles.carouselButtonsContainer}>
          <Button
            style={{
              float: 'left',
              position: 'relative',
              left: '20px',
            }}
            color='btn-white-gray'
            size="small-icon"
            startIcon="icon-left-arrow"
            onClick={previousSlide}
          />
          <Button
            style={{
              float: 'right',
              position: 'relative',
              right: '20px',
            }}
            color='btn-white-gray'
            size="small-icon"
            startIcon="icon-button-arrow-ight"
            onClick={nextSlide}
          />
        </div>

        {
          barrioData && barrioData.slider &&

          <>
            <div className={styles.galleryRow}>
              <img onClick={() => onImgClick(1)} src={barrioData.slider[1].url} alt={barrioData.slider[1].alt} className={imgGetActiveClassName(1)} />
              <img onClick={() => onImgClick(0)} src={barrioData.slider[0].url} alt={barrioData.slider[0].alt} className={imgGetActiveClassName(0)} />
            </div>

            <div className={styles.galleryRow} style={{ top: '165px' }}>
              <img onClick={() => onImgClick(3)} src={barrioData.slider[3].url} alt={barrioData.slider[3].alt} className={imgGetActiveClassName(3)} />
              <img onClick={() => onImgClick(2)} src={barrioData.slider[2].url} alt={barrioData.slider[2].alt} className={imgGetActiveClassName(2)} />
            </div>

            <div className={styles.galleryRow} style={{ top: '330px' }}>
              <img onClick={() => onImgClick(5)} src={barrioData.slider[5].url} alt={barrioData.slider[5].alt} className={imgGetActiveClassName(5)} />
              <img onClick={() => onImgClick(4)} src={barrioData.slider[4].url} alt={barrioData.slider[4].alt} className={imgGetActiveClassName(4)} />
            </div>
          </>
        }
      </div>
    </div>
  );
}

export default SliderGallery;
