import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  INITIAL_DORMITORIOS,
  MAX_DORMITORIOS,
  INITIAL_BANOS,
  MAX_BANOS,
  INITIAL_PRECIO_MIN,
  INITIAL_PRECIO_MAX,
  PRECIO_MAX_MAX,
  selectFiltroBanos,
  selectFiltroDormitorios,
  selectUniqueComunas,
  selectFiltroPrecioMin,
  setFiltroPrecioMin,
  selectFiltroPrecioMax,
  setFiltroPrecioMax,
  selectFiltroUbicaciones,
  setFiltroUbicaciones,
  setFiltroBanos,
  setFiltroDormitorios,
} from "../../redux/proyectosSlice";
import {
  TitleFiltro,
  ButtonDropdown,
  Dropdown,
  Checkbox,
  TextInfo,
  Icon,
  Input,
  GroupRow,
  Button,
  Title,
} from "../ui/index";
import { useHistory } from "react-router-dom";
import "./styles/Search.scss";
import { BREAKPOINT_MOBILE } from "../../config/constant";
import InputFiltro from "../ui/InputFiltro/InputFiltro";
import { comunaFilterSetGet } from "../../utils";

const Search = ({ rizeWidth }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const filtroUbicaciones = useSelector(selectFiltroUbicaciones);
  const uniqueComunas = useSelector(selectUniqueComunas);
  const filtroPrecioMin = useSelector(selectFiltroPrecioMin);
  const filtroPrecioMax = useSelector(selectFiltroPrecioMax);
  const filtroDormitorios = useSelector(selectFiltroDormitorios);
  const filtroBanos = useSelector(selectFiltroBanos);
  const [filtro, setFiltro] = useState(null);



  const linkToFiltro = () => {

    const paramsSearch = new URLSearchParams(window.location.search);
    // Veridcamos si hay comunas seleccionadas
    const getComunas = comunaFilterSetGet(filtroUbicaciones);
    if (getComunas.length > 0) {
      paramsSearch.set("comuna", getComunas);
    }

    if (filtroPrecioMin !== INITIAL_PRECIO_MIN) {
      paramsSearch.set("precioMin", filtroPrecioMin);
    }

    if (filtroPrecioMax !== INITIAL_PRECIO_MAX) {
      paramsSearch.set("precioMax", filtroPrecioMax);
    }

    paramsSearch.set("dormitorio", filtroDormitorios);
    paramsSearch.set("banos", filtroBanos);

    history.push({
      pathname: "/result",
      search: paramsSearch.toString(),
    });
  };

  const onUbicacionCheckboxChange = (event) => {
    if (event.target.checked) {
      if (filtroUbicaciones.indexOf(event.target.name) === -1) {
        // Checkbox ticked y nombre no está en el filtro: agregarlo
        const newFiltroUbicaciones = filtroUbicaciones.slice();
        newFiltroUbicaciones.push(event.target.name);
        dispatch(setFiltroUbicaciones(newFiltroUbicaciones));
      }
    } else {
      if (filtroUbicaciones.indexOf(event.target.name) !== -1) {
        // Checkbox unticked y nombre de región está en el filtro: removerlo
        const newFiltroUbicaciones = filtroUbicaciones.filter(
          (comuna) => comuna !== event.target.name
        );
        dispatch(setFiltroUbicaciones(newFiltroUbicaciones));
      }
    }
  };

  const onPrecioInputChange = () => {

    const precioMinInput = document.getElementById("search-input-precio-min");
    const precioMaxInput = document.getElementById("search-input-precio-max");



    // Asumimos que si hay un punto es un separador de miles y lo removemos de la string
    // antes de convertir a float. "150.000" se convertiría en el float 150,000
    const minRawStr = precioMinInput.value.replace(".", "");
    const maxRawStr = precioMaxInput.value.replace(".", "");

    const newMinValue = minRawStr !== "" ? parseFloat(minRawStr) : null;
    const newMaxValue = maxRawStr !== "" ? parseFloat(maxRawStr) : null;

    dispatch(setFiltroPrecioMin(newMinValue));
    dispatch(setFiltroPrecioMax(newMaxValue));
  };

  // Handlers para el aumento/decremento de banos
  const eventAumBanos = () => {
    const newFiltroBanos = filtroBanos + 1;

    if (newFiltroBanos <= MAX_BANOS) {
      dispatch(setFiltroBanos(newFiltroBanos));
    }
  };

  const eventDisBanos = () => {
    const newFiltroBanos = filtroBanos - 1;

    if (newFiltroBanos >= INITIAL_BANOS) {
      dispatch(setFiltroBanos(newFiltroBanos));
    }
  };

  // Handlers para el aumento/decremento de dormitorios
  const eventAumDormitorios = () => {
    const newFiltroDormitorios = filtroDormitorios + 1;

    if (newFiltroDormitorios <= MAX_DORMITORIOS) {
      dispatch(setFiltroDormitorios(newFiltroDormitorios));
    }
  };

  const eventDisDormitorios = () => {
    const newFiltroDormitorios = filtroDormitorios - 1;

    if (newFiltroDormitorios >= INITIAL_DORMITORIOS) {
      dispatch(setFiltroDormitorios(newFiltroDormitorios));
    }
  };

  // Calcular string del dropdown de 'Rango de precios'
  const precioMin = useSelector(selectFiltroPrecioMin);
  const precioMinStr =
    precioMin !== INITIAL_PRECIO_MIN ? precioMin.toLocaleString() : "Min";
  const precioMax = useSelector(selectFiltroPrecioMax);
  const precioMaxStr =
    precioMax !== INITIAL_PRECIO_MAX ? precioMax.toLocaleString() : "Max";
  const preciosDropdownStr = `$${precioMinStr} - $${precioMaxStr}`;

  // Calcular string del dropdown de '¿Dónde quieres vivir?'
  const dondeVivirDropdownStr =
    filtroUbicaciones.length === 0
      ? "Todas las comunas"
      : filtroUbicaciones.join(", ");

  // Calcular string del dropdown de '¿Cantidad de dormitorios y baños?'
  const dormitorios = useSelector(selectFiltroDormitorios);
  const banos = useSelector(selectFiltroBanos);

  const dormitoriosStr =
    dormitorios === 0 || dormitorios > 1
      ? `${dormitorios} dormitorios`
      : `${dormitorios} dormitorio`;
  const banosStr =
    banos === 0 || banos > 1 ? `${banos} baños` : `${banos} baño`;
  const dormitoriosBanosDropdownStr = `${dormitoriosStr}, ${banosStr}`;

  // Armar array con las comunas que no se repiten
  const ubicacionCheckboxes = uniqueComunas.map((comuna) => {
    const value = filtroUbicaciones.includes(comuna);

    return (
      <Checkbox
        name={comuna}
        key={comuna}
        onChange={onUbicacionCheckboxChange}
        position="right"
        checked={value}
      >
        <TextInfo color="black">
          <Icon name="icon-location-pin" border="round" size="12px" />
          {comuna}
        </TextInfo>
      </Checkbox>
    );
  });

  const hideDropdown = (dropdownId) => {
    const dropdownElement = document.getElementById(dropdownId);
    dropdownElement.click();

    // Restaurar scrolling de la ventana principal
    const body = document.getElementsByTagName("body")[0];
    body.style.position = "initial";
  };

  return (
    <div className="searchHome">
      {rizeWidth < BREAKPOINT_MOBILE && (
        <h2 className="title-search">
          Descubre una nueva experiencia de arriendo{" "}
        </h2>
      )}
      <div className="formContainer">
        <div className="formGroup">
          <div className="overlayDropdown"></div>
          <Title size="16" marginBotton="15">
            ¿Dónde quieres vivir?{" "}
          </Title>
          <ButtonDropdown
            id="dropdown-ubicacion"
            color="btn-white-gray"
            size="large"
            fullWidth={true}
            placeholder={dondeVivirDropdownStr}
          >
            <Dropdown title="Selecciona una ubicación">
              {ubicacionCheckboxes}
              {rizeWidth < BREAKPOINT_MOBILE && (
                <Button
                  onClick={() => hideDropdown("dropdown-ubicacion")}
                  color="btn-outline-orange"
                  className="btn mt-3"
                  fullWidth={true}
                  size="extra-small"
                >
                  APLICAR
                </Button>
              )}
            </Dropdown>
          </ButtonDropdown>
        </div>
        <div className="formGroup">
          <Title size="16" marginBotton="15">
            Rango de precios
          </Title>
          <ButtonDropdown
            color="btn-white-gray"
            size="large"
            fullWidth={true}
            placeholder={preciosDropdownStr}
          >
            <Dropdown title="Precio">
              <TitleFiltro size="16" title="Rango de precios" />
              <GroupRow>
                <Input
                  id="search-input-precio-min"
                  min={0}
                  value={`${filtroPrecioMin !== INITIAL_PRECIO_MIN
                    ? filtroPrecioMin
                    : ""
                    }`}
                  step={25000}
                  type="number"
                  typeStyle="filtro"
                  fullWidth={true}
                  help="Mínimo"
                  placeholder="$ Desde"
                  onChange={onPrecioInputChange}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
                <Input
                  id="search-input-precio-max"
                  min={0}
                  max={PRECIO_MAX_MAX}
                  value={`${filtroPrecioMax !== INITIAL_PRECIO_MAX
                    ? filtroPrecioMax
                    : ""
                    }`}
                  step={25000}
                  type="number"
                  name="passw"
                  typeStyle="filtro"
                  fullWidth={true}
                  help="Máxima"
                  placeholder="$ Hasta"
                  onChange={onPrecioInputChange}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </GroupRow>
            </Dropdown>
          </ButtonDropdown>
        </div>
        <div className="formGroup">
          <Title size="16" marginBotton="15">
            ¿Cantidad de dormitorios?
          </Title>
          <ButtonDropdown
            id="dropdown-dormitorios-banos"
            color="btn-white-gray"
            size="large"
            fullWidth={true}
            placeholder={dormitoriosBanosDropdownStr}
          >
            <Dropdown title="Cantidad de dormitorios y baños">
              <InputFiltro
                type="dormitorio"
                label="Cantidad de dormitorios"
                controlledValue={filtroDormitorios}
                onIncrease={eventAumDormitorios}
                onDecrease={eventDisDormitorios}
                name="dormitorio"
                flex={true}
                between={true}
                marginBottom="20"
              />

              <InputFiltro
                type="bano"
                label="Cantidad de baños"
                controlledValue={filtroBanos}
                onIncrease={eventAumBanos}
                onDecrease={eventDisBanos}
                name="bano"
                flex={true}
                between={true}
              />

              {rizeWidth < BREAKPOINT_MOBILE && (
                <Button
                  onClick={() => hideDropdown("dropdown-dormitorios-banos")}
                  color="btn-outline-orange"
                  className="btn mt-3"
                  fullWidth={true}
                  size="extra-small"
                >
                  APLICAR
                </Button>
              )}
            </Dropdown>
          </ButtonDropdown>
        </div>
        <div className="formGroup">
          <Button
            onClick={linkToFiltro}
            color="btn-orange"
            fullWidth={rizeWidth < BREAKPOINT_MOBILE}
            size="large"
            startIcon="icon-search-fill"
          >
            BUSCAR
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Search;
