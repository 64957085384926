import React from 'react';
import './styles/ModalGaleria.scss';
import { Modal, Icon, Button } from '../ui/index';
import Carousel from 'react-elastic-carousel';
import { BACKEND_BASE_URL } from '../../config/constant';
import notFound from '../../static/images/notFound.png';


class ModalGaleria extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentSlider: 0,
        };

        this.gotoSlider = this.gotoSlider.bind(this);
        this.nextSlider = this.nextSlider.bind(this);
        this.prevSlider = this.prevSlider.bind(this);
        this.removeActive = this.removeActive.bind(this);
    }

    gotoSlider(event) {
        const itemSelectd = event.target;
        this.removeActive();
        itemSelectd.addClass('active');
        this.sliderGalery.goTo(Number(itemSelectd.dataset.position));
    }

    nextSlider() {
        if (!this.props.proyecto) {
            return;
        }

        // Calcular countSlider a partir del número de imágenes en la galería de la tipología actual
        const proyecto = this.props.proyecto;
        const tipologiaIndex = this.props.tipologiaIndex;
        const countSlider = proyecto.tipologias[tipologiaIndex].galeria.length;

        const { activePage } = this.sliderGalery.state;
        const currentNext = Number(activePage + 1);

        if (currentNext < countSlider) {
            const currentThumb = document.querySelector('[data-position="' + currentNext + '"]');
            this.sliderThumbs.goTo(currentNext);
            this.sliderGalery.slideNext();
            this.removeActive();
            currentThumb.addClass('active');
        }
    }

    prevSlider() {
        const { activePage } = this.sliderGalery.state;
        const currentPrev = Number(activePage - 1);

        if (currentPrev >= 0) {
            const currentThumb = document.querySelector('[data-position="' + currentPrev + '"]');
            this.sliderThumbs.goTo(currentPrev);
            this.sliderGalery.slidePrev();
            this.removeActive();
            currentThumb.addClass('active');
        }
    }

    removeActive() {
        const itemsSlider = document.querySelector('.galery-image-items .item-image.active');

        if (itemsSlider) {
            itemsSlider.removeClass('active');
        }
    }

    render() {
        const { show, close } = this.props;

        // Shorthands más comodos
        const proyecto = this.props.proyecto;
        const tipologiaIndex = this.props.tipologiaIndex;

        // Item sliders (carousel principal) e item images (carousel de thumbnails)
        // Defaulteamos a un item slider e item image usando notFound.
        let itemSliders = <div className="item-slider">
            <img src={notFound} alt="notFound" />
        </div>;

        let itemImages = <div data-position="0" className="item-image active" style={{ backgroundImage: `url(${notFound})` }}></div>;

        if (proyecto) {
            if (proyecto.tipologias[tipologiaIndex].galeria.length >= 1) {
                // Construir item sliders
                itemSliders = proyecto.tipologias[tipologiaIndex].galeria.map((galeriaItem, index) => {
                    const imageUrl = `${BACKEND_BASE_URL}${galeriaItem.url}`;
                    const altText = `${BACKEND_BASE_URL}${galeriaItem.alternativeText}`;

                    return (
                        <div key={galeriaItem.id} className="item-slider">
                            <img src={imageUrl} alt={altText} style={{ width: "100%", height: "600px" }} />
                        </div>
                    );
                });

                // Construir item images
                itemImages = proyecto.tipologias[tipologiaIndex].galeria.map((galeriaItem, index) => {
                    const imageUrl = `${BACKEND_BASE_URL}${galeriaItem.url}`;
                    const classStr = 'item-image' + (index === 0 ? ' active' : '');

                    return (
                        <div key={galeriaItem.id} data-position={index} onClick={(e) => this.gotoSlider(e)} className={classStr} style={{ backgroundImage: `url(${imageUrl})` }}></div>
                    );
                });
            }
        }

        return (
            <Modal show={show} close={close}>
                <div className="modal-content modal-generico large fade">
                    <div className="header-modal">
                        <Icon onClick={() => close()} name="icon-close" size="14" />
                        Galería de imágenes
                    </div>
                    <div className="modal-body">
                        <div className="galery-container">

                            <div className="galery-image">
                                <Carousel
                                    ref={ref => (this.sliderGalery = ref)}
                                    itemsToScroll={1}
                                    itemsToShow={1}
                                    enableAutoPlay={false}
                                    showArrows={false}
                                    autoPlaySpeed={5000}
                                    className="slider-galery-image">

                                    {itemSliders}

                                </Carousel>

                                <Button className="btn btn-prev" color='btn-white-gray' size="small-icon" startIcon="icon-left-arrow" onClick={() => this.prevSlider()}></Button>
                                <Button className="btn btn-next" color='btn-white-gray' size="small-icon" startIcon="icon-button-arrow-ight" onClick={() => { this.nextSlider() }}></Button>
                            </div>

                            <div className="galery-image-items">

                                <Carousel
                                    ref={ref => (this.sliderThumbs = ref)}
                                    itemsToScroll={1}
                                    itemsToShow={4}
                                    enableAutoPlay={false}
                                    showArrows={false}
                                    autoPlaySpeed={5000}
                                    className="slider-galery-thumbs">

                                    {itemImages}

                                </Carousel>

                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default ModalGaleria;
