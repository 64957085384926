import React, { useRef, useState } from 'react';
import { Modal } from '../../ui/index';
import styles from './styles/ImageInfoCard.module.scss';


// Componente que muestra una imagen con un overlay de texto en la parte inferior. Cuando
// se la clickea, muestra otro texto.
const ImageInfoCard = ({ src, alt, frontText = '', backText = '', target="desk", imgStyle = {} }) => {

  const imageElement = useRef(null);
  const [backTextHeight, setBackTextHeight] = useState(0);
  const [backTextActive, setBackTextActive] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const onImageClick= () => {
    switch (target) {
      case 'desk':
        setBackTextActive(!backTextActive);
        break;

      case 'mobile':
        setShowModal(!showModal);
        break;

      default:
        console.log(`ImageInfoCard -> Tipo de target "${target}" desconocido`);
    } 
  }

  return (
    <div onClick={onImageClick} className={styles.ImageInfoCardContainer}>
      <img src={src} alt={alt} ref={imageElement} style={imgStyle}
        onLoad={() => {
          setBackTextHeight(imageElement.current.offsetHeight);
        }} />

      <div className={`${styles.ImageInfoCardFrontTextContainer} ${!backTextActive ? styles.ImageInfoCardFrontTextContainerActive : ''}`}>
        <p>{frontText}</p>
      </div>

      <div className={`${styles.ImageInfoCardBackTextContainer}
        ${backTextActive ? styles.ImageInfoCardBackTextContainerActive : ''}`}
        style={{ height: `${backTextHeight}px` }}>
        {backText}
      </div>
      {
        target === 'mobile' &&

        <Modal show={showModal} veilColor="rgba(235, 107, 8, 0.98)">
          <div className={styles.modalBackTextContainer}>
            {backText}
          </div>
        </Modal>
      }
    </div>
  );
}

export default ImageInfoCard;
