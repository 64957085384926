import { useEffect } from "react";
import { disableBodyScroll, enableBodyScroll } from "../utils";

// Hook para habilitar scrolling en modales, desactivando el scrolling del 
// body. El modal en cuestión tiene que tener position: fixed para que funcione.
// Cuando el componente que usa este hook es desmontado, el scrolling del body
// es restaurado.
const useModalScrolling = () => {

  useEffect(() => {
    disableBodyScroll();

    return () => enableBodyScroll();
  }, []);
}

export default useModalScrolling;
