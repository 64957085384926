import { useSelector, useDispatch } from 'react-redux';
import {
  getBarrioData,
  selectBarrioByDireccion,
  selectGetBarrioDataStatus
} from '../redux/proyectosSlice';


// Hook de datos de la página "Conoce tu barrio". Obtiene los datos del barrio pasado (i.e. el barrio
// cuya dirección coincide con `barrioDireccion`) si no fueron obtenidos, y los devuelve.
const useBarrioData = (barrioDireccion) => {

  const dispatch = useDispatch();
  const barrio = useSelector(selectBarrioByDireccion(barrioDireccion));
  const getBarrioDataStatus = useSelector(selectGetBarrioDataStatus);

  if (!barrio) {
    return null;
  }

  if ((!barrio.slider || barrio.slider.length === 0) && getBarrioDataStatus !== 'loading') {
    dispatch(getBarrioData(barrioDireccion));
  }

  return barrio;
}

export default useBarrioData;
