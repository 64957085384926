import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon, Modal } from './ui/index';
import { selectUsername, selectEmail, logoutUser } from '../redux/userSlice';
import './styles/ModalUserProfile.scss';
import ModalChangePassword from './ModalChangePassword';
import useChangePasswordMessage from '../hooks/useChangePasswordMessage';
import ModalChangeEmail from './ModalChangeEmail';
import useChangeEmailMessage from '../hooks/useChangeEmailMessage';


const USERNAME_ENABLED = false;

// Componente auxiliar de botón centrado
const CenteredButton = ({ children, ...other }) => {

  return (
    <Button
      style={{
        width: '60%',
        marginTop: '20px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '0',
      }}
      color="btn-outline-blue"
      size="extra-small"
      {...other}
    >
      {children}
    </Button>
  );
}


const ModalUserProfile = ({ show, close }) => {

  const dispatch = useDispatch();
  const username = useSelector(selectUsername);
  const email = useSelector(selectEmail);
  const headerStr = 'Información de usuario'

  const [showModalChangePassword, setShowModalChangePassword] = useState(false);
  const [showModalChangeEmail, setShowModalChangeEmail] = useState(false);

  // Mostrar mensajes de éxito/error
  useChangePasswordMessage();
  useChangeEmailMessage();

  const onLogoutClick = () => {
    dispatch(logoutUser());
    setTimeout(()=> {
      window.location.href = '/';
    }, 2000);
  }

  return (
    <>
      <Modal show={show} close={close}>
        <div className="modal-content modal-login fade">
          <div className="header-modal">
            <Icon onClick={close} name="icon-close" size="14" />
          </div>
          <div className="body-modal">
            <div className="form-content">
              <h3>{headerStr}</h3>
              <br />
              {USERNAME_ENABLED && <h6>Nombre de usuario: {username}</h6>}
              <h6>Email: {email}</h6>
              <CenteredButton onClick={() => setShowModalChangeEmail(true)}>CAMBIAR EMAIL</CenteredButton>
              <CenteredButton onClick={() => setShowModalChangePassword(true)}>CAMBIAR CONTRASEÑA</CenteredButton>
              <br /><br />
              <Button
                onClick={onLogoutClick}
                style={{
                  height: '33px',
                  width: '100px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginTop: '20px',
                  marginBottom: '50px'
                }}
                color="btn-outline-blue"
                size="extra-small"
              >
                LOGOUT
            </Button>
            </div>
          </div>
        </div>
      </Modal>
      {
        showModalChangePassword &&

        <ModalChangePassword
          show={showModalChangePassword}
          close={() => setShowModalChangePassword(false)}
        />
      }
      {
        showModalChangeEmail &&

        <ModalChangeEmail
          show={showModalChangeEmail}
          close={() => setShowModalChangeEmail(false)}
        />
      }
    </>
  );
};

export default ModalUserProfile;
