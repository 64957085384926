import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BACKEND_BASE_URL } from '../config/constant';

import equipo from '../static/images/quienes_somos/equipo.png';
import genteExterior from '../static/images/quienes_somos/gente_exterior.jpg';
import mural from '../static/images/quienes_somos/mural.jpg';
import genteInterior from '../static/images/quienes_somos/gente_interior.jpg';
import fiesta from '../static/images/quienes_somos/fiesta.jpg';
import muralNenes from '../static/images/quienes_somos/mural_nenes.jpg';

const axios = require('axios');

export const INITIAL_BANNER_DESK = { url: equipo, alt: 'equipo' };
export const INITIAL_BANNER_MOBILE = { url: genteExterior, alt: 'gente-exterior' };
export const INITIAL_HEADER = `Somos Level, Somos Comunidad`;
export const INITIAL_TEXT = `
El condimento principal de un edifico son sus vecinos, por eso en LEVEL queremos conocernos, acompañarnos y sobre todo, formar una comunidad que se respeta y se apoya.`;
export const INITIAL_TEXT_BOLD = `
¿Tú buscas lo mismo? ¡Perfecto! Únete a la Comunidad LEVEL y mantente informado sobre todas las actividades y noticias que nos importan.
`;
export const INITIAL_INGRESO_RESIDENTES_HEADER = `¿Ya eres parte de LEVEL?`;
export const INITIAL_INGRESO_RESIDENTES_TEXT = `Ingresa a la Comunidad usando tus datos.`;
export const INITIAL_SLIDER_DESK = [
  { url: mural, alt: 'mural' },
  { url: genteExterior, alt: 'gente-exterior' },
  { url: genteInterior, alt: 'gente-interior' },
  { url: fiesta, alt: 'fiesta' },
];
export const INITIAL_SLIDER_MOBILE = [
  { url: muralNenes, alt: 'mural-niños' },
  { url: genteInterior, alt: 'gente-interior' },
  { url: genteExterior, alt: 'gente-exterior' },
  { url: fiesta, alt: 'fiesta' },
  { url: mural, alt: 'mural' },
];
export const INITIAL_GET_COMUNIDAD_DATA_STATUS = 'idle';
export const INITIAL_BANNER_HEADER = `Vive una experiencia en comunidad`;
export const INITIAL_BANNER_TEXT = `Revisa aquí todo lo que celebramos en comunidad`;


const initialState = {
  bannerDesk: INITIAL_BANNER_DESK,
  bannerMobile: INITIAL_BANNER_MOBILE,

  header: INITIAL_HEADER,
  text: INITIAL_TEXT,
  textBold: INITIAL_TEXT_BOLD,

  // Textos del widget de "INGRESO RESIDENTES"
  ingresoResidentesHeader: INITIAL_INGRESO_RESIDENTES_HEADER,
  ingresoResidentesText: INITIAL_INGRESO_RESIDENTES_TEXT,

  sliderDesk: INITIAL_SLIDER_DESK,
  sliderMobile: INITIAL_SLIDER_MOBILE,

  getComunidadDataStatus: INITIAL_GET_COMUNIDAD_DATA_STATUS,

  // Textos que van sobre el banner
  bannerHeader: INITIAL_BANNER_HEADER,
  bannerText: INITIAL_BANNER_TEXT,
};


export const getComunidadData = createAsyncThunk('comunidad/getData', async () => {
  try {
    const response = await axios.get(`${BACKEND_BASE_URL}/comunidad`);
    return { response };
  } catch (error) {
    console.log(`Error intentando obtener data de "Comunidad": ${error}`);
    throw error;
  }
});


const comunidadSlice = createSlice({
  name: 'comunidad',
  initialState,
  extraReducers: {
    [getComunidadData.pending]: (state, action) => {
      state.getComunidadDataStatus = 'loading';
    },
    [getComunidadData.fulfilled]: (state, action) => {
      state.getComunidadDataStatus = 'succeeded';

      const { data } = action.payload.response;

      if (data.banner_desk) {
        state.bannerDesk = data.banner_desk;
        state.bannerDesk.alt = state.bannerDesk.alternativeText;
        state.bannerDesk.url = `${BACKEND_BASE_URL}${state.bannerDesk.url}`;
      }

      if (data.banner_mobile) {
        state.bannerMobile = data.banner_mobile;
        state.bannerMobile.alt = state.bannerMobile.alternativeText;
        state.bannerMobile.url = `${BACKEND_BASE_URL}${state.bannerMobile.url}`;
      }

      if (data.header && data.header !== '') {
        state.header = data.header;
      }

      if (data.text && data.text !== '') {
        state.text = data.text;
      }

      if (data.text_bold && data.text_bold !== '') {
        state.textBold = data.text_bold;
      }

      if (data.ingreso_residentes_header && data.ingreso_residentes_header !== '') {
        state.ingresoResidentesHeader = data.ingreso_residentes_header;
      }

      if (data.ingreso_residentes_text && data.ingreso_residentes_text !== '') {
        state.ingresoResidentesText = data.ingreso_residentes_text;
      }

      if (data.banner_header && data.banner_header !== '') {
        state.bannerHeader = data.banner_header;
      }

      if (data.banner_text && data.banner_text !== '') {
        state.bannerText = data.banner_text;
      }

      if (data.slider_desk && data.slider_desk.length > 0) {
        state.sliderDesk = data.slider_desk;

        for (let i = 0; i < state.sliderDesk.length; i++) {
          const currSlide = state.sliderDesk[i];
          currSlide.alt = currSlide.alternativeText;
          currSlide.url = `${BACKEND_BASE_URL}${currSlide.url}`;
        }
      }

      if (data.slider_mobile && data.slider_mobile.length > 0) {
        state.sliderMobile = data.slider_mobile;

        for (let i = 0; i < state.sliderMobile.length; i++) {
          const currSlide = state.sliderMobile[i];
          currSlide.alt = currSlide.alternativeText;
          currSlide.url = `${BACKEND_BASE_URL}${currSlide.url}`;
        }
      }
    },
    [getComunidadData.rejected]: (state, action) => {
      state.getComunidadDataStatus = 'failed';
    },
  }
});

export default comunidadSlice.reducer;

export const selectBannerDesk = state => state.comunidad.bannerDesk;
export const selectBannerMobile = state => state.comunidad.bannerMobile;
export const selectText = state => state.comunidad.text;
export const selectTextBold = state => state.comunidad.textBold;
export const selectSliderDesk = state => state.comunidad.sliderDesk;
export const selectSliderMobile = state => state.comunidad.sliderMobile;
export const selectGetComunidadDataStatus = state => state.comunidad.getComunidadDataStatus;
export const selectAllComunidadData = state => state.comunidad;
