import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
// import ReactGA from "react-ga4";
import * as serviceWorker from './serviceWorker';
import Home from './pages/Home';
import Ui from './components/Ui';
import Result from './pages/Result';
import Place from './pages/Place';
import ResultMap from './pages/ResultMap';
import Edificios from './pages/Edificios';
import Contacto from './pages/Contacto';
import QuienesSomosNosotros from './pages/QuienesSomos/QuienesSomosNosotros';
import QuienesSomosBeneficios from './pages/QuienesSomos/QuienesSomosBeneficios';
import QuienesSomosComunidad from './pages/QuienesSomos/QuienesSomosComunidad';
import QuienesSomosPreguntasFrecuentes from './pages/QuienesSomos/QuienesSomosPreguntasFrecuentes';
import Estacionamientos from './pages/Estacionamientos';
import Bodegas from './pages/Bodegas';
import IngresoMantencion from './pages/IngresoMantencion';
import ResetPassword from './pages/ResetPassword';
import TerminosPolitica from './pages/TerminosPolitica';
import ConoceTuBarrio from './pages/ConoceTuBarrio';
import MisCotizaciones from './pages/MisCotizaciones';
import Agendamiento from './pages/Agendamiento';
import { FEATURE_ENABLED, GOOGLE_ANALYTICS } from './config/constant';
import { getVersion, serviceWorkerLoadNewContent, setVersion, validateJwt } from './utils';
import WrapperNuevoContenido from './components/WrapperNuevoContenido';
import { useDispatch } from 'react-redux';
const VERSION_SITE = '21010902346'

// // GC initialize
// ReactGA.initialize(GOOGLE_ANALYTICS);

const Routes = () => {
  const dispatch = useDispatch();
  validateJwt(dispatch)
  const [waitingWorker, setWaitingWorker] = useState(null);

  // Código para que el service worker cargue el nuevo contenido sin necesidad de cerrar y volver a
  // abrir la página.
  // Basado en el código e información que se puede ver en la siguiente URL:
  // https://dev.to/daniellycosta/showing-new-version-available-notification-on-create-react-app-pwas-3jc9
  // Básicamente, se hace lo siguiente: Se registra el service worker y se settea un callback
  // que es llamado cuando el service worker detecta nuevo contenido. En el callback enviamos una señal
  // al service worker para que, en vez de esperar a que la página se cierre y vuelva a abrir para cargar
  // el nuevo contenido (que es el comportamiento default), cargue inmedatamente el nuevo contenido.
  const onServiceWorkerUpdate = (registration) => {
    if (registration && registration.waiting) {
      if (!FEATURE_ENABLED.MODAL_NUEVO_CONTENIDO) {
        serviceWorkerLoadNewContent(registration.waiting);
      }

      setWaitingWorker(registration.waiting);
    }
  };

  useEffect(() => {

    serviceWorker.register({ onUpdate: onServiceWorkerUpdate });

    /* const version = getVersion();
    if (version === null) {
      setVersion(VERSION_SITE)
      console.log('regs 1 ', waitingWorker);
      serviceWorkerLoadNewContent(waitingWorker);
    } else {
      if (version !== VERSION_SITE) {
        console.log('regs 2 ', waitingWorker);
        navigator.serviceWorker.controller.postMessage('cleanUp');
        setVersion(VERSION_SITE)
      }
    } */
  }, []);

  // useEffect(() => {
  //   // ReactGA.pageview(window.location.pathname + window.location.search);
  //   console.log('pageview', window.location.pathname + window.location.search);
  //   ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  // }, []);

  return (
    <Switch>
      <Route path="/" exact render={({ match }) => {
        return (
          <WrapperNuevoContenido waitingWorker={waitingWorker}>
            <Home match={match} />
          </WrapperNuevoContenido>
        );
      }} />

      <Route path="/result" render={({ match }) => {
        return (
          <WrapperNuevoContenido waitingWorker={waitingWorker}>
            <Result match={match} />
          </WrapperNuevoContenido>
        );
      }} />

      <Route path="/result-map/:tipo?" render={() => {
        return (
          <WrapperNuevoContenido waitingWorker={waitingWorker}>
            <ResultMap />
          </WrapperNuevoContenido>
        );
      }} />

      <Route path="/nuestros-edificios/:slug/:reserva?" render={() => {
        return (
          <WrapperNuevoContenido waitingWorker={waitingWorker}>
            <Place />
          </WrapperNuevoContenido>
        );
      }} />

      <Route path="/edificios" render={({ match }) => {
        return (
          <WrapperNuevoContenido waitingWorker={waitingWorker}>
            <Edificios match={match} />
          </WrapperNuevoContenido>
        );
      }} />

      <Route path="/contacto/horarios" render={({ match }) => {
        return (
          <WrapperNuevoContenido waitingWorker={waitingWorker}>
            <Contacto match={match} />
          </WrapperNuevoContenido>
        );
      }} />

      <Route path="/contacto" render={({ match }) => {
        return (
          <WrapperNuevoContenido waitingWorker={waitingWorker}>
            <Contacto match={match} />
          </WrapperNuevoContenido>
        );
      }} />

      <Route path="/quienes-somos-nosotros" render={() => {
        return (
          <WrapperNuevoContenido waitingWorker={waitingWorker}>
            <QuienesSomosNosotros />
          </WrapperNuevoContenido>
        );
      }} />

      <Route path="/quienes-somos-beneficios" render={() => {
        return (
          <WrapperNuevoContenido waitingWorker={waitingWorker}>
            <QuienesSomosBeneficios />
          </WrapperNuevoContenido>
        );
      }} />

      <Route path="/quienes-somos-comunidad" render={() => {
        return (
          <WrapperNuevoContenido waitingWorker={waitingWorker}>
            <QuienesSomosComunidad />
          </WrapperNuevoContenido>
        );
      }} />

      <Route path="/quienes-somos-preguntas-frecuentes" render={() => {
        return (
          <WrapperNuevoContenido waitingWorker={waitingWorker}>
            <QuienesSomosPreguntasFrecuentes />
          </WrapperNuevoContenido>
        );
      }} />

      <Route path="/estacionamientos" render={({ match }) => {
        return (
          <WrapperNuevoContenido waitingWorker={waitingWorker}>
            <Estacionamientos match={match} />
          </WrapperNuevoContenido>
        );
      }} />

      <Route path="/bodegas" render={({ match }) => {
        return (
          <WrapperNuevoContenido waitingWorker={waitingWorker}>
            <Bodegas match={match} />
          </WrapperNuevoContenido>
        );
      }} />

      <Route path="/ingreso-mantencion/:page?" render={({ match }) => {
        return (
          <WrapperNuevoContenido waitingWorker={waitingWorker}>
            <IngresoMantencion match={match} />
          </WrapperNuevoContenido>
        );
      }} />

      <Route path="/reset-password" render={({ match }) => {
        return (
          <WrapperNuevoContenido waitingWorker={waitingWorker}>
            <ResetPassword match={match} />
          </WrapperNuevoContenido>
        );
      }} />

      <Route path="/terminos-y-politica" render={({ match }) => {
        return (
          <WrapperNuevoContenido waitingWorker={waitingWorker}>
            <TerminosPolitica match={match} />
          </WrapperNuevoContenido>
        );
      }} />

      <Route path="/conoce-tu-barrio" render={({ match }) => {
        return (
          <WrapperNuevoContenido waitingWorker={waitingWorker}>
            <ConoceTuBarrio match={match} />
          </WrapperNuevoContenido>
        );
      }} />

      <Route path="/mis-cotizaciones" render={({ match }) => {
        return (
          <WrapperNuevoContenido waitingWorker={waitingWorker}>
            <MisCotizaciones match={match} />
          </WrapperNuevoContenido>
        );
      }} />

      <Route path="/agendamiento" render={({ match }) => {
        return (
          <WrapperNuevoContenido waitingWorker={waitingWorker}>
            <Agendamiento match={match} />
          </WrapperNuevoContenido>
        );
      }} />

      <Route path="/ui" component={Ui} />
    </Switch>
  );
}

export default Routes;
