import React from 'react';
import { Modal, Icon, TextInfo } from '../../../ui/index';
import './styles/ModalSolicitud.scss';


const ModalSolicitud = ({close}) => {
    return (
        <Modal show={true} close={close}>
        <div className="modal-content help fade">
            <div className="header-modal">
                <Icon onClick={close} name="icon-close" size="14" /> Solicitud de evaluación
            </div>
            <div className="_body-help detalleSlicitud">
                <h2 className="_titular-regular">¿Cómo enviar una correcta evaluación?</h2>
                <p className="_text">La evaluación que debes realizar depende del tipo de cliente (trabajador que eres). Podrás evaluarte siendo cliente Dependiente, Independiente y No residente. Un contrato debe tener un Titular y un Aval. Si es necesario podrás apoyarte con alguien adicional que será responsable como tú al ser Co-Titular. Más adelante verás el detalle en cada caso.</p>
                <span className="_text_orange">Te explicamos qué tipo de cliente/trabajador puedes ser:</span>
                <p className="_text"><strong>Cliente Dependiente: </strong>Se considera aquel trabajador que tiene un contrato laboral vigente. Chilenos o extranjeros con rut. </p>
                <p className="_text"><strong>Cliente Independiente: </strong>Aquel trabajador que presta servicios y emite boleta por estos (persona natural), así como quienes tienen su empresa (persona jurídica). Chilenos o extranjeros con rut. </p>
                <p className="_text"><strong>No residente/visa responsabilidad democrática: </strong>Aquel cliente extranjero con pasaporte vigente o con Visa en trámite o con visa responsabilidad democrática.</p>
                <p className="_text">A continuación, te contamos lo que debes tener presente y qué necesitarás para evaluarte en cada caso. Debes tener presente que tú como titular puedes ser un tipo de cliente distinto a tu aval o Co-titular. Deberás adjuntar la documento que corresponda en cada caso.</p>
                <span className="_text_orange">Te explicamos qué tipo de cliente/trabajador puedes ser:</span>
                <p className="_text">Debes contar con un aval. <a href="# ">Si no tienes uno puedes ver la opción de pre-pagar la totalidad del contrato, de esta forma no necesitarás uno.</a> Debes contar con una renta aproximada de al menos 3 veces el valor del arriendo, o 4 veces entre tu (titular) y tu aval. Si no cumples con alguna de las alternativas del punto anterior, puedes incorporar a un CO-TITULAR el cuál debe ser responsable como tu del arriendo. Esta persona aportará con su renta en la evaluación. Requisitos de documentos con los que debes contar tú y tu aval (y co-titular en caso que incorpores). Estos últimos según corresponda según el tipo de trabajador que sean:</p>

                <ul>
                    <li>Carnet de identidad vigente. Si no eres residente y tu CI está vencido debes presentar el documento de la Solicitud de Permanencia Definitiva. </li>
                    <li>Dicom platinum 360° o Boletín comercial. No debe mostrar deudas con otras entidades, sólo evaluaremos Dicom con deudas por estudios.</li>
                    <li>3 últimas liquidaciones de sueldo (si trabajas hace menos tiempo ingresa las que tengas más tu contrato de trabajo) </li>
                    <li>Últimas 12 cotizaciones de AFP (si trabajar hace menos tiempo ingresa todas las que tengas)</li>
                </ul>

                <span className="_text_orange">2. Si el cliente titular es Independiente:</span>

                <p className="_text">Puedes ser independiente por prestar servicios y entregar boletas (persona natural) o tener una empresa (persona Jurídica). Sólo podrás evaluarte de forma Online si eres Independiente persona natural, si quieres evaluarte como empresa persona jurídica, por favor CONTÁCTANOS! </p>
                <p className="_text">Debes contar con un aval. <a href="# ">Si no tienes uno puedes ver la opción de pre-pagar la totalidad del contrato, de esta forma no necesitarás uno.</a> Si eres independiente y emites Boleta Debes demostrar ingresos aproximados de al menos 3 veces el valor del arriendo, o 4 veces entre tu (titular) y tu aval. Si no cumples con alguna de las alternativas del punto anterior, puedes incorporar a un CO-TITULAR el cuál debe ser responsable como tu del arriendo. Esta persona aportará con su renta en la evaluación. Requisitos de documentos con los que debes contar tú y tu aval (y co-titular en caso que incorpores). Estos últimos según corresponda según el tipo de trabajador que sean:</p>

                <ul>
                    <li>Carnet de identidad vigente. Si no eres residente y tu CI está vencido debes presentar el documento de la Solicitud de Permanencia Definitiva. </li>
                    <li>Dicom platinum 360° o Boletín comercial. No debe mostrar deudas con otras entidades, sólo evaluaremos Dicom con deudas por estudios. </li>
                    <li>6 últimas boletas de honorarios. Si tu aval es independiente también deberá presentar estas, si no, revisa los requisitos para Dependiente.</li>
                    <li>Inicio de Actividades en SII. Si tu aval es independiente también deberá presentar estas, si no, revisa los requisitos para Dependiente.</li>
                </ul>

                <span className="_text_orange">3. Si el cliente es No residente / visa responsabilidad democrática</span>

                <p className="_text">Debes contar con un aval. Si no tienes uno puedes ver la opción de pre-pagar la totalidad del contrato, de esta forma no necesitarás uno. Debes demostrar capacidad de pago de aproximadamente al menos 3 veces el valor del arriendo, o 4 veces entre tu (titular) y tu aval. Si no cumples con alguna de las alternativas del punto anterior, puedes incorporar a un CO-TITULAR el cuál debe ser responsable como tu del arriendo. Esta persona aportará con su renta en la evaluación. Requisitos de documentos con los que debes contar tú y tu aval (y co-titular en caso que incorpores). Estos últimos según corresponda según el tipo de trabajador que sean:</p>

                <ul>
                    <li>Pasaporte Vigente</li>
                    <li>Documento de solicitud de visa.</li>
                    <li>Documento ingreso al país. PDI.</li>
                    <li>Documentos que acrediten ingresos o liquidez. </li>
                    <li>Visa de Responsabilidad democrática si es que la tienes.</li>
                </ul>

            </div>
            <div onClick={close} className="_footer-help">
                <TextInfo>CERRAR Y CONTINUAR</TextInfo>
            </div>
        </div>
    </Modal>
    );
};

export default ModalSolicitud;
