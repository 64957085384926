import React from "react";
import styles from "./styles/CardEjecutivo.module.scss";
import { Icon as Iconify } from "@iconify/react";
import whatsappIcon from "@iconify/icons-mdi/whatsapp";
import emailIcon from "@iconify/icons-mdi/email-outline";
import phoneIcon from "@iconify/icons-mdi/phone";
import notFound from "../static/images/notFound.png";
import { deleteNonDigits } from "../utils";

const EMAIL_SUBJECT = `Level - Contacto ejecutivo`;

// Componente de carta de ejecutivo. Asume que el objeto 'ejecutivo' tiene la forma
// { nombre, email, telefono, edificios, picUrl }, donde 'edificios' es un array de strings,
// y picUrl es la URL de la foto de perfil
const CardEjecutivo = ({ ejecutivo }) => {
  let nombre, telefono, sanitizedTelefono, email, picUrl;
  const darkColor = "rgb(44, 43, 66)";

  if (ejecutivo) {
    nombre = ejecutivo.nombre ? ejecutivo.nombre : "";
   /*  telefono = ejecutivo.telefono ? ejecutivo.telefono : "";
    sanitizedTelefono = deleteNonDigits(telefono);
    email = ejecutivo.email ? ejecutivo.email : ""; */
    picUrl = ejecutivo.picUrl ? ejecutivo.picUrl : notFound;
  }

  return (
    <div className={styles.container}>
      <span className={styles.nombre}>{nombre}</span>
      <img src={picUrl} alt="foto-perfil" className={styles.profilePic} />
     {/*  <div className={styles.socialContainer}>
        <div
          className={`${styles.iconifyContainer} ${styles.iconifyContainerWhatsapp}`}
        >
           <a
            href={`https://api.whatsapp.com/send?phone=${sanitizedTelefono}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Iconify
              icon={whatsappIcon}
              className={styles.iconify}
              color="white"
            />
          </a> 
        </div>
        <div className={styles.iconifyContainer}>
          <a href={`mailto:${email}?subject=${EMAIL_SUBJECT}`} target="_blank" rel="noopener noreferrer">
            <Iconify icon={emailIcon} className={styles.iconify} color={darkColor} />
          </a>
        </div>
        <div className={styles.iconifyContainer}>
          <a
            href={`tel:${sanitizedTelefono}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Iconify
              icon={phoneIcon}
              className={styles.iconify}
              color={darkColor}
            />
          </a>
        </div>
      </div>
      <span className={styles.telefono}>{telefono}</span>
      <span className={styles.email}>{email}</span> */}
    </div>
  );
};

export default CardEjecutivo;
