/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Modal, Icon, TextInfo, Button} from '../../ui/index';
import './styles/SaveCotizacion.scss';
import CardDetalle from './CardDetalle';


const GuardadoCotizacion = ({ close, detalle }) => {
  return (
    <Modal show={true} close={close}>
      <div className="modal-content modal-login fade">
        <div className="header-modal">
          <a onClick={close}><Icon name="icon-close" size="14" /></a>
        </div>
        <div className="body-modal">
          <div className="form-content fill">
            <h3 className="small">Tu cotización se <br /> ha guardado con éxito</h3>
            <div className="_bodySave-c">
              <CardDetalle detalle={detalle}/>
              <div className="_bottom-c">
                <Button onClick={close} color='btn-orange' size="small" fullWidth={true}>CONTINUAR AHORA</Button>
                <TextInfo color="orange" className="link">Ver todas tus cotización</TextInfo>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default GuardadoCotizacion;
