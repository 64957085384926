import React from 'react';
import './styles/Ofertar.scss';
import { Button } from '../ui/index';
import { FEATURE_ENABLED } from '../../config/constant';


const Oferta = () => {

  return (
    <div>
      {
        FEATURE_ENABLED.PROMO_ALERTS &&

        <div className="card-oferta">
          <h2 className="card-title-oferta">Paga la garantía y comisión de tu  <br /> arriendo en 3 cuotas.</h2>
          <div className="card-footer-oferta">
            <p className="card-description-oferta"><strong>¡Arrienda en Lira 320!</strong> Encuentra arriendos desde $265.000 <br /> y paga la garantía y comisión en 3 cuotas  </p>
            <Button color='btn-gray' size="medium">VER OFERTA</Button>
          </div>
        </div>
      }
    </div>
  );
};

export default Oferta;
