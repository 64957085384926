import React from 'react'
import PropTypes from 'prop-types';
import Carousel from 'react-elastic-carousel'

import './Slider.scss';

const Slider = (props) => {
    const { className = '', itemsToShow = 1, enableAutoPlay = false, children, ref } = props;

    return(
        <Carousel 
            ref = {ref}
            itemsToShow={itemsToShow} 
            enableAutoPlay={enableAutoPlay} 
            showArrows={false}
            autoPlaySpeed={5000}
            className={className}>
            {children}
        </Carousel>
    );

}

Slider.propTypes = {
    className: PropTypes.string,
    itemsToShow: PropTypes.number,
    enableAutoPlay: PropTypes.bool
}

export default Slider;