import React, { useEffect } from 'react';
import Experiencia from '../../components/QuienesSomos/Nosotros/Experiencia';
import Layout from '../../components/Layout';
import styles from '../../components/QuienesSomos/Nosotros/styles/Nosotros.module.scss';
import SobreLevel from '../../components/QuienesSomos/Nosotros/SobreLevel';
import NuestrosEdificios from '../../components/QuienesSomos/Nosotros/NuestrosEdificios';
import Equipo from '../../components/QuienesSomos/Nosotros/Equipo';
import EdificiosDestacados from '../../components/QuienesSomos/Nosotros/EdificiosDestacados';
import useResize from '../../hooks/useResize';
import { BREAKPOINT_MOBILE } from '../../config/constant';
import { scrollToTop } from '../../utils';
import useNosotrosData from '../../hooks/useNosotrosData';


const QuienesSomosNosotros = () => {

  const [rizeWidth] = useResize();
  const nosotrosData = useNosotrosData();

  useEffect(() => {
    scrollToTop();
  }, []);

  const destacadosPos = rizeWidth < BREAKPOINT_MOBILE ? {
    position: 'relative',
    top: '-800px',
    backgroundColor: '#F0F4FA',
    paddingTop: '0',
  } : {
    backgroundColor: '#F0F4FA',
  };

  const destacadosPadding = rizeWidth < BREAKPOINT_MOBILE ? {
    padding: '0'
  } : {
    paddingTop: '10px'
  };


  return (
    <Layout>
      <div className="container">
        <div className={styles.innerContainer}>
          <Experiencia nosotrosData={nosotrosData} />
          <SobreLevel nosotrosData={nosotrosData} />
          <NuestrosEdificios nosotrosData={nosotrosData} />
          <Equipo nosotrosData={nosotrosData} />
          <section id="destacados" style={destacadosPos}>
            <div className="row" style={destacadosPadding}>
              <EdificiosDestacados />
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
}

export default QuienesSomosNosotros;
