import React from 'react';
import imgHeader from '../../static/images/header_contacto.png';
import SocialButtons from '../SocialButtons';
import styles from './styles/HeroMobile.module.scss';


const HeroMobile = () => {

  const socialButtonsContainerStyle = {
    /*display: 'flex',
    position: 'relative',
    top: '-200px',
    justifyContent: 'center',*/
    
    display: 'flex',
    top: '30px',
    position: 'absolute',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignContent: 'flex-start',
    alignItems: 'center',
    padding: '0 30%',
    justifyContent: 'center',
  };


  return (
    <div className="container">
      <div className={styles.topContainer}>
        <img src={imgHeader} alt="header" />
        <div className={styles.imgFilter} />
        <h1 className={styles.newContact}>Contáctanos</h1>
        <SocialButtons
          target="mobile-light"
          containerStyle={socialButtonsContainerStyle}
        />
      </div>
    </div>
  );
}

export default HeroMobile;
