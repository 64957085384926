import React from 'react';


// Devuelve el menor precio de los estacionamientos del proyecto
const PrecioMensualEstacionamientos = ({ proyecto }) => {

  const precio = Math.min(proyecto.precioMinEstacionamientosAuto, proyecto.precioMinEstacionamientosMoto);

  return (
    <span>${precio.toLocaleString()}</span>
  );
}

export default PrecioMensualEstacionamientos;
