// FIXME: Este archivo está duplicado en el back-end (level.cms/extensions/users-permissions/config
// /policies/validation.js). Ojo con modificar uno sin modificar el otro!

import {
  TIPO_ARCHIVO,
  TIPO_GENERO,
  TIPO_PERSONA,
  TIPO_TRABAJADOR,
  VALIDATION_ENABLED,
} from './config/constant';
import {
  decommify,
  formatearRut,
  isNumberStr,
  quitarFormatoRut
} from './utils';

// Funciones de validación del frontend. Devuelven true si el dato es válido, false otherwise.


const NAME_MIN_LENGTH = 3;
const PASSWORD_MIN_LENGTH = 5;


// Devuelve true si la fecha está en el futuro
export function isFutureDate(date) {
  if (!VALIDATION_ENABLED) {
    return true;
  }

  const now = new Date();

  // Hacemos esto porque si el usuario elige el día actual, el chequeo fallaría
  // porque `now` sería ligeramente mayor a `fechaSalida` (porque el usuario elige
  // la fecha *antes* de tocar el botón 'Enviar' que triggerea este chequeo)
  now.setHours(0, 0, 0, 0);

  // Sorry, time travellers
  return (date - now) >= 0;
}

export function isValidNombre(nombre) {
  if (!VALIDATION_ENABLED) {
    return true;
  }

  if (nombre.length < NAME_MIN_LENGTH) {
    return false;
  }

  // Nombres válidos: solamente pueden contener letras (incluyendo acentos y ñ) y espacios
  const found = nombre.match(/^[a-zA-ZÀ-ÿ\u00f1\u00d1\s]*$/);

  return found !== null;
}

export function getInvalidNombreString() {
  return `El nombre sólo puede contener letras y espacios`;
}

export function getNombrePlaceHolder() {
  return `Nombre, por ej. Mateo Tomás`;
}

export function isValidApellido(apellido) {
  if (!VALIDATION_ENABLED) {
    return true;
  }

  return isValidNombre(apellido);
}

export function getInvalidApellidoString() {
  return `Los apellidos sólo pueden contener letras y espacios`;
}

export function getApellidoPaternoPlaceholder() {
  return `Apellido paterno, por ej. González`;
}

export function getApellidoMaternoPlaceholder() {
  return `Apellido materno, por ej. Muñoz`;
}

// Devuelve true si el RUT tiene formato válido, false en otro caso. Asume que el RUT ya está
// formateado.
function rutHasValidFormat(rut) {
  if (!VALIDATION_ENABLED) {
    return true;
  }

  if (!/[0-9]{1,2}.[0-9]{3}.[0-9]{3}-[0-9Kk]{1}/.test(rut)) {
    return false;
  }
  return true;
}

// Devuelve true si es un RUT válido (i.e. de la forma '12.345.678-5' o '12345678-5'), false en caso contrario.
// Créditos van a https://github.com/kadumedia/jQuery.rut.chileno
function isValidRut(rut) {
  if (!VALIDATION_ENABLED) {
    return true;
  }

  const rutFormateado = formatearRut(rut);

  if (!rutHasValidFormat(rutFormateado)) {
    return false;
  }

  const tmp = rutFormateado.split('-');
  let dv = tmp[1];
  const tmp2 = tmp[0].split('.').join('');

  if (dv === 'K') {
    dv = 'k';
  }

  function checkRut(rut) {
    var M = 0, S = 1;
    for (; rut; rut = Math.floor(rut / 10))
      S = (S + rut % 10 * (9 - M++ % 6)) % 11;
    return S ? String(S - 1) : 'k';
  }

  return (checkRut(tmp2) === dv);
}

// Chequea la validez de un documento genérico. Esto es necesario porque la API de autenticación
// de Level *no* distingue tipos de documento.
export function isValidGenericDocumento(documento) {
  if (!VALIDATION_ENABLED) {
    return true;
  }

  return documento.length >= 8 && isNumberStr(quitarFormatoRut(documento));
}

export function getInvalidGenericDocumentoString() {
  return 'Documento inválido: El documento debe contener solamente 8 o más dígitos';
}

export function isValidDocumento(tipoDocumento, documento) {
  if (!VALIDATION_ENABLED) {
    return true;
  }

  if (tipoDocumento.length === 0 || documento.length === 0) {
    return false;
  }

  const documentoSanitized = quitarFormatoRut(documento);

  switch (tipoDocumento) {
    case 'RUT':
      {
        return isValidRut(documentoSanitized);
      }

    case 'DNI':
      {
        // DNI válido tiene formato XXXXXXXX (sólo verificamos que haya al menos 8 dígitos)
        const found = documentoSanitized.match(/^\d{8,}$/);
        return found !== null;
      }

    case 'Pasaporte':
      {
        // Pasaporte válido tiene formato XXXXXXXX (sólo verificamos que haya al menos 8 dígitos)
        const found = documentoSanitized.match(/^\d{8,}$/);
        return found !== null;
      }

    default:
      console.log(`isValidDocumento: tipo de documento "${tipoDocumento}" desconocido`);
      return false;
  }
}

// Devuelve una string con un ejemplo de formato válido para el tipo de documento pasado.
// Devuelve una string vacía '' si el tipo de documento es inválido.
// Ejemplo: getValidDocumentoFormat('RUT') -> '345678905'
export function getValidDocumentoFormat(tipoDocumento) {
  switch (tipoDocumento) {
    case 'RUT':
      return '12345678-5';

    case 'DNI':
      return '34567890';

    case 'Pasaporte':
      return '34567890';

    default:
      console.log(`getValidDocumentoFormat: tipo de documento "${tipoDocumento}" desconocido`);
      return '';
  }
}

export function getDocumentoPlaceholder() {
  return `Número de RUT, por ej. 12345678-5`;
}

export function getRUTPlaceholder() {
  return `Número de RUT, por ej. 12345678-5`;
}

// Devuelve una string describiendo el error del RUT. Lo usamos para distinguir un RUT con
// formato erróneo de un RUT inválido.
function getRutErrorString(rut) {
  const rutFormateado = formatearRut(rut);

  if (!rutHasValidFormat(rutFormateado)) {
    return `El RUT debe tener el formato ${getValidDocumentoFormat('RUT')}`;
  }

  return `RUT inválido`;
}

// Devuelve una string con un mensaje "El [tipo documento] debe tener el formato [formato]"
export function getInvalidDocumentString(tipoDocumento, numeroDocumento) {
  if (!tipoDocumento) {
    return `Debe seleccionarse un tipo de documento`;
  }

  if (tipoDocumento === 'RUT') {
    return getRutErrorString(numeroDocumento);
  }

  return `El ${tipoDocumento} debe tener el formato ${getValidDocumentoFormat(tipoDocumento)}`;
}

export function isValidPersona(tipoPersona) {
  if (!VALIDATION_ENABLED) {
    return true;
  }

  for (let i = 0; i < TIPO_PERSONA.length; i++) {
    if (tipoPersona === TIPO_PERSONA[i]) {
      return true;
    }
  }

  console.log(`isValidPersona: tipo de persona "${tipoPersona}" desconocido`);
  return false;
}

export function isValidTelefono(telefono) {
  if (!VALIDATION_ENABLED) {
    return true;
  }

  return isNumberStr(telefono);
}

export function getInvalidTelefonoString() {
  return `El teléfono debe contener sólo números`;
}

export function getTelefonoPlaceholder() {
  return `Teléfono, por ej. 56989692937`;
}

/**
 * Chequea email válido con las siguientes reglas:
 * - Sólo los caracteres [a-zA-Z], [0-9], _ (guión bajo), - (guión medio), @ (arroba),
 * y . (punto) son permitidos.
 * - Mínimo 5 caracteres.
 * - Máximo 255 characters.
 * - Contiene al menos un '@' (arroba).
 * @param email {String} Email to check.
 * @returns {Boolean} True if the email is valid; false otherwise.
 */
export function isValidEmail(email) {
  if (!VALIDATION_ENABLED) {
    return true;
  }

  if (!email.includes('@')) {
    return false;
  }

  const regexp = /^[a-zA-Z0-9_\-@.]{5,255}$/;
  return regexp.test(email);
}

export function getInvalidEmailString() {
  return `El correo electrónico debe contener al menos 5 caracteres y un @`;
}

export function getEmailPlaceholder() {
  return `Correo electrónico, por ej. juliana@gmail.com`;
}

export function isValidPassword(password) {
  if (!VALIDATION_ENABLED) {
    return true;
  }

  return password.length >= PASSWORD_MIN_LENGTH;
}

export function getInvalidPasswordString() {
  return `La contraseña debe contener al menos ${PASSWORD_MIN_LENGTH} caracteres`;
}

export function isValidRentaMensual(rentaMensual) {
  if (!VALIDATION_ENABLED) {
    return true;
  }

  const cleanedRentaMensual = decommify(rentaMensual);

  return isNumberStr(cleanedRentaMensual);
}

export function isValidMensaje(mensaje) {
  if (!VALIDATION_ENABLED) {
    return true;
  }

  return mensaje.trim().length >= 4;
}

export function isValidNroDepto(nroDepto) {
  if (!VALIDATION_ENABLED) {
    return true;
  }

  return isNumberStr(nroDepto);
}

export function isValidEdificio(edificio) {
  if (!VALIDATION_ENABLED) {
    return true;
  }

  return edificio.length >= 4;
}

export function isValidFechaSalida(fechaSalida) {
  if (!VALIDATION_ENABLED) {
    return true;
  }

  return isFutureDate(fechaSalida);
}

export function isValidNacionalidad(nacionalidad) {
  if (!VALIDATION_ENABLED) {
    return true;
  }

  return nacionalidad.length >= 4;
}

export function isValidGenero(genero) {
  if (!VALIDATION_ENABLED) {
    return true;
  }

  for (let i = 0; i < TIPO_GENERO.length; i++) {
    if (genero === TIPO_GENERO[i]) {
      return true;
    }
  }

  console.log(`isValidGenero: tipo de género "${genero}" desconocido`);
  return false;
}

export function isValidAnoNacimiento(anoNacimiento) {
  if (!VALIDATION_ENABLED) {
    return true;
  }

  return isNumberStr(anoNacimiento);
}

export function isValidProfesion(profesion) {
  if (!VALIDATION_ENABLED) {
    return true;
  }

  return profesion.length >= 2;
}

export function isValidActividad(actividad) {
  if (!VALIDATION_ENABLED) {
    return true;
  }

  return actividad.length >= 2;
}

export function isValidTrabajador(tipoTrabajador) {
  if (!VALIDATION_ENABLED) {
    return true;
  }

  for (let i = 0; i < TIPO_TRABAJADOR.length; i++) {
    if (tipoTrabajador === TIPO_TRABAJADOR[i]) {
      return true;
    }
  }

  console.log(`isValidTrabajador: tipo de trabajador "${tipoTrabajador}" desconocido`);
  return false;
}

export function isValidRegion(region) {
  if (!VALIDATION_ENABLED) {
    return true;
  }

  return region.length >= 2;
}

export function isValidCiudad(ciudad) {
  if (!VALIDATION_ENABLED) {
    return true;
  }

  return ciudad.length >= 2;
}

export function isValidComuna(comuna) {
  if (!VALIDATION_ENABLED) {
    return true;
  }

  return comuna.length >= 2;
}

export function isValidDireccion(direccion) {
  if (!VALIDATION_ENABLED) {
    return true;
  }

  return direccion.length >= 2;
}

export function isValidNumeroPisoDepto(numeroPisoDepto) {
  if (!VALIDATION_ENABLED) {
    return true;
  }

  return numeroPisoDepto.length >= 2;
}

export function isValidPoblacion(poblacion) {
  if (!VALIDATION_ENABLED) {
    return true;
  }

  return poblacion.length >= 2;
}

export function isValidMesesArriendo(mesesArriendo) {
  if (!VALIDATION_ENABLED) {
    return true;
  }

  return isNumberStr(mesesArriendo);
}

export function isValidFechaInicioContrato(fechaInicioContrato) {
  if (!VALIDATION_ENABLED) {
    return true;
  }

  return isFutureDate(fechaInicioContrato);
}

// Chequea tipo de archivo válido para subir
export function isValidFileType(fileType) {
  if (!VALIDATION_ENABLED) {
    return true;
  }

  for (let i = 0; i < TIPO_ARCHIVO.length; i++) {
    if (fileType === TIPO_ARCHIVO[i].mime) {
      return true;
    }
  }
  return false;
}

// Devuelve una string con los tipos válidos de archivos, separados por una coma
export function getValidFileTypes() {
  const arr = [];

  for (let i = 0; i < TIPO_ARCHIVO.length; i++) {
    arr.push(TIPO_ARCHIVO[i].userFriendly);
  }

  return arr.join(', ');
}

// Regla de negocio: Si el titular tiene una renta que es mayor o igual a (arriendoMensual * 3),
// pasa de una. Si la renta del titular no llega a eso, entre la renta del titular y la
// renta de los avales/complementos tienen que sumar al menos (arriendoMensual * 4).
export function rentasAreEnough(arriendoMensual, rentaTitular, rentasAvales, rentasComplementos) {
  if (!VALIDATION_ENABLED) {
    return true;
  }

  if (rentaTitular >= (arriendoMensual * 3)) {
    return true;
  }

  let rentasAvalesTotal = rentasAvales.length ? rentasAvales.reduce((acc, curr) => acc + curr) : 0;
  let rentasComplementosTotales = rentasComplementos.length ? rentasComplementos.reduce((acc, curr) => acc + curr) : 0;

  // Cuando no se ingresaron avales, rentasAvales es un array de strings vacías. Detectamos ese caso
  // y asignamos 0.
  rentasAvalesTotal = rentasAvalesTotal === '' ? 0 : rentasAvalesTotal;

  // Idem para los complementos.
  rentasComplementosTotales = rentasComplementosTotales === '' ? 0 : rentasComplementosTotales;

  if ((rentaTitular + rentasAvalesTotal + rentasComplementosTotales) >= (arriendoMensual * 4)) {
    return true;
  }

  return false;
}
