import React from 'react';
import { useSelector } from 'react-redux';
import { MIN_GASTO_COMUN } from '../config/constant';
import { selectFilteredTipologias } from '../redux/proyectosSlice';
import { getTipologiaGastoComun } from '../utils';
import GastoComunTipologia from './GastoComunTipologia';


// Devuelve el gasto común de un proyecto, i.e. el menor gasto común de las tipologías
// que pasan el filtro.
const GastoComunProyecto = ({ proyecto }) => {

  const filteredTipologias = useSelector(selectFilteredTipologias(proyecto ? proyecto.id : null));
  let tipologia = null;
  let gastoComun = Number.MAX_SAFE_INTEGER;
  let cheapestTipoIndex = -1;

  for (let i = 0; i < filteredTipologias.length; i++) {
    const currTipologia = filteredTipologias[i];
    const currGastoComun = getTipologiaGastoComun(currTipologia);

    if (currGastoComun >= MIN_GASTO_COMUN.TIPOLOGIA && currGastoComun < gastoComun) {
      gastoComun = currGastoComun;
      cheapestTipoIndex = i;
    }
  }

  if (cheapestTipoIndex === -1) {
    cheapestTipoIndex = 0;
  }

  tipologia = filteredTipologias[cheapestTipoIndex];

  return (
    <GastoComunTipologia tipologia={tipologia} />
  );
}

export default GastoComunProyecto;
