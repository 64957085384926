import React from 'react';
import './styles/SliderPlace.scss';
import Carousel from 'react-elastic-carousel'
import { Button } from '../ui/index';
import {
  BACKEND_BASE_URL,
  TOUR_360_STR
} from '../../config/constant';
import notFound from '../../static/images/notFound.png';


// Slider de imágenes. Si `proyecto` es pasado, se arman las slides a partir de las imágenes de
// la galería del proyecto correspondiente; si se pasa `imgArray` (el cual debe ser un array de la
// forma [{ src, alt }, { src, alt }, ...], con URLs y alt texts de imágenes), se arman las slides
// a partir de eso.
const SliderPlace = ({ proyecto, imgArray, buttonText = <span>{TOUR_360_STR}<sup>o</sup></span>,
  buttonCallback, containerStyle, optionSliderStyle }) => {

  let carousel;
  let carouselSlides = [
    <div key={0} className="item-slider"><img src={notFound} alt="no-foto" /></div>,
    <div key={1} className="item-slider"><img src={notFound} alt="no-foto" /></div>,
    <div key={2} className="item-slider"><img src={notFound} alt="no-foto" /></div>,
  ];

  // Construir slides a partir de `proyecto`, si lo pasaron
  if (proyecto) {
    if (proyecto.galeria.length > 0) {
      carouselSlides = proyecto.galeria.map((galeriaItem) => {
        const imageUrl = galeriaItem.formats.medium ? `${BACKEND_BASE_URL}${galeriaItem.formats.medium.url}`
          : galeriaItem.formats.small ? `${BACKEND_BASE_URL}${galeriaItem.formats.small.url}`
            : galeriaItem.formats.thumbnail ? `${BACKEND_BASE_URL}${galeriaItem.formats.thumbnail.url}`
              : notFound;

        return (
          <div key={galeriaItem.id} className="item-slider">
            <img src={imageUrl} alt={galeriaItem.alternativeText} />
          </div>
        );
      });
    }
  }

  // Construir slides a partir de `imgArray`, si lo pasaron
  if (imgArray) {
    carouselSlides = imgArray.map((imgData) => {
      return (
        <div className="item-slider">
          <img src={imgData.src} alt={imgData.alt} />
        </div>
      );
    })
  }

  const onButtonClick = () => {
    if (buttonCallback) {
      buttonCallback();
    }
  }

  return (
    <div className="slider-place-container" style={containerStyle}>
      <Carousel
        ref={el => carousel = el}
        itemsToScroll={1}
        itemsToShow={3}
        enableAutoPlay={false}
        showArrows={false}
        autoPlaySpeed={5000}
        className="slider-place">
        {carouselSlides}
      </Carousel>

      <div className="row">
        <div className="option-slider" style={optionSliderStyle}>
          {buttonText && <Button onClick={onButtonClick} color='btn-orange' className="btn tour bold" size="small">{buttonText}</Button>}
          <Button color='btn-white-gray' size="small-icon" startIcon="icon-left-arrow" onClick={() => carousel.slidePrev()}></Button>
          <Button color='btn-white-gray' size="small-icon" startIcon="icon-button-arrow-ight" onClick={() => carousel.slideNext()}></Button>
        </div>
      </div>
    </div>
  );
}

export default SliderPlace;
