import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  selectCurrentProyectoId,
  selectLastCotizacionId,
  selectSendCotizacionData,
  selectTipologiaTitulo,
  setSendCotizacionErrorCount,
  setSendCotizacionLoadingCount,
  setSendCotizacionSuccessCount
} from '../redux/preevaluacionSlice';
import { selectProyectoById } from '../redux/proyectosSlice';
import { dataLayerPush } from '../utils';


// Hook para mostrar mensaje de éxito/error del thunk sendCotizacion.
// También envía data de analytics relacionada a la cotización.
// Devuelve la data relacionada al thunk sendCotizacion.
const useSendCotizacionMessage = () => {

  const dispatch = useDispatch();
  const sendCotizacionData = useSelector(selectSendCotizacionData);
  const tipologiaTitulo = useSelector(selectTipologiaTitulo);
  const lastCotizacionId = useSelector(selectLastCotizacionId);
  const preevaluacionCurrentProyectoId = useSelector(selectCurrentProyectoId);
  const proyecto = useSelector(selectProyectoById(preevaluacionCurrentProyectoId));


  useEffect(() => {
    if (!proyecto) {
      return;
    }

    switch (sendCotizacionData.status) {
      case 'loading':
        {
          if (sendCotizacionData.loadingCount > 0) {
            toast.success(sendCotizacionData.loadingMessage);
            dispatch(setSendCotizacionLoadingCount(sendCotizacionData.loadingCount - 1));
          }
          break;
        }

      case 'succeeded':
        {
          if (sendCotizacionData.successCount > 0) {
            toast.success(sendCotizacionData.successMessage);
            dispatch(setSendCotizacionSuccessCount(sendCotizacionData.successCount - 1));

            //dispatch(setSendCotizacionStatus(null));

            // Analytics
            const eventData = {
              event: 'cotizacion',
              proyecto: proyecto.proyecto_nombre,
              comuna: proyecto.comuna,
              tipologia: tipologiaTitulo,
              idLead: lastCotizacionId,
              status: 'cotizacionExitosa',
            };

            dataLayerPush(eventData);
            console.log(`Acabo de llamar dataLayerPush con el siguiente objeto: ${JSON.stringify(eventData)}`);
          }
          break;
        }

      case 'failed':
        {
          if (sendCotizacionData.errorCount > 0) {
            toast.error(sendCotizacionData.errorMessage);
            dispatch(setSendCotizacionErrorCount(sendCotizacionData.errorCount - 1));

            //dispatch(setSendCotizacionStatus(null));

            // Analytics
            const eventData = {
              event: 'cotizacion',
              proyecto: proyecto.proyecto_nombre,
              comuna: proyecto.comuna,
              tipologia: tipologiaTitulo,
              idLead: '',
              status: 'cotizacionFallida',
            };

            dataLayerPush(eventData);
            console.log(`Acabo de llamar dataLayerPush con el siguiente objeto: ${JSON.stringify(eventData)}`);
          }
          break;
        }

      case 'idle':
        break;

      default:
        console.log(`Estado de sendCotizacionStatus inválido: '${sendCotizacionData.status}'`);
        break;
    }
  }, [dispatch, lastCotizacionId, proyecto, sendCotizacionData.errorCount, sendCotizacionData.errorMessage,
    sendCotizacionData.loadingCount, sendCotizacionData.loadingMessage, sendCotizacionData.status, sendCotizacionData.successCount,
    sendCotizacionData.successMessage, tipologiaTitulo]);

  return sendCotizacionData;
}

export default useSendCotizacionMessage;
