import React from 'react';
import LazyLoad from 'react-lazyload';
import { TextInfo, Button, Title } from '../ui/index';
import './styles/PlaceSimilaresMobile.scss';
import { useSelector } from 'react-redux';
import { selectProyectos, selectProyectosSimilares } from '../../redux/proyectosSlice';
import {
  dataLayerPush,
  getDormitoriosAndBanosString,
  getPlaceUrl,
  getPortadaData,
  scrollToTop
} from '../../utils';
import { Link } from 'react-router-dom';


const PlaceSimilaresMobile = ({ proyecto, showAll = false }) => {

  const proyectos = useSelector(selectProyectos);
  const proyectosSimilares = useSelector(selectProyectosSimilares(proyecto ? proyecto.id : null));
  const proyectosList = showAll ? proyectos : proyectosSimilares;
  let sliderItems = null;

  const onArrendarClick = (proyectoNombre, position) => {
    scrollToTop();

    dataLayerPush({
      event: 'click',
      bannerName: proyectoNombre,
      section: 'place (mobile) - edificios similares',
      position,
    });
  }

  if (proyectosList.length > 0) {
    sliderItems = proyectosList.map((proyecto, index) => {
      const portadaData = getPortadaData(proyecto);
      const amenities = getDormitoriosAndBanosString(proyecto, false);

      return (
        <div key={proyecto.id} className="item-slider">
          <div className="image">
            <LazyLoad throttle={50} height={100}>
              <img src={portadaData.url} alt={portadaData.alt} style={{ maxHeight: '202px' }} />
            </LazyLoad>
            {!proyecto.terceros && <span className="alert-place infoPla">Edificio Multifamily</span>}

          </div>
          <div className="info">
            <TextInfo textWeight="bold" color="black" size="18">{proyecto.proyecto_nombre}</TextInfo>
            <TextInfo iconColor="#FF6D00" iconName="icon-location-pin" size="14">{proyecto.comuna}</TextInfo>
            <div className="option-edificio">
              <TextInfo iconColor="#848390" iconName="icon-bed" size="14">{amenities.dormitoriosStr}</TextInfo>
              <TextInfo iconColor="#848390" iconName="icon-bath" size="14">{amenities.banosStr}</TextInfo>
            </div>
            <Link to={getPlaceUrl(proyecto)} onClick={() => onArrendarClick(proyecto.proyecto_nombre, index + 1)}>
              <Button color="btn-blue" fullWidth={true}>ARRENDAR AQUI</Button>
            </Link>
          </div>
        </div>
      );
    });
  }

  return (
    <div className="__place-similares" style={{ marginBottom: '50px' }}>
      <div className="container">
        <div className="row" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
          <div className="slider-edificio">
            <Title className="muli" size="20" marginBotton={30}>Edificios similares</Title>
            {sliderItems}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlaceSimilaresMobile;
