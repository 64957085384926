import React, { useState } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  TextInfo,
  Icon,
  Input,
  Select,
} from '../../../ui/index';
import {
  isValidRentaMensual,
  rentasAreEnough,
} from '../../../../validation';
import { selectPrecioMensual, selectDropdownData } from '../../../../redux/preevaluacionSlice';
import { useSelector } from 'react-redux';
import {
  commify,
  convertStrArrayToNumArray,
  copyArray,
  decommify,
} from '../../../../utils';
import './styles/main.scss';
import {
  BREAKPOINT_MOBILE,
  MAX_AVALES,
  MAX_COMPLEMENTOS,
  RENTA_MENSUAL_STR,
  TIPO_PERSONA,
  VALIDATION_ENABLED,
} from '../../../../config/constant';
import useResize from '../../../../hooks/useResize';

const DEFAULT_TIPO_PERSONA = TIPO_PERSONA[0];
const DEFAULT_CANTIDAD_AVALES = 1;
const DEFAULT_CANTIDAD_COMPLEMENTOS = 0;

const InnerForm = ({
  tipoPersona,
  onTipoPersonaChange,
  rentaTitular,
  onRentaTitularChange,
  rentasAvales,
  onRentasAvalesChange,
  addRentaAval,
  removeRentaAval,
  rentasComplementos,
  onRentasComplementosChange,
  addRentaComplemento,
  removeRentaComplemento,
  showModalAval,
  showModalComplemento,
  showModalTitular,
  showModalPep,
  onEnviarClick,
  onClientePepChange,
  isDisabled,
  nacionalidad,
  onNacionalidadChange,
  genero,
  onGeneroChange,
  estadocivil,
  onEstadocivilChange,
  profesion,
  onProfesionChange
}) => {

  const [cantidadAvales, setCantidadAvales] = useState(DEFAULT_CANTIDAD_AVALES);
  const [cantidadComplementos, setCantidadComplementos] = useState(DEFAULT_CANTIDAD_COMPLEMENTOS);

  // Responsive styles/classnames
  const [rizeWidth] = useResize();
  const layoutGroupClass = rizeWidth <= BREAKPOINT_MOBILE ? {} : 'layout-group';

  const avalesInputs = [];

  for (let i = 0; i < cantidadAvales; i++) {
    avalesInputs.push(
      <div key={i} className="layout-group full">
        <Input value={rentasAvales[i]} onChange={(e) => onRentasAvalesChange(e, i)} label={`${RENTA_MENSUAL_STR} aval ${i + 1}*`} info={true} infoOnClick={showModalAval} infoColor={true} type="text" classes="extra-small" fullWidth={true} placeholder="$-" />
      </div>
    );
  }

  const complementosInputs = [];

  for (let i = 0; i < cantidadComplementos; i++) {
    complementosInputs.push(
      <div key={i} className="layout-group full">
        <Input value={rentasComplementos[i]} onChange={(e) => onRentasComplementosChange(e, i)} label={`${RENTA_MENSUAL_STR} complemento ${i + 1}*`} info={true} infoOnClick={showModalComplemento} infoColor={true} type="text" classes="extra-small" fullWidth={true} placeholder="$-" />
      </div>
    );
  }

  const onAddAvalClick = () => {
    setCantidadAvales(cantidadAvales + 1);
    addRentaAval();
  }

  const onRemoveAvalClick = () => {
    if (cantidadAvales > 1) {
      setCantidadAvales(cantidadAvales - 1);
      removeRentaAval();
    }
  }

  const onAddComplementoClick = () => {
    setCantidadComplementos(cantidadComplementos + 1);
    addRentaComplemento();
  }

  const onRemoveComplementoClick = () => {
    if (cantidadComplementos > 1) {
      setCantidadComplementos(cantidadComplementos - 1);
      removeRentaComplemento();
    }
  }

  // Los valores se obtienen vía api de Level
  const dropdownData = useSelector(selectDropdownData)
  const { TIPOTRABAJADOR, GENERO, ESTADOCIVIL, PROFESION, PAISES } = dropdownData ? dropdownData : {
    TIPOTRABAJADOR: [], GENERO: [], ESTADOCIVIL: [], PROFESION: [], PAISES: []
  };

  const generoData = GENERO.map(({ maestroDatoId, maestroDatoNombre }) => {
    return { id: maestroDatoId, name: maestroDatoNombre }
  })
  const estadoCivilData = ESTADOCIVIL.map(({ maestroDatoId, maestroDatoNombre }) => {
    return { id: maestroDatoId, name: maestroDatoNombre }
  })
  // Se pone Dependiente primero y se elimina Sin trabajo
  const tipoTrabajadorData = TIPOTRABAJADOR.map(({ maestroDatoId, maestroDatoNombre }) => {
    return { id: maestroDatoId, name: maestroDatoNombre }
  }).sort((a, b) => {
    return a.name < b.name ? -1 : 0
  }).splice(0, 2);
  const profesionData = PROFESION.map(({ maestroDatoId, maestroDatoNombre }) => {
    return { id: maestroDatoId, name: maestroDatoNombre }
  })

  const paisesData = PAISES.map(({ PaisId, Nacionalidad }) => {
    return { id: PaisId, name: Nacionalidad }
  })

  const showAgregarAval = MAX_AVALES === -1 || (cantidadAvales < MAX_AVALES);
  const showRemoverAval = cantidadAvales > 1;
  const showAgregarComplemento = MAX_COMPLEMENTOS === -1 || (cantidadComplementos < MAX_COMPLEMENTOS);
  const showRemoverComplemento = cantidadComplementos > 1;
  const agregarAvalBr = showRemoverAval ? <br /> : <><br /><br /></>;
  const removerAvalBr = <><br /><br /></>;

  return (
    <div>
      <div className="form-layout">
        <div className={layoutGroupClass}>
          <div className="form-group full-width">
            <label>Tipo de trabajador* </label>
            <Select
              value={tipoPersona}
              data={tipoTrabajadorData}
              onChange={onTipoPersonaChange} placeholder="Seleccionar" className="bold" typeStyle="extra-small"
              fullWidth={true} noMargin={true}
            />
          </div>
          <div className="form-group full-width">
            <label>Nacionalidad* </label>
            <Select
              value={nacionalidad}
              data={paisesData}
              onChange={onNacionalidadChange} placeholder="Seleccionar" className="bold" typeStyle="extra-small"
              fullWidth={true} noMargin={true}
            />
          </div>
        </div>
        <div className={layoutGroupClass}>
          <div className="form-group full-width">
            <label>Género*</label>
            <Select
              value={genero}
              data={generoData}
              onChange={onGeneroChange} placeholder="Seleccionar" className="bold" typeStyle="extra-small"
              fullWidth={true} noMargin={true} />
          </div>
          <div className="form-group full-width">
            <label>Estado civil*</label>
            <Select
              value={estadocivil}
              data={estadoCivilData}
              onChange={onEstadocivilChange} placeholder="Seleccionar" className="bold" typeStyle="extra-small"
              fullWidth={true} noMargin={true}
            />
          </div>
        </div>
        <div className={layoutGroupClass}>
          <div className="form-group full-width">
            <label>Profesión*</label>
            <Select
              value={profesion}
              data={profesionData}
              onChange={onProfesionChange} placeholder="Seleccionar" className="bold" typeStyle="extra-small"
              fullWidth={true} noMargin={true}
            />
          </div>
        </div>
        <div className="layout-group full">
          <Input
            value={rentaTitular}
            onChange={onRentaTitularChange}
            label={`${RENTA_MENSUAL_STR} titular*`}
            info={true}
            infoOnClick={showModalTitular}
            infoColor={true}
            type="text"
            classes="extra-small"
            fullWidth={true}
            placeholder="$-"
          />
        </div>
        {avalesInputs}
        {/* Es PEP ? */}
        <div className="layout-group full" style={{ marginBottom: '0px' }}>
          <div className='form-group full-width' >
            <label style={{ 'opacity': isDisabled ? '0.6' : '1' }}>
              ¿Eres una persona expuesta políticamente?
              <Icon onClick={showModalPep} size={16} color='#FF6D00' name="icon-question" />
            </label>
          </div>
        </div>
        <div className="flex">
          <label className="space-right checkbox-container false" style={{ cursor: 'default' }}>
            <span className="label label-left">
              <span className="text-info text-regular text-black">Sí</span>
            </span>
            <input type="radio" ispep="true" onChange={onClientePepChange} name="checkbox-pep" /><span className="checkmark  left" style={{ backgroundColor: '#E8E8EC' }}></span>
          </label>
          <label className="space-right checkbox-container false" style={{ cursor: 'default' }}>
            <span className="label label-left">
              <span className="text-info text-regular text-black">No</span>
            </span>
            <input type="radio" defaultChecked ispep="false" onChange={onClientePepChange} name="checkbox-pep" /><span className="checkmark left" style={{ backgroundColor: '#E8E8EC' }}></span>
          </label>
        </div>
        {showAgregarAval && <><Button onClick={onAddAvalClick} className="btn btn-gray-oscuro bold mb-1" size="extra-small" fullWidth={true}>+ agregar aval</Button>{agregarAvalBr}</>}
        {showRemoverAval && <><Button onClick={onRemoveAvalClick} className="btn btn-gray-oscuro bold mb-1" size="extra-small" fullWidth={true}>- remover aval</Button>{removerAvalBr}</>}
        {complementosInputs}
        {showAgregarComplemento && <Button onClick={onAddComplementoClick} className="btn btn-gray-oscuro bold mb-1" size="extra-small" fullWidth={true}>+ agregar complemento</Button>}
        {showRemoverComplemento && <Button onClick={onRemoveComplementoClick} className="btn btn-gray-oscuro bold mb-1" size="extra-small" fullWidth={true}>- remover complemento</Button>}

        <Button onClick={onEnviarClick} style={{ marginTop: '20px', marginBottom: '30px' }} color='btn-outline-orange' size="extra-small" fullWidth={true}>SIMULAR EVALUACIÓN</Button>
      </div>
    </div>
  );
}


// Función auxiliar. Devuelve la nueva renta que hay que settear.
// 'inputValue' debe ser el event.target.value del input de renta correspondiente,
// 'currentRenta' debe ser el valor actual de la renta correspondiente.
function getRentaFromInputValue(inputValue, currentRenta) {
  if (inputValue === '') {
    return '';
  }

  const newRenta = commify(inputValue);

  // Si newRenta es una string vacía, es porque el usuario ingresó alguna letra o símbolo inválido
  // que hizo que la string no sea una string numérica válida, así que la ignoramos y devolvemos
  // el valor actual de la renta.
  if (newRenta === '') {
    return currentRenta;
  }

  // La comificación fue exitosa y la devolvemos
  return newRenta;
}


const FormPersona = ({
  isCotizacion,
  proyecto,
  title,
  checked,
  processData,
  initialData,
  showModalAval,
  showModalComplemento,
  showModalTitular,
  showModalPep,
  handleEvaluation,
  evalucacionLine = null
}) => {

  const cantidadForms = 1;
  const [tiposPersona, setTiposPersona] = useState([initialData.tipoPersona]);
  const [nacionalidad, setNacionalidad] = useState([initialData.paisId]);
  const [genero, setGenero] = useState([initialData.generoId]);
  const [estadocivil, setEstadocivil] = useState([initialData.estadoCivilId]);
  const [profesion, setProfesion] = useState([initialData.profesionId]);
  const [isPep, setIsPep] = useState(false);

  const [rentaTitular, setRentaTitular] = useState(initialData && initialData.rentaMensual ? commify(initialData.rentaMensual) : '');
  const [rentasAvales, setRentasAvales] = useState(initialData && initialData.rentaMensual ? Array(DEFAULT_CANTIDAD_AVALES).fill(commify(initialData.rentaMensual)) : Array(DEFAULT_CANTIDAD_AVALES).fill(''));
  const [rentasComplementos, setRentasComplementos] = useState(initialData && initialData.rentaMensual ? Array(DEFAULT_CANTIDAD_COMPLEMENTOS).fill(commify(initialData.rentaMensual)) : Array(DEFAULT_CANTIDAD_COMPLEMENTOS).fill(''));
  let precioMensual = useSelector(selectPrecioMensual(proyecto));

  const [isDisabled, setIsDisabled] = useState(true)


  const onClientePepChange = (e) => {
    const pepValue = e.target.getAttribute('isPep')
    setIsPep(pepValue === 'true')
  }

  const addRentaAval = () => {
    const newRentasAvales = copyArray(rentasAvales);
    newRentasAvales.push('');
    setRentasAvales(newRentasAvales);
  }

  const removeRentaAval = () => {
    const newRentasAvales = rentasAvales.slice(0, rentasAvales.length - 1);
    setRentasAvales(newRentasAvales);
  }

  const addRentaComplemento = () => {
    const newRentasComplementos = copyArray(rentasComplementos);
    newRentasComplementos.push('');
    setRentasComplementos(newRentasComplementos);
  }

  const removeRentaComplemento = () => {
    const newRentasComplementos = rentasComplementos.slice(0, rentasComplementos.length - 1);
    setRentasComplementos(newRentasComplementos);
  }

  const onRentaTitularChange = (event) => {
    setIsDisabled(true)
    const newValue = event.target.value;

    const newRentaTitular = getRentaFromInputValue(newValue, rentaTitular);

    if (newRentaTitular !== rentaTitular) {
      setRentaTitular(newRentaTitular);
    }
  }

  const onRentasAvalesChange = (event, index) => {
    setIsDisabled(true)
    const newValue = event.target.value;

    const newRenta = getRentaFromInputValue(newValue, rentasAvales[index]);

    if (newRenta !== rentasAvales[index]) {
      const newRentasAvales = copyArray(rentasAvales);
      newRentasAvales[index] = newRenta;
      setRentasAvales(newRentasAvales);
    }
  }

  const onRentasComplementosChange = (event, index) => {
    setIsDisabled(true)
    const newValue = event.target.value;

    const newRenta = getRentaFromInputValue(newValue, rentasComplementos[index]);

    if (newRenta !== rentasComplementos[index]) {
      const newRentasComplementos = copyArray(rentasComplementos);
      newRentasComplementos[index] = newRenta;
      setRentasComplementos(newRentasComplementos);
    }
  }

  const onTipoPersonaChange = (event, index) => {
    setIsDisabled(true)
    const newTiposPersona = copyArray(tiposPersona);
    newTiposPersona[index] = event.target.value;
    setTiposPersona(newTiposPersona);
  }
  const onGeneroChange = (event, index) => {
    setIsDisabled(true)
    const newGenero = copyArray(genero);
    newGenero[index] = event.target.value;
    setGenero(newGenero);
  }
  const onEstadoCivilChange = (event, index) => {
    setIsDisabled(true)
    const newEstado = copyArray(estadocivil);
    newEstado[index] = event.target.value;
    setEstadocivil(newEstado);
  }
  const onProfesionChange = (event, index) => {
    setIsDisabled(true)
    const newProfesion = copyArray(profesion);
    newProfesion[index] = event.target.value;
    setProfesion(newProfesion);
  }

  const onNacionalidadChange = (event, index) => {
    setIsDisabled(true)
    const newNacionalidad = copyArray(nacionalidad);
    newNacionalidad[index] = event.target.value;
    setNacionalidad(newNacionalidad);
  }

  // Chequea todos los campos. Devuelve true si todos son válidos, false otherwise.
  // También muestra toasts de error.
  const allFieldsAreValid = (index) => {
    if (VALIDATION_ENABLED && (!tiposPersona[index] || tiposPersona[index] === "Seleccionar")) {
      toast.error(`Debes seleccionar un tipo de persona!`);
      return false;
    }
    if (VALIDATION_ENABLED && (!nacionalidad[index] || nacionalidad[index] === "Seleccionar")) {
      toast.error(`Debes seleccionar una nacionalidad!`);
      return false;
    }
    if (VALIDATION_ENABLED && (!genero[index] || genero[index] === "Seleccionar")) {
      toast.error(`Debes seleccionar un genero!`);
      return false;
    }
    if (VALIDATION_ENABLED && (!estadocivil[index] || estadocivil[index] === "Seleccionar")) {
      toast.error(`Debes seleccionar un estadocivil!`);
      return false;
    }
    if (VALIDATION_ENABLED && (!profesion[index] || profesion[index] === "Seleccionar")) {
      toast.error(`Debes seleccionar una profesion!`);
      return false;
    }

    if (!isValidRentaMensual(rentaTitular)) {
      toast.error(`${RENTA_MENSUAL_STR} del titular inválida!`);
      return false;
    }

    // Las rentas de los avales son opcionales. Las validamos solamente si se ingresó
    // algo.
    for (let i = 0; i < rentasAvales.length; i++) {
      if (rentasAvales[i] !== '' && !isValidRentaMensual(rentasAvales[i])) {
        toast.error(`${RENTA_MENSUAL_STR} del aval ${i + 1} inválida!`);
        return false;
      }
    }

    // Idem para las rentas de los complementos.
    for (let i = 0; i < rentasComplementos.length; i++) {
      if (rentasComplementos[i] !== '' && !isValidRentaMensual(rentasComplementos[i])) {
        toast.error(`${RENTA_MENSUAL_STR} del complemento ${i + 1} inválida!`);
        return false;
      }
    }

    // Chequear que las rentas alcancen para seguir el flujo.
    // Convertir rentas (strings comificadas de números) a números.
    const rentaTitularNum = Number(decommify(rentaTitular));
    const rentasAvalesNum = convertStrArrayToNumArray(rentasAvales);
    const rentasComplementosNum = convertStrArrayToNumArray(rentasComplementos);

    if(isCotizacion){
      if(proyecto.resumenContrato == null){
        let sumaPrecio = 0
        for (let index = 0; index < proyecto.productos.length; index++) {
          const item = proyecto.productos[index];
          sumaPrecio = sumaPrecio + item.productoprecio;          
        }
        //const totalSumaArriendo = proyecto.productos.filter((item) => item.productoprecio).reduce((a, b) => a + b);
        precioMensual = sumaPrecio;
      } else {
        precioMensual = proyecto.resumenContrato.montoArriendo
      }
 
    } 

    if (!rentasAreEnough(precioMensual, rentaTitularNum, rentasAvalesNum, rentasComplementosNum)) {
      toast.error(`Tu renta no califica. Agrega un aval o complemento para volver a evaluar.`);
      return false;
    }
    return true;
  }

  const onEnviarClick = () => {
    let allFormsFieldsValid = true;

    for (let i = 0; i < cantidadForms; i++) {
      if (!allFieldsAreValid(i)) {
        allFormsFieldsValid = false;
        break;
      }
    }
    if (!allFormsFieldsValid) {
      return;
    }

    // Todos los datos de todos los forms son válidos, procesarlos
    for (let i = 0; i < cantidadForms; i++) {
      const personaData = {
        tipoPersona: tiposPersona[i],
        nacionalidad: nacionalidad[i],
        genero: genero[i],
        estadocivil: estadocivil[i],
        profesion: profesion[i],
        isPep
      };
      processData(personaData);
      setIsDisabled(false)
      toast.success(`¡Felicidades! Tu renta se ajusta a nuestros requisitos. Puedes continuar con el proceso de evaluación.`);
    }
  }

  // Armar forms internos
  let innerForms = [];

  innerForms.push(
    <div>
      <InnerForm
        tipoPersona={tiposPersona[0]}
        onTipoPersonaChange={(event) => onTipoPersonaChange(event, 0)}
        rentaTitular={rentaTitular}
        onRentaTitularChange={onRentaTitularChange}
        rentasAvales={rentasAvales}
        onRentasAvalesChange={onRentasAvalesChange}
        addRentaAval={addRentaAval}
        removeRentaAval={removeRentaAval}
        rentasComplementos={rentasComplementos}
        onRentasComplementosChange={onRentasComplementosChange}
        addRentaComplemento={addRentaComplemento}
        removeRentaComplemento={removeRentaComplemento}
        showModalAval={showModalAval}
        showModalComplemento={showModalComplemento}
        showModalTitular={showModalTitular}
        showModalPep={showModalPep}
        onEnviarClick={onEnviarClick}
        onClientePepChange={onClientePepChange}
        isDisabled={isDisabled}
        // TODO: add change method
        nacionalidad={nacionalidad}
        onNacionalidadChange={(event) => onNacionalidadChange(event, 0)}
        genero={genero}
        onGeneroChange={(event) => onGeneroChange(event, 0)}
        estadocivil={estadocivil}
        onEstadocivilChange={(event) => onEstadoCivilChange(event, 0)}
        profesion={profesion}
        onProfesionChange={(event) => onProfesionChange(event, 0)}
      />

    </div>
  );

  // Sobreescribir algunos estilos
  const [rizeWidth] = useResize();

  const styleBody = {
    maxHeight: '100%',
    padding: evalucacionLine != null ? '0px' : '30px' 
  }

  const stepBodyStyle = rizeWidth > BREAKPOINT_MOBILE ? styleBody : {};

  const itemStepFormStyle = { height: '100%' };

console.log(evalucacionLine)
  return (
    <>
      <div className={`_itemStep-form  cotizaciones${checked ? 'checked' : ''} active`} style={itemStepFormStyle}>
        <div className="_step-header">
          <TextInfo textWeight="bold" size={18} color="black">{title} <Icon size={16} name="icon-tick" /></TextInfo>
          <Icon onClick={showModalTitular} name="icon-question" />
        </div>
        <div className="_step-body" style={stepBodyStyle}>
          {
            evalucacionLine != null ? (
              <iframe
                  title="level-ingreso-mantencion-form"
                  src={evalucacionLine}
                  marginwidth="0" marginheight="0" frameborder="0"
                  style={{ height: '100%', width: '100%' }}></iframe>
            ):(<>
              {innerForms}
              <Button onClick={() => handleEvaluation(isPep)} disabled={isDisabled} style={{ marginBottom: '30px' }} color='btn-orange' size="extra-small" fullWidth={true}>
                IR A EVALUACIÓN
              </Button>
              </>
            )
          }
          
        </div>
      </div>
    </>
  );
}

export default FormPersona;
