import React from 'react';
import { Modal } from '../../../ui/index';

const ModalPep = ({ close }) => {
    return (
        <Modal show={true} close={close}>
            <div className="modal-content help md fade" style={{ height: '300px', width: '300px' }}>
                
            </div>
        </Modal>
    );
};

export default ModalPep;
