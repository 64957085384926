import React from 'react';
import styles from './styles/SocialButtons.module.scss';
import YoutubeIcon from './YoutubeIcon';
import InstagramIcon from './InstagramIcon';
import FacebookIcon from '../FacebookIcon';


// Botonera horizontal de Instagram, Twitter, y Facebook.
// FIXME: Refactorear esto porque los nombres de target actuales (desk, mobile, mobile-light) son misleading.
// Hay al menos dos icon sets diferentes: uno en el que el icono propiamente dicho no está rodeado de un
// círculito, y otro en el que sí. Y dentro de de estos últimos, si no me equivoco hay dos variantes: una oscura
// y una clara. Por lo que la tipología target=[desk|mobile] es muy inadecuada, algo más apropiado
// debería ser target=[flat|round-light|round-dark]
const SocialButtons = ({
  text = null,
  textColor = null,
  containerStyle = null,
  youtubeIconTop = "0",
  target = "desk"
}) => {

  return (
    <div className={styles.socialIconsContainer} style={containerStyle}>
      {text && <span style={{ color: textColor ? textColor : 'white', marginRight: '15px' }}>{text}</span>}
      <InstagramIcon target={target} />
      <FacebookIcon target={target} />
      <YoutubeIcon target={target} iconSize="31px" top={youtubeIconTop} />
    </div>
  );
}

export default SocialButtons;
