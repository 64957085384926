import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  selectFiltroEntregaInmediata,
  selectFiltroEstrenar,
  selectFiltroMascota,
  selectFiltroOfertas,
  setFiltroEntregaInmediata,
  setFiltroEstrenar,
  setFiltroMascota,
  setFiltroOfertas
} from '../redux/proyectosSlice';
import { scrollToTop } from '../utils';
import { Checkbox, TextInfo } from './ui/index';


// Componente que muestra checkboxes para "Acepta mascota", "Entrega inmediata",
// "Sólo ofertas" y "A estrenar" para el filtro usado en la página /result.
// Modifica el estado de Redux correspondiente.
const CheckboxOtros = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const filtroMascota = useSelector(selectFiltroMascota);
  const filtroEntregaInmediata = useSelector(selectFiltroEntregaInmediata);
  const filtroOfertas = useSelector(selectFiltroOfertas);
  const filtroEstrenar = useSelector(selectFiltroEstrenar);

  const onMascotaCheckboxChange = (event) => {
    const newFiltroMascota = event.target.checked;
    dispatch(setFiltroMascota(newFiltroMascota));
    const paramsSearch = new URLSearchParams(location.search);

    if(newFiltroMascota){
      paramsSearch.set('mascota', newFiltroMascota);
    } else {
      paramsSearch.delete('mascota')
    }
    scrollToTop();
    window.history.pushState({}, '/#/result', '/#/result?' + paramsSearch.toString());
  }

  const onEntregaInmediataCheckboxChange = (event) => {
    const newFiltroEntregaInmediata = event.target.checked;
    dispatch(setFiltroEntregaInmediata(newFiltroEntregaInmediata));
    const paramsSearch = new URLSearchParams(location.search);

    if(newFiltroEntregaInmediata){
      paramsSearch.set('entregaImediata', newFiltroEntregaInmediata);
    } else {
      paramsSearch.delete('entregaImediata')
    }
    scrollToTop();
    window.history.pushState({}, '/#/result', '/#/result?' + paramsSearch.toString());
  }

  const onOfertasCheckboxChange = (event) => {
    const newFiltroOfertas = event.target.checked;
    dispatch(setFiltroOfertas(newFiltroOfertas));
    const paramsSearch = new URLSearchParams(location.search);

    if(newFiltroOfertas){
      paramsSearch.set('oferta', newFiltroOfertas);
    } else {
      paramsSearch.delete('oferta')
    }
    scrollToTop();
    window.history.pushState({}, '/#/result', '/#/result?' + paramsSearch.toString());
  }

  const onEstrenarCheckboxChange = (event) => {
    const newFiltroEstrenar = event.target.checked;
    dispatch(setFiltroEstrenar(newFiltroEstrenar));
    const paramsSearch = new URLSearchParams(location.search);

    if(newFiltroEstrenar){
      paramsSearch.set('estrenar', newFiltroEstrenar);
    } else {
      paramsSearch.delete('estrenar')
    }
    scrollToTop();
    window.history.pushState({}, '/#/result', '/#/result?' + paramsSearch.toString());
  }


  return (
    <>
      <Checkbox checked={filtroMascota} onChange={onMascotaCheckboxChange} >
        <TextInfo color="black">Acepta mascota</TextInfo>
      </Checkbox>
      <Checkbox checked={filtroEntregaInmediata} onChange={onEntregaInmediataCheckboxChange} >
        <TextInfo color="black">Entrega inmediata</TextInfo>
      </Checkbox>
      <Checkbox checked={filtroOfertas} onChange={onOfertasCheckboxChange} >
        <TextInfo color="black">Sólo ofertas</TextInfo>
      </Checkbox>
      <Checkbox checked={filtroEstrenar} onChange={onEstrenarCheckboxChange} marginZero={true}>
        <TextInfo color="black">A estrenar</TextInfo>
      </Checkbox>
    </>
  );
}

export default CheckboxOtros;
