import React, { useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import useResize from '../hooks/useResize';
import { BREAKPOINT_MOBILE } from '../config/constant';
import { ToastContainer } from 'react-toastify';
import useProyectos from '../hooks/useProyectos';
import useComentarios from '../hooks/useComentarios';
import 'react-toastify/dist/ReactToastify.css';
import useSendCotizacionMessage from '../hooks/useSendCotizacionMessage';
import useBarrios from '../hooks/useBarrios';


const Layout = ({ children, pageCurrent, mobileMenuBar = true }) => {

  const [rizeWidth] = useResize();
  const [showLogin, setShowLogin] = useState(false);

  // Obtener proyectos y comentarios. Lo hacemos acá porque Layout es compartido por todas las páginas y
  // así le evitamos el trabajo de chequear y fetchear a los componentes.
  useProyectos();
  useComentarios();
  useBarrios();

  // Mostrar mensajes de cotización
  useSendCotizacionMessage();

  const openLogin = () => {
    setShowLogin(true);
  }

  const closeLogin = () => {
    setShowLogin(false);
  }


  return (
    <>
      <Header
        pageCurrent={pageCurrent}
        showLogin={showLogin}
        openLogin={openLogin}
        closeLogin={closeLogin}
        mobileMenuBar={mobileMenuBar}
      />
      {children}
      {rizeWidth > BREAKPOINT_MOBILE && <Footer />}
      <ToastContainer />
    </>
  );
}

export default Layout;
