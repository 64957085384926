import React, { useEffect, useState } from 'react';
import { Icon, Button, TextInfo, Modal } from '../ui/index';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FACEBOOK_APP_ID } from '../../config/constant';


const ModalShared = ({ proyecto, show, close }) => {

  const [shareText, setShareText] = useState('');

  // Actualizar mensaje a compartir cuando carga el modal
  useEffect(() => {
    setShareText(`Mira este edificio: ${window.location.href}`);
  }, []);

  // Cargar API de Facebook para poder usar diálogo de compartir
  useEffect(() => {
    const script = document.createElement('script');

    script.src = `https://connect.facebook.net/en_US/sdk.js#version=v9.0&appId=${FACEBOOK_APP_ID}&xfbml=true&autoLogAppEvents=true`;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  const onCopiaLinkClick = () => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      toast.success(`Link copiado!`);
    }, () => {
      toast.error(`No se pudo copiar el link!`);
    });
  }

  const onEmailClick = () => {
    const subject = `Level - ${proyecto.proyecto_nombre}`;
    const body = encodeURIComponent(shareText);

    window.location.href = `mailto:user@example.com?subject=${subject}&body=${body}`;
  }

  const onWhatsappClick = () => {
    const message = encodeURIComponent(shareText);
    window.open(`https://api.whatsapp.com/send?text=${message}`, '_blank');
  }

  const onFacebookClick = () => {
    window.FB.ui({
      display: 'popup',
      method: 'share',
      href: window.location.href,
    });
  }

  const onTwitterClick = () => {
    const message = encodeURIComponent(shareText);
    window.open(`https://twitter.com/intent/tweet?text=${message}`, '_blank');
  }


  return (
    <Modal show={show} close={close}>
      <div className="modal-content modal-login fade">
        <div className="header-modal">
          <Icon onClick={close} name="icon-close" size="14" />
        </div>
        <div className="body-modal column">
          <div className="form-content">
            <h3>Comparte con tus amigos</h3>
            <Button onClick={onWhatsappClick} color='btn-social-whatssap ' size="medium" className="btn mb-2 bold">COMPARTE EN WHATSAPP</Button>
            {/*  <Button onClick={onFacebookClick} color='btn-social-facebook-shared' size="medium" className="btn mb-2 bold">COMPARTE EN  FACEBOOK</Button> */}
            {/*  <Button onClick={onTwitterClick} color='btn-social-twitter' size="medium" className="btn mb-2 bold">TWITTEA A TUS SEGUIDORES</Button> */}
            <Button onClick={onEmailClick} color='btn-outline-gray' size="medium" className="btn mb-2 bold">ENVÍA UN EMAIL A TUS AMIGOS</Button>
            <Button onClick={onCopiaLinkClick} color='btn-outline-gray' size="medium" className="btn mb-4 bold">COPIA EL LINK</Button>
          </div>
          <div className="footer" onClick={close} style={{ cursor: 'pointer' }}>
            <TextInfo className="footer-login" size="12">COMPARTIR EN OTRO MOMENTO</TextInfo>
          </div>
        </div>
      </div>
      <input id="modal-shared-hidden-input" style={{ visibility: 'hidden', position: 'fixed' }}></input>
    </Modal>
  );
};

export default ModalShared;
