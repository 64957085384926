import React from 'react'
import PropTypes from 'prop-types'
import './Radiobox.scss'

const Radiobox = props => {
    const { 
        classes = '',
        position = 'left',
        marginZero = false,
        name,
        value,
        children,
        ...other
    } = props;

    const margin = marginZero && 'margin-zero';

    return(
        <label className={`radiobox-container ${margin} ${classes}`}>
            <span className={`label label-${position}`}>{children}</span>
            <input 
                type="radio"
                name={name}
                value={value}
                {...other}
                />
            <span className={`checkmark ${position}`}></span>
        </label>
    );

}

Radiobox.propTypes = {
    name: PropTypes.string,
    classes: PropTypes.string,
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    between: PropTypes.bool,
    type: PropTypes.string,
    value: PropTypes.any,
    typeStyle: PropTypes.oneOf(['defauld', 'filtro', 'filtro-gray']),

}

export default Radiobox;
