import React from 'react';
import InputFiltro from './ui/InputFiltro/InputFiltro';


// Componente que muestra un input para ingresar bodegas (la diferencia con el componente
// `InputBodegaCantidad` es que el estado de Redux que se modifica acá es el que se usa
// en la página /result, y no el de /bodegas). El componente es controlado: se le pasa
// el valor actual con el prop `bodega`, y se ejecutan los callbacks `onIncrease` y
// `onDecrease` cuando la cantidad de estacionamientos aumenta o disminuye, respectivamente.
const InputBodega = ({ bodega, onIncrease, onDecrease }) => {

  return (
    <InputFiltro
      type="bodega"
      label="Incluir bodega"
      controlledValue={bodega}
      onIncrease={onIncrease}
      onDecrease={onDecrease}
      name="bodega"
      flex={true}
      between={true}
      marginBottom="20"
      margin={false}
    />
  );
}

export default InputBodega;
