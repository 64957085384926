import React, { useState } from 'react';
import { Button, Icon, TextInfo } from '../ui/index';
import styles from './CardCotizacion.module.scss';
import CotizacionesAlerta from './CotizacionesAlerta';
import useResize from '../../hooks/useResize';
import { BREAKPOINT_MOBILE } from '../../config/constant';
import { formatPrice, makePluralizedString, pluralize } from '../../utils';
import TipologiaPortada from '../TipologiaPortada';
import './Tabs.scss';
import { Icon as Iconify } from '@iconify/react';
import checkIcon from '@iconify/icons-mdi/check-circle';

const statusStyle = {
  0: {
    background: '#FEFC79',
    color: 'black'
  },
  1: {
    background: '#5FCE5C',
    color: 'white'
  }
}
const CardCotizacion = ({ cotizacion, onArrendarClick, mobileCard = false, openVisita }) => {
  const [rizeWidth] = useResize();
  const iconSize = rizeWidth <= BREAKPOINT_MOBILE ? '20' : '20';
  const expirationAlertText = cotizacion.expired ? 'Esta cotización <strong>ya expiró!</strong>' :
    `Esta cotización expira en <strong>${makePluralizedString('día', cotizacion.expirationInDays)}.</strong>`;
  const { status: { evaluacion, reserva, contrato, firma } } = cotizacion
  const statusEvaluacion = evaluacion
  const statusReserva = reserva
  const statusContrato = contrato
  const statusFirma = firma

  const [openInfo, setOpenInfo] = useState(false);

  let precioTotal = 0;
  
  if(cotizacion.resumenContrato == null){
      let sumaPrecio = 0
      let sumaGastoComunes = 0
      for (let index = 0; index < cotizacion.productos.length; index++) {
        const item = cotizacion.productos[index];
        sumaPrecio = sumaPrecio + item.productoprecio;
        sumaGastoComunes = sumaGastoComunes + item.productogastocomun
      }

      //console.log(sumaPrecio);
      precioTotal = sumaPrecio + sumaGastoComunes
  } else {
      precioTotal = cotizacion.resumenContrato.montoArriendo + cotizacion.resumenContrato.montoGastoComun 
  }


  return (
    <div className={styles.container}>

      <div className={styles.infoContainer}>
          { statusEvaluacion != 'undefined' && (
            <>
              <div className={styles.alertContainer}>
                <CotizacionesAlerta
                  cardStyle={{
                    width: '100%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    textAlign: 'center',
                    padding: '6px',
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                  icon=''
                  text={expirationAlertText}
                  status={statusEvaluacion}
                />
              </div>
              <div>
                <ul className={`tabbed-list ${!mobileCard ? 'noBorder':''}`}>
                  <li
                    style={{
                      backgroundColor: statusEvaluacion !== undefined ? statusStyle[statusEvaluacion]?.background : '',
                      color: statusEvaluacion ? statusStyle[statusEvaluacion]?.color : ''
                    }}
                  >
                    <span className="content">
                      <Iconify icon={checkIcon} className="tabs-iconofy-icon" />
                      Evaluación
                    </span>
                  </li>
                  <li
                    style={{
                      backgroundColor: statusReserva !== undefined ? statusStyle[statusReserva]?.background : '',
                      color: statusReserva ? statusStyle[statusReserva]?.color : ''
                    }}
                  >
                    <span className="content"><Iconify icon={checkIcon} className="tabs-iconofy-icon" />
                      Reserva
                    </span>
                  </li>
                  <li
                    style={{
                      backgroundColor: statusContrato !== undefined ? statusStyle[statusContrato]?.background : '',
                      color: statusContrato ? statusStyle[statusContrato]?.color : ''
                    }}
                  >
                    <span className="content"><Iconify icon={checkIcon} className="tabs-iconofy-icon" />
                      Contrato
                    </span>
                  </li>
                  <li
                    style={{
                      backgroundColor: statusFirma !== undefined ? statusStyle[statusFirma]?.background : '',
                      color: statusFirma ? statusStyle[statusFirma]?.color : ''
                    }}
                  >
                    <span className="content"><Iconify icon={checkIcon} className="tabs-iconofy-icon" />
                      Firma
                    </span>
                  </li>
                </ul>
              </div>
            </>
          )}
        <div className={`flex ${mobileCard ? styles.cardMovilHeader:''}`}>
          <div className={`${styles.tipologiaImgContainer} `}>
            {true && <TipologiaPortada tipologia={cotizacion.tipologia} />}
          </div>
          {!mobileCard && <div className={styles.location + " flex-column"}>
            <div className={styles.locationNameContainer}>
              <p>{cotizacion.tipologia.titulo}</p>
              <TextInfo
                iconName="icon-location-pin"
                iconColor="#FF6D00"
                containerStyle={{
                  fontSize: '13px',
                  position: 'relative',
                  left: '-8px',
                  top: '5px',
                }}
              >
                {cotizacion.proyecto.proyecto_nombre} | {cotizacion.proyecto.comuna}
              </TextInfo>
            </div>
            <div className={styles.priceContainer}>
              {/* {
                cotizacion.resumenContrato == null ? ( */}
                  <>
                    <p>Precio Mensual + Gastos Comunes:</p>
                    <p className={styles.priceHighlight}>${formatPrice(precioTotal)}</p>
                  </>
                {/* ):(
                  <>
                    <p>Precio mensual</p>
                    <p className={styles.priceHighlight}>${formatPrice(precioTotal)}</p>
                  </>
                )
              }  */}
            </div>
          </div>}
        </div>
        {!mobileCard &&
          <div className={`flex ${mobileCard ? styles.cardMovilItem:''}`}>
            <ul className={styles.features}>
              <li className={styles.featureBox}>
                <div className={styles.featureInnerBox}>
                  <Icon name="icon-hotel" size={iconSize} iconStyle={{ marginLeft: '0' }} />
                  <p><strong>1</strong> departamento</p>
                </div>
              </li>

              <li className={styles.featureBox}>
                <div className={styles.featureInnerBox}>
                  <Icon name="icon-garage" size={iconSize} iconStyle={{ marginLeft: '0' }} />
                  <p><strong>{cotizacion.estacionamientos.length}</strong> {pluralize('estacionamiento', cotizacion.estacionamientos.length)}</p>
                </div>
              </li>

              <li className={styles.featureBox}>
                <div className={styles.featureInnerBox} style={{ borderRight: 'none' }}>
                  <Icon name="icon-warehouse" size={iconSize} iconStyle={{ marginLeft: '0' }} />
                  <p><strong>{cotizacion.bodegas.length}</strong> {pluralize('bodega', cotizacion.bodegas.length)}</p>
                </div>
              </li>
            </ul>
            <div className={styles.buttonsContainer}>
              <div className={styles.buttonComponentContainer}>
                <Button color='btn-outline-orange' disabled={statusReserva == 1} onClick={()=>openVisita(cotizacion)} size="extra-small" fullWidth={true}>AGENDAR VISITA</Button>
              </div>
              <div className={styles.buttonComponentContainer}>
                <Button onClick={() => onArrendarClick({
                  statusEvaluacion,
                  statusReserva,
                  statusContrato
                })} color='btn-orange' size="extra-small" fullWidth={true}>
                  {
                    statusContrato
                      ? statusFirma == 0 ? ('IR A FIRMA'):(statusFirma != undefined ? 'VER CONTRATO' : 'IR A FIRMA')
                      : statusContrato === 0
                        ? 'PAGAR CONTRATO'
                        : statusReserva
                          ? 'IR A CONTRATO'
                          : statusEvaluacion
                            ? 'IR A RESERVA'
                            : 'ARRENDAR ONLINE'
                  }
                </Button>
              </div>
            </div>
          </div>
        }
      {mobileCard &&
            <>
              <div className={styles.locationNameContainerMobile}>
                <div className={styles.infotextMobile}>
                  <p>{cotizacion.tipologia.titulo}</p>
                    <TextInfo
                      iconName="icon-location-pin"
                      iconColor="#FF6D00"
                      containerStyle={{
                        fontSize: '13px',
                        position: 'relative',
                        left: '-8px',
                        top: '5px',
                      }}
                    >
                      {cotizacion.proyecto.proyecto_nombre} | {cotizacion.proyecto.comuna}
                    </TextInfo>
                </div>
                
                <div className={`${styles.arrowColap} ${openInfo ? styles.show:''}`} onClick={()=>setOpenInfo( openInfo ? false : true)}></div>

              </div>

              <ul className={`${styles.featuresMobile} ${openInfo ? styles.show:''}`}>
                    <li className={styles.featureBox}>
                      <div className={styles.featureInnerBox}>
                        <Icon name="icon-hotel" size={iconSize} iconStyle={{ marginLeft: '0' }} />
                        <p><strong>1</strong> departamento</p>
                      </div>
                    </li>

                    <li className={styles.featureBox}>
                      <div className={styles.featureInnerBox}>
                        <Icon name="icon-garage" size={iconSize} iconStyle={{ marginLeft: '0' }} />
                        <p><strong>{cotizacion.estacionamientos.length}</strong> {pluralize('estacionamiento', cotizacion.estacionamientos.length)}</p>
                      </div>
                    </li>

                    <li className={styles.featureBox}>
                      <div className={styles.featureInnerBox} style={{ borderRight: 'none' }}>
                        <Icon name="icon-warehouse" size={iconSize} iconStyle={{ marginLeft: '0' }} />
                        <p><strong>{cotizacion.bodegas.length}</strong> {pluralize('bodega', cotizacion.bodegas.length)} {cotizacion.id}</p>
                      </div>
                    </li>
              </ul>

              <div className={styles.btnMobile}>
                  
                  <div className={styles.priceContainer}>
                    {
                      cotizacion.resumenContrato == null ? (
                        <>
                          <p>Precio Mensual + Gastos Comunes:</p>
                          <p className={styles.priceHighlight}>${formatPrice(precioTotal)}</p>
                        </>
                      ):(
                        <>
                          <p>Precio mensual</p>
                          <p className={styles.priceHighlight}>${formatPrice(precioTotal)}</p>
                        </>
                      )
                    }
                  </div>

                  <div className={styles.buttonsContainer}>
                    <div className={styles.buttonComponentContainer}>
                      <Button color='btn-outline-orange' disabled={statusReserva == 1} onClick={()=>openVisita(cotizacion)} size="extra-small" fullWidth={true}>AGENDAR VISITA</Button>
                    </div>
                    <div className={styles.buttonComponentContainer}>
                      <Button onClick={() => onArrendarClick({
                        statusEvaluacion,
                        statusReserva,
                        statusContrato
                      })} color='btn-orange' size="extra-small" fullWidth={true}>
                        {
                          statusContrato
                            ? 'IR A FIRMA'
                            : statusContrato === 0
                              ? 'PAGAR CONTRATO'
                              : statusReserva
                                ? 'IR A CONTRATO'
                                : statusEvaluacion
                                  ? 'IR A RESERVA'
                                  : 'ARRENDAR ONLINE'
                        }
                      </Button>
                    </div>
                  </div>

              </div>
      </>}


      </div>

      {(cotizacion.expired && statusEvaluacion == 0) && <div className={styles.veil}>  <Button onClick={onArrendarClick} color='btn-orange' size="extra-small">VOLVER A COTIZAR</Button> </div>}
    </div>
  );
}


export default CardCotizacion;
