import React from 'react';
import './styles/PlaceMap.scss';
import CardPlaceGenerico from './CardPlaceGenerico';


// Pin para usar en un Gmap. Cuando se clickea se muestra una carta con la información
// del proyecto correspondiente.
const PlaceMap = ({
  proyecto,
  id,
  lat,
  lng,
  selectPlace,
  handleClick,
  disablePlaceCard,
  cardType,
  detallesClickCallback
}) => {

  const coordinates = { lat, lng };
  const isActive = selectPlace === id && 'active';

  return (
    <div className="boxMap">
      <div data-direccion={proyecto.direccion} className={`circle-map ${isActive}`} onClick={() => handleClick(id, coordinates, proyecto)}></div>
      <div className={`box-place-map ${!disablePlaceCard ? isActive : disablePlaceCard}`}>
        {
          !disablePlaceCard &&
          <CardPlaceGenerico
            proyecto={proyecto}
            gastos={false}
            btnPreview={true}
            cardType={cardType}
            detallesClickCallback={detallesClickCallback}
          />
        }
      </div>
    </div>
  );
}

export default PlaceMap;
