import React from 'react';
import stylesDefault from './styles/Spinner.module.scss';


// Componente de spinner. Si `enabled` es true, se muestra una animación de un spinner, sino
// no se muestra nada. 'spinnerStyle' es un prop opcional para customizar el CSS del spinner.
const Spinner = ({
  enabled,
  spinnerStyle
}) => {

  let loaderClass = spinnerStyle ? spinnerStyle.loader : stylesDefault.loader;

  return (
    <div>
      {enabled && <div className={loaderClass}>Loading...</div>}
    </div>
  );
}

export default Spinner;
