import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  selectGetConfiguracionReservaData,
  setGetConfiguracionReservaErrorCount,
  setGetConfiguracionReservaSuccessCount
} from '../redux/preevaluacionSlice';


// Hook para mostrar mensaje de éxito/error del thunk getConfiguracionReserva. Devuelve data relacionada
// al thunk.
const useGetConfiguracionReservaMessage = () => {

  const dispatch = useDispatch();
  const getConfiguracionReservaData = useSelector(selectGetConfiguracionReservaData);

  useEffect(() => {
    switch (getConfiguracionReservaData.status) {
      case 'succeeded':
        if (getConfiguracionReservaData.successCount > 0) {
          // No mostramos nada si el thunk fue exitoso, solo reseteamos el counter
          //toast.success(getConfiguracionReservaData.successMessage);
          dispatch(setGetConfiguracionReservaSuccessCount(0));
        }
        break;

      case 'failed':
        if (getConfiguracionReservaData.errorCount > 0) {
          toast.error(`No se pudo obtener la configuración de reserva: ${getConfiguracionReservaData.errorMessage}`)
          dispatch(setGetConfiguracionReservaErrorCount(0));
        }
        break;

      default:
        break;
    }
  }, [dispatch, getConfiguracionReservaData.errorCount, getConfiguracionReservaData.errorMessage, getConfiguracionReservaData.status,
    getConfiguracionReservaData.successCount, getConfiguracionReservaData.successMessage]);


  return getConfiguracionReservaData;
}

export default useGetConfiguracionReservaMessage;
