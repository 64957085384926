import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Input,
  Button,
  ButtonDropdown,
  Dropdown,
  Checkbox,
  TextInfo
} from '../ui/index';
import {
  getInvalidApellidoString,
  getInvalidEmailString,
  getInvalidNombreString,
  getInvalidTelefonoString,
  isValidApellido,
  isValidDocumento,
  isValidEdificio,
  isValidEmail,
  isValidFechaSalida,
  isValidMensaje,
  isValidNombre,
  isValidNroDepto,
  isValidTelefono,
} from '../../validation';
import {
  selectUniqueNombresProyectos,
  sendMessage
} from '../../redux/proyectosSlice';
import styles from './styles/NecesitasHablar.module.scss';
import StyledDatePicker from '../StyledDatePicker';
import {
  CONSULTA,
  DEFAULT_CONSULTA_DESK,
  DEFAULT_EDIFICIO_TEXT,
  DEFAULT_TIPO_CONSULTA_TEXT_DESK,
  tipoConsultaToStr
} from './constants';
import {
  selectApellidoMaterno,
  selectApellidoPaterno,
  selectEmail,
  selectNombre,
  selectTelefono
} from '../../redux/userSlice';
import FileUpload from '../FileUpload';
import { DEFAULT_FILENAME } from '../../config/constant';
import { quitarFormatoRut } from '../../utils';


const defaultTipoConsultaCheckboxState = Array(CONSULTA.length).fill(false);
defaultTipoConsultaCheckboxState[DEFAULT_CONSULTA_DESK] = true;


const NecesitasHablar = () => {

  const dispatch = useDispatch();
  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [email, setEmail] = useState('');
  const [telefono, setTelefono] = useState('');
  const [rut, setRut] = useState('');
  const [dni, setDni] = useState('');
  const [nroDepto, setNroDepto] = useState('');
  const [fechaSalida, setFechaSalida] = useState(new Date());
  const [mensaje, setMensaje] = useState('');
  const [tipoConsultaCheckboxState, setTipoConsultaCheckboxState] = useState(defaultTipoConsultaCheckboxState);
  const [tipoConsultaText, setTipoConsultaText] = useState(DEFAULT_TIPO_CONSULTA_TEXT_DESK);
  const [edificiosCheckboxState, setEdificiosCheckboxState] = useState(null);
  const [edificioText, setEdificioText] = useState(DEFAULT_EDIFICIO_TEXT);
  const [archivo, setArchivo] = useState(null);
  const fileInputEl = useRef(null);
  const [filename, setFilename] = useState(DEFAULT_FILENAME);


  // Inicializar algunos datos si el usuario está loggeado y están disponibles
  const userNombre = useSelector(selectNombre);
  const userApellidoPaterno = useSelector(selectApellidoPaterno);
  const userApellidoMaterno = useSelector(selectApellidoMaterno);
  const userEmail = useSelector(selectEmail);
  const userTelefono = useSelector(selectTelefono);

  useEffect(() => {
    if (userNombre !== '') {
      if (userApellidoPaterno === '' || userApellidoMaterno === '') {
        const split = userNombre.split(" ");
        setNombre(split.length > 1 ? split[0] : userNombre);
      } else {
        setNombre(userNombre);
      }
    }

    if (userApellidoPaterno !== '' && userApellidoMaterno !== '') {
      setApellido(`${userApellidoPaterno} ${userApellidoMaterno}`);
    } else {
      const split = userNombre.split(" ");
      setApellido(split[split.length - 1]);
    }

    if (userEmail !== '') {
      setEmail(userEmail);
    }

    if (userTelefono !== '') {
      setTelefono(userTelefono);
    }
  }, [userApellidoMaterno, userApellidoPaterno, userEmail, userNombre, userTelefono]);


  // Actualizar checkboxes de edificios cuando obtenemos los nombres de los proyectos
  const uniqueNombresProyectos = useSelector(selectUniqueNombresProyectos);

  useEffect(() => {
    if (uniqueNombresProyectos.length > 0) {
      setEdificiosCheckboxState(Array(uniqueNombresProyectos.length).fill(false));
    }
  }, [uniqueNombresProyectos]);

  let edificiosCheckboxes = null;

  if (uniqueNombresProyectos.length > 0 && edificiosCheckboxState) {
    edificiosCheckboxes = uniqueNombresProyectos.map((nombre, index) => {
      return (
        <Checkbox
          checked={edificiosCheckboxState[index]}
          onChange={(event) => onEdificiosCheckboxChange(event, index)}
          position="right"
        >
          <TextInfo color="black"> {nombre}</TextInfo>
        </Checkbox>
      );
    });
  }

  const onTipoConsultaCheckboxChange = (event, index) => {
    const newTipoConsultaCheckboxState = Array(CONSULTA.length).fill(false);
    newTipoConsultaCheckboxState[index] = true; // no permitimos deschequear elementos
    setTipoConsultaCheckboxState(newTipoConsultaCheckboxState);

    // Actualizar tipo de consulta
    const consultaStr = tipoConsultaToStr(index);
    setTipoConsultaText(consultaStr);

    // Simular click en dropdown para cerrarlo
    document.getElementById('necesitamos-hablar-buttondropdown').click();
  }

  const onEdificiosCheckboxChange = (event, index) => {
    const newEdificiosCheckboxState = Array(CONSULTA.length).fill(false);
    newEdificiosCheckboxState[index] = true; // no permitimos deschequear elementos
    setEdificiosCheckboxState(newEdificiosCheckboxState);

    // Actualizar texto de edificio
    const newEdificioText = uniqueNombresProyectos[index];
    setEdificioText(newEdificioText);

    // Simular click en dropdown para cerrarlo
    document.getElementById('edificios-buttondropdown').click();
  }

  const onNombreChange = (event) => {
    setNombre(event.target.value);
  }

  const onApellidoChange = (event) => {
    setApellido(event.target.value);
  }

  const onEmailChange = (event) => {
    setEmail(event.target.value);
  }

  const onTelefonoChange = (event) => {
    setTelefono(event.target.value);
  }

  const onRutChange = (event) => {
    setRut(event.target.value);
  }

  const onDniChange = (event) => {
    setDni(event.target.value);
  }

  const onNroDeptoChange = (event) => {
    setNroDepto(event.target.value);
  }

  const onMensajeChange = (event) => {
    setMensaje(event.target.value);
  }

  const onEnviarClick = () => {
    let continueSending = true;

    if (tipoConsultaCheckboxState[CONSULTA.SUGERENCIAS_FELICITACIONES_RECLAMOS] === true) {
      if (!isValidMensaje(mensaje)) {
        toast.error('Mensaje inválido!');
        continueSending = false;
      }
    } else {
      if (!isValidNombre(nombre)) {
        toast.error(getInvalidNombreString());
        continueSending = false;
      }

      if (!isValidNombre(nombre)) {
        toast.error(getInvalidNombreString());
        continueSending = false;
      }

      if (!isValidApellido(apellido)) {
        toast.error(getInvalidApellidoString());
        continueSending = false;
      }

      if (!isValidEmail(email)) {
        toast.error(getInvalidEmailString());
        continueSending = false;
      }

      if (!isValidTelefono(telefono)) {
        toast.error(getInvalidTelefonoString());
        continueSending = false;
      }

      if (
        tipoConsultaCheckboxState[CONSULTA.RENOVACION_CONTRATO] === true ||
        tipoConsultaCheckboxState[CONSULTA.CUPON_PAGO] === true ||
        tipoConsultaCheckboxState[CONSULTA.SOLICITUD_SALIDA] === true ||
        tipoConsultaCheckboxState[CONSULTA.SOLICITUD_CAMBIO_DEPTO] === true ||
        tipoConsultaCheckboxState[CONSULTA.REEMBOLSO_SERVICIOS_BASICOS] === true ||
        tipoConsultaCheckboxState[CONSULTA.ASESOR_EJECUTIVO] === true
      ) {
        if (!isValidDocumento('RUT', rut)) {
          toast.error('RUT inválido!');
          continueSending = false;
        }

      }

      if (
        tipoConsultaCheckboxState[CONSULTA.NO_POSEO_RUT] === true
      ) {
        const validDNI = isValidDocumento('DNI', dni)
        const validPass = isValidDocumento('Pasaporte', dni)

        if (!validDNI || !validPass || dni === '') {
          toast.error('DNI o pasaporte inválido!');
          continueSending = false;
        }

      }

      if (tipoConsultaCheckboxState[CONSULTA.RECOMIENDA_AMIGO] !== true &&
        tipoConsultaCheckboxState[CONSULTA.OTRAS_CONSULTAS] !== true &&
        tipoConsultaCheckboxState[CONSULTA.NO_POSEO_RUT] !== true &&
        !isValidNroDepto(nroDepto)) {
        toast.error('Número de depto inválido!');
        continueSending = false;
      }

      if (tipoConsultaCheckboxState[CONSULTA.RENOVACION_CONTRATO] ||
          tipoConsultaCheckboxState[CONSULTA.CUPON_PAGO] ||
          tipoConsultaCheckboxState[CONSULTA.SOLICITUD_SALIDA] ||
          tipoConsultaCheckboxState[CONSULTA.SOLICITUD_CAMBIO_DEPTO] ||
          tipoConsultaCheckboxState[CONSULTA.REEMBOLSO_SERVICIOS_BASICOS]
        ) {
        if (!isValidEdificio(edificioText) || edificioText === DEFAULT_EDIFICIO_TEXT) {
          toast.error('Edificio inválido!');
          continueSending = false;
        }
      }



      if (!isValidMensaje(mensaje)) {
        toast.error('Mensaje inválido!');
        continueSending = false;
      }

      if (!isValidFechaSalida(fechaSalida)) {
        toast.error('Fecha de salida inválida!');
        continueSending = false;
      }
    }

    if (continueSending) {
      // Enviar mensaje al back
      dispatch(sendMessage({
        tipoConsultaText: tipoConsultaText === DEFAULT_TIPO_CONSULTA_TEXT_DESK ?
          tipoConsultaToStr(DEFAULT_CONSULTA_DESK) : tipoConsultaText,
        nombre,
        apellido,
        email,
        telefono,
        rut: quitarFormatoRut(rut),
        nroDepto,
        edificio: edificioText === DEFAULT_EDIFICIO_TEXT ? '' : edificioText,
        fechaSalida,
        mensaje,
        archivo,
      }));

      // Resetear estado
      setNombre('');
      setApellido('');
      setEmail('');
      setTelefono('');
      setRut('');
      setNroDepto('');
      setEdificioText(DEFAULT_EDIFICIO_TEXT);
      setFechaSalida(new Date());
      setMensaje('');
      setTipoConsultaCheckboxState(defaultTipoConsultaCheckboxState);
      setTipoConsultaText(DEFAULT_TIPO_CONSULTA_TEXT_DESK);
      setArchivo(null);
      resetFileInput();
    }
  }

  const resetFileInput = () => {
    fileInputEl.current.value = '';
    setFilename(DEFAULT_FILENAME);
  }

  const updateFile = (newFile) => {
    setArchivo(newFile);
  }

  const updateFilename = (newFilename) => {
    setFilename(newFilename);
  }


  return (
    <div className={`form-layout`}>
      <ButtonDropdown id="necesitamos-hablar-buttondropdown" style={{ marginBottom: '30px' }} color="btn-white-gray"
        placeholder={tipoConsultaText}>
        <Dropdown style={{ width: '500px' }} title="Tipos de consulta">
          <Checkbox
            checked={tipoConsultaCheckboxState[CONSULTA.RENOVACION_CONTRATO]}
            onChange={(event) => onTipoConsultaCheckboxChange(event, CONSULTA.RENOVACION_CONTRATO)}
            position="right">
            <TextInfo color="black"> Necesito renovar mi contrato de arriendo</TextInfo>
          </Checkbox>
          <Checkbox
            checked={tipoConsultaCheckboxState[CONSULTA.CUPON_PAGO]}
            onChange={(event) => onTipoConsultaCheckboxChange(event, CONSULTA.CUPON_PAGO)}
            position="right">
            <TextInfo color="black"> Tengo dudas con mi cupón de pago</TextInfo>
          </Checkbox>
          <Checkbox
            checked={tipoConsultaCheckboxState[CONSULTA.RECOMIENDA_AMIGO]}
            onChange={(event) => onTipoConsultaCheckboxChange(event, CONSULTA.RECOMIENDA_AMIGO)}
            position="right">
            <TextInfo color="black"> Quiero que ayuden a un amigo a cotizar en LEVEL</TextInfo>
          </Checkbox>
          <Checkbox
            checked={tipoConsultaCheckboxState[CONSULTA.SOLICITUD_SALIDA]}
            onChange={(event) => onTipoConsultaCheckboxChange(event, CONSULTA.SOLICITUD_SALIDA)}
            position="right">
            <TextInfo color="black"> Necesito dar aviso del termino de mi contrato de arriendo</TextInfo>
          </Checkbox>
          <Checkbox
            checked={tipoConsultaCheckboxState[CONSULTA.SOLICITUD_CAMBIO_DEPTO]}
            onChange={(event) => onTipoConsultaCheckboxChange(event, CONSULTA.SOLICITUD_CAMBIO_DEPTO)}
            position="right">
            <TextInfo color="black"> Necesito cambiarme de departamento en LEVEL</TextInfo>
          </Checkbox>
          <Checkbox
            checked={tipoConsultaCheckboxState[CONSULTA.SUGERENCIAS_FELICITACIONES_RECLAMOS]}
            onChange={(event) => onTipoConsultaCheckboxChange(event, CONSULTA.SUGERENCIAS_FELICITACIONES_RECLAMOS)}
            position="right">
            <TextInfo color="black"> Necesito dejar una sugerencia, felicitación o reclamo</TextInfo>
          </Checkbox>
          <Checkbox
            checked={tipoConsultaCheckboxState[CONSULTA.REEMBOLSO_SERVICIOS_BASICOS]}
            onChange={(event) => onTipoConsultaCheckboxChange(event, CONSULTA.REEMBOLSO_SERVICIOS_BASICOS)}
            position="right">
            <TextInfo color="black"> Necesito pedir el reembolso de las cuentas de servicios básicos</TextInfo>
          </Checkbox>
          <Checkbox
            checked={tipoConsultaCheckboxState[CONSULTA.ASESOR_EJECUTIVO]}
            onChange={(event) => onTipoConsultaCheckboxChange(event, CONSULTA.ASESOR_EJECUTIVO)}
            position="right">
            <TextInfo color="black"> Necesito que un ejecutivo de arriendo me asesore</TextInfo>
          </Checkbox>
          <Checkbox
            paddingRight="20px"
            checked={tipoConsultaCheckboxState[CONSULTA.NO_POSEO_RUT]}
            onChange={(event) => onTipoConsultaCheckboxChange(event, CONSULTA.NO_POSEO_RUT)}
            position="right">
            <TextInfo color="black"> No poseo RUT, necesito cotizar un departamento, estacionamiento y/o bodega en LEVEL</TextInfo>
          </Checkbox>
        </Dropdown>
      </ButtonDropdown>

      <div className="layout-group">
        <Input value={nombre} onChange={onNombreChange} label="Nombre" type="text" classes="extra-small" fullWidth={true} placeholder="Ingresa un nombre" />
        <Input value={apellido} onChange={onApellidoChange} label="Apellido" type="text" classes="extra-small" fullWidth={true} placeholder="Ingresa un apellido" />
      </div>
      <div className="layout-group">
        <Input value={email} onChange={onEmailChange} label="Correo electrónico" type="text" classes="extra-small" fullWidth={true} placeholder="Ingresa un correo electrónico" />
        <Input value={telefono} onChange={onTelefonoChange} label="Teléfono" type="text" classes="extra-small" fullWidth={true} placeholder="Ingresa un teléfono" />
      </div>
      {true &&
        <>
          {
            tipoConsultaCheckboxState[CONSULTA.RECOMIENDA_AMIGO] !== true &&
            tipoConsultaCheckboxState[CONSULTA.ASESOR_EJECUTIVO] !== true &&
            tipoConsultaCheckboxState[CONSULTA.OTRAS_CONSULTAS] !== true &&
            tipoConsultaCheckboxState[CONSULTA.NO_POSEO_RUT] !== true &&

            <div className="layout-group">
              <Input value={nroDepto} onChange={onNroDeptoChange} label="Nº Depto" type="text" classes="extra-small" fullWidth={true} placeholder="Ingresa un número de depto" />

              <div style={{ position: 'relative', top: '30px' }}>
                <ButtonDropdown id="edificios-buttondropdown"
                  style={{ marginBottom: '30px', position: 'relative', height: '36px' }}
                  color="btn-white-gray" fullWidth={true} placeholder={edificioText}>
                  <Dropdown style={{ width: '280px', left: '-15px', paddingBottom: '0px' }} title="Edificios">
                    {edificiosCheckboxes}
                  </Dropdown>
                </ButtonDropdown>
              </div>
            </div>
          }
          {
            tipoConsultaCheckboxState[CONSULTA.SOLICITUD_SALIDA] === true &&

            <div className="layout-group" style={{ gridGap: '0', columnGap: '20px' }}>
              <Input value={rut} onChange={onRutChange} label="RUT" type="text" classes="extra-small" fullWidth={true} placeholder="Ingresa un RUT" />
              <StyledDatePicker label="Fecha de salida" date={fechaSalida} updateDate={(date) => setFechaSalida(date)} />
            </div>
          }
          {
            tipoConsultaCheckboxState[CONSULTA.SOLICITUD_SALIDA] !== true &&
            tipoConsultaCheckboxState[CONSULTA.RECOMIENDA_AMIGO] !== true &&
            tipoConsultaCheckboxState[CONSULTA.SUGERENCIAS_FELICITACIONES_RECLAMOS] !== true &&
            tipoConsultaCheckboxState[CONSULTA.OTRAS_CONSULTAS] !== true &&
            tipoConsultaCheckboxState[CONSULTA.NO_POSEO_RUT] !== true &&

            <div className="layout-group" style={{ gridGap: '0', columnGap: '20px' }}>
              <Input value={rut} onChange={onRutChange} label="RUT" type="text" classes="extra-small" fullWidth={true} placeholder="Ingresa un RUT" />
            </div>
          }

          {
            tipoConsultaCheckboxState[CONSULTA.NO_POSEO_RUT] === true &&

            <div className="layout-group" style={{ gridGap: '0', columnGap: '20px' }}>
              <Input value={dni} onChange={onDniChange} label="DNI/Pasaporte" type="text" classes="extra-small" fullWidth={true} placeholder="Ingresa un DNI/Pasaporte" maxLength="12" />
            </div>
          }
        </>
      }
      <label>Mensaje</label>
      <div className={styles.textareaContainer}>
        <textarea value={mensaje} onChange={onMensajeChange} />
      </div>
      {
        tipoConsultaCheckboxState[CONSULTA.ASESOR_EJECUTIVO] !== true &&
        tipoConsultaCheckboxState[CONSULTA.NO_POSEO_RUT] !== true &&

        <FileUpload updateFile={updateFile} filename={filename} updateFilename={updateFilename}
        inputEl={fileInputEl} />
      }
      <div className="_option-form white">
        <Button onClick={onEnviarClick} style={{ marginTop: '25px' }} color="btn-outline-orange" size="extra-small" fullWidth={true} disabled={false}>ENVIAR</Button>
      </div>
    </div>
  );
}

export default NecesitasHablar;
