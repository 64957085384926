import React from 'react';
import { Modal, Icon, TextInfo, Button} from '../../../ui/index';


const FirmaConfirmada = ({close}) => {
    return (
        <Modal show={true} close={close}>
            <div className="modal-content modal-login fade">
                <div className="header-modal">
                    <Icon onClick={close} name="icon-close" size="14" />
                </div>
                <div className="body-modal">
                    <div className="form-content small">
                        <h3 className="small">Cita confirmada</h3>
                        <span>Te esperamos para la firma de tu contrato. Si<br/> tienes dudas puedes <TextInfo color="orange">contactarnos.</TextInfo></span>
                        <div className="_bodySave-c ">
                            <div className="_fecha_cita">
                                <TextInfo iconName="icon-clock" iconColor="#FF6D00" iconSize={16} color="black" textWeight="bold">14 de mayo del 2020</TextInfo>
                                <Icon size={14} color="#FF6D00" name="icon-correct"/>
                            </div>
                            <div className="_bottom-c small">
                                <Button onClick={close} color='btn-orange' size="small" fullWidth={true}> IR A MI PERFIL</Button>
                                <TextInfo color="orange" className="link">Agregar cita a tu calendario</TextInfo>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default FirmaConfirmada;
