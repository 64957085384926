import React, { useEffect } from 'react';
import Layout from '../components/Layout';
import 'react-toastify/dist/ReactToastify.css';
import IngresoMantencionFrame from '../components/IngresoMantencionFrame';
import { dataLayerPush } from '../utils';
import { useLocation } from 'react-router-dom';


const IngresoMantencion = ({ match }) => {

  const pageCurrent = match.path;

  // Analytics
  const location = useLocation();

  useEffect(() => {
    dataLayerPush({
      event: 'vpv',
      page: location.pathname,
      section: 'mantención',
      url: window.location.href,
    });
  }, [location.pathname]);


  return (
    <Layout pageCurrent={pageCurrent}>
      <IngresoMantencionFrame match={match} />
    </Layout>
  );
}

export default IngresoMantencion;
