import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BACKEND_BASE_URL } from '../config/constant';

import mercadoFrutas from '../static/images/quienes_somos/mercado_frutas.png';
import lavadoras from '../static/images/quienes_somos/level_lavadoras.png';
import piscina from '../static/images/quienes_somos/level_piscina.png';
import hall from '../static/images/quienes_somos/level_hall.png';
import deptoInterior from '../static/images/quienes_somos/depto_interior.png';
import hallRecepcion from '../static/images/quienes_somos/hall_recepcion.png';

const axios = require('axios');


export const INITIAL_UBICACION_IMAGE = { url: mercadoFrutas, alt: 'mercado-frutas' };
export const INITIAL_UBICACION_TEXT = `
Nuestros proyectos se encuentran en los
barrios más dinámicos de Santiago.
Siempre bien ubicados y emplazados en
barrios vivos del Gran Santiago, cercanos
al metro y al transporte público, cuentan
con toda la conectividad que necesitas
para moverte.
`;
export const INITIAL_UBICACION_TITLE = `Ubicación.`;

export const INITIAL_ESPACIOS_COMUNES_IMAGE = { url: lavadoras, alt: 'lavadoras' };
export const INITIAL_ESPACIOS_COMUNES_TEXT = `
Nuestros espacios comunes están
pensados como una extensión de cada
uno de los departamentos, manteniendo
los estándares y agregando la
entretención y relajo necesario a cada
proyecto.
`;
export const INITIAL_ESPACIOS_COMUNES_TITLE = `Espacios comunes.`;

export const INITIAL_GASTOS_FIJOS_IMAGE = { url: piscina, alt: 'piscina' };
export const INITIAL_GASTOS_FIJOS_TEXT = `
En LEVEL tenemos la capacidad de
mantener los gastos comunes fijos, sin
cambios mes a mes, lo que te permitirá
programarte de forma más eficiente.
`;
export const INITIAL_GASTOS_FIJOS_TITLE = `Gastos comunes fijos.`;

export const INITIAL_SEGURIDAD_IMAGE = { url: hall, alt: 'hall' };
export const INITIAL_SEGURIDAD_TEXT = `
Contamos con circuitos cerrados de
televisión y cámaras en cada proyecto,
las que monitorean los puntos de más
alto tránsito en todo el edificio,
resguardando la seguridad.
`;
export const INITIAL_SEGURIDAD_TITLE = `Seguridad.`;

export const INITIAL_DEPARTAMENTOS_IMAGE = { url: deptoInterior, alt: 'depto-interior' };
export const INITIAL_DEPARTAMENTOS_TEXT = `
Nuestros departamentos están diseñados
para la gente de hoy, con materialidades
sobre el estándar y mantención
recurrente que mantiene su calidad en el
tiempo.
`;
export const INITIAL_DEPARTAMENTOS_TITLE = `Departamentos.`;

export const INITIAL_MANTENCION_IMAGE = { url: hallRecepcion, alt: 'hall-recepcion' };
export const INITIAL_MANTENCION_TEXT = `
Level cuenta con un equipo de
mantención capacitado para reparar y
mejorar cualquier inconveniente en tu
departamento, agendando el servicio en
nuestra web.
`;
export const INITIAL_MANTENCION_TITLE = `Mantención.`;

export const INITIAL_GET_BENEFICIOS_DATA_STATUS = 'idle';


const initialState = {
  ubicacion: {
    image: INITIAL_UBICACION_IMAGE,
    text: INITIAL_UBICACION_TEXT,
    title: INITIAL_UBICACION_TITLE,
  },

  espaciosComunes: {
    image: INITIAL_ESPACIOS_COMUNES_IMAGE,
    text: INITIAL_ESPACIOS_COMUNES_TEXT,
    title: INITIAL_ESPACIOS_COMUNES_TITLE,
  },

  gastosFijos: {
    image: INITIAL_GASTOS_FIJOS_IMAGE,
    text: INITIAL_GASTOS_FIJOS_TEXT,
    title: INITIAL_GASTOS_FIJOS_TITLE,
  },

  seguridad: {
    image: INITIAL_SEGURIDAD_IMAGE,
    text: INITIAL_SEGURIDAD_TEXT,
    title: INITIAL_SEGURIDAD_TITLE,
  },

  departamentos: {
    image: INITIAL_DEPARTAMENTOS_IMAGE,
    text: INITIAL_DEPARTAMENTOS_TEXT,
    title: INITIAL_DEPARTAMENTOS_TITLE,
  },

  mantencion: {
    image: INITIAL_MANTENCION_IMAGE,
    text: INITIAL_MANTENCION_TEXT,
    title: INITIAL_MANTENCION_TITLE,
  },

  getBeneficiosDataStatus: INITIAL_GET_BENEFICIOS_DATA_STATUS,
};


export const getBeneficiosData = createAsyncThunk('beneficios/getData', async () => {
  try {
    const response = await axios.get(`${BACKEND_BASE_URL}/beneficios`);
    return { response };
  } catch (error) {
    console.log(`Error intentando obtener data de "Beneficios": ${error}`);
    throw error;
  }
});


const beneficiosSlice = createSlice({
  name: 'beneficios',
  initialState,
  extraReducers: {
    [getBeneficiosData.pending]: (state, action) => {
      state.getBeneficiosDataStatus = 'loading';
    },
    [getBeneficiosData.fulfilled]: (state, action) => {
      state.getBeneficiosDataStatus = 'succeeded';

      const { data } = action.payload.response;

      // Datos de "Ubicación"
      if (data.ubicacion_image) {
        state.ubicacion.image = data.ubicacion_image;
        state.ubicacion.image.alt = state.ubicacion.image.alternativeText;
        state.ubicacion.image.url = `${BACKEND_BASE_URL}${state.ubicacion.image.url}`;
      }

      if (data.ubicacion_text && data.ubicacion_text !== '') {
        state.ubicacion.text = data.ubicacion_text;
      }

      if (data.ubicacion_title && data.ubicacion_title !== '') {
        state.ubicacion.title = data.ubicacion_title;
      }

      // Datos de "Espacios comunes"
      if (data.espacios_comunes_image) {
        state.espaciosComunes.image = data.espacios_comunes_image;
        state.espaciosComunes.image.alt = state.espaciosComunes.image.alternativeText;
        state.espaciosComunes.image.url = `${BACKEND_BASE_URL}${state.espaciosComunes.image.url}`;
      }

      if (data.espacios_comunes_text && data.espacios_comunes_text !== '') {
        state.espaciosComunes.text = data.espacios_comunes_text;
      }

      if (data.espacios_comunes_title && data.espacios_comunes_title !== '') {
        state.espaciosComunes.title = data.espacios_comunes_title;
      }

      // Datos de "Gastos fijos"
      if (data.gastos_fijos_image) {
        state.gastosFijos.image = data.gastos_fijos_image;
        state.gastosFijos.image.alt = state.gastosFijos.image.alternativeText;
        state.gastosFijos.image.url = `${BACKEND_BASE_URL}${state.gastosFijos.image.url}`;
      }

      if (data.gastos_fijos_text && data.gastos_fijos_text !== '') {
        state.gastosFijos.text = data.gastos_fijos_text;
      }

      if (data.gastos_fijos_title && data.gastos_fijos_title !== '') {
        state.gastosFijos.title = data.gastos_fijos_title;
      }

      // Datos de "Seguridad"
      if (data.seguridad_image) {
        state.seguridad.image = data.seguridad_image;
        state.seguridad.image.alt = state.seguridad.image.alternativeText;
        state.seguridad.image.url = `${BACKEND_BASE_URL}${state.seguridad.image.url}`;
      }

      if (data.seguridad_text && data.seguridad_text !== '') {
        state.seguridad.text = data.seguridad_text;
      }

      if (data.seguridad_title && data.seguridad_title !== '') {
        state.seguridad.title = data.seguridad_title;
      }

      // Datos de "Departamentos"
      if (data.departamentos_image) {
        state.departamentos.image = data.departamentos_image;
        state.departamentos.image.alt = state.departamentos.image.alternativeText;
        state.departamentos.image.url = `${BACKEND_BASE_URL}${state.departamentos.image.url}`;
      }

      if (data.departamentos_text && data.departamentos_text !== '') {
        state.departamentos.text = data.departamentos_text;
      }

      if (data.departamentos_title && data.departamentos_title !== '') {
        state.departamentos.title = data.departamentos_title;
      }

      // Datos de "Mantención"
      if (data.mantencion_image) {
        state.mantencion.image = data.mantencion_image;
        state.mantencion.image.alt = state.mantencion.image.alternativeText;
        state.mantencion.image.url = `${BACKEND_BASE_URL}${state.mantencion.image.url}`;
      }

      if (data.mantencion_text && data.mantencion_text !== '') {
        state.mantencion.text = data.mantencion_text;
      }

      if (data.mantencion_title && data.mantencion_title !== '') {
        state.mantencion.title = data.mantencion_title;
      }
    },
    [getBeneficiosData.rejected]: (state, action) => {
      state.getBeneficiosDataStatus = 'failed';
    },
  }
});

export default beneficiosSlice.reducer;

export const selectUbicacion = state => state.beneficios.ubicacion;
export const selectEspaciosComunes = state => state.beneficios.espaciosComunes;
export const selectGastosFijos = state => state.beneficios.gastosFijos;
export const selectSeguridad = state => state.beneficios.seguridad;
export const selectDepartamentos = state => state.beneficios.departamentos;
export const selectMantencion = state => state.beneficios.mantencion;
export const selectGetBeneficiosDataStatus = state => state.beneficios.getBeneficiosDataStatus;
export const selectAllBeneficiosData = state => state.beneficios;
