/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BACKEND_BASE_URL, BREAKPOINT_MOBILE } from '../../../../config/constant';
import useResize from '../../../../hooks/useResize';
import {
  selectCurrentDeptosByPiso,
  selectCurrentPisos,
  selectDeptoByNombre,
  selectReserva,
  setReserva,
  selectPisosWithDeptos,
  selectPisosDptWithApi
} from '../../../../redux/preevaluacionSlice';
import TipologiaPortada from '../../../TipologiaPortada';
import { Button, Icon, Select, TextInfo, Checkbox } from '../../../ui/index';
import ModalImagen from './ModalImagen';
import PlanoInteractivoSvg from './PlanoInteractivoSvg';
import styles from './styles/SeleccionaDepartamento.module.scss';


const PISO_DEFAULT = -1;
const DEPTO_DEFAULT = -1;

const YES = 0;
const NO = 1;


const SeleccionaDepartamento = ({ proyecto, currentCotizacion, active, checked, onHeaderClick, nextForm, setdisponibilidad, disponibilidad }) => {
  const { piso: pisoCurrent, numero: numeroDptCurrent } = currentCotizacion.departamento
  const [checkboxesMascota, setCheckboxesMascota] = useState([false, true]);
  const [currentPiso, setCurrentPiso] = useState(PISO_DEFAULT);
  const [currentDeptoNum, setCurrentDeptoNum] = useState(DEPTO_DEFAULT);
  const [showModalImagen, setShowModalImagen] = useState(false);
  const [showModalDetalle, setShowModalDetalle] = useState(false);
  const dispatch = useDispatch();
  const [rizeWidth] = useResize();
  const [allPisosList, setAllPisosList] = useState([]);
  const [allDptList, setAllDptList] = useState([]);
  const [gastoComunPrice, setGastoComunPrice] = useState(0)
  const [arriendoMensualPrice, setArriendoMensualPrice] = useState(0)
  const reserva = useSelector(selectReserva);
  // Objeto de Strapi del depto actual. El número de depto en el back es una string llamada "nombre",
  // de ahí el nombre del selector y el cast a String que hacemos acá.
  //const currentDeptoObj = useSelector(selectDeptoByNombre(proyecto.departamentos, String(currentDeptoNum)));

  // Actualizar el objeto de depto en la reserva cuando cambia currentDeptoObj
  /* useEffect(() => {
    if (currentDeptoObj) {
      dispatch(setReserva({
        ...reserva,
        deptoStrapiId: currentDeptoObj.id,
        deptoStrapiObj: currentDeptoObj,
      }));
    }
    
  }, [dispatch, reserva, currentDeptoObj]); */

  const { tipologia } = currentCotizacion
  // Pisos correspondientes a la tipología actual
  //const pisosCurrentTipologia = useSelector(selectCurrentPisos(proyecto.departamentos, tipologia));

  //const allPisosAPi = useSelector(selectPisosDptWithApi(proyectoNombre, tituloTipologia))
  const obtenerPisos = async (proyectoNombre, tituloTipologia, pisoCurrent) => {
    const result = await selectPisosDptWithApi(proyectoNombre, tituloTipologia, pisoCurrent);
    const mascota = checkboxesMascota[0] ? true : false
    const filterOption = result.filter((item) => item.PRODUCTOPRIMARIOPERMITEMASCOTA === mascota);
    if (pisoCurrent > 0) {
      setAllDptList(filterOption)
    } else {
      setAllPisosList(filterOption)
    }

    if (filterOption.length == 0) {
      setGastoComunPrice(0)
      setArriendoMensualPrice(0)
    }

  }

  useEffect(() => {
    const proyectoNombre = proyecto.proyecto_nombre;
    const tituloTipologia = tipologia.titulo.replaceAll('-', '+');
    obtenerPisos(proyectoNombre, tituloTipologia, 0);
    obtenerPisos(proyectoNombre, tituloTipologia, currentPiso);
  }, [currentPiso, tipologia, proyecto, checkboxesMascota])


  /*   const allPisos = useSelector(selectPisosWithDeptos(proyecto.departamentos, pisosCurrentTipologia, tipologia, checkboxesMascota));
    const deptosCurrentPiso = useSelector(selectCurrentDeptosByPiso(proyecto.departamentos, currentPiso, tipologia, checkboxesMascota));
   */
  let pisos = [];
  let deptos = [];

  if (allDptList.length > 0) {
    const listDpt = allDptList.map((piso) => piso.PRODUCTOPRIMARIONUMERO)
    const dptList = [...new Set(listDpt)];
    deptos = dptList.map((dpto) => {
      return {
        id: dpto,
        name: dpto,
      };
    });
  }

  if (allPisosList.length > 0) {
    const listPisos = allPisosList.map((piso) => piso.PRODUCTOPRIMARIOPISO)
    const pisosLis = [...new Set(listPisos)];
    pisos = pisosLis.map((piso) => {
      return {
        id: piso,
        name: piso,
      };
    });



    let initialLoading = true;
    if (currentPiso === -1) {
      setTimeout(() => {
        if (initialLoading) {
          const isPiso = allPisosList.filter((piso) => piso.PRODUCTOPRIMARIOPISO === pisoCurrent);
          if (isPiso.length > 0) {
            setCurrentPiso(pisoCurrent);
            setCurrentDeptoNum(numeroDptCurrent)
          }
          initialLoading = false;
        }

      }, 2000)
    }

    setdisponibilidad(true);
  } else {
    setdisponibilidad(false);
  }

  const existDpt = allDptList.filter((item) => item.PRODUCTOPRIMARIONUMERO === currentDeptoNum);
  const existPiso = allPisosList.filter((item) => item.PRODUCTOPRIMARIOPISO === currentPiso);


  // Gastos comunes y arriendo mensuales
  if (existDpt > 0 && existPiso > 0) {
    detailDptPrecio(currentDeptoNum);
  }

  const onPisoChange = (event) => {
    const newPiso = parseInt(event.target.value, 10);
    setCurrentPiso(newPiso);
    setCurrentDeptoNum(DEPTO_DEFAULT);
    setGastoComunPrice(0)
    setArriendoMensualPrice(0)
  }

  const onDeptoChange = (event) => {
    const newDepto = parseInt(event.target.value, 10);
    if (newDepto) {
      setCurrentDeptoNum(newDepto);
      detailDptPrecio(newDepto)
    }

  }

  const detailDptPrecio = (selectedDpt) => {
    const dpt = proyecto.departamentos.filter((item) => item.nombre == selectedDpt);
    const detalleDpt = dpt[0];
    const gastoComun = detalleDpt ? '$' + detalleDpt.gastocomun.toLocaleString() : 0;
    const arriendoMensual = detalleDpt ? '$' + detalleDpt.precio_no_amoblado.toLocaleString() : 0;
    setGastoComunPrice(gastoComun)
    setArriendoMensualPrice(arriendoMensual)

    dispatch(setReserva({
      ...reserva,
      deptoStrapiId: detalleDpt.id,
      deptoStrapiObj: detalleDpt,
    }));

    if (detalleDpt.tipologia.detalle && detalleDpt.tipologia.detalle[0]) {
      detalleUrl = `${BACKEND_BASE_URL}${detalleDpt.tipologia.detalle[0].url}`;
      setDetalleUrl(detalleUrl);
      if (detalleDpt.tipologia.detalle[0].alternativeText &&
        detalleDpt.tipologia.detalle[0].alternativeText !== '') {
        detalleAltText = detalleDpt.tipologia.detalle[0].alternativeText;
        setDetalleAltText(detalleAltText);
      }
    }

  }

  const updateCurrentDeptoNum = (newDepto) => {
    setCurrentDeptoNum(newDepto);
  }

  const onCheckboxMascotaChange = (event, index) => {
    const newCheckboxesMascota = [false, false];
    newCheckboxesMascota[index] = true; // no permitimos deschequear
    setCheckboxesMascota(newCheckboxesMascota);

    dispatch(setReserva({
      ...reserva,
      mascota: index === YES,
    }));
  }

  let currentPisoSvgUrl = '';
  let plantaUrl = '';
  let plantaAltText = 'imagen-planta';

  const [detalleUrl, setDetalleUrl] = useState('');
  const [detalleAltText, setDetalleAltText] = useState('imagen-detalle')

  if (proyecto) {
    // Construir url del svg del piso actual. El nombre del archivo tiene que ser
    // [whatever]-piso-[int]. Por ejemplo, para el piso 3 de Lira 320, el nombre del
    // svg podría ser lira-320-piso-3.svg
    if (proyecto.pisos) {
      for (let i = 0; i < proyecto.pisos.length; i++) {

        //currentPisoSvgUrl = `${BACKEND_BASE_URL}${proyecto.pisos[i].url}`;
        /* desactivado temporalmente, muestra el ultimo que se encuentre en el array de SVG, en nuestro caso solo un edificio tenia SVG*/

        const pisoName = proyecto.pisos[i].name;
        const splitPisoName = pisoName.split('-');

        // Tiene que haber al menos dos strings (e.g. 'piso-3' -> 'piso' y '3')
        if (splitPisoName.length < 2) {
          console.log(`Advertencia: splitPisoName.length < 2 en pisos[${i}] del proyecto "${proyecto.proyecto_nombre}"`);
          continue;
        }

        const last = splitPisoName[splitPisoName.length - 1];
        const secToLast = splitPisoName[splitPisoName.length - 2];

        // Chequeamos que la string termine en -piso-[int] y que el número de piso
        // coincida con el actual
        if (secToLast === 'piso' && last === String(currentPiso)) {
          currentPisoSvgUrl = `${BACKEND_BASE_URL}${proyecto.pisos[i].url}`;
        }
      }
    }

    // Construir url de la imagen de la planta del piso actual
    if (proyecto.pisos_plantas) {
      for (let i = 0; i < proyecto.pisos_plantas.length; i++) {
        const pisoName = proyecto.pisos_plantas[i].name;
        const splitPisoName = pisoName.split('-');

        // Tiene que haber al menos dos strings (e.g. 'piso-3' -> 'piso' y '3')
        if (splitPisoName.length < 2) {
          console.log(`Advertencia: splitPisoName.length < 2 en pisos_plantas[${i}] del proyecto "${proyecto.proyecto_nombre}"`);
          continue;
        }

        const last = splitPisoName[splitPisoName.length - 1];
        const secToLast = splitPisoName[splitPisoName.length - 2];

        // Chequeamos que la string termine en -piso-[int] y que el número de piso
        // coincida con el actual
        if (secToLast === 'piso' && last === String(currentPiso)) {
          plantaUrl = `${BACKEND_BASE_URL}${proyecto.pisos_plantas[i].url}`;

          if (proyecto.pisos_plantas[i].alternativeText && proyecto.pisos_plantas[i].alternativeText !== '') {
            plantaAltText = proyecto.pisos_plantas[i].alternativeText;
          }
        }
      }
    }

    // Construir url de la imagen de detalle del depto actual
    /* if (currentDeptoObj) {
      if (currentDeptoObj.tipologia.detalle && currentDeptoObj.tipologia.detalle[0]) {
        detalleUrl = `${BACKEND_BASE_URL}${currentDeptoObj.tipologia.detalle[0].url}`;

        if (currentDeptoObj.tipologia.detalle[0].alternativeText &&
          currentDeptoObj.tipologia.detalle[0].alternativeText !== '') {
          detalleAltText = currentDeptoObj.tipologia.detalle[0].alternativeText;
        }
      }
    } */
  }

  const onVerDetallesClick = () => {
    setShowModalDetalle(true);
  }

  const closeModalDetalle = () => {
    setShowModalDetalle(false);
  }

  const onVerPlantaInteriorClick = () => {
    setShowModalImagen(true);
  }

  const closeModalImagen = () => {
    setShowModalImagen(false);
  }

  const linkCorredores = () => {
    window.open(window.location.origin + '/#/contacto', '_blank')
  }

  // Responsive classnames/styles
  const headerFormClass = rizeWidth <= BREAKPOINT_MOBILE ? '' : '_header-form';
  const btnStyle = rizeWidth <= BREAKPOINT_MOBILE ? { marginBottom: '100px' } : {};
  const infoSpanStyle = rizeWidth <= BREAKPOINT_MOBILE ? { display: 'block' } : {};
  const stepBodyStyle = rizeWidth > BREAKPOINT_MOBILE ? { maxHeight: 'none', height: '690px' } : {};


  return (
    <div
      className={`_itemStep-form ${checked ? 'checked' : ''} ${active ? 'active' : ''}`}
      style={{ display: `${(rizeWidth <= BREAKPOINT_MOBILE && !active) ? 'none' : ''}` }}
    >
      <div className="_step-header" onClick={onHeaderClick} >
        <TextInfo textWeight="bold" size={18} color="black">
          Selecciona departamento<span className="obligatorio">*</span>  <Icon size={16} name="icon-tick" />
        </TextInfo>
        <div className="_info">{checked > 0 ? <small>$000.000/mes</small> : ''}<Icon name="icon-question" /></div>
      </div>
      <div className="_step-body" style={stepBodyStyle}>
        <div className="_form-departamento">
          <div>
            <div style={{ 'margin-bottom': '10px', 'margin-top': '5px' }}><strong>¿Desea arrendar un departamento que permita mascotas?</strong></div>
            <div className={styles.mascotaSelector}>
              <Checkbox onChange={(e) => onCheckboxMascotaChange(e, YES)} checked={checkboxesMascota[YES]} position="left">
                <TextInfo color="black"> Sí</TextInfo>
              </Checkbox>
              <Checkbox onChange={(e) => onCheckboxMascotaChange(e, NO)} checked={checkboxesMascota[NO]} position="left">
                <TextInfo color="black"> No</TextInfo>
              </Checkbox>
            </div>
          </div>
          <div style={{ 'margin-bottom': '15px' }}>Selecciona un piso y número de departamento.</div>
          <div className={headerFormClass}>
            <div className="_opciones">
              <Select data={pisos} onChange={onPisoChange} selected={currentPiso} placeholder="Piso" className="bold" typeStyle="extra-small" fullWidth={true} />
              <Select value={currentDeptoNum} data={deptos} selected={currentDeptoNum} onChange={onDeptoChange} placeholder="Departamento" className="bold" typeStyle="extra-small" fullWidth={true} />
            </div>
            <div className="_info">
              <span style={infoSpanStyle}>Arriendo mensual: {arriendoMensualPrice}</span>
              <span style={infoSpanStyle}>Gastos comunes mensuales: {gastoComunPrice}</span>
            </div>
          </div>

          {!disponibilidad && <small>No hay unidades disponibles. Comuniquese con un corredor. <br /><Button onClick={linkCorredores} color="btn-orange" size="extra-small" fullWidth={false}>CORREDORES DISPONIBLES</Button></small>}

          <div className="_img-dep">
            {/*  <PlanoInteractivoSvg
              currentPisoSvgUrl={currentPisoSvgUrl}
              currentDeptoNum={currentDeptoNum}
              updateCurrentDeptoNum={updateCurrentDeptoNum}
              deptos={deptos}
            /> */}

            <TipologiaPortada tipologia={tipologia} />


            {/* <div className="_link-detale">
              <a onClick={onVerDetallesClick} className={styles.link}>Ver detalles</a> <span>|</span>
              <a onClick={onVerPlantaInteriorClick} className={styles.link}>Ver planta interior</a>
            </div> */}
          </div>
        </div>
        <Button
          onClick={nextForm}
          className="btn bold"
          color='btn-outline-orange'
          size="extra-small"
          fullWidth={true}
          disabled={currentPiso === PISO_DEFAULT || currentDeptoNum === DEPTO_DEFAULT || !disponibilidad}
          style={btnStyle}
        >
          SIGUIENTE
        </Button>
      </div>
      <ModalImagen
        imagenUrl={plantaUrl} altText={plantaAltText} closeText="CERRAR VISTA DE PLANO"
        show={showModalImagen} close={closeModalImagen}
      />
      <ModalImagen
        imagenUrl={detalleUrl} altText={detalleAltText} closeText="CERRAR VISTA DE PLANO"
        show={showModalDetalle} close={closeModalDetalle}
      />
    </div>
  );
}

export default SeleccionaDepartamento;
