import React from "react";
import styles from "./styles/ContactoWhatsapp.module.scss";
import whatsappIcon from "@iconify/icons-mdi/whatsapp";
import { Icon as Iconify } from "@iconify/react";
import useElementOnScreen from "../../hooks/useElementoOnScreen";
import { useEffect } from "react";

const ContactoWhatsapp = React.memo(() => {
  /*   const [ containerRef, isVisible ] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 1.0
  }) 

  useEffect(() => {
    console.log(isVisible);
    const chatWhatsapp = document.querySelector('#clgo-wsp');
    if(chatWhatsapp != null){
        if(isVisible){
            chatWhatsapp.style.display = 'none';
        } else {
            chatWhatsapp.style.display = 'block';
        }
    }
   

  }, [isVisible]) */

  return (
    <div className="container">
      <div className={styles.boxWhat}>
        <h3 className={styles.title}>
          ¿Te ayudo con tu arriendo?
          <a
            href={`https://api.whatsapp.com/send/?phone=56931461656&text&app_absent=0`}
            target="_blank"
            rel="noopener noreferrer"
            className={`${styles.iconifyContainer} ${styles.iconifyContainerWhatsapp}`}
          >
            <Iconify
              icon={whatsappIcon}
              className={styles.iconify}
              color="white"
            />
          </a>{" "}
        </h3>
      </div>
    </div>
  );
});

export default ContactoWhatsapp;
