import React from 'react';


// Devuelve los gastos comunes mínimos de las bodegas del proyecto
const GastoComunBodegas = ({ proyecto }) => {

  const gastoMin = proyecto.gastoComunMinBodegas;

  return (
    <span>${gastoMin.toLocaleString()}</span>
  );
}

export default GastoComunBodegas;
