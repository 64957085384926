/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import './styles/PlaceHeader.scss';
import { Title, Icon, TextInfo } from '../ui/index';
import SocialLinks from './SocialLinks';
import { useHistory } from 'react-router-dom';
import { scrollTo } from '../../utils';
import ModalVisita from '../ModalVisita';
import { MENU_ENABLED } from '../../config/constant';


const SECTION = {
  EDIFICIO: 0,
  DEPTOS: 1,
  VIDEOS: 2,
  UBICACION: 3,
};


const PlaceHeader = ({ openShared, proyecto }) => {

  const history = useHistory();
  const [showModalVisita, setShowModalVisita] = useState(false);
  const [section, setSection] = useState(SECTION.EDIFICIO);


  // Settear menu item activo de acuerdo al scroll
  useEffect(() => {
    document.addEventListener('scroll', (event) => {
      const offsetHeader = 5;
      const edificioEl = document.getElementById('card-place-edificio-header');
      const deptoEl = document.getElementById('card-place-departamento-header');
      const videosEl = document.getElementById('card-place-videos-header');
      const ubicacionEl = document.getElementById('card-place-ubicacion-header');
      let videosTop = 0;
      if (!edificioEl || !deptoEl || !ubicacionEl) {
        return;
      }


      const edificioTop = edificioEl.offsetTop - offsetHeader;
      const deptoTop = deptoEl.offsetTop - offsetHeader;

      if(proyecto && proyecto.videos && proyecto.videos.length > 0){
        videosTop = videosEl.offsetTop - offsetHeader;
      }

      const ubicacionTop = ubicacionEl.offsetTop - offsetHeader;

      const currScrollY = window.scrollY + 220; // agregar offset del header del sitio y /place

      if(currScrollY >= edificioTop) {
        setSection(SECTION.EDIFICIO);
      }
      
      if (currScrollY >= deptoTop) {
        setSection(SECTION.DEPTOS);
      }

      if(proyecto && proyecto.videos && proyecto.videos.length > 0){
        if (currScrollY >= videosTop) {
            setSection(SECTION.VIDEOS);
        } 
      }
      
      if (currScrollY >= ubicacionTop) {
        setSection(SECTION.UBICACION);
      }


    });
  }, []);

  const onEdificioClick = () => {
    scrollTo('card-place-edificio-header');
  }

  const onDepartamentosClick = () => {
    scrollTo('card-place-departamento-header');
  }

  const onVideosClick = () => {
    scrollTo('card-place-videos-header');
  }

  const onUbicacionClick = () => {
    scrollTo('card-place-ubicacion-header');
  }

  const onVisitarClick = () => {
    // Hack! Ver comentario en la función 'onQuienesSomosClick' en src/components/Header.js
    const placeHeader = document.getElementById('place-header-header');
    if (placeHeader) {
      placeHeader.style.zIndex = 500;
    }

    setShowModalVisita(true);
  }

  const closeModalVisita = () => {
    setShowModalVisita(false);
  }

  return (
    <div id="place-header-header" className="_headerFix">
      <div className="container">
        <div className="place-header">
          <div className="row">
            <div className="place-header-content">
              <a className="prev-link" onClick={() => history.push('/result')}><Icon name="icon-left-arrow" color="white" width={30} size="14" /></a>
              <Title size="25" className="muli white">{proyecto ? proyecto.proyecto_nombre : ''}</Title>
              <div className="place-header-right">
                <div className="place-header-nav">
                  <a onClick={onEdificioClick} className={`${section === SECTION.EDIFICIO ? 'active' : ''}`}><TextInfo color="white"><Icon name="icon-hotel" background="gray" border="round" width={30} size="15" />Edificio</TextInfo></a>
                  <a onClick={onDepartamentosClick} className={`${section === SECTION.DEPTOS ? 'active' : ''}`}><TextInfo color="white"><Icon name="icon-bed" background="gray" border="round" width={30} size="15" />Departamentos</TextInfo></a>
                  {proyecto && proyecto.videos && proyecto.videos.length > 0 && <a onClick={onVideosClick} className={`${section === SECTION.VIDEOS ? 'active' : ''}`}><TextInfo color="white"><Icon name="icon-camare" background="gray" border="round" width={30} size="15" />Videos</TextInfo></a>}
                  <a onClick={onUbicacionClick} className={`${section === SECTION.UBICACION ? 'active' : ''}`}><TextInfo color="white"><Icon name="icon-map-open" background="gray" border="round" width={30} size="15" />Ubicación</TextInfo></a>
                  {MENU_ENABLED.PLACE_VISITAR && <a onClick={onVisitarClick}><TextInfo color="white"><Icon name="icon-clock" background="gray" border="round" width={30} size="15" />Visitar</TextInfo></a>}
                </div>
                <SocialLinks openShared={openShared} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModalVisita && <ModalVisita show={showModalVisita} close={closeModalVisita} />}
    </div>
  );
};

export default PlaceHeader;
