import React from 'react';
import '../static/css/app.css';
import {
  Separador,
  Button,
  Icon,
  Input,
  InputButton,
  Checkbox,
  ToogleSwich,
  TitleFiltro,
  Badge,
  Title,
  TextInfo,
  Dropdown,
  GroupRow,
  Slider,
  Select,
  ButtonDropdown
} from './ui/index';
import imgHeader from '../static/images/header.png';
import SalaArriendo from './SalaArriendo/SalaArriendo';


const Ui = () => {

  return (
    <div className="container">

      <div className="row">

        <Title size='45' marginBotton="20">LEVEL WELCOME - UI</Title>

        <Title size="30" marginTop="20">Slider</Title>
        <Separador />

        <Slider
          className="slider-header"
          itemsToShow={1}
          enableAutoPlay={true} >
          <div className="item-slider">
            <div className="overlay-black"></div>
            <img src={imgHeader} alt="slider 1" />
          </div>
          <div className="item-slider">
            <div className="overlay-black"></div>
            <img src={imgHeader} alt="slider 1" />
          </div>
        </Slider>

        <Separador />

        <Title size="30">Button</Title>Santiago Centro
          <Separador />

        <Button color='btn-outline-blue' size="extra-small">ARRENDAR ONLINE</Button>
        <Button color='btn-orange' size="large" startIcon="icon-add">BUSCAR</Button>
        <Button color='btn-orange' size="small">ARRENDAR AQUI</Button>
        <Button color='btn-white-orange'>EXPLORAR BARRIOSS</Button>
        <Button color='btn-gray' size="medium">VER OFERTA</Button>
        <Button color='btn-outline-orange' size="medium" startIcon="icon-location-pin">VER MAPA</Button>
        <Button color='btn-white-gray' size="small-icon" startIcon="icon-left-arrow"></Button>
        <Button color='btn-white-gray' size="small-icon" startIcon="icon-button-arrow-ight"></Button>
        <Button color="btn-white-gray" startIcon="icon-adjust">FILTROS</Button>
        <Button color="btn-outline-orange" endIcon="icon-adjust">Filtrar puntos de interés</Button>

        <Title size="30" marginTop="20">Icons</Title>
        <Separador />

        <Icon size="40px" name="icon-adjust" color="#FF6D00" />
        <Icon size="30px" name="icon-adjust" color="#FF6D00" />
        <Icon size="20px" name="icon-adjust" color="#2C2B42" />
        <Icon size="16px" name="icon-adjust" color="#FF6D00" />
        <Icon size="14px" name="icon-adjust" color="#FF6D00" />

        <Icon name="icon-location-pin" border="round" background="gray-dark" color="#fff" width={40} size={20} />
        <Icon name="icon-location-pin" border="round" size={14} />

        <Title size="30" marginTop="20">Input</Title>
        <Separador />

        <Input type="text" name="passw" placeholder="No he decidido aún" />
        <Input type="text" name="passw" typeStyle="filtro" help="Minima" placeholder="$150.000" />
        <Input type="text" name="passw" typeStyle="filtro" help="Máxima" placeholder="$350.000" />

        <Input type="text" name="passw" typeStyle="filtro-gray" placeholder="2 dormitorios, 1 baño" />
        <InputButton type="text" name="passw" placeholder="+1" />

        <Select placeholder="Más relevates" typeStyle="small" fullWidth={true} />
        <Select placeholder="Más relevates" typeStyle="small" className="fill" fullWidth={true} />


        <Separador />
        <ToogleSwich position="right">¿Amoblado?</ToogleSwich>
        <Separador />

        <InputButton type="text" name="passw" flex={true} placeholder="+1" >
          <TextInfo color="black">Incluir estacionamiento</TextInfo>
        </InputButton>


        <Title size="30" marginTop="20">DropDown</Title>
        <Separador />

        <ButtonDropdown color="btn-white-gray" placeholder="No he decidido aún">
          <Dropdown title="Selecciona una ubicación">
            <Checkbox checked={true} position="right">
              <TextInfo color="black"><Icon name="icon-location-pin" border="round" size="12px" /> Santiago Centro</TextInfo>
            </Checkbox>
            <Checkbox position="right">
              <TextInfo color="black"><Icon name="icon-location-pin" border="round" size="12px" /> Independencia</TextInfo>
            </Checkbox>
            <Checkbox position="right">
              <TextInfo color="black"><Icon name="icon-location-pin" border="round" size="12px" /> La Florida</TextInfo>
            </Checkbox>
            <Checkbox marginZero={true} position="right">
              <TextInfo color="black"><Icon name="icon-location-pin" border="round" size="12px" /> San Miguel</TextInfo>
            </Checkbox>
          </Dropdown>
        </ButtonDropdown>

        <ButtonDropdown color="btn-white-gray" placeholder="$200.000 - $300.000">
          <Dropdown title="Cantidad de dormitorios y baños">
            <InputButton type="text" name="passw" flex={true} between={true} placeholder="2" >
              Cantidad de dormitorios
              </InputButton>
            <InputButton type="text" name="passw" flex={true} between={true} placeholder="1" >
              Cantidad de baños
              </InputButton>
          </Dropdown>
        </ButtonDropdown>

        <ButtonDropdown color="btn-white-gray" placeholder="2 dormitorios, 1 baño">
          <Dropdown title="Precio">
            <TitleFiltro size="16" title="Rango de precios" />
            <GroupRow>
              <Input type="text" name="passw" typeStyle="filtro" fullWidth={true} help="Minimo" placeholder="$150.000" />
              <Input type="text" name="passw" typeStyle="filtro" fullWidth={true} help="Máxima" placeholder="$350.000" />
            </GroupRow>
          </Dropdown>
        </ButtonDropdown>



        <Separador />

        <TitleFiltro title="Checkbox"></TitleFiltro>
        <Checkbox checked={true}>
          <TextInfo color="black">Santiago Centro</TextInfo>
        </Checkbox>
        <Checkbox>
          <TextInfo color="black">Independencia</TextInfo>
        </Checkbox>
        <Checkbox>
          <TextInfo color="black">La Florida</TextInfo>
        </Checkbox>
        <Checkbox marginZero={true}>
          <TextInfo color="black">San Miguel</TextInfo
          ></Checkbox>


        <Title size="30" marginTop="20">Badge</Title>
        <Separador />
        <Badge>Santiago Centro</Badge>
        <Badge>2 dormitorios</Badge>
        <Badge>1 baño</Badge>
        <Badge>Max $400.000</Badge>




        <Title size="30" marginTop="20">Texto Info</Title>
        <Separador />

        <TextInfo iconName="icon-tick" color="success" textWeight="bold">El 85% de los residentes destacan la seguridad del barrio.</TextInfo>
        <TextInfo>Gastos comunes <strong>desde $000.0000</strong></TextInfo>
        <TextInfo iconName="icon-location-pin">Santiago Centro</TextInfo>
        <TextInfo color="black">Santiago Centro</TextInfo>
        <TextInfo size="20" color="orange" textWeight="bold">$265.000</TextInfo>
        <Separador />

        <SalaArriendo />

        <div style={{ marginBottom: '200px' }} />

      </div>
    </div>
  );
}

export default Ui;
