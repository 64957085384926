import React from 'react';
import { Icon, Modal } from './ui/index';
import styles from './styles/ModalLugarInteres.module.scss';
import SocialButtons from './SocialButtons';


const ModalLugarInteres = ({ show, close, imgObj, friendlyPlaceType, name, address }) => {

  return (
    <Modal show={show} close={close}>
      <div className="modal-content modal-login fade">
        <div className="header-modal">
          <Icon onClick={close} name="icon-close" size="14" />
          <span className={styles.header}>Lugares destacados</span>
        </div>
        <div>
          <img src={imgObj.src} alt={imgObj.alt} className={styles.image} />
          <div className={styles.textContainer}>
            <div className={styles.placeTypeContainer}>
              <p className={styles.placeType}>{friendlyPlaceType}</p>
              <SocialButtons
                target="mobile-light"
                youtubeIconTop={7}
                containerStyle={{
                  position: 'relative',
                  top: '-2px',
                }}
              />
            </div>
            <h1>{name}</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
              ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
              laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
              voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
              non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <p><Icon
                name="icon-location-pin"
                background="light-orange"
                color="#EB6B08"
                width={20}
                size={10}
                style={{
                  position: 'relative',
                  top: '-2px',
                }}
              /> {address}</p>
            <p className={styles.link}>¿Cómo llegar?</p>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalLugarInteres;
