import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  TextInfo,
  Icon,
  Input,
  Select,
} from '../../../ui/index';

import {
  selectEstacionamientosAuto,
  selectEstacionamientosMoto,
  selectBodegas,
  selectTipologiaIndex,
  selectAmoblado,
  selectCurrentMesesArray,
  selectCurrentConfiguracionReserva,
  getConfiguracionReserva
} from '../../../../redux/preevaluacionSlice';

import { useSelector } from 'react-redux';
import {
  convertDateToUsDateFormat,
  convertSpanishDateToUsDate,
  formatPrice,
  getBodegaPrecio,
  getEstacionamientoAutoPrecio,
  getEstacionamientoMotoPrecio,
  getTipologiaGastoComun,
  getTipologiaPrecioMensual
} from '../../../../utils';
import './styles/main.scss';
import {
  BREAKPOINT_MOBILE,
  FLUJO_ENTRADA_AUTOFILL,
} from '../../../../config/constant';
import useResize from '../../../../hooks/useResize';
import GastoComunTipologia from '../../../../components/GastoComunTipologia';
import PrecioMensualTipologia from '../../../../components/PrecioMensualTipologia';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const DIAS_SIGUIENTES_ARRIENDO = 2;

function addDays(date) {
  var result = new Date(date);
  result.setDate(date.getDate() + DIAS_SIGUIENTES_ARRIENDO);
  return result;
}

const InnerForm = ({
  dias,
  horarios,
  setHorariosSelected,
  horariosSelected,
  currentCotizacion,
  meses,
  onMesesChange,
  fecha,
  onFechaChange,
  residentes,
  onResidentesChange,
  handleCalendar
}) => {
  // Responsive styles/classnames
  const [rizeWidth] = useResize();
  const layoutGroupClass = rizeWidth <= BREAKPOINT_MOBILE ? {} : 'layout-group';
  const [fechasValidas, setFechasValidas] = useState([])

  //const mesesArriendo = useSelector(selectCurrentMesesArray);
  //const { configuracionFechaInicio, fechaTermino } = currentCotizacion.configuracionReserva;
  const { fechaInicio, fechaTermino } = useSelector(selectCurrentConfiguracionReserva);
  const dateNow = new Date();
  const { mesesArriendo } = currentCotizacion;

  const mesesArriendoData = mesesArriendo.map(({ datoId, cantidad }) => {
    return { id: datoId, name: cantidad }
  })



  useEffect(() => {
    if (fechaTermino !== undefined) {

      let arrayDate = [];

      while (new Date(convertSpanishDateToUsDate(fechaTermino)).getTime() >= dateNow.getTime()) {
        dateNow.setDate(dateNow.getDate() + 1);
        const day = new Date(dateNow).getDay();
        const obtenerHoras = dias.filter(item => parseInt(item.dia) === parseInt(day));
        if (obtenerHoras.length > 0) {
          arrayDate.push(new Date(dateNow))
        }
      }
      setFechasValidas(arrayDate)
    }
  }, [fechaTermino])


  return (
    <div className="form-layout">
      <div className={layoutGroupClass}>
        <div className="form-group full-width">
          <label>Meses de arriendo* </label>
          <Select
            value={meses}
            data={mesesArriendoData}
            onChange={onMesesChange}
            placeholder="Seleccionar"
            className="bold"
            typeStyle="extra-small"
            fullWidth={true}
            noMargin={true}
          />
        </div>
        <Input value={residentes} onChange={onResidentesChange} label="Número de residentes*" type="number" classes="extra-small" min="1" fullWidth={true} placeholder="Número de residentes" />
      </div>
      <div className={layoutGroupClass}>
        <div className="form-group full-width">
          {/* <Button onClick={handleCalendar} style={{ marginTop: '20px', marginBottom: '30px' }} color='btn-outline-orange' size="extra-small" fullWidth={true}>
            Fecha inicio contrato*
          </Button> */}
          <DatePicker

            selected={fecha}
            onChange={(date) => onFechaChange(date)}
            //minDate={addDays(new Date())}
            placeholderText="Fecha inicio contrato"
            includeDates={fechasValidas}
          /*  minDate={dateNow}
           maxDate={fechaTermino !== undefined && new Date(convertSpanishDateToUsDate(fechaTermino))} */
          />
        </div>
      </div>
      <div className={layoutGroupClass}>
        <div className="contenHorario">

          {horarios.length > 0 ? (
            <>
              <p>Horarios disponibles</p>
              <small>Seleccione un horario</small>
              <div className="itemHorarios">
                {horarios.map((item, index) => <a className={`${horariosSelected === item ? 'selected' : ''}`} key={index} onClick={() => setHorariosSelected(item)}>{item}</a>)}
              </div>
            </>) :
            <>
              {fecha !== null && <strong className="noDisponible">No hay horarios disponibles para la fecha seleccionada.</strong>}
            </>
          }

        </div>
      </div>
    </div>
  );
}

const FormPersona = ({
  currentCotizacion,
  proyecto,
  title,
  checked,
  processData,
  initialData,
  showModalAval,
  showModalComplemento,
  showModalTitular,
  numAvales,
  numComplementos,
  callback,
  handleCalendar
}) => {
  const { dias } = useSelector(selectCurrentConfiguracionReserva);
  const [curForm, setCurForm] = useState(0);
  const [meses, setMeses] = useState(FLUJO_ENTRADA_AUTOFILL ? 7 : []);
  const [fecha, setFecha] = useState(null);
  const [residentes, setResidentes] = useState(FLUJO_ENTRADA_AUTOFILL ? 5 : null);
  const [payReserva, setPayReserva] = useState(false)
  const [horarios, setHorarios] = useState([]);
  const [horariosSelected, setHorariosSelected] = useState(null);
  const onMesesChange = (event) => {
    setMeses(event.target.value);
  }

  const onFechaChange = (event) => {
    const day = new Date(event).getDay();
    const obtenerHoras = dias.filter(item => parseInt(item.dia) === parseInt(day));
    if (obtenerHoras.length > 0) {
      const { horas } = obtenerHoras[0];
      const dataHoarios = new Set(horas);
      setHorarios([...dataHoarios]);
    } else {
      setHorarios([]);
      setHorariosSelected(null);
    }

    setFecha(event);
  }
  const onResidentesChange = (event, index) => {
    setResidentes(event.target.value);
  }

  // Chequea todos los campos. Devuelve true si todos son válidos, false otherwise.
  // También muestra toasts de error.
  const allFieldsAreValid = () => {
    if (!meses || meses === 'Seleccionar') {
      toast.error('Seleccione los meses de arriendo');
      return false;
    }

    if (!fecha) {
      toast.error('Fecha de inicio inválida!');
      return false;
    } else {
      /* if (new Date(fecha) < new Date()) {
        toast.error('Fecha de inicio inválida, la fecha seleccionada es inferior a la fecha actual.!');
        return false;
      } */
    }

    if (!residentes || isNaN(residentes)) {
      toast.error('Cantidad de residentes inválido!');
      return false;
    }

    return true;
  }

  const onSiguienteClick = () => {
    onEnviarClick() && setPayReserva(true)
  }

  const onPagarClick = () => {
    const urlLink = 'https://ww3.servipag.com/pagoenlinea/portal/desktop/public/todas/!ut/p/b1/04_Sj9CPykssy0xPLMnMz0vMAfGjzOKNgs0CLZ0MHQ38zQPcDBx9gs1CzAK9Df0DTYAKIvEo8DanTL-HEXH6DXAARwNC-r30o9Jz8pOAXg3Xj8KrGOQXvApAjgUrwOMaP4_83FT9gtzQ0IjKYE9dR0VFADNEynw!/dl4/d5/L2dBISEvZ0FBIS9nQSEh/?idServicio=47&idBiller=151#'
    window.location.href = urlLink;
  }


  const onEnviarClick = () => {
    if (!allFieldsAreValid()) {
      return false;
    }

    if (curForm === 0) {
      const fechaReserva = fecha.toLocaleString('es-ES', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      });
      const reserva = {
        reservaNumeroResidentes: parseInt(residentes),
        reservaMesesArriendoId: parseInt(meses),
        fechaReserva,
        hora: `${parseInt(horariosSelected)}`,
      }

      processData({ reserva })
      return true;
    }
    return true;
  }

  // Armar forms internos
  let innerForms = [];

  innerForms.push(
    <div>
      <InnerForm
        dias={dias}
        horarios={horarios}
        horariosSelected={horariosSelected}
        setHorariosSelected={setHorariosSelected}
        currentCotizacion={currentCotizacion}
        meses={meses}
        onMesesChange={(event) => onMesesChange(event)}
        fecha={fecha}
        onFechaChange={(event) => onFechaChange(event, 0)}
        residentes={residentes}
        onResidentesChange={(event) => onResidentesChange(event, 0)}
        handleCalendar={handleCalendar}
      />
    </div>
  );

  // Sobreescribir algunos estilos
  const [rizeWidth] = useResize();
  const stepBodyStyle = rizeWidth > BREAKPOINT_MOBILE ? { maxHeight: '100%' } : {};

  const estacionamientosAuto = useSelector(selectEstacionamientosAuto);
  const estacionamientosMoto = useSelector(selectEstacionamientosMoto);
  const bodegas = useSelector(selectBodegas);
  const tipologiaIndex = useSelector(selectTipologiaIndex);
  const amoblado = useSelector(selectAmoblado);
  const montoReserva = useSelector(selectCurrentConfiguracionReserva).monto

  let arriendoDpto = 0;
  let arriendoEstacionamientosAuto = 0;
  let arriendoEstacionamientosMoto = 0;
  let arriendoBodegas = 0;
  let arriendoComision = 0;
  let gastosComunesEstacionamientosAuto = 0;
  let gastosComunesEstacionamientosMoto = 0;
  let gastosComunesBodegas = 0;
  let precioMensual = 0;


  arriendoDpto = getTipologiaPrecioMensual(proyecto.tipologias[tipologiaIndex], amoblado);
  arriendoEstacionamientosAuto = getEstacionamientoAutoPrecio(proyecto) * estacionamientosAuto;
  arriendoEstacionamientosMoto = getEstacionamientoMotoPrecio(proyecto) * estacionamientosMoto;
  arriendoBodegas = getBodegaPrecio(proyecto) * bodegas;

  // Por alguna razón estamos recibiendo un 0 negativo (sí, existe tal cosa:
  // https://dev.to/emnudge/identifying-negative-zero-2j1o), que cuando se convierte con
  // toLocaleString da la string "-0". Esto lo arregla. El 0 negativo parece ocurrir solamente
  // para arriendoEstacionamientosAuto, pero por las dudas seteamos todo a 0. 
  if (arriendoDpto === 0 || arriendoDpto === '-') {
    arriendoDpto = 0;
  }

  if (arriendoEstacionamientosAuto === 0) {
    arriendoEstacionamientosAuto = 0;
  }

  if (arriendoEstacionamientosMoto === 0) {
    arriendoEstacionamientosMoto = 0;
  }

  if (arriendoBodegas === 0) {
    arriendoBodegas = 0;
  }

  precioMensual += arriendoDpto;
  precioMensual += arriendoEstacionamientosAuto + arriendoEstacionamientosMoto + arriendoBodegas;

  gastosComunesEstacionamientosAuto = estacionamientosAuto * proyecto.gastoComunMinEstacionamientosAuto;
  gastosComunesEstacionamientosMoto = estacionamientosMoto * proyecto.gastoComunMinEstacionamientosMoto;

  gastosComunesBodegas = bodegas * proyecto.gastoComunMinBodegas;
  const gastosComunesTotal = gastosComunesEstacionamientosAuto + gastosComunesEstacionamientosMoto +
    gastosComunesBodegas + getTipologiaGastoComun(proyecto.tipologias[tipologiaIndex]);


  return (
    <>
      <div className={`_itemStep-form reserva ${checked ? 'checked' : ''} active ${curForm === 3 ? 'in-resumen' : ''}`} >
        <div className={curForm === 0 ? 'active' : curForm > 0 ? 'highlight' : ''}>
          <div className="_step-header">
            <TextInfo textWeight="bold" size={18} color="black">Información adicional del titular<Icon size={16} name="icon-tick" /></TextInfo>
            <Icon onClick={showModalTitular} name="icon-question" />
          </div>
          <div className="_step-body" style={stepBodyStyle}>
            {innerForms}
            <Button disabled={horariosSelected === null} onClick={onSiguienteClick} style={{ marginTop: '20px', marginBottom: '30px' }} color='btn-outline-orange' size="extra-small" fullWidth={true}>
              GENERAR RESERVA
            </Button>
            {
              payReserva && (
                <div className="resumen-area">
                  <div className="pink-bg">
                    <p className="text-info text-orange">Monto a pagar para reservar <strong>${formatPrice(montoReserva)}</strong></p>
                  </div>
                  <div className="disclaimer">
                    <p>*Recuerda que dispones de <strong>48 horas</strong> para realizar el pago antes de que tu cotización pierda vigencia.</p>
                  </div>
                  <Button className="btn btn-reserva-enviar" disabled={!payReserva} onClick={onPagarClick} style={{ marginTop: '20px', marginBottom: '30px' }} color='btn-orange' size="extra-small" fullWidth={false}>
                    PAGAR RESERVA
                  </Button>
                </div>
              )
            }
          </div>
        </div>
      </div>

    </>
  );
}

export default FormPersona;
