import React from 'react';
import { useSelector } from 'react-redux';
import { selectFiltroAmoblado } from '../redux/proyectosSlice';
import { getTipologiaPrecioMensual } from '../utils';

// Devuelve el precio mensual de una tipologia, i.e. el precio mínimo de los
// deptos de esa tipología, sin tener en cuenta la disponibilidad.
const PrecioMensualTipologia = ({ tipologia, color }) => {

  const filtroAmoblado = useSelector(selectFiltroAmoblado);
  let precioMensual = '';

  if (tipologia) {
    precioMensual = getTipologiaPrecioMensual(tipologia, filtroAmoblado).toLocaleString();
  }

  return (
    <span style={{ color: color ? color : '' }}>${precioMensual}</span>
  );
}

export default PrecioMensualTipologia;
