import React, { useState } from 'react';
import { TextInfo } from '../ui/index';
import './styles/ResultMapModal.scss';
import CardMap from '../CardMap';
import ProyectosSlider from './ProyectosSlider';
import { useSelector } from 'react-redux';
import { selectFilteredProyectos } from '../../redux/proyectosSlice';
import ModalFiltroInteres from './ModalFiltroInteres';
import CotizarMobileModal from '../Cotizar/layers/CotizarMobileModal';
import { COTIZACION_TYPE } from '../Cotizar/layers/CotizarMobile';
import useHideCliengoButtons from '../../hooks/useHideCliengoButtons';


const ModalVistaMapaMobile = ({ show, close, cardType }) => {

  const [showFiltro, setShowFiltro] = useState(false);
  const [showCotizar, setShowCotizar] = useState(false);
  const [cotizarProyecto, setCotizarProyecto] = useState(null);
  const proyectos = useSelector(selectFilteredProyectos);
  let skipOnPinClick = false;
  let cotizacionType;

  useHideCliengoButtons(show);

  switch (cardType) {
    case 'estacionamientos':
      cotizacionType = COTIZACION_TYPE.ESTACIONAMIENTOS;
      break;

    case 'bodegas':
      cotizacionType = COTIZACION_TYPE.BODEGAS;
      break;

    default:
      break;
  }

  // Actualiza el slider a la posición adecuada cuando se clickea un pin en el mapa
  const onPinClick = (proyecto) => {
    if (skipOnPinClick) {
      skipOnPinClick = false;
      return;
    }

    const listPlaceMap = document.getElementsByClassName('list-place-map')[0];
    const recDots = listPlaceMap.getElementsByClassName('rec-dot');

    for (let i = 0; i < proyectos.length; i++) {
      const currProy = proyectos[i];

      if (currProy.proyecto_nombre === proyecto.proyecto_nombre) {
        recDots[i].click();
      }
    }
  }

  // Actualiza el pin activo del mapa cuando cambia el slide activo
  const onItemChange = (proyecto) => {
    skipOnPinClick = true;

    const circles = document.getElementsByClassName('circle-map');

    for (let i = 0; i < circles.length; i++) {
      const currCircle = circles[i];

      if (currCircle.dataset.direccion === proyecto.direccion) {
        currCircle.click();
      }
    }
  }

  const onBuscarClick = () => {
    setShowFiltro(true);
  }

  const closeFiltro = () => {
    setShowFiltro(false);
  }

  const cardImgClickCallback = (proyecto) => {
    setCotizarProyecto(proyecto);
    setShowCotizar(true);
  }


  return (
    <div className={`modal-search-mobile result-map-modal ${show}`}>
      <div className="header--search">
        <div className="header-step flex show" onClick={close}>
          <TextInfo iconName="icon-close" color="white" textWeight="bold"> Listado</TextInfo>
          <TextInfo iconName="icon-adjust" size="16" color="white" textWeight="bold"></TextInfo>
        </div>
      </div>
      <div className="search-body">
        <div className="body-step show">
          <span className="__search-area" onClick={onBuscarClick}>BUSCAR EN ESTA AREA</span>
          <div className="__body-map">
            <CardMap onMobilePinClick={onPinClick} disablePlaceCard={true} />
          </div>
          <ProyectosSlider
            onItemChange={onItemChange}
            cardType={cardType}
            cardImgClickCallback={cardImgClickCallback}
          />
        </div>
      </div>
      <ModalFiltroInteres show={showFiltro} close={closeFiltro} />
      {
        showCotizar &&
        <CotizarMobileModal
          proyecto={cotizarProyecto}
          show={showCotizar}
          close={() => setShowCotizar(false)}
          cotizacionType={cotizacionType}
        />
      }
    </div>
  );
};

export default ModalVistaMapaMobile;
