import React from 'react';
import youtube from '@iconify/icons-mdi/youtube';
import Icon from '@iconify/react';
import styles from './styles/YoutubeIcon.module.scss';
import { LEVEL_YOUTUBE_URL } from '../config/constant';


// Componente de icono de Youtube
const YoutubeIcon = ({
  color = 'white',
  url = LEVEL_YOUTUBE_URL,
  target = 'desk',
  iconSize,
  iconBgColor,
  top,
}) => {

  const mobileIconContainerStyle = {
    width: iconSize ? iconSize : '',
    height: iconSize ? iconSize : '',
    backgroundColor: iconBgColor ? iconBgColor : '',
  };

  const mobileAnchorStyle = {
    top: top ? top : '',
  };


  return (
    <>
    {
      target === 'desk' &&
      <a href={url} target="_blank" rel="noopener noreferrer">
        <Icon icon={youtube} color={color} className={styles.icon} />
      </a>
    }
    {
      target === 'mobile' &&
      <a href={url} target="_blank" rel="noopener noreferrer" className={styles.mobileAnchor} style={mobileAnchorStyle}>
        <div className={styles.mobileIconContainer} style={mobileIconContainerStyle}>
          <Icon icon={youtube} color={color} className={styles.icon} />
        </div>
      </a>
    }
    {
      target === 'mobile-light' &&
      <a href={url} target="_blank" rel="noopener noreferrer" className={styles.mobileAnchor} style={mobileAnchorStyle}>
        <div className={styles.mobileIconContainer} style={{ ...mobileIconContainerStyle, backgroundColor: '#F0F4FA' }}>
          <Icon icon={youtube} color="#2C2B42" className={styles.icon} />
        </div>
      </a>
    }
    </>
  );
}

export default YoutubeIcon;
