import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { selectLevelClienteId } from '../redux/userSlice';
import {
  getCotizaciones,
  selectCotizaciones,
  selectFiltroEdificios,
  selectFiltroOrdenar,
  selectFiltroUbicaciones,
  selectProyectos,
  setCotizaciones
} from '../redux/proyectosSlice';
import {
  filterCotizaciones, getTipologiaByTitulo,
} from '../utils';
import { BACKEND_BASE_URL } from '../config/constant';


// Hook de cotizaciones. Obtiene las cotizaciones del usuario y devuelve un array de objetos
// con la siguiente forma:
/*
    {
      id,
      proyecto,
      tipologia,
      departamento: {
        numero,
        precio,
        gastoComun,
      },
      estacionamientos: [
        {
          tipo,
          numero,
          precio,
          gastoComun,
        },
        ...
      ],
      bodegas: [
        {
          numero,
          precio,
          gastoComun,
        },
        ...
      ],
      expirationInDays,
      expired,
    }
*/
const useCotizaciones = () => {

  const dispatch = useDispatch();
  const cotizaciones = useSelector(selectCotizaciones);
  const levelClienteId = useSelector(selectLevelClienteId);
  const proyectos = useSelector(selectProyectos);
  const filtroUbicaciones = useSelector(selectFiltroUbicaciones);
  const filtroEdificios = useSelector(selectFiltroEdificios);
  const filtroOrdenar = useSelector(selectFiltroOrdenar);

  // Cotizaciones "full", i.e. con tipología
  const [cotizacionesFull, setCotizacionesFull] = useState([]);

  // Obtener cotizaciones. Las vamos a fetchear cada vez que se entre a la página de "Mis cotizaciones",
  // para asegurarnos que la data esté fresca.
  useEffect(() => {
    if (!levelClienteId || levelClienteId === '' || !proyectos || proyectos.length === 0) {
      return;
    }
    dispatch(getCotizaciones(levelClienteId));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proyectos]);


  // Construir las cotizaciones full cuando se obtienen las cotizaciones sin tipología. Necesario porque las
  // cotizaciones de Level vienen sin tipología. Lo hacemos acá porque no se puede fetchear data asíncronamente
  // en los extraReducers de Redux.
 /*  useEffect(() => {
    if (cotizaciones.length === 0) {
      return;
    }

    async function fetchDeptos() {
      const newCotizacionesFull = [];
      for (const cotizacion of cotizaciones) {
        try {
          const proyectoNombre = cotizacion.proyecto.proyecto_nombre;
          const deptoNumero = cotizacion.departamento.numero;

          const deptoResponse = await axios.get(`${BACKEND_BASE_URL}/departamentos?proyecto_nombre=${proyectoNombre}&nombre=${deptoNumero}`);

          const depto = deptoResponse.data[0];

          if (!depto) {
            throw new Error(`Depto ${deptoNumero} no pudo ser encontrado en proyecto ${proyectoNombre}`);
          } 

          const newCotizacionFull = {
            ...cotizacion,
            tipologia: getTipologiaByTitulo(cotizacion.proyecto, depto.tipologia.titulo)
          };

          newCotizacionesFull.push(newCotizacionFull);
        } catch (error) {
          console.log(`Error fetcheando depto: ${error}`);
        }
      }
      setCotizacionesFull(newCotizacionesFull);
    }

    fetchDeptos();
  }, [cotizaciones]);
 */

  // Filtramos las cotizaciones full
  let filteredCotizaciones = [];

  if (cotizaciones.length > 0) {
    const filtros = {
      ubicaciones: filtroUbicaciones,
      edificios: filtroEdificios,
      ordenar: filtroOrdenar,
    };

    filteredCotizaciones = filterCotizaciones(cotizaciones, filtros);
  }

  // Ordenamos las cotizaciones filtradas de más nuevas a más viejas
  let orderedCotizaciones = [];

  if (filteredCotizaciones.length > 0) {
    orderedCotizaciones = filteredCotizaciones.sort((a, b) => b.expirationInDays - a.expirationInDays);
  }

  return orderedCotizaciones;
}

export default useCotizaciones;
