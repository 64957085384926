import React, { useState } from 'react';
import { FEATURE_ENABLED } from '../config/constant';
import ModalNuevoContenido from './ModalNuevoContenido';


// Componente wrapper que agrega capacidad de mostrar modal de nuevo contenido a sus children.
const WrapperNuevoContenido = ({ children, waitingWorker }) => {

  const [showModalNuevoContenido, setShowModalNuevoContenido] = useState(true);

  return (
    <>
      {children}
      {
        (FEATURE_ENABLED.MODAL_NUEVO_CONTENIDO && waitingWorker && showModalNuevoContenido) &&
        <ModalNuevoContenido
          waitingWorker={waitingWorker}
          close={() => setShowModalNuevoContenido(false)}
        />
      }
    </>
  );
}

export default WrapperNuevoContenido;
