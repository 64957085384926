import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectFilteredProyectos,
  selectFilteredProyectosByBodegas,
  selectFilteredProyectosByEstacionamientos,
  selectFiltroRegiones,
  selectGetProyectosStatus
} from '../redux/proyectosSlice';
import { TextInfo } from './ui/index';
import './styles/ResultMobile.scss';
import CardEdificioMobile from './containers/CardEdificioMobile';
import CardAlerta from './containers/CardAlerta';
import ModalFiltroMobile from './containers/ModalFiltroMobile';
import ModalVistaMapaMobile from './containers/ModalVistaMapaMobile';
import OrdenarCheckboxes from './OrdenarCheckboxes';
import CotizacionesAlerta from './MisCotizaciones/CotizacionesAlerta';
import CardCotizacion from './MisCotizaciones/CardCotizacion';
import styles from './styles/ResultMobileCustom.module.scss';
import Spinner from './Spinner';
import spinnerStyle from './styles/ResultMobileSpinner.module.scss';
import { FILTER_TYPE } from '../config/constant';


export const TIPO_RESULT = {
  PROYECTO: 0,
  ESTACIONAMIENTOS: 1,
  BODEGAS: 2,
  MIS_COTIZACIONES: 3,
};


const ResultMobile = ({
  tipoResult = TIPO_RESULT.PROYECTO,
  cotizacionesData,
  onCardCotizacionArrendarClick,
  cardCotizacion
}) => {

  const [showFiltro, setShowFiltro] = useState('');
  const [showFiltroOrdenar, setShowFiltroOrdenar] = useState('');
  const [showFiltroMap, setShowFiltroMap] = useState('');

  let cardsCotizaciones = null;

  if (cotizacionesData && cotizacionesData.cotizaciones) {
    cardsCotizaciones = cardCotizacion;
    /*  cardsCotizaciones = cotizacionesData.cotizaciones.map((cotizacion) => {
       return (
         <CardCotizacion
           mobileCard={true}
           cotizacion={cotizacion}
           onArrendarClick={() => onCardCotizacionArrendarClick(cotizacion)}
           key={cotizacion.id}
         />
       );
     }); */
  }

  // Open Modal login
  const openFiltro = () => {
    setShowFiltro('show');
  }

  // Close Modal Login
  const closeFiltro = () => {
    setShowFiltro('');
  }

  // Open Modal login
  const openFiltroOrdenar = () => {
    setShowFiltroOrdenar('show');
  }

  // Close Modal Login
  const closeFiltroOrdenar = () => {
    setShowFiltroOrdenar('');
  }

  // Open Modal login
  const openFiltroMap = () => {
    setShowFiltroMap('show');
  }

  // Close Modal Login
  const closeFiltroMap = () => {
    setShowFiltroMap('');
  }

  // Indica si muchas personas están buscando en esta misma zona.
  // FIXME: Levantar esto de algún lado.
  const hotZone = false;

  // Proyectos filtrados de acuerdo a los filtros que hay en Redux
  const proyectosFiltered = useSelector(selectFilteredProyectos);
  const proyectosFilteredEstacionamientos = useSelector(selectFilteredProyectosByEstacionamientos);
  const proyectosFilteredBodegas = useSelector(selectFilteredProyectosByBodegas);

  const proyectosCards = proyectosFiltered.map((proyecto) => {
    return (
      <CardEdificioMobile key={proyecto._id} proyecto={proyecto} />
    );
  });

  const estacionamientosCards = proyectosFilteredEstacionamientos.map((proyecto) => {
    return (
      <CardEdificioMobile key={proyecto._id} proyecto={proyecto} estacionamientoCard={true} />
    );
  });

  const bodegasCards = proyectosFilteredBodegas.map((proyecto) => {
    return (
      <CardEdificioMobile key={proyecto._id} proyecto={proyecto} bodegaCard={true} />
    );
  });

  const regiones = useSelector(selectFiltroRegiones);
  let resultKind;
  let cardType;

  switch (tipoResult) {
    case TIPO_RESULT.PROYECTO:
      resultKind = 'Departamentos';
      break;

    case TIPO_RESULT.ESTACIONAMIENTOS:
      resultKind = 'Estacionamientos';
      cardType = 'estacionamientos';
      break;

    case TIPO_RESULT.BODEGAS:
      resultKind = 'Bodegas';
      cardType = 'bodegas';
      break;

    case TIPO_RESULT.MIS_COTIZACIONES:
      break;

    default:
      console.log(`Tipo de resultado "${tipoResult}" desconocido`);
      resultKind = '';
      break;
  }

  const headerStr = regiones.length >= 1 ? `${resultKind} en ${regiones.join(', ')}` : `${resultKind} en todas las regiones`;
  const getProyectosStatus = useSelector(selectGetProyectosStatus);

  // Tipo de filtro; depende del tipo de resultado
  let tipoFiltro;

  switch (tipoResult) {
    case TIPO_RESULT.PROYECTO:
      tipoFiltro = FILTER_TYPE.PROYECTOS;
      break;

    case TIPO_RESULT.ESTACIONAMIENTOS:
      tipoFiltro = FILTER_TYPE.ESTACIONAMIENTOS;
      break;

    case TIPO_RESULT.BODEGAS:
      tipoFiltro = FILTER_TYPE.BODEGAS;
      break;

    case TIPO_RESULT.MIS_COTIZACIONES:
      tipoFiltro = FILTER_TYPE.MIS_COTIZACIONES;
      break;

    default:
      console.log(`Tipo de resultado "${tipoResult}" desconocido`);
      break;
  }

  const filtroResultStyle = tipoResult === TIPO_RESULT.MIS_COTIZACIONES ? { marginTop: '75px' } : {};
  const bgResultStyle = tipoResult === TIPO_RESULT.MIS_COTIZACIONES ? { backgroundColor: 'inherit' } : {};


  return (
    <>
      <div className="container">
        {
          tipoResult !== TIPO_RESULT.MIS_COTIZACIONES &&

          <div className="searchCurrent">
            <div className="row">
              <TextInfo textWeight="bold" color="black" iconName="icon-search-fill">{headerStr}</TextInfo>
            </div>
          </div>
        }
        <div className="filtro--result" style={filtroResultStyle}>
          <TextInfo onClick={openFiltro} textWeight="bold" iconName="icon-adjust" color="white">FILTROS</TextInfo>
          <TextInfo onClick={openFiltroOrdenar} textWeight="bold" iconName="icon-arrow-desc" color="white">ORDENAR</TextInfo>
          <div className={`overlayDropdown ${showFiltroOrdenar}`}></div>
          <div className={`dropdown ${showFiltroOrdenar}`} tabIndex="15">
            <div className="dropdown-header">
              <TextInfo onClick={closeFiltroOrdenar} iconName="icon-close" iconSize="12" color="black" textWeight="bold">Ordenar por</TextInfo>
            </div>
            <div className="dropdown-body">
              <OrdenarCheckboxes />
              <button onClick={closeFiltroOrdenar} type="button" className="btn mt-3 btn-outline-orange extra-small full-width">APLICAR</button>
            </div>
          </div>
        </div>

        {
          tipoResult === TIPO_RESULT.MIS_COTIZACIONES &&
          <>
            <CotizacionesAlerta
              cardStyle={{
                position: 'relative',
                top: '114px',
                fontSize: '16px',
                paddingLeft: '28px',
                paddingRight: '28px'
              }}
            />
            <div style={{ position: 'relative', top: '135px' }}>
              <h1 className={styles.cotizacionesHeader}>Mis cotizaciones</h1>
              {
                cotizacionesData.errorMessage !== '' &&

                <CotizacionesAlerta
                  cardStyle={{
                    paddingLeft: '20px',
                    paddingRight: '20px'
                  }}
                  text={cotizacionesData.errorMessage}
                  icon=''
                />
              }
              <Spinner enabled={cotizacionesData.spinnerEnabled} spinnerStyle={spinnerStyle} />
              <br />
              {cardsCotizaciones}
            </div>
          </>
        }

        <div className="row bg-result" style={bgResultStyle}>
          <div className="results--body">
            {tipoResult === TIPO_RESULT.PROYECTO && getProyectosStatus === 'succeeded' &&
              proyectosCards.length === 0 &&
              <CardAlerta icon="icon-alarm" text="¡No se han encontrado resultados para tu búsqueda!" />}

            {tipoResult === TIPO_RESULT.ESTACIONAMIENTOS && getProyectosStatus === 'succeeded' &&
              estacionamientosCards.length === 0 &&
              <CardAlerta icon="icon-alarm" text="¡No se han encontrado resultados para tu búsqueda!" />}

            {tipoResult === TIPO_RESULT.BODEGAS && getProyectosStatus === 'succeeded' &&
              bodegasCards.length === 0 &&
              <CardAlerta icon="icon-alarm" text="¡No se han encontrado resultados para tu búsqueda!" />}

            {hotZone && <CardAlerta icon="icon-alarm" text="¡Muchas personas buscando en esta misma zona. <strong>Cotiza tu arriendo ahora.</strong>" />}

            {tipoResult === TIPO_RESULT.PROYECTO && proyectosCards}
            {tipoResult === TIPO_RESULT.ESTACIONAMIENTOS && estacionamientosCards}
            {tipoResult === TIPO_RESULT.BODEGAS && bodegasCards}
          </div>
          {/* <span onClick={openFiltroMap} className="mode-filtro map"> VER MAPA</span> */}
        </div>
      </div>
      {cardsCotizaciones !== null && <ModalFiltroMobile show={showFiltro} close={closeFiltro} type={tipoFiltro} filtroCotizacion={true} />}
      {cardsCotizaciones === null && < ModalFiltroMobile show={showFiltro} close={closeFiltro} type={tipoFiltro} filtroCotizacion={false} />}

      <ModalVistaMapaMobile show={showFiltroMap} close={closeFiltroMap} cardType={cardType} />
    </>
  );
};

export default ResultMobile;
