/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import './styles/destacadoinfo.scss';
import { Icon } from '../ui/index';
import ModalArrendar from '../ModalArrendar';
import { FEATURE_ENABLED, MENU_ENABLED } from '../../config/constant';
import { useHistory } from 'react-router-dom';
import {
  selectBodegasDisponibles,
  selectDeptosDisponibles,
  selectEstacionamientosDisponibles
} from '../../redux/proyectosSlice';
import { useSelector } from 'react-redux';
import { round5 } from '../../utils';


const Destacadoinfo = () => {

  const history = useHistory();
  const [showArrendar, setShowArrendar] = useState(false);
  const deptosDisponibles = useSelector(selectDeptosDisponibles);
  const estacionamientosDisponibles = useSelector(selectEstacionamientosDisponibles);
  const bodegasDisponibles = useSelector(selectBodegasDisponibles);

  const openArrendar = () => {
    setShowArrendar(true);
  }

  const closeArrendar = () => {
    setShowArrendar(false);
  }

  const onArriendaDeptoClick = () => {
    history.push('/result');
  }

  const onArriendaEstacionamientoClick = () => {
    if (MENU_ENABLED.ESTACIONAMIENTOS) {
      history.push('/estacionamientos');
    }
  }

  const onArriendaBodegaClick = () => {
    if (MENU_ENABLED.BODEGAS) {
      history.push('/bodegas');
    }
  }

  const arriendaEstacionamientoColor = MENU_ENABLED.ESTACIONAMIENTOS ? '#fff' : 'darkgray';
  const arriendaBodegaColor = MENU_ENABLED.BODEGAS ? '#fff' : 'darkgray';


  return (
    <>
      <div className="destacadoinfo">
        <div className="itemsInfo">
          <div className="itemInfo" style={{ cursor: 'pointer' }} onClick={onArriendaDeptoClick}>
            <Icon name="icon-edificio" border="round" background="gray-dark" color="#fff" width={58} size={36} />
            <div className="item-body">
              <h3>Arrienda un <br /> departamento </h3>
              {
                FEATURE_ENABLED.DESTACADO_INFO_CANTIDAD ?
                  <p>Más de {round5(deptosDisponibles)} disponibles</p>
                    :
                  <p>Disponibles</p>
              }
            </div>
          </div>
          <div className="itemInfo" style={{ cursor: 'pointer' }} onClick={onArriendaEstacionamientoClick}>
            <Icon name="icon-garage" border="round" background="gray-dark" color={arriendaEstacionamientoColor} width={58} size={36} />
            <div className="item-body">
              <h3 style={{ color: arriendaEstacionamientoColor }}>Arrienda un <br /> Estacionamiento</h3>
              {
                FEATURE_ENABLED.DESTACADO_INFO_CANTIDAD ?
                  <p style={{ color: arriendaEstacionamientoColor }}>Más de {round5(estacionamientosDisponibles)} disponibles</p>
                    :
                  <p>Disponibles</p>
              }
            </div>
          </div>
          <div className="itemInfo" style={{ cursor: 'pointer' }} onClick={onArriendaBodegaClick}>
            <Icon name="icon-warehouse" border="round" background="gray-dark" color={arriendaBodegaColor} width={58} size={36} />
            <div className="item-body">
              <h3 style={{ color: arriendaBodegaColor }}>Arrienda una  <br /> Bodega</h3>
              {
                FEATURE_ENABLED.DESTACADO_INFO_CANTIDAD ?
                <p style={{ color: arriendaBodegaColor }}>Más de {round5(bodegasDisponibles)} disponibles</p>
                  :
                <p>Disponibles</p>
              }
            </div>
          </div>
        </div>
        <div onClick={openArrendar} className="item-continue">
          <a href="#">
            <h3>Encuentra tu próximo <br /> hogar en Level</h3>
            <Icon name="icon-button-arrow-ight" color="#fff" size={20} />
          </a>
        </div>
      </div>
      <ModalArrendar
        show={showArrendar}
        close={closeArrendar}
      />
    </>
  );
};

export default Destacadoinfo;
